import { Box, Grid, Typography, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import ColorBox from './ColorBox';

const PREFIX = 'MaterialTable';

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  monitoringTable: `${PREFIX}-monitoringTable`,
  tableHeader: `${PREFIX}-tableHeader`,
  tableBodyCell: `${PREFIX}-tableBodyCell`,
  tableContainer: `${PREFIX}-tableContainer`,
  tableRow: `${PREFIX}-tableRow`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    borderRadius: '0px',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.monitoringTable}`]: {
    minWidth: 750,
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: '#0bb7a7',
    height: '10px',
  },

  [`& .${classes.tableBodyCell}`]: {
    borderRight: '0px !important',
    borderLeft: '0px solid white !important',
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent',
  },

  [`& .${classes.tableContainer}`]: {
    borderRadius: '1px !important',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.tableRow}`]: {
    color: 'white',
  },

  [`& .${classes.tableHeadCell}`]: {
    borderRight: '0px !important',
    borderLeft: '0px solid white !important',
    padding: '7px',
    color: 'white',
    display: 'table-cell !important',
  },
}));

/**
 * @function MaterialTable
 * @description MaterialTable component to render table
 * @param {object} props
 * @param {object} props.tab - object of the tab
 * @param {number} props.indexes - index of the tab
 * @param {object} props.values - object of the values
 * @param {function} props.handleChange - function to handle change in the input
 * @param {object} props.tableValTouched - object of the touched values
 * @param {object} props.tableValErrors - Table value errors object
 * @param {boolean} props.readOnlyField - boolean to check if the field is read only or not (true/false)
 * @param {string} props.tableTitlePrefix - string to show the table title prefix
 * @returns {React.Component} <MaterialTable />
 */
export default function MaterialTable({
  tab,
  indexes,
  values,
  handleChange,
  tableValTouched,
  tableValErrors,
  readOnlyField,
  tableTitlePrefix,
}) {
  return (
    <Root className={classes.root}>
      <Box mb={1}>
        <Typography component="h6" variant="h6">
          {tableTitlePrefix} {tab.key}
        </Typography>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.monitoringTable}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <colgroup>
            <col width="10%" />
            <col width="25%" />
            <col width="25%" />
            <col width="30%" />
            <col width="10%" />
          </colgroup>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeadCell} align="center">
                Sr. No
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                {tab?.key ? tab?.key : ''}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Interference
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Action
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="center">
                Representation
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tab.value.map((data, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell className={classes.tableBodyCell} align="center">
                  {index + 1}
                </TableCell>

                <TableCell className={classes.tableBodyCell} align="center">
                  {index === 2 && (
                    <TextField
                      required
                      type="number"
                      label="Enter value"
                      // placeholder={'less than'}
                      name={`tableValue[${indexes}].val1`}
                      value={
                        values?.tableValue?.length
                          ? values.tableValue[indexes]?.val1
                          : ''
                      }
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      helperText={
                        tableValErrors?.val1 && tableValTouched?.val1
                          ? tableValErrors.val1
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Less than
                          </InputAdornment>
                        ),
                        readOnly: readOnlyField,
                      }}
                      error={
                        Boolean(tableValErrors?.val1) && tableValTouched?.val1
                      }
                    />
                  )}
                  {index === 1 && (
                    <Grid container>
                      <Grid item xs={6}>
                        <TextField
                          required
                          type="number"
                          label="Enter value"
                          // placeholder={'from'}
                          name={`tableValue[${indexes}].val2`}
                          value={values.tableValue[indexes]?.val2}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          helperText={
                            tableValErrors?.val2 && tableValTouched?.val2
                              ? tableValErrors.val2
                              : null
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                From
                              </InputAdornment>
                            ),
                            readOnly: readOnlyField,
                          }}
                          error={
                            Boolean(tableValErrors?.val2) &&
                            tableValTouched?.val2
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          type="number"
                          label="Enter value"
                          // placeholder={'to'}
                          style={{ width: '100%' }}
                          name={`tableValue[${indexes}].val3`}
                          value={values.tableValue[indexes]?.val3}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          helperText={
                            tableValErrors?.val3 && tableValTouched?.val3
                              ? tableValErrors.val3
                              : null
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                To
                              </InputAdornment>
                            ),
                            readOnly: readOnlyField,
                          }}
                          error={
                            Boolean(tableValErrors?.val3) &&
                            tableValTouched?.val3
                          }
                        />
                      </Grid>
                    </Grid>
                  )}

                  {index === 0 && (
                    <TextField
                      required
                      type="number"
                      label="Enter value"
                      // placeholder={'greater than'}
                      name={`tableValue[${indexes}].val4`}
                      value={values.tableValue[indexes]?.val4}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      helperText={
                        tableValErrors?.val4 && tableValTouched?.val4
                          ? tableValErrors.val4
                          : null
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Greater than
                          </InputAdornment>
                        ),
                        readOnly: readOnlyField,
                      }}
                      error={
                        Boolean(tableValErrors?.val4) && tableValTouched?.val4
                      }
                    />
                  )}
                </TableCell>
                <TableCell className={classes.tableBodyCell} align="center">
                  {data.interference}
                </TableCell>
                <TableCell className={classes.tableBodyCell} align="center">
                  {data.action}
                </TableCell>
                <TableCell className={classes.tableBodyCell} align="center">
                  <ColorBox index={index} metric={tab?.key} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
}

MaterialTable.defaultProps = {
  readOnlyField: false,
  tableTitlePrefix: '',
};
MaterialTable.propTypes = {
  tab: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tableValTouched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tableValErrors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  indexes: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  readOnlyField: PropTypes.bool,
  tableTitlePrefix: PropTypes.string,
};
