// @ts-nocheck
import { useState } from 'react';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import { useSelector } from 'react-redux';
import axios from 'axios';
import useProject from '../Project/useProject';
import ApiEndpoints from '../../const/ApiEndpoints';

const BASE_URL = ApiEndpoints.MODULE_BASE_URL.DM;
/**
 * @function useDataIngestion
 * @description hook for data ingestion submodule of data management  module
 * @param {function} fetchDatasetList - fetch dataset list
 * @param {object} project - project object
 * @param {function} fetchDatasetRowsWithPagination - fetch dataset rows with pagination
 * @param {object} datasetRowsWithPagination - dataset rows with pagination
 * @param {object} setDatasetRowsWithPagination - set dataset rows with pagination
 * @param {object} totalDatasetRows - total dataset rows
 * @param {object} datasetRowsPageCount - dataset rows page count
 * @param {boolean} isLoading - to check if data is loading
 * @param {function} updateDatasetCells - update dataset cells
 * @param {function} refreshGridOnSuccess - refresh grid on success
 * @returns {object} - returning multiple methods and states for data ingestion submodule
 * @example const {initiateUploadToS3,fetchPresignedUrlForEachChunkFromS3,
 * updateDatasetsWithProject )= useDataIngestion ()
 */
function useDataIngestion() {
  const {
    fetchDatasetList,
    datasetList,
    datasetListPageCount,
    datasetListTotalCount,
    setDatasetListPageCount,
    setDatasetListTotalCount,
    project,
    fetchDatasetRowsWithPagination,
    datasetRowsWithPagination,
    setDatasetRowsWithPagination,
    totalDatasetRows,
    datasetRowsPageCount,
    isLoading,
    updateDatasetCells,
    refreshGridOnSuccess,
  } = useProject();

  // const [allDatasets, setAllDatasets] = useState([]);
  const [delimiter, setDelimiter] = useState(null);
  const [encoding, setEncoding] = useState(null);
  const { activeProject } = useSelector((state) => state.project);

  /**
   * @function initiateUploadToS3
   * @description POST API call for initiate upload to S3
   * @param {object} fileDetails - details of file
   * @param {function} successCallback - Callback function to be called on success
   * @param {function} errorCallback - Callback function to be called on error
   */
  const initiateUploadToS3 = (fileDetails, successCallback, errorCallback) =>
    axios
      .post(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.UPLOAD_S3}`,
        fileDetails
      )
      .then(
        (resp) => {
          if (successCallback) {
            successCallback(resp);
          }
        },
        (error) => {
          errorCallback(error);
        }
      );

  // useEffect(() => {
  //   if (activeDataset?.id) {
  //     datasetList.forEach((data) => {
  //       if (data.id === activeDataset.id) {
  //         setActiveDataset(data);
  //       }
  //     });
  //   }
  // }, [datasetList]);

  // chunkDetails = {
  //     fileName: file.fileName,
  //     partNumber: index,
  //     uploadId: uploadId,
  //     chunkData: chunkData,
  // }
  /**
   * @function uploadChunk
   * @description PUT API call for upload each chunk to cloud storage
   * @param {object} chunkDetails - details of chunk data
   * @param {object} config - config data
   * @returns {Promise} AxiosPromise
   * @example uploadChunk(chunkDetails, config)
   */

  const uploadChunk = (chunkDetails, config) => {
    return axios.put(
      `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.UPLOAD_CHUNK}`,
      chunkDetails,
      config
    );
  };

  // fileChunksSummary =  {
  //     fileName: file.fileName,
  //     parts: uploadPartsArray,
  //     uploadId: uploadId,
  //   }
  /**
   * @function onCompleteMultipartUploadToS3
   * @description API call for complete multipart upload to S3
   * @param {object} fileChunksSummary - summary of chunk files
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   *  when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example onCompleteMultipartUploadToS3(fileChunksSummary,successCallback,errorCallback)
   */
  const onCompleteMultipartUploadToS3 = (
    fileChunksSummary,
    successCallback,
    errorCallback
  ) =>
    axios
      .post(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.UPLOAD_COMPLETED}`,
        {
          ...fileChunksSummary,
          project_id: activeProject?.project_id,
          delimiter: delimiter || ',',
          encoding: encoding || 'utf-8',
        }
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  const { startUpload, progress } = useMultipartFileUploadToS3({
    uploadChunk,
    startUploadCallback: initiateUploadToS3,
    onCompleteMultipartUpload: onCompleteMultipartUploadToS3,
  });
  // Ready to use
  /**
   * @function markDatasetAsReadyToUse
   * @description Marks the dataset as ready to use.
   * @param {object} body - The request body.
   * @returns {Promise} - Axios promise
   * @example markDatasetAsReadyToUse(body);
   */
  const markDatasetAsReadyToUse = (body) =>
    axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.DI +
          ApiEndpoints.DI.DI_READY_TO_USE_STATUS
        }`,
        body
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function updateDatasetsWithProject
   * @description API call for updating datasets with project
   * @param {object} datasetDetails - dataset details
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example updateDatasetsWithProject(rowData)
   */
  const updateDatasetsWithProject = (datasetDetails) =>
    axios
      .put(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DM.ASSIGN_DATASET}`,
        datasetDetails
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );
  /**
  //  * @function  fetchAllDatasetsList
  //  * @description API call for fetching all datasetslist
  //  * @param {function} successCallback - Callback function to execute upon completion of API call
  //  * @param {function} errorCallback - Callback function to execute
  //  * when any error happens during the calling of API
  //  * @param {string} projectId - project id of dataset
  //  * @returns {Promise} AxiosPromise
  //  * @example  fetchAllDatasetsList(successCallback,errorCallback)
  //  */
  // const fetchAllDatasetsList = (
  //   projectId,
  //   successCallback = () => {},
  //   errorCallback = () => {}
  // ) => {
  //   fetchDatasetList(
  //     projectId ?? null,
  //     (resp) => {
  //       setAllDatasets(resp.data);
  //       successCallback(resp);
  //     },
  //     (e) => {
  //       setAllDatasets([]);
  //       errorCallback(e);
  //     }
  //   );
  // };
  /**
   * @function deleteDatasetsWithTableName
   * @description API call for deleting datasets with table name
   * @param {Array} rowData - row data
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example deleteDatasetsWithTableName (rowData, successCallback,errorCallback)
   */
  const deleteDatasetsWithTableName = (
    rowData,
    successCallback,
    errorCallback
  ) =>
    axios
      .delete(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DM.DELETE_DATASET}`,
        {
          data: {
            dataset_id: rowData?.id,
          },
        }
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * @function datasetCountRemap
   * @description API call for dataset count remap
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example datasetCountRemap(successCallback,errorCallback)
   */
  const datasetCountRemap = (successCallback, errorCallback) =>
    axios.get(`${BASE_URL + ApiEndpoints.DM.DATASET_COUNT_REMAP}`).then(
      (res) => {
        successCallback(res);
      },
      (error) => {
        errorCallback(error);
      }
    );
  /**
   * @function updateDataTypeTargetVariable
   * @description API call for updating data type target variable
   * @param {object} updatedDataTypeData - updating data type data
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example updateDataTypeTargetVariable(updatedDataTypeData,successCallback,errorCallback)
   */
  const updateDataTypeTargetVariable = (
    updatedDataTypeData,
    successCallback,
    errorCallback
  ) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.DI +
          ApiEndpoints.DI.UPDATE_DATATYPE_TARGET_VARIABLE
        }`,
        updatedDataTypeData
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * @function importS3
   * @description API call for importing S3 files
   * @param {object} formData - form data
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example importS3 (formData,successCallback,errorCallback)
   */
  const importS3 = (formData, successCallback, errorCallback) =>
    axios
      .post(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.IMPORT_S3}`,
        formData
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * @function importFlatFiles
   * @description API call for importing flat files
   * @param {object} formData - form data
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example importFlatFiles(formData,successCallback,errorCallback)
   */
  const importFlatFiles = (formData, successCallback, errorCallback) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.DI + +ApiEndpoints.DI.IMPORT_FLAT_FILES
        }`,
        formData
      )
      .then(
        (res) => {
          if (res) {
            successCallback(res);
          }
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * @function importPostGres
   * @description API call for importing post gres files
   * @param {object} formData - form data
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   *  * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example importPostGres(formData,successCallback,errorCallback)
   */
  const importPostGres = (formData, successCallback, errorCallback) =>
    axios
      .post(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.IMPORT_POSTGRES}`,
        formData
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * @function  importSql
   * @description API call for importing Sql files
   * @param {object} formData - form data
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   *  when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example  importSql(formData,successCallback,errorCallback)
   */
  const importSql = (formData, successCallback, errorCallback) =>
    axios
      .post(
        `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.IMPORT_SQL}`,
        formData
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * @function  fetchDatasetColumnDetails
   * @description API call for fetching dataset column details
   * @param {object} payload - payload data
   * @returns {Promise} AxiosPromise
   * @example  fetchDatasetColumnDetails(payload)
   */
  const fetchDatasetColumnDetails = (payload) =>
    axios.post(
      `${
        ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.DATASET_COLUMN_DETAILS
      }`,
      payload
    );

  const fetchTargetColumnsDetails = (datasetID) =>
    axios.get(`${ApiEndpoints.MODULE_BASE_URL.GR + ApiEndpoints.DM.DATASETS}`, {
      params: {
        dataset_id: datasetID,
      },
    });

  /**
   * @function  renameDatasetDisplayName
   * @description API call for renaming dataset display name.
   * @param {object} payload - payload data
   * @returns {Promise} AxiosPromise
   * @example  renameDatasetDisplayName(payload)
   */
  const renameDatasetDisplayName = (payload) =>
    axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.DI +
          ApiEndpoints.DI.RENAME_DATASET_DISPLAY_NAME
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  const recalibrateDataset = (payload) => {
    return axios.post(
      `${ApiEndpoints.MODULE_BASE_URL.DI + ApiEndpoints.DI.RESET_DATASET}`,
      payload
    );
  };
  return {
    startUpload,
    progress,
    // fetchAllDatasetsList,
    datasetList,
    fetchDatasetList,
    datasetListPageCount,
    datasetListTotalCount,
    setDatasetListPageCount,
    setDatasetListTotalCount,
    // allDatasets,
    updateDatasetsWithProject,
    updateDataTypeTargetVariable,
    deleteDatasetsWithTableName,
    datasetCountRemap,
    importFlatFiles,
    activeProject,
    importS3,
    importPostGres,
    importSql,
    project,
    fetchDatasetColumnDetails,
    markDatasetAsReadyToUse,
    fetchTargetColumnsDetails,
    setDelimiter,
    setEncoding,
    renameDatasetDisplayName,
    fetchDatasetRowsWithPagination,
    datasetRowsWithPagination,
    setDatasetRowsWithPagination,
    totalDatasetRows,
    datasetRowsPageCount,
    isLoading,
    updateDatasetCells,
    refreshGridOnSuccess,
    recalibrateDataset,
  };
}

export default useDataIngestion;
