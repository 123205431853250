import React, { useCallback } from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

// if getOptionLabel or renderOption specified then "select all" should be implemented outside because component
// is unaware about what keys are used in getOptionLabel and in renderOption

/**
 * @function MultiSelect
 * @description MultiSelect component for rendering a multiple selection dropdown.
 * @param {Object} props - The props passed to the MultiSelect component.
 * @param {string} props.helperText Helper text to display below the input.
 * @param {Function} props.renderOption Function to render custom options.
 * @param {Function} props.handleBlur Function to handle the onBlur event.
 * @param {number} props.limitTags Maximum number of tags to display.
 * @param {Function} props.getTagChipLabel Function to get the label for a tag chip.
 * @param {Function} props.getOptionLabel Function to get the label for an option.
 * @returns The JSX element representing the MultiSelect component.
 */
const MultiSelect = ({
  helperText,
  required = false,
  renderOption,
  handleBlur,
  limitTags = 3,
  getTagChipLabel,
  getOptionLabel,
  ...props
}) => {
  const selectAll = {
    label: 'Select all options',
    value: 'mui_auto_complete_select_all',
  };
  const handleChange = useCallback(
    (_e, value) => {
      if (value.includes(selectAll)) {
        if (props?.disabledOptions?.length) {
          return (
            props.onChange &&
            props.onChange(
              props?.options?.filter(
                (ele) => !props?.disabledOptions?.includes(ele.value)
              )
            )
          );
        }
        return props.onChange && props.onChange(props.options);
      }
      return props.onChange && props.onChange(value);
    },
    [props]
  );

  return (
    <Autocomplete
      disableCloseOnSelect
      size="small"
      popupIcon={<DropdownArrow />}
      isOptionEqualToValue={(item, current) => item.value === current.value}
      sx={(theme) => ({
        // '& .MuiInputBase-root': {
        //   minHeight: '53.13px !important',
        // },
        // '& .MuiFormLabel-root': {
        //   marginTop: '3px !important',
        // },
        '& .MuiInputBase-root.Mui-disabled': {
          background: `${theme.palette.other.white2} !important`,
        },
      })}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { onDelete, ...chipProps } = getTagProps({ index });
          return option.value !== 'mui_auto_complete_select_all' ? (
            <Chip
              color={props.color ? props.color : 'primary'}
              size={props?.size ? props?.size : 'small'}
              label={getTagChipLabel ? getTagChipLabel(option) : option.label}
              onDelete={() => {
                onDelete(chipProps.key);
              }}
              {...chipProps}
            />
          ) : null;
        })
      }
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          required={required}
          label={props.label}
          placeholder={!props?.value?.length ? props?.placeholder : ''}
          error={Boolean(props.error)}
          helperText={helperText}
          onBlur={handleBlur}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {...props}
      getOptionLabel={getOptionLabel || ((option) => option.label)}
      multiple
      limitTags={limitTags}
      options={
        // eslint-disable-next-line no-nested-ternary
        getOptionLabel || renderOption
          ? props?.options
          : props?.options?.length
          ? [selectAll, ...props.options]
          : []
      }
      onChange={handleChange}
    />
  );
};

MultiSelect.defaultProps = {
  required: false,
  handleBlur: () => {},
  color: 'primary',
  size: 'small',
  renderOption: null,
  limitTags: 3,
  getTagChipLabel: null,
  noOptionsText: '',
  disabledOptions: [],
  getOptionLabel: null,
  helperText: '',
  placeholder: '',
  name: '',
  error: false,
};

MultiSelect.propTypes = {
  helperText: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  color: PropTypes.string,
  name: PropTypes.string,
  noOptionsText: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  renderOption: PropTypes.func,
  limitTags: PropTypes.number,
  disabledOptions: PropTypes.oneOfType([PropTypes.array]),
  getTagChipLabel: PropTypes.func,
  getOptionLabel: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default MultiSelect;
