// @ts-nocheck
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
} from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { utils as UTILS, ReactMuiTable } from 'solytics-frontend';
import Editor from '@monaco-editor/react';
import { sortBy } from 'lodash';
import { CSVLink } from 'react-csv';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import * as _ from 'lodash';
import { DIALOG_ICON_BUTTON_CSS } from '../../../const/CommonConst';
import CommentBox from '../CommentBox';
import { ReactComponent as DeleteIcon } from '../../../assets/images/DeleteIcon.svg';
import { ReactComponent as CommentButtonIcon } from '../../../assets/images/CommentIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/DownloadIcon.svg';
import utils from '../../../utils/utils';
import { ReactComponent as CodeIcon } from '../../../assets/images/Code.svg';
import SwalToast from '../SwalToast';
import NoDataFoundComponent from '../NoDataFoundComponent';
import AddResultToModelMetaDataModal from '../AddResultToModelMetaDataModal';
import { ModelMonitoringDashboardIcon } from '../../../const/CommonIcons';
import ModelMonitoringDashboardDialog from '../../../views/ModelMonitoringDashboard/ModelMonitoringDashboardDialog';

const editorTheme = 'myThemeDark';
const UNAUTHORIZED_MESSAGE = 'You are not authorized to access this!';
const DEFAULT_PAGE_SIZE = 500;
const TABLE_COLUMN_WIDTH = 40;

const MIN_ROWS_PER_PAGE = 5;
const DEFAULT_ROWS_PER_PAGE = 10;
const MID_ROWS_PER_PAGE = 20;
const MAX_ROWS_PER_PAGE = 50;

/**
 * @function PaperComponent
 * @description It is Component of Paper
 * @param {any} props
 * @returns {JSX}
 * @example PaperComponent props={props}
 */
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const handleSetEmptyData = () => {};

/**
 * @function getCellProps
 * @description A function which is used to get Cell Props
 * @returns {void}
 */
const getCellProps = () => ({
  style: {
    textAlign: 'left',
    // paddingLeft: 24,
  },
});

/**
 * @function getHeaderProps
 * @description A function which is used to get Header Props
 * @returns {void}
 */
const getHeaderProps = () => ({
  style: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
});

const TableHeaderCell = ({ column }) => (
  <Box
    flexGrow={1}
    whiteSpace="noWrap"
    overflow="hidden"
    sx={{
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',

      letterSpacing: '0',
      textTransform: 'uppercase',
    }}
  >
    {column.heading}
  </Box>
);

TableHeaderCell.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const TableHeaderCellGridTasks = ({ column }) => (
  <Box
    flexGrow={1}
    whiteSpace="noWrap"
    overflow="hidden"
    sx={{
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',

      letterSpacing: '0',
      fontSize: '10px',
      textTransform: 'uppercase',
    }}
  >
    {column?.heading}
  </Box>
);

TableHeaderCellGridTasks.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

function TableWithComment({
  // codeSnippet,
  type,
  // data,
  opName = '',
  // tableheader,
  commentid,
  // commenttableName,
  customCell,
  comment = true,
  isAllOptionDisabled,
  moduleName,
  isConsole,
  rowsPerPageOptions,
  isGridTaskTable = false,
  tableId,
  fetchTableData,
  initialPageSize,
  permission,
  downloadTableData,
  isPaginated,
  onAddTableClick,
  tableData,
  onDeleteTable,
  deleteTable,
  index,
  activeProject,
  dataType,
  subModuleName,
  columnsToExcludeFromNumberFormatting = [],
  addResultToModelMetaData,
  modelMetaDataSchema,
  disableHeaderBorders = false,
}) {
  const [openTableComment, setOpenTableComment] = useState(false);
  const [skipPageReset] = React.useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tablePageData, setTablePageData] = useState([]);
  const [tableRowCount, setTableRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [openCode, setOpenCode] = React.useState(false);
  const [openAddToModelMetaData, setOpenAddToModelMetaData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  /**
   * @function handleCodeBoxOpen
   * @description When the condition will satisfied it will change state from null to true
   * @param {string} value
   * @returns {void}
   * @example handleCodeBoxOpen value={value}
   */
  const handleCodeBoxOpen = (value) => {
    if (value === 'Table') {
      setOpenCode(true);
    }
  };

  const CustomCell = (data) => {
    const { cell } = data;
    const label = cell?.value?.label;
    const value = cell?.value?.value;
    const cellValue =
      typeof cell?.value === 'object' ? cell?.value?.at(0) || '' : cell?.value;

    return (
      <>
        {label && value ? (
          <Box>{UTILS.getFormattedNumber(label)}</Box>
        ) : (
          <Box title={cell.value}>{UTILS.getFormattedNumber(cellValue)}</Box>
        )}
      </>
    );
  };

  const monacoRef = useRef(null);
  const handleEditorDidMount = (monaco) => {
    monacoRef.current = monaco;
    monaco.editor.defineTheme('myThemeDark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.foreground': '#AECDFC',
        'editor.background': '#141A3F',
        // 'editorCursor.foreground': '#8B0000',
        // 'editor.lineHighlightBackground': '#0000FF20',
        'editorLineNumber.foreground': '#141A3F',
        'editorLineNumber.activeForeground': '#fff',
        // 'editorLineNumber.background': 'red',
        'editorGutter.background': '#84afefbf',
        // 'editorGutter.mar': '5px',
        // 'editor.selectionBackground': '#88000030',
        // 'editor.inactiveSelectionBackground': '#88000015',
        'scrollbarSlider.background': '#AECDFC',
        'scrollbarSlider.activeBackground': '#AECDFC',
        'scrollbarSlider.hoverBackground': '#AECDFC',
      },
    });
  };

  /**
   * @function handleCodeBoxClose
   * @description It will change state from null to false
   * @returns {void}
   * @example handleCodeBoxClose()
   */
  const handleCodeBoxClose = () => {
    setOpenCode(false);
  };

  /**
   * @function handleCommentBoxOpen
   * @description When the condition will satisfied it will change state from null to true
   * @param {string} value
   * @returns {void}
   * @example handleCommentBoxOpen value={value}
   */
  const handleCommentBoxOpen = (value) => {
    if (value === 'Table') {
      setOpenTableComment(true);
    }
  };

  /**
   * @function handleCommentBoxClose
   * @description It will change state from null to false
   * @returns {void}
   * @example handleCommentBoxClose()
   */
  const handleCommentBoxClose = () => {
    setOpenTableComment(false);
  };

  /**
   * @function fetchDatasetRows
   * @description It is used to fetch Dataset Rows
   * @param {number} pageIndex
   * @param {number} pageSize
   * @returns {void}
   * @example fetchDatasetRows pageIndex={pageIndex}
   */
  const fetchDatasetRows = React.useCallback(
    _.debounce((pageIndex = 0, pageSize = DEFAULT_PAGE_SIZE) => {
      if (tableId || isPaginated) {
        fetchTableData(
          isPaginated ? commentid : tableId,
          pageIndex,
          pageSize
        ).then((res) => {
          setTableRowCount(res.headers['x-total-count']);
          setPageCount(Math.ceil(res.headers['x-total-count'] / pageSize));
          setTablePageData(
            Array.isArray(res.data.response) ? res.data.response : []
          );
        });
      }
    }, 1000),
    [tableId, isPaginated]
  );

  /**
   * @function handleDownloadClick
   * @description It is used to handle Download Click
   * @returns {void}
   */
  const handleDownloadClick = useCallback(() => {
    downloadTableData(commentid);
  }, [downloadTableData]);

  /**
   * @function handleDownloadError
   * @description It is a function which is used to handle Download Error
   * @returns {void}
   */
  const handleDownloadError = useCallback(() => {
    SwalToast({
      icon: 'error',
      title: 'Failed to download. Invalid data.',
    });
  }, []);

  /**
   * @function handleCodeBoxClick
   * @description A function which is used to handle Code Box Click
   * @returns {void}
   */
  const handleCodeBoxClick = useCallback(() => {
    handleCodeBoxOpen('Table');
  }, [handleCodeBoxOpen]);

  /**
   * @function handleCommentBoxClick
   * @description A function which is used to handle Comment Box Click
   * @returns {void}
   */
  const handleCommentBoxClick = useCallback(() => {
    handleCommentBoxOpen('Table');
  }, [handleCommentBoxOpen]);

  /**
   * @function addTableInMonitoring
   * @description It is used to handle Table Change
   * @param {object} payloadData - payloadData
   * @returns {void}
   * @example handleTableChange newPage={newPage}
   */

  const addTableInMonitoring = (payloadData) => {
    const tableDataPayload = payloadData;
    tableDataPayload.type = 'table';
    tableDataPayload.project_id = activeProject?.project_id;
    onAddTableClick(tableDataPayload).then((res) => {
      SwalToast({
        icon: 'success',
        title: res.data?.msg,
      });
    });
  };

  /**
   * @function handleAddTableClick
   * @description It is used to handle Add Table Click event
   * @returns {void}
   */
  const handleAddTableClick = useCallback(() => {
    addTableInMonitoring(tableData);
  }, [addTableInMonitoring]);

  /**
   * @function handleDeleteTable
   * @description A function which is used to handle Delete Table
   * @returns {void}
   */
  const handleDeleteTable = useCallback(() => {
    onDeleteTable(index, dataType);
  }, [onDeleteTable]);

  useEffect(() => {
    if (tableData?.data?.length !== 0) {
      const dynamicTableHeaders = utils.tableDataColumnCalculator(
        tableData?.data,
        TABLE_COLUMN_WIDTH,
        customCell || CustomCell,
        !isGridTaskTable ? TableHeaderCell : TableHeaderCellGridTasks,
        null,
        null,
        null,
        null,
        null,
        null,
        columnsToExcludeFromNumberFormatting,
        true
      );

      // This is for ordering the columns in results table, in case its unorganised.
      switch (opName) {
        case 'EDA_SUMMARY_STATS':
        case 'EDA_VA_UNIVARIATE':
        case 'SAMPLING_SAMPLING':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'attribute' ? 0 : 1
            )
          );
          break;
        case 'MI_PERFORMANCE_METRICS':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'metrics' ? 0 : 1
            )
          );
          break;
        case 'DM_DQ_ANAYLSE':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'statistics' || id.toLowerCase() === 'column'
                ? 0
                : 1
            )
          );
          break;
        case 'VR_FORWARD_FEATURE_SELECTION':
        case 'VR_BACKWARD_FEATURE_SELECTION':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'feature' ? 0 : 1
            )
          );
          break;
        case 'VR_IV_VISULAISATION':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'var_name' ? 0 : 1
            )
          );
          break;
        case 'VR_CLUSTRING':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'variable' ? 0 : 1
            )
          );
          break;
        case 'EDA_STAT_NONPARAMETRIC':
        case 'EDA_STAT_NORMALITY':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'name of test' ? 0 : 1
            )
          );
          break;
        case 'SEGMENTATION_RULE_BASED':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'statistics' ||
              id.toLowerCase() === 'segment'
                ? 0
                : 1
            )
          );
          break;
        case 'MI_INDIVIDUAL_PREDICTION_CONTRIBUTION':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) => (id === 'REASON' ? 0 : 1))
          );
          break;
        case 'SEGMENTATION_CLUSTERING':
        case 'SEGMENTATION_DECISION_TREE':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'column' ? 0 : 1
            )
          );
          break;
        case 'SAMPLING_SPLITTING':
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'column attributes' ? 0 : 1
            )
          );
          break;
        default:
          setTableColumns(
            sortBy(dynamicTableHeaders, ({ id }) =>
              id.toLowerCase() === 'column name' ||
              id.toLowerCase() === 'column names'
                ? 0
                : 1
            )
          );
          break;
      }

      // setTableColumns(dynamicTableHeaders);
      // for serialized rows
    }
  }, [tableData.data]);

  const handleAddToModelMetaDataModalClose = () => {
    setOpenAddToModelMetaData(false);
  };

  const showAddToModelMetaDataOption = React.useMemo(
    () =>
      addResultToModelMetaData &&
      activeProject?.system_generated_flag &&
      activeProject?.system_name === 'Vault',
    [addResultToModelMetaData, activeProject]
  );
  const handleModelMonitoringIconClick = useCallback(() => {
    setOpenDialog(true);
  }, [setOpenDialog]);

  return (
    <Grid>
      <Box>
        {type === 'outputTable' ? (
          <Box
            sx={
              !isGridTaskTable
                ? {
                    backgroundColor: 'white',
                    border: !disableHeaderBorders ? 1 : 0,
                    borderBottom: 0,
                    borderStyle: 'solid',
                    borderColor: 'secondary.main',
                    borderRadius: '4px 4px 0 0',
                    padding: 1,
                  }
                : {
                    backgroundColor: 'white',
                    padding: 0.5,
                  }
            }
            display="flex"
            alignItems="center"
            flex="1"
            flexDirection="row"
          >
            <Box flexGrow={1} textAlign="left">
              <Typography
                component={!isGridTaskTable ? 'h4' : 'body3'}
                variant={!isGridTaskTable ? 'h4' : 'body3'}
                style={{ textTransform: 'capitalize' }}
              >
                {tableData.header}
              </Typography>
            </Box>
            {Array.isArray(tableData?.data) || typeof data === 'string' ? (
              <>
                {isPaginated ? (
                  <IconButton
                    onClick={handleDownloadClick}
                    size="small"
                    color="primary"
                    data-html2canvas-ignore="true"
                  >
                    <DownloadIcon title="Download" />
                  </IconButton>
                ) : (
                  <CSVLink
                    headers={tableColumns?.map((ele) => ({
                      label: ele.heading,
                      key: ele.id,
                    }))}
                    data={tableData?.data}
                    filename={`${tableData.header || 'table'}.csv`}
                    style={{ textDecoration: 'none' }}
                  >
                    <IconButton
                      size="small"
                      color="primary"
                      data-html2canvas-ignore="true"
                    >
                      <DownloadIcon title="Download" />
                    </IconButton>
                  </CSVLink>
                )}
              </>
            ) : (
              <IconButton
                size="small"
                onClick={handleDownloadError}
                color="primary"
                data-html2canvas-ignore="true"
              >
                <DownloadIcon title="Download" />
              </IconButton>
            )}
            {tableData?.code && (
              <IconButton
                size="small"
                onClick={handleCodeBoxClick}
                data-html2canvas-ignore="true"
              >
                <CodeIcon title="Code snippet" />
              </IconButton>
            )}
            {comment && (
              <IconButton
                onClick={handleCommentBoxClick}
                size="small"
                disabled={!['Manage', 'View'].includes(permission)}
                title={
                  !['Manage', 'View'].includes(permission)
                    ? UNAUTHORIZED_MESSAGE
                    : ''
                }
                data-html2canvas-ignore="true"
              >
                <CommentButtonIcon title="Add comment" />
              </IconButton>
            )}
            {onAddTableClick && (
              <IconButton
                onClick={handleAddTableClick}
                size="small"
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? UNAUTHORIZED_MESSAGE
                    : 'Add to model monitoring dashboard'
                }
                color="primary"
                data-html2canvas-ignore="true"
              >
                <DashboardCustomizeIcon />
              </IconButton>
            )}
            {onAddTableClick && (
              <IconButton
                onClick={handleModelMonitoringIconClick}
                color="primary"
                title="Model monitoring dashboard"
                data-html2canvas-ignore="true"
              >
                <ModelMonitoringDashboardIcon width={20} height={20} />
              </IconButton>
            )}
            {deleteTable && (
              <IconButton
                onClick={handleDeleteTable}
                size="small"
                title={
                  !['Manage', 'Yes'].includes(permission)
                    ? UNAUTHORIZED_MESSAGE
                    : 'Delete'
                }
                disabled={!['Manage', 'Yes'].includes(permission)}
                color="primary"
                data-html2canvas-ignore="true"
              >
                <DeleteIcon />
              </IconButton>
            )}
            {showAddToModelMetaDataOption ? (
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenAddToModelMetaData(true);
                }}
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? UNAUTHORIZED_MESSAGE
                    : 'Add to vault'
                }
                data-html2canvas-ignore="true"
              >
                <ModelTrainingIcon />
              </IconButton>
            ) : null}
          </Box>
        ) : null}
        <Grid direction="row" container justifyContent="center">
          {isPaginated ? (
            <ReactMuiTable
              fetchData={fetchDatasetRows}
              enableControlledPagination={true}
              pageCount={pageCount}
              skipPageReset={true}
              dataLength={tableRowCount}
              setData={handleSetEmptyData}
              updateMyData={handleSetEmptyData}
              columns={tableColumns}
              data={tablePageData}
              enableRowSelection={false}
              showToolbar={false}
              isAllOptionDisabled={isAllOptionDisabled}
              rowsPerPageOptions={rowsPerPageOptions}
              initialPageSize={initialPageSize}
              getCellProps={getCellProps}
              getHeaderProps={getHeaderProps}
              NoDataFoundComponent={NoDataFoundComponent}
            />
          ) : (
            <ReactMuiTable
              setData={handleSetEmptyData}
              updateMyData={handleSetEmptyData}
              columns={tableColumns}
              data={tableData?.data}
              pageCount={tableData?.data?.length}
              enableRowSelection={false}
              skipPageReset={skipPageReset}
              showToolbar={false}
              isAllOptionDisabled={isAllOptionDisabled}
              rowsPerPageOptions={rowsPerPageOptions}
              initialPageSize={initialPageSize}
              getCellProps={getCellProps}
              getHeaderProps={getHeaderProps}
              NoDataFoundComponent={NoDataFoundComponent}
            />
          )}
        </Grid>

        {type === 'outputTable' && openTableComment && (
          <CommentBox
            handleCommentBoxClose={handleCommentBoxClose}
            id={commentid}
            openDialog={openTableComment}
            // tableName={commenttableName}
            type="table"
            moduleName={moduleName}
            subModuleName={subModuleName}
            isConsole={isConsole}
          />
        )}
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        PaperComponent={PaperComponent}
        open={openCode}
        onClose={handleCodeBoxClose}
        aria-labelledby="draggable-dialog-title"
        // hideBackdrop={true}
        // TransitionComponent={DialogTransition}
      >
        <DialogTitle>Code Snippet</DialogTitle>
        <IconButton
          id="mui_cmp_cust_drag_dialog_title_close_btn"
          className="mui-dialog-close-icon-btn"
          color="primary"
          aria-label="close"
          onClick={handleCodeBoxClose}
          size="large"
          sx={DIALOG_ICON_BUTTON_CSS}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Editor
            height="50vh"
            defaultLanguage="python"
            defaultValue={tableData?.code}
            theme={editorTheme}
            beforeMount={handleEditorDidMount}
            options={{
              padding: {
                left: 12,
                right: 12,
                top: 12,
                bottom: 12,
              },
              readOnly: true,
              fontSize: 16,
              lineHeight: 22,
              fontFamily: 'Open Sans',
              lineDecorationsWidth: 5,
              scrollbar: {
                vertical: 'auto',
                horizontal: 'auto',
                verticalScrollbarSize: 5,
                horizontalScrollbarSize: 5,
              },
            }}
          />
        </DialogContent>
      </Dialog>

      <ModelMonitoringDashboardDialog
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
      {showAddToModelMetaDataOption ? (
        <AddResultToModelMetaDataModal
          open={openAddToModelMetaData}
          onClose={handleAddToModelMetaDataModalClose}
          addResultToModelMetaData={addResultToModelMetaData}
          modelMetaDataSchema={modelMetaDataSchema}
          activeProject={activeProject}
          resultContents={tableData?.data}
          resultType="table"
        />
      ) : null}
    </Grid>
  );
}

TableWithComment.defaultProps = {
  permission: 'View',
  initialPageSize: 20,
  moduleName: '',
  fetchTableData: () => ({}),
  isGridTaskTable: false,
  rowsPerPageOptions: [
    MIN_ROWS_PER_PAGE,
    DEFAULT_ROWS_PER_PAGE,
    MID_ROWS_PER_PAGE,
    MAX_ROWS_PER_PAGE,
  ],
  isConsole: false,
  customCell: null,
  tableId: null,
  commentid: null,
  isPaginated: false,
  isAllOptionDisabled: false,
  downloadTableData: () => ({}),
  onAddTableClick: null,
  onDeleteTable: () => ({}),
  index: null,
  activeProject: null,
  dataType: '',
  deleteTable: false,
  subModuleName: '',
  columnsToExcludeFromNumberFormatting: [],
  addResultToModelMetaData: null,
  modelMetaDataSchema: null,
  disableHeaderBorders: false,
};
TableWithComment.propTypes = {
  type: PropTypes.string.isRequired,
  // data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  opName: PropTypes.string.isRequired,
  commentid: PropTypes.number,
  // commenttableName: PropTypes.string.isRequired,
  customCell: PropTypes.oneOfType([PropTypes.object]),
  // eslint-disable-next-line react/require-default-props
  comment: PropTypes.bool,
  isAllOptionDisabled: PropTypes.bool,
  isConsole: PropTypes.bool,
  rowsPerPageOptions: PropTypes.oneOfType([PropTypes.array]),
  isGridTaskTable: PropTypes.bool,
  tableId: PropTypes.number,
  fetchTableData: PropTypes.func,
  moduleName: PropTypes.string,
  initialPageSize: PropTypes.number,
  permission: PropTypes.string,
  downloadTableData: PropTypes.func,
  isPaginated: PropTypes.bool,
  onAddTableClick: PropTypes.func,
  tableData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteTable: PropTypes.func,
  index: PropTypes.bool,
  activeProject: PropTypes.oneOfType([PropTypes.object]),
  dataType: PropTypes.string,
  deleteTable: PropTypes.bool,
  subModuleName: PropTypes.string,
  columnsToExcludeFromNumberFormatting: PropTypes.oneOfType([PropTypes.array]),
  addResultToModelMetaData: PropTypes.func,
  modelMetaDataSchema: PropTypes.oneOfType([PropTypes.object]),
  disableHeaderBorders: PropTypes.bool,
};

export default TableWithComment;
