import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import DoneIcon from '@mui/icons-material/Done';
import React, { useEffect, useState } from 'react';
import { SwalToast } from '../../../Common';
import DummyData from '../PTDummyData';

const PREFIX = 'MetricSelection';

const classes = {
  alternativeLabel: `${PREFIX}-alternativeLabel`,
  active: `${PREFIX}-active`,
  completed: `${PREFIX}-completed`,
  line: `${PREFIX}-line`,
  root: `${PREFIX}-root`,
  chip: `${PREFIX}-chip`,
  typographyHeading: `${PREFIX}-typographyHeading`,
  instructions: `${PREFIX}-instructions`,
  stepper: `${PREFIX}-stepper`,
  nextButton: `${PREFIX}-nextButton`,
  backButton: `${PREFIX}-backButton`,
  labelText: `${PREFIX}-labelText`,
  iconContainer: `${PREFIX}-iconContainer`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginTop: '5px',
  },

  [`& .${classes.chip}`]: {
    margin: theme.spacing(0.5),
    fontSize: '1rem',
    letterSpacing: '1px',
    borderWidth: '2px',
  },

  [`& .${classes.typographyHeading}`]: {
    letterSpacing: '1px',
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.stepper}`]: {
    paddingTop: '15px !important',
    paddingBottom: '15px !important',
    paddingLeft: '15px !important',
    paddingRight: '15px !important',
    backgroundColor: 'white !important',
    border: '1px solid #0bb7a7',
    borderRadius: '4px',
  },

  [`& .${classes.nextButton}`]: {
    color: 'white',
    fontWeight: 'bold',
  },

  [`& .${classes.backButton}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.labelText}`]: {
    fontSize: '1.2rem',
    letterSpacing: '1px',
  },

  [`& .${classes.iconContainer}`]: {
    color: 'white',
    fontSize: '3rem',
  },
}));

function getSteps() {
  return ['Stability Metrics', 'Strength Metrics'];
}

const QontoConnector = StepConnector;

/**
 * @function MetricSelection
 * @param {object} userData - contains the details of logged in user
 * @param {object} activeDataset - contains the details of the active dataset
 * @param {object} activeProject - contains the details of the active project
 * @param {function} fetchExisistingRules - function to fetch existing rules
 * @param {object} existingMetrices - contains the details of existing metrics
 * @param {function} onMetricSelectionSubmit - function to submit the selected metrics
 * @returns {React.ReactElement} React component
 *
 */
const MetricSelection = ({
  userData,
  activeDataset,
  activeProject,
  fetchExisistingRules,
  existingMetrices,
  onMetricSelectionSubmit,
}) => {
  const [stabilityMetrics, setStabilityMetrics] = useState(
    existingMetrices?.stability
      ? existingMetrices?.stability
      : DummyData.stabilityMetrics
  );
  const [strengthMetrics, setStrengthMetrics] = useState(
    existingMetrices?.strength
      ? existingMetrices?.strength
      : DummyData.strengthMetrics
  );
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [isSubmitVisible, setIsSubmitVisible] = useState(false);
  const [notSelectedMetricsIndexes, setNotSelectedMetricsIndexes] = useState(
    []
  );

  useEffect(() => {
    if (existingMetrices?.stability && existingMetrices?.strength) {
      setStabilityMetrics(existingMetrices?.stability);
      setStrengthMetrics(existingMetrices?.strength);
    }
  }, [existingMetrices]);

  const handleSubmit = () => {
    const strength = strengthMetrics;
    const stability = stabilityMetrics;

    const body = {
      strength,
      stability,
      project_id: activeProject?.project_id,
      user: userData?.username,
      dataset_id: activeDataset?.id,
    };

    onMetricSelectionSubmit(body, () => {
      SwalToast({
        icon: 'success',
        title:
          'The operation is scheduled for execution. Please check the job queue for further updates.',
      });

      fetchExisistingRules(
        { project_id: activeProject?.project_id },
        () => {},
        () => {}
      );
    });
  };

  useEffect(() => {
    if (activeDataset?.name) {
      setStabilityMetrics(DummyData.stabilityMetrics);
      setStrengthMetrics(DummyData.strengthMetrics);
    }
  }, [activeDataset?.name]);

  const handleStabilityChipClick = (data) => {
    const newStabiltiyMetrics = [...stabilityMetrics];
    const currentIndex = newStabiltiyMetrics.findIndex(
      (x) => x.label === data.label
    );
    newStabiltiyMetrics[currentIndex].isSelected =
      !newStabiltiyMetrics[currentIndex].isSelected;
    setStabilityMetrics(newStabiltiyMetrics);
  };

  /**
   * @function handleStrengthChipClick
   * @description function to handle the click event of strength chip
   * @param {object} data - contains the details of the clicked chip
   * @returns {undefined} undefined
   */
  const handleStrengthChipClick = (data) => {
    const newStrengthMetrics = [...strengthMetrics];
    const currentIndex = newStrengthMetrics.findIndex(
      (x) => x.label === data.label
    );
    newStrengthMetrics[currentIndex].isSelected =
      !newStrengthMetrics[currentIndex].isSelected;
    setStrengthMetrics(newStrengthMetrics);
  };

  const getContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography
              variant="h6"
              align="center"
              className={classes.typographyHeading}
            >
              Select Stability Metrics
            </Typography>
            <div className={classes.root}>
              {stabilityMetrics.map((data) => (
                <li key={data.key}>
                  <Chip
                    variant="outlined"
                    // size="small"
                    label={data.label}
                    color={data.isSelected ? 'primary' : 'default'}
                    clickable
                    icon={data.isSelected ? <DoneIcon /> : null}
                    onClick={() => handleStabilityChipClick(data)}
                    className={classes.chip}
                  />
                </li>
              ))}
            </div>
          </>
        );

      case 1:
        return (
          <>
            <Typography
              variant="h6"
              align="center"
              className={classes.typographyHeading}
            >
              Select Strength Metrics
            </Typography>
            <div className={classes.root}>
              {strengthMetrics.map((data) => (
                <li key={data.key}>
                  <Chip
                    variant="outlined"
                    // size="small"
                    label={data.label}
                    color={data.isSelected ? 'primary' : 'default'}
                    clickable
                    icon={data.isSelected ? <DoneIcon /> : null}
                    onClick={() => handleStrengthChipClick(data)}
                    className={classes.chip}
                  />
                </li>
              ))}
            </div>
          </>
        );

      default:
        return (
          <>
            {isSubmitVisible ? (
              <Box height={55} textAlign="center">
                <Typography variant="h6">
                  Select Submit to make selection
                </Typography>
              </Box>
            ) : (
              <Box height={55} textAlign="center">
                <Typography color="error" variant="h6">
                  Please select atleast 1 item from each metrics and save
                  metrics.
                </Typography>
              </Box>
            )}
          </>
        );
    }
  };

  function checkSubmitVisible() {
    return (
      activeStep === steps.length &&
      stabilityMetrics.filter((data) => data.isSelected === true).length &&
      strengthMetrics.filter((data) => data.isSelected === true).length
    );
  }
  useEffect(() => {
    if (checkSubmitVisible()) setIsSubmitVisible(true);
    else setIsSubmitVisible(false);
  }, [activeStep]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNotSelectedMetricsIndexes([]);
  };

  const handleNext = () => {
    if (activeStep !== steps.length - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    else {
      const arr = [];
      const stabilityChoosenLength = stabilityMetrics.filter(
        (data) => data.isSelected === true
      ).length;
      const strengthChoosenLength = strengthMetrics.filter(
        (data) => data.isSelected === true
      ).length;
      if (!stabilityChoosenLength) arr.push(0);
      if (!strengthChoosenLength) arr.push(1);
      setNotSelectedMetricsIndexes(arr);

      if (stabilityChoosenLength && strengthChoosenLength) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (activeProject?.project_id) {
      fetchExisistingRules(
        { project_id: activeProject?.project_id },
        () => {},
        (error) => {
          const msg = error?.response?.data?.msg
            ? `Failed to fetch existing metrics : ${error.response.data.msg}`
            : 'Failed to fetch existing metrics ';
          SwalToast({
            icon: 'error',
            title: msg,
          });
        }
      );
    }
  }, [activeProject?.project_id, activeDataset?.name]);

  return (
    <StyledGrid container className="mt-3 ml-2" justifyContent="center">
      {activeDataset?.length !== 0 ? (
        <>
          <Grid item xs={5}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              connector={
                <QontoConnector
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    active: classes.active,
                    completed: classes.completed,
                    line: classes.line,
                  }}
                />
              }
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                labelProps.classes = {
                  label: classes.labelText,
                  iconContainer: classes.iconContainer,
                };
                if (notSelectedMetricsIndexes.includes(index)) {
                  labelProps.optional = (
                    <Typography variant="caption" color="error">
                      Please select atlease 1 option
                    </Typography>
                  );
                  labelProps.error = true;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div className={classes.instructions}>{getContent(activeStep)}</div>
            <Grid container justifyContent="flex-end">
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                className={classes.nextButton}
              >
                {activeStep === steps.length - 1 ? 'Save' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
    </StyledGrid>
  );
};

MetricSelection.propTypes = {
  userData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeDataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  stabilityMetrics: PropTypes.oneOfType([PropTypes.array]).isRequired,
  strengthMetrics: PropTypes.oneOfType([PropTypes.array]).isRequired,
  existingMetrices: PropTypes.oneOfType([PropTypes.array]).isRequired,
  fetchExisistingRules: PropTypes.func.isRequired,
  onMetricSelectionSubmit: PropTypes.func.isRequired,
  setStabilityMetrics: PropTypes.func.isRequired,
  setStrengthMetrics: PropTypes.func.isRequired,
};
export default MetricSelection;
