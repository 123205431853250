/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Chip, Typography, Zoom } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useHistory } from 'react-router-dom';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import Delete from '@mui/icons-material/Delete';
import { useContextMenu } from 'react-contexify';
import LockResetIcon from '@mui/icons-material/LockReset';
import { ReactComponent as DatasetIcon } from '../../assets/images/DatasetsIcon.svg';
import { ReactComponent as NoDatasetSelectedFrame } from '../../assets/images/Frame.svg';
import SwalToast from './SwalToast';
import {
  DS_LOCKED_MSG,
  DS_RECALIBRATION_MSG,
  DATASET_REQUIRED_FIELDS,
} from '../../const/CommonConst';
import useDataIngestion from '../../Hooks/DM/useDataIngestion';
import { ContextMenus } from './ContextMenus';
import DeleteConfirmModal from './DeleteConfirmModal';
import RecalibrationModal from './RecalibrationModal';

const PREFIX = 'OperationCards';

const classes = {
  chip: `${PREFIX}-chip`,
  activeChip: `${PREFIX}-activeChip`,
};

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: 'calc(100vh - 250px)',
  alignItems: 'center',
  paddingTop: theme.spacing(10),
  [`& .${classes.chip}`]: {
    margin: theme.spacing(1),
    maxWidth: theme.spacing(25),
  },
  [`& .${classes.activeChip}`]: {
    margin: theme.spacing(1),
    maxWidth: theme.spacing(25),
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.primary.main,
  },
}));

/**
 * @function NoDetailsSelected
 * @description function for case when do details are selected
 * @param {string} content - content to be displayed
 * @param {boolean} showFullContent - boolean to show full content
 * @param {object} activeOperation - object conatining details of selected operation.
 * @param {object} activeDataset - currently active dataset
 * @param {function} onDqClick - function to be called on click of dataset
 * @param {array} datasets - An array of dataset
 * @param {object} activeProject - An object which store active projects in DatasetTable
 * @param {string} module - module name
 * @returns {JSX.Element}
 */

const NoDetailsSelected = ({
  content,
  showFullContent = false,
  activeOperation,
  activeDataset,
  onDqClick,
  datasets,
  activeProject,
  module,
  fetchDatasetList,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const [deleteDatasetId, setDeleteDatasetId] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecalibrateModal, setShowRecalibrateModal] = useState(false);
  const filteredDatasetsNotReadyToUseArray = React.useMemo(
    () =>
      datasets?.length > 0 &&
      datasets?.filter(
        (item) => item.preprocessed === 'done' && item.published === false
      ),
    [datasets]
  );
  const { deleteDatasetsWithTableName, recalibrateDataset } =
    useDataIngestion();
  const { show: showDatasetOperationMenus } = useContextMenu();
  const menus = useMemo(
    () => [
      {
        label: `Recalibrate/Reset dataset`,
        id: 'ds_recal',
        icon: AutoModeIcon,
        isMenuDisabled: ({ props: data }) =>
          data?.datalock === false ||
          (data?.datalock === true && data?.preprocessed === 'recalibrating'),
        onClick: ({ props: data }) => {
          setDeleteDatasetId(data);
          setShowRecalibrateModal(true);
        },
      },
      {
        label: 'Delete dataset',
        id: 'ds_delete',
        icon: Delete,
        onClick: ({ props: data }) => {
          setDeleteDatasetId(data);
          setShowDeleteModal(true);
        },
      },
    ],
    [setDeleteDatasetId, setShowRecalibrateModal, setShowDeleteModal]
  );
  const handleDeleteDataset = useCallback(() => {
    deleteDatasetsWithTableName(
      deleteDatasetId,
      () => {
        SwalToast({
          icon: 'success',
          title: 'Dataset deleted successfully.',
        });
        fetchDatasetList(
          activeProject?.project_id,
          true,
          [
            DATASET_REQUIRED_FIELDS,
            'mvperc',
            'categorical_threshold',
            'stages',
            'fields',
          ].join()
        );
      },
      () => {
        SwalToast({
          icon: 'error',
          title: 'Failed to delete dataset.',
        });
      }
    );
    setShowDeleteModal(false);
  }, [setShowDeleteModal, deleteDatasetsWithTableName, deleteDatasetId]);

  const handleRecalibrateDataset = useCallback(() => {
    const payload = {
      dataset_id: deleteDatasetId?.id,
      project_id: activeProject?.project_id,
    };
    recalibrateDataset(payload).then((res) => {
      SwalToast({
        icon: 'success',
        title: res?.data?.response,
      });
      fetchDatasetList(
        activeProject?.project_id,
        true,
        [
          DATASET_REQUIRED_FIELDS,
          'mvperc',
          'categorical_threshold',
          'stages',
          'fields',
        ].join()
      );
    });
    setShowRecalibrateModal(false);
  }, [recalibrateDataset, setShowRecalibrateModal, deleteDatasetId]);

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
    setDeleteDatasetId({});
  }, [setDeleteDatasetId, setShowDeleteModal]);

  const handleCloseRecalibrateModal = useCallback(() => {
    setShowRecalibrateModal(false);
    setDeleteDatasetId({});
  }, [setDeleteDatasetId, setShowRecalibrateModal]);

  const onClickHandler = useCallback(() => {
    history.push('/data-management/data-ingestion');
  }, []);

  const getIcon = useCallback(
    (data) => {
      switch (true) {
        case data?.preprocessed === 'recalibrating':
          return <LockResetIcon color="primary" />;
        case data?.datalock:
          return <LockOutlinedIcon color="primary" />;
        default:
          return <></>;
      }
    },
    [datasets]
  );
  const getTitle = useCallback(
    (data) => {
      switch (true) {
        case data?.preprocessed === 'recalibrating':
          return DS_RECALIBRATION_MSG;
        case data?.datalock:
          return DS_LOCKED_MSG;
        default:
          return `Dataset: ${data.dataset}`;
      }
    },
    [datasets]
  );

  return (
    <RootStyle>
      <Zoom in={true} mountOnEnter unmountOnExit>
        <Box
          sx={{
            maxWidth: 480,
            margin: 'auto',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <NoDatasetSelectedFrame
            sx={{
              width: 100,
              height: 100,
              marginTop: '0px',
              cursor: 'pointer',
            }}
          />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              // marginY: theme.spacing(4),
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '26px',
            }}
          >
            {showFullContent
              ? content
              : `${content} ${activeOperation?.label} operation.`}
          </Typography>

          {!showFullContent &&
            (datasets?.length !== 0 ? (
              <Box
                overflow="hidden"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="flex-start"
                sx={{
                  border: '1px solid',
                  borderColor: theme.palette.secondary.light,
                  borderRadius: '3px',
                  padding: theme.spacing(2),
                  marginX: theme.spacing(3),
                  minWidth: 420,
                  maxHeight: 180,
                  overflowY: 'auto',
                }}
              >
                {datasets &&
                activeProject?.project_id &&
                datasets.filter(
                  (item) =>
                    item.preprocessed === 'done' &&
                    (item.published === true ||
                      (item.published === false && module === 'DM'))
                ).length !== 0 ? (
                  datasets
                    .filter(
                      (item) =>
                        (item.preprocessed === 'done' ||
                          item.preprocessed === 'recalibrating') &&
                        (item.published === true ||
                          (item.published === false && module === 'DM'))
                    )
                    .map((data, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Box
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        id={`${module}_ops_cards_dts_dt_${data.id}`}
                      >
                        <Chip
                          size="small"
                          color="primary"
                          title={getTitle(data)}
                          sx={{
                            '.MuiChip-icon': {
                              color: 'initial',
                            },
                          }}
                          icon={getIcon(data)}
                          label={
                            <Typography variant="body2" noWrap={true}>
                              {data.table_display_name}
                            </Typography>
                          }
                          onClick={() => {
                            if (data?.preprocessed === 'recalibrating') {
                              SwalToast({
                                icon: 'info',
                                title: DS_RECALIBRATION_MSG,
                              });
                              onDqClick(data);
                            } else if (data?.datalock) {
                              SwalToast({
                                icon: 'info',
                                title: DS_LOCKED_MSG,
                              });
                              onDqClick(data);
                            } else {
                              onDqClick(data);
                            }
                          }}
                          className={
                            activeDataset?.name === data.name
                              ? classes.activeChip
                              : classes.chip
                          }
                          onContextMenu={(e) => {
                            showDatasetOperationMenus({
                              id: 'dataset-operation-menus',
                              event: e,
                              props: data,
                            });
                          }}
                        />
                        <ContextMenus
                          menuId="dataset-operation-menus"
                          menus={menus}
                        />
                      </Box>
                    ))
                ) : (
                  <Typography variant="body2">
                    {filteredDatasetsNotReadyToUseArray?.length} dataset(s)
                    available in this project but not marked as ready to use.
                  </Typography>
                )}
              </Box>
            ) : (
              <Box
                overflow="hidden"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                // justifyContent="flex-start"
                sx={{
                  padding: theme.spacing(2),
                  marginX: theme.spacing(3),
                  minWidth: 420,
                  maxHeight: 180,
                  overflowY: 'auto',
                }}
              >
                <DatasetIcon onClick={onClickHandler} />
                <Typography
                  variant="body2"
                  noWrap={true}
                  onClick={onClickHandler}
                  sx={(t) => ({
                    color: t.palette.other.lightBrandBlueLogin,
                    textDecoration: 'underline',
                    textDecorationColor: t.palette.other.lightBrandBlueLogin,
                  })}
                >
                  +Add dataset
                </Typography>
              </Box>
            ))}
        </Box>
      </Zoom>
      <DeleteConfirmModal
        open={showDeleteModal}
        onCloseClick={handleCloseDeleteModal}
        onDeleteClick={handleDeleteDataset}
        confirmationMsg={`Are you sure you want to delete ${deleteDatasetId.table_display_name} ?`}
        resourceName="Dataset"
      />
      <RecalibrationModal
        open={showRecalibrateModal}
        onClose={handleCloseRecalibrateModal}
        onRecalibrateClick={handleRecalibrateDataset}
      />
    </RootStyle>
  );
};

export default NoDetailsSelected;
