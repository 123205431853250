const ModelLib = [
  { key: 'h2o', label: 'h2o' },
  { key: 'sklearn', label: 'sklearn' },
  { key: 'statsmodels', label: 'statsmodels' },
];

const H2Mojo = [
  { key: 'DeepLearning', label: 'Deep Learning (Neural Networks)' },
  { key: 'DistributedRandomForest', label: 'Distributed Random Forest (DRF)' },
  { key: 'GeneralizedLinearModel', label: 'Generalized Linear Model (GLM)' },
  {
    key: 'SupportVectorMachine',
    label: 'Support Vector Machine',
  },
  { key: 'GradientBoostingMachine', label: 'Gradient Boosting Machine (GBM)' },
  { key: 'NaiveBayesClassifier', label: 'Naive Bayes' },
  { key: 'XGBoost_Classifier', label: 'XGBoost Classifier' },
];

const skL = [
  {
    key: 'RandomForestClassifier',
    label: 'Random Forest Classifier',
  },
  { key: 'NaiveBayesClassifier', label: 'Naive Bayes' },
  { key: 'LinearDiscriminantAnalysis', label: 'Linear Discriminant Analysis' },
  { key: 'SupportVectorMachine', label: 'Support Vector Machine' },
  {
    key: 'LogisticRegressionClassifier',
    label: 'Logistic Regression Classifier',
  },
  {
    key: 'DecisionTreeClassifier',
    label: 'Decision Tree Classifier',
  },
  {
    key: 'ArtificialNeuralNetworkClassifier',
    label: 'Artificial Neural Network',
  },
];

const autoGl = [
  { key: 'CatboostClassifier', label: 'CatBoost Classifier' },
  { key: 'LightGBMClassifierXT', label: 'LightGBMClassifierXT' },
  { key: 'LightGBMClassifier', label: 'LightGBMClassifier' },
  { key: 'LightGBMClassifierCustom', label: 'LightGBMClassifierCustom' },
  { key: 'RandomForestClassifierEntr', label: 'RandomForestClassifierEntr' },
  { key: 'RandomForestClassifierGini', label: 'RandomForestClassifierGini' },
  { key: 'ExtraTreesClassifierGini', label: 'ExtraTrees Classifier (Gini)' },
  { key: 'ExtraTreesClassifierEntr', label: 'ExtraTrees Classifier (Entropy)' },
  { key: 'KNeighborsClassifierUnif', label: 'KNeighborsClassifierUnif' },
  { key: 'KNeighborsClassifierDist', label: 'KNeighborsClassifierDist' },
  { key: 'XGBoost_Classifier', label: 'XGBoost Classifier' },
  {
    key: 'LogisticRegressionClassifier',
    label: 'LogisticRegressionClassifier',
  },
];

const R = [
  { key: 'NaiveBayesClassifier', label: 'Naive Bayes' },
  { key: 'Linear Regression', label: 'Linear Regression' },
  { key: 'SupportVectorMachine', label: 'Support Vector Machine' },
  {
    key: 'LogisticRegressionClassifier',
    label: 'Logistic Regression Classifier',
  },
];

const statsModel = [
  { key: 'ARIMA', label: 'ARIMA' },
  { key: 'ARIMAX', label: 'ARIMAX' },
  { key: 'SARIMAX', label: 'SARIMAX' },
  { key: 'ARDL', label: 'ARDL' },
  { key: 'OLS REGRESSION', label: 'OLS Regression' },
  { key: 'LASSO REGRESSION', label: 'Lasso Regression' },
  {
    key: 'BETA REGRESSION',
    label: 'Beta Regression',
  },
  {
    key: 'GLS REGRESSION',
    label: 'GLS Regression',
  },
  {
    key: 'GLSAR REGRESSION',
    label: 'GLSAR Regression',
  },
  {
    key: 'WLS REGRESSION',
    label: 'WLS Regression',
  },
  {
    key: 'Quantile Regression',
    label: 'Quantile Regression',
  },
];

const NonTimeSeriesModelTechinquesMI = [
  { value: 'CatboostClassifier', label: 'CatBoost Classifier' },
  { value: 'XGBoost_Classifier', label: 'XGBoost Classifier' },
  { value: 'ExtraTreesClassifierGini', label: 'ExtraTrees Classifier (Gini)' },
  {
    value: 'ExtraTreesClassifierEntr',
    label: 'ExtraTrees Classifier (Entropy)',
  },
  {
    value: 'RandomForestClassifierEntr',
    label: 'Random Forest Classifier (Entropy)',
  },
  {
    value: 'RandomForestClassifierGini',
    label: 'Random Forest Classifier (Gini)',
  },
  {
    value: 'LogisticRegressionClassifier',
    label: 'Logistic Regression Classifier',
  },
  { value: 'LinearSVC', label: 'LinearSVC' },
  { value: 'NaiveBayesClassifier', label: 'Naive Bayes' },
  {
    value: 'SupportVectorMachine',
    label: 'Support Vector Machine',
  },
  { value: 'DecisionTreeClassifier', label: 'Decision Tree Classifier' },
  {
    value: 'ArtificialNeuralNetworkClassifier',
    label: 'Artificial Neural Network',
  },
];

const TimeSeriesModelTechinquesMI = [
  { value: 'ARIMA', label: 'ARIMA' },
  { value: 'ARIMAX', label: 'ARIMAX' },
  { value: 'ARDL', label: 'ARDL' },
  { value: 'OLS REGRESSION', label: 'OLS Regression' },
  { value: 'LASSO REGRESSION', label: 'Lasso Regression' },
  { value: 'SARIMAX', label: 'SARIMAX' },
  {
    value: 'BETA REGRESSION',
    label: 'Beta Regression',
  },
  {
    value: 'GLS REGRESSION',
    label: 'GLS Regression',
  },
  {
    value: 'GLSAR REGRESSION',
    label: 'GLSAR Regression',
  },
  {
    value: 'WLS REGRESSION',
    label: 'WLS Regression',
  },
  {
    value: 'Quantile Regression',
    label: 'Quantile Regression',
  },
];

const GlmGeeModelTechinquesMI = [
  { value: 'GEE', label: 'GEE' },
  { value: 'GLM', label: 'GLM' },
];

const VECMModelTechinquesMI = [{ value: 'VECM', label: 'VECM' }];

// models for sensitivity analysis
const SAModals = [
  'LASSO REGRESSION',
  'ARIMAX',
  'SARIMAX',
  'ARDL',
  'OLS REGRESSION',
  'GLM',
  'GEE',
  'VECM',
  'BETA REGRESSION',
  'GLS REGRESSION',
  'GLSAR REGRESSION',
  'WLS REGRESSION',
  'Quantile Regression',
];

const TimeSeriesModels = [
  'LASSO REGRESSION',
  'ARIMAX',
  'SARIMAX',
  'ARIMA',
  'ARDL',
  'OLS REGRESSION',
  'GLM',
  'GEE',
  'VECM',
  'BETA REGRESSION',
  'GLS REGRESSION',
  'GLSAR REGRESSION',
  'WLS REGRESSION',
  'Quantile Regression',
];

const TimeSeriesATModels = [
  'LASSO REGRESSION',
  'ARIMAX',
  'SARIMAX',
  'ARIMA',
  'ARDL',
  'OLS REGRESSION',
  'GLM',
  'GEE',
  'BETA REGRESSION',
  'GLS REGRESSION',
  'GLSAR REGRESSION',
  'WLS REGRESSION',
  'Quantile Regression',
];

const DisplayModels = [
  { key: 'XGBoost_Classifier', label: 'XGBoost Classifier' },
  { key: 'CatboostClassifier', label: 'CatBoost Classifier' },
  {
    key: 'RandomForestClassifierEntr',
    label: 'Random Forest Classifier (Entropy)',
  },
  {
    key: 'RandomForestClassifierGini',
    label: 'Random Forest Classifier (Gini)',
  },
  { key: 'ExtraTreesClassifierGini', label: 'ExtraTrees Classifier (Gini)' },
  { key: 'ExtraTreesClassifierEntr', label: 'ExtraTrees Classifier (Entropy)' },
  {
    key: 'LogisticRegressionClassifier',
    label: 'Logistic Regression Classifier',
  },
  {
    key: 'RandomForestClassifier',
    label: 'Random Forest Classifier',
  },
  { key: 'DistributedRandomForest', label: 'Distributed Random Forest (DRF)' },
  { key: 'GradientBoostingMachine', label: 'Gradient Boosting Machine (GBM)' },
  { key: 'NaiveBayesClassifier', label: 'Naive Bayes' },
  {
    key: 'SupportVectorMachine',
    label: 'Support Vector Machine',
  },
  { key: 'DecisionTreeClassifier', label: 'Decision Tree Classifier' },
  {
    key: 'ArtificialNeuralNetworkClassifier',
    label: 'Artificial Neural Network',
  },
  {
    key: 'GeneralizedLinearModel',
    label: 'Generalized Linear Model',
  },
  {
    key: 'DeepLearning',
    label: 'Deep Learning (Neural Networks)',
  },
  { key: 'OLS REGRESSION', label: 'OLS Regression' },
  { key: 'LASSO REGRESSION', label: 'Lasso Regression' },
  { key: 'ARIMAX', label: 'ARIMAX' },
  { key: 'SARIMAX', label: 'SARIMAX' },
  { key: 'ARIMA', label: 'ARIMA' },
  { key: 'ARDL', label: 'ARDL' },
  { key: 'GLM', label: 'GLM' },
  { key: 'GEE', label: 'GEE' },
  { key: 'VECM', label: 'VECM' },
  { key: 'BETA REGRESSION', label: 'Beta Regression' },
];

const DisplayModelsInteraction = [
  { key: 'RandomForestClassifierEntr', label: 'RandomForestClassifierEntr' },
  { key: 'RandomForestClassifierGini', label: 'RandomForestClassifierGini' },
  { key: 'ExtraTreesClassifierGini', label: 'ExtraTreesClassifierGini' },
  { key: 'ExtraTreesClassifierEntr', label: 'ExtraTreesClassifierEntr' },
  {
    key: 'RandomForestClassifier',
    label: 'Decision Tree Classifiers/Random Forests',
  },
  { key: 'XGBoost_Classifier', label: 'XGBoost Classifier' },
  { key: 'DistributedRandomForest', label: 'Distributed Random Forest (DRF)' },
  { key: 'GradientBoostingMachine', label: 'Gradient Boosting Machine (GBM)' },
];

const DisplayModelsDTBarPlots = [
  {
    key: 'RandomForestClassifierEntr',
    label: 'Random Forest Classifier (Entropy)',
  },
  {
    key: 'RandomForestClassifier',
    label: 'Random Forest Classifier',
  },
  {
    key: 'RandomForestClassifierGini',
    label: 'Random Forest Classifier (Gini)',
  },
];

const DisplayModelsDTDecisionTrees = [
  {
    key: 'RandomForestClassifierEntr',
    label: 'Random Forest Classifier (Entropy)',
  },
  {
    key: 'RandomForestClassifier',
    label: 'Random Forest Classifier',
  },
  {
    key: 'RandomForestClassifierGini',
    label: 'Random Forest Classifier (Gini)',
  },
  { key: 'DecisionTreeClassifier', label: 'Decision Tree Classifier' },
];

const DisplayCoefficientPlotModels = [
  'OLS REGRESSION',
  'LASSO REGRESSION',
  'ARIMAX',
  'SARIMAX',
  'ARIMA',
  'ARDL',
  'GLM',
  'GEE',
  'VECM',
  'BETA REGRESSION',
  'GLS REGRESSION',
  'GLSAR REGRESSION',
  'WLS REGRESSION',
  'Quantile Regression',
];

const DisplayModelSummaryModels = [
  'OLS REGRESSION',
  'LASSO REGRESSION',
  'ARIMAX',
  'SARIMAX',
  'ARIMA',
  'ARDL',
  'GLM',
  'GEE',
  'VECM',
  'BETA REGRESSION',
  'GLS REGRESSION',
  'GLSAR REGRESSION',
  'WLS REGRESSION',
  'Quantile Regression',
];

const BIG_DATA_ONLY_MODEL_TECHNIQUES = [
  'CatboostClassifier',
  'XGBoost_Classifier',
];
const NON_BIG_DATA_ONLY_MODEL_TECHNIQUES = ['LinearSVC'];

const H2O_FILTER_FOR_CONTRIBUTION_PDP = [
  'DistributedRandomForest',
  'GradientBoostingMachine',
  'NaiveBayesClassifier',
  'SupportVectorMachine',
  'XGBoost_Classifier',
  'GeneralizedLinearModel',
  'DeepLearning',
];

export {
  H2Mojo,
  autoGl,
  skL,
  ModelLib,
  R,
  statsModel,
  NonTimeSeriesModelTechinquesMI,
  TimeSeriesModelTechinquesMI,
  GlmGeeModelTechinquesMI,
  VECMModelTechinquesMI,
  SAModals,
  TimeSeriesModels,
  TimeSeriesATModels,
  DisplayModels,
  DisplayModelsInteraction,
  DisplayModelsDTBarPlots,
  DisplayModelsDTDecisionTrees,
  DisplayCoefficientPlotModels,
  DisplayModelSummaryModels,
  BIG_DATA_ONLY_MODEL_TECHNIQUES,
  NON_BIG_DATA_ONLY_MODEL_TECHNIQUES,
  H2O_FILTER_FOR_CONTRIBUTION_PDP,
};
