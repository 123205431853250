import { useState } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../const/ApiEndpoints';
// import useConsole from '../Console/useConsole';
import useProject from '../Project/useProject';
import utils from '../../utils/utils';
import useUDFManager from '../UDFManager/useUDFManager';

const BASE_URL = ApiEndpoints.MODULE_BASE_URL.DOC;

/**
 * @function useDocumentation
 * @description Custom hook for managing documentation-related data and actions.
 * @returns {object} Object containing various functions and state variables related to documentation.
 */
function useDocumentation() {
  const { fetchUdfs, fetchTables, fetchCharts, udfs, setUdfs } =
    useUDFManager();
  const {
    project: { activeProject },
    fetchUserProjects,
    datasetList,
    fetchDatasetList,
    user,
  } = useProject();

  // STATES
  const [docEditorOverview, setDocEditorOverview] = useState(null);
  const [docList, setDocList] = useState([]);
  const [activeDoc, setActiveDoc] = useState(null);
  const [docTableAndCharts, setDocTableAndCharts] = useState(null);
  const [moduleOptiontype, setModuleOptiontype] = useState({});
  const [allTemplate, setAllTemplate] = useState([]);
  const [allTemplateLite, setAllTemplateLite] = useState([]);
  const [activeDocSection, setActiveDocSection] = useState({});
  /**
   * @function fetchDocumentList
   * @description API call to get the documents list
   * @param {number} projectId - data to get documents list
   * @returns {Promise} Promise
   * @example fetchDocumentList();
   */
  const fetchDocumentList = (projectId) => {
    return axios
      .get(BASE_URL + ApiEndpoints.DOC.DOC_LIST_HOME, {
        params: { username: user?.userData?.username, project_id: projectId },
      })
      .then(
        (res) => {
          const allDocs = [];
          // Unpack First then update in store
          Object.keys(res.data).forEach((obj) => {
            allDocs.push(...res.data[obj]);
          });
          setDocList(allDocs);
          res.data = allDocs;
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function fetchDocumentById
   * @description API call to get the documents list
   * @param {number} id - document id to be fetched
   * @returns {Promise} Promise
   * @example fetchDocumentById(id);
   */
  const fetchDocumentById = (id) =>
    axios
      .get(`${BASE_URL + ApiEndpoints.DOC.DOC_LIST_DETAILS}`, {
        params: { document_id: id },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function createNewDoc
   * @description API call to create new document
   * @param {object} body - data to create new document
   * @returns {Promise} Promise
   * @example createNewDoc(body);
   */
  const createNewDoc = (body) =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_CREATE, body).then(
      (res) => {
        setActiveDoc(res.data[0]);
        return res.data[0];
      },
      (error) => {
        throw error;
      }
    );
  /**
   * @function saveAsDoc
   * @description API call to save a document as a new document.
   * @param {object} body - Data representing the document to be saved as a new document.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const saveAsDoc = (body) =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_SAVE_AS, body).then(
      (res) => {
        return res;
      },
      (error) => {
        throw error;
      }
    );

  /**
   * @function updateDoc
   * @description API call to create new document
   * @param {object} body - data to update new document
   * any error happens during the calling of API
   * @returns {Promise} Promise
   * @example updateDoc(data);
   */
  const updateDoc = (body) =>
    axios.post(`${BASE_URL + ApiEndpoints.DOC.DOC_LIST_UPDATE}`, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   * @function fetchEditorOverview
   * @description API call to get editor overview
   * @param {object} body - data needed to get editor overview
   * @returns {Promise} Promise
   * @example fetchEditorOverview({ docID: activeDoc.id });
   */
  const fetchEditorOverview = (body) =>
    axios
      .get(`${BASE_URL + ApiEndpoints.DOC.DOC_LIST_EDIT}`, {
        params: { document_id: body.docID },
      })
      .then(
        (res) => {
          setDocEditorOverview(res.data);
          return res;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function createNode
   * @description API call to create a new node.
   * @param {object} body - Data representing the node to be created.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const createNode = (body) =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_CREATE_NODE, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );
  /**
   * @function updateNode
   * @description API call to update a node.
   * @param {object} body - Data representing the node to be updated.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const updateNode = (body) =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_UPDATE_NODE, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );
  /**
   * @function deleteNode
   * @description API call to delete a node.
   * @param {object} body - Data representing the node to be deleted.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const deleteNode = (body) =>
    axios
      .delete(BASE_URL + ApiEndpoints.DOC.DOC_DELETE_NODE, {
        data: {
          node_id: body?.node_id,
          document_id: body?.document_id,
          node_type: body?.node_type,
        },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );
  /**
   * @function fetchNodeDetails
   * @description API call to fetch details of a node.
   * @param {object} body - Data representing the node whose details are to be fetched.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const fetchNodeDetails = (body) =>
    axios
      .get(BASE_URL + ApiEndpoints.DOC.DOC_NODE_DETAILS, {
        params: {
          node_id: body.node_id,
        },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function saveRearragedNodes
   * @description API call to rearrange nodes
   * @param {object} body
   * @returns {Promise} Promise
   * @example saveRearragedNodes(body);
   */
  const saveRearragedNodes = (body) =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_REARRANGE_NODES, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   * @function fetchDoctableAndImage
   * @description API call to get document table and image
   * @param {object} body - data needed to get document table and image
   * @returns {Promise} AxiosPromise
   * @example fetchDoctableAndImage(data);
   */
  const fetchDoctableAndImage = (body) => {
    const baseUrl = utils.getModuleBaseUrl(body.module, body.sub_module);
    return axios
      .get(`${baseUrl}/get/${body.table_chart}`, {
        params: {
          pid: body.pid,
          sub_module: body.sub_module,
          operation: body.sub_operation,
          dataset_ids: `[${body.dataset}]`,
        },
      })
      .then(
        (res) => {
          setDocTableAndCharts(res.data);
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function fetchDocTablesConsole
   * @description Fetches tables related to documentation from the console.
   * @param {object} params - Parameters for fetching tables.
   * @returns {Promise} A Promise that resolves with the table data if successful, or rejects with an error.
   */
  const fetchDocTablesConsole = (params) => {
    fetchTables(params).then(
      (res) => {
        setDocTableAndCharts(res.data);
        return res;
      },
      (error) => {
        throw error;
      }
    );
  };

  /**
   * @function fetchDocChartsConsole
   * @description Fetches charts related to documentation from the console.
   * @param {object} params - Parameters for fetching charts.
   * @returns {Promise} A Promise that resolves with the chart data if successful, or rejects with an error.
   */
  const fetchDocChartsConsole = (params) => {
    fetchCharts(params).then(
      (res) => {
        setDocTableAndCharts(res.data);
        return res;
      },
      (error) => {
        throw error;
      }
    );
  };

  /**
   * @function deleteDoc
   * @description API call to delete document
   * @param {object} body - data needed to delete document
   * @returns {Promise} AxiosPromise
   * @example deleteDoc(data);
   */
  const deleteDoc = (body) =>
    axios
      .delete(`${BASE_URL + ApiEndpoints.DOC.DOC_LIST_DELETE}`, {
        data: { document_id: body?.document_id },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function saveTemplate
   * @description API call to save template
   * @param {object} body - data needed to save template
   * @returns {Promise} AxiosPromise
   * @example saveTemplate(body);
   */
  const saveTemplate = (body) =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_SAVE_TEMPLATE, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   * @function deleteTemplate
   * @description API call to delete template
   * @param {object} body - data needed to delete template
   * @returns {Promise} AxiosPromise
   * @example deleteTemplate(body);
   */
  const deleteTemplate = (body) =>
    axios
      .delete(BASE_URL + ApiEndpoints.DOC.DOC_SAVE_TEMPLATE, {
        data: { document_id: body },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );
  /**
   * @function saveContentWord
   * @description API call to save word content
   * @param {object} body - data needed to save word content
   * @returns {Promise} AxiosPromise
   * @example saveContentWord(body);
   */
  const saveContentWord = (body) =>
    axios
      .get(`${BASE_URL + ApiEndpoints.DOC.DOC_SAVE_WORDDOC}`, {
        params: {
          document_id: body.docId,
        },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchSharableLink
   * @description API call to get sharable link
   * @param {object} body - data needed to get sharable link
   * @returns {Promise} AxiosPromise
   * @example fetchSharableLink(data);
   */
  const fetchSharableLink = (body) =>
    axios
      .get(`${BASE_URL + ApiEndpoints.DOC.DOC_LIST_CREATE}`, {
        params: {
          type: body.type,
          document_id: body.docId,
        },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchModuleOptionType
   * @description API call to get module option type
   * @returns {Promise} AxiosPromise
   * @example fetchModuleOptionType();
   */
  const fetchModuleOptionType = (projectId) =>
    axios
      .get(BASE_URL + ApiEndpoints.DOC.DOC_MODULE_OPTION_TYPE, {
        params: {
          project_id: projectId,
        },
      })
      .then(
        (res) => {
          setModuleOptiontype(res.data);
          return res;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchAllEditorTemplate
   * @description API call to get all editor overview
   * @returns {Promise} AxiosPromise
   * @example fetchAllEditorTemplate(fetchAllEditorTemplate,successCallback,errorCallback);
   */
  const fetchAllEditorTemplate = (data) => {
    return axios
      .get(BASE_URL + ApiEndpoints.DOC.DOC_ALL_TEMPLATE, {
        params: {
          username: user?.userData?.username,
          document_id: data?.Doc_id,
        },
      })
      .then(
        (res) => {
          setAllTemplate(res.data);
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function fetchAllEditorTemplateLite
   * @description API call to get all editor overview lite version
   * @param {object} body - data needed to get all editor template lite version
   * @returns {Promise} AxiosPromise
   * @example fetchAllEditorTemplateLite(fetchAllEditorTemplateLite,successCallback,errorCallback);
   */

  const fetchAllEditorTemplateLite = () => {
    return axios.get(BASE_URL + ApiEndpoints.DOC.DOC_ALL_TEMPLATE_LITE).then(
      (res) => {
        setAllTemplateLite(res.data);
        return res;
      },
      (error) => {
        throw error;
      }
    );
  };

  /**
   * @function copyTemplate
   * @description API call to copy the template when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example copyTemplate(copyTemplate,successCallback,errorCallback);
   */
  const copyTemplate = () =>
    axios.post(BASE_URL + ApiEndpoints.DOC.DOC_COPY).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   * @function uploadDocumentTemplate
   * @description Uploads a document template.
   * @param {object} payload - The payload containing the document template.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const uploadDocumentTemplate = (payload) =>
    axios
      .post(
        `${BASE_URL + ApiEndpoints.DOC.DOC_DOCUMENTATION_TEMPLATE}`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
        }
      )
      .then(
        (resp) => {
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function deleteDocumentTemplate
   * @description Deletes a document template.
   * @param {object} payload - The payload containing information about the document template to be deleted.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const deleteDocumentTemplate = (payload) =>
    axios
      .delete(
        `${BASE_URL + ApiEndpoints.DOC.DOC_DOCUMENTATION_TEMPLATE}`,
        payload
      )
      .then(
        (resp) => {
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchDocumentTemplate
   * @description Fetches a document template.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const fetchDocumentTemplate = () =>
    axios.get(`${BASE_URL + ApiEndpoints.DOC.DOC_DOCUMENTATION_TEMPLATE}`).then(
      (resp) => {
        return resp;
      },
      (error) => {
        throw error;
      }
    );

  /**
   * @function fetchDocDefaultStylingList
   * @description Fetches the default styling list for documents.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const fetchDocDefaultStylingList = () =>
    axios.get(BASE_URL + ApiEndpoints.DOC.DOC_STYLING_LIST).then(
      (resp) => {
        return resp;
      },
      (error) => {
        throw error;
      }
    );

  return {
    activeDoc,
    datasetList,
    docList,
    activeProject,
    docEditorOverview,
    allTemplate,
    allTemplateLite,
    moduleOptiontype,
    docTableAndCharts,
    activeDocSection,
    setDocGraphTableEmpty() {
      setDocTableAndCharts(null);
    },
    emptyActiveDocument() {
      setActiveDoc(null);
    },
    // functions
    setDocEditorOverview,
    setActiveDocSection,
    fetchUserProjects,
    fetchDatasetList,
    fetchDocumentList,
    fetchDocumentById,
    createNewDoc,
    updateDoc,
    fetchEditorOverview,
    fetchDoctableAndImage,
    fetchAllEditorTemplate,
    fetchAllEditorTemplateLite,
    saveTemplate,
    saveContentWord,
    copyTemplate,
    deleteDoc,
    fetchSharableLink,
    fetchModuleOptionType,
    deleteTemplate,

    // Console
    fetchUdfs,
    udfs,
    fetchDocTablesConsole,
    fetchDocChartsConsole,
    setUdfs,

    // New API Structure
    createNode,
    updateNode,
    saveAsDoc,
    deleteNode,
    fetchNodeDetails,
    saveRearragedNodes,
    uploadDocumentTemplate,
    deleteDocumentTemplate,
    fetchDocumentTemplate,
    fetchDocDefaultStylingList,
  };
}

export default useDocumentation;
