import React from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Slide } from '@mui/material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import NavSection from './NavSection';
import SIDEBAR_CONFIG from './SidebarConfig';
import { ReactComponent as SidebarOpenIcon } from '../../assets/images/NewArrowLeft.svg';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;
const COLLAPSE_WIDTH = 86;

/**
 * @function Sidebar
 * @description The sidebar component for the application
 * @param {object} project The project object for the current project
 * @param {object} userProjectUnreadTask The unread task object for the current project
 * @param {boolean} isSidebarCollapsed The boolean value to check if the sidebar is collapsed
 * @param {function} toggleSidebarWidth The function to toggle the sidebar width between collapsed and expanded
 * @param {object} userPermissions The user permissions object for the current user
 */
export default function Sidebar({
  isSidebarCollapsed,
  toggleSidebarWidth,
  project,
  userPermissions,
  userProjectUnreadTask,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: {
          lg: isSidebarCollapsed ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        },
        ...(!isSidebarCollapsed && {
          position: 'absolute',
        }),
      }}
    >
      <Drawer
        open
        variant="persistent"
        onClose={toggleSidebarWidth}
        PaperProps={{
          sx: {
            width: isSidebarCollapsed ? COLLAPSE_WIDTH : DRAWER_WIDTH,
            background: alpha(theme.palette.secondary.light, 0.38),
            fontSize: '1.1rem',
            fontWeight: 600,
            fontFamily: 'Open Sans',
            overflow: 'inherit',
            border: '1px solid',
            borderColor: theme.palette.secondary.dark,
            transition: '0.2s',
          },
        }}
      >
        <Slide in={true} direction="right" mountOnEnter timeout={700}>
          <Stack
            sx={{
              height: 1,
              marginTop: '65px',
              ...(!isSidebarCollapsed && {
                overflow: 'scroll',
              }),
            }}
          >
            <NavSection
              project={project}
              navConfig={SIDEBAR_CONFIG}
              isShow={!isSidebarCollapsed}
              toggleSidebarWidth={toggleSidebarWidth}
              userPermissions={userPermissions}
              userProjectUnreadTask={userProjectUnreadTask}
            />

            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        </Slide>
        <Box
          component="span"
          sx={{
            width: 30,
            height: 30,
            display: 'flex',
            borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: theme.palette.other.blue2,
            border: '1px solid',
            borderColor: theme.palette.secondary.dark,
            cursor: 'pointer',
            position: 'absolute',
            right: '-15px',
            bottom: '10px',
          }}
          onClick={toggleSidebarWidth}
        >
          {isSidebarCollapsed ? (
            <KeyboardArrowRight
              sx={{
                width: 25,
                height: 25,
                marginTop: '0px',
                cursor: 'pointer',
              }}
            />
          ) : (
            <SidebarOpenIcon
              sx={{
                width: 25,
                height: 25,
                marginTop: '0px',
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  );
}
Sidebar.defaultProps = {
  project: {},
  userProjectUnreadTask: {},
  isSidebarCollapsed: {},
  toggleSidebarWidth: {},
};

Sidebar.propTypes = {
  project: PropTypes.oneOfType([PropTypes.object]),
  userProjectUnreadTask: PropTypes.oneOfType([PropTypes.object]),
  isSidebarCollapsed: PropTypes.bool,
  toggleSidebarWidth: PropTypes.func,
  userPermissions: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
