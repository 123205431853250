export const PodSpecs = [
  {
    value: 'high',
    label: 'High',
    cpu: 4,
    ram: '12 GB',
  },
  {
    value: 'medium',
    label: 'Medium',
    cpu: 3,
    ram: '8 GB',
  },
  {
    value: 'low',
    label: 'Low',
    cpu: 2,
    ram: '4 GB',
  },
];
