/* eslint-disable */
import React from 'react';
import { TextField } from '@mui/material';

/**
 * The NumberField component is a text field that only allows numeric input and displays an error
 * message if the input is invalid.
 * @param props - an object containing the properties passed to the NumberField component.
 * @returns A React functional component called `NumberField`. It takes in props and returns a
 * `TextField` component with additional functionality to restrict input to only numbers, decimal
 * points, and scientific notation characters. It also displays an error message if provided through
 * props.
 */
const NumberField = (props) => {
  const handleKeyDown = (e) => {
    const key = e.key;
    if (!/[\d+eE-]/.test(key)) e.preventDefault();
  };

  return <TextField fullWidth onKeyDown={handleKeyDown} {...props} />;
};

export default NumberField;
