import PropTypes from 'prop-types';
import React from 'react';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { stringShortining } from '../Common/CommonFunc';
import utils from '../../utils/utils';

const PREFIX = 'DocTree';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTreeView = styled(TreeView)({
  [`&.${classes.root}`]: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const NODE_TYPES = {
  SECTION: 'section',
  IMAGE: 'image',
  PARAGRAPH: 'paragraph',
  TABLE: 'table',
  SUBSECTION: 'subsection',
};

/**
 * @function getNodeTitle
 * @description A function to get title of Node
 * @param {object} nodeData - An object which stores data of node
 * @returns {JSX}
 * @example <getNodeTitle nodeData={nodeData} />
 */
const getNodeTitle = (nodeData) => {
  switch (nodeData.node_type) {
    case NODE_TYPES.SECTION:
    case NODE_TYPES.SUBSECTION:
      return stringShortining(nodeData.node_content, 30);
    case NODE_TYPES.TABLE:
      return `Table : ${stringShortining(nodeData.node_heading, 30)}`;
    case NODE_TYPES.IMAGE:
      return `Image : ${stringShortining(nodeData.node_heading, 30)}`;
    case NODE_TYPES.PARAGRAPH:
      if (nodeData.node_heading) {
        return nodeData.node_heading;
      }
      return `${stringShortining(utils.stripTags(nodeData.node_content), 30)}`;
    default:
      break;
  }
};

/**
 * @function renderTree
 * @description to render the tree view
 * @param {object} nodes - with all the children tree data which need to be shown under tree view
 * @param {number} parentNodeId - id of the parent node
 * @param {function} getSelectedID - function to get the id of the selected
 * @returns {JSX} - renderTree UI
 * @example renderTree(data)
 */
const RenderTree = ({ nodes, parentNodeId, getSelectedID }) => {
  const title = React.useMemo(() => getNodeTitle(nodes), [nodes]);
  return (
    <TreeItem
      key={nodes.node_id}
      onClick={() => {
        getSelectedID({
          parent: parentNodeId,
          id: nodes.node_id,
          type: nodes.node_type,
        });
      }}
      nodeId={nodes.node_id}
      label={title}
    >
      {['section', 'subsection', 'root'].includes(nodes.node_type) &&
      Array.isArray(nodes.child)
        ? nodes.child.map((node) => (
            <RenderTree
              key={node.node_id}
              nodes={node}
              parentNodeId={nodes.node_id}
              getSelectedID={getSelectedID}
            />
          ))
        : null}
    </TreeItem>
  );
};

RenderTree.propTypes = {
  nodes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  parentNodeId: PropTypes.number.isRequired,
  getSelectedID: PropTypes.func.isRequired,
};

/**
 * @function RecursiveTreeView
 * @description to display the recursive tree like view for documentation
 * @param {object} data - with all the data which need to be shown as tree view
 * @param {function} getSelectedID - to get the id of the selected
 * @returns {JSX} - RecursiveTreeView UI
 * @example <RecursiveTreeView data={tree} getSelectedID={(val) => getSelected(val)}/>
 */
function RecursiveTreeView({ data, getSelectedID }) {
  return (
    <StyledTreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {data.child.map((node) => (
        <RenderTree
          key={node.node_id}
          nodes={node}
          parentNodeId={-1}
          getSelectedID={getSelectedID}
        />
      ))}
    </StyledTreeView>
  );
}

RecursiveTreeView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getSelectedID: PropTypes.func.isRequired,
};
export default React.memo(RecursiveTreeView);
