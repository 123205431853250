import React from 'react';
import { REGULATIONS } from '../../const/CommonConst';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import {
  AutomatedDocumentationIcon,
  DataManagementIcon,
  EdaIcon,
  FeatureEngineeringIcon,
  ModelEstimationIcon,
  ModelInterpretationIcon,
  ModelTestingIcon,
  ProjectOverViewIcon,
  SamplingAnfSegmentationIcon,
  VariableReductionIcon,
  SettingGearIcon,
  CodePipline,
  // ModelDeploymentIcon,
  Downloading,
  ModelManagerIcon,
  UdfManager,
} from '../../const/CommonIcons';
import { ReactComponent as BxsFile } from '../../assets/images/bxs-file.svg';

const allRegulationKeys = REGULATIONS.map((item) => item.key);
const tsRegulationKeys = REGULATIONS.filter((item) => item.isTs).map(
  (item) => item.key
);
const nonTsRegulationKeys = REGULATIONS.filter((item) => !item.isTs).map(
  (item) => item.key
);
const SIDEBAR_CONFIG = [
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.PO.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.PO.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <ProjectOverViewIcon width={20} height={20} className="not-active-icon" />
    ),
    route: '/project/overview',
    Permissions: [
      'ProjectOverview_CreateProject:Yes',
      'ProjectOverview_EditProject:Yes',
      'ProjectOverview_DeleteProject:Yes',
      'ProjectOverview_ArtifactsSharing:Yes',
      'ProjectOverview_ArchiveProject:Yes',

      'ProjectOverview_CreateProject:No',
      'ProjectOverview_EditProject:No',
      'ProjectOverview_DeleteProject:No',
      'ProjectOverview_ArtifactsSharing:No',
      'ProjectOverview_ArchiveProject:No',
    ],
    children: [],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <DataManagementIcon width={20} height={20} className="not-active-icon" />
    ),
    route: '/data-management',
    permissions: [
      'DataManagement_DataIngestion:View',
      'DataManagement_DataPreparation:View',
      'DataManagement_DataQuality:View',
      'DataManagement_DataValidation:View',
      'DataManagement_Grid:View',
      'DataManagement_ModelUpload:View',

      'DataManagement_DataIngestion:Manage',
      'DataManagement_DataPreparation:Manage',
      'DataManagement_DataQuality:Manage',
      'DataManagement_DataValidation:Manage',
      'DataManagement_Grid:Manage',
      'DataManagement_ModelUpload:Manage',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DI.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DI.key,
        icon: BxsFile,
        route: '/data-management/data-ingestion',
        projectRegulations: allRegulationKeys,
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
        permissions: [
          'DataManagement_DataIngestion:View',
          'DataManagement_DataIngestion:Manage',
        ],
      },
      // {
      //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DQ.label,
      //   icon: BxsFile,
      //   route: '/data-management/quality',
      //   projectRegulations: allRegulationKeys,
      //   projectType: [
      //     'Model development',
      //     'Model validation',
      //     'Model recalibration',
      //   ],
      // },
      // {
      //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DV.label,
      //   icon: BxsFile,
      //   route: '/data-management/validation',
      //   projectRegulations: allRegulationKeys,
      //   projectType: [
      //     'Model development',
      //     'Model validation',
      //     'Model recalibration',
      //   ],
      // },
      {
        name: (
          <span>
            Data Preparation <br /> & Adjustment
          </span>
        ),
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.DPA.key,
        icon: BxsFile,
        route: '/data-management/preparation',
        projectRegulations: allRegulationKeys,
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
        permissions: [
          'DataManagement_DataPreparation:View',
          'DataManagement_DataPreparation:Manage',
        ],
      },
      // {
      //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.MU.label,
      //   key: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.MU.key,
      //   icon: BxsFile,
      //   route: '/data-management/model-upload',
      //   projectRegulations: allRegulationKeys,
      //   // projectType: [
      //   //   'Model monitoring',
      //   //   'Model development',
      //   //   'Model validation',
      //   //   'Model recalibration',
      //   // ],
      //   permissions: [
      //     'DataManagement_ModelUpload:View',
      //     'DataManagement_ModelUpload:Manage',
      //   ],
      // },
    ],
  },

  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: <EdaIcon width={20} height={20} className="not-active-icon" />,
    route: '/eda',

    permissions: [
      'EDA_MissingValuesImputation:View',
      'EDA_PreliminarySteps:View',
      'EDA_StatisticalTests:View',
      'EDA_TimeSeriesAnalysis:View',
      'EDA_TreatingOutliers:View',
      'EDA_VariableAnalysis:View',

      'EDA_MissingValuesImputation:Manage',
      'EDA_PreliminarySteps:Manage',
      'EDA_StatisticalTests:Manage',
      'EDA_TimeSeriesAnalysis:Manage',
      'EDA_TreatingOutliers:Manage',
      'EDA_VariableAnalysis:Manage',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.PS.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.PS.key,
        icon: BxsFile,
        route: '/eda/preliminary-steps',
        projectRegulations: allRegulationKeys,
        permissions: [
          'EDA_PreliminarySteps:View',
          'EDA_PreliminarySteps:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.TO.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.TO.key,
        icon: BxsFile,
        route: '/eda/treating-outliers',
        projectRegulations: allRegulationKeys,
        permissions: [
          'EDA_TreatingOutliers:View',
          'EDA_TreatingOutliers:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: (
          <span>
            Missing Value <br /> Imputation
          </span>
        ),
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.MVI.key,
        icon: BxsFile,
        route: '/eda/missing-values-imputation',
        projectRegulations: allRegulationKeys,
        permissions: [
          'EDA_MissingValuesImputation:View',
          'EDA_MissingValuesImputation:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.VA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.VA.key,
        icon: BxsFile,
        route: '/eda/variable-analysis',
        projectRegulations: allRegulationKeys,
        permissions: [
          'EDA_VariableAnalysis:View',
          'EDA_VariableAnalysis:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.ST.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.ST.key,
        icon: BxsFile,
        route: '/eda/statistical-tests',
        projectRegulations: allRegulationKeys,
        permissions: [
          'EDA_StatisticalTests:View',
          'EDA_StatisticalTests:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.TSA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.EDA.subModules.TSA.key,
        icon: BxsFile,
        route: '/eda/time-series-analysis',
        projectRegulations: tsRegulationKeys,
        permissions: [
          'EDA_TimeSeriesAnalysis:View',
          'EDA_TimeSeriesAnalysis:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
    ],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.FE.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.FE.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <FeatureEngineeringIcon
        width={20}
        height={20}
        className="not-active-icon"
      />
    ),
    route: '/feature-engineering/feature-transformation',
    permissions: [
      'FeatureEngineering_FeatureEngineering:View',
      'FeatureEngineering_FeatureEngineering:Manage',
    ],
    children: [],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.SS.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.SS.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <SamplingAnfSegmentationIcon
        width={20}
        height={20}
        className="not-active-icon"
      />
    ),
    route: '/sampling-segmentation',
    permissions: [
      'SamplingSegmentation_Sampling:View',
      'SamplingSegmentation_Segmentation:View',

      'SamplingSegmentation_Sampling:Manage',
      'SamplingSegmentation_Segmentation:Manage',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SA.key,
        icon: BxsFile,
        route: '/sampling-segmentation/sampling',
        projectRegulations: allRegulationKeys,
        permissions: [
          'SamplingSegmentation_Sampling:View',
          'SamplingSegmentation_Sampling:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SE.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.SS.subModules.SE.key,
        icon: BxsFile,
        route: '/sampling-segmentation/segmentation',
        projectRegulations: allRegulationKeys,
        permissions: [
          'SamplingSegmentation_Segmentation:View',
          'SamplingSegmentation_Segmentation:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
    ],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.VR.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.VR.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <VariableReductionIcon
        width={20}
        height={20}
        className="not-active-icon"
      />
    ),
    route: '/variable-reduction',
    permissions: [
      'VariableReduction_VariableReduction:View',
      'VariableReduction_VariableReduction:Manage',
    ],
    children: [],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.MM.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.MM.key,
    projectRegulations: allRegulationKeys, // [NNG-9937] changing module regulation
    // projectType: [
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <ModelManagerIcon width={20} height={20} className="not-active-icon" />
    ),
    route: '/model-manager',
    permissions: [
      // 'ModelManager:View',
      // 'ModelManager:Manage',
      // 'ModelManager_ModelManager:View',
      // 'ModelManager_ModelManager:Manage',
      'ModelManager_Models:View',
      'ModelManager_Models:Manage',
      'ModelManager_TestCaseManage:View',
      'ModelManager_TestCaseManage:Manage',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MM.subModules.MM.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MM.subModules.MM.key,
        icon: BxsFile,
        route: '/model-manager/models',
        projectRegulations: allRegulationKeys,
        permissions: [
          'ModelTesting_PerformaceTesting:View',
          'ModelTesting_PerformaceTesting:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      // {
      //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.MM.subModules.TCM.label,
      //   key: MODULE_SUB_MODULE_OPERATIONS_TREE.MM.subModules.TCM.key,
      //   icon: BxsFile,
      //   route: '/model-manager/test-case-manager',
      //   projectRegulations: allRegulationKeys,
      //   permissions: [
      //     'ModelEstimation_ModelEstimation:View',
      //     'ModelEstimation_ModelEstimation:Manage',
      //   ],
      //   // projectType: [
      //   //   'Model monitoring',
      //   //   'Model development',
      //   //   'Model validation',
      //   //   'Model recalibration',
      //   // ],
      // },
    ],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.ME.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.ME.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <ModelEstimationIcon width={20} height={20} className="not-active-icon" />
    ),
    route: '/model-estimation',
    permissions: [
      'ModelEstimation_ModelEstimation:View',
      'ModelEstimation_ModelEstimation:Manage',
    ],
    children: [],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],

    icon: (
      <ModelTestingIcon width={20} height={20} className="not-active-icon" />
    ),
    route: '/model-testing',
    permissions: [
      'ModelTesting_BackTesting:View',
      'ModelTesting_Forecasting:View',
      'ModelTesting_ModelComparison:View',
      'ModelTesting_AssumptionTesting:View',
      'ModelTesting_ModelRecaliberation:View',
      'ModelTesting_PerformaceTesting:View',
      'ModelTesting_Fairness:View',
      'ModelTesting_DriftAnalysis:View',
      'ModelTesting_ScoringScaling:View',
      'ModelTesting_SensitivityAnalysis:View',

      'ModelTesting_BackTesting:Manage',
      'ModelTesting_Forecasting:Manage',
      'ModelTesting_ModelRecaliberation:Manage',
      'ModelTesting_PerformaceTesting:Manage',
      'ModelTesting_ScoringScaling:Manage',
      'ModelTesting_AssumptionTesting:Manage',
      'ModelTesting_ModelComparison:Manage',
      'ModelTesting_Fairness:Manage',
      'ModelTesting_DriftAnalysis:Manage',
      'ModelTesting_SensitivityAnalysis:Manage',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.PT.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.PT.key,
        icon: BxsFile,
        route: '/model-testing/performance-testing',
        projectRegulations: allRegulationKeys,
        permissions: [
          'ModelTesting_PerformaceTesting:View',
          'ModelTesting_PerformaceTesting:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.AT.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.AT.key,
        icon: BxsFile,
        route: '/model-testing/assumption-testing',
        projectRegulations: allRegulationKeys,
        permissions: [
          'ModelTesting_AssumptionTesting:View',
          'ModelTesting_AssumptionTesting:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.MC.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.MC.key,
        icon: BxsFile,
        route: '/model-testing/model-comparison',
        projectRegulations: allRegulationKeys,
        permissions: [
          'ModelTesting_ModelComparison:View',
          'ModelTesting_ModelComparison:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.BT.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.BT.key,
        icon: BxsFile,
        route: '/model-testing/back-testing',
        projectRegulations: allRegulationKeys,
        permissions: [
          'ModelTesting_BackTesting:View',
          'ModelTesting_BackTesting:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.SA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.SA.key,
        icon: BxsFile,
        route: '/model-testing/sensitivity-analysis',
        projectRegulations: allRegulationKeys,
        permissions: [
          'ModelTesting_SensitivityAnalysis:View',
          'ModelTesting_SensitivityAnalysis:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.SS.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.SS.key,
        icon: BxsFile,
        route: '/model-testing/scoring-scaling',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelTesting_ScoringScaling:View',
          'ModelTesting_ScoringScaling:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.FA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.FA.key,
        icon: BxsFile,
        route: '/model-testing/fairness',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelTesting_Fairness:View',
          'ModelTesting_Fairness:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.DA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.DA.key,
        icon: BxsFile,
        route: '/model-testing/drift-analysis',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelTesting_DriftAnalysis:View',
          'ModelTesting_DriftAnalysis:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.MR.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.MR.key,
        icon: BxsFile,
        route: '/model-testing/calibiration',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelTesting_ModelRecaliberation:View',
          'ModelTesting_ModelRecaliberation:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.FC.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.FC.key,
        icon: BxsFile,
        route: '/model-testing/forecasting',
        projectRegulations: tsRegulationKeys,
        permissions: [
          'ModelTesting_Forecasting:View',
          'ModelTesting_Forecasting:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
    ],
  },

  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.label,
    key: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.key,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <ModelInterpretationIcon
        width={20}
        height={20}
        className="not-active-icon"
      />
    ),
    route: '/model-interpretation',
    permissions: [
      'ModelInterpretation_DecisionTrees:View',
      'ModelInterpretation_FeatureInterpretation:View',
      'ModelInterpretation_IndividualPrediction:View',
      'ModelInterpretation_TimeSeriesAnalysis:View',

      'ModelInterpretation_DecisionTrees:Manage',
      'ModelInterpretation_FeatureInterpretation:Manage',
      'ModelInterpretation_IndividualPrediction:Manage',
      'ModelInterpretation_TimeSeriesAnalysis:View',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.FI.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.FI.key,
        icon: BxsFile,
        route: '/model-interpretation/feature-interpretation',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelInterpretation_FeatureInterpretation:View',
          'ModelInterpretation_FeatureInterpretation:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.IP.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.IP.key,
        icon: BxsFile,
        route: '/model-interpretation/individual-prediction',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelInterpretation_IndividualPrediction:View',
          'ModelInterpretation_IndividualPrediction:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.DT.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.DT.key,
        icon: BxsFile,
        route: '/model-interpretation/decision-trees',
        projectRegulations: nonTsRegulationKeys,
        permissions: [
          'ModelInterpretation_DecisionTrees:View',
          'ModelInterpretation_DecisionTrees:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.TSA.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.MI.subModules.TSA.key,
        icon: BxsFile,
        route: '/model-interpretation/time-series-analysis',
        projectRegulations: tsRegulationKeys,
        permissions: [
          'ModelInterpretation_TimeSeriesAnalysis:View',
          'ModelInterpretation_TimeSeriesAnalysis:Manage',
        ],
        // projectType: [
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
    ],
  },

  // {
  //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.MD.label,
  //   key: MODULE_SUB_MODULE_OPERATIONS_TREE.MD.key,
  //   projectRegulations: nonTsRegulationKeys, // [NNG-9937] changing module regulation
  //   // projectType: [
  //   //   'Model development',
  //   //   'Model validation',
  //   //   'Model recalibration',
  //   // ],
  //   icon: (
  //     <ModelDeploymentIcon width={20} height={20} className="not-active-icon" />
  //   ),
  //   route: '/model-deployment',
  //   permissions: [
  //     'ModelDeployment:View',
  //     'ModelDeployment:Manage',
  //     'ModelDeployment_ModelDeployment:View',
  //     'ModelDeployment_ModelDeployment:Manage',
  //   ],
  //   children: [],
  // },

  {
    name: 'Automated Documentation',
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <AutomatedDocumentationIcon
        width={20}
        height={20}
        className="not-active-icon"
      />
    ),
    // icon: require("../assets/images/bxs-archive.svg"),
    route: '/documentation/list',
    children: [],
  },
  {
    name: 'UDF Manager',
    key: 'UDF',
    projectRegulations: allRegulationKeys,
    icon: <UdfManager width={20} height={20} className="not-active-icon" />,
    route: '/udf-manager/list',
    children: [],
  },
  {
    name: 'Pipeline Manager',
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: <CodePipline width={20} height={20} className="not-active-icon" />,
    route: '/pipeline/list',
    children: [],
    permissions: ['Pipeline_Pipeline:View', 'Pipeline_Pipeline:Manage'],
  },
  {
    name: 'Downloads',
    projectRegulations: allRegulationKeys,
    icon: <Downloading width={20} height={20} className="not-active-icon" />,
    route: '/downloads',
    children: [],
    permissions: ['Downloads_Downloads:View', 'Downloads_Downloads:Manage'],
  },
  {
    name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.label,
    projectRegulations: allRegulationKeys,
    // projectType: [
    //   'Model monitoring',
    //   'Model development',
    //   'Model validation',
    //   'Model recalibration',
    // ],
    icon: (
      <SettingGearIcon width={20} height={20} className="not-active-icon" />
    ),
    route: '/settings',
    permissions: [
      'Settings_UserManagement:View',
      'Settings_UserManagement:Manage',
      'Settings_Auditability:View',
      'Settings_Auditability:Manage',
      'Settings_Workers:View',
      'Settings_Workers:Manage',
      'Settings_BigData:View',
      'Settings_BigData:Manage',
      'Settings_ConsoleLibrary:View',
      'Settings_ConsoleLibrary:Manage',
      'Settings_DocumentationTemplate:View',
      'Settings_DocumentationTemplate:Manage',
      'Settings_PowerBi:View',
      'Settings_PowerBi:Manage',
      'Settings_OrchestrationManagement:View',
      'Settings_OrchestrationManagement:Manage',
      'Settings_StreamlitDashboard:View',
      'Settings_StreamlitDashboard:Manage',
      // 'Settings_ApisAndIntegrations:View',
      // 'Settings_ApisAndIntegrations:Manage',
    ],
    children: [
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.CP.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.CP.key,
        icon: BxsFile,
        route: '/settings/color-palette',
        projectRegulations: allRegulationKeys,
        projectType: [
          'Model monitoring',
          'Model development',
          'Model validation',
          'Model recalibration',
        ],
        permissions: [
          'Settings_ColorPalette:View',
          'Settings_ColorPalette:Manage',
        ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.UM.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.UM.key,
        icon: BxsFile,
        route: '/settings/user-group-provisioning',
        projectRegulations: allRegulationKeys,
        permissions: [
          'Settings_UserManagement:View',
          'Settings_UserManagement:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.AUD.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.AUD.key,
        icon: BxsFile,
        route: '/settings/auditability',
        projectRegulations: allRegulationKeys,
        permissions: [
          'Settings_Auditability:View',
          'Settings_Auditability:Manage',
        ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.OM.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.OM.key,
        icon: BxsFile,
        route: '/settings/orchestration-management',
        projectRegulations: allRegulationKeys,
        permissions: [
          'Settings_OrchestrationManagement:View',
          'Settings_OrchestrationManagement:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.WM.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.WM.key,
        icon: BxsFile,
        route: '/settings/worker-management',
        projectRegulations: allRegulationKeys,
        permissions: ['Settings_Workers:View', 'Settings_Workers:Manage'],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.BD.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.BD.key,
        icon: BxsFile,
        route: '/settings/big-data',
        projectRegulations: allRegulationKeys,
        permissions: ['Settings_BigData:View', 'Settings_BigData:Manage'],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      // {
      //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.CL.label,
      //   key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.CL.key,
      //   icon: BxsFile,
      //   route: '/settings/console-library',
      //   projectRegulations: allRegulationKeys,
      //   permissions: [
      //     'Settings_ConsoleLibrary:View',
      //     'Settings_ConsoleLibrary:Manage',
      //   ],
      //   // projectType: [
      //   //   'Model monitoring',
      //   //   'Model development',
      //   //   'Model validation',
      //   //   'Model recalibration',
      //   // ],
      // },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.DT.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.DT.key,
        icon: BxsFile,
        route: '/settings/documentation-template',
        projectRegulations: allRegulationKeys,
        permissions: [
          'Settings_DocumentationTemplate:View',
          'Settings_DocumentationTemplate:Manage',
        ],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.PB.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.PB.key,
        icon: BxsFile,
        route: '/settings/power-bi',
        projectRegulations: allRegulationKeys,
        permissions: ['Settings_PowerBi:View', 'Settings_PowerBi:Manage'],
        // projectType: [
        //   'Model monitoring',
        //   'Model development',
        //   'Model validation',
        //   'Model recalibration',
        // ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.SD.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.SD.key,
        icon: BxsFile,
        route: '/settings/streamlit-dashboard',
        projectRegulations: allRegulationKeys,
        permissions: [
          'Settings_StreamlitDashboard:View',
          'Settings_StreamlitDashboard:Manage',
        ],
      },
      {
        name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.ESP.label,
        key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.ESP.key,
        projectRegulations: allRegulationKeys,
        permissions: [
          'Settings_EditSupportPage:View',
          'Settings_EditSupportPage:Manage',
        ],
      },

      // {
      //   name: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.AAI.label,
      //   key: MODULE_SUB_MODULE_OPERATIONS_TREE.ST.subModules.AAI.key,
      //   icon: BxsFile,
      //   route: '/settings/apis-and-integrations',
      //   projectRegulations: allRegulationKeys,
      //   permissions: [
      //     'Settings_ApisAndIntegrations:View',
      //     'Settings_ApisAndIntegrations:Manage',
      //   ],
      // },
    ],
  },
];

export default SIDEBAR_CONFIG;
