import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { Box } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import styled from '@emotion/styled';

const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
];

const tabKeyCode = 9;
const maxDepth = 4;

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

// Custom overrides for "code" style.
const STYLE_MAP = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.other.white2}`,
  fontFamily: 'Georgia, serif',
  fontSize: '14px',
  padding: '15px',

  '& .RichEditor-editor': {
    borderTop: `1px solid ${theme.palette.other.white2}`,
    cursor: 'text',
    fontSize: '16px',
    marginTop: '10px',
  },

  '& .RichEditor-editor .public-DraftEditorPlaceholder-root': {
    margin: '0 - 15px - 15px',
    padding: '15px',
  },
  '& .RichEditor-editor .public-DraftEditor-content': {
    margin: '0 - 15px - 15px',
    padding: '15px',
    minHeight: '100px',
  },
  '& .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root': {
    display: 'none',
  },
  '& .RichEditor-editor .RichEditor-blockquote': {
    borderLeft: `5px solid ${theme.palette.other.white}`,
    color: theme.palette.other.grey3,
    fontFamily: 'Hoefler Text, Georgia, serif',
    fontStyle: 'italic',
    margin: '16px 0',
    padding: '10px 20px',
  },
  '& .RichEditor-editor .public-DraftStyleDefault-pre': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: 'Inconsolata, Menlo, Consolas, monospace',
    fontSize: '16px',
    padding: '20px',
  },
  '& .RichEditor-controls': {
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '14px',
    marginBottom: '5px',
    userSelect: 'none',
  },
  '& .RichEditor-styleButton': {
    color: theme.palette.other.grey4,
    cursor: 'pointer',
    marginRight: '16px',
    padding: '2px 0',
    display: 'inline-block',
  },
  '& .RichEditor-activeButton': {
    color: theme.palette.other.blue5,
  },
}));

/**
 * @function StyleButton
 * @description To style button
 * @param {function} onToggle  - A function which call on Toggle
 * @param {string} style       - A string value which tells the which style to do
 * @param {string} label       - A string value shows the value of styled button
 * @param {bool} active        - A Boolean value
 * @returns {JSX}
 * @example StyleButton onToggle={onToggle}
 */
const StyleButton = ({ onToggle, style, label, active }) => {
  const handleToggle = (e) => {
    e.preventDefault();
    onToggle(style);
  };
  return (
    <span
      role="button"
      tabIndex={-1}
      className={`RichEditor-styleButton${
        active ? '  RichEditor-activeButton' : ''
      }`}
      onMouseDown={handleToggle}
    >
      {label}
    </span>
  );
};

StyleButton.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired,
};

/**
 * @function getBlockStyle
 * @description To get Style of Block
 * @param {object} block  - An object which stores the block style information
 * @returns {string}
 * @example getBlockStyle block={block}
 */
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

/**
 * @function BlockStyleControls
 * @description Block Style Controls
 * @param {object} editorState  - An object which store the state of editor
 * @param {function} onToggle  - A function which handles on Toggle
 * @returns {JSX.Element}
 * @example BlockStyleControls editorState={editorState}
 */
const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

BlockStyleControls.propTypes = {
  editorState: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onToggle: PropTypes.func.isRequired,
};

/**
 * @function InlineStyleControls
 * @description Inline Style Controls
 * @param {object} editorState
 * @param {function} onToggle - A function which handles on Toggle
 * @returns {JSX}
 * @example <InlineStyleControls editorState={editorState} />
 */
const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

InlineStyleControls.propTypes = {
  editorState: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onToggle: PropTypes.func.isRequired,
};

const RichEditor = ({ setContent, content = '' }) => {
  const [initiated, setInitiated] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [className, setClassName] = useState('RichEditor-editor');
  const ref = useRef();
  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
    setContent(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
  };

  useEffect(() => {
    if (initiated || content === '') {
      if (content === '') {
        setEditorState(EditorState.createEmpty());
        setInitiated(false);
      }
    } else {
      const blocksFromHTML = convertFromHTML(content);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(
        EditorState.moveFocusToEnd(EditorState.createWithContent(state))
      );
      setInitiated(true);
    }
  }, [content]);

  /**
   * @function handleKeyCommand
   * @description To handle key Command
   * @param {string} command  - A string value which store the editor value
   * @param {any} newEditorState  - A value which stores the new state of Editor
   * @returns {boolean}
   * @example handleKeyCommand command={command}
   */
  const handleKeyCommand = (command, newEditorState) => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === tabKeyCode) {
      const newEditorState = RichUtils.onTab(e, editorState, maxDepth);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  /**
   * @function toggleBlockType
   * @description To toggle Block Type
   * @param {string} blockType  - A string value which store the block type
   * @returns {void}
   * @example toggleBlockType blockType={blockType}
   */

  const toggleBlockType = useCallback(
    (blockType) => {
      onChange(RichUtils.toggleBlockType(editorState, blockType));
    },
    [editorState, onChange]
  );

  /**
   * @function toggleInlineStyle
   * @description To toggle Inline Style
   * @param {string} inlineStyle  - A string value which store the inline style
   * @returns {void}
   * @example toggleInlineStyle inlineStyle={inlineStyle}
   */
  const toggleInlineStyle = useCallback(
    (inlineStyle) => {
      onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    },
    [editorState, onChange]
  );

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        setClassName('RichEditor-editor RichEditor-hidePlaceholder');
      }
    } else {
      setClassName('RichEditor-editor');
    }
  }, [editorState]);

  const handleClick = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  if (editorState != null) {
    return (
      <StyledBox className="RichEditor-root">
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <Box className={className} onClick={handleClick}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={STYLE_MAP}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder=""
            ref={ref}
            spellCheck={true}
          />
        </Box>
      </StyledBox>
    );
  }
  return null;
};

RichEditor.defaultProps = {
  content: '',
};

RichEditor.propTypes = {
  setContent: PropTypes.func.isRequired,
  content: PropTypes.string,
};

export default RichEditor;
