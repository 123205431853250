/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step, { stepClasses } from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Carousel from 'react-material-ui-carousel';
import { Typography } from '@mui/material';
// @ts-ignore
import { ReactComponent as DatasetIcon } from '../../assets/images/LoginPage/DataIngestion.svg';
import { ReactComponent as ModelUpload } from '../../assets/images/LoginPage/ModelUpload.svg';
import { ReactComponent as AutomatedDocumentation } from '../../assets/images/LoginPage/AutomatedDocumentation.svg';
import { ReactComponent as DataManagement } from '../../assets/images/LoginPage/DataManagement.svg';
import { ReactComponent as SamplingSegmentation } from '../../assets/images/LoginPage/Sampling&Segmentation.svg';
import { ReactComponent as Pipeline } from '../../assets/images/LoginPage/Pipeline.svg';
import { ReactComponent as MetricSelection } from '../../assets/images/LoginPage/MetricSelection.svg';
import { ReactComponent as ModelInterpretation } from '../../assets/images/LoginPage/ModelInterpretation.svg';
import { ReactComponent as ModelEstimation } from '../../assets/images/LoginPage/ModelEstimation.svg';
import { ReactComponent as ModelDevelopmentIcon } from '../../assets/images/LoginPage/ModelDevelopment.svg';
import { ReactComponent as ModelValidationIcon } from '../../assets/images/LoginPage/ModelValidation.svg';
import { ReactComponent as ModelMonitoringIcon } from '../../assets/images/LoginPage/ModelMonitoring.svg';
import { ReactComponent as PreModelingSteps } from '../../assets/images/LoginPage/PreModelingSteps.svg';
import { ReactComponent as ModelPerformanceAndTesting } from '../../assets/images/LoginPage/ModelPerformanceAndTesting.svg';
import { ReactComponent as DefiningThresholdForMonitoringMetrics } from '../../assets/images/LoginPage/DefiningThresholdForMonitoringMetrics.svg';
import { ReactComponent as ModelTestingAndComparison } from '../../assets/images/LoginPage/ModelTestingAndComparison.svg';
import { ReactComponent as MonitoringPipelineAndScheduler } from '../../assets/images/LoginPage/MonitoringPipelineAndScheduler.svg';
import { ReactComponent as SensitivityAndSchenarioAnalysis } from '../../assets/images/LoginPage/SensitivityAndSchenarioAnalysis.svg';
import { ReactComponent as ModelReplicationAndBenchmarking } from '../../assets/images/LoginPage/ModelReplicationAndBenchmarking.svg';
import { ReactComponent as CounterPartyCreditRisk } from '../../assets/images/LoginPage/CounterPartyCreditRisk.svg';
import { ReactComponent as StressTesting } from '../../assets/images/LoginPage/StressTesting.svg';
import { ReactComponent as VarModelling } from '../../assets/images/LoginPage/VarModelling.svg';
import { ReactComponent as LiquidityRisk } from '../../assets/images/LoginPage/LiquidityRisk.svg';
import { ReactComponent as IRRBBReporting } from '../../assets/images/LoginPage/IRRBBReporting.svg';
import { ReactComponent as CryptoPNLModelling } from '../../assets/images/LoginPage/CryptoPNLModelling.svg';
import { ReactComponent as PPNRModelling } from '../../assets/images/LoginPage/PPNRModelling.svg';
import { ReactComponent as CECICalculator } from '../../assets/images/LoginPage/CECICalculator.svg';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: '20px',
  width: 5,
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette?.other?.green1,
      transition: '.5s ease-in',
      // backgroundImage:
      //   'linear-gradient( 95deg,rgba(2,0,36,1) 0%,rgba(60,244,255,1) 50%,rgba(0,212,255,1) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette?.other?.green1,
      transition: '.5s ease-in',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      // backgroundImage:
      //   'linear-gradient( 95deg,rgba(2,0,36,1) 0%,rgba(60,244,255,1) 50%,rgba(0,212,255,1) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 30,
    border: 0,
    backgroundColor: theme.palette?.other?.white,
    borderRadius: 1,
  },
}));

const sliderAndSteps = [
  {
    name: 'Model Development',
    steps: [
      { name: 'Model Development', icon: ModelDevelopmentIcon },
      { name: 'Data Ingestion', icon: DatasetIcon },
      { name: 'Data Management', icon: DataManagement },
      { name: 'Pre-Modelling Steps', icon: PreModelingSteps },
      { name: 'Sampling & Segmentation', icon: SamplingSegmentation },
      { name: 'Model Estimation', icon: ModelEstimation },
      {
        name: 'Model Performance Testing/Outcome Analysis',
        icon: ModelPerformanceAndTesting,
      },
      { name: 'Model Interpretation', icon: ModelInterpretation },
      { name: 'Automated Documentation', icon: AutomatedDocumentation },
    ],
  },
  {
    name: 'Model Monitoring',
    steps: [
      { name: 'Model Monitoring', icon: ModelMonitoringIcon },
      { name: 'Data Ingestion', icon: DatasetIcon },
      { name: 'Model Upload (R/Python/SAS)', icon: ModelUpload },
      { name: 'Monitoring Metrics Selection', icon: MetricSelection },
      {
        name: 'Define Threshold For Monitoring Metrics',
        icon: DefiningThresholdForMonitoringMetrics,
      },
      { name: 'Model Testing/Comparison', icon: ModelTestingAndComparison },
      { name: 'Model Interpretation', icon: ModelInterpretation },
      { name: 'Automated Documentation', icon: AutomatedDocumentation },
      {
        name: 'Monitoring Pipeline/Scheduler',
        icon: MonitoringPipelineAndScheduler,
      },
    ],
  },
  {
    name: 'Model Validation',
    steps: [
      { name: 'Model Validation', icon: ModelValidationIcon },
      { name: 'Data Ingestion', icon: DatasetIcon },
      { name: 'Data Validation/Data Quality Check/EDA', icon: DataManagement },
      { name: 'Model Upload (R/Python/SAS)', icon: ModelUpload },
      {
        name: 'Model Replication/Benchmarking',
        icon: ModelReplicationAndBenchmarking,
      },
      {
        name: 'Model Performance Testing/Outcome Analysis',
        icon: ModelPerformanceAndTesting,
      },
      {
        name: 'Sensitivity & Scenario Analysis',
        icon: SensitivityAndSchenarioAnalysis,
      },
      { name: 'Model Interpretation', icon: ModelInterpretation },
      { name: 'Automated Documentation', icon: AutomatedDocumentation },
    ],
  },
  {
    name: 'Pipeline Manager',
    steps: [
      { name: 'Pipeline Manager', icon: Pipeline },
      { name: 'Counter Party Credit Risk', icon: CounterPartyCreditRisk },
      { name: 'Stress Testing', icon: StressTesting },
      { name: 'VaR Modelling', icon: VarModelling },
      {
        name: 'Liquidity Risk',
        icon: LiquidityRisk,
      },
      {
        name: 'IRRBB Reporting',
        icon: IRRBBReporting,
      },
      {
        name: 'Crypto PNL Modelling',
        icon: CryptoPNLModelling,
      },
      { name: 'CECI Calculator', icon: CECICalculator },
      { name: 'PPNR Modelling', icon: PPNRModelling },
    ],
  },
];

export default function LoginPageAnimation() {
  const [stepCounter, setStepCounter] = React.useState(0);
  const [activeSlide, setActiveSlide] = React.useState(0);
  React.useEffect(() => {
    setTimeout(() => {
      if (stepCounter === 9) {
        setStepCounter(0);
        if (activeSlide >= 3) {
          setActiveSlide(0);
        } else {
          setActiveSlide(activeSlide + 1);
        }
      } else {
        setStepCounter(stepCounter + 1);
      }
    }, 1000);
  }, [stepCounter, activeSlide]);
  return (
    <Stack width="100%" spacing={4}>
      <Carousel
        navButtonsAlwaysInvisible={true}
        autoPlay={false}
        index={activeSlide}
        sx={{ width: '650px', height: '100%' }}
        interval={10000}
        animation="slide"
        indicatorIconButtonProps={{
          style: {
            marginTop: '30px',
          },
        }}
      >
        {sliderAndSteps.map((slide) => (
          <Stepper
            orientation="vertical"
            activeStep={stepCounter}
            connector={<ColorlibConnector />}
            key={slide.name}
          >
            {slide.steps.map((step, stepIndex) => (
              <Step key={step?.name}>
                <StepLabel
                  StepIconComponent={step.icon}
                  sx={(theme) => ({
                    padding: 0,
                    [`.${stepLabelClasses.iconContainer}`]: {
                      backgroundColor: theme.palette.other?.white,
                      zIndex: 1,
                      color: theme?.palette?.other?.brandBlue,
                      width: stepIndex === 0 ? 50 : 35,
                      height: stepIndex === 0 ? 50 : 35,
                      display: 'flex',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: '.5s ease-in',
                      padding: 0,
                      marginLeft: stepIndex === 0 ? 0 : '5px',
                    },
                    [`.${stepClasses.completed}`]: {
                      [`&.${stepLabelClasses.iconContainer}`]: {
                        background: theme.palette?.other?.green1,
                        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
                      },
                    },
                  })}
                >
                  <Typography
                    sx={{
                      color: 'error.contrastText',
                      ml: 2,
                      fontSize: stepIndex === 0 ? '22px' : '18px',
                      fontWeight: 600,
                      fontStyle: 'normal',
                      // marginLeft: stepIndex === 0 ? 2 : 4,
                    }}
                  >
                    {step?.name}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ))}
      </Carousel>
    </Stack>
  );
}
