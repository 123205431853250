import { Grid, Button, Typography, Box } from '@mui/material';
import React from 'react';
import ImgWarning from '../../assets/images/404NotFound.svg';

const NotFound404 = (props) => (
  <Box mt={20}>
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: 'calc(100vh - 200px)' }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <img src={ImgWarning} width="25%" alt="404NotFoundPage" />
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            color="textSecondary"
            align="center"
            style={{ color: '#141A3F', fontSize: '18px', lineHeight: '25px' }}
          >
            Sorry, we couldn’t find the page you are looking for.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
            onClick={() => props.history.push('/')}
            variant="contained"
            color="primary"
          >
            GO BACK TO HOME
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);
export default NotFound404;
