import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Slide,
  Button,
  Zoom,
  Dialog,
  DialogTitle,
  Card,
  Divider,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Editor from '@monaco-editor/react';
import { utils as UTILS } from 'solytics-frontend';
import { withTransaction } from '@elastic/apm-rum-react';
import PropTypes from 'prop-types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DraftEditor from '../../components/Common/DraftEditor';
import DeleteConfirmModal from '../../components/Common/DeleteConfirmModal';
import TableWithComment from '../../components/Common/Table/TableWithComment';
import { SwalToast } from '../../components/Common';
import { ReactComponent as EditIcon } from '../../assets/images/Edit.svg';
import DialogTransition from '../../components/Common/Transitions/DialogTransition';
import utils from '../../utils/utils';
import useModelMonitoringDashboard from '../../Hooks/ModelMonitoringDashboard/useModelMonitoringDashboard';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import { MonitoringRules } from '../../components/ModelTesting/PerformanceTesting';
import { ReactComponent as DownloadIcon } from '../../assets/images/DownloadIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { ReactComponent as CodeIcon } from '../../assets/images/Code.svg';
import { DIALOG_ICON_BUTTON_CSS } from '../../const/CommonConst';

const ColoredCell = (data) => {
  const { cell } = data;
  const color = cell?.value?.color;
  const value = cell?.value?.value?.toString();

  if (color && value) {
    return (
      <Box textAlign="left" bgcolor={color}>
        {UTILS.getFormattedNumber(value)}
      </Box>
    );
  }
  return <Box title={cell?.value}>{UTILS.getFormattedNumber(cell?.value)}</Box>;
};
const editorTheme = 'myThemeDark';
const permissionMessage = 'You are not authorized to access this!';
const noDataAvailableMessage = 'Add data here.';

const fetchTSExistingRules = () => {};

/**
 * @function ModelMonitoringDashboard
 * @description  The function `ModelMonitoringDashboard` renders a dashboard for monitoring
 * model's performance, allowing users to view and edit various aspects of the model's monitoring
 * results.
 * Used to build UI of Performance Testing using useModelMonitoringDashboard() hook
 * @returns {JSX} A React functional component that renders a model monitoring dashboard with various
 * sections such as executive summary, model purpose and use, model data, monitoring results, and
 * conclusion. It also includes functionality for editing and submitting comments for each section, as
 * well as downloading the dashboard as a Word or PDF file.
 * @example <ModelMonitoringDashboard  props/>
 */
const ModelMonitoringDashboard = ({ setOpenDialog, openDialog }) => {
  const {
    project,
    fetchMonitoringResult,
    monitoringResultDetails,
    testingConfig,
    fetchExisistingRules,
  } = useModelMonitoringDashboard();
  const { permissions } = useSelector((state) => state.user);
  const modelMonitoringPermission =
    permissions[
      `${MODULE_SUB_MODULE_OPERATIONS_TREE.PO.key}_${MODULE_SUB_MODULE_OPERATIONS_TREE.PO.subModules.MMD.key}`
    ];
  const [tableAndChartData, setTableAndChartData] = useState({
    key: '',
    type: '',
  });

  const [monitoringResult, setMonitoringResult] = useState({});
  const [editText, seteditText] = useState('');
  const [subSectionHeading, setSubSectionHeading] = useState('');
  const [open, setOpen] = useState(false);
  const [codeSnippet, setCodeSnippet] = useState('');
  const [openCode, setOpenCode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const existingRules = testingConfig?.rules ?? {};
  const existingRulesData = testingConfig?.rule_data ?? [];

  const formikForm = useFormik({
    initialValues: {
      project_id: project.activeProject.project_id,
      execSummaryCmt: '',
      modelPurpAndUseCmt: '',
      modelDataCmt: '',
      conclusionCmt: '',
      monitoringResults: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setTableAndChartData({
        key: '',
        type: '',
      });
      monitoringResultDetails(values)
        .then(() => {
          if (tableAndChartData.type) {
            SwalToast({
              icon: 'success',
              title:
                tableAndChartData.type === 'chart'
                  ? 'Chart deleted successfully.'
                  : 'Table deleted successfully.',
            });
          } else {
            SwalToast({
              icon: 'success',
              title: 'Comment updated successfully.',
            });
          }
        })
        .then(() => {
          fetchMonitoringResult(project.activeProject?.project_id).then(
            (res) => {
              setMonitoringResult(res.data);
            }
          );
        })
        .catch((error) => {
          throw error;
        });
      setOpen(false);
    },
  });
  const monacoRef = useRef(null);

  /**
   * @function handleEditorDidMount
   * @description It will define the theme for the editor
   * @param {object} monaco - Object that contains the monaco editor
   * @returns {void}
   */
  const handleEditorDidMount = (monaco) => {
    monacoRef.current = monaco;
    monaco.editor.defineTheme('myThemeDark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.foreground': '#AECDFC',
        'editor.background': '#141A3F',
        'editorLineNumber.foreground': '#141A3F',
        'editorLineNumber.activeForeground': '#fff',
        'editorGutter.background': '#84afefbf',
        'scrollbarSlider.background': '#AECDFC',
        'scrollbarSlider.activeBackground': '#AECDFC',
        'scrollbarSlider.hoverBackground': '#AECDFC',
      },
    });
  };

  const downloadChartFile = useCallback(
    (url, filename = 'chart') => {
      // if svg
      if (url?.startsWith('data:image/svg+xml;base64')) {
        utils.base64SvgToOtherBase64(url, 'png', (pngData) => {
          const link = document.createElement('a');
          link.href = pngData;
          link.download = `${filename}.png`;
          link.click();
        });
      } else {
        // else png
        const link = document.createElement('a');
        link.href = `data:image/png;base64,${url}`;
        link.download = `${filename}.png`;
        link.click();
      }
    },
    [utils]
  );

  useEffect(() => {
    if (project?.activeProject?.project_id) {
      fetchMonitoringResult(project.activeProject?.project_id).then((res) => {
        setMonitoringResult(res.data);
      });
      fetchExisistingRules({ project_id: project.activeProject.project_id });
    }
  }, [project.activeProject?.project_id]);
  /**
   * @function handleClose
   * @description It will change state from null to false
   * @returns {void}
   * @example handleClose()
   */
  const handleClose = () => {
    setOpen(false);
  };
  /**
   * @function handleCodeBoxOpen
   * @description When the condition satisfied it change the state from null to true
   * @param {string} value
   * @returns {void}
   * @example handleCodeBoxOpen value={value}
   */
  const handleCodeBoxOpen = (value) => {
    setCodeSnippet(value);
    setOpenCode(true);
  };

  /**
   * @function handleCodeBoxClose
   * @description It change the state from null to false
   * @returns {void}
   * @example handleCodeBoxOpen value={value}
   */
  const handleCodeBoxClose = () => {
    setOpenCode(false);
  };

  /**
   * @function onDeleteTableAndChart
   * @description It will set the state of tableAndChartData
   * @param {number} key - It will contain the key of the table or chart
   * @param {string} type - It will contain the type of the table or chart
   * @returns {void}
   * @example onDeleteTableAndChart key={key} type={type}
   */
  const onDeleteTableAndChart = (key, type) => {
    setTableAndChartData({
      key,
      type,
    });
    setShowDeleteModal(true);
  };
  const deleteTableAndChartData = useCallback(() => {
    const { key, type } = tableAndChartData;
    if (type === 'opTableData') {
      monitoringResult?.monitoringResults?.opTables?.splice(key, 1);
      setMonitoringResult({ ...monitoringResult, monitoringResult });
    } else if (type === 'escalationMetrixData') {
      monitoringResult?.monitoringResults?.escalationMetrixData?.splice(key, 1);
      setMonitoringResult({ ...monitoringResult, monitoringResult });
    } else {
      const updatedOpCharts =
        monitoringResult?.monitoringResults?.opCharts?.filter(
          (col) => col.id !== key
        );
      setMonitoringResult({
        ...monitoringResult,
        monitoringResults: {
          ...monitoringResult.monitoringResults,
          opCharts: updatedOpCharts,
        },
      });
    }

    setTimeout(() => {
      formikForm.handleSubmit();
      setShowDeleteModal(false);
    }, 0);
  }, [tableAndChartData]);

  useEffect(() => {
    if (monitoringResult) {
      formikForm.setValues({
        project_id: project.activeProject.project_id,
        execSummaryCmt: monitoringResult.execSummaryCmt,
        modelPurpAndUseCmt: monitoringResult.modelPurpAndUseCmt,
        modelDataCmt: monitoringResult.modelDataCmt,
        conclusionCmt: monitoringResult.conclusionCmt,
        monitoringResults: monitoringResult.monitoringResults,
      });
    }
  }, [monitoringResult, open]);

  const getTitle = (titleData) => {
    let title;

    if (modelMonitoringPermission !== 'Yes') {
      title = permissionMessage;
    } else {
      title = titleData ? 'Edit' : 'Add';
    }

    return title;
  };
  const handleExportToWord = () => {
    utils.exportToWord('exportTofile', 'exportToDoc', '#removeElement');
  };
  const handleExportToPdf = () => {
    utils.pdfDownloadHandler('downloadPdf', 'exportTofile');
  };
  const handleCloseClick = React.useCallback(() => {
    setShowDeleteModal(false);
  }, [setShowDeleteModal]);

  const handleClick = useCallback(
    (editTextValue, subSectionHeadingValue) => {
      setOpen(true);
      seteditText(editTextValue);
      setSubSectionHeading(subSectionHeadingValue);
    },
    [setOpen, seteditText, setSubSectionHeading]
  );
  const handleDeleteChartClick = useCallback(
    (id = '', type = '') => {
      setTableAndChartData({
        key: id,
        type,
      });
      setShowDeleteModal(true);
    },
    [setTableAndChartData, setShowDeleteModal]
  );

  const handleReset = useCallback(() => {
    formikForm.setFieldValue(editText, '');
  }, [editText]);
  const handleModelMonitoringDialog = useCallback(() => {
    setOpenDialog(!openDialog);
  }, [setOpenDialog]);
  return (
    <Grid>
      <Grid item xs={12} marginTop={1}>
        <Box display="flex" alignItems="center">
          <Box px={3} flexGrow={1}>
            <Typography component="h3" variant="h3">
              Model Monitoring Dashboard
            </Typography>
          </Box>
          <Box display="flex" gap={2}>
            <Box>
              <IconButton
                id="removeElement"
                onClick={handleExportToWord}
                title="Download word document"
              >
                <TextSnippetIcon
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                  })}
                />
              </IconButton>
            </Box>
            <Box>
              <IconButton
                id="removeElement"
                onClick={handleExportToPdf}
                title="Download pdf"
              >
                <PictureAsPdfIcon
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                  })}
                />
              </IconButton>
            </Box>
          </Box>
          <Box marginRight={3} marginLeft={5}>
            <IconButton onClick={handleModelMonitoringDialog}>
              <CloseIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box marginTop={2}>
          <Divider />
        </Box>
      </Grid>
      <Grid
        container
        px={3}
        pt={2}
        rowSpacing={4}
        component="div"
        id="exportTofile"
      >
        <Grid item xs={12}>
          <Typography component="h4" variant="h4">
            Executive Summary
          </Typography>
          <Box
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="subtitle2">
                {monitoringResult.execSummaryCmt ? (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: monitoringResult.execSummaryCmt,
                    }}
                  />
                ) : (
                  noDataAvailableMessage
                )}
              </Typography>
            </Box>
            <IconButton
              data-html2canvas-ignore="true"
              size="small"
              onClick={() => handleClick('execSummaryCmt', 'Executive summary')}
              title={getTitle(monitoringResult.execSummaryCmt)}
              color="primary"
              disabled={modelMonitoringPermission !== 'Yes'}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4">
            Model Purpose And Use
          </Typography>
          <Box
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="subtitle2">
                {monitoringResult.modelPurpAndUseCmt ? (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: monitoringResult.modelPurpAndUseCmt,
                    }}
                  />
                ) : (
                  noDataAvailableMessage
                )}
              </Typography>
            </Box>
            <IconButton
              data-html2canvas-ignore="true"
              size="small"
              onClick={() =>
                handleClick('modelPurpAndUseCmt', 'Model purpose and use')
              }
              disabled={modelMonitoringPermission !== 'Yes'}
              title={getTitle(monitoringResult.modelPurpAndUseCmt)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4">
            Model Data
          </Typography>
          <Box
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="subtitle2">
                {monitoringResult.modelDataCmt ? (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: monitoringResult.modelDataCmt,
                    }}
                  />
                ) : (
                  noDataAvailableMessage
                )}
              </Typography>
            </Box>
            <IconButton
              data-html2canvas-ignore="true"
              size="small"
              onClick={() => handleClick('modelDataCmt', 'Model data')}
              disabled={modelMonitoringPermission !== 'Yes'}
              title={getTitle(monitoringResult.modelDataCmt)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Grid>
        {existingRulesData.length > 0 && (
          <Grid item xs={12}>
            <Typography component="h4" variant="h4">
              Monitoring Results
            </Typography>

            <MonitoringRules
              activeProject={project.activeProject}
              fetchExisistingRules={fetchExisistingRules}
              existingRules={existingRules}
              existingRulesData={existingRulesData}
              fetchTSExistingRules={fetchTSExistingRules}
              readOnlyField={true}
              tableTitlePrefix="Threshold -"
            />
          </Grid>
        )}
        <Grid item xs={12} ml={3} mr={3}>
          <Slide direction="up" in={true} timeout={900}>
            <Box my={0}>
              <Grid container spacing={3}>
                {monitoringResult?.monitoringResults?.opCharts?.map(
                  (data, index) => (
                    <Grid key={data?.id} item xs={6} mb={1}>
                      <Zoom in={true}>
                        <Card>
                          <Box
                            display="flex"
                            flexDirection="column"
                            height="100%"
                            py={1}
                            px={1}
                          >
                            <Box display="flex" flexDirection="column">
                              <Box
                                mb={0}
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Box mt={1} ml={1}>
                                  <Typography
                                    component="h4"
                                    variant="h4"
                                    style={{ textTransform: 'capitalize' }}
                                  >
                                    {data?.chart_title}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  flexGrow={1}
                                  data-html2canvas-ignore="true"
                                >
                                  <IconButton
                                    onClick={() =>
                                      downloadChartFile(
                                        data?.url,
                                        data?.chart_title
                                      )
                                    }
                                    color="primary"
                                  >
                                    <DownloadIcon title="Download" />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleCodeBoxOpen(data.code)}
                                  >
                                    <CodeIcon title="Code snippet" />
                                  </IconButton>

                                  <IconButton
                                    onClick={() =>
                                      handleDeleteChartClick(data.id, 'chart')
                                    }
                                    size="small"
                                    disabled={
                                      modelMonitoringPermission !== 'Yes'
                                    }
                                    title={
                                      modelMonitoringPermission !== 'Yes'
                                        ? permissionMessage
                                        : 'Delete'
                                    }
                                    color="primary"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                            <img
                              id="module_monitering"
                              src={
                                data.url &&
                                data.url.includes('data:image/svg+xml;base64')
                                  ? data.url
                                  : `data:image/png;base64, ${data.url}`
                              }
                              alt="Operation result"
                              style={{
                                objectFit: 'contain',
                              }}
                            />
                          </Box>
                        </Card>
                      </Zoom>
                      <Grid
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        mt={1}
                        xs={12}
                      >
                        <Grid item xs={11}>
                          <Typography variant="subtitle2">
                            {monitoringResult?.monitoringResults?.opCharts[
                              index
                            ]?.cmt ? (
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html:
                                    monitoringResult?.monitoringResults
                                      ?.opCharts[index]?.cmt,
                                }}
                                sx={{ display: 'flex', flexWrap: 'wrap' }}
                              />
                            ) : (
                              noDataAvailableMessage
                            )}
                          </Typography>
                        </Grid>
                        <IconButton
                          data-html2canvas-ignore="true"
                          size="small"
                          onClick={() =>
                            handleClick(
                              `monitoringResults.opCharts.${index}.cmt`,
                              data?.chart_title
                            )
                          }
                          disabled={modelMonitoringPermission !== 'Yes'}
                          title={getTitle(
                            monitoringResult?.monitoringResults?.opCharts[index]
                              ?.cmt
                          )}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          </Slide>
        </Grid>
        <Grid item xs={12}>
          <Slide direction="up" in={true} timeout={900}>
            <Box>
              {monitoringResult?.monitoringResults?.escalationMetrixData?.map(
                (data, index) => (
                  <Box mb={2} key={JSON.stringify(index)}>
                    <TableWithComment
                      index={index}
                      type="outputTable"
                      tableData={data}
                      opName="Escalation Metrix"
                      isPaginated={data.is_paginated}
                      customCell={ColoredCell}
                      comment={false}
                      deleteTable={true}
                      permission={modelMonitoringPermission}
                      onDeleteTable={onDeleteTableAndChart}
                      dataType="escalationMetrixData"
                    />
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography variant="subtitle2">
                            {monitoringResult?.monitoringResults
                              ?.escalationMetrixData[index]?.cmt ? (
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html:
                                    monitoringResult?.monitoringResults
                                      ?.escalationMetrixData[index]?.cmt,
                                }}
                              />
                            ) : (
                              noDataAvailableMessage
                            )}
                          </Typography>
                        </Box>
                        <IconButton
                          data-html2canvas-ignore="true"
                          size="small"
                          onClick={() =>
                            handleClick(
                              `monitoringResults.escalationMetrixData.${index}.cmt`,
                              data?.header
                            )
                          }
                          disabled={modelMonitoringPermission !== 'Yes'}
                          title={getTitle(
                            monitoringResult?.monitoringResults
                              ?.escalationMetrixData[index]?.cmt
                          )}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Box>
                )
              )}
            </Box>
          </Slide>
        </Grid>
        <Grid item xs={12}>
          <Slide direction="up" in={true} timeout={900}>
            <Box>
              {monitoringResult?.monitoringResults?.opTables?.map(
                (data, index) => (
                  <Box
                    mb={
                      monitoringResult?.monitoringResults?.opTables?.length -
                        1 ===
                      index
                        ? 2
                        : 5
                    }
                    key={JSON.stringify(index)}
                  >
                    <TableWithComment
                      index={index}
                      type="outputTable"
                      tableData={data}
                      opName="Escalation Metrix"
                      isPaginated={data.is_paginated}
                      customCell={ColoredCell}
                      comment={false}
                      deleteTable={true}
                      permission={modelMonitoringPermission}
                      onDeleteTable={onDeleteTableAndChart}
                      dataType="opTableData"
                      showModelMonitoringDashboardIcon={false} // not to show icon for model monitoring dashboard tables
                    />
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography variant="subtitle2">
                            {monitoringResult?.monitoringResults?.opTables[
                              index
                            ]?.cmt ? (
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html:
                                    monitoringResult?.monitoringResults
                                      ?.opTables[index]?.cmt,
                                }}
                              />
                            ) : (
                              noDataAvailableMessage
                            )}
                          </Typography>
                        </Box>
                        <IconButton
                          data-html2canvas-ignore="true"
                          size="small"
                          onClick={() =>
                            handleClick(
                              `monitoringResults.opTables.${index}.cmt`,
                              data?.header
                            )
                          }
                          disabled={modelMonitoringPermission !== 'Yes'}
                          title={getTitle(
                            monitoringResult?.monitoringResults?.opTables[index]
                              ?.cmt
                          )}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Box>
                )
              )}
            </Box>
          </Slide>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4">
            Conclusion
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="subtitle2">
                {monitoringResult.conclusionCmt ? (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: monitoringResult.conclusionCmt,
                    }}
                  />
                ) : (
                  noDataAvailableMessage
                )}
              </Typography>
            </Box>
            <IconButton
              data-html2canvas-ignore="true"
              size="small"
              onClick={() => handleClick('conclusionCmt', 'Conclusion')}
              disabled={modelMonitoringPermission !== 'Yes'}
              title={getTitle(monitoringResult.conclusionCmt)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Grid>
        <DeleteConfirmModal
          onCloseClick={handleCloseClick}
          open={showDeleteModal}
          onDeleteClick={deleteTableAndChartData}
          confirmationMsg="Are you sure you want to delete?"
          resourceName={tableAndChartData.type === 'chart' ? 'Chart' : 'Table'}
        />
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
          TransitionComponent={DialogTransition}
        >
          <DialogTitle textTransform="capitalize">
            {subSectionHeading}
          </DialogTitle>
          <IconButton
            id="mui_cmp_cust_drag_dialog_title_close_btn"
            className="mui-dialog-close-icon-btn"
            color="primary"
            aria-label="close"
            onClick={handleClose}
            size="large"
            sx={DIALOG_ICON_BUTTON_CSS}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DraftEditor
                  setContent={formikForm.handleChange(editText)}
                  content={editText
                    ?.split('.')
                    ?.reduce((obj, key) => obj?.[key], formikForm.values)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={formikForm.handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openCode}
          onClose={handleCodeBoxClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Code Snippet</DialogTitle>
          <IconButton
            id="mui_cmp_cust_drag_dialog_title_close_btn"
            className="mui-dialog-close-icon-btn"
            color="primary"
            aria-label="close"
            onClick={handleCodeBoxClose}
            size="large"
            sx={DIALOG_ICON_BUTTON_CSS}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Editor
                  height="50vh"
                  defaultLanguage="python"
                  defaultValue={codeSnippet}
                  theme={editorTheme}
                  beforeMount={handleEditorDidMount}
                  options={{
                    padding: {
                      left: 12,
                      right: 12,
                      top: 12,
                      bottom: 12,
                    },
                    readOnly: true,
                    fontSize: 16,
                    lineHeight: 22,
                    fontFamily: 'Open Sans',
                    lineDecorationsWidth: 5,
                    scrollbar: {
                      vertical: 'auto',
                      horizontal: 'auto',
                      verticalScrollbarSize: 5,
                      horizontalScrollbarSize: 5,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

ModelMonitoringDashboard.propTypes = {
  setOpenDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
};
export default withTransaction(
  'ModelMonitoringDashboard',
  'component'
)(ModelMonitoringDashboard);
