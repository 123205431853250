import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  Autocomplete,
  Slide,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import OperationResultsViewer from '../../../Common/OperationResultsViewer';
import { SwalToast } from '../../../Common';
import { DisplayModels } from '../../../../const/ModellingTechniques';
import { ReactComponent as DropdownArrow } from '../../../../assets/images/DropdownArrow.svg';
import utils from '../../../../utils/utils';

/**
 * @function ClassificationPlots
 * @description Classification Plots - child component of Classification Stats - building UI of Classification Stats
 * @param {object} activeDataset - currently active dataset
 * @param {Array} datasetList - list of dataset associated with the active project.
 * @param {object} taskResults - object containing task details like graphs, tables and table ids.
 * @param {object} parameters - parameters of task details, use to repopulate the form fields upon redirection from job queue.
 * @param {object} allModelData - object containing details of all the models associated with the active project.
 * @param {function} onClassificationPlotSubmit - function for making request of classification plots operation.
 * @param {Array} targetVariables - list of target variables present in the active dataset
 * @param {object} activeProject - currently active project.
 * @param {string} permission - contains permission details of the active project.
 * @param {boolean} isImportedInPipeline - boolean value to check if the component is imported in pipeline.
 * @param {function} getOperaionForm - function to get the formik form of the component.
 * @param {function} onAddTableChartClick - function to add table chart in the active project.
 * @param {string} moduleName - name of the module.
 * @param {string} subModuleName - name of the sub module.
 * @returns {JSX} Classification Plots UI
 * @example <ClassificationPlots activeProject={activeProject} activeDataset={activeDataset} taskResults={taskResults}/>
 */
const ClassificationPlots = ({
  activeDataset,
  datasetList,
  taskResults,
  parameters,
  allModelData,
  onClassificationPlotSubmit,
  activeProject,
  permission,
  isImportedInPipeline = false,
  getOperaionForm = (form) => form,
  onAddTableChartClick,
  moduleName,
  subModuleName,
  addResultToModelMetaData,
  fetchModelMetaDataSchema,
}) => {
  const formValidationSchema = Yup.object({
    targetColumn: Yup.string().required('Required'),
    model: Yup.object().shape({
      id: Yup.string().required('Required'),
      model_name: Yup.string().required('Required'),
    }),
    cutoff: Yup.number()
      .min(0, 'Please enter value between 0 to 1.')
      .max(1, 'Please enter value between 0 to 1.')
      .required('Required'),
    bin_size: Yup.number()
      .min(0, 'Please enter value between 0 to 0.5.')
      .max(0.5, 'Please enter value between 0 to 0.5.')
      .required('Required'),
  });

  const formikForm = useFormik({
    initialValues: {
      model: {
        id: '',
        model_name: '',
      },
      targetColumn: '',
      cutoff: '',
      bin_size: '',
    },
    validationSchema: formValidationSchema,

    onSubmit: (values) => {
      const body = {
        dataset_id: activeDataset.id,
        model_id: values.model?.modelId
          ? values.model?.modelId
          : formikForm.values.model.id,
        bin_size: Number(formikForm.values.bin_size),
        cutoff: Number(formikForm.values.cutoff),
        target: formikForm.values.targetColumn,
        multiclass: true,
        project_id: activeProject?.project_id,
      };
      onClassificationPlotSubmit(
        body,

        () => {
          SwalToast({
            icon: 'success',
            title:
              'The operation is scheduled for execution. Please check the job queue for further updates.',
          });
          window.dispatchEvent(new Event('UPDATE_UREAD_JOB_COUNTS'));
        }
      );
    },
  });
  // This is a temporary method for formik re-initialization as formik's
  // method is not working properly due to its deep checking.
  useEffect(() => {
    if (Object.keys(parameters).length !== 0) {
      setTimeout(() => {
        formikForm.setValues({
          model: allModelData.find(
            (model) =>
              model?.id === Number(parameters?.model_id) ||
              model?.modelId === Number(parameters?.model_id) ||
              model?.id === parameters?.model_id ||
              model?.modelId === parameters?.model_id ||
              utils.checkForIds(model?.modelId, parameters?.model_id) ||
              utils.checkForIds(model?.id, parameters?.model_id)
          ),
          targetColumn: parameters?.target,
          cutoff: parameters?.cutoff,
          bin_size: parameters?.bin_size,
        });
      }, 2000);
    }
  }, [parameters, allModelData]);

  useEffect(() => {
    formikForm.handleReset();
  }, [activeDataset]);

  useEffect(() => {
    if (allModelData && formikForm.values.model?.id !== '') {
      allModelData.forEach((data) => {
        if (data.id === formikForm.values.model?.id) {
          formikForm.setFieldValue('targetColumn', data.target_column);
          return data;
        }
        return false;
      });
    }
  }, [datasetList, allModelData, formikForm.values.model]);

  useEffect(() => {
    if (isImportedInPipeline) {
      getOperaionForm(formikForm);
    }
  }, [formikForm, isImportedInPipeline]);

  return (
    <Slide in={true} direction="left" mountOnEnter timeout={700}>
      <Box>
        <Grid
          container
          item
          direction={
            taskResults?.is_chart || taskResults?.is_table ? 'row' : 'column'
          }
          xs={taskResults?.is_chart || taskResults?.is_table ? 12 : 4}
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid item xs={4}>
            <Autocomplete
              options={
                allModelData.length !== 0
                  ? allModelData?.filter(
                      (option) =>
                        DisplayModels.find(
                          (el) =>
                            el.key === option.model_technique ||
                            option.library === 'SAS'
                        ) &&
                        !option?.archive &&
                        option?.status === 'SUCCESS'
                    )
                  : []
              }
              noOptionsText="No models were trained on this dataset"
              value={formikForm.values.model}
              disableClearable={['', undefined].includes(
                formikForm?.values?.model?.model_name
              )}
              getOptionLabel={(option) =>
                option.model_name
                  ? `${option?.model_name} (${option?.model_technique_label}) ${
                      option?.outputFromPrevNode
                        ? `( ${option?.prevNodeName} )`
                        : ''
                    }`
                  : ''
              }
              isOptionEqualToValue={(option, val) => option === val}
              renderOption={(props, option) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                  alignItems="center"
                  {...props}
                  minHeight="unset !important"
                  height="unset !important"
                  key={option.id}
                >
                  <Box display="flex" flexGrow={1} flexDirection="column">
                    <Typography variant="body2">
                      {option.model_name
                        ? `${option.model_name} (${option.model_technique_label})`
                        : ''}
                    </Typography>
                  </Box>
                  {/* for pipeline node */}
                  {option.outputFromPrevNode ? (
                    <Box display="flex" alignSelf="flex-end">
                      <Typography variant="caption">
                        Output from previous node : {option?.prevNodeName}
                      </Typography>
                    </Box>
                  ) : (
                    <Box display="flex" alignSelf="flex-end">
                      <Typography noWrap variant="caption">
                        {option?.external_pkl ? 'External' : 'Internal'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              popupIcon={<DropdownArrow />}
              onChange={(event, newValue) => {
                formikForm.setFieldValue(
                  'model',
                  newValue || {
                    model_name: '',
                    model_technique: '',
                  }
                );
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Model"
                  placeholder="Select model"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="model"
                  onBlur={formikForm.handleBlur}
                  helperText={
                    formikForm?.errors?.model?.model_name &&
                    formikForm?.touched?.model
                      ? formikForm.errors.model?.model_name
                      : null
                  }
                  error={
                    Boolean(formikForm?.errors?.model?.model_name) &&
                    formikForm?.touched?.model
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Bin size *"
              placeholder="Enter in range 0 to 0.5"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                formikForm.setFieldValue(
                  'bin_size',
                  event.target.value === '' ? '' : Number(event.target.value)
                );
              }}
              onBlur={formikForm.handleBlur('bin_size')}
              value={
                formikForm.values.bin_size !== undefined
                  ? formikForm.values.bin_size
                  : ''
              }
              helperText={
                formikForm?.errors?.bin_size && formikForm?.touched?.bin_size
                  ? formikForm.errors.bin_size
                  : null
              }
              error={
                Boolean(formikForm?.errors?.bin_size) &&
                formikForm?.touched?.bin_size
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="number"
              label="Cutoff prediction probability *"
              placeholder="Enter in range 0 to 1"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                formikForm.setFieldValue(
                  'cutoff',
                  event.target.value === '' ? '' : Number(event.target.value)
                );
              }}
              onBlur={formikForm.handleBlur('cutoff')}
              value={
                formikForm.values.cutoff !== undefined
                  ? formikForm.values.cutoff
                  : ''
              }
              helperText={
                formikForm?.errors?.cutoff && formikForm?.touched?.cutoff
                  ? formikForm.errors.cutoff
                  : null
              }
              error={
                Boolean(formikForm?.errors?.cutoff) &&
                formikForm?.touched?.cutoff
              }
            />
          </Grid>
          {!isImportedInPipeline && (
            <Grid container item columnSpacing={2} xs="auto" mt={1}>
              <Grid item xs="auto" display="flex" alignItems="end">
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={formikForm.handleReset}
                  disabled={permission !== 'Manage'}
                  title={
                    permission !== 'Manage'
                      ? 'You are not authorized to access this!'
                      : null
                  }
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs display="flex" alignItems="end">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={formikForm.handleSubmit}
                  disabled={permission !== 'Manage'}
                  title={
                    permission !== 'Manage'
                      ? 'You are not authorized to access this!'
                      : null
                  }
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <OperationResultsViewer
          taskResults={taskResults}
          // activeDataset={activeDataset}
          permission={permission}
          onAddChartClick={onAddTableChartClick}
          onAddTableClick={onAddTableChartClick}
          activeProject={activeProject}
          moduleName={moduleName}
          subModuleName={subModuleName}
          addResultToModelMetaData={addResultToModelMetaData}
          fetchModelMetaDataSchema={fetchModelMetaDataSchema}
        />
      </Box>
    </Slide>
  );
};

ClassificationPlots.defaultProps = {
  isImportedInPipeline: false,
  getOperaionForm: (form) => form,
  fetchModelMetaDataSchema: null,
  addResultToModelMetaData: null,
};

ClassificationPlots.propTypes = {
  activeDataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allModelData: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]).isRequired
  ).isRequired,
  datasetList: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]).isRequired
  ).isRequired,
  onClassificationPlotSubmit: PropTypes.func.isRequired,
  parameters: PropTypes.oneOfType([PropTypes.object]).isRequired,
  taskResults: PropTypes.oneOfType([PropTypes.object]).isRequired,
  permission: PropTypes.string.isRequired,
  isImportedInPipeline: PropTypes.bool,
  getOperaionForm: PropTypes.func,
  moduleName: PropTypes.string.isRequired,
  subModuleName: PropTypes.string.isRequired,
  onAddTableChartClick: PropTypes.func.isRequired,
  addResultToModelMetaData: PropTypes.func,
  fetchModelMetaDataSchema: PropTypes.func,
};

export default ClassificationPlots;
