/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isHelpIconClicked: false,
  isSidebarCollapsed: false,
  isOpen: true,
  projectOverviewListProjectEnableListView: false,
  projectOverviewRegulationFilter: 'All',
  projectOveriewProjectTypeFilter: 'Type (All)',
  createEditUdfTypeFilter: 'Type',
  projectOverviewProjectSearch: '',
  modelManagerListEnableListView: false,
  modelManagerModelSearch: '',
  modelManagerModelTypeFilter: 'Type (All)',
  modelManagerModelStatusFilter: 'Type (All)',
  modelManagerModelFilter: 'Type (All)',
  pipelineListProjectEnableListView: false,
  createEditUdfArgumentsEnableListView: false,
  pipelineProjectSearch: '',
  pipelineProjectCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  usersCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  usersNameSearch: '',
  modelNameSearch: '',
  relatedJobsSearch: '',
  relatedPipelinesSearch: '',
  selectedModel: [],
  groupsCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  groupsNameSearch: '',
  accessLogsCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  consoleFileNameSearch: '',
  auditLogsCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  auditLogsNameSearch: '',
  accessLogsNameSearch: '',
  datasetsNameSearch: '',
  datasetsFilterByProject: {
    value: 'all',
    label: 'All project',
  },
  documentationNameSearch: '',
  documentationProjectTypeFilter: 'all',
  documentationCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  documentationProjectListFilter: {
    value: 'all',
    label: 'All project',
  },
  documentationListProjectEnableListView: true,
  dataManagementProjectListNameSearch: '',
  dataManagementDatasetListNameSearch: '',
  workerListEnableListView: false,
  pipelineExecutionHistoryPipelineNameSearch: '',
  pipelineExecutionHistoryCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  pipelineExecutionHistoryEnableListView: false,
  consoleLibraryNameSearch: '',
  integrationAPITokenSearch: '',
  integrationAPITokenCreatedOnFilter: {
    fromDate: null,
    toDate: null,
  },
  selectedExecutionIdsForComparison: [],
  udfManagerUdfSearch: '',
  helpDocumentKeywordSearch: null,
  helpDrawerKeywordSearch: null,
};

/**
 * @function commonSlice  Common slice reducer.
 * @description Redux slice reducer for common/shared state.
 * @param {initialState} state - Current common state.
 * @param {string} name - Name of the action.
 * @returns {object} commonSlice reducer.
 */
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsHelpIconClicked: (state) => {
      state.isHelpIconClicked = !state.isHelpIconClicked;
    },
    toggleSidebarWidth: (state) => {
      state.isSidebarCollapsed = !state.isSidebarCollapsed;
    },
    toggleProjectOverviewProjectListView: (state) => {
      state.projectOverviewListProjectEnableListView =
        !state.projectOverviewListProjectEnableListView;
    },
    toggleCreateEditUdfArgumentsEnableListView: (state) => {
      state.createEditUdfArgumentsEnableListView =
        !state.createEditUdfArgumentsEnableListView;
    },
    setProjectOverviewRegulationFilter: (state, action) => {
      state.projectOverviewRegulationFilter = action.payload
        ? action.payload
        : initialState.projectOverviewRegulationFilter;
    },
    setCreateEditUdfTypeFilter: (state, action) => {
      state.createEditUdfTypeFilter = action.payload
        ? action.payload
        : initialState.createEditUdfTypeFilter;
    },
    setProjectOverviewProjectTypeFilter: (state, action) => {
      state.projectOveriewProjectTypeFilter = action.payload
        ? action.payload
        : initialState.projectOveriewProjectTypeFilter;
    },
    setProjectOverviewProjectSearch: (state, action) => {
      state.projectOverviewProjectSearch = action.payload;
    },
    toggleModelManagerListView: (state) => {
      state.modelManagerListEnableListView =
        !state.modelManagerListEnableListView;
    },
    setModelManagerModelSearch: (state, action) => {
      state.modelManagerModelSearch = action.payload;
    },
    setModelManagerModelTypeFilter: (state, action) => {
      state.modelManagerModelTypeFilter = action.payload
        ? action.payload
        : initialState.modelManagerModelTypeFilter;
    },
    setModelManagerModelStatusFilter: (state, action) => {
      state.modelManagerModelStatusFilter = action.payload
        ? action.payload
        : initialState.modelManagerModelStatusFilter;
    },
    setModelManagerModelFilter: (state, action) => {
      state.modelManagerModelFilter = action.payload
        ? action.payload
        : initialState.modelManagerModelFilter;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload
        ? action.payload
        : initialState.selectedModel;
    },
    setUdfManagerUdfSearch: (state, action) => {
      state.udfManagerUdfSearch = action.payload;
    },
    togglePipelineProjectListView: (state) => {
      state.pipelineListProjectEnableListView =
        !state.pipelineListProjectEnableListView;
    },
    setPipelineProjectSearch: (state, action) => {
      state.pipelineProjectSearch = action.payload;
    },
    setPipelineProjectCreatedOnFilter: (state, action) => {
      state.pipelineProjectCreatedOnFilter = action.payload
        ? action.payload
        : initialState.pipelineProjectCreatedOnFilter;
    },
    setUsersNameSearch: (state, action) => {
      state.usersNameSearch = action.payload;
    },
    setConsoleFileNameSearch: (state, action) => {
      state.consoleFileNameSearch = action.payload;
    },
    setModelNameSearch: (state, action) => {
      state.modelNameSearch = action.payload;
    },
    setRelatedJobsSearch: (state, action) => {
      state.relatedJobsSearch = action.payload;
    },
    setRelatedPipelinesSearch: (state, action) => {
      state.relatedPipelinesSearch = action.payload;
    },
    setHelpDocumentKeywordSearch: (state, action) => {
      state.helpDocumentKeywordSearch = action.payload;
    },
    setHelpDrawerKeywordSearch: (state, action) => {
      state.helpDrawerKeywordSearch = action.payload;
    },
    setUsersCreatedOnFilter: (state, action) => {
      state.usersCreatedOnFilter = action.payload
        ? action.payload
        : initialState.usersCreatedOnFilter;
    },
    setGroupsNameSearch: (state, action) => {
      state.groupsNameSearch = action.payload;
    },
    setGroupsCreatedOnFilter: (state, action) => {
      state.groupsCreatedOnFilter = action.payload
        ? action.payload
        : initialState.groupsCreatedOnFilter;
    },
    setAccessLogsNameSearch: (state, action) => {
      state.accessLogsNameSearch = action.payload;
    },
    setAccessLogsCreatedOnFilter: (state, action) => {
      state.accessLogsCreatedOnFilter = action.payload
        ? action.payload
        : initialState.accessLogsCreatedOnFilter;
    },
    setAuditLogsNameSearch: (state, action) => {
      state.auditLogsNameSearch = action.payload;
    },
    setAuditLogsCreatedOnFilter: (state, action) => {
      state.auditLogsCreatedOnFilter = action.payload
        ? action.payload
        : initialState.auditLogsCreatedOnFilter;
    },
    setDatasetsNameSearch: (state, action) => {
      state.datasetsNameSearch = action.payload;
    },
    setDatasetsFilterByProject: (state, action) => {
      state.datasetsFilterByProject = action.payload
        ? action.payload
        : initialState.datasetsFilterByProject;
    },
    setDocumentationProjectTypeFilter: (state, action) => {
      state.documentationProjectTypeFilter = action.payload
        ? action.payload
        : initialState.documentationProjectTypeFilter;
    },
    setDocumentationCreatedOnFilter: (state, action) => {
      state.documentationCreatedOnFilter = action.payload
        ? action.payload
        : initialState.documentationCreatedOnFilter;
    },
    setDocumentationProjectListFilter: (state, action) => {
      state.documentationProjectListFilter = action.payload
        ? action.payload
        : initialState.documentationProjectListFilter;
    },
    toggleDocumentationProjectListView: (state) => {
      state.documentationListProjectEnableListView =
        !state.documentationListProjectEnableListView;
    },
    setDocumentationNameSearch: (state, action) => {
      state.documentationNameSearch = action.payload;
    },
    setDataManagementProjectListNameSearch: (state, action) => {
      state.dataManagementProjectListNameSearch = action.payload;
    },
    setDataManagementDatasetListNameSearch: (state, action) => {
      state.dataManagementDatasetListNameSearch = action.payload;
    },
    toggleWorkerListView: (state) => {
      state.workerListEnableListView = !state.workerListEnableListView;
    },
    setPipelineExecutionHistoryPipelineNameSearch: (state, action) => {
      state.pipelineExecutionHistoryPipelineNameSearch = action.payload;
    },
    setPipelineExecutionHistoryCreatedOnFilter: (state, action) => {
      state.pipelineExecutionHistoryCreatedOnFilter = action.payload
        ? action.payload
        : initialState.pipelineExecutionHistoryCreatedOnFilter;
    },
    togglePipelineExecutionHistoryPipelineListView: (state) => {
      state.pipelineExecutionHistoryEnableListView =
        !state.pipelineExecutionHistoryEnableListView;
    },
    setConsoleLibraryNameSearch: (state, action) => {
      state.consoleLibraryNameSearch = action.payload;
    },
    setIntegrationAPITokenSearch: (state, action) => {
      state.integrationAPITokenSearch = action.payload;
    },
    setIntegrationAPITokenCreatedOnFilter: (state, action) => {
      state.integrationAPITokenCreatedOnFilter = action.payload
        ? action.payload
        : initialState.integrationAPITokenCreatedOnFilter;
    },
    setSelectedExecutionIdsForComparison: (state, action) => {
      state.selectedExecutionIdsForComparison = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsHelpIconClicked,
  toggleSidebarWidth,
  toggleProjectOverviewProjectListView,
  toggleCreateEditUdfArgumentsEnableListView,
  setCreateEditUdfTypeFilter,
  setProjectOverviewRegulationFilter,
  setProjectOverviewProjectTypeFilter,
  setProjectOverviewProjectSearch,
  toggleModelManagerListView,
  setModelManagerModelSearch,
  setModelManagerModelTypeFilter,
  setModelManagerModelStatusFilter,
  setModelManagerModelFilter,
  togglePipelineProjectListView,
  setPipelineProjectCreatedOnFilter,
  setPipelineProjectSearch,
  setUsersNameSearch,
  setModelNameSearch,
  setUdfManagerUdfSearch,
  setRelatedJobsSearch,
  setRelatedPipelinesSearch,
  setSelectedModel,
  setHelpDocumentKeywordSearch,
  setHelpDrawerKeywordSearch,
  setUsersCreatedOnFilter,
  setGroupsNameSearch,
  setGroupsCreatedOnFilter,
  setDocumentationCreatedOnFilter,
  setDocumentationProjectListFilter,
  setDocumentationProjectTypeFilter,
  toggleDocumentationProjectListView,
  setDocumentationNameSearch,
  setDataManagementDatasetListNameSearch,
  setDataManagementProjectListNameSearch,
  toggleWorkerListView,
  setPipelineExecutionHistoryPipelineNameSearch,
  setPipelineExecutionHistoryCreatedOnFilter,
  togglePipelineExecutionHistoryPipelineListView,
  setDatasetsNameSearch,
  setDatasetsFilterByProject,
  setConsoleLibraryNameSearch,
  setIntegrationAPITokenSearch,
  setIntegrationAPITokenCreatedOnFilter,
  setAccessLogsNameSearch,
  setAccessLogsCreatedOnFilter,
  setConsoleFileNameSearch,
  setAuditLogsCreatedOnFilter,
  setAuditLogsNameSearch,
  setSelectedExecutionIdsForComparison,
} = commonSlice.actions;

/**
 * Default export of the common slice reducer.
 * @type {Function}
 */
export default commonSlice.reducer;
