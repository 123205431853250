import PropTypes from 'prop-types';
import { Grid, Typography, Box, Link } from '@mui/material';
import React from 'react';
import ImgNoProject from '../../assets/images/NoProjectImage.svg';

/**
 * @function NoProject
 * @description No Project component to display when there is no project
 * @returns {JSX} ProjectsOverview component
 * @example <NoProject history={history} />
 */
const NoProject = ({ user }) => {
  // const { user } = useAuth();
  // const {  } = useProject();
  const showProjectsEvent = new Event('showProjects', { bubbles: true });
  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="calc(100vh - 64px)"
      >
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid container justifyContent="center" alignItems="center">
            <img
              src={ImgNoProject}
              width="186.05"
              height={253.99}
              alt="NoProjectImage"
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="primary.main" align="center">
              Hi,
              {` ${user?.userData?.username}`}, please
              <Link
                onClick={() => {
                  document.dispatchEvent(showProjectsEvent);
                }}
                href="#button"
                style={{ cursor: 'pointer' }}
                underline="hover"
              >
                {' '}
                activate a project{' '}
              </Link>
              or create a new one.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

NoProject.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default NoProject;
