export default {
  sections: [
    {
      sectionFormat: {
        pageWidth: 612,
        pageHeight: 792,
        leftMargin: 72,
        rightMargin: 72,
        topMargin: 72,
        bottomMargin: 72,
        differentFirstPage: false,
        differentOddAndEvenPages: false,
        headerDistance: 36,
        footerDistance: 36,
      },
      blocks: [
        {
          paragraphFormat: {
            afterSpacing: 30,
            styleName: 'Heading 1',
            listFormat: {},
          },
          characterFormat: {},
          inlines: [
            {
              characterFormat: {},
              text: 'Document Loading....',
            },
          ],
        },
      ],
      headersFooters: {
        header: {
          blocks: [
            {
              paragraphFormat: { listFormat: {} },
              characterFormat: {},
              inlines: [],
            },
          ],
        },
        footer: {
          blocks: [
            {
              paragraphFormat: { listFormat: {} },
              characterFormat: {},
              inlines: [],
            },
          ],
        },
      },
    },
  ],
  characterFormat: { fontSize: 11, fontFamily: 'Calibri' },
  paragraphFormat: {
    lineSpacing: 1.0791666507720947,
    lineSpacingType: 'Multiple',
    listFormat: {},
  },
  styles: [
    {
      name: 'Normal',
      type: 'Paragraph',
      paragraphFormat: {
        lineSpacing: 1.149999976158142,
        lineSpacingType: 'Multiple',
        listFormat: {},
      },
      characterFormat: { fontFamily: 'Calibri' },
      next: 'Normal',
    },
    {
      name: 'Default Paragraph Font',
      type: 'Character',
      characterFormat: {},
    },
    {
      name: 'Heading 1 Char',
      type: 'Character',
      characterFormat: {
        fontSize: 16,
        fontFamily: 'Calibri Light',
        fontColor: '#2F5496',
      },
      basedOn: 'Default Paragraph Font',
    },
    {
      name: 'Heading 1',
      type: 'Paragraph',
      paragraphFormat: {
        beforeSpacing: 12,
        afterSpacing: 0,
        outlineLevel: 'Level1',
        listFormat: {},
      },
      characterFormat: {
        fontSize: 16,
        fontFamily: 'Calibri Light',
        fontColor: '#2F5496',
      },
      basedOn: 'Normal',
      link: 'Heading 1 Char',
      next: 'Normal',
    },
    {
      name: 'Heading 2 Char',
      type: 'Character',
      characterFormat: {
        fontSize: 13,
        fontFamily: 'Calibri Light',
        fontColor: '#2F5496',
      },
      basedOn: 'Default Paragraph Font',
    },
    {
      name: 'Heading 2',
      type: 'Paragraph',
      paragraphFormat: {
        beforeSpacing: 2,
        afterSpacing: 6,
        outlineLevel: 'Level2',
        listFormat: {},
      },
      characterFormat: {
        fontSize: 13,
        fontFamily: 'Calibri Light',
        fontColor: '#2F5496',
      },
      basedOn: 'Normal',
      link: 'Heading 2 Char',
      next: 'Normal',
    },
  ],
  lists: [],
  abstractLists: [],
};
