/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// @ts-nocheck
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  ListSubheader,
  Menu,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useCallback } from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

const hundredMiliSecond = 100;

/**
 * @function DropDown
 * @description common component function of dropdown
 * @param {function} onChange - on change function
 * @param {string} label - label of dropdown
 * @param {string} name - name of dropdown
 * @param {string} value - value of dropdown
 * @param {string} helperText - helper text of dropdown
 * @param {boolean} required - required of dropdown
 * @param {string} placeholder - placeholder of dropdown
 * @returns {JSX}
 */
const DropDown = React.memo(
  ({
    label,
    name,
    value,
    onChange,
    helperText,
    children,
    required = false,
    placeholder,
    labelSx,
    ...props
  }) => {
    const theme = useTheme();
    const [labelShrinkState, setLabelShrinkState] = React.useState();
    const [shrinkState, setShrinkState] = React.useState(false);
    const inputLabel = React.useRef();
    const selectRef = React.useRef();
    let observer;
    React.useEffect(() => {
      if (inputLabel?.current) {
        // Select the node that will be observed for mutations
        // const targetNode = document.getElementById('some-id');
        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        // Create an observer instance linked to the callback function
        // eslint-disable-next-line no-shadow, no-unused-vars
        observer = new MutationObserver((mutationList, _observer) => {
          mutationList.forEach((item) => {
            if (
              item?.type === 'attributes' &&
              item?.attributeName === 'data-shrink'
            ) {
              setLabelShrinkState(item?.target?.getAttribute('data-shrink'));
            }
          });
        });

        // Start observing the target node for configured mutations
        observer.observe(inputLabel?.current, config);
      }
      return () => {
        if (observer) {
          try {
            observer.disconnect();
          } catch (e) {
            throw new Error(e);
          }
        }
      };
    }, [inputLabel?.current]);

    const handleOpen = useCallback(() => {
      setShrinkState(true);
    }, [setShrinkState]);

    const handleClose = useCallback(() => {
      setShrinkState(false);
      // this might break with mui version update.
      // changes are done for https://solytics.atlassian.net/browse/NNG-7585
      setTimeout(() => {
        selectRef?.current?.children[0]?.blur();
      }, [hundredMiliSecond]);
    }, [selectRef, setShrinkState, hundredMiliSecond]);

    return (
      <FormControl
        error={Boolean(helperText)}
        variant="outlined"
        sx={{ marginTop: '22px' }}
      >
        <InputLabel
          id="nimbus-select-dropdown-label"
          required={required}
          ref={inputLabel}
          shrink={true}
          sx={{
            transform: 'none',
            top: '-24px',
            ...labelSx,
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="nimbus-select-dropdown-label"
          name={name}
          displayEmpty
          value={value || ''}
          onChange={onChange}
          IconComponent={(_props) => <DropdownArrow {..._props} />}
          renderValue={
            !['', undefined].includes(value)
              ? undefined
              : () => (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      opacity: '0.5',
                    }}
                  >
                    {placeholder}
                  </Typography>
                )
          }
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            style: {
              marginTop: '0.1rem',
            },
            sx: {
              '& .MuiList-root': {
                padding: '6px',
                borderRadius: '4px',
                fontSize: 14,
              },
              '& .MuiPopver-paper': {
                boxShadow: `0px 6px 12px ${alpha(
                  theme.palette.other.lightBrandBlueLogin,
                  0.31
                )}`,
                marginTop: '5px',
              },
              '& .MuiMenu-paper': {
                boxShadow: `0px 6px 12px ${alpha(
                  theme.palette.other.lightBrandBlueLogin,
                  0.31
                )}`,
                marginTop: '5px',
              },
              '& .MuiMenuItem-root': {
                color: alpha(theme.palette.primary.main, 0.5),
                backgroundColor: theme.palette.other.white,
                marginTop: '4px',
                height: '40px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: theme.palette.other.blue1,
                },
                '&:active': {
                  color: theme.palette.other.black,
                  backgroundColor: theme.palette.secondary.main,
                  '&&&:before': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                  '&&:after': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                },
                '&:focus': {
                  backgroundColor: theme.palette.other.blue1,
                },
              },
            },
            // getContentAnchorEl: null,
          }}
          {...props}
          ref={selectRef}
          onOpen={handleOpen}
          onClose={handleClose}
          variant="outlined"
          sx={{
            '& .MuiFilledInput-input': {
              lineHeight: '19.07px',
              fontWeight: '500 !important',
              fontStyle: 'normal',
            },

            '& .MuiSelect-select .notranslate::after':
              // eslint-disable-next-line no-extra-boolean-cast
              placeholder && labelShrinkState === 'true'
                ? {
                    content: `"${placeholder}"`,
                    opacity: 0.42,
                  }
                : {},
            ...props?.sx,
          }}
        >
          {children}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

const DropdownItem = React.memo(({ children, ...props }) => (
  <MenuItem
    {...props}
    sx={(theme) => ({
      '&.Mui-disabled': {
        backgroundColor: theme.palette.other.white,
        WebkitTextFillColor: theme.palette.text.disabled,
        opacity: 1,
      },
    })}
  >
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Typography variant="body2">{children}</Typography>
    </div>
  </MenuItem>
));

const DropdownItemGroup = React.memo(({ children, ...props }) => (
  <ListSubheader {...props}>
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Typography>{children}</Typography>
    </div>
  </ListSubheader>
));

const MultiLevelDropdownItem = React.memo(
  ({ children, nestedItems, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <MenuItem {...props} onClick={handleOpenMenu}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography variant="body2">{children}</Typography>
          </div>
        </MenuItem>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {nestedItems &&
            nestedItems.map((nestedItem) => (
              <MenuItem
                key={nestedItem}
                onClick={handleCloseMenu}
                value={nestedItem.value}
              >
                <Typography variant="body2">{nestedItem.label}</Typography>
              </MenuItem>
            ))}
        </Menu>
      </>
    );
  }
);

export { DropDown, DropdownItem, DropdownItemGroup, MultiLevelDropdownItem };
