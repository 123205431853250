import React from 'react';
import { TextField, IconButton } from '@mui/material';
import SearchIconNew from '../../assets/images/SearchIconNew.svg';

/**
 * It's a custom text field that takes in props and returns a text field with a search icon.
 * @param {object} props - props accept all that support in TextField
 * @returns A React component.
 * @example <TransperentSearchBox placeholder="Search" />
 */

const TransperentSearchBox = (props) => {
  return (
    <TextField
      sx={{
        backgroundColor: 'white',
        opacity: 0.8,
        padding: '0px',
        width: '200px',
        marginTop: '0px',
      }}
      variant="filled"
      InputProps={{
        sx: {
          backgroundColor: 'transparent !important',
          paddingLeft: 1,
          '& .MuiFilledInput-input': {
            paddingTop: '10px',
          },
        },
        startAdornment: (
          <IconButton
            size="small"
            sx={{ marginRight: 1, height: '20px', width: '20px' }}
          >
            <img src={SearchIconNew} alt="search Logo" height={20} width={20} />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

export default TransperentSearchBox;
