/* eslint-disable import/no-extraneous-dependencies */
// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  Fab,
  Dialog,
  Card,
  Zoom,
  Slide,
  // Button,
} from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import TableWithComment from './Table/TableWithComment';
import IframeContainer from '../AllCustomComponents/Iframe/IframeContainer';
import OperationResultsViwerImageContainer from './OperationResultsViewerImageContainer';
import OperationResultsViewerDatasetTable from './OperationResultsViewerDatasetTable';
import DialogTransition from './Transitions/DialogTransition';

const GRID_HALF = 6;
const GRID_FULL = 12;
const SMALL_SPACING = 60;
const LARGE_SPACING = 80;
const MAX_THRESHOLD_FOR_SHOW_MORE_TABLES = 20;

/**
 * @function ConsoleOperationsResultViewer
 * @description To Console Operations Result Viewer
 * @param {object} taskResult   - An object which stores the task Result
 * @param {object} activeDataset - An object the dataset which are active
 * @param {string} operationName - It stores the name of operation
 * @param {any} customCell   - A value which store custom cell data
 * @param {boolean} showCommentBox - A boolean value which decide to show commentBox or not
 * @param {function} fetchTableData - A function to fetch Table data
 * @param {string} permission       - A string value
 * @param {function} downloadTableData - A function to download data of table
 * @param {bool} isPagination     - A boolean value
 * @returns {JSX.Element}
 * @example <ConsoleOperationsResultViewer taskResult={taskResult}/>
 */
const ConsoleOperationsResultViewer = ({
  taskResult = {},
  // activeDataset = {},
  operationName = '',
  customCell,
  showCommentBox = true,
  fetchTableData,
  permission,
  downloadTableData,
  isPagination,
  taskResultDatasets = [],
  fetchDatasetRowsWithPagination = () => {},
  isLoading = false,
  activeProject = {},
  datasetList = [],
  fetchAllDatasets,
  markDatasetAsReadyToUse,
  fetchModelMetaDataSchema,
  addResultToModelMetaData,
  enableComments = true,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [modelMetaDataSchema, setModelMetaDataSchema] = useState(null);
  // const [slicedTables, setSlicedTables] = useState(
  //   taskResult?.tables?.length ? taskResult?.tables?.slice(0, 10) : []
  // );
  // console.log(slicedTables);

  // React.useEffect(() => {
  //   if (taskResult?.tables?.length > MAX_THRESHOLD_FOR_SHOW_MORE_TABLES)
  //     setSlicedTables(
  //       taskResult?.tables?.length ? taskResult?.tables?.slice(0, 10) : []
  //     );
  // }, [taskResult?.tables]);

  // const showMoreTables = React.useCallback(() => {
  //   if (taskResult?.tables?.length) {
  //     setSlicedTables([
  //       ...slicedTables,
  //       ...taskResult.tables.slice(
  //         slicedTables?.length,
  //         slicedTables?.length + 10
  //       ),
  //     ]);
  //   }
  // }, [slicedTables, taskResult?.tables]);

  /**
   * @function handleDialogOpen
   * @description To change the state of DialogOpen from null to true
   * @returns {void}
   * @example handleDialogOpen()
   */
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  /**
   * @function handleDialogClose
   * @description It change the state from null to false
   * @returns {void}
   * @example handleDialogClose
   */
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [activeView, setActiveView] = useState('table');
  useEffect(() => {
    if (taskResult?.is_chart) {
      setActiveView('graph');
    } else if (taskResult?.exceptions) {
      setActiveView('exceptions');
    } else if (taskResult?.is_image) {
      setActiveView('image');
    } else if (taskResult?.is_table) {
      setActiveView('table');
    } else if (taskResult?.isText) {
      setActiveView('text');
    } else if (taskResult?.is_grid) {
      setActiveView('dataset');
    }
  }, [taskResult]);

  const handleExceptionsClick = useCallback(() => {
    setActiveView('exceptions');
  }, [setActiveView]);

  const handleParametersClick = useCallback(() => {
    setActiveView('parameters');
  }, [setActiveView]);

  const handleTableClick = useCallback(() => {
    setActiveView('table');
  }, [setActiveView]);

  const handleGraphClick = useCallback(() => {
    setActiveView('graph');
  }, [setActiveView]);

  const handleImageClick = useCallback(() => {
    setActiveView('image');
  }, [setActiveView]);

  const handleTextClick = useCallback(() => {
    setActiveView('text');
  }, [setActiveView]);

  const getHeight = useCallback(
    (theme) => ({
      height:
        taskResult?.charts?.length > 1
          ? theme.spacing(SMALL_SPACING)
          : theme.spacing(LARGE_SPACING),
    }),
    []
  );

  React.useEffect(() => {
    if (
      // eslint-disable-next-line no-constant-condition
      fetchModelMetaDataSchema &&
      activeProject?.project_id &&
      activeProject?.system_generated_flag &&
      activeProject?.system_name === 'Vault'
    ) {
      fetchModelMetaDataSchema({
        project_id: activeProject?.project_id,
        type: activeProject?.type,
      }).then(
        (resp) => {
          setModelMetaDataSchema(resp?.data);
        },
        () => {
          setModelMetaDataSchema(null);
        }
      );
    }
  }, []);

  const listRef = useRef(null);

  const virtualizer = useVirtualizer({
    count: taskResult?.tables?.length,
    getScrollElement: () => listRef.current,
    estimateSize: React.useCallback(() => 100, []),
    overscan: 1,
    enabled: true,
  });

  const items = virtualizer.getVirtualItems();
  // console.clear();
  // console.log({ items });
  // console.log(virtualizer.getVirtualItems());
  // console.log(taskResult.tables);

  return (
    <Grid container mt="20px">
      <Box
        sx={{
          position: 'fixed',
          bottom: '30px',
          right: ' 30px',
          zIndex: 1000,
        }}
      >
        {taskResult?.is_chart ||
        taskResult?.exceptions ||
        taskResult?.parameters ||
        taskResult?.is_table ||
        taskResult?.is_image ||
        taskResult?.is_grid ||
        taskResult?.isText ? (
          <Slide direction="left" in={true} timeout={900}>
            <Fab
              variant="extended"
              size="small"
              disableRipple={true}
              disableFocusRipple={true}
              sx={{
                padding: 0,
                ':hover': { backgroundColor: 'secondary.main' },
              }}
              color="secondary"
            >
              {taskResult?.exceptions ? (
                <Fab
                  variant="extended"
                  size="small"
                  sx={{
                    boxShadow: activeView === 'exceptions' ? 1 : 0,
                  }}
                  color={activeView === 'exceptions' ? 'primary' : 'secondary'}
                  onClick={handleExceptionsClick}
                >
                  Exceptions
                </Fab>
              ) : null}
              {taskResult?.parameters ? (
                <Fab
                  variant="extended"
                  size="small"
                  sx={{
                    boxShadow: activeView === 'parameters' ? 1 : 0,
                  }}
                  color={activeView === 'parameters' ? 'primary' : 'secondary'}
                  onClick={handleParametersClick}
                >
                  Parameters
                </Fab>
              ) : null}
              {taskResult?.tables?.length && taskResult?.is_table ? (
                <Fab
                  variant="extended"
                  size="small"
                  sx={{
                    boxShadow: activeView === 'table' ? 1 : 0,
                  }}
                  color={activeView === 'table' ? 'primary' : 'secondary'}
                  onClick={handleTableClick}
                >
                  Tables
                </Fab>
              ) : null}
              {taskResultDatasets.length && taskResult?.is_grid ? (
                <Fab
                  variant="extended"
                  size="small"
                  sx={{
                    boxShadow: activeView === 'dataset' ? 'block' : 'none',
                  }}
                  color={activeView === 'dataset' ? 'primary' : 'secondary'}
                  onClick={() => {
                    setActiveView('dataset');
                  }}
                >
                  Dataset
                </Fab>
              ) : null}
              {taskResult?.charts?.length && taskResult?.is_chart ? (
                <Fab
                  variant="extended"
                  sx={{
                    boxShadow: activeView === 'graph' ? 1 : 0,
                  }}
                  color={activeView === 'graph' ? 'primary' : 'secondary'}
                  size="small"
                  onClick={handleGraphClick}
                >
                  Graphs
                </Fab>
              ) : null}
              {taskResult?.images?.length && taskResult?.is_image ? (
                <Fab
                  variant="extended"
                  sx={{
                    boxShadow: activeView === 'image' ? 1 : 0,
                  }}
                  color={activeView === 'image' ? 'primary' : 'secondary'}
                  size="small"
                  onClick={handleImageClick}
                >
                  Images
                </Fab>
              ) : null}
              {taskResult?.texts?.length && taskResult?.isText ? (
                <Fab
                  variant="extended"
                  sx={{
                    boxShadow: activeView === 'text' ? 1 : 0,
                  }}
                  color={activeView === 'text' ? 'primary' : 'secondary'}
                  size="small"
                  onClick={handleTextClick}
                >
                  Text
                </Fab>
              ) : null}
            </Fab>
          </Slide>
        ) : null}
      </Box>

      {/*
				<Box display="flex" alignItems="center" mb={2} mt={3}></Box> */}

      {taskResult && Object.keys(taskResult).length !== 0 && (
        <Box width="100%" pb={5}>
          <Grid container spacing={3}>
            {taskResult?.exceptions ? (
              <Grid
                item
                xs={12}
                style={{
                  display: activeView === 'exceptions' ? 'block' : 'none',
                }}
              >
                <Slide
                  direction="up"
                  in={activeView === 'exceptions'}
                  timeout={900}
                >
                  <Box mt={1} mb={1}>
                    <Card>
                      <Box pt={2} pb={2} pr={3} pl={3}>
                        <Typography>
                          <pre
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordWrap: 'break-word',
                              wordBreak: 'keep-all',
                            }}
                          >
                            {taskResult?.exceptions}
                          </pre>
                        </Typography>
                      </Box>
                    </Card>
                  </Box>
                </Slide>
              </Grid>
            ) : null}

            {taskResult?.texts?.length && taskResult?.isText ? (
              <Slide direction="up" in={activeView === 'text'} timeout={900}>
                <Grid
                  item
                  xs={12}
                  style={{ display: activeView === 'text' ? 'block' : 'none' }}
                >
                  {taskResult?.texts.map((text) => (
                    <Box m={1} key={text.id}>
                      <Card>
                        <Box pt={2} pb={2} pr={3} pl={3}>
                          <Typography>
                            <pre
                              style={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                wordBreak: 'keep-all',
                              }}
                            >
                              {text?.string}
                            </pre>
                          </Typography>
                        </Box>
                      </Card>
                    </Box>
                  ))}
                </Grid>
              </Slide>
            ) : null}

            {taskResult?.tables?.length && taskResult?.is_table ? (
              <Slide direction="up" in={activeView === 'table'} timeout={900}>
                <Grid
                  item
                  xs={12}
                  style={{ display: activeView === 'table' ? 'block' : 'none' }}
                >
                  <Box
                    ref={listRef}
                    sx={{
                      height: '90vh',
                      width: '100%',
                      overflowY: 'auto',
                      contain: 'strict',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                      scrollbarWidth: 'none' /* For Firefox */,
                      '-ms-overflow-style': 'none',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        height: virtualizer.getTotalSize(),
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                      >
                        {items.map((virtualRow) => (
                          <Box
                            key={virtualRow.key}
                            data-index={virtualRow.index}
                            ref={virtualizer.measureElement}
                          >
                            <Box sx={{ padding: '10px 0' }}>
                              <TableWithComment
                                type="outputTable"
                                tableData={taskResult?.tables[virtualRow.index]}
                                opName={operationName || ''}
                                comment={showCommentBox}
                                commentid={
                                  taskResult?.tables[virtualRow.index]?.id
                                }
                                // commenttableName={
                                //   activeDataset?.Name ? activeDataset?.Name : ''
                                // }
                                initialPageSize={
                                  taskResult?.tables?.length >
                                  MAX_THRESHOLD_FOR_SHOW_MORE_TABLES
                                    ? 5
                                    : undefined
                                }
                                customCell={customCell}
                                isConsole
                                fetchTableData={fetchTableData}
                                permission={permission}
                                downloadTableData={downloadTableData}
                                isPaginated={isPagination}
                                modelMetaDataSchema={modelMetaDataSchema}
                                addResultToModelMetaData={
                                  addResultToModelMetaData
                                }
                                activeProject={activeProject}
                                tableId={
                                  taskResult?.tables[virtualRow.index]?.id
                                }
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Slide>
            ) : null}

            {taskResultDatasets?.length && taskResult?.is_grid ? (
              <Slide direction="up" in={activeView === 'dataset'} timeout={900}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: activeView === 'dataset' ? 'block' : 'none',
                  }}
                >
                  <Box>
                    <OperationResultsViewerDatasetTable
                      fetchDatasetRowsWithPagination={
                        fetchDatasetRowsWithPagination
                      }
                      isLoading={isLoading}
                      projectId={activeProject?.project_id}
                      activeProject={activeProject}
                      datasetList={datasetList?.length ? datasetList : []}
                      permissions={permission}
                      taskResultDatasets={taskResultDatasets}
                      fetchAllDatasets={fetchAllDatasets}
                      markDatasetAsReadyToUse={markDatasetAsReadyToUse}
                      enableComments={enableComments}
                    />
                  </Box>
                </Grid>
              </Slide>
            ) : null}

            {taskResult?.charts?.length && taskResult?.is_chart ? (
              <Slide direction="up" in={activeView === 'graph'} timeout={900}>
                <Grid
                  item
                  xs={12}
                  style={{ display: activeView === 'graph' ? 'block' : 'none' }}
                >
                  <Box my={0}>
                    <Grid container spacing={5}>
                      {taskResult?.charts?.map((data) => (
                        <Grid
                          item
                          xs={12}
                          sm={
                            taskResult?.charts?.length > 1
                              ? GRID_HALF
                              : GRID_FULL
                          }
                          key={`graph_${data.id}`}
                        >
                          <Zoom in={true}>
                            <Card sx={getHeight}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                height="100%"
                                pl={1}
                                pr={1}
                                pt={1}
                                pb={1}
                              >
                                <Box display="flex" flexGrow={1}>
                                  <Box sx={{ width: '100%', height: '100%' }}>
                                    <IframeContainer
                                      key={data.id}
                                      url={data.url}
                                      msg={data.msg}
                                      b64={data?.b64}
                                      status={200}
                                      showCommentBox={showCommentBox}
                                      commentid={data.id}
                                      chartHeader={data?.chart_title}
                                      // commenttableName={activeDataset.Name}
                                      commentButtonStyle={{
                                        position: 'absolute',
                                        top: 17,
                                        right: 65,
                                      }}
                                      isConsole
                                      graphData={data}
                                      dialogOpen={dialogOpen}
                                      handleDialogOpen={handleDialogOpen}
                                      setGraphData={setGraphData}
                                      handleDialogClose={handleDialogClose}
                                      permission={permission}
                                      activeProject={activeProject}
                                      modelMetaDataSchema={modelMetaDataSchema}
                                      addResultToModelMetaData={
                                        addResultToModelMetaData
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Card>
                          </Zoom>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Slide>
            ) : null}
            {taskResult?.images?.length && taskResult?.is_image ? (
              <Slide direction="up" in={activeView === 'image'} timeout={900}>
                <Grid
                  item
                  xs={12}
                  style={{ display: activeView === 'image' ? 'block' : 'none' }}
                >
                  <Box my={0}>
                    <Grid container spacing={5}>
                      {taskResult?.images?.map((data) => (
                        <Grid
                          item
                          xs={12}
                          sm={
                            taskResult?.images?.length > 1
                              ? GRID_HALF
                              : GRID_FULL
                          }
                          key={`images_${data.id}`}
                        >
                          <Zoom in={true}>
                            <Card
                              sx={(theme) => ({
                                height:
                                  taskResult?.charts?.length > 1
                                    ? theme.spacing(SMALL_SPACING)
                                    : '100%',
                              })}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                height="100%"
                                pl={1}
                                pr={1}
                                pt={1}
                                pb={1}
                              >
                                <Box display="flex" flexGrow={1}>
                                  <Box sx={{ width: '100%', height: '100%' }}>
                                    <OperationResultsViwerImageContainer
                                      url={data.url}
                                      showCommentBox={showCommentBox}
                                      commentId={data.id}
                                      b64={data?.b64}
                                      // commentTableName={activeDataset.Name}
                                      imageHeader={data?.image_title}
                                      isConsole
                                      imgData={data}
                                      dialogOpen={dialogOpen}
                                      handleDialogOpen={handleDialogOpen}
                                      setImageData={setGraphData}
                                      handleDialogClose={handleDialogClose}
                                      permission={permission}
                                      activeProject={activeProject}
                                      modelMetaDataSchema={modelMetaDataSchema}
                                      addResultToModelMetaData={
                                        addResultToModelMetaData
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Card>
                          </Zoom>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Slide>
            ) : null}

            <Dialog
              fullWidth
              aria-labelledby="customized-dialog-title"
              maxWidth="xl"
              open={dialogOpen}
              TransitionComponent={DialogTransition}
            >
              <Box height={650} flexGrow={1}>
                {graphData?.type === 'chart' ? (
                  <IframeContainer
                    url={graphData.url}
                    msg={graphData.msg}
                    status={200}
                    b64={graphData?.b64}
                    showCommentBox={showCommentBox}
                    commentid={graphData.id}
                    chartHeader={graphData?.chart_title}
                    // commenttableName={activeDataset.Name}
                    isConsole
                    graphData={graphData}
                    dialogOpen={dialogOpen}
                    handleDialogOpen={handleDialogOpen}
                    setGraphData={setGraphData}
                    handleDialogClose={handleDialogClose}
                    permission={permission}
                    activeProject={activeProject}
                    modelMetaDataSchema={modelMetaDataSchema}
                    addResultToModelMetaData={addResultToModelMetaData}
                  />
                ) : (
                  <OperationResultsViwerImageContainer
                    url={graphData.url}
                    b64={graphData?.b64}
                    showCommentBox={showCommentBox}
                    commentid={graphData.id}
                    // commenttableName={activeDataset.Name}
                    isConsole
                    imageHeader={graphData?.image_title}
                    imgData={graphData}
                    dialogOpen={dialogOpen}
                    handleDialogOpen={handleDialogOpen}
                    setImageData={setGraphData}
                    handleDialogClose={handleDialogClose}
                    permission={permission}
                    activeProject={activeProject}
                    modelMetaDataSchema={modelMetaDataSchema}
                    addResultToModelMetaData={addResultToModelMetaData}
                  />
                )}
              </Box>
            </Dialog>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};
ConsoleOperationsResultViewer.defaultProps = {
  customCell: null,
  operationName: '',
  taskResult: {},
  permission: 'Manage',
  downloadTableData: () => ({}),
  isPagination: false,
  taskResultDatasets: [],
  activeProject: {},
  fetchDatasetRowsWithPagination: () => {},
  isLoading: false,
  datasetList: [],
  fetchAllDatasets: () => {},
  markDatasetAsReadyToUse: () => {},
  fetchModelMetaDataSchema: null,
  addResultToModelMetaData: null,
  enableComments: true,
};
ConsoleOperationsResultViewer.propTypes = {
  taskResult: PropTypes.oneOfType([PropTypes.object]),
  // activeDataset: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  //   .isRequired,
  operationName: PropTypes.string,
  customCell: PropTypes.oneOfType([PropTypes.object]),
  showCommentBox: PropTypes.bool.isRequired,
  fetchTableData: PropTypes.func.isRequired,
  permission: PropTypes.string,
  downloadTableData: PropTypes.func,
  isPagination: PropTypes.bool,
  enableComments: PropTypes.bool,
  activeProject: PropTypes.oneOfType([PropTypes.object]),
  taskResultDatasets: PropTypes.oneOfType([PropTypes.array]),
  fetchDatasetRowsWithPagination: PropTypes.func,
  isLoading: PropTypes.bool,
  datasetList: PropTypes.oneOfType([PropTypes.array]),
  fetchAllDatasets: PropTypes.func,
  markDatasetAsReadyToUse: PropTypes.func,
  fetchModelMetaDataSchema: PropTypes.func,
  addResultToModelMetaData: PropTypes.func,
};
export default React.memo(ConsoleOperationsResultViewer);
