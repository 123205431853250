import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTransition from './Transitions/DialogTransition';

/**
 * @function DeleteConfirmModal
 * @description to show the Modal of Delete Node Confirm
 * @param {function} onCloseClick - A function to handle modal close
 * @param {boolean} open - A boolean value
 * @param {function} onDeleteClick - A function on click to handle delete
 * @param {object} confirmationMsg - get title of this modal
 * @param {string} resourceName - type of node
 * @returns {JSX.Element} DeleteConfirmModal UI
 * @example <DeleteConfirmModal onCloseClick={onCloseClick} />
 */
const DeleteConfirmModal = ({
  onCloseClick,
  open,
  onDeleteClick,
  confirmationMsg,
  resourceName,
}) => (
  <Dialog
    open={open}
    aria-labelledby="delete-dialog"
    maxWidth="xs"
    fullWidth
    onClose={onCloseClick}
    TransitionComponent={DialogTransition}
  >
    <DialogTitle> {resourceName ? `Delete ${resourceName}` : ''}</DialogTitle>
    <IconButton
      id="mui-dialog-close-icon-btn"
      color="primary"
      aria-label="close"
      onClick={onCloseClick}
      size="large"
      sx={{
        position: 'absolute',
        right: 16,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent dividers>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="center"
            sx={{ wordWrap: 'break-word' }}
          >
            {confirmationMsg}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {resourceName === 'Project' && (
            <Typography variant="body4">
              <strong>Note:</strong>&nbsp;{' '}
              <i>
                You might lose access to the artifacts like datasets and
                documents in this project. Make sure you have downloaded or
                shared those with other users before proceeding with the
                deletion.
              </i>{' '}
            </Typography>
          )}
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="outlined" onClick={onCloseClick}>
        No
      </Button>
      <Button color="primary" variant="contained" onClick={onDeleteClick}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

DeleteConfirmModal.defaultProps = {
  resourceName: '',
};

DeleteConfirmModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  confirmationMsg: PropTypes.string.isRequired,
  resourceName: PropTypes.string,
};

export default DeleteConfirmModal;
