import { useState } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../const/ApiEndpoints';
import useProject from '../Project/useProject';

const MT_BASE_URL = ApiEndpoints.MODULE_BASE_URL.MT;

/**
 * @function useModelMonitoringDashboard
 * @description hook for backtesting submodule of model testing module
 * @returns {object} - returning multiple methods and states for
 *  backtesting submodule of model testing module
 * @example const {fetchModuleTasks,fetchDatasetList,datasetList}=useModelMonitoringDashboard()
 */
export default function useModelMonitoringDashboard() {
  const { project } = useProject();
  const [monitoringResult, setMonitoringResult] = useState({});
  const [testingConfig, setTestingConfig] = useState({});

  /**
   * @function MonitoringResultDetails
   * @description API call for fetching monitoring result details
   * @param {object} datasetName - object containing project_id and complete chnaged data
   * @returns {Promise} AxiosPromise
   * @example MonitoringResultDetails(datasetName);
   */
  const monitoringResultDetails = (datasetName) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.MT +
          ApiEndpoints.MMD.MONITORING_DASHBOARD
        }`,
        datasetName
      )
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchMonitoringResult
   * @description API call for fetching asynchronous result
   *  using task id, result will contain date like graph and charts
   * @param {string} projectId - task id
   * @returns {Promise} AxiosPromise
   * @example fetchMonitoringResult(projectId);
   */

  const fetchMonitoringResult = (projectId) => {
    return axios
      .get(
        `${MT_BASE_URL}${ApiEndpoints.MMD.MONITORING_DASHBOARD}?project_id=${projectId}`
      )
      .then(
        (res) => {
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };
  /**
   * @function fetchExisistingRules
   * @description API call to fetch ExisistingRules data from server
   * @param {object} body -data for ExisistingRules operation result
   *  when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example fetchExisistingRules(body, errorCallback);
   */
  const fetchExisistingRules = (body) =>
    axios
      .get(
        `${
          MT_BASE_URL +
          ApiEndpoints.MT.MT_PT_FETCH_EXISISTING_RULES +
          body.project_id
        }`
      )
      .then(
        (res) => {
          setTestingConfig(res.data);
        },
        (error) => {
          throw error;
        }
      );

  return {
    project,
    fetchMonitoringResult,
    monitoringResult,
    setMonitoringResult,
    monitoringResultDetails,
    testingConfig,
    fetchExisistingRules,
  };
}
