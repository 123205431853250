import React from 'react';
import { useLocation } from 'react-router-dom';
/**
 * Custom hook to retrieve and manage query parameters from the current URL.
 * @function useQueryParams
 * @returns {URLSearchParams} - An instance of URLSearchParams containing the query parameters from the current URL.
 * @example
 * const queryParams = useQueryParams();
 * const paramValue = queryParams.get('paramName');
 */
export default function useQueryParams() {
  /**
   * Retrieves and manages query parameters from the current URL.
   * @function
   * @returns {URLSearchParams} - An instance of URLSearchParams containing the query parameters from the current URL.
   */
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
