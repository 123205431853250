// @ts-nocheck
import { Grid, styled } from '@mui/material';
import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { withTransaction } from '@elastic/apm-rum-react';
import SwalToast from '../Common/SwalToast';
// import REGEX from '../../const/Regex';

const JoditEditorCustom = styled(JoditEditor)(({ theme }) => ({
  [`
  & .jodit-toolbar-button_text-icons_true button`]: {
    padding: '0 8px',
    backgroundColor: `${theme?.palette?.primary?.main}`,
    color: 'white',
    fontSize: 'medium',
    width: '63px',
    height: ' 40px',
    borderRadius: ' 6px',
    '&:hover': {
      backgroundColor: `${theme?.palette?.primary?.main}`,
    },
  },
  [`
  & .jodit-ui-spacer`]: {
    flex: '0',
  },
}));

/**
 * Custom styled Jodit editor dialog component.
 * @param {object} props - The props passed to the component.
 * @param {boolean} props.showJoditEditor - Flag indicating whether to show the Jodit editor.
 * @param {string} props.initialContent - The initial content of the editor.
 * @param {function} props.setEditorContent - Function to set the content of the editor.
 * @param {function} props.setSaveButtonClicked - Function to set the save button clicked state.
 * @returns {JSX.Element} JSX element representing the Jodit editor dialog.
 */
const JoditEditorDialog = ({
  showJoditEditor,
  initialContent,
  setEditorContent,
  setSaveButtonClicked,
}) => {
  const config = useMemo(
    () => ({
      style: {
        fontFamily: 'Open Sans, sans-serif',
        fontWeight: '400',
      },
      height: '100vh',
      hidePoweredByJodit: true,
      enableDragAndDropFileToEditor: true,

      spellcheck: true,

      toolbar: true,

      colorPickerDefaultTab: 'background',
      imageDefaultWidth: 100,
      toolbarSticky: true,

      toolbarContainer: '#custom-toolbar-container',
      workplace: '#custom-editor-container',
      removeButtons: [
        'about',
        'file',
        'ai-assistant',
        'ai-commands',
        'copyformat',
        'classSpan',
      ],
      fonts: {
        'Open Sans': 'Open Sans, sans-serif',
      },
      uploader: {
        insertImageAsBase64URI: true,
      },
    }),
    []
  );
  const editor = useRef(null);

  /**
   * Callback function to fix HTML tags in the content.
   * @param {string} htmlContent - The HTML content to fix.
   * @returns {void}
   */
  const fixHTMLTags = useCallback(
    (htmlContent) => {
      try {
        const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
        const fixedHTML = new XMLSerializer().serializeToString(
          doc.documentElement
        );
        setEditorContent(fixedHTML);
      } catch (error) {
        SwalToast({
          icon: 'error',
          title: error?.msg,
        });
      }
    },

    [setEditorContent]
  );

  /**
   * Callback function to update the content of the editor.
   * @param {string} newContent - The new content of the editor.
   * @returns {void}
   */
  const updateContent = useCallback(
    (newContent) => {
      const updatedContent = newContent.replace(
        /<td(.*?)style="(.*?)">(.*?)<\/td>/g,
        (match, p1, p2, p3) => {
          if (p2 && /^border(-\w*)?:\s*[\w\s#]+;$/.test(p2)) {
            return `<td${p1}style="${p2};border-bottom: 1px solid #84AFEF;">${p3}</td>`;
          }

          return `<td${p1}style="border-bottom: 1px solid #84AFEF;">${p3}</td>`;
        }
      );
      fixHTMLTags(updatedContent);
      setSaveButtonClicked(true);
    },
    [setSaveButtonClicked]
  );

  return (
    showJoditEditor && (
      <Grid container item>
        <JoditEditorCustom
          ref={editor}
          value={initialContent}
          config={{
            ...config,
            extraButtons: [
              {
                name: 'Save',
                icon: 'fa fa-save',

                exec: () => {
                  updateContent(editor.current.value);
                },
              },
            ],
            onChange: null,
          }}
        />
      </Grid>
    )
  );
};

JoditEditorDialog.defaultProps = {
  initialContent: '',
};
JoditEditorDialog.propTypes = {
  showJoditEditor: PropTypes.bool.isRequired,
  setEditorContent: PropTypes.func.isRequired,
  setSaveButtonClicked: PropTypes.func.isRequired,
  initialContent: PropTypes.string,
};

export default withTransaction(
  'JoditEditorDialog',
  'component'
)(JoditEditorDialog);
