// @ts-nocheck
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  NavLink as RouterLink,
  useLocation,
  useHistory,
} from 'react-router-dom';
// material
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Tooltip,
  Badge,
} from '@mui/material';

import AccessControl from '../AccessControl';
import { ReactComponent as SubMenuCollapasedIcon } from '../../assets/images/NewArrowDown.svg';
import { ReactComponent as SubMenuOpenIcon } from '../../assets/images/NewArrowUp.svg';
import useHelpSection from '../../Hooks/HelpSection/useHelpSection';

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  height: 45,
  position: 'relative',
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(0.3),
  marginRight: theme.spacing(0.5),
  paddingLeft: theme.spacing(1.75),
  paddingRight: theme.spacing(1.75),
  color: theme.palette.primary.main,
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '19.07px',
  '&:hover': {
    background: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
    borderRadius: '4px',
  },
}));

const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  width: 30,
  height: 30,
  transform: 'scale(1.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.other.lightBrandBlueLogin,
  fill: theme.palette.other.lightBrandBlueLogin,
  minWidth: 0,
}));

function NavItem({
  item,
  isShow,
  toggleSidebarWidth,
  project,
  subMouduleUnreadTask,
}) {
  const { setIsUserSuperadmin } = useHelpSection();
  const theme = useTheme();
  const history = useHistory();

  const { pathname } = useLocation();
  const { name, route, icon, info, children } = item;
  const isActiveRoot =
    `/${pathname.split('/')?.[1]}` === route || pathname === route;

  const handleOnClick = useCallback((itemObject, path) => {
    if (itemObject?.key === 'EditSupportPage') {
      const url = history.createHref({ pathname: path });
      setIsUserSuperadmin();
      setTimeout(() => {
        window.open(url, '_blank');
      }, 1200);
    }
  }, []);

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
    if (!isShow) {
      toggleSidebarWidth();
    }
  };

  const handleClick = () => {
    if (!isShow) {
      toggleSidebarWidth();
    }
  };

  const activeRootStyle = {
    color: theme.palette.primary.dark,
    fontWeight: '600',
    bgcolor: theme.palette.secondary.light,
    borderRadius: '4px',
    '&:before': { display: 'block' },
    borderLeft: 'solid',
    svg: {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  };

  const activeSubStyle = {
    color: theme.palette.primary.dark,
    fontWeight: '700',
  };

  if (children.length) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          component={RouterLink}
          to={!isShow ? children[0]?.route : {}}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            ...(!isShow && {
              alignItems: 'center',
              justifyContent: 'center',
            }),
          }}
          // {...(!isShow ? { to: children[0]?.route } : {})}
        >
          <Tooltip title={name} placement="right">
            <ListItemIconStyle
              size="large"
              sx={{
                ...(isShow && {
                  marginRight: theme.spacing(1.5),
                }),
              }}
            >
              {icon}
            </ListItemIconStyle>
          </Tooltip>

          {isShow && (
            <>
              <ListItemText disableTypography primary={name} />
              {info}
              <Box
                component={open ? SubMenuOpenIcon : SubMenuCollapasedIcon}
                sx={{
                  width: 12,
                  height: 12,
                  ml: 1,
                  color: theme.palette.secondary.dark,
                }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children
                .filter((data) => {
                  if (
                    // data.projectType.includes(project.activeProject?.type) &&
                    data.projectRegulations.includes(
                      project.activeProject?.regulation
                    )
                  ) {
                    return data;
                  }
                  if (!project.activeProject) {
                    return data;
                  }
                  return false;
                })
                .map((item1) => {
                  const isActiveSub = item1.route === pathname;

                  return (
                    <AccessControl
                      allowedPermissions={item1.permissions}
                      key={item1.name}
                    >
                      <ListItemStyle
                        component={RouterLink}
                        to={item1.route ? item1.route : ''}
                        sx={{
                          paddingLeft: theme.spacing(5),
                          ...(isActiveSub && activeSubStyle),
                          marginLeft: '-5px',
                        }}
                      >
                        <ListItemIconStyle>
                          <Box
                            component="span"
                            sx={{
                              width: 4,
                              height: 4,
                              display: 'flex',
                              borderRadius: '50%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: theme.palette.secondary.dark,
                              // eslint-disable-next-line no-shadow
                              transition: (theme) =>
                                theme.transitions.create('transform'),
                              ...(isActiveSub && {
                                // transform: 'scale(2)',
                                bgcolor: 'primary.main',
                              }),
                            }}
                          />
                        </ListItemIconStyle>
                        <ListItemText
                          sx={{ marginLeft: '-4px' }}
                          disableTypography
                          primary={item1.name}
                          onClick={() => handleOnClick(item1, '/help')}
                        />
                        {subMouduleUnreadTask[item1.key] > 0 &&
                        project.activeProject ? (
                          <Badge
                            sx={{
                              '& .MuiBadge-badge': {
                                color: theme.palette.other.white,
                                backgroundColor:
                                  theme.palette.other.badgeBorder,
                              },
                            }}
                            badgeContent={subMouduleUnreadTask[item1.key]}
                          />
                        ) : (
                          ''
                        )}
                      </ListItemStyle>
                    </AccessControl>
                  );
                })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={route || ''}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        ...(!isShow && {
          alignItems: 'center',
          justifyContent: 'center',
        }),
      }}
      onClick={handleClick}
    >
      <Tooltip title={name} placement="right">
        <ListItemIconStyle
          sx={{
            ...(isShow && {
              marginRight: theme.spacing(1.5),
            }),
          }}
        >
          {icon}
        </ListItemIconStyle>
      </Tooltip>
      {isShow && (
        <>
          <ListItemText disableTypography primary={name} />
          {info}
          {subMouduleUnreadTask[item.key] > 0 && project.activeProject ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  color: theme.palette.other.white,
                  backgroundColor: theme.palette.other.badgeBorder,
                },
              }}
              badgeContent={subMouduleUnreadTask[item.key]}
            />
          ) : (
            ''
          )}
        </>
      )}
    </ListItemStyle>
  );
}
NavItem.defaultProps = {
  item: {},
  project: {},
  subMouduleUnreadTask: {},
  toggleSidebarWidth: () => {},
  isShow: false,
};

NavItem.propTypes = {
  isShow: PropTypes.bool,
  project: PropTypes.oneOfType([PropTypes.object]),
  item: PropTypes.oneOfType([PropTypes.object]),
  subMouduleUnreadTask: PropTypes.oneOfType([PropTypes.object]),
  toggleSidebarWidth: PropTypes.func,
};

function NavSection({
  navConfig,
  project,
  isShow = true,
  toggleSidebarWidth,
  userPermissions,
  userProjectUnreadTask,
  ...other
}) {
  return (
    <Box {...other}>
      {navConfig
        .filter((data) => {
          if (
            // data.projectType.includes(project.activeProject?.type) &&
            data.projectRegulations.includes(project.activeProject?.regulation)
          ) {
            return data;
          }
          if (!project.activeProject) {
            return data;
          }
          return false;
        })
        .map((item) => {
          return (
            <AccessControl
              allowedPermissions={item.permissions}
              key={item.name}
            >
              <NavItem
                item={item}
                isShow={isShow}
                toggleSidebarWidth={toggleSidebarWidth}
                project={project}
                subMouduleUnreadTask={userProjectUnreadTask[item.key]}
              />
            </AccessControl>
          );
        })}
    </Box>
  );
}

export default React.memo(NavSection);
NavSection.defaultProps = {
  project: {},
  userProjectUnreadTask: {},
  isShow: '',
  navConfig: [],
  toggleSidebarWidth: () => {},
  userPermissions: {},
};

NavSection.propTypes = {
  project: PropTypes.oneOfType([PropTypes.object]),
  userProjectUnreadTask: PropTypes.oneOfType([PropTypes.object]),
  isShow: PropTypes.bool,
  navConfig: PropTypes.oneOfType([PropTypes.array]),
  toggleSidebarWidth: PropTypes.func,
  userPermissions: PropTypes.oneOfType([PropTypes.object]),
};
