import PropTypes from 'prop-types';
import React from 'react';
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  Grid,
  Button,
  FormHelperText,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTransition from './Transitions/DialogTransition';
import SwalToast from './SwalToast';
import ModelMetaDataSchemaTree from './ModelMetaDataSchemaTree';

/**
 * The function `AddResultToModelMetaDataModal` returns a Dialog component with various child
 * components and elements.
 * @returns a JSX element, specifically a Dialog component from a UI library.
 */
const AddResultToModelMetaDataModal = ({
  open,
  onClose,
  modelMetaDataSchema,
  addResultToModelMetaData,
  activeProject,
  resultContents,
  resultType,
}) => {
  const [selectedAttrInfo, setSelectedAttrInfo] = React.useState('');
  const [validationMsg, setValidationMsg] = React.useState('');

  const handleNodeClick = (section, attribute) => {
    setSelectedAttrInfo({
      project_id: activeProject?.project_id,
      section_name: section.section_name, // json schema section name
      attribute_name: attribute.name, // json schema attribute name
      content: resultContents,
    });
  };

  const onSubmitClick = React.useCallback(() => {
    if (selectedAttrInfo) {
      addResultToModelMetaData(selectedAttrInfo).then(
        () => {
          SwalToast({
            icon: 'success',
            title: `${resultType} added successfully to Vault`,
          });
          onClose();
        },
        () => onClose()
      );
    } else {
      setValidationMsg('Please select valid node first');
    }
  }, [addResultToModelMetaData, selectedAttrInfo, resultType]);

  const onResetClick = React.useCallback(() => {
    setSelectedAttrInfo('');
    setValidationMsg('');
  }, [setSelectedAttrInfo, setValidationMsg]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle>Add to Vault</DialogTitle>
      <IconButton
        id="add_result_to_mmd_close_btn"
        color="primary"
        aria-label="close"
        onClick={
          /* The `onClose` function is a callback function that is called when the dialog
              is closed. It is typically used to update the state of the parent component
              to control the visibility of the dialog. */
          onClose
        }
        size="large"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      {modelMetaDataSchema ? (
        <>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color={validationMsg ? 'error' : 'primary'}
                >
                  Select node*
                </Typography>
                <Box
                  p={1}
                  border={1}
                  borderColor="primary.main"
                  sx={{ width: 'auto', height: 250, overflow: 'scroll' }}
                >
                  <ModelMetaDataSchemaTree
                    modelMetaDataSchema={modelMetaDataSchema}
                    handleNodeClick={handleNodeClick}
                    nodeTypesToEnable={[resultType]}
                  />
                </Box>
                <Box ml={-1}>
                  <FormHelperText error={Boolean(validationMsg)}>
                    {validationMsg}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onResetClick} variant="outlined">
              Reset
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmitClick}>
              Submit
            </Button>
          </DialogActions>
        </>
      ) : (
        <Grid item xs={12} textAlign="center">
          <Typography variant="body2" fontStyle="italic">
            Model metadata schema not available.
            <br /> Please check once in Nimbus Vault.
          </Typography>
        </Grid>
      )}
    </Dialog>
  );
};

AddResultToModelMetaDataModal.defaultProps = {
  resultContents: '',
};

AddResultToModelMetaDataModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modelMetaDataSchema: PropTypes.oneOfType([PropTypes.object]).isRequired,
  addResultToModelMetaData: PropTypes.func.isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  resultContents: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  resultType: PropTypes.oneOf(['chart', 'table']).isRequired,
};

export default AddResultToModelMetaDataModal;
