/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeProject: JSON.parse(localStorage.getItem('ACTIVE_PROJECT')) ?? null,
  userRoles: [],
  activeProjectStatus: JSON.parse(
    localStorage.getItem('ACTIVE_PROJECT_STATS')
  ) ?? {
    total_datasets: null,
    total_time: null,
    total_section_completed: null,
    total_modelling_technique: null,
  },
  userProjectsList: [],
  userProjectsStatus: {},
  projectAccessTable: [],
};

/**
 * @function projectSlice  Project slice reducer.
 * @description Redux slice reducer for project state.
 * @param {initialState} state - Current project state.
 * @param {string} name - Name of the action.
 * @param {Function} reducer - Reducer function for the action.
 * @returns {Object} - Project slice reducer.
 */

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.userProjectsList = action.payload;
    },
    setActivateProject: (state, action) => {
      localStorage.setItem('ACTIVE_PROJECT', JSON.stringify(action.payload));
      // eslint-disable-next-line no-param-reassign
      state.activeProject = action.payload;
    },
    setUserRoles: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userRoles = action.payload;
    },
    setindividualProjectStats: (state, action) => {
      localStorage.setItem(
        'ACTIVE_PROJECT_STATS',
        JSON.stringify(action.payload)
      );
      // eslint-disable-next-line no-param-reassign
      state.activeProjectStatus = action.payload;
    },
    setUseProjectStats: (state, action) => {
      state.userProjectsStatus = action.payload;
    },
    setProjectAccessTable: (state, action) => {
      state.projectAccessTable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProjects,
  setActivateProject,
  setUserRoles,
  setindividualProjectStats,
  setUseProjectStats,
  setProjectAccessTable,
} = projectSlice.actions;

export default projectSlice.reducer;
