// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
import './App.scss';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { IconButton, styled } from '@mui/material';
import { SnackbarProvider as Snackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { inializeAxios } from './utils/Axios';
import { CloseButtonIcon } from './const/CommonIcons';
import { FullscreenLoader, NotFound404 } from './components/Common';
import Layout from './views/Layout/Layout';
import Login from './views/Login/Login';
import './wdyr';
import useCurrentTheme from './Theme/useCurrentTheme';
import HelpPage from './components/HelpPage/HelpPage';
// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, isLoggedIn }) => (
  <Route
    render={(props) =>
      isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const SnackbarProvider = styled(Snackbar)`
	&.SnackbarItem-contentRoot{
		width: 345px;
	}
	& .SnackbarItem-message{
		margin-right: 27px;
    word-break: break-word;
	}
	& .SnackbarItem-action{
		position: absolute;
		right: 0;
		top: 5px;
		margin-right: 5px;
		padding: 0;
	}
    &.SnackbarItem-variantSuccess {
        background: ${(p) => p.theme.palette.success.main};
    },
	&.SnackbarItem-variantError {
		background: ${(p) => p.theme.palette.error.main};
	},
	&.SnackbarItem-variantWarning {
		background: ${(p) => p.theme.palette.warning.main};
	},
	&.SnackbarItem-variantInfo {
		background: ${(p) => p.theme.palette.info.main};
	},
`;
inializeAxios();
function App() {
  const theme = useCurrentTheme('light-theme');
  window.MS_TREE_CACHE = {};
  // @ts-ignore
  const { user } = useSelector((state) => state);
  const notistackRef = useRef();
  useEffect(() => {
    try {
      const perfEntries = performance.getEntriesByType('navigation');
      if (
        localStorage.getItem('remember_me') === 'false' &&
        perfEntries[0]?.type !== 'reload'
      ) {
        localStorage.clear();
        window.location.reload();
      }
    } catch (e) {
      console.log(
        'performance.getEntriesByType(navigation) is not supported by your browser'
      );
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.enqueueSnackbar = notistackRef.current.enqueueSnackbar;
    window.closeSnackbar = notistackRef.current.closeSnackbar;
  }, []);

  const onClickSnackBarClose = (key) => {
    // @ts-ignore
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            hideIconVariant
            preventDuplicate
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={5000}
            action={(key) => (
              <IconButton onClick={() => onClickSnackBarClose(key)}>
                <CloseButtonIcon color="#ffffff" />
              </IconButton>
            )}
          >
            <FullscreenLoader />
            <BrowserRouter>
              <React.Suspense fallback="">
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/help" component={HelpPage} />
                  <PrivateRoute
                    path="/"
                    isLoggedIn={user.isAuthenticate}
                    component={Layout}
                  />
                  <Route
                    path="*"
                    name="Not Found"
                    render={(props) => <NotFound404 {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
