import { useMemo } from 'react';
import darkTheme from './dark';
import lightTheme from './light';
/**
 * Returns theme based on user preferences.
 *
 * @returns {Object} Current theme
 */
const useCurrentTheme = (themeValue) =>
  useMemo(
    () => (themeValue === 'light-theme' ? lightTheme : darkTheme),
    [themeValue]
  );

export default useCurrentTheme;
