import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MaterialTable from '../MaterialTable';
import { SwalToast } from '../../../Common';
import { REGULATIONS } from '../../../../const/CommonConst';

const tsRegulationKeys = REGULATIONS.filter((item) => item.isTs).map(
  (item) => item.key
);

/**
 * @function MonitoringRules
 * @description MonitoringRules Modal - child component of
 *  Performance Testing - building UI of Performance Testing
 * @param {object} activeProject - object containing details
 *  of active project like project regulation, type, datasetcount etc.
 * @param  {Array} existingRules - array having existing rules info if present
 * @param {object} activeDataset - currently active dataset
 * @param  {function} fetchExisistingRules - function to fetch existing rules
 * @param  {Array} existingRulesData - array having existing rules data if present
 * @param  {function} onMonitoringRulesSubmit - function to submit data on metric selection
 * @param  {function} fetchTSExistingRules - function to fetch existing rules for TS
 * @param  {function} onTSMonitoringRulesSubmit - function to submit data on metric selection for TS
 * @param  {boolean} readOnlyField - boolean to check if field is read only
 * @param  {string} tableTitlePrefix - string to set table title prefix
 * @param {boolean} showRulesError=false - boolean that decide if to show error or not
 * @returns {JSX} MonitoringRules UI
 * @example <MonitoringRules activeDataset={activeDataset}
 *  activeProject={activeProject} fetchExisistingRules={fetchExisistingRules} />
 */
const MonitoringRules = ({
  activeProject,
  existingRules,
  activeDataset,
  fetchExisistingRules,
  existingRulesData,
  onMonitoringRulesSubmit,
  fetchTSExistingRules,
  onTSMonitoringRulesSubmit,
  readOnlyField,
  tableTitlePrefix,
  showRulesError = false,
}) => {
  const formRef = useRef();

  const initialValues = {
    tableValue: [],
  };
  useEffect(() => {
    if (formRef.current) {
      const { current } = formRef;
      const tableValue = [];
      for (let i = 0; i < existingRulesData.length; i += 1) {
        tableValue.push({
          key: existingRulesData[i].key,
          val1: existingRules[i]?.val1 ? existingRules[i].val1 : '',
          val2: existingRules[i]?.val2 ? existingRules[i].val2 : '',
          val3: existingRules[i]?.val3 ? existingRules[i].val3 : '',
          val4: existingRules[i]?.val4 ? existingRules[i].val4 : '',
        });
      }
      current.setValues({
        ...current.values,
        tableValue,
      });
    }
  }, [existingRules, existingRulesData]);

  const formValidationSchema = Yup.object({
    tableValue: Yup.array().of(
      Yup.object().shape({
        val1: Yup.number().required('Required'),
        val2: Yup.number().required('Required'),
        val3: Yup.number().required('Required'),
        val4: Yup.number().required('Required'),
      })
    ),
  });

  useEffect(() => {
    if (activeProject?.project_id) {
      fetchExisistingRules({ project_id: activeProject.project_id });
      fetchTSExistingRules({ project_id: activeProject.project_id });
    }
  }, [activeProject?.project_id, activeDataset?.name]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="sticky"
      >
        <Typography component="h6" variant="h6" color="primary">
          Performance Testing Rules{' '}
        </Typography>
      </Box>
      <Box px={3} py={2} width>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={(values) => {
            const body = {
              rules: values.tableValue,
              project_id: activeProject.project_id,
            };
            if (tsRegulationKeys.includes(activeProject?.regulation)) {
              onTSMonitoringRulesSubmit(body).then(() => {
                fetchTSExistingRules(
                  { project_id: activeProject.project_id },
                  () => {},
                  () => {}
                );
                SwalToast({
                  icon: 'success',
                  title: 'Monitoring rules updated successfully.',
                });
              });
            } else {
              onMonitoringRulesSubmit(body).then(() => {
                fetchExisistingRules(
                  { project_id: activeProject.project_id },
                  () => {},
                  () => {}
                );
                SwalToast({
                  icon: 'success',
                  title: 'Monitoring rules updated successfully.',
                });
              });
            }
          }}
          validationSchema={formValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form noValidate onSubmit={handleSubmit} id="rulesForm">
              <Grid container>
                {existingRulesData.length !== 0 &&
                  existingRulesData.map((tab, index) => {
                    const tableValErrors =
                      (errors.tableValue?.length && errors.tableValue[index]) ||
                      {};
                    const tableValTouched =
                      (touched.tableValue?.length &&
                        touched.tableValue[index]) ||
                      {};
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item xs={12} key={index}>
                        <MaterialTable
                          tab={tab}
                          indexes={index}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          tableValErrors={tableValErrors}
                          tableValTouched={tableValTouched}
                          readOnlyField={readOnlyField}
                          tableTitlePrefix={tableTitlePrefix}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
      {showRulesError && (
        <Box height={55} textAlign="center">
          <Typography color="error" component="h6" variant="h6">
            Please fill all fields and save rules.
          </Typography>
        </Box>
      )}
    </>
  );
};
MonitoringRules.defaultProps = {
  readOnlyField: false,
  tableTitlePrefix: '',
  showRulesError: false,
};
MonitoringRules.propTypes = {
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  existingRulesData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  existingRules: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onMonitoringRulesSubmit: PropTypes.func.isRequired,
  fetchExisistingRules: PropTypes.func.isRequired,
  activeDataset: PropTypes.string.isRequired,
  fetchTSExistingRules: PropTypes.func.isRequired,
  onTSMonitoringRulesSubmit: PropTypes.func.isRequired,
  readOnlyField: PropTypes.bool,
  tableTitlePrefix: PropTypes.string,
  showRulesError: PropTypes.bool,
};

export default React.memo(MonitoringRules);
