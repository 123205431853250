import React, { useCallback } from 'react';
import { Dialog } from '@mui/material';
import { withTransaction } from '@elastic/apm-rum-react';
import PropTypes from 'prop-types';
import DialogTransition from '../../components/Common/Transitions/DialogTransition';
import ModelMonitoringDashboard from './ModelMonitoringDashboard';
/**
 * @function ModelMonitoringDashboardDialog
 * @description  The function `ModelMonitoringDashboardDialog` renders a dashboard dialog for monitoring
 * model's performance, allowing users to view and edit various aspects of the model's monitoring
 * results.
 * @returns {JSX} A React functional component that renders a model monitoring dashboard dialog
 * @example <ModelMonitoringDashboardDialog  props/>
 */
const ModelMonitoringDashboardDialog = ({ setOpenDialog, openDialog }) => {
  const handleModelMonitoringDialog = useCallback(() => {
    setOpenDialog(!openDialog);
  }, [setOpenDialog]);
  return (
    <Dialog
      fullScreen
      open={openDialog}
      aria-labelledby="customized-dialog-title"
      maxWidth="xs"
      TransitionComponent={DialogTransition}
      onClose={handleModelMonitoringDialog}
      PaperProps={{
        style: {
          paddingTop: 0,
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 0,
        },
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          boxShadow: 'none',
        },
      }}
    >
      <ModelMonitoringDashboard
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
    </Dialog>
  );
};
ModelMonitoringDashboardDialog.propTypes = {
  setOpenDialog: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
};
export default withTransaction(
  'ModelMonitoringDashboardDialog',
  'component'
)(ModelMonitoringDashboardDialog);
