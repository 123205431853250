/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SCROLL_BY_PX = 300;
const CustomCarousel = (props) => {
  const elementRef = useRef(null);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [showArrows, setShowArrows] = useState(false);
  const [scrolledByCode, setScrolledByCode] = useState(false);

  const handleScrollRight = useCallback(() => {
    if (
      currentScrollPosition <
      elementRef.current.scrollWidth - elementRef.current.offsetWidth
    ) {
      setCurrentScrollPosition(currentScrollPosition + SCROLL_BY_PX);
    }
  }, [currentScrollPosition, setCurrentScrollPosition, elementRef]);

  const handleLeftArrowClick = useCallback(() => {
    if (currentScrollPosition > 0) {
      setCurrentScrollPosition(currentScrollPosition - SCROLL_BY_PX);
    }
  }, [currentScrollPosition, setCurrentScrollPosition]);

  const handleScrollLeft = useCallback(
    (event) => {
      if (scrolledByCode) {
        setScrolledByCode(false);
      } else {
        setCurrentScrollPosition(event.target.scrollLeft);
      }
    },
    [scrolledByCode, setCurrentScrollPosition]
  );

  useEffect(() => {
    if (
      elementRef?.current?.offsetWidth &&
      elementRef?.current?.scrollWidth &&
      elementRef?.current?.offsetWidth < elementRef?.current?.scrollWidth
    ) {
      setShowArrows(true);
    }
  }, [elementRef?.current]);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scroll(currentScrollPosition, 0);
      setScrolledByCode(true);
    }
  }, [currentScrollPosition]);

  return props?.elements?.length ? (
    <Box>
      <Box display="flex" alignItems="center">
        {showArrows ? (
          <IconButton size="small" onClick={handleLeftArrowClick}>
            <KeyboardArrowLeftIcon sx={{ opacity: 0.2 }} />
          </IconButton>
        ) : null}
        <Box
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '99%',
            cursor: 'pointer',
          }}
          ref={elementRef}
          onScroll={handleScrollLeft}
        >
          {props?.elements?.map((element, index) => (
            <Box
              sx={{
                marginLeft: 0.5,
                marginRight: 0.5,
                display: 'inline-block',
                wordBreak: 'break-all',
                width: 'auto',
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={`caraosel_${index}`}
            >
              {element}
            </Box>
          ))}
        </Box>
        {showArrows ? (
          <IconButton size="small" onClick={handleScrollRight}>
            <KeyboardArrowRightIcon sx={{ opacity: 0.2 }} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  ) : null;
};
export default CustomCarousel;
