// @ts-nocheck
import { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  setProjects,
  setActivateProject,
  setUserRoles,
  setindividualProjectStats,
  setUseProjectStats,
  setProjectAccessTable,
} from '../../toolkit/projectSlicer';
import ApiEndpoints from '../../const/ApiEndpoints';
import { SwalToast } from '../../components/Common';
import useConsole from '../Console/useConsole';
import utils from '../../utils/utils';

/**
 * @function useProject
 * @description hook for ProjectOverview Module
 * @returns {object} -returning multiple methods and states for project overview module
 * @example const {createNewProject,fetchUserProjects,deleteProject,cloneProject }=useProject()
 */
export default function useProject() {
  const dispatch = useDispatch();
  const { project, user } = useSelector((state) => state);

  // without pagination
  const [datasetRowsWithoutPagination, setDatasetRowsWithoutPagination] =
    useState([]);

  const [datasetList, setDatasetList] = useState([]);
  const [datasetListPageCount, setDatasetListPageCount] = useState(0);
  const [datasetListTotalCount, setDatasetListTotalCount] = useState(0);
  // for storing unread task details
  const [userProjectUnreadTask, setUserProjectUnreadTask] = useState({});

  // with pagination
  const [datasetRowsWithPagination, setDatasetRowsWithPagination] = useState(
    []
  );
  const [datasetRowsPageCount, setDatasetRowPageCount] = useState(0);
  const [totalDatasetRows, setTotalDatasetRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const fetchIdRef = useRef(0);
  const BASE_URL = ApiEndpoints.MODULE_BASE_URL;
  const { fetchUdfs, udfs } = useConsole();

  // User collection
  const fetchUserRecipients = (successCallback = () => {}) =>
    axios.get(`${BASE_URL.FTS}${ApiEndpoints.FTS.INV_USER_COLLECTION}`).then(
      (res) => {
        successCallback(res);
      },
      (error) => {
        throw error;
      }
    );

  // sharing entity
  const submitSharingData = (body) =>
    axios.post(`${BASE_URL.FTS}${ApiEndpoints.FTS.INV_SHARING}`, body).then(
      (res) => {
        SwalToast({
          icon: 'success',
          title: res.data.msg,
        });
      },
      (error) => {
        throw error;
      }
    );

  // recived entity
  const fetchSentReceivedArtifacts = (successCallback = () => {}) =>
    axios.get(`${BASE_URL.FTS}${ApiEndpoints.FTS.INV_RECIVED}`).then(
      (res) => {
        successCallback(res);
      },
      (error) => {
        throw error;
      }
    );

  const fetchSentReceivedPipelines = () => {
    return axios
      .get(`${BASE_URL.DOC}${ApiEndpoints.PIPELINE.FETCH_SHARED_PIPELINE}`)
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  // action of entity
  const acceptRejectArtifact = (body) =>
    axios.post(`${BASE_URL.FTS}${ApiEndpoints.FTS.INV_ACTION}`, body).then(
      // Change1
      (res) => res,
      (error) => {
        throw error;
      }
    );

  // Project
  /**
   * @function createNewProject
   * @description API call for creating new project
   * @param {object} body - data for creating project
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example createNewProject(body,successCallback,errorCallback);
   */
  const createNewProject = (
    body,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
    axios
      .post(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.CREATE_PROJECT}`,
        body
      )
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  const cloneProject = (body) =>
    axios
      .post(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.CLONE_PROJECT}`,
        body
      )
      .then(
        (resp) => {
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function shareProject
   * @description Shares a project.
   * @param {object} body - The data representing the project to be shared.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const shareProject = (body) => {
    return axios
      .post(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.SHARE_PROJECT}`,
        body
      )
      .then((res) => res)
      .catch((e) => {
        throw e;
      });
  };

  /**
   * @function fetchSentReceivedProject
   * @description Fetches sent and received projects.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const fetchSentReceivedProject = () => {
    return axios
      .get(`${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.SHARE_PROJECT}`)
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function acceptProject
   * @description Accepts a project.
   * @param {object} body - The data representing the project to be accepted.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const acceptProject = (body) =>
    axios
      .post(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.ACTION_PROJECT}`,
        body
      )
      .then((res) => res)
      .catch((e) => {
        throw e;
      });

  /**
   * @function rejectProject
   * @description Rejects a project.
   * @param {number} id - The ID of the project to be rejected.
   * @param {object} rejectDatePayload - The payload containing data for rejecting the project.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const rejectProject = (id, rejectDatePayload) =>
    axios
      .put(`${BASE_URL.DI + ApiEndpoints.PROJECT_MANAGEMENT.ACTION_PROJECT}`, {
        id,
        ...rejectDatePayload,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function updateProject
   * @description Updates a project.
   * @param {object} body - The data representing the project to be updated.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const updateProject = (body) =>
    axios
      .put(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.UPDATE_PROJECT}`,
        body
      )
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchUserProjects
   * @description API call for fetching user projects
   * @param {object} body - data for fetching user projects
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example fetchUserProjects(body,successCallback,errorCallback);
   */
  const fetchUserProjects = (
    body,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
    axios
      .get(`${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.USER_PROJECTS}`)
      .then(
        (res) => {
          dispatch(setProjects(res.data));
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  /**
   * @function fetchUserUnreadTaskSubmodulewise
   * @description API call for fetching unread jobs for current project
   * @param {project_Id}  active project id
   * @returns {Promise} AxiosPromise
   * @example fetchUserUnreadTaskSubmodulewise(project_id);
   */

  const fetchUserUnreadTaskSubmodulewise = (projectId) => {
    axios
      .get(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.USER_PROJECT_DETAILS}`,
        {
          params: { project_id: projectId },
          loader: false,
        }
      )
      .then((res) => {
        setUserProjectUnreadTask(res.data);
      });
  };

  /**
   * @function deleteProject
   * @description API call for delete project
   * @param {number} projectId - projectId to be deleted
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example deleteProject(projectId,successCallback,errorCallback);
   */
  const deleteProject = (
    projectId,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
    axios
      .delete(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.DELETE_PROJECT}`,
        { data: { project_id: projectId } }
      )
      .then(
        (res) => {
          fetchUserProjects();
          // eslint-disable-next-line no-use-before-define
          fetchUserProjectsStatus();
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  /**
   * @function archiveProject
   * @description Archives a project.
   * @param {number} projectId - The ID of the project to be archived.
   * @returns {Promise} A Promise that resolves if the project is successfully archived, or rejects with an error.
   */
  const archiveProject = (projectId) =>
    axios
      .delete(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.ARCHIVE_PROJECT}`,
        {
          data: { project_id: projectId },
        }
      )
      .then(
        () => {
          fetchUserProjects();
          // eslint-disable-next-line no-use-before-define
          fetchUserProjectsStatus();
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function unarchiveProject
   * @description Restores an archived project.
   * @param {number} projectId - The ID of the project to be restored.
   * @returns {Promise} A Promise that resolves if the project is successfully restored, or rejects with an error.
   */
  const unarchiveProject = (projectId) =>
    axios
      .post(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.RESTORE_PROJECT}`,
        { project_id: projectId }
      )
      .then(
        () => {
          fetchUserProjects();
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchUserRoles
   * @description API call for fetching user roles
   * @param {object} body - data for fetching user roles
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example fetchUserRoles(body,successCallback,errorCallback);
   */
  const fetchUserRoles = (
    body,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
    axios
      .get(`${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.USER_ROLES}`)
      .then(
        (res) => {
          dispatch(setUserRoles(res.data));
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  /**
   * @function fetchIndividualProjectStats
   * @description API call for fetching individual project stats
   * @param {object} body - data for fetching individual project stats
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example fetchIndividualProjectStats(body,successCallback,errorCallback);
   */
  const fetchIndividualProjectStats = (body) =>
    axios
      .get(
        `${`${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.FETCH_INDIVIDUAL_PROJECT_STATS}/${body}`}`
      )
      .then((res) => {
        dispatch(setindividualProjectStats(res.data));
        fetchUserProjects();
        return res;
      });

  /**
   * @function fetchUserProjectsStatus
   * @description API call for fetching user projects status
   * @param {object} body - data for fetching user projects status
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example fetchUserProjectsStatus(body,successCallback,errorCallback);
   */
  const fetchUserProjectsStatus = (
    body,
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
    axios
      .get(
        `${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.USER_PROJECTS_STATUS}`
      )
      .then(
        (res) => {
          dispatch(setUseProjectStats(res.data));
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );

  /**
   * @function fetchProjectTypes
   * @description API call for fetching user projects status
   * @returns {Promise} AxiosPromise
   * @example fetchProjectTypes(body,successCallback,errorCallback);
   */

  const fetchProjectTypes = () => {
    return axios
      .get(`${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.PROJECT_TYPE}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  };

  /**
   * @function fetchDatasetList
   * @description API call for fetching datasetlist
   * @param {number} project_id - project_id from which datasetlist to be fetched
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetList(project_id,successCallback,errorCallback);
   */
  // const fetchDatasetList = (
  //   projectId,
  //   successCallback = () => {},
  //   errorCallback = () => {},
  //   loader = true
  // ) => {
  //   const Endpoint = projectId
  //     ? `${ApiEndpoints.DI.DATAMANAGE_DATASETS}?project_id=${projectId}`
  //     : ApiEndpoints.DI.DATAMANAGE_DATASETS;

  //   return axios.get(`${BASE_URL.DI}${Endpoint}`, { loader }).then(
  //     (res) => {
  //       setDatasetList(res.data);
  //       successCallback(res);
  //     },
  //     (e) => {
  //       errorCallback(e);
  //     }
  //   );
  // };

  /**
   * @function fetchDatasetList
   * @description Fetches a list of datasets.
   * @param {number} projectId - The ID of the project associated with the datasets.
   * @param {boolean} lite - Flag indicating whether to fetch lite dataset list or full dataset list.
   * @param {string} field - The field to filter datasets by.
   * @param {number} pageIndex - The index of the page to fetch.
   * @param {number} pageSize - The size of each page in the dataset list.
   * @param {string} searchText - The text to search for in the dataset names.
   * @returns {Promise} A Promise that resolves with the dataset list data if successful, or rejects with an error.
   */
  const fetchDatasetList = (
    projectId,
    lite = true,
    field = '',
    pageIndex = 0,
    pageSize = 10,
    searchText = ''
  ) => {
    if (projectId !== undefined) {
      if (!lite) {
        return axios
          .get(`${BASE_URL.DI}${ApiEndpoints.DI.DATAMANAGE_DATASETS}`, {
            params: {
              page: pageIndex + 1,
              ...(projectId ? { project_id: projectId } : {}),
              ...(pageSize ? { pagesize: pageSize } : {}),
              ...(searchText
                ? { search_term: searchText }
                : { search_term: '' }),
            },
          })
          .then(
            (res) => {
              if (res.data) {
                setIsLoading(false);
                setDatasetList(res.data);
                setDatasetListTotalCount(res.headers['x-total-count']);
                setDatasetListPageCount(
                  Math.ceil(res.headers['x-total-count'] / pageSize)
                );
              } else {
                setIsLoading(false);
                setDatasetList([]);
                setDatasetListPageCount(0);
                setDatasetListTotalCount(0);
              }
              return res;
            },
            (e) => {
              setIsLoading(false);
              setDatasetList([]);
              setDatasetListPageCount(0);
              setDatasetListTotalCount(0);
              throw e;
            }
          );
      }
      return axios
        .get(`${BASE_URL.DI}${ApiEndpoints.DI.DATAMANAGE_DATASETS}`, {
          params: {
            ...(projectId ? { project_id: projectId } : {}),
            ...(field ? { fields: field } : {}),
          },
        })
        .then(
          (res) => {
            if (res.data) {
              setIsLoading(false);
              setDatasetList(res.data);
            } else {
              setIsLoading(false);
              setDatasetList([]);
              setDatasetListPageCount(0);
              setDatasetListTotalCount(0);
            }
            return res;
          },
          (e) => {
            setIsLoading(false);
            setDatasetList([]);
            setDatasetListPageCount(0);
            setDatasetListTotalCount(0);
            throw e;
          }
        );
    }
  };

  /**
   * @function fetchDatasetRowsWithoutPagination
   * @description API call for fetching dataset rows without pagination
   * @param {number} datasetID -fetchingdataset rows without pagination of particular dataset id
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetRowsWithoutPagination(datasetID);
   */
  const fetchDatasetRowsWithoutPagination = (datasetID) => {
    setIsLoading(true);
    return axios
      .get(`${BASE_URL.GR}${ApiEndpoints.DM.DATASETS}`, {
        params: {
          dataset_id: datasetID,
        },
      })
      .then(
        (res) => {
          if (res.data.response) {
            setIsLoading(false);
            setDatasetRowsWithoutPagination(res.data.response);
          } else {
            setIsLoading(false);
            setDatasetRowsWithoutPagination([]);
          }
          return res;
        },
        (e) => {
          setIsLoading(false);
          setDatasetRowsWithoutPagination([]);
          throw e;
        }
      );
  };

  /**
   * @function fetchDatasetRowsWithPagination
   * @description API call for fetching dataset rows with pagination
   * @param {number} datasetId -fetchingdataset rows with pagination of particular dataset id
   * @param {number} pageIndex - fetching dataset rows with page index
   * @param {number} pageSize - fetching dataset rows with pagesize
   * @param {string} searchText - text to be searched
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetRowsWithPagination(datasetId,pageIndex,pageSize,searchText);
   */
  const fetchDatasetRowsWithPagination = useCallback(
    (datasetId, pageIndex = 0, pageSize = 500, searchText = '') => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server.

      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      // Your server could send back total page count.
      setIsLoading(true);
      return axios
        .get(`${BASE_URL.GR}${ApiEndpoints.DM.DATASETS}`, {
          params: {
            dataset_id: datasetId,
            page: pageIndex + 1,
            ...(pageSize ? { pagesize: pageSize } : {}),
            ...(searchText ? { search: searchText } : {}),
          },
        })
        .then(
          (res) => {
            if (res.data.response) {
              setIsLoading(false);
              if (fetchId === fetchIdRef.current) {
                setDatasetRowsWithPagination(res.data.response);
                setTotalDatasetRows(res.headers['x-total-count']);
                setDatasetRowPageCount(
                  Math.ceil(res.headers['x-total-count'] / pageSize)
                );
              }
            } else {
              setIsLoading(false);
              setDatasetRowsWithPagination([]);
              setDatasetRowPageCount(0);
              setTotalDatasetRows(0);
            }
            return res;
          },
          (e) => {
            setIsLoading(false);
            setDatasetRowsWithPagination([]);
            setDatasetRowPageCount(0);
            setTotalDatasetRows(0);
            throw e;
          }
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * @function updateDatasetCells
   * @description API call for updating dataset cells
   * @param {number} datasetID -updating dataset cells of particular dataset id
   * @param {Array} cells - dataset cells
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example  updateDatasetCells(datasetID,cells,successCallback,errorCallback)
   */
  const updateDatasetCells = (
    cells = [],
    successCallback = () => {},
    errorCallback = () => {}
  ) =>
    axios.put(`${BASE_URL.DM}${ApiEndpoints.GRID.EDIT_CELL}`, cells).then(
      (resp) => {
        if (successCallback) {
          successCallback(resp);
        }
      },
      (err) => {
        if (errorCallback) {
          errorCallback(err);
        }
      }
    );
  /**
   * @function fetchModuleTasks
   * @description API call for fetching module tasks
   * @param {string} module -module to be fetched
   * @param {object} operation - operation
   * @param {string} endpoint - API endpoints
   * @param {function} setModuleTasks -setting module tasks
   * @param {number} project_id - project id
   * @param {boolean} hideLoaderOnRequest - hiding loader on request
   * @returns {Promise} AxiosPromise
   * @example  fetchModuleTasks(module,operation,endpoint,setModuleTasks,project_id,hideLoaderOnRequest)
   */
  const fetchModuleTasks = (
    module,
    operation,
    endpoint,
    setModuleTasks,
    // eslint-disable-next-line camelcase
    project_id,
    hideLoaderOnRequest
  ) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL[module] + ApiEndpoints[module][endpoint]
        }`,
        {
          params: {
            operation,
            // eslint-disable-next-line camelcase
            project_id,
          },
          loader: !hideLoaderOnRequest,
        }
      )
      .then(
        (res) => {
          setModuleTasks(res.data);
        },
        () => {}
      );

  /**
   * @function saveChartComment
   * @description API call for saving chart comment
   * @param {object} body -data for saving chart comment
   * @param {boolean} isConsole - if true then console API is called
   * @returns {Promise} AxiosPromise
   * @example  saveChartComment(body)
   */
  const saveChartComment = (body, isConsole, successCallback = () => {}) => {
    const baseUrl = utils.getModuleBaseUrl(body.module, body.sub_module);
    const url = isConsole
      ? BASE_URL.CONSOLE + ApiEndpoints.UDF_MANAGER.SAVE_CHART
      : `${baseUrl}${ApiEndpoints.SAVE_CHART_COMMENT}`;
    // eslint-disable-next-line no-param-reassign
    delete body.api;
    // eslint-disable-next-line no-param-reassign
    delete body.moduleRoute;
    return (
      axios.post(url, body).then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: 'Comment submitted successfully.',
          });
          successCallback(res);
        },
        () => {}
      ),
      body.moduleRoute
    );
  };

  /**
   * @function saveTableComment
   * @description API call for saving table comment
   * @param {object} body -data for saving table comment
   * @param {boolean} isConsole - if true then console API is called
   * @returns {Promise} AxiosPromise
   * @example  saveTableComment(body)
   */
  const saveTableComment = (body, isConsole, successCallback = () => {}) => {
    const baseUrl = utils.getModuleBaseUrl(body.module, body.sub_module);
    const url = isConsole
      ? BASE_URL.CONSOLE + ApiEndpoints.UDF_MANAGER.SAVE_TABLE
      : `${baseUrl}${ApiEndpoints.SAVE_TABLE_COMMENT}`;
    return (
      axios.post(url, body).then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: 'Comment submitted successfully.',
          });
          successCallback(res);
        },
        () => {}
      ),
      body.moduleRoute
    );
  };

  /**
   * @function refreshGridOnSuccess
   * @description API call for refresh grid on success
   * @param {number} dataSetId - dataset id
   * @returns {undefined}
   * @example  refreshGridOnSuccess(dataSetId)
   */
  const refreshGridOnSuccess = (dataSetId) => {
    if (
      dataSetId &&
      document.getElementById(`dataset-table-${dataSetId}-refresh-btn`)
    ) {
      document
        .getElementById(`dataset-table-${dataSetId}-refresh-btn`)
        ?.click();
    }
  };

  /**
   * @function fetchProjectAccessTable
   * @description API call for fetch project access table
   * @param {number} projectId -project id
   * @returns {Promise} AxiosPromise
   * @example  fetchProjectAccessTable(projectId)
   */
  const fetchProjectAccessTable = (projectId) => {
    if (projectId) {
      return axios
        .get(
          `${
            BASE_URL.DI + ApiEndpoints.PROJECT_MANAGEMENT.PROJECT_ACCESS_TABLE
          }/${projectId}`
        )
        .then(
          (resp) => {
            dispatch(setProjectAccessTable(resp.data));
            return resp;
          },
          (e) => {
            dispatch(setProjectAccessTable([]));
            return Promise.reject(e);
          }
        );
    }
  };

  /**
   * @function fetchWorkflowDetails
   * @description API call for fetch workflow details
   * @param {object} body -body as data in params
   * @returns {Promise} AxiosPromise
   * @example  fetchWorkflowDetails(body)
   */

  const fetchWorkflowDetails = (body) => {
    // {{url}}/data-management/projectmanagement/workflow?project_id=73
    return axios
      .get(
        `${`${BASE_URL.DI}${ApiEndpoints.PROJECT_MANAGEMENT.FETCH_PROJECT_WORKFLOW_DETAILS}`}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      });
  };

  /**
   * @function updateProjectAccessTable
   * @description API call for update project access table
   * @param {object} payload -payload data
   * @returns {Promise} AxiosPromise
   * @example  updateProjectAccessTable(payload)
   */
  const updateProjectAccessTable = (payload) => {
    if (payload) {
      return axios
        .post(
          BASE_URL.DI + ApiEndpoints.PROJECT_MANAGEMENT.PROJECT_ACCESS_TABLE,
          payload
        )
        .then(
          (resp) => resp,
          (e) => Promise.reject(e)
        );
    }
  };

  /**
   * @function validateAndActivateProject
   * @description This function is used to validate the project
   * and activate the project if it is valid
   * @param {Object} _project
   * @returns {void}
   */
  const validateAndActivateProject = (_project) => {
    if (
      !_project.is_archived &&
      ['NotStarted', 'Success'].includes(_project?.clone_status) &&
      ['NotStarted', 'Success'].includes(_project?.share_status)
    ) {
      dispatch(setActivateProject(_project));
    } else {
      if (_project.is_archived) {
        SwalToast({
          icon: 'info',
          title: 'This project is archived.',
        });
      }
      if (_project?.share_status === 'InProgress') {
        SwalToast({
          icon: 'info',
          title: 'Project accepting is in progress. Please wait for sometime.',
        });
      }
      if (_project?.share_status === 'Failed') {
        SwalToast({
          icon: 'error',
          title: `Project accepting failed. Reason: ${_project?.share_failure_msg}`,
        });
      }
      if (_project?.clone_status === 'InProgress') {
        SwalToast({
          icon: 'info',
          title: 'Project cloning is in progress. Please wait for sometime.',
        });
      }
      if (_project?.clone_status === 'Failed') {
        SwalToast({
          icon: 'error',
          title: `Project cloning failed. Reason: ${_project?.clone_failure_msg}`,
        });
      }
    }
  };

  return {
    datasetList,
    datasetListPageCount,
    datasetListTotalCount,
    fetchDatasetList,
    fetchDatasetRowsWithoutPagination,
    fetchModuleTasks,
    saveTableComment,
    saveChartComment,
    datasetRowsWithoutPagination,
    setDatasetRowsWithoutPagination,

    datasetRowsWithPagination,
    setDatasetRowsWithPagination,

    totalDatasetRows,
    fetchDatasetRowsWithPagination,
    datasetRowsPageCount,
    isLoading,
    updateDatasetCells,

    // Project
    project,
    createNewProject,
    cloneProject,
    deleteProject,
    shareProject,
    fetchUserProjects,
    fetchUserRoles,
    fetchIndividualProjectStats,
    fetchUserProjectsStatus,
    fetchUserRecipients,
    submitSharingData,
    fetchSentReceivedArtifacts,
    fetchSentReceivedProject,
    fetchSentReceivedPipelines,
    acceptProject,
    rejectProject,
    acceptRejectArtifact,
    activateProject(data) {
      dispatch(setActivateProject(data));
    },
    refreshGridOnSuccess,
    fetchProjectAccessTable,
    updateProjectAccessTable,
    updateProject,
    unarchiveProject,
    archiveProject,
    fetchWorkflowDetails,
    fetchUserUnreadTaskSubmodulewise,
    userProjectUnreadTask,
    fetchUdfs,
    udfs,
    validateAndActivateProject,
    fetchProjectTypes,
    setDatasetListTotalCount,
    setDatasetListPageCount,
    user,
  };
}
