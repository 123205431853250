import { useDispatch, useSelector } from 'react-redux';
import {
  toggleProjectOverviewProjectListView,
  toggleCreateEditUdfArgumentsEnableListView,
  setCreateEditUdfTypeFilter,
  setProjectOverviewRegulationFilter,
  setProjectOverviewProjectTypeFilter,
  setProjectOverviewProjectSearch,
  toggleModelManagerListView,
  setModelManagerModelSearch,
  setModelManagerModelTypeFilter,
  togglePipelineProjectListView,
  setPipelineProjectCreatedOnFilter,
  setPipelineProjectSearch,
  setUsersCreatedOnFilter,
  setUsersNameSearch,
  setModelNameSearch,
  setRelatedJobsSearch,
  setRelatedPipelinesSearch,
  setSelectedModel,
  setHelpDocumentKeywordSearch,
  setUdfManagerUdfSearch,
  setHelpDrawerKeywordSearch,
  setGroupsCreatedOnFilter,
  setGroupsNameSearch,
  setDocumentationCreatedOnFilter,
  setDocumentationProjectListFilter,
  setDocumentationProjectTypeFilter,
  toggleDocumentationProjectListView,
  setDocumentationNameSearch,
  setDataManagementDatasetListNameSearch,
  setDataManagementProjectListNameSearch,
  toggleWorkerListView,
  setPipelineExecutionHistoryPipelineNameSearch,
  setPipelineExecutionHistoryCreatedOnFilter,
  togglePipelineExecutionHistoryPipelineListView,
  setDatasetsNameSearch,
  setDatasetsFilterByProject,
  setConsoleLibraryNameSearch,
  setIntegrationAPITokenSearch,
  setIntegrationAPITokenCreatedOnFilter,
  setAccessLogsNameSearch,
  setAccessLogsCreatedOnFilter,
  setAuditLogsNameSearch,
  setAuditLogsCreatedOnFilter,
  setConsoleFileNameSearch,
  setSelectedExecutionIdsForComparison,
  setModelManagerModelStatusFilter,
  setModelManagerModelFilter,
} from '../toolkit/commonSlicer';

/**
 * Custom hook for accessing and dispatching common actions at the application level.
 *
 * @returns {{
 *   dispatch: Function,
 *   common: Object,
 * }} Object containing functions and state variables for common actions.
 * @example
 * const { dispatch, common } = useCommon();
 */

export default function useCommon() {
  const dispatch = useDispatch();
  // @ts-ignore
  const { common } = useSelector((state) => state);

  return {
    dispatch,
    common,
    toggleProjectOverviewProjectListView,
    toggleCreateEditUdfArgumentsEnableListView,
    setCreateEditUdfTypeFilter,
    setProjectOverviewRegulationFilter,
    setProjectOverviewProjectTypeFilter,
    setProjectOverviewProjectSearch,
    toggleModelManagerListView,
    setModelManagerModelSearch,
    setUdfManagerUdfSearch,
    setModelManagerModelTypeFilter,
    togglePipelineProjectListView,
    setPipelineProjectCreatedOnFilter,
    setPipelineProjectSearch,
    setUsersCreatedOnFilter,
    setUsersNameSearch,
    setModelNameSearch,
    setRelatedJobsSearch,
    setRelatedPipelinesSearch,
    setSelectedModel,
    setHelpDocumentKeywordSearch,
    setHelpDrawerKeywordSearch,
    setGroupsCreatedOnFilter,
    setGroupsNameSearch,
    setDocumentationCreatedOnFilter,
    setDocumentationProjectListFilter,
    setDocumentationProjectTypeFilter,
    toggleDocumentationProjectListView,
    setDocumentationNameSearch,
    setDataManagementDatasetListNameSearch,
    setDataManagementProjectListNameSearch,
    toggleWorkerListView,
    setPipelineExecutionHistoryPipelineNameSearch,
    setPipelineExecutionHistoryCreatedOnFilter,
    togglePipelineExecutionHistoryPipelineListView,
    setDatasetsNameSearch,
    setDatasetsFilterByProject,
    setConsoleLibraryNameSearch,
    setIntegrationAPITokenSearch,
    setIntegrationAPITokenCreatedOnFilter,
    setAccessLogsNameSearch,
    setAccessLogsCreatedOnFilter,
    setAuditLogsCreatedOnFilter,
    setAuditLogsNameSearch,
    setConsoleFileNameSearch,
    setSelectedExecutionIdsForComparison,
    setModelManagerModelStatusFilter,
    setModelManagerModelFilter,
  };
}
