import {
  Grid,
  Box,
  Slide,
  Button,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { utils as UTILS } from 'solytics-frontend';
import OperationResultsViewer from '../../../Common/OperationResultsViewer';
import MultiSelect from '../../../Common/MultiSelect';
import { SwalToast } from '../../../Common';
import utils from '../../../../utils/utils';
import { TimeSeriesModels } from '../../../../const/ModellingTechniques';
import { ReactComponent as DropdownArrow } from '../../../../assets/images/DropdownArrow.svg';
import { REGULATIONS } from '../../../../const/CommonConst';

const PREFIX = 'OngoingComparison';
const tsRegulationKeys = REGULATIONS.filter((item) => item.isTs).map(
  (item) => item.key
);
const classes = {
  button: `${PREFIX}-button`,
  label: `${PREFIX}-label`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.button}`]: {
    width: '100%',
  },

  [`& .${classes.label}`]: {
    fontWeight: '700',
    color: '#0BB7A7 !important',
    letterSpacing: '1px',
  },
}));

// Cell template to be used to show data with colored background
const ColoredCell = (data) => {
  const { cell } = data;
  const color = cell?.value?.color;
  const value = cell?.value?.value?.toString();
  return (
    <>
      {color && value ? (
        <StyledBox textAlign="left" bgcolor={color}>
          {UTILS.getFormattedNumber(value)}
        </StyledBox>
      ) : (
        <Box title={cell?.value}>{UTILS.getFormattedNumber(cell?.value)}</Box>
      )}
    </>
  );
};

const NormalRender = React.memo(
  ({ formikForm, monDataOptions, allModelData }) => {
    return (
      <>
        <Grid item md={4} sm={6} xs={12}>
          <Autocomplete
            options={
              allModelData
                ? allModelData.filter(
                    (option) => !option?.archive && option?.status === 'SUCCESS'
                  )
                : []
            }
            noOptionsText="No models were trained on this dataset."
            popupIcon={<DropdownArrow />}
            value={formikForm.values.model}
            getOptionLabel={(option) =>
              option.model_name
                ? `${option?.model_name} (${option?.model_technique_label}) ${
                    option?.outputFromPrevNode
                      ? `( ${option?.prevNodeName} )`
                      : ''
                  }`
                : ''
            }
            isOptionEqualToValue={(option, val) => option === val}
            renderOption={(props, option) => (
              <Box
                display="flex"
                flexDirection="row"
                flexGrow={1}
                alignItems="center"
                {...props}
                minHeight="unset !important"
                height="unset !important"
                key={option?.id}
              >
                <Box display="flex" flexGrow={1} flexDirection="column">
                  <Typography variant="body2">
                    {option.model_name
                      ? `${option.model_name} (${option.model_technique_label})`
                      : ''}
                  </Typography>
                </Box>
                {/* for pipeline node */}
                {option.outputFromPrevNode ? (
                  <Box display="flex" alignSelf="flex-end">
                    <Typography variant="caption">
                      Output from previous node : {option?.prevNodeName}
                    </Typography>
                  </Box>
                ) : (
                  <Box display="flex" alignSelf="flex-end">
                    <Typography noWrap variant="caption">
                      {option?.external_pkl ? 'External' : 'Internal'}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            onChange={(event, newValue) => {
              formikForm?.setFieldValue(
                'model',
                newValue || {
                  model_name: '',
                  model_technique: '',
                }
              );
            }}
            disableClearable={['', undefined].includes(
              formikForm?.values?.model?.model_name
            )}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Model"
                placeholder="Select model"
                InputLabelProps={{
                  shrink: true,
                }}
                name="modelId"
                onBlur={formikForm.handleBlur('model')}
                helperText={
                  formikForm?.errors?.model?.model_name &&
                  formikForm?.touched?.model
                    ? formikForm.errors.model?.model_name
                    : null
                }
                error={
                  Boolean(formikForm?.errors?.model?.model_name) &&
                  formikForm?.touched?.model
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={12}>
            <MultiSelect
              required
              name="monitoringDataset"
              options={monDataOptions}
              label="Monitoring datasets"
              placeholder="Select monitoring datasets"
              InputLabelProps={{
                shrink: true,
              }}
              renderOption={(props, option) => (
                <Box
                  display="flex"
                  flexDirection="row"
                  flexGrow={1}
                  alignItems="center"
                  {...props}
                  minHeight="unset !important"
                  height="unset !important"
                  key={option?.id}
                >
                  <Box display="flex" flexGrow={1} flexDirection="column">
                    <Typography variant="body2">
                      {option.label ? `${option.label}` : ''}
                    </Typography>
                  </Box>
                </Box>
              )}
              onBlur={formikForm.handleBlur('monitoringDataset')}
              value={
                formikForm.values.monitoringDataset !== undefined
                  ? formikForm.values.monitoringDataset
                  : []
              }
              noOptionsText="No monitoring datasets available."
              onChange={(e) => {
                formikForm?.setFieldValue('monitoringDataset', e);
              }}
              helperText={
                formikForm?.errors?.monitoringDataset &&
                formikForm?.touched?.monitoringDataset
                  ? formikForm.errors.monitoringDataset
                  : null
              }
              error={
                Boolean(formikForm?.errors?.monitoringDataset) &&
                formikForm?.touched?.monitoringDataset
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }
);

const TimeSeriesRender = React.memo(
  ({ formikForm, targetVariables, datasetList, allModelData }) => (
    <>
      <Grid item md={4} sm={6} xs={12}>
        <Autocomplete
          options={
            allModelData &&
            allModelData.filter((option) =>
              TimeSeriesModels.includes(option.model_technique)
            ).length
              ? allModelData.filter(
                  (option) =>
                    TimeSeriesModels.includes(option.model_technique) &&
                    !option?.archive &&
                    option?.status === 'SUCCESS'
                )
              : []
          }
          value={formikForm.values.model}
          disableClearable={['', undefined].includes(
            formikForm?.values?.model?.model_name
          )}
          getOptionLabel={(option) =>
            option.model_name
              ? `${option.model_name} (${option.model_technique_label})`
              : ''
          }
          isOptionEqualToValue={(option, val) => option === val}
          renderOption={(props, option) => (
            <Box
              display="flex"
              flexDirection="row"
              flexGrow={1}
              alignItems="center"
              {...props}
              minHeight="unset !important"
              height="unset !important"
              key={option?.id}
            >
              <Box display="flex" flexGrow={1} flexDirection="column">
                <Typography variant="body2">
                  {option.model_name
                    ? `${option.model_name} (${option.model_technique_label})`
                    : ''}
                </Typography>
              </Box>
              {option.outputFromPrevNode ? (
                <Box display="flex" alignSelf="flex-end">
                  <Typography variant="caption">
                    Output from previous node : {option?.prevNodeName}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" alignSelf="flex-end">
                  <Typography noWrap variant="caption">
                    {option?.external_pkl ? 'External' : 'Internal'}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          noOptionsText="No model were trained on this dataset."
          popupIcon={<DropdownArrow />}
          onChange={(event, newValue) => {
            formikForm?.setFieldValue(
              'model',
              newValue || {
                model_name: '',
                model_technique: '',
              }
            );
          }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Model"
              placeholder="Select model"
              InputLabelProps={{
                shrink: true,
              }}
              name="modelId"
              onBlur={formikForm.handleBlur('model')}
              helperText={
                formikForm?.errors?.model?.model_name &&
                formikForm?.touched?.model
                  ? formikForm.errors.model?.model_name
                  : null
              }
              error={
                Boolean(formikForm?.errors?.model?.model_name) &&
                formikForm?.touched?.model
              }
            />
          )}
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <MultiSelect
          required
          name="outSampleDataset"
          options={
            datasetList.length >= 1
              ? datasetList
                  ?.filter((dataset) => dataset?.published === true)
                  ?.map((option) => ({
                    id: option.id,
                    value: option.name,
                    label: option.table_display_name,
                  }))
              : []
          }
          label="Out sample dataset"
          placeholder="Select out sample dataset"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={formikForm.handleBlur('outSampleDataset')}
          value={
            formikForm.values.outSampleDataset !== undefined
              ? formikForm.values.outSampleDataset
              : []
          }
          noOptionsText="No out sample dataset available."
          onChange={(e) => {
            formikForm?.setFieldValue('outSampleDataset', e);
          }}
          helperText={
            formikForm?.errors?.outSampleDataset &&
            formikForm?.touched?.outSampleDataset
              ? formikForm.errors.outSampleDataset?.value
              : null
          }
          error={
            Boolean(formikForm?.errors?.outSampleDataset) &&
            formikForm?.touched?.outSampleDataset
          }
        />
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Autocomplete
          name="targetColumn"
          options={targetVariables}
          value={formikForm.values.targetColumn}
          noOptionsText="No target variable available."
          disableClearable={['', undefined].includes(
            formikForm?.values?.targetColumn?.column_name
          )}
          getOptionLabel={(option) =>
            option.column_name
              ? `${option.column_name} (${option.data_type})`
              : ''
          }
          isOptionEqualToValue={(option, val) =>
            option?.column_name === val?.column_name
          }
          popupIcon={<DropdownArrow />}
          onChange={(event, newValue) => {
            formikForm?.setFieldValue(
              'targetColumn',
              newValue || {
                column_name: '',
                data_type: '',
              }
            );
          }}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              label="Target variable"
              placeholder="Select target variable"
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={formikForm.handleBlur('targetColumn')}
              helperText={
                formikForm?.errors?.targetColumn?.column_name &&
                formikForm?.touched?.targetColumn
                  ? formikForm.errors.targetColumn?.column_name
                  : null
              }
              error={Boolean(
                formikForm?.errors?.targetColumn?.column_name &&
                  formikForm?.touched?.targetColumn
                  ? formikForm.errors.targetColumn?.column_name
                  : null
              )}
            />
          )}
        />
      </Grid>
    </>
  )
);

/**
 * @function OngoingComparision
 * @description Ongoing Comparision Operation - child
 *  component of Performance Testing - building UI of Performance Testing
 * @param {object} activeDataset - currently active dataset
 * @param {Array} datasetList - list of dataset associated with the active project.
 * @param {object} taskResults - object containing task details like graphs, tables and table ids.
 * @param {object} parameters - parameters of task details, use to
 *  repopulate the form fields upon redirection from job queue.
 * @param {object} allModelData - object containing details of
 *  all the models associated with the active project.
 * @param {function} onOngoingComparisionSubmit - function for
 *  making request of Ongoing Comparision operation.
 * @param {function} onTsOngoingComparisionSubmit - function for
 * making request of Time Series Ongoing Comparision operation.
 * @param {Array} targetVariables - list of target variables present in the active dataset
 * @param {object} activeProject - object containing details of active
 *  project like project regulation, type, datasetcount etc.
 * @param {Array} datasetColumnDetails - list of all columns present in
 *  current selected dataset and properties like data type , type etc
 * @param {object} permission - object containing boolean value for create, read, write, delete permission
 * @param {boolean} isImportedInPipeline - boolean value to check if the
 * current dataset is imported in pipeline or not.
 * @param {function} getOperaionForm - function to get the formik form
 * @param {function} onAddTableChartClick - function to add table chart in the task results
 * @param {string} moduleName - name of the module in which the operation is used
 * @param {string} subModuleName - name of the sub module in which the operation is used
 * @returns {JSX} OngoingComparision UI
 * @example <OngoingComparision activeDataset={activeDataset} datasetList={datasetList}/>
 */
const OngoingComparision = ({
  activeDataset,
  datasetList,
  taskResults,
  parameters,
  allModelData,
  onOngoingComparisionSubmit,
  onTsOngoingComparisionSubmit,
  targetVariables,
  activeProject,
  datasetColumnDetails,
  permission,
  isImportedInPipeline = false,
  getOperaionForm = (form) => form,
  onAddTableChartClick,
  moduleName,
  subModuleName,
  addResultToModelMetaData,
  fetchModelMetaDataSchema,
}) => {
  // const [monitoringParams, setMonitoringParams] = useState([]);
  const formValidationSchema = Yup.object({
    monitoringDataset: Yup.array()
      .nullable()
      .min(1, 'Required')
      .of(
        Yup.object()
          .shape({
            label: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
          })
          .required('Required')
      ),
    model: Yup.object().shape({
      id: Yup.string().required('Required'),
      model_name: Yup.string().required('Required'),
    }),
    external_pkl_value: Yup.boolean(),
  });

  const formikForm = useFormik({
    initialValues: {
      monitoringDataset: [],
      model: {
        id: '',
        model_name: '',
      },
      developmentData: '',
      targetColumn: '',
      external_pkl_value: '',
    },
    validationSchema: formValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const body = {
        dataset_id_monitoring: formikForm.values.monitoringDataset.map(
          (data) => data.id
        ),
        model_id: values.model?.modelId
          ? values.model?.modelId
          : formikForm.values.model.id,
        dataset_id_development: activeDataset.id,
        target_column: formikForm.values.targetColumn,
        project_id: activeProject.project_id,
        user: JSON.parse(localStorage.getItem('user_data')).username,
        dataset_id: activeDataset.id,
      };

      onOngoingComparisionSubmit(
        body,

        () => {
          SwalToast({
            icon: 'success',
            title:
              'The operation is scheduled for execution. Please check the job queue for further updates.',
          });
          window.dispatchEvent(new Event('UPDATE_UREAD_JOB_COUNTS'));
        }
      );
    },
  });
  const formValidationSchemaTs = Yup.object({
    model: Yup.object().shape({
      id: Yup.string().required('Required'),
      model_name: Yup.string().required('Required'),
    }),
    targetColumn: Yup.object().shape({
      column_name: Yup.string().required('Required'),
      data_type: Yup.string().required('Required'),
    }),
    outSampleDataset: Yup.array()
      .nullable()
      .min(1, 'Required')
      .of(
        Yup.object()
          .shape({
            label: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
          })
          .required('Required')
      ),
    // dateTimeIndex:Yup.string().required('Required'),
  });

  const formikFormTs = useFormik({
    initialValues: {
      model: {
        id: '',
        model_name: '',
      },
      outSampleDataset: [],
      targetColumn: {
        column_name: '',
        data_type: '',
      },
      // dateTimeIndex:'',
    },
    validationSchema: formValidationSchemaTs,
    onSubmit: (values) => {
      const body = {
        project_id: activeProject.project_id,
        dataset_id: activeDataset.id,
        model_id: values.model?.modelId
          ? values.model?.modelId
          : formikFormTs.values.model.id,
        dataset_id_test: formikFormTs.values.outSampleDataset.map(
          (data) => data.id
        ),
        target_column: formikFormTs.values.targetColumn.column_name,
        user: JSON.parse(localStorage.getItem('user_data')).username,
        // datetime_index:formikFormTs.values.dateTimeIndex,
      };

      onTsOngoingComparisionSubmit(
        body,

        () => {
          SwalToast({
            icon: 'success',
            title:
              'The operation is scheduled for execution. Please check the job queue for further updates.',
          });
          window.dispatchEvent(new Event('UPDATE_UREAD_JOB_COUNTS'));
        },
        () => {}
      );
    },
  });

  const [monDataOptions, setMonDataOptions] = useState([]);
  const [devDataOptions, setDevDataOptions] = useState([]);
  useEffect(() => {
    const devArr = [];
    const monArr = [];

    // Code to set options for monitoring datasets
    if (activeProject?.project_id) {
      datasetList
        .filter((dataset) => {
          if (
            dataset.project_id.includes(activeProject.project_id) &&
            dataset.preprocessed === 'done' &&
            dataset.published === true
          ) {
            // code commented for NNG-10191
            // if (
            //   // table769 === table769
            //   data.Name === activeDataset.Name ||
            //   // table769 , table769_train
            //   data.Name.split('_').includes(activeDataset.Name) ||
            //   // table769_train , table769
            //   activeDataset.Name.split('_').includes(data.Name) ||
            //   // table769_train , table769_test
            //   data.Name.split('_').includes(activeDataset.Name.split('_')[0]) ||
            //   // table769_test , table769_train
            //   activeDataset.Name.split('_').includes(data.Name.split('_')[0])
            // ) {
            //   return data;
            // }
            return dataset;
          }
          return 0;
        })
        .forEach((data) => {
          const object = {};
          object.label = data.table_display_name;
          object.id = data.id;
          object.value = data.name;

          monArr.push(object);
        });

      setMonDataOptions(monArr);
    }

    // Code to set options for development datasets
    if (allModelData) {
      allModelData.forEach((data) => {
        const object = {};
        object.label = `${data.model_name} : (${data.model_technique})`;
        object.value = data.id;
        devArr.push(object);
      });

      // condition to check if the array is empty
      if (devArr.length === 0) {
        setDevDataOptions([
          {
            label: 'No models were trained on this dataset.',
            value: '',
          },
        ]);
      } else {
        setDevDataOptions(devArr);
      }
    }

    // Code to set Target Column
    if (allModelData && formikForm.values.model?.id !== '') {
      allModelData.forEach((data) => {
        if (data.id === formikForm.values.model?.id) {
          formikForm.setFieldValue('external_pkl_value', data.external_pkl);
          formikForm.setFieldValue('targetColumn', data.target_column);
        }
      });
    }
  }, [datasetList, allModelData, formikForm.values.model, activeDataset]);

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(parameters).length !== 0 && datasetList?.length) {
        if (tsRegulationKeys.includes(activeProject.regulation)) {
          // const tableName = datasetList.find(
          //   (data) => data.id === parameters.datasetIdTest
          // ).Name;
          // const outDataset = utils.getDatasetObjectBydatasetName(
          //   datasetList,
          //   tableName
          // )?.[0];

          const tempObject = [];
          datasetList.forEach((ele) => {
            if (parameters?.dataset_id_test.includes(ele.id)) {
              const object = {};
              object.label = ele.table_display_name;
              object.value = ele.name;
              object.id = ele.id;
              tempObject.push(object);
            }
          });

          // @ts-ignore
          formikFormTs.setValues({
            model: allModelData.find(
              (model) =>
                model?.id === Number(parameters?.model_id) ||
                model?.modelId === Number(parameters?.model_id) ||
                model?.id === parameters?.model_id ||
                model?.modelId === parameters?.model_id ||
                utils.checkForIds(model?.modelId, parameters?.model_id) ||
                utils.checkForIds(model?.modelId, parameters?.model_id)
            ),
            outSampleDataset: tempObject,
            targetColumn: targetVariables.find(
              (target) => target.column_name === parameters.target_column
            ),
          });
        } else {
          const tempObject = [];
          datasetList.forEach((ele) => {
            if (parameters?.dataset_id_monitoring.includes(ele.id)) {
              const object = {};
              object.label = ele.table_display_name;
              object.value = ele.name;
              object.id = ele.id;
              tempObject.push(object);
            }
          });
          formikForm.setValues({
            monitoringDataset: tempObject,
            model: allModelData.find(
              (model) =>
                model?.id === Number(parameters?.model_id) ||
                model?.modelId === Number(parameters?.model_id) ||
                model?.id === parameters?.model_id ||
                model?.modelId === parameters?.model_id ||
                utils.checkForIds(model?.modelId, parameters?.model_id)
            ),
            developmentData: parameters?.development_data,
            targetColumn: parameters?.target_column,
            external_pkl_value: '',
          });
        }
      }
    }, 2000);
  }, [parameters, datasetList, allModelData, targetVariables]);

  useEffect(() => {
    formikForm.handleReset();
    formikFormTs.handleReset();
  }, [activeDataset]);

  useEffect(() => {
    if (
      datasetColumnDetails?.length &&
      !datasetColumnDetails?.filter((data) => data?.target_timeseries === true)
        ?.length &&
      tsRegulationKeys.includes(activeProject.regulation)
    ) {
      SwalToast({
        icon: 'warning',
        title:
          'The time variable is not selected for the selected dataset. Please select time variable from the Data Ingestion page.',
      });
    }
  }, [datasetColumnDetails]);

  useEffect(() => {
    if (isImportedInPipeline) {
      if (tsRegulationKeys.includes(activeProject.regulation)) {
        getOperaionForm(formikFormTs);
      } else {
        getOperaionForm(formikForm);
      }
    }
  }, [
    formikForm,
    formikFormTs,
    isImportedInPipeline,
    tsRegulationKeys,
    activeProject,
  ]);
  return (
    <Slide in={true} direction="left" mountOnEnter timeout={700}>
      <Box>
        <Grid
          container
          flexDirection={
            taskResults?.is_chart || taskResults?.is_table ? 'row' : 'column'
          }
          spacing={2}
          pt={1}
          mb={2}
        >
          {activeProject &&
          tsRegulationKeys.includes(activeProject.regulation) ? (
            <TimeSeriesRender
              formikForm={formikFormTs}
              devDataOptions={devDataOptions}
              datasetColumnDetails={datasetColumnDetails}
              datasetList={datasetList}
              targetVariables={targetVariables}
              allModelData={allModelData}
              taskResults={taskResults}
            />
          ) : (
            <NormalRender
              formikForm={formikForm}
              devDataOptions={devDataOptions}
              monDataOptions={monDataOptions}
              allModelData={allModelData}
              taskResults={taskResults}
            />
          )}
        </Grid>
        {!isImportedInPipeline && (
          <Grid container item columnSpacing={2} xs="auto" mt={3}>
            <Grid item xs="auto" display="flex" alignItems="end">
              <Button
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => {
                  if (tsRegulationKeys.includes(activeProject.regulation)) {
                    formikFormTs.handleReset();
                  } else {
                    formikForm.handleReset();
                  }
                }}
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? 'You are not authorized to access this!'
                    : ''
                }
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs display="flex" alignItems="end">
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  if (tsRegulationKeys.includes(activeProject.regulation))
                    formikFormTs.handleSubmit();
                  else formikForm.handleSubmit();
                }}
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? 'You are not authorized to access this!'
                    : ''
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
        <OperationResultsViewer
          taskResults={taskResults}
          // activeDataset={activeDataset}
          permission={permission}
          operationName="ONGOING COMPARISON"
          customCell={ColoredCell}
          onAddChartClick={onAddTableChartClick}
          onAddTableClick={onAddTableChartClick}
          activeProject={activeProject}
          moduleName={moduleName}
          subModuleName={subModuleName}
          addResultToModelMetaData={addResultToModelMetaData}
          fetchModelMetaDataSchema={fetchModelMetaDataSchema}
        />
      </Box>
    </Slide>
  );
};
OngoingComparision.defaultProps = {
  isImportedInPipeline: false,
  getOperaionForm: (form) => form,
  fetchModelMetaDataSchema: null,
  addResultToModelMetaData: null,
};

NormalRender.propTypes = {
  formikForm: PropTypes.oneOfType([PropTypes.object]).isRequired,
  monDataOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  devDataOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allModelData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
TimeSeriesRender.propTypes = {
  formikForm: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allModelData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  monDataOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  devDataOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
  datasetColumnDetails: PropTypes.oneOfType([PropTypes.object]).isRequired,
  datasetList: PropTypes.oneOfType([PropTypes.object]).isRequired,
  targetVariables: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
OngoingComparision.propTypes = {
  activeDataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  datasetList: PropTypes.oneOfType([PropTypes.object]).isRequired,
  taskResults: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allModelData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  parameters: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  targetVariables: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onOngoingComparisionSubmit: PropTypes.func.isRequired,
  onTsOngoingComparisionSubmit: PropTypes.func.isRequired,
  datasetColumnDetails: PropTypes.oneOfType([PropTypes.object]).isRequired,
  permission: PropTypes.string.isRequired,
  isImportedInPipeline: PropTypes.bool,
  getOperaionForm: PropTypes.func,
  moduleName: PropTypes.string.isRequired,
  subModuleName: PropTypes.string.isRequired,
  onAddTableChartClick: PropTypes.func.isRequired,
  addResultToModelMetaData: PropTypes.func,
  fetchModelMetaDataSchema: PropTypes.func,
};

export default OngoingComparision;
