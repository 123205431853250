/* eslint-disable no-nested-ternary */
import React from 'react';
import Box from '@mui/material/Box';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ReactComponent as NoDatasetFound } from '../../assets/images/NoDatasetFound.svg';
import { ReactComponent as NoDataFound } from '../../assets/images/NoDataFound.svg';

function NoDataFoundComponent({
  page,
  rows,
  globalFilter,
  data,
  enablePagination,
}) {
  return (
    <Box>
      {data?.length <= 0 ? (
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <NoDatasetFound />
            <Typography
              variant="subtitle1"
              textAlign="center"
              sx={{ mt: '35px' }}
            >
              No data present !
            </Typography>
          </Box>
        </Box>
      ) : !_.isEmpty(globalFilter) &&
        (enablePagination ? page : rows).length <= 0 ? (
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt="30px"
        >
          <NoDataFound />
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ py: '24px' }}
          >
            No matching result found.
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

NoDataFoundComponent.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  page: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  // eslint-disable-next-line react/require-default-props
  globalFilter: PropTypes.string,
  enablePagination: PropTypes.bool.isRequired,
};

export default NoDataFoundComponent;
