import { REGULATIONS } from './CommonConst';
import ApiEndpoints from './ApiEndpoints';

const combineDatasetsSvgIcon = require('../assets/images/DataManagement/DP/CombineDatasetsOperationIcon.svg');
const editCellOperationIcon = require('../assets/images/DataManagement/DP/EditCellOperationIcon.svg');
const appendSvgIcon = require('../assets/images/DataManagement/DP/AppendOperationIcon.svg');
const transposeSvgIcon = require('../assets/images/DataManagement/DP/TransposeOperationIcon.svg');
const summaryStatsSvgIcon = require('../assets/images/EDA/PreliminaryStepsIcons/SummaryStatisticsOperationIcon.svg');
const overallSummaryStatsSvgIcon = require('../assets/images/EDA/PreliminaryStepsIcons/OverallSummaryStatisticsOperationIcon.svg');
const treatingOutliersSvgIcon = require('../assets/images/EDA/TreatingOutliersIcons/TreatingOutlierOperationIcon.svg');
const missingValuesSvgIcon = require('../assets/images/EDA/MissingValuesImputationIcons/MissingValuesImputation.svg');
const univariateSvgIcon = require('../assets/images/EDA/VariableAnalysisIcons/UnivariateOperationIcon.svg');
const bivariateSvgIcon = require('../assets/images/EDA/VariableAnalysisIcons/BiVariateOperationIcon.svg');
const multivariateSvgIcon = require('../assets/images/EDA/VariableAnalysisIcons/MultiVariateOperationIcon.svg');
const nonParametricSvgIcon = require('../assets/images/EDA/StatisticalTestsIcons/NonParametricTestsOperationIcon.svg');
const normalitySvgIcon = require('../assets/images/EDA/StatisticalTestsIcons/TestsForNormalityOperationIcon.svg');
const parametricSvgIcon = require('../assets/images/EDA/StatisticalTestsIcons/ParametricTestsOperationIcon.svg');
const homogeneitySvgIcon = require('../assets/images/EDA/StatisticalTestsIcons/HomogeneityOfVariancesOperationIcon.svg');
const stationarySvgIcon = require('../assets/images/EDA/TimeSeriesAnalysis/StationaryTestsOperationIcon.svg');
const acfPacfPlotSvgIcon = require('../assets/images/EDA/TimeSeriesAnalysis/AcfPacfPlotsOperationIcon.svg');
const grangerCasualtyTestSvgIcon = require('../assets/images/EDA/TimeSeriesAnalysis/GrangerCasualityTestOperationIcon.svg');
const cointegrationTestSvgIcon = require('../assets/images/EDA/TimeSeriesAnalysis/ContegrationTestOperationIcon.svg');
const seasonalityTestSvgIcon = require('../assets/images/EDA/TimeSeriesAnalysis/SeasonalityTestOperationIcon.svg');
const NumericalIcon = require('../assets/images/FeatureEngineering/NumericTransformation.svg');
const CategoricalIcon = require('../assets/images/FeatureEngineering/CategoricalTransformation.svg');
const TimeSeriesIcon = require('../assets/images/FeatureEngineering/TimeSeriesTransformation.svg');
const visualizaeImbalanceSvgIcon = require('../assets/images/Sampling/VisualizeImbalanceOperationIcon.svg');
const samplingSvgIcon = require('../assets/images/Sampling/SamplingOperationIcon.svg');
const resamplingSvgIcon = require('../assets/images/Sampling/ResamplingOperationIcon.svg');
const splittingSvgIcon = require('../assets/images/Sampling/SplittingOperationIcon.svg');
const clusteringSvgIcon = require('../assets/images/Segmentation/ClusteringOperationIcon.svg');
const decisionTreeSvgIcon = require('../assets/images/Segmentation/DecisionTreeOperationIcon.svg');
const ruleBaseSvgIcon = require('../assets/images/Segmentation/RuleBasedOperationIcon.svg');
const missingvalues = require('../assets/images/VariableReduction/MissingValuesOperationIcon.svg');
const PCA = require('../assets/images/VariableReduction/PCA.svg');
const lowVariance = require('../assets/images/VariableReduction/LowVarianceOperationIcon.svg');
const highcorrelation = require('../assets/images/VariableReduction/HighCorelationOperationIcon.svg');
const randomForest = require('../assets/images/VariableReduction/RandomForestOperationIcon.svg');
const backwardFeatureSelection = require('../assets/images/VariableReduction/BackwardFeatureSelectionOperationIcon.svg');
const forwardFeatureSelection = require('../assets/images/VariableReduction/ForwardFeatureSelectionOperationIcon.svg');
const clusteringIcon = require('../assets/images/VariableReduction/ClusteringOperationOutlinedIcon.svg');
const ivValues = require('../assets/images/VariableReduction/IVValuesOperationIcon.svg');
const variableFinalisation = require('../assets/images/VariableReduction/VariableFinalisation.svg');
const LassoFeatureSelection = require('../assets/images/VariableReduction/LassoFeatureSelectionOperationIcon.svg');
const RidgeFeatureSelection = require('../assets/images/VariableReduction/RidgeFeatureSelectionOperationIcon.svg');
const StepwiseFeatureSelection = require('../assets/images/VariableReduction/StepwiseFeatureSelectionOperationIcon.svg');
const metricSelection = require('../assets/images/ModelEstimation/MetricSelectionOperationIcon.svg');
const saveAndDeploy = require('../assets/images/ModelEstimation/SaveAndDeployModelOperationIcon.svg');
const lowVarianceMt = require('../assets/images/ModelTesting/PerformanceTesting/OngoingComparisonOperationIcon.svg');
const randomForestMt = require('../assets/images/ModelTesting/PerformanceTesting/ModelComparisonOperationIcon.svg');
const highCorrelation = require('../assets/images/ModelTesting/PerformanceTesting/AssumptionTestingOperationIcon.svg');
const scoring = require('../assets/images/ModelTesting/ScoringScaling/ScoringOperationIcon.svg');
const scaling = require('../assets/images/ModelTesting/ScoringScaling/ScalingOperationIcon.svg');
const rankOrder = require('../assets/images/ModelTesting/BackTesting/RankOrderOperationIcon.svg');
const kFold = require('../assets/images/ModelTesting/BackTesting/KFoldOperationIcon.svg');
const rulerCombinedSolid = require('../assets/images/ruler-combined-solid.svg');
const sensitivityAnalysis = require('../assets/images/ModelTesting/SensitivityAnalysis/SensitivityAnalysisOperationIcon.svg');
const modelCalibration = require('../assets/images/ModelTesting/ModelRecalibration/ModelCalibrationOperationIcon.svg');
const Forcasting = require('../assets/images/ModelTesting/Forecasting/ForecastingOperationIcon.svg');
const deatureImportanceSvgIcon = require('../assets/images/ModelInterpretationIcons/FeatureInterpretation/FeatureImportanceOperationIcon.svg');
const featureDependenceSvgIcon = require('../assets/images/ModelInterpretationIcons/FeatureInterpretation/FeatureDependenceOperationIcon.svg');
const realtiveContributionSvgIcon = require('../assets/images/ModelInterpretationIcons/FeatureInterpretation/RelativeContributionOperationIcon.svg');
const classificationPlotsSvgIcon = require('../assets/images/ModelInterpretationIcons/ClassificationStats/ClassificationPlotsOperationIcon.svg');
const aucLiftCurvesSvgIcon = require('../assets/images/ModelInterpretationIcons/ClassificationStats/AucLiftCurvesOperationIcon.svg');
const predictionSvgIcon = require('../assets/images/ModelInterpretationIcons/IndividualPrediction/PredictionOperationIcon.svg');
const contributionScgIcon = require('../assets/images/ModelInterpretationIcons/IndividualPrediction/ContributionOperationIcon.svg');
const partialDependenceSvgIcon = require('../assets/images/ModelInterpretationIcons/IndividualPrediction/PartialDependencePlotOperationIcon.svg');
const barPlotVisualizationSvgIcon = require('../assets/images/ModelInterpretationIcons/DecisionTrees/BarPlotOperationIcon.svg');
const decisionTreeVisualizationSvgIcon = require('../assets/images/ModelInterpretationIcons/DecisionTrees/DecisionTreeOperationOutlinedIcon.svg');
const BiasnessOperationIcon = require('../assets/images/ModelInterpretationIcons/Fairness/BiasnessOperationIcon.svg');
const FairnessOperationIcon = require('../assets/images/ModelInterpretationIcons/Fairness/FairnessOperationIcon.svg');
const VIFTableOperationIcon = require('../assets/images/ModelInterpretationIcons/TimeSeriesAnalysis/VIFTableOperationIcon.svg');
const CoefficientPlotOperationIcon = require('../assets/images/ModelInterpretationIcons/TimeSeriesAnalysis/CoefficientPlotOperationIcon.svg');
const ModelSummaryOperationIcon = require('../assets/images/ModelInterpretationIcons/TimeSeriesAnalysis/ModelSummaryOperationIcon.svg');
const DataDriftOperationIcon = require('../assets/images/ModelInterpretationIcons/ModelDrift/DataDriftOperation.svg');
// const BestModelSelectionOperationIcon = require('../assets/images/ModelDeployment/BestModelSelectionOperation.svg');
const AnalyseSvgIcon = require('../assets/images/DataManagement/DQ/AnalyseOperationIcon.svg');
const ManageSpacesSvgIcon = require('../assets/images/DataManagement/DQ/ManageSpacesOperationIcon.svg');
const ManageCasesSvgIcon = require('../assets/images/DataManagement/DQ/ManageCasesOperationIcon.svg');
const valueErrorSvgIcon = require('../assets/images/DataManagement/DQ/ValueErrorOperationIcon.svg');
const StandardisationErrorSvgIcon = require('../assets/images/DataManagement/DQ/StdErrorOperationIcon.svg');
const EmbeddedValuesSvgIcon = require('../assets/images/DataManagement/DQ/EmbeddedValuesOperationIcon.svg');
const IntegrityRulesSvgIcon = require('../assets/images/DataManagement/DQ/IntegrityValuesOperationIcon.svg');

const RegularExpressisonSvgIcon = require('../assets/images/DataManagement/DV/RegularExpressionOperationIcon.svg');
const BlankValuesSvgIcon = require('../assets/images/DataManagement/DV/BlankValuesOperationIcon.svg');
const UniquenessSvgIcon = require('../assets/images/DataManagement/DV/UniquenessOperationIcon.svg');
const DuplicateRowsColsSvgIcon = require('../assets/images/DataManagement/DV/DuplicateRowsAndColumnsOperationIcon.svg');
const DuplicateColSvgIcon = require('../assets/images/DataManagement/DV/FindDuplicate.svg');
const DuplicateRowSvgIcon = require('../assets/images/DataManagement/DV/FindDuplicateRow.svg');
const SortScoresSvgIcon = require('../assets/images/DataManagement/DV/ScoresOperationIcon.svg');
const SortColSvgIcon = require('../assets/images/DataManagement/DP/SortColOperationIcon.svg');
const RenameColSvgIcon = require('../assets/images/DataManagement/DP/RenameColumnOperationIcon.svg');
const FindAndReplaceSvgIcon = require('../assets/images/DataManagement/DP/FindAndReplaceOperationIcon.svg');
const DeleteRowColSvgIcon = require('../assets/images/DataManagement/DP/DeleteRowsAndColumnsOperationIcon.svg');
const AddRowColSvgIcon = require('../assets/images/DataManagement/DP/AddRowsAndColumnsOperationIcon.svg');
const SplitColSvgIcon = require('../assets/images/DataManagement/DP/SplitColOperationIcon.svg');

// eslint-disable-next-line no-unused-vars
const allRegulationKeys = REGULATIONS.map((item) => item.key);
const tsRegulationKeys = REGULATIONS.filter((item) => item.isTs).map(
  (item) => item.key
);
const nonTsRegulationKeys = REGULATIONS.filter((item) => !item.isTs).map(
  (item) => item.key
);

const BASE_URL = ApiEndpoints.MODULE_BASE_URL;

const MODULE_SUB_MODULE_OPERATIONS_TREE = {
  PO: {
    objKey: 'PO',
    key: 'ProjectOverview',
    label: 'Project Overview',
    regulation: allRegulationKeys,
    show: false,
    subModules: {
      PC: {
        objKey: 'PC',
        key: 'CreateProject',
        label: 'Create project',
        regulation: allRegulationKeys,
        show: false,
      },
      PE: {
        objKey: 'PE',
        key: 'EditProject',
        label: 'Edit project',
        regulation: allRegulationKeys,
        show: false,
      },
      PD: {
        objKey: 'PD',
        key: 'DeleteProject',
        label: 'Delete project',
        regulation: allRegulationKeys,
        show: false,
      },
      PA: {
        objKey: 'PA',
        key: 'ArtifactsSharing',
        label: 'Artifacts sharing',
        regulation: allRegulationKeys,
        show: false,
      },
      AP: {
        objKey: 'AP',
        key: 'ArchiveProject',
        label: 'Archive project',
        regulation: allRegulationKeys,
        show: false,
      },
      CP: {
        objKey: 'CP',
        key: 'CloneProject',
        label: 'Clone project',
        regulation: allRegulationKeys,
        show: false,
      },
      SP: {
        objKey: 'SP',
        key: 'ShareProject',
        label: 'Share project',
        regulation: allRegulationKeys,
        show: false,
      },
      MMD: {
        objKey: 'MMD',
        key: 'ModelMonitoringDashboard',
        label: 'Model Monitoring Dashboard',
        regulation: allRegulationKeys,
        show: false,
      },
    },
  },
  DM: {
    objKey: 'DM',
    key: 'DataManagement',
    label: 'Data Management',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      DI: {
        objKey: 'DI',
        key: 'DataIngestion',
        label: 'Data Ingestion',
        regulation: allRegulationKeys,
        show: true,
      },
      DPA: {
        objKey: 'DPA',
        key: 'DataPreparation',
        label: 'Data Preparation & Adjustment',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          CD: {
            objKey: 'CD',
            key: 'CombineDatasets',
            label: 'Combine Datasets',
            icon: combineDatasetsSvgIcon,
            desc: 'Used to combine the datasets.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.DM + ApiEndpoints.DM.DP_OP_COMBINE,
          },
          AP: {
            objKey: 'AP',
            key: 'Append',
            label: 'Append',
            icon: appendSvgIcon,
            desc: 'Used to append with the datasets.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.DM + ApiEndpoints.DM.DP_OP_APPEND,
          },
          // ARC: {
          //   objKey: 'ARC',
          //   key: 'AddRowsColumns',
          //   label: 'Add Rows/Columns',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // DR: {
          //   objKey: 'DR',
          //   key: 'DeleteRow',
          //   label: 'Delete Row',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // DC: {
          //   objKey: 'DC',
          //   key: 'DeleteColumn',
          //   label: 'Delete Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // DRC: {
          //   objKey: 'DRC',
          //   key: 'DeleteRowCol',
          //   label: 'Delete Row/Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // RC: {
          //   objKey: 'RC',
          //   key: 'RenameColumn',
          //   label: 'Rename Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // FAR: {
          //   objKey: 'FAR',
          //   key: 'FindAndReplace',
          //   label: 'Find and Replace',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // EC: {
          //   objKey: 'EC',
          //   key: 'EditACell',
          //   label: 'EditACell',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // DUR: {
          //   objKey: 'DUR',
          //   key: 'DuplicateRow',
          //   label: 'Duplicate Row',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // DUC: {
          //   objKey: 'DUC',
          //   key: 'DuplicateColumn',
          //   label: 'Duplicate Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // DURC: {
          //   objKey: 'DURC',
          //   key: 'DuplicateRowCol',
          //   label: 'Duplicate Row/Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          // SC: {
          //   objKey: 'SC',
          //   key: 'SortColumn',
          //   label: 'Sort Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
          TR: {
            objKey: 'TR',
            key: 'Transpose',
            label: 'Transpose',
            icon: transposeSvgIcon,
            desc: 'Used to transpose.',
            regulation: [],
            showOp: true,
            runOpUrl: BASE_URL.DM + ApiEndpoints.DM.DP_OP_TRANSPOSE,
          },
          // SPC: {
          //   objKey: 'SPC',
          //   key: 'SplitColumn',
          //   label: 'Split Column',
          //   icon: '',
          //   desc: '',
          //   regulation: [],
          //   showOp: false,
          // },
        },
      },
      GR: {
        objKey: 'GR',
        key: 'Grid',
        label: 'Grid',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          AN: {
            objKey: 'AN',
            key: 'Analyse',
            icon: AnalyseSvgIcon,
            label: 'Analyse',
            regulation: allRegulationKeys,
          },
          EC: {
            objKey: 'EC',
            key: 'EditACell',
            label: 'EditACell',
            icon: editCellOperationIcon,
            desc: 'Edit a cell',
            regulation: allRegulationKeys,
            showOp: true,
          },
          MS: {
            objKey: 'MS',
            key: 'ManageSpaces',
            icon: ManageSpacesSvgIcon,
            label: 'Manage Spaces',
            regulation: allRegulationKeys,
          },
          MC: {
            objKey: 'MC',
            key: 'ManageCases',
            icon: ManageCasesSvgIcon,
            label: 'Manage Cases',
            regulation: allRegulationKeys,
          },
          VE: {
            objKey: 'VE',
            key: 'ValueError',
            icon: valueErrorSvgIcon,
            label: 'Value Error',
            regulation: allRegulationKeys,
          },
          SE: {
            objKey: 'SE',
            key: 'StandardisationError',
            icon: StandardisationErrorSvgIcon,
            label: 'Standardisation Error',
            regulation: allRegulationKeys,
          },
          EV: {
            objKey: 'EV',
            key: 'EmbeddedValues',
            icon: EmbeddedValuesSvgIcon,
            label: 'Embedded Values',
            regulation: allRegulationKeys,
          },
          IR: {
            objKey: 'IR',
            key: 'IntegrityRules',
            icon: IntegrityRulesSvgIcon,
            label: 'Integrity Rules',
            regulation: allRegulationKeys,
          },
          RP: {
            objKey: 'RP',
            key: 'RegexPercentage',
            icon: RegularExpressisonSvgIcon,
            label: 'Regex Percentage',
            regulation: allRegulationKeys,
          },
          RBV: {
            objKey: 'RBV',
            key: 'ReplaceBlankValues',
            icon: BlankValuesSvgIcon,
            label: 'Replace Blank Values',
            regulation: allRegulationKeys,
          },
          BVP: {
            objKey: 'BVP',
            key: 'BlankValuePercentage',
            icon: BlankValuesSvgIcon,
            label: 'Blank Value Percentage',
            regulation: allRegulationKeys,
          },
          UQ: {
            objKey: 'UQ',
            key: 'Uniqueness',
            icon: UniquenessSvgIcon,
            label: 'Uniqueness',
            regulation: allRegulationKeys,
          },
          SC: {
            objKey: 'SC',
            key: 'Scores',
            icon: SortScoresSvgIcon,
            label: 'Scores',
            regulation: allRegulationKeys,
          },
          SPC: {
            objKey: 'SPC',
            key: 'SplitColumn',
            icon: SplitColSvgIcon,
            label: 'Split Column',
            regulation: allRegulationKeys,
          },
          SO: {
            objKey: 'SO',
            key: 'Sort',
            icon: SortColSvgIcon,
            label: 'Sort',
            regulation: allRegulationKeys,
          },
          FCD: {
            objKey: 'FCD',
            key: 'FindColumnDuplicates',
            icon: DuplicateColSvgIcon,
            label: 'Find Column Duplicates',
            regulation: allRegulationKeys,
          },
          FDDC: {
            objKey: 'FDDC',
            key: 'FindAndDeleteDuplicateColumns',
            icon: DuplicateColSvgIcon,
            label: 'Find And Delete Duplicate Columns',
            regulation: allRegulationKeys,
          },
          DE: {
            objKey: 'DU',
            key: 'Delete',
            icon: DeleteRowColSvgIcon,
            label: 'Delete',
            regulation: allRegulationKeys,
          },
          RE: {
            objKey: 'RE',
            key: 'Rename',
            icon: RenameColSvgIcon,
            label: 'Rename',
            regulation: allRegulationKeys,
          },
          CD: {
            objKey: 'CD',
            key: 'CreateDuplicate',
            icon: DuplicateRowsColsSvgIcon,
            label: 'Create Duplicate',
            regulation: allRegulationKeys,
          },
          AC: {
            objKey: 'AC',
            key: 'AddColumn',
            icon: AddRowColSvgIcon,
            label: 'Add Column',
            regulation: allRegulationKeys,
          },
          FAR: {
            objKey: 'FAR',
            key: 'FindAndReplace',
            icon: FindAndReplaceSvgIcon,
            label: 'Find & Replace',
            regulation: allRegulationKeys,
          },
          // Row level operations
          DR: {
            objKey: 'DR',
            key: 'DuplicateRow',
            icon: DuplicateRowsColsSvgIcon,
            label: 'Duplicate Row',
            regulation: allRegulationKeys,
          },
          FRD: {
            objKey: 'FRD',
            key: 'FindRowDuplicates',
            icon: DuplicateRowSvgIcon,
            label: 'Find Row Duplicates',
            regulation: allRegulationKeys,
          },
          FDDR: {
            objKey: 'FDDR',
            key: 'FindAndDeleteDuplicateRows',
            icon: DuplicateRowSvgIcon,
            label: 'Find And Delete Duplicate Rows',
            regulation: allRegulationKeys,
          },
          DLR: {
            objKey: 'DLR',
            key: 'DeleteRow',
            icon: DeleteRowColSvgIcon,
            label: 'Delete Row',
            regulation: allRegulationKeys,
          },
          AR: {
            objKey: 'AR',
            key: 'AddRows',
            icon: AddRowColSvgIcon,
            label: 'Add Rows',
            regulation: allRegulationKeys,
          },
        },
      },
    },
  },
  EDA: {
    objKey: 'EDA',
    key: 'EDA',
    label: 'EDA',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      PS: {
        objKey: 'PS',
        key: 'PreliminarySteps',
        label: 'Preliminary Steps',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          SS: {
            objKey: 'SS',
            key: 'SummaryStatistics',
            label: 'Summary Statistics',
            icon: summaryStatsSvgIcon,
            desc: 'Used to calculate summary statistics.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.PS_SUMMARY_STATS,
          },
          OSS: {
            objKey: 'OSS',
            key: 'OverallSummaryStatistics',
            label: 'Overall Summary Statistics',
            icon: overallSummaryStatsSvgIcon,
            desc: 'Used to calculate overall summary statistics.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.PS_OVERALL_STATS,
          },
        },
      },
      TO: {
        objKey: 'TO',
        key: 'TreatingOutliers',
        label: 'Treating Outliers',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          OD: {
            objKey: 'OD',
            key: 'OutlierDetection',
            label: 'Outlier Detection',
            icon: treatingOutliersSvgIcon,
            desc: 'Used for outlier etection.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.EDA + ApiEndpoints.EDA.EDA_GET_OUTLIER_TREATMENT_RESULTS,
            runOpUrlAlt: BASE_URL.EDA + ApiEndpoints.EDA.EDA_SAVE_RESULTS,
            runOpUrlTreatment:
              BASE_URL.EDA + ApiEndpoints.EDA.EDA_GET_OUTLIER_TREATMENT_RESULTS,
          },
        },
      },
      MVI: {
        objKey: 'MVI',
        key: 'MissingValuesImputation',
        label: 'Missing Value Imputation',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          MVI: {
            objKey: 'MVI',
            key: 'MissingValuesImputation',
            label: 'Missing Value Imputation',
            icon: missingValuesSvgIcon,
            desc: 'Used to calculate the missing value imputation.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.EDA_MVI_SUBMIT,
            runOpUrlAlt: BASE_URL.EDA + ApiEndpoints.EDA.EDA_MVI_SAVE,
          },
        },
      },
      VA: {
        objKey: 'VA',
        key: 'VariableAnalysis',
        label: 'Variable Analysis',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          UV: {
            objKey: 'UV',
            key: 'Univariate',
            label: 'Univariate',
            icon: univariateSvgIcon,
            desc: 'Used to calculate univariate analysis.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.EDA_VA_ACTION,
          },
          BV: {
            objKey: 'BV',
            key: 'Bivariate',
            label: 'Bivariate',
            icon: bivariateSvgIcon,
            desc: 'Used for bivariate.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.EDA_VA_ACTION,
          },
          MV: {
            objKey: 'MV',
            key: 'Multivariate',
            label: 'Multivariate',
            icon: multivariateSvgIcon,
            desc: 'Used for multivariate.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.EDA_VA_ACTION,
          },
        },
      },
      ST: {
        objKey: 'ST',
        key: 'StatisticalTests',
        label: 'Statistical Tests',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          NPT: {
            objKey: 'NPT',
            key: 'NonParametricTests',
            label: 'Non-Parametric Tests',
            icon: nonParametricSvgIcon,
            desc: 'Used for non-parametric tests.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.EDA + ApiEndpoints.EDA.EDA_STATISTICAL_NON_PATAMETRIC,
          },
          TFN: {
            objKey: 'TFN',
            key: 'TestForNormality',
            label: 'Test For Normality',
            icon: normalitySvgIcon,
            desc: 'Used for test for normality.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.EDA + ApiEndpoints.EDA.EDA_STATISTICAL_NORMALITY,
          },
          PT: {
            objKey: 'PT',
            key: 'ParametricTests',
            label: 'Parametric Tests',
            icon: parametricSvgIcon,
            desc: 'Used for parametric tests.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.EDA + ApiEndpoints.EDA.EDA_STATISTICAL_PATAMETRIC,
          },
          HOV: {
            objKey: 'HOV',
            key: 'HomoginityOfVariances',
            label: 'Homogeneity Of Variances',
            icon: homogeneitySvgIcon,
            desc: 'Used for homogeneity of variances.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.EDA + ApiEndpoints.EDA.EDA_STATISTICAL_HOMOGENEITY,
          },
        },
      },
      TSA: {
        objKey: 'TSA',
        key: 'TimeSeriesAnalysis',
        label: 'Time Series Analysis',
        regulation: tsRegulationKeys,
        show: true,
        operations: {
          ST: {
            objKey: 'ST',
            key: 'StationaryTest',
            label: 'Stationary Test',
            icon: stationarySvgIcon,
            desc: 'Used for stationary test.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.EDA.EDA_STATIONARY_TEST,
          },
          APP: {
            objKey: 'APP',
            key: 'ACFPACFPlots',
            label: 'ACF/PACF Plots',
            icon: acfPacfPlotSvgIcon,
            desc: 'Used for ACF/PACF plots.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.EDA.EDA_ACF_PACF_PLOTS,
          },
          GCT: {
            objKey: 'GCT',
            key: 'GrangerCausalityTest',
            label: 'Granger Causality Test',
            icon: grangerCasualtyTestSvgIcon,
            desc: 'Used for Granger Causality Test.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.EDA.EDA_GRANULARITY_TEST,
          },
          CT: {
            objKey: 'CT',
            key: 'CointegrationTest',
            label: 'Cointegration Test',
            icon: cointegrationTestSvgIcon,
            desc: 'Used for cointegration test.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.EDA.EDA_COINTEGRATION_TEST,
          },
          SET: {
            objKey: 'SET',
            key: 'SeasonalityTest',
            label: 'Seasonality Test',
            icon: seasonalityTestSvgIcon,
            desc: 'Used for seasonality test.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.EDA.EDA_SEASONALITY_TEST,
          },
        },
      },
    },
  },
  FE: {
    objKey: 'FE',
    key: 'FeatureEngineering',
    label: 'Feature Engineering',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      FE: {
        objKey: 'FE',
        key: 'FeatureEngineering',
        label: 'Feature Engineering',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          NT: {
            objKey: 'NT',
            key: 'NumericTransformation',
            label: 'Numeric Transformation',
            icon: NumericalIcon,
            desc: 'Used for numeric variable transformation.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.FTS + ApiEndpoints.FTS.FTS_NUMERIC,
            runOpUrlAlt: BASE_URL.FTS + ApiEndpoints.FTS.FTS_NUMERIC,
          },
          CT: {
            objKey: 'CT',
            key: 'CategoricalTransformation',
            label: 'Categorical Transformation',
            icon: CategoricalIcon,
            desc: 'Used for categorical variable transformation.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.FTS + ApiEndpoints.FTS.FTS_CATEGORICAL,
            runOpUrlAlt: BASE_URL.FTS + ApiEndpoints.FTS.FTS_CATEGORICAL,
          },
          VT: {
            objKey: 'VT',
            key: 'VariableTransformation',
            label: 'Variable Transformation',
            icon: TimeSeriesIcon,
            desc: 'Used for timestamp variable transformation.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.FTS.FTS_TIMESTAMP,
            runOpUrlAlt: BASE_URL.BASE_URL_TS + ApiEndpoints.FTS.FTS_TIMESTAMP,
          },
        },
      },
    },
  },
  SS: {
    objKey: 'SS',
    key: 'SamplingSegmentation',
    label: 'Sampling & Segmentation',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      SA: {
        objKey: 'SA',
        key: 'Sampling',
        label: 'Sampling',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          VI: {
            objKey: 'VI',
            key: 'VisualizeImbalance',
            label: 'Visualize Imbalance',
            icon: visualizaeImbalanceSvgIcon,
            desc: 'Used for visualize imbalance.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.SAMPLING +
              ApiEndpoints.SAMPLING.SAMPLING_VISUALIZE_IMBALANCE,
          },
          SP: {
            objKey: 'SP',
            key: 'Sampling',
            label: 'Sampling',
            icon: samplingSvgIcon,
            desc: 'Used for sampling.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.SAMPLING + ApiEndpoints.SAMPLING.SAMPLING_SAMPLING,
            runOpUrlAlt:
              BASE_URL.SAMPLING + ApiEndpoints.SAMPLING.SAMPLING_SAMPLING,
          },
          RSP: {
            objKey: 'RSP',
            key: 'Resampling',
            label: 'Resampling',
            icon: resamplingSvgIcon,
            desc: 'Used for resampling.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.SAMPLING + ApiEndpoints.SAMPLING.SAMPLING_RESAMPLING,
            runOpUrlAlt:
              BASE_URL.SAMPLING + ApiEndpoints.SAMPLING.SAMPLING_RESAMPLING,
          },
          SPL: {
            objKey: 'SPL',
            key: 'Splitting',
            label: 'Splitting',
            icon: splittingSvgIcon,
            desc: 'Used for splitting.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.SAMPLING + ApiEndpoints.SAMPLING.SAMPLING_SPLITTING,
            runOpUrlAlt:
              BASE_URL.SAMPLING + ApiEndpoints.SAMPLING.SAMPLING_SPLITTING,
          },
          SPT: {
            objKey: 'SPT',
            key: 'SamplingTs',
            label: 'Sampling',
            icon: samplingSvgIcon,
            desc: 'Used for sampling.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.SAMPLING.SAMPLING_TSA,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS + ApiEndpoints.SAMPLING.SAMPLING_TSA,
          },
        },
      },
      SE: {
        objKey: 'SE',
        key: 'Segmentation',
        label: 'Segmentation ',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          CL: {
            objKey: 'CL',
            key: 'Clustering',
            label: 'Clustering',
            icon: clusteringSvgIcon,
            desc: 'Used for clustering.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.SEG + ApiEndpoints.SEG.SEG_CLUSTER,
          },
          DT: {
            objKey: 'DT',
            key: 'DecisionTree',
            label: 'Decision Tree',
            icon: decisionTreeSvgIcon,
            desc: 'Used for decision tree.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.SEG + ApiEndpoints.SEG.SEG_DT,
          },
          RB: {
            objKey: 'RB',
            key: 'RuleBased',
            label: 'Rule Based',
            icon: ruleBaseSvgIcon,
            desc: 'Used for rule based segmentation.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.SEG + ApiEndpoints.SEG.SEG_RB,
          },
        },
      },
    },
  },
  VR: {
    objKey: 'VR',
    key: 'VariableReduction',
    label: 'Variable Reduction',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      VR: {
        objKey: 'VR',
        key: 'VariableReduction',
        label: 'Variable Reduction',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          MV: {
            objKey: 'MV',
            key: 'MissingValues',
            label: 'Missing Values',
            icon: missingvalues,
            desc: 'Used for missing values.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_MISSING_VALUES,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS + ApiEndpoints.VR.VR_OP_MISSING_VALUES,
          },
          PCA: {
            objKey: 'PCA',
            key: 'PCA',
            label: 'PCA',
            icon: PCA,
            desc: 'Used for Principal Component Analysis.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.VR + ApiEndpoints.VR.VR_OP_PRINCIPAL_COMPONENT_ANALYSIS,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS +
              ApiEndpoints.VR.VR_OP_PRINCIPAL_COMPONENT_ANALYSIS,
          },
          LV: {
            objKey: 'LV',
            key: 'LowVariance',
            label: 'Low Variance',
            icon: lowVariance,
            desc: 'Used for low variance.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_LOW_VARIANCE,
          },
          HC: {
            objKey: 'HC',
            key: 'HighCorrelation',
            label: 'High Correlation',
            icon: highcorrelation,
            desc: 'Used for high correlation.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_HIGH_CORRELATION,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS + ApiEndpoints.VR.VR_OP_MISSING_VALUES,
          },
          RF: {
            objKey: 'RF',
            key: 'RandomForest',
            label: 'Random Forest',
            icon: randomForest,
            desc: 'Used for random forest.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_RANDOM_FOREST,
          },
          CL: {
            objKey: 'CL',
            key: 'Clustering',
            label: 'Clustering',
            icon: clusteringIcon,
            desc: 'Used for clustering.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_CLUSTERING,
          },
          IVV: {
            objKey: 'IVV',
            key: 'IVValues',
            label: 'IV Values',
            icon: ivValues,
            desc: '',
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_IV_VALUES,
          },
          BFS: {
            objKey: 'BFS',
            key: 'BackwardFeatureSelection',
            label: 'Backward Feature Selection',
            icon: backwardFeatureSelection,
            desc: 'Used for backward feature selection.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS +
              ApiEndpoints.VR.VR_OP_TS_BACKWARD_FEATURE_SELECTION,
          },
          FFS: {
            objKey: 'FFS',
            key: 'ForwardFeatureSelection',
            label: 'Forward Feature Selection',
            icon: forwardFeatureSelection,
            desc: 'Used for forward feature selection.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS +
              ApiEndpoints.VR.VR_OP_TS_FORWARD_FEATURE_SELECTION,
          },
          LFS: {
            objKey: 'LFS',
            key: 'LassoFeatureSelection',
            label: 'Lasso Feature Selection',
            icon: LassoFeatureSelection,
            desc: 'Used for lasso feature selection.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.VR.VR_TS_LASSO,
          },
          RFS: {
            objKey: 'RFS',
            key: 'RidgeFeatureSelection',
            label: 'Ridge Feature Selection',
            icon: RidgeFeatureSelection,
            desc: 'Used for ridge feature selection.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.VR.VR_TS_RIDGE,
          },
          SFS: {
            objKey: 'SFS',
            key: 'StepwiseFeatureSelection',
            label: 'Stepwise Feature Selection',
            icon: StepwiseFeatureSelection,
            desc: 'Used for stepwise feature selection.',
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS +
              ApiEndpoints.VR.VR_OP_TS_STEP_WISE_SELECTION,
          },
          VF: {
            objKey: 'VF',
            key: 'VariableFinalization',
            label: 'Variable Finalization',
            icon: variableFinalisation,
            desc: 'Used for variable finalization.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.VR + ApiEndpoints.VR.VR_OP_VARIABLE_FINALISATION,
          },
        },
      },
    },
  },
  ME: {
    objKey: 'ME',
    key: 'ModelEstimation',
    label: 'Model Estimation',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      ME: {
        objKey: 'ME',
        key: 'ModelEstimation',
        label: 'Model Estimation',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          MI: {
            objKey: 'MI',
            key: 'ModelInitiation',
            label: 'Model Initiation',
            icon: metricSelection,
            desc: 'Used for model initiation non time series.',
            stepCount: 1,
            regulation: nonTsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.ME + ApiEndpoints.ME.ME_SC_MODEL_INITIATION_SUBMIT,
          },
          MIT: {
            objKey: 'MIT',
            key: 'ModelInitiationTS',
            label: 'Model Initiation',
            icon: metricSelection,
            desc: 'Used for model initiation time series.',
            stepCount: 1,
            regulation: tsRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS +
              ApiEndpoints.ME.ME_TS_MODEL_INITIATION_SUBMIT,
          },
          SDM: {
            objKey: 'SDM',
            key: 'SaveAndDeployModel',
            label: 'Save And Deploy Model',
            icon: saveAndDeploy,
            desc: 'Used for save and deploy model.',
            stepCount: 2,
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.ME + ApiEndpoints.ME.ME_DEPLOY_MODEL,
            runOpUrlAlt:
              BASE_URL.ME + ApiEndpoints.ME.ME_DEPLOY_MODEL_WITH_CONFIGS,
          },
        },
      },
    },
  },
  MT: {
    objKey: 'MT',
    key: 'ModelTesting',
    label: 'Model Testing',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      PT: {
        objKey: 'PT',
        key: 'PerformaceTesting',
        label: 'Performance Testing',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          OO: {
            objKey: 'OO',
            key: 'OngoingComparison',
            label: 'Ongoing Comparison',
            icon: lowVarianceMt,
            desc: 'Used for ongoing comparison.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_PT_ONGOING_COMPARISION,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS +
              ApiEndpoints.MT.MT_TS_PT_ONGOING_COMPARISON,
          },
          CP: {
            objKey: 'CP',
            key: 'ClassificationPlots',
            label: 'Classification Plots',
            icon: classificationPlotsSvgIcon,
            desc: 'Used for classification plots.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            stepCount: 4,
            runOpUrl:
              BASE_URL.MT + ApiEndpoints.MT.MT_CS_CLASSIFICATION_PLOTS_SUBMIT,
          },
          AUC: {
            objKey: 'AUC',
            key: 'AUC',
            label: 'AUC/Lift Curves',
            icon: aucLiftCurvesSvgIcon,
            desc: 'Used for AUC/Lift Curves.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            stepCount: 5,
            runOpUrl:
              BASE_URL.MT + ApiEndpoints.MT.MT_CS_AUC_LIFT_CURVES_SUBMIT,
          },
        },
      },
      AT: {
        objKey: 'AT',
        key: 'AssumptionTesting',
        label: 'Assumption Testing',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          AT: {
            objKey: 'AT',
            key: 'AssumptionTesting',
            label: 'Assumption Testing',
            icon: highCorrelation,
            desc: 'Used for assumption testing.',
            regulation: tsRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.MT.MT_TS_PT_ASSUMPTION_TEST,
          },
          GFT: {
            objKey: 'GFT',
            key: 'GoodnessOfFitTest',
            label: 'Goodness of Fit Test',
            icon: highCorrelation,
            desc: 'Used for goodness of fit test.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_PT_ASSUMPTION_TEST,
          },
        },
      },
      MC: {
        objKey: 'MC',
        key: 'ModelComparison',
        label: 'Model Comparison',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          MC: {
            objKey: 'MC',
            key: 'ModelComparison',
            label: 'Model Comparison',
            icon: randomForestMt,
            desc: 'Used for model comparison.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_PT_MODEL_COMPARISION,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS + ApiEndpoints.MT.MT_TS_PT_MODEL_COMPARISON,
          },
        },
      },
      SS: {
        objKey: 'SS',
        key: 'ScoringScaling',
        label: 'Scoring Scaling',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          SC: {
            objKey: 'SC',
            key: 'Scoring',
            label: 'Scoring',
            icon: scoring,
            desc: '',
            regulation: allRegulationKeys,
            stepCount: 1,
            showOp: true,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_SS_SCORING,
          },
          SCA: {
            objKey: 'SCA',
            key: 'Scaling',
            label: 'Scaling',
            icon: scaling,
            desc: '',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_SS_SCALING,
          },
        },
      },
      BT: {
        objKey: 'BT',
        key: 'BackTesting',
        label: 'Back Testing',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          RO: {
            objKey: 'RO',
            key: 'RankOrder',
            label: 'Rank Order',
            icon: rankOrder,
            desc: 'Used for rank order.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_BT_RANK_ORDER,
          },
          KF: {
            objKey: 'KF',
            key: 'KFold',
            label: 'K Fold',
            icon: kFold,
            desc: 'Used for k fold.',
            regulation: nonTsRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_BT_K_FOLD,
          },
          BT: {
            objKey: 'BT',
            key: 'BackTesting',
            label: 'Back Testing',
            icon: rulerCombinedSolid,
            desc: 'Used for back testing.',
            regulation: tsRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.MT.MT_TS_BACKTESTING_OP,
          },
        },
      },
      SA: {
        objKey: 'SA',
        key: 'SensitivityAnalysis',
        label: 'Sensitivity Analysis',
        regulation: allRegulationKeys,
        show: true,
        operations: {
          SA: {
            objKey: 'SA',
            key: 'SensitivityAnalysis',
            label: 'Sensitivity Analysis',
            icon: sensitivityAnalysis,
            desc: 'Used for sensitivity analysis.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_SA_SENSITIVITY_ANALYSIS,
            runOpUrlAlt:
              BASE_URL.BASE_URL_TS + ApiEndpoints.MT.VR_OP_MISSING_VALUES,
          },
        },
      },
      FA: {
        objKey: 'FA',
        key: 'Fairness',
        label: 'Fairness & Biasness',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          BA: {
            objKey: 'BA',
            key: 'Baisness',
            label: 'Biasness',
            icon: BiasnessOperationIcon,
            desc: 'Used to generate Biasness.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_FS_BIASNESS_SUBMIT,
          },
          FA: {
            objKey: 'FA',
            key: 'Fairness',
            label: 'Fairness',
            icon: FairnessOperationIcon,
            desc: 'Used to generate Biasness.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_FS_FAIRNESS_SUBMIT,
          },
        },
      },
      DA: {
        objKey: 'DA',
        key: 'DriftAnalysis',
        label: 'Drift Analysis',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          DD: {
            objKey: 'DD',
            key: 'DataDrift',
            label: 'Data Drift',
            icon: DataDriftOperationIcon,
            desc: 'Used to generate Data Drift.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.MT.MT_DATA_DRIFT_SUBMIT,
          },
        },
      },
      MR: {
        objKey: 'MR',
        key: 'ModelRecaliberation',
        label: 'Model Recalibration',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          MC: {
            objKey: 'MC',
            key: 'ModelCaliberation',
            label: 'Model Calibration',
            icon: modelCalibration,
            desc: 'Used for model calibration.',
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl: BASE_URL.MT + ApiEndpoints.MT.MT_MCA_SUBMIT_TASK,
          },
        },
      },
      FC: {
        objKey: 'FC',
        key: 'Forecasting',
        label: 'Forecasting',
        regulation: tsRegulationKeys,
        show: true,
        operations: {
          FC: {
            objKey: 'FC',
            key: 'Forecasting',
            label: 'Forecasting',
            icon: Forcasting,
            desc: 'Used for forecasting.',
            regulation: allRegulationKeys,
            stepCount: 1,
            showOp: true,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.MT.MT_TS_FORECAST,
          },
        },
      },
    },
  },
  MI: {
    objKey: 'MI',
    key: 'ModelInterpretation',
    label: 'Model Interpretation',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      FI: {
        objKey: 'FI',
        key: 'FeatureInterpretation',
        label: 'Feature Interpretation',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          FM: {
            objKey: 'FM',
            key: 'FeatureImportance',
            label: 'Feature Importance',
            icon: deatureImportanceSvgIcon,
            desc: 'Used for feature importance.',
            stepCount: 1,
            regulation: allRegulationKeys,
            showOp: true,
            runOpUrl:
              BASE_URL.MI + ApiEndpoints.MI.MI_FI_FEATURE_IMPORTANCE_SUBMIT,
          },
          FD: {
            objKey: 'FD',
            key: 'FeatureDependence',
            label: 'Feature Dependence',
            icon: featureDependenceSvgIcon,
            desc: 'Used for feature dependence.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl:
              BASE_URL.MI + ApiEndpoints.MI.MI_FI_FEATURE_DEPENDENCE_SUBMIT,
          },
          RC: {
            objKey: 'RC',
            key: 'RelativeContribution',
            label: 'Relative Contribution',
            icon: realtiveContributionSvgIcon,
            desc: 'Used for Relative Contribution.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 3,
            runOpUrl:
              BASE_URL.MI + ApiEndpoints.MI.MI_FI_RELATIVE_CONTRIBUTION_SUBMIT,
          },
        },
      },
      IP: {
        objKey: 'IP',
        key: 'IndividualPrediction',
        label: 'Individual Prediction',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          PD: {
            objKey: 'PD',
            key: 'Prediction',
            label: 'Prediction',
            icon: predictionSvgIcon,
            desc: 'Used for prediction.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl: BASE_URL.MI + ApiEndpoints.MI.MI_IP_PREDICTION_SUBMIT,
          },
          CT: {
            objKey: 'CT',
            key: 'Contribution',
            label: 'Contribution',
            icon: contributionScgIcon,
            desc: '',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl: BASE_URL.MI + ApiEndpoints.MI.MI_IP_CONTRIBUTION_SUBMIT,
          },
          PDP: {
            objKey: 'PDP',
            key: 'PartialDependencePlot',
            label: 'Partial Dependence Plot',
            icon: partialDependenceSvgIcon,
            desc: '',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 3,
            runOpUrl:
              BASE_URL.MI + ApiEndpoints.MI.MI_IP_PARTIAL_DEPENDENCE_SUBMIT,
          },
        },
      },
      DT: {
        objKey: 'DT',
        key: 'DecisionTrees',
        label: 'Decision Trees',
        regulation: nonTsRegulationKeys,
        show: true,
        operations: {
          BPV: {
            objKey: 'BPV',
            key: 'BarPlotVisualization',
            label: 'Bar Plot Visualization',
            icon: barPlotVisualizationSvgIcon,
            desc: 'Used for Pridictions Bar Plot Visualization.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl: BASE_URL.MI + ApiEndpoints.MI.MI_DT_DECISION_TREE_SUBMIT,
          },
          DTV: {
            objKey: 'DTV',
            key: 'DecisionTreeVisualization',
            label: 'Decision Tree Visualization',
            icon: decisionTreeVisualizationSvgIcon,
            desc: 'Used for Contributions Decision Tree Visualization.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl: BASE_URL.MI + ApiEndpoints.MI.MI_DT_DECISION_TREE_SUBMIT,
          },
        },
      },
      TSA: {
        objKey: 'TSA',
        key: 'TimeSeriesAnalysis',
        label: 'Time Series Analysis',
        regulation: tsRegulationKeys,
        show: true,
        operations: {
          VIF: {
            objKey: 'VIF',
            key: 'VifTable',
            label: 'VIF Table',
            icon: VIFTableOperationIcon,
            desc: 'Used to generate VIF Table.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 1,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.MI.MI_TS_VIF_TABLE,
          },
          CT: {
            objKey: 'CT',
            key: 'CoefficientTable',
            label: 'Coefficient Plot',
            icon: CoefficientPlotOperationIcon,
            desc: 'Used to generate Coefficient Plot.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 2,
            runOpUrl: BASE_URL.BASE_URL_TS + ApiEndpoints.MI.MI_TS_COFF_TABLE,
          },
          MS: {
            objKey: 'MS',
            key: 'ModelSummary',
            label: 'Model Summary',
            icon: ModelSummaryOperationIcon,
            desc: 'Used to generate Model Summary.',
            regulation: allRegulationKeys,
            showOp: true,
            stepCount: 3,
            runOpUrl:
              BASE_URL.BASE_URL_TS + ApiEndpoints.MI.MI_TS_MODEL_SUMMARY,
          },
        },
      },
    },
  },
  MM: {
    objKey: 'MM',
    key: 'ModelManager',
    label: 'Model Manager',
    regulation: allRegulationKeys,
    show: true,
    subModules: {
      MM: {
        objKey: 'MM',
        key: 'Models',
        label: 'Models',
        regulation: allRegulationKeys,
        show: true,
      },
      // TCM: {
      //   objKey: 'TCM',
      //   key: 'TestCaseManager',
      //   label: 'Test Cases',
      //   regulation: allRegulationKeys,
      //   show: true,
      // },
    },
  },
  // MD: {
  //   objKey: 'MD',
  //   key: 'ModelDeployment',
  //   label: 'Model Deployment',
  //   regulation: nonTsRegulationKeys,
  //   show: true,
  //   // [NNG-9937] changing module regulation for this ticket
  //   subModules: {
  //     MD: {
  //       objKey: 'MD',
  //       key: 'ModelDeployment',
  //       label: 'Model Deployment',
  //       regulation: nonTsRegulationKeys,
  //       show: true,
  //       operations: {
  //         DD: {
  //           objKey: 'DD',
  //           key: 'DataDrift',
  //           label: 'Data Drift',
  //           icon: DataDriftOperationIcon,
  //           desc: 'Used for model deployment.',
  //           stepCount: 1,
  //           regulation: nonTsRegulationKeys,
  //           showOp: true,
  //           runOpUrl: BASE_URL.MD + ApiEndpoints.MD.MD_DATA_DRIFT,
  //         },
  //         BMS: {
  //           objKey: 'BMS',
  //           key: 'BestModelSelection',
  //           label: 'Best Model Selection',
  //           icon: BestModelSelectionOperationIcon,
  //           desc: 'Used for model deployment.',
  //           stepCount: 1,
  //           regulation: nonTsRegulationKeys,
  //           showOp: true,
  //           runOpUrl: BASE_URL.MD + ApiEndpoints.MD.MD_BEST_MODEL_SELECTION,
  //         },
  //       },
  //     },
  //   },
  // },
  PIP: {
    objKey: 'PIP',
    key: 'Pipeline',
    label: 'Pipeline Manager',
    regulation: allRegulationKeys,
    show: false,
    subModules: {
      PIP: {
        objKey: 'PIP',
        key: 'Pipeline',
        label: 'Pipeline',
        show: false,
      },
    },
  },
  DOWNS: {
    objKey: 'DOWNS',
    key: 'Downloads',
    label: 'Downloads',
    regulation: allRegulationKeys,
    show: false,
    subModules: {
      DOWNS: {
        objKey: 'DOWNS',
        key: 'Downloads',
        label: 'Downloads',
        show: false,
      },
    },
  },
  ST: {
    objKey: 'ST',
    key: 'Settings',
    label: 'Settings',
    regulation: allRegulationKeys,
    show: false,
    subModules: {
      CP: {
        objKey: 'CP',
        key: 'ColorPalette',
        label: 'Color Palette',
        show: false,
      },
      UM: {
        objKey: 'UM',
        key: 'UserManagement',
        label: 'User Management',
        show: false,
      },
      AUD: {
        objKey: 'AUD',
        key: 'Auditability',
        label: 'Auditability',
        show: false,
      },
      OM: {
        objKey: 'OM',
        key: 'OrchestrationManagement',
        label: 'Orchestration Management',
        show: false,
      },
      WM: {
        objKey: 'WM',
        key: 'Workers',
        label: 'Workers',
        show: false,
      },
      BD: {
        objKey: 'BD',
        key: 'BigData',
        label: 'Big Data',
        show: false,
      },
      // CL: {
      //   objKey: 'CL',
      //   key: 'ConsoleLibrary',
      //   label: 'Console Libraries',
      //   show: false,
      // },
      DT: {
        objKey: 'DT',
        key: 'DocumentationTemplate',
        label: 'Documentation Template',
        show: false,
      },
      PB: {
        objKey: 'PB',
        key: 'PowerBi',
        label: 'PowerBi',
        show: false,
      },
      SD: {
        objKey: 'SD',
        key: 'StreamlitDashboard',
        label: 'Streamlit Dashboard',
        show: false,
      },
      ESP: {
        objKey: 'ESP',
        key: 'EditSupportPage',
        label: 'Edit Support Page',
        show: false,
      },
      // AAI: {
      //   objKey: 'AAI',
      //   key: 'ApisAndIntegrations',
      //   label: 'APIs & Integrations',
      //   show: false,
      // },
    },
  },
  UDF: {
    objKey: 'UDF',
    key: 'UDF',
    label: 'UDF Manager',
    regulation: allRegulationKeys,
    show: false,
    subModules: {
      CUDF: {
        objKey: 'CUDF',
        key: 'CreateUdf',
        label: 'Create UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      VD: {
        objKey: 'VD',
        key: 'ViewDetails',
        label: 'View Details',
        regulation: allRegulationKeys,
        show: false,
      },
      EX: {
        objKey: 'EX',
        key: 'Execute',
        label: 'Execute UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      RU: {
        objKey: 'RU',
        key: 'RunUdf',
        label: 'Run UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      DL: {
        objKey: 'DL',
        key: 'DeleteUdf',
        label: 'Delete UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      SV: {
        objKey: 'SV',
        key: 'SaveEditUdf',
        label: 'Save (Edit) UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      VH: {
        objKey: 'VH',
        key: 'VersionHistory',
        label: 'Version History',
        regulation: allRegulationKeys,
        show: false,
      },
      SHS: {
        objKey: 'SHS',
        key: 'ShareSendUdf',
        label: 'Share (Send) UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      SHA: {
        objKey: 'SHA',
        key: 'ShareAcceptUdf',
        label: 'Share (Accept) UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      CL: {
        objKey: 'CL',
        key: 'CloneUdf',
        label: 'Clone UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      RR: {
        objKey: 'RR',
        key: 'Rerun',
        label: 'Rerun UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      JV: {
        objKey: 'JV',
        key: 'JobsView',
        label: 'Jobs View',
        regulation: allRegulationKeys,
        show: false,
      },
      JC: {
        objKey: 'JC',
        key: 'JobsCompare',
        label: 'Jobs Compare',
        regulation: allRegulationKeys,
        show: false,
      },
      JAP: {
        objKey: 'JAP',
        key: 'JobsAddPipeLine',
        label: 'Jobs Add PipeLine',
        regulation: allRegulationKeys,
        show: false,
      },
      JD: {
        objKey: 'JD',
        key: 'JobsDelete',
        label: 'Jobs Delete',
        regulation: allRegulationKeys,
        show: false,
      },
      FV: {
        objKey: 'FV',
        key: 'FileView',
        label: 'File View',
        regulation: allRegulationKeys,
        show: false,
      },
      FU: {
        objKey: 'FU',
        key: 'FileUpload',
        label: 'File Upload',
        regulation: allRegulationKeys,
        show: false,
      },
      FD: {
        objKey: 'FD',
        key: 'FileDownload',
        label: 'File Download',
        regulation: allRegulationKeys,
        show: false,
      },
      FDL: {
        objKey: 'FDL',
        key: 'FileDelete',
        label: 'File Delete',
        regulation: allRegulationKeys,
        show: false,
      },
      DUDF: {
        objKey: 'DUDF',
        key: 'DeleteUdf',
        label: 'Delete UDF',
        regulation: allRegulationKeys,
        show: false,
      },
      CF: {
        objKey: 'CF',
        key: 'ConsoleFile',
        label: 'Console File Upload',
        regulation: allRegulationKeys,
        show: true,
      },
    },
  },
};
export default MODULE_SUB_MODULE_OPERATIONS_TREE;
