const stabilityMetrics = [
  { label: 'PSI', value: 'PSI', isSelected: false },
  { label: 'CSI', value: 'CSI', isSelected: false },
];

const strengthMetrics = [
  { label: 'Confusion Matrix', value: 'Confusion Matrix', isSelected: false },
  { label: 'ROC AUC', value: 'ROC-AUC', isSelected: false },
  { label: 'KS', value: 'KS', isSelected: false },
  { label: 'Precision', value: 'Precision', isSelected: false },
  { label: 'F1 Score', value: 'F1-Score', isSelected: false },
  { label: 'Recall', value: 'Recall', isSelected: false },
  { label: 'Gini', value: 'gini', isSelected: false },
  {
    label: 'Specificity vs Sensitivity',
    value: 'specificity & sensitivity',
    isSelected: false,
  },
  { label: 'VIF test', value: 'vif', isSelected: false },
  { label: 'HL Test', value: 'HL', isSelected: false },
];

const timeSeriesMetrics = [
  { label: 'ME', value: 'ME', isSelected: false },
  { label: 'MAE', value: 'MAE', isSelected: false },
  { label: 'RMSE', value: 'RMSE', isSelected: false },
  { label: 'MAPE', value: 'MAPE', isSelected: false },
  { label: 'RMSE/SD', value: 'RMSE/SD', isSelected: false },
  { label: 'Rsquared', value: 'Rsquared', isSelected: false },
  { label: 'Adjusted-Rsquared', value: 'Adjusted-Rsquared', isSelected: false },
];

const assumptionsTests = [{ label: 'HL Test', value: 'HL Test' }];
const data = [
  {
    key: 'KS & GINI RULE',
    value: [
      {
        interference: 'Good Discriminatory Power',
        action: 'No action required',
      },
      {
        interference: 'Acceptable for a model',
        action: 'check other score card monitoring metrics',
      },
      {
        interference: 'No prediction Power',
        action: 'Need to dive deeper',
      },
    ],
  },
  {
    key: 'PSI & CSI',
    value: [
      {
        interference: 'Good Discriminatory Power',
        action: 'No action required',
      },
      {
        interference: 'Acceptable for a model',
        action: 'check other score card monitoring metrics',
      },
      {
        interference: 'No prediction Power',
        action: 'Need to dive deeper',
      },
    ],
  },
];

const exportObj = {
  stabilityMetrics,
  strengthMetrics,
  assumptionsTests,
  data,
  timeSeriesMetrics,
};

export default exportObj;
