// import Swal from 'sweetalert2';
// import {
//   documentBgColor,
//   lightdangerColor,
//   primaryColor,
//   toastBgColor,
// } from '../../const/DefaultStyleVariables';
// import './SwalToast.scss';

// const SuccessToast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   showCloseButton: true,
//   timer: 5000,
//   width: 400,
//   iconColor: primaryColor,
//   background: toastBgColor,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', Swal.stopTimer);
//     toast.addEventListener('mouseleave', Swal.resumeTimer);
//   },
//   customClass: {
//     closeButton: 'swal2-close',
//   },
// });
// const ErrorToast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 5000,
//   width: 400,
//   showCloseButton: true,
//   background: lightdangerColor,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', Swal.stopTimer);
//     toast.addEventListener('mouseleave', Swal.resumeTimer);
//   },
//   customClass: {
//     closeButton: 'swal2-close',
//   },
// });
// const InfoToast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 5000,
//   width: 400,
//   showCloseButton: true,
//   background: documentBgColor,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', Swal.stopTimer);
//     toast.addEventListener('mouseleave', Swal.resumeTimer);
//   },
//   customClass: {
//     closeButton: 'swal2-close',
//   },
// });

/**
 * @function SwalToast
 * @description Displays a toast using the Snackbar component from the Material-UI library.
 * @param {object} props - The props object.
 * @param {string} [props.icon='success'] - The type of the toast icon. Defaults to 'success'.
 * @param {string} props.title - The title of the toast.
 * @returns {void}
 */
const SwalToast = ({ icon: type = 'success', title }) => {
  // @ts-ignore
  window.enqueueSnackbar(title, { variant: type, preventDuplicate: true });
};

export default SwalToast;
