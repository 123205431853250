const OVERWRITE_EXISTING = [
  { label: 'Overwriting the existing dataset', value: 'false' },
  { label: 'Creating a new dataset', value: 'true' },
];
const REGULATIONS = [
  {
    label: 'Classification',
    key: 'Classification', // this is scorecard catergory only we just renamed it.
    isTs: false,
  },
  // {
  //   label: 'Regulatory Capital',
  //   key: 'Regulatory Capital',
  //   isTs: false,
  // },
  {
    label: 'Regression',
    key: 'Regression', // this is time series catergory only we just renamed it.
    isTs: true,
  },
  // {
  //   label: 'CCAR',
  //   key: 'CCAR',
  //   isTs: true,
  // },
  // {
  //   label: 'IFRS9',
  //   key: 'IFRS9',
  //   isTs: true,
  // },
  // {
  //   label: 'Stress Testing (ICAAP)',
  //   key: 'ICAAP',
  //   isTs: true,
  // },
  {
    label: 'All Models',
    key: 'AllModels', // this is time series catergory only we just renamed it.
    isTs: true,
  },
];

const DATASET_REQUIRED_FIELDS = [
  'id',
  'name',
  'table_display_name',
  'preprocessed',
  'published',
  'datalock',
  'dataset',
  'project_id',
];
const SPACING_SIZES = [
  { label: 1.0, value: 1.0 },
  { label: 1.15, value: 1.15 },
  { label: 1.5, value: 1.5 },
  { label: 2.0, value: 2.0 },
  { label: 2.5, value: 2.5 },
  { label: 3.0, value: 3.0 },
];

const DOCUMENT_ALIGNMENT = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Center' },
  { value: 'right', label: 'Right' },
  { value: 'justify', label: 'Justify' },
];

const BEFORE_AFTER_SPACING = [
  { value: '0', label: '0' },
  { value: 0.05, label: 0.05 },
  { value: 0.1, label: 0.1 },
  { value: 0.13, label: 0.13 },
  { value: 0.16, label: 0.16 },
  { value: 0.18, label: 0.18 },
  { value: 0.2, label: 0.2 },
];

const FONT_SIZE = [
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
];

const DIALOG_ICON_BUTTON_CSS = {
  position: 'absolute',
  right: 16,
  top: 8,
};

const MODEL_UPLOAD_TYPE = [
  { value: 'Python', label: 'Python' },
  { value: 'R', label: 'R' },
  { value: 'SAS', label: 'SAS' },
];

const MODEL_TYPE = [
  { value: 'Champion', label: 'Champion' },
  { value: 'Challenger', label: 'Challenger' },
  { value: 'Candidate', label: 'Candidate' },
];

const DS_LOCKED_MSG =
  'Dataset is locked as some other operation is in progress. You can try later or unlock the dataset from Data Ingestion.';

const DS_RECALIBRATION_MSG =
  'Dataset is undergoing recalibration. Please wait for the process to complete.';

export {
  DS_LOCKED_MSG,
  DS_RECALIBRATION_MSG,
  REGULATIONS,
  OVERWRITE_EXISTING,
  SPACING_SIZES,
  DOCUMENT_ALIGNMENT,
  BEFORE_AFTER_SPACING,
  FONT_SIZE,
  DIALOG_ICON_BUTTON_CSS,
  MODEL_UPLOAD_TYPE,
  MODEL_TYPE,
  DATASET_REQUIRED_FIELDS,
};
