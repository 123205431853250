/**
 * @function stringShortining
 * @description Shortining the given string
 * @param {string} stringData
 * @param {number} lenData
 * @returns {string}
 */

const MAX_LENGTH = 21;
const SHORTEN_THRESHOLD = 20;

export function stringShortining(stringData, lenData = MAX_LENGTH) {
  if (stringData.length > SHORTEN_THRESHOLD) {
    // eslint-disable-next-line no-param-reassign
    stringData = `${stringData.substring(0, lenData)}...`;
    return stringData;
  }
  return stringData;
}
