import * as React from 'react';
import Box from '@mui/material/Box';
import { useRef, useState, useCallback, useEffect } from 'react';
import { object, string, array } from 'yup';
import { useFormik } from 'formik';
import Chip from '@mui/material/Chip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import CloseIcon from '@mui/icons-material/Close';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useContextMenu } from 'react-contexify';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import useCommon from '../../Hooks/useCommon';
import SwalToast from '../Common/SwalToast';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
import { ReactComponent as HelpSectionWarningIcon } from '../../assets/images/HelpSectionWarningIcon.svg';
import REGEX from '../../const/Regex';
import { ContextMenus } from '../Common/ContextMenus';
import DialogTransition from '../Common/Transitions/DialogTransition';
import { DIALOG_ICON_BUTTON_CSS } from '../../const/CommonConst';
import DeleteConfirmModal from '../Common/DeleteConfirmModal';
import JoditEditorDialog from './JoditEditor';
import { ReactComponent as Cursor } from '../../assets/images/cursor.svg';
import { ReactComponent as Folder } from '../../assets/images/folder.svg';
import { ReactComponent as File } from '../../assets/images/file.svg';
import { ReactComponent as Section } from '../../assets/images/SectionIcon.svg';
import { ReactComponent as SubSection } from '../../assets/images/SubSectionIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteMenuIcon.svg';
import { ReactComponent as NoDataFound } from '../../assets/images/NoDataFound.svg';
import MultiSelect from '../Common/MultiSelect';
import TransperentSearchBox from '../Common/TransperentSearchBox';
import useHelpSection from '../../Hooks/HelpSection/useHelpSection';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import { ReactComponent as DragIcon } from '../../assets/images/drag-handle-vertical.svg';

const handleFocus = (e, theme) => {
  e.currentTarget.style.border = `2px solid ${theme.palette.secondary.main}`;
};
const handleBlur = (e, theme) => {
  e.currentTarget.style.border = `2px solid ${theme?.palette?.grey?.[300]}`;
};

const handleMouseEnter = (e, themeObject) => {
  e.currentTarget.style.backgroundColor = themeObject.palette.other.grey1;
};

const handleMouseLeave = (e, themeObject) => {
  e.currentTarget.style.backgroundColor = themeObject.palette.other.white;
};

const showContentWithElipses = (content, maxWidth) => {
  if (content.length <= maxWidth) {
    return content;
  }
  return `${content.slice(0, maxWidth - 3)}...`;
};

const TreeItemCustom = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    padding: '0 8px 0 32px',
    '&:hover': {
      backgroundColor: theme.palette?.other?.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: theme.palette?.other?.white,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    borderLeft: `0.1px solid ${theme?.palette.other.grey5}`,
    marginLeft: '38px',
  },
}));

const ItemTypes = {
  BOX: 'box',
};

const moduleMapping = [];
moduleMapping.push(
  {
    label: 'About Nimbus',
    value: 'AboutNimbus',
  },
  {
    label: 'Getting Started',
    value: 'GettingStarted',
  }
);

Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((key) => {
  const { label } = MODULE_SUB_MODULE_OPERATIONS_TREE[key];
  const value = MODULE_SUB_MODULE_OPERATIONS_TREE[key].key;
  moduleMapping.push({ label, value });

  if (
    key !== 'PO' &&
    key !== 'FE' &&
    key !== 'VR' &&
    key !== 'ME' &&
    key !== 'DOWNS' &&
    key !== 'PIP' &&
    key !== 'editSupportPage' &&
    MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules
  ) {
    if (key === 'EDA') {
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules).forEach(
        (subKey) => {
          const { label: subLabel } =
            MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey];
          const subValue =
            subKey === 'TSA'
              ? `${MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey].key}_EDA`
              : MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey].key;
          const modifiedSubLabel =
            subKey === 'TSA' ? 'Time Series Analysis (EDA)' : subLabel;
          moduleMapping.push({ label: modifiedSubLabel, value: subValue });
        }
      );
    } else if (key === 'MI') {
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules).forEach(
        (subKey) => {
          const { label: subLabel } =
            MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey];
          const subValue =
            subKey === 'TSA'
              ? `${MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey].key}_ModelInterpretation`
              : MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey].key;
          const modifiedSubLabel =
            subKey === 'TSA'
              ? 'Time Series Analysis (Model Interpretation)'
              : subLabel;
          moduleMapping.push({ label: modifiedSubLabel, value: subValue });
        }
      );
    } else {
      Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules).forEach(
        (subKey) => {
          const { label: subLabel } =
            MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey];
          const subValue =
            MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules[subKey].key;
          moduleMapping.push({ label: subLabel, value: subValue });
        }
      );
    }
  }
});
moduleMapping.push(
  {
    label: 'Automated Documentation',
    value: 'AutomatedDocumentation',
  },
  {
    label: 'Console',
    value: 'Console',
  },
  {
    label: 'Glossary',
    value: 'Glossary',
  },
  {
    label: 'NIMBUS Architecture',
    value: 'NIMBUSArchitecture',
  },
  {
    label: 'FAQs',
    value: 'FAQs',
  }
);

/**
 * Flattens the nested nodes array into a single-level array so we can use it for next and prev feature.
 *
 * @function flattenNodes
 * @param {Array} nodes - The array of nodes with potentially nested subsections.
 * @returns {Array} The flattened array of nodes.
 */
function flattenNodes(nodes) {
  let flattenedArray = [];

  nodes?.forEach((node) => {
    flattenedArray?.push(node);
    if (node?.subsection && node?.subsection?.length > 0) {
      flattenedArray = flattenedArray?.concat(flattenNodes(node?.subsection));
    }
  });

  return flattenedArray;
}

/**
 * Finds the parents of a node with the given nodeId within the provided array of nodes.
 * @param {Array<Object>} nodes - The array of nodes to search within.
 * @param {string} nodeId - The ID of the node whose parents are to be found.
 * @returns {Array<string>} An array containing the IDs of the found parents, in hierarchical order. Returns an empty array if no parents are found.
 */
function findParents(nodes, nodeId) {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    // eslint-disable-next-line no-restricted-syntax
    for (const subnode of node.subsection) {
      if (subnode.id === nodeId) {
        return [subnode.id].concat(findParents(nodes, node.id));
      }
    }
  }
  return [];
}

/**
 * Finds the node with the given nodeId and its parents within the provided array of nodes.
 * @param {Array<Object>} nodes - The array of nodes to search within.
 * @param {string} nodeId - The ID of the node to be found along with its parents.
 * @returns {Array<string>|null} An array containing the IDs of the found node and its parents, in hierarchical order. Returns null if the node is not found.
 */
function findNodeAndParents(nodes, nodeId) {
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    if (node?.id === nodeId) {
      return [node?.id].concat(findParents(nodes, node?.id));
    }
    if (node.subsection?.length > 0) {
      const result = findNodeAndParents(node.subsection, nodeId);
      if (result) {
        return [node?.id].concat(result);
      }
    }
  }
  return null;
}

/**
 * Recursively searches for a target node with the specified ID inside the given source node.
 * @param {Object} sourceNode - The source node to start the search from.
 * @param {string} targetId - The ID of the target node to search for.
 * @returns {boolean} True if the target node with the specified ID is found inside the source node or its subsections, otherwise false.
 */
function findTargetNodeInsideTheSourceNode(sourceNode, targetId) {
  // Check if the current sourceNode's ID matches the target ID
  if (sourceNode?.id === targetId) {
    return true;
  }

  // Iterate over subsections recursively
  for (let i = 0; i < sourceNode?.subsection?.length; i += 1) {
    // Recursively check each subsection
    if (
      findTargetNodeInsideTheSourceNode(sourceNode?.subsection[i], targetId)
    ) {
      return true;
    }
  }

  // If the target sourceNode is not found in this subtree, return false
  return false;
}

/**
 * @function DropZone
 * @description Renders a drop zone component for drag and drop functionality.
 * @param {object} props - Props passed to the component.
 * @param {object} props.data - Data related to the drop zone.
 * @param {function} props.onDrop - Function to handle the drop event.
 * @param {boolean} props.isLast - Flag indicating if the drop zone is the last one.
 * @returns {JSX.Element} A React component representing the drop zone.
 */
const DropZone = ({ data, onDrop, isLast, themeObject }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop: (item, monitor) => {
      if (!monitor.getDropResult()) {
        onDrop(item, data, isLast);
      }
    },
    canDrop: (item) => {
      // If the target and source are same or the target is present into the source then disabling the dropzone
      if (findTargetNodeInsideTheSourceNode(item?.node, data?.node?.id)) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <Box
      sx={{
        background: isActive
          ? themeObject?.palette?.primary?.main
          : themeObject?.palette?.common?.white,
        height: '5px',
      }}
      ref={drop}
    />
  );
};

DropZone.propTypes = {
  isLast: PropTypes.bool.isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  onDrop: PropTypes.func.isRequired,
  themeObject: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

/**
 * @function renderTree
 * @description Renders the tree structure recursively.
 * @param {Array} nodes - Array of nodes representing the tree structure.
 * @param {string} parentNumber - Parent number of the current node.
 * @param {function} handleTreeItemClick - Function to handle tree item click events.
 * @param {function} handleContextMenu - Function to handle context menu events.
 * @param {string} selectedNodeId - ID of the selected node.
 * @param {function} moveItem - Function to handle item movement.
 * @param {function} moveItemIntoTheSection - Function to handle moving items into a section.
 * @returns {JSX.Element[]} An array of React components representing the tree structure.
 */
const renderTree = (
  nodes,
  parentNumber = '',
  handleTreeItemClick,
  handleContextMenu,
  selectedNodeId,
  moveItem,
  moveItemIntoTheSection,
  access,
  permissions,
  themeObject
) => {
  if (!nodes) return null;
  return nodes?.map((node, index) => {
    const nodePath = parentNumber
      ? `${parentNumber}.${index + 1}`
      : `${index + 1}`;
    return (
      <>
        <DropZone
          data={{ node, nodePath }}
          onDrop={moveItem}
          themeObject={themeObject}
        />
        <TreeItemComponent
          key={node?.id}
          node={node}
          index={index}
          parentNumber={parentNumber}
          handleTreeItemClick={handleTreeItemClick}
          handleContextMenu={handleContextMenu}
          selectedNodeId={selectedNodeId}
          moveItem={moveItem}
          nodePath={nodePath}
          moveItemIntoTheSection={moveItemIntoTheSection}
          access={access}
          permissions={permissions}
          themeObject={themeObject}
        />
        {nodes?.length - 1 === index && (
          <DropZone
            data={{ node, nodePath }}
            onDrop={moveItem}
            isLast
            themeObject={themeObject}
          />
        )}
      </>
    );
  });
};

/**
 * @function TreeItemComponent
 * @description Renders a single item in the tree structure.
 * @param {object} props - Props passed to the component.
 * @param {object} props.node - Node object representing the item.
 * @param {string} props.parentNumber - Parent number of the current node.
 * @param {number} props.index - Index of the node in its parent array.
 * @param {function} props.handleTreeItemClick - Function to handle tree item click events.
 * @param {function} props.handleContextMenu - Function to handle context menu events.
 * @param {function} props.moveItem - Function to handle item movement.
 * @param {string} props.nodePath - Path of the current node in the tree.
 * @param {function} props.moveItemIntoTheSection - Function to handle moving items into a section.
 * @param {string} props.selectedNodeId - ID of the selected node.
 * @returns {JSX.Element} A React component representing a single item in the tree structure.
 */
const TreeItemComponent = ({
  node,
  parentNumber,
  index,
  handleTreeItemClick,
  handleContextMenu,
  selectedNodeId,
  moveItem,
  nodePath,
  moveItemIntoTheSection,
  access,
  permissions,
  themeObject,
}) => {
  const nodeNumber = parentNumber
    ? `${parentNumber}.${index + 1}`
    : `${index + 1}`;
  const hasChildren = node?.subsection && node?.subsection?.length;
  const isSelected = selectedNodeId === node?.id;

  const [{ opacity }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: () => {
      return {
        node,
        parentNumber,
        index,
        nodeNumber,
      };
    }, // Define the item with the attribute data

    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop: (item, monitor) => {
      if (!monitor.getDropResult()) {
        moveItemIntoTheSection(item, { node, nodePath });
      }
    },
    canDrop: (item) => {
      // If the target and source are same or the target is present into the source then disabling the dropzone
      if (findTargetNodeInsideTheSourceNode(item?.node, node?.id)) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  // Render without drag and drop functionality if access is false
  if (!(access && permissions?.Settings_EditSupportPage === 'Manage')) {
    return (
      <TreeItemCustom
        nodeId={node?.id}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Box
              pr={0.5}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {hasChildren ? <Folder /> : <File />}
            </Box>
            <Typography
              title={node?.title}
              variant={nodeNumber.includes('.') ? 'body4' : 'body3'}
              sx={{
                whiteSpace: 'noWrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {`${nodeNumber}. ${node?.title}`}
            </Typography>
          </Box>
        }
        onClick={(event) => {
          handleTreeItemClick(event, node?.id, 'fromTreeItemClick');
        }}
        onContextMenu={(event) =>
          handleContextMenu(event, node?.id, selectedNodeId)
        }
        sx={{
          width: '100%',
          '& .MuiTreeItem-label': {
            padding: '4px',
          },
          opacity,
          // This is because we want to show the selected node as highlighted into the sidebar file view
          '&.MuiTreeItem-root > .MuiTreeItem-content': {
            cursor: 'pointer',
            backgroundColor: (theme) =>
              (isSelected || isActive) &&
              `${theme.palette?.other?.treeViewBackgroundColor}!important`,
          },
        }}
      >
        {hasChildren
          ? renderTree(
              node?.subsection,
              nodeNumber,
              handleTreeItemClick,
              handleContextMenu,
              selectedNodeId,
              moveItem,
              moveItemIntoTheSection,
              access,
              permissions,
              themeObject
            )
          : null}
      </TreeItemCustom>
    );
  }

  return (
    <Box
      display="flex"
      ref={isSelected ? drag : drop}
      sx={{
        position: 'relative',
        cursor: isSelected ? 'grab' : 'pointer',
      }}
    >
      {isSelected &&
        access &&
        permissions?.Settings_EditSupportPage === 'Manage' && (
          <Box
            sx={{
              position: 'absolute',
              top: 2,
              left: 6,
              cursor: 'grab',
            }}
          >
            <DragIcon width="16px" height="16px" />
          </Box>
        )}
      <TreeItemCustom
        nodeId={node?.id}
        // Stop hijacking to make dnd works.
        onFocusCapture={(e) => {
          e.stopPropagation();
        }}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Box
              pr={0.5}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {hasChildren ? <Folder /> : <File />}
            </Box>
            <Typography
              title={node?.title}
              variant={nodeNumber.includes('.') ? 'body4' : 'body3'}
              sx={{
                whiteSpace: 'noWrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {`${nodeNumber}. ${node?.title}`}
            </Typography>
          </Box>
        }
        onClick={(event) => {
          handleTreeItemClick(event, node?.id, 'fromTreeItemClick');
        }}
        onContextMenu={(event) =>
          handleContextMenu(event, node?.id, selectedNodeId)
        }
        sx={{
          width: '100%',
          '& .MuiTreeItem-label': {
            padding: '4px',
          },
          opacity,
          // This is because we want to show the selected node as highlighted into the sidebar file view
          '&.MuiTreeItem-root > .MuiTreeItem-content': {
            cursor: isSelected ? 'grab' : 'pointer',
            backgroundColor: (theme) =>
              (isSelected || isActive) &&
              `${theme.palette?.other?.treeViewBackgroundColor}!important`,
          },
        }}
      >
        {hasChildren
          ? renderTree(
              node?.subsection,
              nodeNumber,
              handleTreeItemClick,
              handleContextMenu,
              selectedNodeId,
              moveItem,
              moveItemIntoTheSection,
              access,
              permissions,
              themeObject
            )
          : null}
      </TreeItemCustom>
    </Box>
  );
};

TreeItemComponent.defaultProps = {
  parentNumber: null,
};

TreeItemComponent.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    subsection: PropTypes.oneOfType([PropTypes.array]).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  parentNumber: PropTypes.string,
  index: PropTypes.number.isRequired,
  handleTreeItemClick: PropTypes.func.isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  moveItem: PropTypes.func.isRequired,
  moveItemIntoTheSection: PropTypes.func.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  nodePath: PropTypes.string.isRequired,
  access: PropTypes.bool.isRequired,
  themeObject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  permissions: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const NumberOfResultstoShow = 5;
const commonNumberHundred = 100;

/**
 * HelpDocument component displays the help document tree structure, content, and related functionalities.
 * @returns {JSX.Element} React component representing the HelpDocument.
 */
export default function HelpDocument() {
  const [access, setAccess] = useState(
    localStorage.getItem('IsUserSuperAdmin')
  );
  const [permissions, setPermissions] = useState(
    JSON.parse(localStorage.getItem('user_permissions'))
  );
  const { common, dispatch, setHelpDocumentKeywordSearch } = useCommon();
  const theme = useTheme();
  const {
    getHierarchy,
    addNewSection,
    updateSection,
    deleteNode,
    saveFileStructure,
  } = useHelpSection();

  const [treeData, setTreeData] = useState([]);
  const [flatStructureDataForNextPrev, setFlatStructureDataForNextPrev] =
    useState([]);
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { show: showRowLevelMenus } = useContextMenu();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showSubSecModal, setShowSubSecModal] = useState(false);
  const [showSecModal, setShowSecModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isNodeClicked, setIsNodeClicked] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [lengthOfContent, setLengthOfContent] = useState(0);
  const [contentMain, setContentMain] = useState([]);
  const [remainingContent, setRemainingContent] = useState(0);
  const [numberOfresults, setNumberOfresults] = useState(1);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [contentToBeFetched, setContentToBeFetched] = useState(true);
  const [showWarning, setShowWarning] = useState(true);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const expandedRef = useRef(true);
  const nextNode =
    flatStructureDataForNextPrev?.length >= currentIndex + 1 &&
    flatStructureDataForNextPrev[currentIndex + 1];

  const previousNode =
    flatStructureDataForNextPrev?.length >= currentIndex - 1 &&
    flatStructureDataForNextPrev[currentIndex - 1];

  useEffect(() => {
    // Whenever localStorage changes, update the state
    const handleStorageChange = () => {
      setAccess(localStorage.getItem('IsUserSuperAdmin'));
      setPermissions(JSON.parse(localStorage.getItem('user_permissions')));
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setAccess, setPermissions]);
  const handleShowMore = useCallback(() => {
    setNumberOfresults((prevState) => prevState + 1);
  }, [numberOfresults, setNumberOfresults]);

  const handleShowLess = useCallback(() => {
    setNumberOfresults((prevState) => prevState - 1);
  }, [numberOfresults, setNumberOfresults]);

  // Fetches the tree view data from the server using the `getHierarchy` function and updates the state with the response data.
  const fetchTreeView = useCallback(() => {
    getHierarchy({
      id: null,
      tags: null,
      search: null,
    }).then((resp) => {
      if (resp) {
        setTreeData(resp);
      }
    });
  }, [getHierarchy, setTreeData]);

  const fetchActiveNodeContent = useCallback(
    (nodeId) => {
      getHierarchy({
        id: nodeId,
        tags: null,
        search: null,
      })
        .then((respa) => {
          const newContent = respa?.content
            .replace(/>\s*\n\s*/g, '>')
            .replace(/>\n*/g, '>')
            .replace(/>\s*/g, '>');
          setContent(newContent);
          setTags(respa?.tags);
        })
        .catch((error) => {
          setContent('');
          SwalToast({
            icon: 'error',
            title: error?.msg,
          });
        });
    },
    [getHierarchy, setContent, setTags]
  );

  const formikFormRename = useFormik({
    initialValues: {
      newTitle: '',
      tags: [],
    },
    validationSchema: object({
      newTitle: string()
        .matches(REGEX.ALPHA_NUM_SPACE_DASH, 'Incorrect input')
        .max(commonNumberHundred, 'Must be 100 characters or less')
        .required('Required'),
      tags: array()
        .min(1, 'Required')
        .of(object().required('Required'))
        .required('Required'),
    }),
    onSubmit: (values) => {
      const tagValues = values?.tags?.map((tag) => tag?.value);
      updateSection({
        id: currentNode?.id,
        title: values?.newTitle,
        content,
        tags: tagValues,
      })
        .then(() => {
          fetchTreeView();
          fetchActiveNodeContent(currentNode?.id);
        })
        .catch((error) => {
          SwalToast({
            icon: 'error',
            title: error?.msg,
          });
        });

      formikFormRename.resetForm();
      setShowRenameModal(false);
    },
  });

  const formikFormSubSection = useFormik({
    initialValues: {
      newSubSectionName: '',
      tags: [],
      childOf: '',
    },
    validationSchema: object({
      newSubSectionName: string()
        .max(commonNumberHundred, 'Must be 100 characters or less')
        .required('Required'),
      tags: array()
        .min(1, 'Required')
        .of(object().required('Required'))
        .required('Required'),
      childOf: string(),
    }),
    onSubmit: (values) => {
      const tagValues = values?.tags?.map((tag) => tag?.value);
      addNewSection({
        title: values?.newSubSectionName,
        content: '',
        tags: tagValues,

        childOf: showSecModal ? '-1' : selectedNodeId,
      }).then((resp) => {
        SwalToast({
          icon: 'success',
          title: resp?.data?.msg,
        });
        fetchTreeView();
      });
      setShowSecModal(false);
      formikFormSubSection.resetForm();
      setShowSubSecModal(false);
      formikFormSubSection.handleClose();
    },
  });

  useEffect(() => {
    getHierarchy({
      id: null,
      tags: null,
      search: null,
    }).then((resp) => {
      if (resp) {
        setTreeData(resp);
      }
    });
  }, []);

  /**
   * Finds the index of the current node in the flat structure data array for next/previous navigation.
   *
   * @function findCurrentNodeIndexInFlatStructureDataForNextPrev
   * @param {string} nodeid - The ID of the current node.
   * @returns {number} The index of the current node in the flat structure data array, or -1 if not found.
   */
  const findCurrentNodeIndexInFlatStructureDataForNextPrev = (nodeid) => {
    return flatStructureDataForNextPrev?.findIndex(
      (node) => node?.id === nodeid
    );
  };

  /**
   * Handles the click event on a tree item.
   * Updates the URL with the selected node ID, sets various state variables, and triggers data fetching if required.
   * Toggles the expanded/collapsed state of the node if the click originates from a tree item click.
   *
   * @param {Event} event - The click event.
   * @param {string} nodeid - The ID of the clicked node.
   * @param {string} fromWhereClick - Indicates the origin of the click ('fromTreeItemClick' if from a tree item click).
   */
  const handleTreeItemClick = useCallback(
    (event, nodeid, fromWhereClick) => {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('nodeId', nodeid);
      window.history.pushState({}, '', newUrl);
      setIsEditing(false);
      setShowWarning(true);
      setIsNodeClicked(true);
      setSelectedNodeId(nodeid);
      if (fromWhereClick === 'fromTreeItemClick') {
        // If we click on the tree item then only we will toggle the expanded and collapse
        setExpandedNodes((prev) => {
          const isNodeExpanded = prev.find((node) => node === nodeid);
          if (isNodeExpanded) {
            // remove the node from expanded so we can collapse it
            const filteredNodes = prev.filter((node) => node !== nodeid);
            return filteredNodes;
          }
          return [...prev, nodeid];
        });
      }
      const currentNodeIndexInFlatStructureDataForNextPrev =
        findCurrentNodeIndexInFlatStructureDataForNextPrev(nodeid);

      if (currentNodeIndexInFlatStructureDataForNextPrev >= 0) {
        setCurrentIndex(currentNodeIndexInFlatStructureDataForNextPrev);
      }
      setContentToBeFetched(true);
    },
    [
      treeData,
      setIsEditing,
      setShowWarning,
      setIsNodeClicked,
      setSelectedNodeId,
      setContentToBeFetched,
      flatStructureDataForNextPrev,
      setCurrentIndex,
      setExpandedNodes,
    ]
  );

  /**
   * Handles the context menu event on a tree item.
   * Prevents the default context menu behavior and stops event propagation.
   * Shows row-level menus if the clicked node is selected.
   *
   * @param {Event} event - The context menu event.
   * @param {string} nodeId - The ID of the clicked node.
   * @param {string} selectedId - The ID of the selected node.
   */
  const handleContextMenu = useCallback((event, nodeId, selectedId) => {
    event.preventDefault();
    event.stopPropagation();
    const treeItem = event.target.closest('.MuiTreeItem-root');
    if (treeItem) {
      const isSelected = nodeId === selectedId;
      if (isSelected) {
        showRowLevelMenus({
          id: 'help-section-menus',
          event,
          props: nodeId,
        });
      }
    }
  }, []);

  /**
   * Finds a node by its ID in a nested nodes structure.
   *
   * @function findNodeById
   * @param {Array} nodes - The array of nodes with potentially nested subsections.
   * @param {string} targetId - The ID of the node to find.
   * @returns {(Object|null)} The found node, or null if not found.
   */
  function findNodeById(nodes, targetId) {
    let foundNode = null;

    nodes?.forEach((node) => {
      if (node?.id === targetId) {
        foundNode = node;
        return;
      }
      if (node?.subsection && node?.subsection?.length > 0) {
        const subNode = findNodeById(node?.subsection, targetId);
        if (subNode) {
          foundNode = subNode;
        }
      }
    });

    return foundNode;
  }

  /**
   * Handles the deletion of a node.
   * Deletes the node with the specified ID and updates the tree data.
   * Displays a success toast message upon successful deletion.
   *
   * @param {string} nodeid - The ID of the node to be deleted.
   */
  const handleDeleteNode = useCallback(
    (nodeid) => {
      deleteNode(nodeid).then((resp) => {
        SwalToast({
          icon: 'success',
          title: resp?.data?.msg,
        });

        getHierarchy({
          id: null,
          tags: null,
          search: null,
        })
          .then((hierarchyResponse) => {
            setTreeData(hierarchyResponse);
            setSelectedNodeId(hierarchyResponse[0]);
            const activeNode = findNodeById(
              hierarchyResponse,
              hierarchyResponse[0]
            );
            setCurrentNode(activeNode);
            setContentToBeFetched(true);
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.set('nodeId', hierarchyResponse[0]);
            window.history.pushState({}, '', newUrl);
          })
          .catch((error) => {
            SwalToast({
              icon: 'error',
              title: error?.msg,
            });
          });

        setShowDeleteModal(false);
      });
    },
    [
      setTreeData,
      setContentToBeFetched,
      setShowDeleteModal,
      setCurrentNode,
      getHierarchy,
    ]
  );
  /**
   * Handles the click event for renaming a node.
   * Sets the node name for rename and shows the rename modal.
   */
  const renameOnClick = useCallback(() => {
    setShowRenameModal(true);
  }, [setShowRenameModal]);
  /**
   * Handles the click event for editing.
   * Sets the state to enable editing and reset the save button state.
   */
  const handleEditClick = useCallback(() => {
    setIsEditing(true);
    setSaveButtonClicked(false);
  }, [setIsEditing, setSaveButtonClicked]);

  /**
   * Handles the click event for deleting.
   * Shows the delete modal.
   */
  const deleteOnClick = useCallback(() => {
    setShowDeleteModal(true);
  }, [setShowDeleteModal]);

  /**
   * Handles the click event for adding a subsection.
   * Shows the subsection modal.
   */
  const subSecOnClick = useCallback(() => {
    setShowSubSecModal(true);
  }, [setShowSubSecModal]);

  /**
   * Handles the click event for adding a section.
   * Shows the section modal.
   */
  const secOnClick = useCallback(() => {
    setShowSecModal(true);
  }, [setShowSecModal]);

  /**
   * Handles closing the delete modal.
   */
  const OnCloseDeleteModal = useCallback(
    () => setShowDeleteModal(false),
    [setShowDeleteModal]
  );

  /**
   * Handles the click event for deleting a node.
   * Calls the delete node function and closes the delete modal.
   *
   * @param {string} nodeid - The ID of the node to be deleted.
   */
  const OnClickDeleteModal = useCallback(
    (nodeid) => {
      handleDeleteNode(nodeid);
      setShowDeleteModal(false);
    },

    [setShowDeleteModal, handleDeleteNode]
  );

  const onCloseDialogBox = useCallback(
    () => setShowRenameModal(false),
    [setShowRenameModal]
  );

  const onClickCloseIconButton = useCallback(() => {
    setShowRenameModal(false);
  }, [setShowRenameModal]);

  /**
   * Handles closing the subsection modal.
   */
  const onCloseDialogBoxSubSec = useCallback(() => {
    formikFormSubSection.handleReset();
    setShowSubSecModal(false);
    setShowSecModal(false);
  }, [setShowSubSecModal, formikFormSubSection]);

  /**
   * Handles clicking the close icon button on the subsection modal.
   * Resets the form and closes the subsection modal.
   */
  const onClickCloseIconButtonSubSec = useCallback(() => {
    formikFormSubSection.handleReset();
    setShowSubSecModal(false);
    setShowSecModal(false);
  }, [formikFormSubSection, setShowSubSecModal]);

  /**
   * Debounces a callback function to be executed after a delay.
   *
   * @param {Function} callback - The callback function to be debounced.
   * @param {number} delay - The delay in milliseconds before the callback is executed.
   * @returns {Function} The debounced callback function.
   */
  const debounce = (callback, delay) => {
    const timeoutRef = useRef(null);
    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);
    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
    return debouncedCallback;
  };

  /**
   * Handles the search content input change event.
   * Dispatches an action to set the keyword search and updates the state.
   *
   * @param {Event} _event - The input change event.
   */
  const handleSearchContent = useCallback(
    (_event) => {
      if (_event?.target?.value?.length > 0) {
        dispatch(setHelpDocumentKeywordSearch(_event.target.value));
        setIsNodeClicked(false);
      } else {
        dispatch(setHelpDocumentKeywordSearch(''));
        setIsNodeClicked(true);
      }
    },
    [dispatch, setHelpDocumentKeywordSearch, setIsNodeClicked]
  );
  /**
   * Handles the debounced search operation.
   * Executes a search query based on the keyword search and updates the state.
   */
  const handleSearch = debounce(() => {
    if (common.helpDocumentKeywordSearch) {
      getHierarchy({
        id: null,
        tags: null,
        search: common.helpDocumentKeywordSearch,
      })
        .then((resp) => {
          setIsNodeClicked(false);
          setContentMain(resp);
          setLengthOfContent(resp?.length);
          setIsNodeClicked(false);
        })
        .catch((error) => {
          SwalToast({
            icon: 'error',
            title: error,
          });
        });
    }
  }, 1200);

  useEffect(() => {
    handleSearch();
  }, [common.helpDocumentKeywordSearch]);

  /**
   * Handles the click event on a node in the tree view with the specified index.
   * Updates the URL with the node ID, sets the node as clicked, and fetches its content.
   * If treeData is available, sets the current node.
   *
   * @param {Event} event - The click event.
   * @param {string} id - The ID of the node.
   */
  const handleClickWithIndex = useCallback(
    (event, id) => {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('nodeId', id);
      window.history.pushState({}, '', newUrl);
      setIsNodeClicked(true);
      fetchActiveNodeContent(id);
      if (treeData) {
        const currentnode = findNodeById(treeData, id);
        setCurrentNode(currentnode);
      }
    },
    [
      treeData,
      setIsNodeClicked,
      setCurrentNode,
      findNodeById,
      fetchActiveNodeContent,
    ]
  );

  /**
   * Generates an array of menu items with their labels, icons, and click handlers.
   * Each menu item represents an action that can be performed on a help section.
   *
   * @type {Array} An array of menu items.
   */
  const menus = React.useMemo(
    () => [
      {
        label: 'Edit content',
        id: 'Edit content',
        icon: DriveFileRenameOutlineIcon,
        onClick: () => handleEditClick(),
      },
      {
        label: 'Edit page',
        id: 'Edit page',
        icon: Cursor,
        onClick: () => renameOnClick(),
      },
      {
        label: 'Delete',
        id: 'Delete',
        icon: DeleteIcon,
        onClick: () => deleteOnClick(),
      },
      {
        label: 'Add section',
        id: 'Add  section',
        icon: Section,
        onClick: () => secOnClick(),
      },
      {
        label: 'Add sub-section',
        id: 'Add Sub section',
        icon: SubSection,
        onClick: () => subSecOnClick(),
      },
    ],
    []
  );

  /**
   * Handles the click event for navigating to the next page in the flat structure data.
   * Updates the current index, selected node, current node, expanded nodes, and triggers necessary actions.
   *
   * @returns {void}
   */
  const handleNextPageClick = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      const tempNextNode = flatStructureDataForNextPrev[prevIndex + 1];
      handleTreeItemClick(undefined, tempNextNode?.id, 'fromNextPageClick');
      // When we click on next page and our next page is nested and not expanded then it should automatically get expanded in the file structure view
      const newExpandedNodes = findNodeAndParents(treeData, tempNextNode?.id);

      if (newExpandedNodes) {
        setExpandedNodes((prev) => {
          return [...prev, ...newExpandedNodes];
        });
      }
      setSelectedNodeId(tempNextNode?.id);
      setCurrentNode(tempNextNode);
      setIsEditing(false);
      setShowWarning(true);
      setIsNodeClicked(true);
      return prevIndex + 1;
    });
  }, [
    currentNode,
    setSelectedNodeId,
    setCurrentNode,
    setShowWarning,
    setIsEditing,
    setIsNodeClicked,
    flatStructureDataForNextPrev,
  ]);

  /**
   * Handles the click event for navigating to the previous page in the flat structure data.
   * Updates the current index, selected node, current node, expanded nodes, and triggers necessary actions.
   *
   * @returns {void}
   */
  const handlePreviousPageClick = useCallback(() => {
    setCurrentIndex((prevIndex) => {
      const tempPrevNode = flatStructureDataForNextPrev[prevIndex - 1];
      handleTreeItemClick(undefined, tempPrevNode?.id, 'fromPreviousPageClick');
      const newExpandedNodes = findNodeAndParents(treeData, tempPrevNode?.id);
      // When we click on next page and our next page is nested and not expanded then it should automatically get expanded in the file structure view
      if (newExpandedNodes) {
        setExpandedNodes((prev) => {
          return [...prev, ...newExpandedNodes];
        });
      }
      setSelectedNodeId(tempPrevNode?.id);
      setCurrentNode(tempPrevNode);
      setIsEditing(false);
      setShowWarning(true);
      setIsNodeClicked(true);
      return prevIndex - 1;
    });
  }, [
    setCurrentNode,
    setIsEditing,
    setShowWarning,
    setIsNodeClicked,
    flatStructureDataForNextPrev,
  ]);

  /**
   * Finds a node in a hierarchical data structure by its path (array of indexes).
   * @param {Array} path - The path representing the indexes to traverse in the hierarchy.
   * @param {Array} nodes - The hierarchical data structure represented as an array of objects.
   * @returns {Object} Returns the node found at the specified path, or undefined if not found.
   */
  function getNodeByPath(path, nodes) {
    let nodeByPath = cloneDeep(nodes);

    path?.forEach((pathSegment, index) => {
      if (path?.length - 1 === index) {
        nodeByPath = nodeByPath[Number(pathSegment) - 1];
      } else {
        nodeByPath = nodeByPath[Number(pathSegment) - 1].subsection;
      }
    });
    return nodeByPath;
  }

  /**
   * Inserts an object above a target object in a hierarchical data structure.
   * @param {Array} data - The hierarchical data structure represented as an array of objects.
   * @param {string} targetId - The ID of the target object above which the new object will be inserted.
   * @param {Object} objectToInsert - The object to insert above the target object.
   * @returns {boolean} Returns true if the object was successfully inserted, otherwise false.
   */
  function insertObjectAboveTarget(data, targetId, objectToInsert) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === targetId) {
        data.splice(i, 0, objectToInsert); // Insert the object above the target object
        return true; // Object inserted successfully
      }

      if (data[i].subsection && data[i].subsection.length > 0) {
        if (
          insertObjectAboveTarget(data[i].subsection, targetId, objectToInsert)
        ) {
          return true; // Object inserted successfully in subsection
        }
      }
    }

    return false; // Target object not found
  }

  /**
   * Inserts an object below a target object in a hierarchical data structure.
   * @param {Array} data - The hierarchical data structure represented as an array of objects.
   * @param {string} targetId - The ID of the target object below which the new object will be inserted.
   * @param {Object} objectToInsert - The object to insert below the target object.
   * @returns {boolean} Returns true if the object was successfully inserted, otherwise false.
   */
  function insertObjectBelowTarget(data, targetId, objectToInsert) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === targetId) {
        data.splice(i + 1, 0, objectToInsert); // Insert the object above the target object
        return true; // Object inserted successfully
      }

      if (data[i].subsection && data[i].subsection.length > 0) {
        if (
          insertObjectBelowTarget(data[i].subsection, targetId, objectToInsert)
        ) {
          return true; // Object inserted successfully in subsection
        }
      }
    }

    return false; // Target object not found
  }

  /**
   * Removes an object from a hierarchical data structure by its ID.
   * @param {Array} data - The hierarchical data structure represented as an array of objects.
   * @param {string} idToRemove - The ID of the object to remove.
   * @returns {boolean} Returns true if the object was successfully removed, otherwise false.
   */
  function removeObjectById(data, idToRemove) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === idToRemove) {
        data.splice(i, 1);
        return true; // Object removed successfully
      }

      if (data[i].subsection && data[i].subsection.length > 0) {
        if (removeObjectById(data[i].subsection, idToRemove)) {
          return true; // Object removed successfully from subsection
        }
      }
    }

    return false; // Object not found
  }

  /**
   * Formats rearranged hierarchical tree data by extracting relevant information.
   * @param {Array} sections - The hierarchical tree data represented as an array of objects.
   * @returns {Array} Returns an array of objects containing only the ID and subsection (if present) of each node.
   */
  const formatRearrangeData = (sections) => {
    return sections.reduce((acc, node) => {
      const { id, subsection } = node;
      const extractedNode = { id };

      if (subsection && subsection.length > 0) {
        extractedNode.subsection = formatRearrangeData(subsection);
      } else {
        extractedNode.subsection = [];
      }

      return [...acc, extractedNode];
    }, []);
  };

  /**
   * Moves an item from one place to another in a hierarchical tree data structure.
   * @param {Object} fromItem - The item to be moved.
   * @param {Object} toItem - The item representing the destination place where the item will be moved.
   */
  const moveItem = (fromItem, toItem, isLast) => {
    const fromPath = fromItem?.nodeNumber?.split('.');
    // Find the source node
    const sourceNode = getNodeByPath(fromPath, treeData);

    // Find the target node
    const targetNode = getNodeByPath(toItem?.nodePath?.split('.'), treeData);

    // If the target and source are same or the target is present into the source then do nothing
    if (findTargetNodeInsideTheSourceNode(sourceNode, targetNode?.id)) {
      return;
    }

    const clonedTransformedTreeData = cloneDeep(treeData);

    // Removed the source
    removeObjectById(clonedTransformedTreeData, sourceNode?.id);

    // If the dropzone is at last then inserting the source below the target else above the target
    if (isLast) {
      insertObjectBelowTarget(
        clonedTransformedTreeData,
        targetNode?.id,
        sourceNode
      );
    } else {
      insertObjectAboveTarget(
        clonedTransformedTreeData,
        targetNode?.id,
        sourceNode
      );
    }

    const formattedDataResponse = formatRearrangeData(
      clonedTransformedTreeData
    );
    saveFileStructure({
      formattedDataResponse,
    }).then((resp) => {
      SwalToast({
        icon: 'success',
        title: resp?.data?.msg,
      });
      fetchTreeView();
    });
  };

  /**
   * Recursively inserts an object into the subsection of a target node in a hierarchical data structure.
   * @param {Array} data - The hierarchical data structure represented as an array of objects.
   * @param {string} targetNodeId - The ID of the target node where the object should be inserted.
   * @param {Object} objectToInsert - The object to insert into the subsection of the target node.
   * @returns {boolean} Returns true if the object was successfully inserted, otherwise false.
   */
  function insertIntoSubsection(data, targetNodeId, objectToInsert) {
    data.forEach((item) => {
      if (item.id === targetNodeId && item.subsection) {
        item.subsection.push(objectToInsert);
        return true; // Object inserted successfully
      }
      if (
        item.subsection &&
        insertIntoSubsection(item.subsection, targetNodeId, objectToInsert)
      ) {
        return true; // Object inserted successfully in a nested subsection
      }
    });
    return false; // Target node not found
  }

  /**
   * Moves an item from one section into another in a hierarchical tree data structure.
   * @param {Object} fromItem - The item to be moved.
   * @param {Object} toItem - The item representing the destination section where the item will be moved.
   */
  const moveItemIntoTheSection = (fromItem, toItem) => {
    const fromPath = fromItem?.nodeNumber?.split('.');
    // Find the source node
    const sourceNode = getNodeByPath(fromPath, treeData);

    // Find the target node
    const targetNode = getNodeByPath(toItem?.nodePath?.split('.'), treeData);

    // If the target and source are same or the target is present into the source then do nothing
    if (findTargetNodeInsideTheSourceNode(sourceNode, targetNode?.id)) {
      return;
    }

    const clonedTransformedTreeData = cloneDeep(treeData);

    // Removed the source
    removeObjectById(clonedTransformedTreeData, sourceNode?.id);

    insertIntoSubsection(clonedTransformedTreeData, targetNode?.id, sourceNode);

    const formattedDataResponse = formatRearrangeData(
      clonedTransformedTreeData
    );
    saveFileStructure({
      formattedDataResponse,
    }).then((resp) => {
      SwalToast({
        icon: 'success',
        title: resp?.data?.msg,
      });
      fetchTreeView();
    });
  };

  useEffect(() => {
    setRemainingContent(
      (Math.floor(lengthOfContent / NumberOfResultstoShow) - numberOfresults) *
        NumberOfResultstoShow
    );
  }, [numberOfresults, lengthOfContent, setRemainingContent]);

  useEffect(() => {
    if (currentNode?.id && treeData && contentToBeFetched) {
      fetchActiveNodeContent(currentNode?.id);
      setContentToBeFetched(false);
    }
  }, [currentNode, treeData, setContentToBeFetched]);

  useEffect(() => {
    if (treeData?.length) {
      const urlParams = new URLSearchParams(window.location.search);
      const urlNodeId = urlParams.get('nodeId');

      if (urlNodeId) {
        setSelectedNodeId(urlNodeId);
      } else {
        setSelectedNodeId(treeData[0]?.id);
      }

      setIsNodeClicked(true);
    }
  }, [window.location.href, setSelectedNodeId, treeData]);

  useEffect(() => {
    if (saveButtonClicked) {
      updateSection({
        id: currentNode?.id,
        title: currentNode?.title,
        content: editorContent,
        tags,
      })
        .then(() => {
          fetchActiveNodeContent(currentNode?.id);
          setIsEditing(false);
          setShowWarning(true);
          setSaveButtonClicked(false);
        })
        .catch((error) => {
          SwalToast({
            icon: 'error',
            title: error?.msg,
          });
        });
    }
  }, [
    editorContent,
    setIsEditing,
    setShowWarning,
    saveButtonClicked,
    currentNode,
    setSaveButtonClicked,
  ]);

  useEffect(() => {
    if (treeData && selectedNodeId) {
      const activeNode = findNodeById(treeData, selectedNodeId);
      setCurrentNode(activeNode);

      const currentNodeIndexInFlatStructureDataForNextPrev =
        findCurrentNodeIndexInFlatStructureDataForNextPrev(selectedNodeId);

      if (
        currentNodeIndexInFlatStructureDataForNextPrev &&
        currentNodeIndexInFlatStructureDataForNextPrev >= 0
      ) {
        setCurrentIndex(currentNodeIndexInFlatStructureDataForNextPrev);
      }
    }
  }, [treeData, selectedNodeId, setCurrentNode]);

  const onClickCloseWarning = useCallback(() => {
    setShowWarning(false);
  }, [setShowWarning]);

  useEffect(() => {
    setFlatStructureDataForNextPrev(flattenNodes(treeData));
  }, [treeData]);

  useEffect(() => {
    const newExpandedNodes = findNodeAndParents(treeData, selectedNodeId);
    // When we refresh the page or come on the help page we will show the expanded node from which we are redirected from
    if (newExpandedNodes && expandedRef.current) {
      setExpandedNodes((prev) => {
        expandedRef.current = false;
        return [...prev, ...newExpandedNodes];
      });
    }
  }, [selectedNodeId, treeData]);

  useEffect(() => {
    if (showRenameModal) {
      formikFormRename.setFieldValue('newTitle', currentNode?.title);
    }
  }, [currentNode, showRenameModal]);

  useEffect(() => {
    if (showRenameModal) {
      formikFormRename.setFieldValue(
        'tags',
        moduleMapping?.filter((mapping) => tags?.includes(mapping?.value))
      );
    }
  }, [tags, showRenameModal]);

  const resetOnClick = useCallback(() => {
    formikFormRename?.handleReset();
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        backgroundColor: theme?.palette?.common?.white,
      }}
    >
      <Grid
        xs={4}
        sx={{
          overflow: 'auto',
          borderRight: `1px solid ${theme?.palette?.grey?.[300]}`,
          maxHeight: '100%',
          width: '364px',
          paddingTop: '24px',
        }}
      >
        <Box paddingLeft={4}>
          <Typography variant="h4">
            {access && permissions?.Settings_EditSupportPage === 'Manage'
              ? 'Edit Support Page'
              : 'NIMBUS Support'}
          </Typography>
        </Box>
        <Box
          marginTop="16px"
          display="flex"
          flexDirection="row"
          marginLeft="27px"
          sx={{
            width: '320px',
            height: '48px',
            border: `2px solid ${theme?.palette?.grey?.[300]}`,
            borderRadius: '8px',
            outline: 'none',
            transition: 'border-color 0.3s ease',
          }}
          onFocus={(e) => handleFocus(e, theme)}
          onBlur={(e) => handleBlur(e, theme)}
        >
          <TransperentSearchBox
            value={common.helpDocumentKeywordSearch}
            sx={{
              marginTop: '0px',
            }}
            onChange={handleSearchContent}
            placeholder="Search what you need"
          />
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            height: 'calc(100vh - 110px)',
            paddingBottom: '4px',
            paddingTop: '24px',
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <TreeView
              aria-label="rich object"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expandedNodes}
              sx={{
                maxHeight: '100%',
                overflowX: 'none',
              }}
            >
              {renderTree(
                treeData,
                undefined,
                handleTreeItemClick,
                handleContextMenu,
                selectedNodeId,
                moveItem,
                moveItemIntoTheSection,
                access,
                permissions,
                theme
              )}
            </TreeView>
          </DndProvider>
        </Box>
      </Grid>

      <Grid
        item
        xs={8}
        sx={{
          width: 'calc(100% - 364px)',
        }}
      >
        {isEditing ? (
          <Grid container>
            <Grid container item>
              {showWarning && (
                <Grid
                  xs={12}
                  sx={{
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#FFF3D8',
                    alignItems: 'center',
                    px: '16px',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <HelpSectionWarningIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body3">
                        This is an unpublished draft.
                      </Typography>
                      <Typography variant="body4">
                        Only you can see it until you save it.
                      </Typography>
                    </Grid>
                  </Grid>
                  <IconButton
                    id="help_section_rename"
                    aria-label="close"
                    onClick={onClickCloseWarning}
                    size="small"
                    sx={{
                      color: theme?.palette?.common?.black,
                    }}
                  >
                    <CloseIcon
                      sx={{
                        height: '16px',
                        width: '16px',
                      }}
                    />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12}>
              <JoditEditorDialog
                showJoditEditor={isEditing}
                initialContent={content}
                setEditorContent={setEditorContent}
                setSaveButtonClicked={setSaveButtonClicked}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} sx={{ overflowY: 'auto', height: '100vh' }}>
            {(common.helpDocumentKeywordSearch?.length === 0 ||
              isNodeClicked) && (
              <Box
                sx={{
                  paddingLeft: !isEditing ? '24px' : '0px',
                  paddingRight: !isEditing ? '24px' : '0px',
                  marginLeft: '5px',
                  paddingTop: '24px',
                  maxWidth: '100%',
                  textAlign: 'justify',
                  backgroundColor: 'transparent',
                }}
              >
                <Typography
                  sx={{ color: theme?.palette?.common?.black }}
                  variant="h4"
                >
                  {currentNode?.title}
                </Typography>
                <Typography
                  sx={{
                    color: theme?.palette?.common?.black,
                    fontWeight: 400,
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    backgroundColor: 'transparent',
                  }}
                >
                  {parse(DOMPurify.sanitize(content))}
                </Typography>
              </Box>
            )}
            {common.helpDocumentKeywordSearch?.length > 0 && !isNodeClicked && (
              <Box marginTop={2} paddingLeft={!isEditing ? 3 : 0}>
                {common.helpDocumentKeywordSearch?.length > 0 &&
                  lengthOfContent !== 0 &&
                  !isNodeClicked && (
                    <>
                      <Typography variant="body2">
                        Showing results from content containing{' '}
                      </Typography>
                      <Typography variant="body1">{`'${common.helpDocumentKeywordSearch}'`}</Typography>
                    </>
                  )}
              </Box>
            )}
            {common.helpDocumentKeywordSearch?.length > 0 &&
              !isNodeClicked &&
              lengthOfContent === 0 && (
                <>
                  <Box
                    marginTop="22%"
                    marginLeft="35%"
                    paddingLeft={!isEditing ? 3 : 0}
                  >
                    {common.helpDocumentKeywordSearch?.length > 0 &&
                      lengthOfContent === 0 &&
                      !isNodeClicked && <NoDataFound />}
                  </Box>
                  <Box marginLeft="35%">
                    {common.helpDocumentKeywordSearch?.length > 0 &&
                      lengthOfContent === 0 &&
                      !isNodeClicked && (
                        <Typography variant="body2">
                          No matching result found.
                        </Typography>
                      )}
                  </Box>
                </>
              )}
            {common.helpDocumentKeywordSearch?.length > 0 &&
              lengthOfContent > 0 &&
              !isNodeClicked &&
              contentMain?.map(
                (item, index) =>
                  index < numberOfresults * NumberOfResultstoShow && (
                    <Box
                      px={3}
                      py={0}
                      key={item?.id}
                      role="button"
                      tabIndex={0}
                      onClick={(event) => handleClickWithIndex(event, item?.id)}
                      sx={{
                        borderRadius: '8px',
                        marginTop: '10px',
                        cursor: 'pointer',
                      }}
                      onMouseEnter={(e) => handleMouseEnter(e, theme)}
                      onMouseLeave={(e) => handleMouseLeave(e, theme)}
                    >
                      <Box sx={{ paddingTop: '5px' }}>
                        <Typography variant="body1">{item.title}</Typography>
                      </Box>
                      <Box
                        sx={{
                          opacity: '60%',
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            margin: '0px',
                            padding: '0px',
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            height: '78px',
                            color: theme?.palette?.other?.brandBlue,
                            textAlign: 'justify',
                          }}
                        >
                          {showContentWithElipses(
                            item?.content?.repeat(10).replace(/<[^>]+>/g, ''),
                            370
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  )
              )}

            {!isNodeClicked && common.helpDocumentKeywordSearch?.length > 0 && (
              <Box
                paddingTop="40px"
                paddingBottom="40px"
                display="flex"
                justifyContent="space-between"
              >
                {numberOfresults * NumberOfResultstoShow < lengthOfContent && (
                  <Typography
                    sx={{
                      marginLeft: '16px',
                      color: theme?.palette?.secondary?.contrastText,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={handleShowMore}
                  >
                    Show{' '}
                    {remainingContent >= NumberOfResultstoShow
                      ? NumberOfResultstoShow
                      : lengthOfContent -
                        numberOfresults * NumberOfResultstoShow}{' '}
                    more articles
                  </Typography>
                )}
                {numberOfresults > 1 && lengthOfContent > 0 && (
                  <Typography
                    sx={{
                      marginLeft: '16px',
                      color: theme?.palette?.secondary?.contrastText,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={handleShowLess}
                  >
                    Show less
                  </Typography>
                )}
              </Box>
            )}
            {isNodeClicked && !isEditing && (
              <Grid
                container
                justifyContent="center"
                spacing={2}
                sx={{
                  paddingTop: '80px',
                  paddingBottom: '50px',
                  paddingLeft: '24px',
                  paddingRight: '24px',
                }}
              >
                {previousNode?.id && (
                  <Grid item xs={12} sm={nextNode?.id ? 6 : 12}>
                    <Box
                      sx={{
                        border: '0.2px solid rgba(39, 45, 85, 1)',
                        borderRadius: '8px',
                        '&:hover': {
                          border: 'none',
                          boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.2)',
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handlePreviousPageClick}
                        sx={{
                          width: '100%',
                          border: 'none',
                          height: '60px',
                          '&:hover': {
                            border: 'none',
                          },
                        }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <IconButton size="small">
                              <ArrowBackIcon />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            sm={9}
                            md={10}
                            sx={{ textAlign: 'right' }}
                          >
                            <Typography variant="body4" sx={{ opacity: 0.7 }}>
                              Previous
                            </Typography>
                            <Typography
                              variant="body3"
                              noWrap
                              component="div"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                height: '16px',
                              }}
                            >
                              {previousNode?.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Box>
                  </Grid>
                )}

                {nextNode?.id && (
                  <Grid item xs={12} sm={previousNode?.id ? 6 : 12}>
                    <Box
                      sx={{
                        border: '0.2px solid rgba(39, 45, 85, 1)',
                        borderRadius: '8px',
                        '&:hover': {
                          border: 'none',
                          boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.2)',
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleNextPageClick}
                        sx={{
                          width: '100%',
                          border: 'none',
                          height: '60px',
                          '&:hover': {
                            border: 'none',
                          },
                        }}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={7}
                            sm={9}
                            md={10}
                            sx={{ textAlign: 'left' }}
                          >
                            <Typography variant="body4" sx={{ opacity: 0.7 }}>
                              Next
                            </Typography>
                            <Typography
                              variant="body3"
                              noWrap
                              component="div"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                height: '16px',
                              }}
                            >
                              {nextNode?.title}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton size="small">
                              <ArrowForwardIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {access && permissions?.Settings_EditSupportPage === 'Manage' && (
        <ContextMenus menuId="help-section-menus" menus={menus} />
      )}
      <Dialog
        open={showRenameModal}
        aria-labelledby="Rename-dataset-dialog-title"
        maxWidth="xs"
        fullWidth
        onClose={onCloseDialogBox}
      >
        <DialogTitle>Edit Page</DialogTitle>
        <IconButton
          id="help_section_rename"
          aria-label="close"
          onClick={onClickCloseIconButton}
          size="large"
          sx={DIALOG_ICON_BUTTON_CSS}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                type="text"
                label="Edit title"
                placeholder="Enter title"
                name="newTitle"
                {...formikFormRename?.getFieldProps('newTitle')}
                error={
                  Boolean(formikFormRename?.errors?.newTitle) &&
                  formikFormRename?.touched?.newTitle
                }
                helperText={
                  formikFormRename?.errors?.newTitle &&
                  formikFormRename?.touched?.newTitle
                    ? formikFormRename.errors.newTitle
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MultiSelect
                required={true}
                value={
                  formikFormRename?.values?.tags
                    ? formikFormRename?.values?.tags
                    : []
                }
                limitTags={3}
                label="Tags"
                placeholder="Select tags"
                InputLabelProps={{
                  shrink: true,
                }}
                noOptionsText="No tag available"
                name="tags"
                options={moduleMapping?.length ? moduleMapping : []}
                popupIcon={<DropdownArrow />}
                onChange={(e) => {
                  formikFormRename.setFieldValue('tags', e);
                }}
                onBlur={formikFormRename.handleBlur('tags')}
                isOptionEqualToValue={(option, val) =>
                  option?.value === val?.value
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={option?.value}
                      color="primary"
                      label={`${option?.label} `}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
                error={
                  formikFormRename.errors?.tags &&
                  formikFormRename.touched?.tags
                    ? formikFormRename.errors.tags
                    : null
                }
                helperText={
                  formikFormRename.errors?.tags &&
                  formikFormRename.touched?.tags
                    ? formikFormRename.errors.tags
                    : null
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={resetOnClick}
          >
            Reset
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={formikFormRename.handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDeleteModal}
        aria-labelledby="customized-dialog-title"
        maxWidth="xs"
        fullWidth
        TransitionComponent={DialogTransition}
        onClose={OnCloseDeleteModal}
      >
        <DeleteConfirmModal
          open={showDeleteModal}
          onCloseClick={OnCloseDeleteModal}
          onDeleteClick={() => OnClickDeleteModal(currentNode?.id)}
          confirmationMsg={`Are you sure you want to delete ${currentNode?.title} ?`}
          resourceName="Section"
        />
      </Dialog>
      <Dialog
        open={showSubSecModal || showSecModal}
        aria-labelledby="Rename-dataset-dialog-title"
        maxWidth="xs"
        fullWidth
        onClose={onCloseDialogBoxSubSec}
      >
        <DialogTitle>
          {showSecModal ? 'Add Section' : 'Add Sub-section'}
        </DialogTitle>
        <IconButton
          id="help_section_add_sub_section"
          aria-label="add sub section"
          onClick={onClickCloseIconButtonSubSec}
          size="large"
          sx={DIALOG_ICON_BUTTON_CSS}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label={showSecModal ? 'Section title' : 'Sub-section title'}
                placeholder={
                  showSecModal
                    ? 'Enter section title'
                    : 'Enter Sub-section title'
                }
                name="newTitle"
                {...formikFormSubSection?.getFieldProps('newSubSectionName')}
                error={
                  Boolean(formikFormSubSection?.errors?.newSubSectionName) &&
                  formikFormSubSection?.touched?.newSubSectionName
                }
                helperText={
                  formikFormSubSection?.errors?.newSubSectionName &&
                  formikFormSubSection?.touched?.newSubSectionName
                    ? formikFormSubSection.errors?.newSubSectionName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MultiSelect
                required
                value={
                  formikFormSubSection?.values?.tags
                    ? formikFormSubSection?.values?.tags
                    : []
                }
                limitTags={3}
                label="Tags"
                placeholder="Select tags"
                InputLabelProps={{
                  shrink: true,
                }}
                noOptionsText="No tag available"
                name="tags"
                options={moduleMapping?.length ? moduleMapping : []}
                popupIcon={<DropdownArrow />}
                onChange={(e) => {
                  formikFormSubSection.setFieldValue('tags', e);
                }}
                onBlur={formikFormSubSection.handleBlur('tags')}
                isOptionEqualToValue={(option, val) =>
                  option?.value === val?.value
                }
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={option?.value}
                      color="primary"
                      label={`${option?.label} `}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
                error={
                  formikFormSubSection.errors?.tags &&
                  formikFormSubSection.touched?.tags
                    ? formikFormSubSection.errors.tags
                    : null
                }
                helperText={
                  formikFormSubSection.errors?.tags &&
                  formikFormSubSection.touched?.tags
                    ? formikFormSubSection.errors.tags
                    : null
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={formikFormSubSection.handleReset}
          >
            Reset
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={formikFormSubSection.handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
