// @ts-nocheck
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Button,
  Box,
  Typography,
  TextField,
  IconButton,
  Card,
  Tooltip,
  // Stack,
  Dialog,
  Chip,
  CardContent,
  Slide,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { alpha } from '@mui/material/styles';
import Editor, { loader } from '@monaco-editor/react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { object, string, array, bool } from 'yup';
import { useFormik } from 'formik';
// import Autocomplete from '@mui/material/Autocomplete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
// import { isEmpty } from 'lodash';
import { useResizeDetector } from 'react-resize-detector';
import { DIALOG_ICON_BUTTON_CSS } from '../../const/CommonConst';
import MultiSelect from './MultiSelect';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import { DropDown, DropdownItem } from '../../utils/material-ui_common';
import { ReactComponent as LightMode } from '../../assets/images/LightMode.svg';
import { ReactComponent as DarkMode } from '../../assets/images/DarkMode.svg';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
// import ConsoleArgumentInput from './ConsoleArgumentInput';
import SwalToast from './SwalToast';
import ConsoleOperationsResultViewer from './ConsoleOperationsResultViewer';
import utils from '../../utils/utils';
import ConsoleCreateEditArgModal from './ConsoleCreateEditArgModal';

loader.config({ paths: { vs: '/monaco' } });

const TEN = 10;
const MAX_CHARACTER_SIZE = 225;
const TIME_OUT_THOUSAND = 1000;
const TIME_OUT_SIX_HUNDRED = 600;

const extractArg = (arg) => {
  switch (arg.type.value) {
    case 'Int':
    case 'Float':
      return {
        ...arg,
        type: arg.type.value,
        value: Number(arg.value),
      };
    case 'Column':
      return {
        ...arg,
        value: arg.value.map((item) => item.column_name),
        type: arg.type.value,
        dataset: arg.dataset.id,
      };
    case 'Model':
    case 'Dataset':
      return {
        ...arg,
        value: arg.value.id,
        type: arg.type.value,
      };
    case 'ConsoleFiles':
      return {
        ...arg,
        value: arg.value.id,
        type: arg.type.value,
      };
    default:
      return {
        ...arg,
        type: arg.type.value,
      };
  }
};

const CustomCell = (data) => {
  const { cell } = data;
  const value = [true, false].includes(cell.value)
    ? cell.value.toString()
    : cell.value;
  return <Box title={value}>{value}</Box>;
};

/**
 * @function formatArg
 * @description To Format Arg
 * @param {object} arg   - An object which stores the arg key and values
 * @param {object} models - An object which stores the models
 * @param {any} datasets - It stores the all the dataset
 * @param {function} fetchDatasetColumnDetails - A function to fetch Column Details
 * @param {object} activeProject       - An object which the projects which are active
 * @returns {JSX.Element}
 * @example <formatArg arg={arg}/>
 */
const formatArg = async (
  arg,
  models,
  datasets,
  consoleFilesList,
  fetchDatasetColumnDetails,
  activeProject
) => {
  switch (arg.type) {
    case 'Model':
      return {
        ...arg,
        value: models.find((model) => model.id === parseInt(arg.value, TEN)),
        type: { value: arg.type, label: arg.type },
      };
    case 'Dataset':
      return {
        ...arg,
        value: datasets.find(
          (dataset) => dataset.id === parseInt(arg.value, TEN)
        ),
        type: { value: arg.type, label: arg.type },
      };
    case 'Column': {
      const dataset = datasets.find(
        (ds) => ds.id === parseInt(arg.dataset, TEN)
      );
      const {
        data: { columns: datasetColumns },
      } = await fetchDatasetColumnDetails({
        project_id: activeProject?.project_id,
        datasetId: dataset?.id,
      });
      return {
        ...arg,
        dataset,
        datasetColumns,
        value: datasetColumns.filter((col) =>
          arg.value.includes(col.column_name)
        ),
        type: { value: arg.type, label: arg.type },
      };
    }
    case 'ConsoleFiles':
      return {
        ...arg,
        value: consoleFilesList.find(
          (file) => file.id === parseInt(arg.value, TEN)
        ),
        type: { value: arg.type, label: arg.type },
      };
    default:
      return {
        ...arg,
        type: { value: arg.type, label: arg.type },
      };
  }
};

/**
 * @function ConsoleCreateEditUdf
 * @description To create and edit udf
 * @param {object} githubValue  - An object to store github value
 * @param {function} setGithubValue - A function to set Github Value
 * @param {string} mode       - A string which stores value of mode
 * @param {function} setMode  - A function to set Mode value
 * @param {object} udf        - An object
 * @param {object} activeProject - An object which stores the projects which are active
 * @param {function} createUdf  - A function to create UDF
 * @param {function} updateUdf  - A function to update UDF
 * @param {string} moduleName   - A string which store Module Name
 * @param {string} subModuleName - A string which store subModule Name
 * @param {function} fetchUdfs   - A function to fetch UDFs
 * @param {function} runUdf      - A function to run UDF
 * @param {object} datasetList   - An object which store all the datasets
 * @param {function} fetchDatasetColumnDetails   - A function to fetch Dataset Column Details
 * @param {object} allModels    - A object which store all Models
 * @param {function} onBackClick - A function which call on Back Click
 * @param {function} fetchTableData - A function to fetch Data from the table
 * @param {function} fetchConsoleLibraries - A function to fetch Console Libraries
 * @returns {JSX.Element}
 * @example <ConsoleCreateEditUdf githubValue={githubValue}/>
 */
export default function ConsoleCreateEditUdf({
  githubValue,
  setGithubValue = () => {},
  mode = 'create',
  setMode = () => {},
  udf,
  activeProject,
  createUdf,
  updateUdf,
  moduleName,
  subModuleName,
  fetchUdfs,
  runUdf,
  datasetList = [],
  fetchDatasetColumnDetails,
  allModels,
  onBackClick,
  fetchTableData,
  fetchConsoleLibraries,
  fetchConsoleFileList = () => {},
}) {
  const { width, height, ref } = useResizeDetector();
  const [consoleFilesList, setConsoleFilesList] = useState();
  const [argumentList, setArgumentList] = useState([]);
  const [updateArgument, setUpdateArgument] = useState([]);
  const [showConsoleLibraryDialog, setShowConsoleLibraryDialog] =
    React.useState(false);
  const [open, setOpen] = useState(false);
  const [argumentMode, setArgumentMode] = React.useState(false);
  const [libraries, setLibraries] = useState([]);
  // form, editor
  const [view, setView] = React.useState('form');
  const [editorTheme, setEditorTheme] = React.useState('myThemeDark');
  const monacoRef = useRef(null);
  const [subModulesForSelectedModule, setSubModulesForSelectedModule] =
    React.useState();
  const [runResult, setRunResult] = React.useState(null);

  const [cardsPerRow, setCardsPerRow] = useState(0);
  const [timesButtonClicked, setTimesButtonClicked] = useState(0);
  const [editArgument, setEditArgument] = useState({});
  const initialCards = argumentList?.slice(
    0,
    cardsPerRow * Math.floor(height / 200)
  );
  const [slicedList, setSlicedList] = useState(initialCards);
  const cardWidth = 330 + (width % 330) / Math.floor(width / 330) - 16;

  useEffect(() => {
    setSlicedList(initialCards);
  }, [argumentList, height]);
  useEffect(() => {
    setSlicedList(
      argumentList?.slice(0, (2 + timesButtonClicked) * cardsPerRow)
    );
  }, [cardsPerRow, height]);

  const formValidationSchema = object({
    name: string()
      .required('Required')
      .max(MAX_CHARACTER_SIZE, 'Only 225 characters are allowed.'),
    projectId: string().required('Required'),
    module: string().required('Required'),
    code: string(),
    subModule: string().required('Required'),
    libraries: array().nullable(),
    showInOpBar: bool(),
  });
  const formikForm = useFormik({
    initialValues: {
      name: '',
      projectId: activeProject?.project_id,
      code: '',
      module: moduleName,
      subModule: subModuleName,
      libraries: [],
      showInOpBar: false,
    },
    validationSchema: formValidationSchema,
    onSubmit: () => {
      const payload = {
        name: formikForm.values.name,
        module: formikForm.values.module,
        subModule: formikForm.values.subModule,
        projectId: formikForm.values.projectId,
        args: [],
        showInOpBar: formikForm.values.showInOpBar,
        githubInt: githubValue.githubInt,
        code: githubValue.code,
      };
      argumentList.forEach((arg) => {
        payload.args.push(extractArg(arg));
      });
      createUdf(payload).then((resp) => {
        formikForm.setFieldValue('code', resp.data.code);
        formikForm.setFieldValue('id', resp.data.id);
        setMode('edit');
        setView('editor');
        fetchUdfs();
        setGithubValue({});
      });
    },
  });

  React.useEffect(() => {
    const isConditionMet = Boolean(
      mode === 'edit' &&
        udf &&
        Array.isArray(allModels) &&
        activeProject &&
        datasetList?.length >= 0
    );
    (async function () {
      if (isConditionMet) {
        const { user, ...additionalData } = udf;
        formikForm.setValues({
          ...additionalData,
        });
        formikForm.setFieldValue('argument', udf?.args?.length);
        // eslint-disable-next-line no-unsafe-optional-chaining
        const args = [...udf?.args];
        formikForm.setFieldValue('showInOpBar', udf.showInOpBar);
        // eslint-disable-next-line no-restricted-syntax
        for (const [index, arg] of args.entries()) {
          // eslint-disable-next-line no-await-in-loop
          args[index] = await formatArg(
            { ...arg },
            [...allModels],
            [...datasetList],
            [...consoleFilesList],
            fetchDatasetColumnDetails,
            activeProject
          );
        }
        setArgumentList(args);

        setTimeout(() => {
          formikForm.validateForm();
        }, TIME_OUT_THOUSAND);
      }
    })();
  }, [udf, allModels, datasetList, activeProject, consoleFilesList]);
  React.useEffect(() => {
    Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).forEach((key) => {
      if (
        MODULE_SUB_MODULE_OPERATIONS_TREE[key].key === formikForm.values?.module
      ) {
        setSubModulesForSelectedModule(
          MODULE_SUB_MODULE_OPERATIONS_TREE[key].subModules
        );
      }
    });
  }, [formikForm.values?.module]);

  React.useEffect(() => {
    if (fetchConsoleLibraries) {
      fetchConsoleLibraries().then((resp) => {
        setLibraries(resp.data);
      });
    }
  }, []);

  /**
   * @function saveUdf
   * @description To update and save UDF
   * @returns {void}
   * @example saveUdf()
   */
  const saveUdf = () => {
    const payload = {
      ...formikForm.values,
      libraries: formikForm.values.libraries,
      code: formikForm.values.code,
      args: [],
      showInOpBar: formikForm.values.showInOpBar,
    };
    argumentList.forEach((arg) => {
      payload.args.push(extractArg(arg));
    });
    updateUdf(payload).then(() => {
      fetchUdfs();
      SwalToast({
        icon: 'success',
        title: 'Saved successfully.',
      });
    });
  };

  /**
   * @function run
   * @description It is for update and run Udf
   * @returns {void}
   * @example run()
   */
  const run = () => {
    const payload = {
      ...formikForm.values,
      libraries: formikForm.values.libraries,
      code: formikForm.values.code,
      args: [],
    };
    argumentList.forEach((arg) => {
      payload.args.push(extractArg({ ...arg }));
    });
    updateUdf(payload).then((resp) => {
      fetchUdfs();
      setTimeout(() => {
        runUdf(
          resp?.data?.id || formikForm?.values?.id,
          activeProject?.project_id
        ).then((runResp) => {
          setRunResult(runResp.data);
          SwalToast({
            icon: 'success',
            title: 'Run success.',
          });
        });
      }, TIME_OUT_SIX_HUNDRED);
    });
  };

  const handleEditorDidMount = (monaco) => {
    // here is another way to get monaco instance
    // you can also store it in `useRef` for further usage
    monacoRef.current = monaco;
    monaco.editor.defineTheme('myThemeDark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.foreground': '#AECDFC',
        'editor.background': '#141A3F',
        // 'editorCursor.foreground': '#8B0000',
        // 'editor.lineHighlightBackground': '#0000FF20',
        'editorLineNumber.foreground': '#141A3F',
        'editorLineNumber.activeForeground': '#fff',
        // 'editorLineNumber.background': 'red',
        'editorGutter.background': '#84afefbf',
        // 'editorGutter.mar': '5px',
        // 'editor.selectionBackground': '#88000030',
        // 'editor.inactiveSelectionBackground': '#88000015',
        'scrollbarSlider.background': '#AECDFC',
        'scrollbarSlider.activeBackground': '#AECDFC',
        'scrollbarSlider.hoverBackground': '#AECDFC',
      },
    });
    monaco.editor.defineTheme('myThemeLight', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.foreground': '#000000',
        'editor.background': '#FFFFFF',
        'editorLineNumber.foreground': '#141A3F',
        'editorLineNumber.activeForeground': '#000',
        'editorGutter.background': '#C8DEFF',
      },
    });
  };

  const nextButtonOnClick = useCallback(() => {
    if (mode === 'create') {
      formikForm.handleSubmit();
      return;
    }
    if (mode === 'edit') {
      setView('editor');
      setRunResult(null);
    }
  }, [setView, setRunResult, formikForm]);

  const callBackClickFunc = () => {
    onBackClick();
  };

  const backArrowOnClick = useCallback(() => {
    switch (view) {
      case 'form':
        callBackClickFunc();
        break;
      case 'editor':
        setView('form');
        break;
      default:
        break;
    }
  }, [callBackClickFunc, setView]);

  useEffect(() => {
    fetchConsoleFileList(activeProject?.project_id).then((resp) => {
      setConsoleFilesList(resp.data);
    });
  }, [activeProject]);

  const openArgumentModel = () => {
    setArgumentMode(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleArgumentSubmit = useCallback(
    (value) => {
      if (argumentMode) {
        setArgumentList((prevArray) => {
          const newArray = [...prevArray];
          newArray[updateArgument] = value;
          return newArray;
        });
      } else {
        setArgumentList([...argumentList, value]);
      }
    },
    [updateArgument, argumentMode, argumentList]
  );

  const onDeleteClick = useCallback(
    (index) => {
      const updatedList = argumentList.filter((_, i) => i !== index);
      setArgumentList(updatedList);
    },
    [argumentList]
  );
  const countCards = (windowWidth) => {
    const count = utils.getCardCount(windowWidth);
    setCardsPerRow(count);
  };
  useEffect(() => {
    countCards(width);
  }, [width, height, slicedList]);

  useEffect(() => {
    setArgumentList(argumentList);
  }, [argumentList]);

  const addRowToList = useCallback(() => {
    setTimesButtonClicked((prev) => prev + 1);
    setSlicedList((prev) => argumentList?.slice(0, cardsPerRow + prev.length));
  }, [setTimesButtonClicked, setSlicedList, argumentList, cardsPerRow]);

  const onEditClick = (data, index) => {
    setEditArgument(cloneDeep(data));
    setUpdateArgument(index);
    setOpen(true);
    setArgumentMode(true);
  };

  return (
    <Box ref={ref}>
      <Box
        display="flex"
        alignItems="center"
        flexGrow={1}
        mt={1}
        ml={-1}
        mb={2}
      >
        <IconButton onClick={backArrowOnClick}>
          <ArrowBackIcon />
        </IconButton>
        <Box width="100%">
          {view === 'form' ? (
            <Box>
              <Typography variant="subtitle1">
                {mode === 'edit' ? 'Edit ' : 'Create '}
                User Defined Function
              </Typography>
            </Box>
          ) : (
            <Box
              textAlign="left"
              width="100%"
              display="flex"
              alignItems="center"
            >
              <Typography variant="h4" sx={{ wordBreak: 'break-all' }}>
                {formikForm?.values?.name}
                .py
              </Typography>
              <Tooltip
                sx={(theme) => ({
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: theme.palette.secondary.main,
                  },
                })}
                title={
                  <Box display="flex" flexDirection="column">
                    <Typography>Supported libraries: </Typography>
                    <Typography variant="body2">pandas (v1.3.5)</Typography>
                    <Typography variant="body2">plotly (v4.14.3)</Typography>
                    <Typography variant="body2">psycopg2 (v2.9.3)</Typography>
                    <Typography variant="body2">requests (v2.27.1)</Typography>
                    <Typography variant="body2">SQLAlchemy (1.4.31)</Typography>
                    <Typography variant="body2">h2o (v3.32.1.5)</Typography>
                    <Typography variant="body2">rpy2</Typography>
                    <Typography variant="body2">joblib</Typography>
                    <Typography variant="body2">sklearn</Typography>
                  </Box>
                }
                arrow
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>

        {view === 'editor' ? (
          <Box display="flex" position="relative">
            <Box display="flex" alignItems="center">
              <IconButton
                size="large"
                title="Use libraries"
                onClick={() => {
                  setShowConsoleLibraryDialog(true);
                }}
              >
                <LibraryAddOutlinedIcon variant="filled" />
              </IconButton>
            </Box>
            {/* <FormControlLabel
							control={
								<Switch
									checked={editorTheme === 'vs-dark'}
									onChange={() => {
										setEditorTheme(
											editorTheme === 'vs-dark' ? 'light' : 'vs-dark'
										);
									}}
									name="editorTheme"
									color="primary"
									size="small"
								/>
							}
							label={editorTheme === 'vs-dark' ? 'Dark' : 'Light'}
							labelPlacement="end"
							style={{
								fontSize: 12,
							}}
						/> */}

            <Box display="flex" alignItems="center">
              <IconButton
                size="small"
                onClick={() => {
                  setEditorTheme(
                    editorTheme === 'myThemeDark'
                      ? 'myThemeLight'
                      : 'myThemeDark'
                  );
                }}
                title={
                  editorTheme === 'myThemeDark' ? 'Dark mode' : 'Light mode'
                }
              >
                {editorTheme === 'myThemeDark' ? <LightMode /> : <DarkMode />}
              </IconButton>
              {/* <Typography textTransform="uppercase" noWrap variant="body2">
              Open External Editor
            </Typography> */}
            </Box>
          </Box>
        ) : null}
      </Box>

      {view === 'form' ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              placeholder="Please enter valid python identifier"
              name="name"
              label="Function name *"
              disabled={mode === 'edit'}
              value={formikForm.values.name}
              {...formikForm.getFieldProps('name')}
              error={Boolean(
                formikForm?.errors?.name && formikForm?.touched?.name
                  ? formikForm.errors.name
                  : null
              )}
              helperText={
                formikForm?.errors?.name && formikForm?.touched?.name
                  ? formikForm.errors.name
                  : null
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DropDown
              placeholder="Select module"
              disabled={true}
              name="module"
              label="Module *"
              {...formikForm.getFieldProps('module')}
              helperText={
                formikForm?.errors?.module && formikForm?.touched?.module
                  ? formikForm.errors.module
                  : null
              }
            >
              {Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE).length ? (
                Object.keys(MODULE_SUB_MODULE_OPERATIONS_TREE)?.map((key) => (
                  <DropdownItem
                    key={MODULE_SUB_MODULE_OPERATIONS_TREE[key].key}
                    value={MODULE_SUB_MODULE_OPERATIONS_TREE[key].key}
                  >
                    {MODULE_SUB_MODULE_OPERATIONS_TREE[key].label}
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem disabled={true} value="">
                  No Module Selected
                </DropdownItem>
              )}
            </DropDown>
          </Grid>
          <Grid item xs={12} md>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              gap={2}
            >
              <Box display="flex" flexGrow={1} overflow="hidden">
                <DropDown
                  placeholder="Select sub module"
                  disabled={true}
                  name="subModule"
                  label="Sub module *"
                  {...formikForm.getFieldProps('subModule')}
                  helperText={
                    formikForm?.errors?.subModule &&
                    formikForm?.touched?.subModule
                      ? formikForm.errors.subModule
                      : null
                  }
                >
                  {subModulesForSelectedModule &&
                  Object.keys(subModulesForSelectedModule).length ? (
                    Object.keys(subModulesForSelectedModule)?.map((key) => (
                      <DropdownItem
                        key={subModulesForSelectedModule[key].key}
                        value={subModulesForSelectedModule[key].key}
                      >
                        {subModulesForSelectedModule[key].label}
                      </DropdownItem>
                    ))
                  ) : (
                    <DropdownItem disabled={true} value="">
                      No Module Selected
                    </DropdownItem>
                  )}
                </DropDown>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={openArgumentModel}
                sx={{ marginBottom: '4px' }}
                title="Add Argument"
              >
                Add Argument
              </Button>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <input
              type="checkbox"
              checked={formikForm.values.showInOpBar}
              onChange={() => {
                formikForm.setFieldValue(
                  'showInOpBar',
                  !formikForm.values.showInOpBar
                );
              }}
            />
            <Typography variant="body2">
              Show this user defined function in operations bar.
            </Typography>
          </Grid>

          {argumentList?.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">Arguments</Typography>
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="flex-start" gap={2}>
            {argumentList?.length > 0 &&
              slicedList?.map((data, key) => (
                <Card
                  // elevation={3}
                  // eslint-disable-next-line react/no-array-index-key
                  key={key}
                  sx={(theme) => ({
                    border: 'unset',
                    borderColor: theme.palette.secondary.main,
                    width: cardWidth,
                    height: '143px',
                    position: 'relative',
                    '& .project-card-actions': {
                      display: 'none',
                    },
                    '&:hover': {
                      boxShadow: theme.shadows[3],
                      '& .project-card-actions': {
                        display: 'block',
                      },
                    },
                  })}
                >
                  <CardContent>
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{ fontWeight: 600 }}
                      >
                        {data?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{ fontWeight: 600, color: '#636363' }}
                      >
                        {data?.type.label ? data?.type.label : data?.type}
                      </Typography>
                    </Box>
                    <Box mt={2} mb={1}>
                      <Divider />
                    </Box>
                    <Box>
                      <Typography
                        color="disabled"
                        variant="body3"
                        sx={{ fontWeight: 400, color: '#636363' }}
                        mt={1}
                      >
                        Description
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="body3"
                        sx={{
                          lineHeight: '19.07px',
                          fontWeight: 400,
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          wordWrap: 'break-word',
                        }}
                      >
                        {data.description}
                      </Typography>
                    </Box>
                    <Box
                      className="project-card-actions"
                      sx={(theme) => ({
                        position: 'absolute',
                        bottom: 10,
                        left: 13,
                        right: 13,
                        paddingTop: '10px',
                        backgroundImage: `linear-gradient(180deg, ${alpha(
                          theme.palette.other.white,
                          0
                        )} 0%, ${theme.palette.other.white}  80% )`,
                        backgroundSize: '10px',
                      })}
                      width="auto"
                    >
                      <Box
                        display="flex"
                        flexGrow={1}
                        justifyContent="flex-end"
                      >
                        <Box>
                          <IconButton
                            onClick={() => onEditClick(data, key)}
                            title="Edit argument"
                            color="primary"
                          >
                            <EditIcon height={20} width={20} />
                          </IconButton>
                          <IconButton
                            onClick={() => onDeleteClick(key)}
                            title="Delete argument"
                            color="primary"
                          >
                            <DeleteIcon height={20} width={20} />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            <Grid container justifyContent="center" alignItems="center" p={2}>
              {slicedList?.length !== argumentList.length ? (
                <Slide in={true} direction="up" mountOnEnter timeout={700}>
                  <Button variant="contained" onClick={addRowToList}>
                    Show More
                  </Button>
                </Slide>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  {mode === 'edit' ? (
                    <Box ml={0.5}>
                      <Typography variant="body4">
                        <strong>Note:</strong>&nbsp;
                      </Typography>
                      <Typography variant="body4">
                        <i>
                          If you are modifying existing arguments then make sure
                          to modify the code accordingly.
                        </i>
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={nextButtonOnClick}
                >
                  Proceed Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Card
              sx={{
                '& .lines-content.monaco-editor-background': {
                  paddingLeft: '24px !important',
                },
              }}
            >
              <Editor
                height="50vh"
                defaultLanguage="python"
                value={
                  formikForm.values?.code
                    ? formikForm.values?.code
                    : '// please start writing your code here'
                }
                theme={editorTheme}
                beforeMount={handleEditorDidMount}
                options={{
                  fontSize: 16,
                  lineHeight: 22,
                  fontFamily: 'Open Sans',
                  lineDecorationsWidth: 5,
                  padding: {
                    top: 12,
                    bottom: 12,
                    left: 12,
                    right: 12,
                  },
                  scrollbar: {
                    vertical: 'auto',
                    horizontal: 'auto',
                    verticalScrollbarSize: 5,
                    horizontalScrollbarSize: 5,
                  },
                }}
                onChange={(value) => {
                  formikForm.setFieldValue('code', value);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row-reverse"
              flexGrow={1}
              alignItems="center"
              mt={2}
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ color: 'white' }}
                  onClick={run}
                  title="Run your code"
                >
                  Run
                </Button>
              </Box>
              <Box mr={2}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={saveUdf}
                  title="Save your code"
                >
                  Save
                </Button>
              </Box>
              <Box mr={2}>
                <Button onClick={onBackClick} size="large" title="Exit editor">
                  <ExitToAppIcon />
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <ConsoleOperationsResultViewer
              fetchTableData={fetchTableData}
              taskResult={runResult?.results}
              showCommentBox={false}
              isPagination={false}
              customCell={CustomCell}
            />
          </Grid>
        </Grid>
      )}
      <Dialog
        onClose={() => {
          setShowConsoleLibraryDialog(false);
        }}
        aria-labelledby="console-add-lib-modal"
        maxWidth="sm"
        fullWidth={true}
        open={showConsoleLibraryDialog}
        // TransitionComponent={DialogTransition}
      >
        <DialogTitle>Use Libraries</DialogTitle>
        <IconButton
          onClick={() => {
            setShowConsoleLibraryDialog(false);
          }}
          size="large"
          id="prj_over_prj_item_list_cr_new_prj_modal_close_btn"
          className="mui-dialog-close-icon-btn"
          color="primary"
          aria-label="close"
          sx={DIALOG_ICON_BUTTON_CSS}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MultiSelect
                value={
                  formikForm?.values?.libraries
                    ? formikForm.values.libraries
                    : []
                }
                limitTags={3}
                label="Libraries to use in code"
                placeholder="Select libraries to use in code"
                InputLabelProps={{
                  shrink: true,
                }}
                name="libraries"
                options={libraries?.length ? libraries : []}
                disableClearable={['', undefined].includes(
                  formikForm?.values?.libraries
                )}
                popupIcon={<DropdownArrow />}
                noOptionsText="No libraries are available."
                onChange={(e) => {
                  formikForm.setFieldValue('libraries', e);
                }}
                onBlur={formikForm.handleBlur('libraries')}
                getOptionLabel={(option) =>
                  option ? `${option?.name} (${option?.version})` : ''
                }
                isOptionEqualToValue={(option, val) => option?.id === val?.id}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={option.id}
                      color="primary"
                      label={`${option?.name} (${option?.version})`}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              formikForm.setFieldValue('libraries', []);
            }}
            variant="outlined"
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              saveUdf();
              setShowConsoleLibraryDialog(false);
            }}
          >
            Link & Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConsoleCreateEditArgModal
        open={open}
        handleClose={handleClose}
        handleArgumentSubmit={handleArgumentSubmit}
        formikForm={formikForm}
        allModels={allModels}
        datasetList={datasetList}
        // onDataSetChange={onDataSetChange}
        activeProject={activeProject}
        fetchConsoleFileList={fetchConsoleFileList}
        fetchDatasetColumnDetails={fetchDatasetColumnDetails}
        editArgument={editArgument}
        mode={argumentMode}
      />
    </Box>
  );
}

ConsoleCreateEditUdf.defaultProps = {
  udf: null,
};

ConsoleCreateEditUdf.propTypes = {
  // eslint-disable-next-line react/require-default-props
  activeDataset: PropTypes.oneOfType([PropTypes.object]),
  activeDatasetColumns: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ).isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allModels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  createUdf: PropTypes.func.isRequired,
  datasetList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  fetchDatasetColumnDetails: PropTypes.func.isRequired,
  fetchUdfs: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  githubValue: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setGithubValue: PropTypes.func.isRequired,
  moduleName: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  runUdf: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  subModuleName: PropTypes.string.isRequired,
  udf: PropTypes.oneOfType([PropTypes.object]),
  updateUdf: PropTypes.func.isRequired,
  fetchTableData: PropTypes.func.isRequired,
  fetchConsoleLibraries: PropTypes.func.isRequired,
  fetchConsoleFileList: PropTypes.func.isRequired,
};
