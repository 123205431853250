/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  Typography,
  DialogActions,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogTransition from './Transitions/DialogTransition';

/**
 * @function RecalibrationModal
 * @description to show the Modal of Recalibrate or unlock datasets
 * @param {function} onClose - A function to handle modal close
 * @param {boolean} open - A boolean value
 * @param {function} onRecalibrateClick - A function on click to handle recalibration
 * @returns {JSX.Element} RecalibrationModal UI
 */
const RecalibrationModal = ({ open, onClose, onRecalibrateClick }) => (
  <Dialog
    open={open}
    aria-labelledby="customized-dialog-title"
    maxWidth="xs"
    fullWidth={true}
    TransitionComponent={DialogTransition}
    onClose={onClose}
  >
    <DialogTitle>Recalibrate/Reset dataset</DialogTitle>
    <IconButton
      id="dm_di_dts_del_close_icon"
      color="primary"
      aria-label="close"
      onClick={onClose}
      size="large"
      sx={{
        position: 'absolute',
        right: 16,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
    <DialogContent dividers>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            Are you sure you want to recalibrate this dataset?
          </Typography>
          <Typography mt={2} variant="body4" display="flex" textAlign="center">
            <strong>Note:</strong>
            <i>
              Recalibration might affect the current ongoing jobs on this
              dataset and lead to data inconsistency issues.
            </i>
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color="primary" variant="outlined" size="large" onClick={onClose}>
        No
      </Button>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={onRecalibrateClick}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

RecalibrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRecalibrateClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default RecalibrationModal;
