// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { connect } from 'react-redux';
import utils from '../utils/utils';

/**
 * @function AccessControl
 * @param {Array} allowedPermissions - list of permissions that are allowed
 * @param {Array} userPermissions - list of permissions that the user has
 * @param {Function} renderNoAccess - function to render if the user does not have access
 * @param {Function} accessCheck - function to check if the user has access
 * @param {Object} extraAccessData - extra data to pass to the accessCheck function
 * @param {Object} user - the user object
 * @returns {React.Component} - the component
 */
const AccessControl = ({
  user,
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  accessCheck,
  extraAccessData,
}) => {
  let permitted;
  // when an accessCheck function is provided, ensure that passes as well as the permissions
  // this is for additional checks if we need.
  if (accessCheck) {
    permitted =
      accessCheck(extraAccessData, user) &&
      utils.checkPermissions(userPermissions, allowedPermissions);
  } else {
    // otherwise only check permissions
    permitted = utils.checkPermissions(userPermissions, allowedPermissions);
  }

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
};

export default connect((state) => ({
  userPermissions:
    state?.user &&
    Object.keys(state?.user?.permissions).map(
      (permKey) => `${permKey}:${state?.user?.permissions?.[permKey]}`
    ),
  user: state?.user,
}))(AccessControl);
