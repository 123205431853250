const API_ENDPOINTS = {
  HOSTS_NEEDS_TOKEN: [process.env.REACT_APP_HOSTS_NEEDS_TOKEN],
  MODULE_BASE_URL: {
    DI: process.env.REACT_APP_DI_BASE_URL,
    DM: process.env.REACT_APP_DM_BASE_URL,
    GR: process.env.REACT_APP_GR_BASE_URL,
    EDA: process.env.REACT_APP_EDA_BASE_URL,
    FTS: process.env.REACT_APP_FTS_BASE_URL,
    SAMPLING: process.env.REACT_APP_SAMPLING_BASE_URL,
    SEG: process.env.REACT_APP_SEG_BASE_URL,
    VR: process.env.REACT_APP_VR_BASE_URL,
    ME: process.env.REACT_APP_ME_BASE_URL,
    MT: process.env.REACT_APP_MT_BASE_URL,
    MI: process.env.REACT_APP_MI_BASE_URL,
    MD: process.env.REACT_APP_MD_BASE_URL,
    DOC: process.env.REACT_APP_DOC_BASE_URL,
    CONSOLE_HUB: process.env.REACT_APP_CONSOLE_HUB_BASE_URL,
    CONSOLE: process.env.REACT_APP_CONSOLE_BASE_URL,
    BASE_URL_TS: process.env.REACT_APP_TS_BASE_URL,
  },
  // API_MODELESTIMATION_URL: 'http://65.1.8.219:8011',
  // http://13.126.61.50:8012/
  // http://65.0.130.29:3000/model-estimation
  // API_DOC_URL: 'http://65.0.186.12:8012',
  API_DOC_URL: '',
  API_MODEL_INT_URL: '',
  API_MODELESTIMATION_URL: '',

  // API_MODELESTIMATION_URL: 'http://13.233.166.251:8011',

  // http://65.0.186.12:8012
  FETCH_USER_DETAILS: '/user',

  // React MUI table pagination api

  //* *** Duplicate URL '/datasets/quality/valueerror/illegal' line 95,96 ******

  //* *** Duplicate URL '/datasets/quality/valueerror/misspell' line 100,101 ******

  //* *** Duplicate URL '/datasets/quality/valueerror/misfield' line 105,106 ******

  DQ_OP_STANDARDISATION_SYNONYMS: '/datasets/quality/standarderror/synonym',

  //* *** Duplicate URL '/datasets/quality/embeddedvalues' line 121,122 ******

  // DATA Quality Console
  GET_UDFS: '/console',

  // DATA Preparation

  // Saving graphs,tables and their comments COMMON FOR ENTIRE DATA MANAGEMENT
  // (Chart+hraph+table)Comment
  GRAPH_OR_TABLE_WITH_COMMENT: '/datasets/comment',

  // Chart Comment

  SAVE_CHART_COMMENT: '/save/chart',

  // Table Comment
  SAVE_TABLE_COMMENT: '/save/table',

  // ? ---------------------------- SAMPLING : ST--------------------------------------------------

  // ? ---------------------------------- SAMPLING : END   -------------------------------------

  // ? --------------------------FEATURE ENGINEERING--------------------------------------//

  // FEATURE TRANSFORMATIONS

  // Automated Documentation
  // CREATE_DOC: ':9252/automated_documentation/newdoc',
  // DOC_MAIN_SECTION: ':9252/automated_documentation/section',
  // DOC_SUB_SECTION: ':9252/automated_documentation/subsection',
  // DOC_DEEP_SUB_SECTION: ':9252/automated_documentation/subsubsection',
  // DOC_PARA: ':9252/automated_documentation/para',
  // DOC_TABLE: ':9252/automated_documentation/table',
  // DOC_IMAGE: ':9252/automated_documentation/image',

  // ? -------------------------SEGMENTATION-----------------------

  // ? ------------------------------------------------------------

  // ? --------------VARIABLE REDUCTION SECTION-------------------?//

  /* THIS end point will be hit by all the operations for submmiting
  the list of columns that user wants to flag for deletion */

  /* VARIABLE FINALISATION */
  /* this end point will fetch  columns to display  in VARIABLE FINALISATION */

  /* this end point will drop selected columns in VARIABLE FINALISATION */

  /* for saving graph comment */

  /* for saving table comment */

  // ? -----------------------PERFORMANCE TESTING-------------------------------------------------//
  // ? -------------------------SCORING/SCALING-----------------------------------//

  // ? --------------------MODEL RECALIBIRATION-------------------------------------------------//

  // ? -------------------------------Sensitivity Analysis-------------------------//

  // ? -------------------------BACK TESTING-------------------------------------------------//

  // ? -----------------------MODEL INTERPRETATION--------------

  // ? ----------------FEATURE INTERPRETATION-------------------------------//

  // ? -------------------------------------CLASSIFICATION STATS----------------------------------//

  // ? ---------------------------------------INDIVIDUAL PREDICTION-------------------------------//
  // ? -----------------------------------DECISION TREE---------------------------------------//

  // ? -------------------------------SEND b64----------------------------------------------------//

  PROJECT_MANAGEMENT: {
    FETCH_PROJECT_WORKFLOW_DETAILS: '/project-management/workflow',
    CREATE_PROJECT: '/project-management/create-project',
    CLONE_PROJECT: '/project-management/clone-project',
    // MODEL_INDUSTRY_LIST: '/project-management/model-industry', Not used
    USER_PROJECTS: '/project-management/project-list',
    // GRAPHS_DATA: '/project-management/graphs', Not used
    // GRAPH_MODEL_VS_COUNT: '/project-management/graphs/model-vs-count', Not used
    // GRAPH_PROJECT_GANTT_CHART: '/project-management/graphs/gantt-chart', Not used
    USER_PROJECTS_STATUS: '/project-management/project-overall-status',
    DELETE_PROJECT: '/project-management/delete-project',
    UPDATE_PROJECT: '/project-management/update-project',
    RESTORE_PROJECT: '/project-management/restore-project',
    ARCHIVE_PROJECT: '/project-management/archive-project',
    SHARE_PROJECT: '/project-management/share/project',
    ACTION_PROJECT: '/project-management/share/action',

    GRAPH_COLOR_CHANGE: '/project-management/graph-settings',
    BIG_DATA: '/project-management/bigdata-setting',

    PROJECT_ACCESS_TABLE: '/project-management/team-view',

    // below endpoint featch uread task from selected project ..
    USER_PROJECT_DETAILS: '/project-management/tasks-count',
    // below endpoint stats of selected project...
    FETCH_INDIVIDUAL_PROJECT_STATS: '/project-management/project-status',
    // below endpoint gets table for team view... and passing projectid will give individual access
    // FETCH_TEAM_VIEW_OF_PROJECT: '/projectmanagement/teamview/',
    // TO view Full TEAM for a particular USER just send GET REQuest to below API
    COMPLETE_TEAM_VIEW_OF_PROJECT: '/project-management/complete-team-view',
    // Below API will show you users according to their roles
    USER_ROLES: '/project-management/user-roles',
    FETCH_PROJECT_TIME_SPENT_STATS: '/project-management/project-time-stats',
    TRACKING_PROJECT_TIME_SPENT: '/project-management/project-time-spent',
    DATASET_COUNT_REMAP: '/project-management/dataset-count-remap',
    PROJECT_TYPE: '/project-management/project-type',
  },
  // GRID
  GRID: {
    EDIT_CELL: '/datasets/grid/update',
    RENAME_COLUMN: '/datasets/grid/rename-column',
    DELETE_COLUMN: '/datasets/grid/delete-column',
    DUPLICATE_COLUMN: '/datasets/grid/duplicate-column',
    ADD_COLUMN: '/datasets/grid/add-column',
    DELETE_ROW: '/datasets/grid/delete-row',
    DUPLICATE_ROW: '/datasets/grid/duplicate-row',
    ADD_ROW: '/datasets/grid/add-rows',
    FETCH_TASKS: '/datasets/grid/tasks',
    MANAGE_SPACES: '/datasets/grid/manage-spaces',
    MANAGE_CASES: '/datasets/grid/manage-cases',
    SPLIT_COLUMN: '/datasets/grid/split-column',
    FIND_AND_REPLACE: '/datasets/grid/find-and-replace',
    DOWNLOAD_CSV: '/datasets/grid/export-csv',
    SORT_COLUMN: '/datasets/grid/sort-column',
    ANALYSE: '/datasets/grid/analysis',
    EMBEDDED_VALUES: '/datasets/grid/embedded-values',
    STANDARDISATION_ERROR_CURRENCY: '/datasets/grid/standard-error/currency',
    STANDARDISATION_ERROR_DATE: '/datasets/grid/standard-error/date',
    STANDARDISATION_ERROR_EMAIL: '/datasets/grid/standarderror/email',
    STANDARDISATION_ERROR_PHONE: '/datasets/grid/standarderror/phone',
    STANDARDISATION_ERROR_UNIT: '/datasets/grid/standard-error/unit',
    INTEGRITY_RULES_INCORRECT_VALUES: '/datasets/grid/integrity-rule/incorrect',
    INTEGRITY_RULES_RERERENTIAL: '/datasets/grid/integrity-rule/referential',
    UNIQUENESS: '/datasets/grid/unique',
    SCORES_RSSPDS: '/datasets/grid/score/rss-pds',
    SCORES_DFCS: '/datasets/grid/score/dfcs',
    FIND_DUPLICATE_COLUMN: '/datasets/grid/get-duplicate-column',
    FIND_DUPLICATE_ROW: '/datasets/grid/get-duplicate-row',
    JOB_QUEUE_MARK_READ_UNREAD: '/datasets/grid/readstatus',
    VALUE_ERROR: '/datasets/grid/value-error',
    FIND_BLANK_VALUE_PERCENTAGE: '/datasets/grid/get-column-missing-values',
    REPLACE_BLANK_VALUES: '/datasets/grid/replace-column-missing-values',
    FIND_REGEX_PERCENTAGE: '/datasets/grid/regex-percentage',
    DOWNLOAD_GRID_CSV: '/datasets/grid/download-csv',
    DOWNLOAD_GRID_LIST: '/datasets/grid/download-csv-list',
    GRID_TABLE: '/datasets/grid/result-table',
    FIND_AND_DELETE_DUPLICATE_COLUMNS: '/datasets/grid/drop-duplicate-columns',
    FIND_AND_DELETE_DUPLICATE_ROWS: '/datasets/grid/drop-duplicate-rows',
    RERUN_OPERATION: '/datasets/grid/rerun',
    FETCH_MODULE_TASKS_LIST: '/datasets/grid/tasks',
  },
  PUSH_NOTIFICATION: {
    SUBSCRIBE: '/spark/webpush',
  },
  AUTH: {
    LOGIN_USER: '/auth/login',
    GET_USER_DETAILS: '/auth/get-user-details',
    FORGET_PASSWORD: '/auth/request-reset-email',
    RESET_PASSWORD: '/auth/password-reset-complete',
    USER_PROFILE: '/auth/users-profile',
    CREATE_USER: '/auth/register',
    BLOCK_USER: '/auth/suspend-user',
    GROUP_PROFILE: '/auth/read-group',
    CREATE_GROUP: '/auth/create-group',
    UPDATE_GROUP: '/auth/update-group',
    DELETE_GROUP: '/auth/delete-group',
    USER_PERMISSION: '/auth/get-user-permission',
    REFRESH_TOKEN: '/auth/token/refresh',
    UPDATE_USER: '/profile/update',
    CHANGE_PASSWORD: '/auth/reset-password',
    ACCESS_LOGS: '/auth/access-logs',
    USER_STATS: '/auth/prepare-user-stats',
    DOWNLOAD_CSV: '/auth/download-access-logs',
    AUDIT_LOGS: '/auth/auditability',
    DOWNLOAD_CSV_AUDIT_LOGS: '/auth/download-auditability',
    DOWNLOAD_USER_CSV_LIST: '/auth/download-user-csv-list',
  },
  DI: {
    DATAMANAGE_DATASETS: '/datasets/list',

    UPLOAD_S3: '/spark/startupload',
    UPLOAD_CHUNK: '/spark/uploadchunk',
    UPLOAD_COMPLETED: '/spark/uploadcomplete',
    IMPORT_FLAT_FILES: '/spark/uploads3',
    DI_READY_TO_USE_STATUS: '/datasets/ready-to-use',
    UPDATE_DATATYPE_TARGET_VARIABLE: '/datasets/datatype',
    IMPORT_S3: '/datasets/import/s3-bucket',

    IMPORT_POSTGRES: '/datasets/import/psql',
    IMPORT_SQL: '/datasets/import/mysql-new',
    RENAME_DATASET_DISPLAY_NAME: '/datasets/rename',

    RESET_DATASET: '/datasets/recalibrate',
    DATASET_COLUMN_DETAILS: '/datasets/column/type',
  },
  DM: {
    GET_DM_TASKS: '/datasets/tasks',
    DATASETS: '/datasets/view',
    DELETE_DATASET: '/datasets/delete-dataset',
    ASSIGN_DATASET: '/datasets/save-dataset',

    RERUN: '/datasets/adjustment/rerun',
    DP_OP_APPEND: '/datasets/adjustment/append',
    DP_OP_DELETE_ROW: '/datasets/adjustment/delete/row',
    DP_OP_DELETE_COLUMN: '/datasets/adjustment/delete/column',
    DP_OP_RENAME_COLUMN: '/datasets/adjustment/renamecol',
    DP_OP_SORT_COL: '/datasets/adjustment/sort',
    DP_OP_FIND_AND_REPLACE: '/datasets/adjustment/findreplace',
    DP_OP_DUPLICATE_ROW: '/datasets/adjustment/duplicate/row',
    DP_OP_DUPLICATE_COLUMN: '/datasets/adjustment/duplicate/column',
    DP_OP_EDIT_CELL: '/datasets/adjustment/editcell',
    DP_OP_TRANSPOSE: '/datasets/adjustment/transpose',
    DP_OP_SPLIT_COL: '/datasets/adjustment/splitcol',
    DP_OP_ADD_ROW: '/datasets/adjustment/add/row',
    DP_OP_ADD_COLUMN: '/datasets/adjustment/add/column',
    DP_OP_COMBINE: '/datasets/adjustment/join',
    DM_JOB_QUEUE_MARK_READ_UNREAD: '/datasets/readstatus',
    DQ_ANALYZE: '/datasets/quality/consistencymet/analysis',
    DQ_OP_MANAGE_CASE_CAPITALIZE: '/datasets/quality/managecase/capitalize',
    DQ_OP_MANAGE_CASE_LOWER: '/datasets/quality/managecase/lower',
    DQ_OP_MANAGE_CASE_UPPER: '/datasets/quality/managecase/upper',
    DQ_OP_MANAGE_SPACE_BEGIN: '/datasets/quality/managespaces/begin',
    DQ_OP_MANAGE_SPACE_END: '/datasets/quality/managespaces/end',
    DQ_OP_MANAGE_SPACE_MIDDLE: '/datasets/quality/managespaces/middle',
    DQ_EMBEDDED_VALUE_POST: '/datasets/quality/embeddedvalues',
    DQ_EMBEDDED_VALUE_PUT: '/datasets/quality/embeddedvalues',
    DQ_OP_VALUE_ERROR_ILLEGAL_LIMITS: '/datasets/quality/valueerror/illegal',
    DQ_OP_VALUE_ERROR_ILLEGAL_UPDATE: '/datasets/quality/valueerror/illegal',
    DQ_OP_VALUE_ERROR_MISSPELL_NORMAL: '/datasets/quality/valueerror/misspell',
    DQ_OP_VALUE_ERROR_MISSPELL_UPDATE: '/datasets/quality/valueerror/misspell',
    DQ_OP_VALUE_ERROR_MISFIELD_DATATYPE:
      '/datasets/quality/valueerror/misfield',
    DQ_OP_VALUE_ERROR_MISFIELD_UPDATE: '/datasets/quality/valueerror/misfield',
    DQ_OP_STANDARDISATION_CRYPTIC: '/datasets/quality/standarderror/cryptic',
    DQ_OP_STANDARDISATION_DATE: '/datasets/quality/standarderror/date',
    DQ_OP_STANDARDISATION_EMAIL: '/datasets/quality/standarderror/email',
    DQ_OP_STANDARDISATION_PHONE: '/datasets/quality/standarderror/phone',
    DQ_OP_STANDARDISATION_CURRENCY: '/datasets/quality/standarderror/currency',
    DQ_OP_STANDARDISATION_UNIT: '/datasets/quality/standarderror/unit',
    DQ_INTEGRITY_REFERENTIAL: '/datasets/quality/integrityrule/referential',
    DQ_INTEGRITY_INCORRECT: '/datasets/quality/integrityrule/incorrect',
    DV_OP_REGEXP: '/datasets/validate/regexp',
    DV_OP_BLANK: '/datasets/validate/blank',
    DV_OP_SCORE_DFCS: '/datasets/validate/score/dfcs',
    DV_OP_SCORE_RSSPDS: '/datasets/validate/score/rsspds',
    DV_OP_UNIQUE: '/datasets/validate/unique',
    DV_OP_DUPLICATE_ROW: '/datasets/validate/duplicate/row',
    DV_OP_DUPLICATE_COL: '/datasets/validate/duplicate/column',
    DV_OP_DELETE_DUPLICATE_ROW: '/datasets/validate/duplicate/row',
    DATASET_COLUMN_DETAILS: '/datasets/column/type',
    GET_TARGET_LIST: '/datasets/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/datasets/tasks',
  },

  EDA: {
    RERUN: '/eda/rerun',
    GET_EDA_TASKS: '/eda/tasks',
    EDA_GET_OUTLIER_DETECTION_METHODS: '/eda/detection/getMethodName',
    EDA_GET_OUTLIER_DETECTION_VARIABLES: '/eda/column/type',
    EDA_GET_OUTLIER_DETECTION_RESULTS: '/eda/detection/run/',
    EDA_GET_OUTLIER_TREATMENT_METHODS: '/eda/treatment/getMethodName',
    EDA_GET_OUTLIER_TREATMENT_RESULTS: '/eda/treatment/run/',
    EDA_SAVE_RESULTS: '/eda/treatment/save',
    EDA_STATISTICAL_NORMALITY: '/eda/normality-test',
    EDA_CHI_SQUARE_COLOUMN: '/eda/columns/chi/',
    EDA_STATISTICAL_NON_PATAMETRIC: '/eda/non-parametric-test',
    EDA_STATISTICAL_PATAMETRIC: '/eda/parametric-test',
    EDA_STATISTICAL_HOMOGENEITY: '/eda/homogeneity-of-variances',
    EDA_GET_COLUMN_DETAILS: '/eda/column/type',
    EDA_GET_COLUMN_DETAILS_PRELIMINARY: '/eda/column/type2',
    PS_SORT_COLUMNS: '/eda/sort/columns',
    PS_DELETE_COLUMNS: '/eda/drop/columns',
    PS_DELETE_ROWS: '/eda/drop/rows',
    PS_DELETE_BY_THRESHOLD: '/eda/drop/columns/threshold',
    PS_SUMMARY_STATS: '/eda/summary-stats',
    PS_OVERALL_STATS: '/eda/summary-stats/overall',
    EDA_MVI_GET_COLUMN_DETAILS: '/eda/mvi/getColumns',
    EDA_MVI_SUBMIT: '/eda/mvi/submit',
    EDA_MVI_SAVE: '/eda/mvi/save',
    EDA_MVI_MISSING_COLUMNS_CHECK: '/eda/mvi/barplot',
    EDA_MVI_GET_COLUMNS: '/eda/mvi/get-columns',
    EDA_VA_ACTION: '/eda/variable/analysis',

    EDA_STATIONARY_TEST: '/eda/st',
    EDA_COINTEGRATION_TEST: '/eda/jct',
    EDA_GRANULARITY_TEST: '/eda/granular',
    EDA_SEASONALITY_TEST: '/eda/seast',
    EDA_ACF_PACF_PLOTS: '/eda/acf-pacf',
    EDA_JOB_QUEUE_MARK_READ_UNREAD: '/eda/readstatus',
    EDA_SAVE_TREATMENT_RESULTS: '/eda/treatment/save',
    EDA_DOWNLOAD_TABLE: '/eda/downloadtable',
    EDA_TABLE_DATA: '/eda/pagetable',
    GET_TARGET_LIST: '/eda/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/eda/tasks',
    DATASET_COLUMN_DETAILS: '/eda/column/type',
  },
  FTS: {
    RERUN: '/fts/rerun',
    GET_FE_TASKS: '/fts/tasks',
    FTS_NUMERIC: '/fts/feature-transformation/numerical',
    FTS_CATEGORICAL: '/fts/feature-transformation/categorical',
    FTS_TIMESTAMP: '/fts/feature-transformation/timestamp',
    FTS_BINNING_COLUMNS: '/fts/feature-transformation/response-cols',
    FE_JOB_QUEUE_MARK_READ_UNREAD: '/fts/readstatus',
    INV_USER_COLLECTION: '/sharing/user',
    INV_SHARING: '/sharing/share',
    INV_RECIVED: '/sharing/show',
    INV_ACTION: '/sharing/action',
    DATASET_COLUMN_DETAILS: '/fts/column/type',
    GET_TARGET_LIST: '/fts/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/fts/tasks',
    FETCH_UNIQUE_VALUES: '/fts/unique-values',
    UDF_SHARING: '/sharing/share',
  },
  SAMPLING: {
    GET_SAMPLING_TASKS: '/sampling/tasks',
    RERUN: '/sampling/rerun',
    SAMPLING_VISUALIZE_IMBALANCE: '/sampling/visualise-imbalance',

    SAMPLING_RESAMPLING: '/sampling/resample',

    SAMPLING_SPLITTING: '/sampling/splitting',

    SAMPLING_SAMPLING: '/sampling/sampling',

    SAMPLING_CHART_COMMENT: '/sampling/save/chart/comment',

    SAMPLING_TABLE_COMMENT: '/sampling/save/table/comment',

    SAMPLING_TASKBAR_STATS: '/sampling/get/taskstatus',
    SAMPLING_TSA: '/sampling/sampling-timeseries',
    SAMPLING_JOB_QUEUE_MARK_READ_UNREAD: '/sampling/read-status',
    DATASET_COLUMN_DETAILS: '/sampling/column/type',
    GET_TARGET_LIST: '/sampling/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/sampling/tasks',
  },
  SEG: {
    GET_SEG_TASKS: '/sg/tasks',
    RERUN: '/sg/rerun',
    SEG_CLUSTER: '/sg/clustering',

    SEG_DT: '/sg/decision-tree',

    SEG_RB: '/sg/rule-based',

    SEG_TASKBAR_STATS: '/sg/get/taskstatus',
    SEG_JOB_QUEUE_MARK_READ_UNREAD: '/sg/read-status',
    DATASET_COLUMN_DETAILS: '/sg/column/type',
    GET_TARGET_LIST: '/sg/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/sg/tasks',
  },
  VR: {
    RERUN: '/vr/rerun',
    GET_VR_TASKS: '/vr/tasks',
    VR_OP_MISSING_VALUES: '/vr/missing',
    VR_OP_TS_MISSING_VALUES: '/vr/missing',
    VR_OP_LOW_VARIANCE: '/vr/variance',
    VR_OP_HIGH_CORRELATION: '/vr/correlation',
    VR_OP_TS_HIGH_CORRELATION: '/vr/correlation',
    VR_OP_RANDOM_FOREST: '/vr/random',
    VR_OP_FORWARD_FEATURE_SELECTION: '/vr/elimination/forward',
    VR_OP_TS_FORWARD_FEATURE_SELECTION: '/vr/elimination/forward',
    VR_OP_BACKWARD_FEATURE_SELECTION: '/vr/elimination/backward',
    VR_OP_TS_BACKWARD_FEATURE_SELECTION: '/vr/elimination/backward',
    VR_OP_STEP_WISE_SELECTION: '/vr/elimination/stepwise',
    VR_OP_TS_STEP_WISE_SELECTION: '/vr/elimination/stepwise',
    VR_OP_CLUSTERING: '/vr/clustering',
    VR_OP_IV_VALUES: '/vr/iv',
    VR_OP_PRINCIPAL_COMPONENT_ANALYSIS: '/vr/pca',
    VR_OP_FINAL_SUBMIT: '/vr/final-submit',
    // VR_OP_FETCH_VARIABLE_FINALISATION: '/vr/final-drop', //for get request
    VR_OP_VARIABLE_FINALISATION: '/vr/final-drop',
    VR_OP_CHART_COMMENT: '/vr/save/chart/comment',
    VR_OP_TABLE_COMMENT: '/vr/save/table/comment',
    VR_TS_STEPWISE: '/vr/elimination/stepwise',
    VR_MISSING_VALUE_CHECK: '/vr/check-missing',
    VR_CLUSTRING_CHECK: '/vr/check-clustering',
    VR_TS_LASSO: '/vr/elimination/lasso',
    VR_TS_RIDGE: '/vr/elimination/ridge',
    VR_JOB_QUEUE_MARK_READ_UNREAD: '/vr/readstatus',
    VR_TARGET_VARIABLE: '/vr/column/type',
    DATASET_COLUMN_DETAILS: '/vr/column/type',
    PS_OVERALL_STATS: '/eda/summary-stats/overall',
    PS_SUMMARY_STATS: '/eda/summary-stats',
    VR_TS_RERUN: '/vr/rerun',
    GET_TARGET_LIST: '/vr/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/vr/tasks',
  },
  ME: {
    GET_ME_TASKS: '/me/tasks',
    ME_LE_GET_TABLE_DATA: '/me/leaderboard/execute',
    // ME_LE_GET_TABLE_DATA_ASYNC: '/me/task/', Not used
    ME_GET_CLASS_DIST: '/me/leaderboard/class',
    ME_HYPER_CD_SUBMIT: '/me/hypertunning/execute',
    ME_GET_HYPER_MODAL_NAME: '/me/model/storage',
    ME_GET_SAVED_MODEL_LIST: '/me/allmodels',
    ME_DEPLOY_MODEL: '/me/model/prediction', // new_tabel , target_variable
    ME_GET_PARAMETERS: '/me/model/parameter',
    ME_UPLOAD_PKL: '/me/model/upload',
    ME_TASKBAR_STATS: '/me/get/taskstatus',
    // ME_KILL_TASK: '/me/kill/', not used
    MT_MCA_ALL_MODELS_DETAILS: '/me/allmodels',
    MI_RUN: '/me/explained',
    ME_LE_GET_TABLE_DATA_TS: '/me/TS_LeaderBoard',
    ME_HT_TIME_SERIES_SUBMIT: '/me/hypertunning/execute',
    RENAME_MODEL_DISPLAY_NAME: '/me/model/update',
    DELETE_MODEL: '/me/model/delete',
    ME_JOB_QUEUE_MARK_READ_UNREAD: '/me/readstatus',
    ME_UPLOAD_PKL_TS: '/me/model/upload',
    ME_SC_MODEL_INITIATION_SUBMIT: '/me/initiation/execute',
    ME_TS_MODEL_INITIATION_SUBMIT: '/me/model/initiation',
    ME_UPLOAD_SAS: '/me/model/uploadsas',
    ME_GET_BIG_DATA_STATUS: '/me/is-bigdata',
    DATASET_COLUMN_DETAILS: '/me/column/type',
    ME_DEPLOY_MODEL_WITH_CONFIGS: '/modeldeployment/modeldeployurl',
    ME_RERUN: '/me/rerun',
    ME_TS_RERUN: '/me/rerun',
    GET_TARGET_LIST: '/me/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/me/tasks',
    MM_GET_MODEL_SUMMARY_STATS: '/me/model-manager/model-stats',
    MM_GET_RELATEDS_JOBS: '/me/model-manager/related-jobs',
    MM_GET_RELATEDS_PIPELINES: '/me/model-manager/related-pipelines',
    MM_GET_MODEL_DETAILS: '/me/allmodels/details',
    MM_UPDATE_MODEL: '/me/model/update',
    MM_GET_DEPLOYMENT_DETAILS: '/me/model-manager/view-deployment',
    MM_START_DEPLOYMENT: '/me/model-manager/model-deploy',
    MM_STOP_DEPLOYMENT: '/me/model-manager/stop-deployment',
    MM_GET_MODEL_FILES: '/me/model-manager/model-files',
    MM_GET_DEPLOYMENT_USAGE: '/me/model-manager/deployment-usage',
    ADD_TO_MMD: '/me/vault/model_dashboard/add',
    FETCH_MMD_SCHEMA: '/me/vault/model_dashboard/model_schema',
    FETCH_MMD_DATA: '/me/vault/model_dashboard',
  },
  MMD: {
    MONITORING_DASHBOARD: '/mp/monitoring-dashboard',
  },
  MT: {
    GET_TARGET_LIST: '/mp/valid-targets',
    MT_TS_PT_GETTING_RULES: '/mt/get/metric-rules/',
    MT_TS_PT_METRIC_SELECTION: '/mt/metric-selection',
    MT_TS_PT_SAVING_RULES: '/mt/save/monitoring-rules',
    MT_PT_FETCH_EXISISTING_RULES: '/mp/get/metric-rules/',
    MT_PT_SET_MONITORING_RULES: '/mp/save/monitoring-rules',
    MT_PT_METRIC_SELECTION: '/mp/metric-selection',
    MT_PT_ASSUMPTION_TEST: '/mp/assumption-testing',
    MT_PT_MODEL_COMPARISION: '/mp/model-comparision',
    MT_PT_ONGOING_COMPARISION: '/mp/ongoing-comparision',
    MT_CS_AUC_LIFT_CURVES_SUBMIT: '/mp/auc',
    MT_CS_CLASSIFICATION_PLOTS_SUBMIT: '/mp/plots',
    MT_PT_TASK_STATUS: '/mp/get/taskstatus',
    MT_SS_SCALING: '/mp/scaling',
    MT_SS_SCORING: '/mp/scoring',
    MT_MCA_SUBMIT_TASK: '/mp/callibrate?model_id=',
    MT_MCA_ASYNC_DATA: '/mp/task/',
    MT_SA_SENSITIVITY_ANALYSIS: '/mp/sensitivity',
    MT_FS_FAIRNESS_SUBMIT: '/mp/fairness',
    MT_FS_BIASNESS_SUBMIT: '/mp/biasness',
    MT_DATA_DRIFT_SUBMIT: '/mp/data-drift',
    MT_EM_ESCALATION_MATRIX: '/mp/escalation-matrix',
    MT_SA_ALL_VARIABLE_DETAILS: '/mp/columns',
    MT_BT_RANK_ORDER: '/mp/rankorder',
    MT_BT_K_FOLD: '/mp/kfold',
    MT_TS_PT_ASSUMPTION_TEST: '/mt/assumption-testing',
    MT_TS_FORECAST: '/mt/forecast',
    MT_TS_SA_SENSITIVITY_ANALYSIS: '/mt/sensitivity-time',
    MT_TS_PT_ONGOING_COMPARISON: '/mt/ongoing-comparision',
    MT_TS_PT_MODEL_COMPARISON: '/mt/model-comparision',
    MT_TS_BACKTESTING_OP: '/mt/backtest',
    GET_SS_TASKS: '/mp/tasks',
    MT_SS_ALL_MODELS_DETAILS: '/me/allmodels',
    MT_JOB_QUEUE_MARK_READ_UNREAD: '/mp/readstatus',
    DATASET_COLUMN_DETAILS: '/mp/column/type',
    MT_TABLE_DATA: '/mp/result-table',
    MT_TS_TABLE_DATA: '/mt/result-table',
    MT_RERUN: '/mp/rerun',
    MT_TS_RERUN: '/mt/rerun',
    MT_ESCALATION_MATRIX: '/mp/escalation-matrix',
    FETCH_MODULE_TASKS_LIST: '/mp/tasks',
    ADD_TABLE_AND_CHART: '/mp/monitoring-dashboard/add',
    MT_ALL_MODELS_DETAILS: '/me/allmodels/details',
  },
  MI: {
    RERUN: '/mi/rerun',
    GET_MI_TASKS: '/mi/tasks',
    MI_TASKBAR_STATS: '/mi/get/taskstatus',
    MI_FI_FEATURE_IMPORTANCE_SUBMIT: '/mi/feature-importance',
    MI_FI_RELATIVE_CONTRIBUTION_SUBMIT: '/mi/relative-contribution',
    MI_FI_FEATURE_DEPENDENCE_SUBMIT: '/mi/feature-dependence',
    MI_CS_PERFORMANCE_MATRICES_SUBMIT: '/mi/metrics',
    MI_CS_AUC_LIFT_CURVES_SUBMIT: '/mi/auc',
    MI_CS_CLASSIFICATION_PLOTS_SUBMIT: '/mi/plots',
    MI_IP_PREDICTION_SUBMIT: '/mi/prediction',
    MI_IP_CONTRIBUTION_SUBMIT: '/mi/contribution',
    MI_IP_PARTIAL_DEPENDENCE_SUBMIT: '/mi/partial-dependence',
    MI_DT_DECISION_TREE_SUBMIT: '/mi/decision-tree',
    MI_TS_VIF_TABLE: '/mi/vif-table',
    TS_RERUN: '/mi/rerun',
    MI_TS_COFF_TABLE: '/mi/coefficient-plot',
    MI_TS_MODEL_SUMMARY: '/mi/model-summary',
    MT_SS_ALL_MODELS_DETAILS: '/me/allmodels',
    MI_JOB_QUEUE_MARK_READ_UNREAD: '/mi/read-status',
    DATASET_COLUMN_DETAILS: '/mi/column/type',
    GET_TARGET_LIST: '/mi/valid-targets',
    FETCH_MODULE_TASKS_LIST: '/mi/tasks',
    ADD_TABLE_AND_CHART: '/mp/monitoring-dashboard/add',
  },
  MD: {
    GET_MD_TASKS: '/modeldeployment/tasks',
    MD_DATA_DRIFT: '/modeldeployment/detect_drift_md',
    MD_BEST_MODEL_SELECTION: '/modeldeployment/best-model-selection',
    FETCH_MODULE_TASKS_LIST: '/modeldeployment/tasks',
    MD_RERUN: '/modeldeployment/rerun',
  },
  DOC: {
    DOC_LIST_HOME: '/automated-documentation/document/getlist',
    DOC_LIST: '/automated-documentation/documents',
    DOC_LIST_DETAILS: '/automated-documentation/documents/details',
    DOC_LIST_UPDATE: '/automated-documentation/documents/update',
    DOC_LIST_EDIT: '/automated-documentation/documents/edit',
    DOC_LIST_CREATE: '/automated-documentation/onedrive/link/create',
    DOC_LIST_DELETE: '/automated-documentation/documents/delete',
    DOC_CREATE: '/automated-documentation/createdoc',
    DOC_SAVE_AS: '/automated-documentation/saveas',
    DOC_IMAGE_AND_TABLE: '/automated-documentation/imagelist',
    DOC_DM_DATA: '/automated-documentation/listdm',
    DOC_MODULE_OPTION_TYPE: '/automated-documentation/mlist',
    DOC_SAVE_TEMPLATE: '/automated-documentation/savetemplate',
    DOC_ALL_TEMPLATE: '/automated-documentation/overall',
    DOC_ALL_TEMPLATE_LITE: '/automated-documentation/overview-all/',
    DOC_ALL_OVERVIEW: '/automated-documentation/overall',
    DOC_COPY: '/automated-documentation/copytemplate',
    DOC_SAVE_WORDDOC: '/automated-documentation/onedrive/save',
    DOC_CREATE_NODE: '/automated-documentation/node/create',
    DOC_UPDATE_NODE: '/automated-documentation/node/update',
    DOC_DELETE_NODE: '/automated-documentation/node/delete',
    DOC_NODE_DETAILS: '/automated-documentation/node/detail',
    DOC_REARRANGE_NODES: '/automated-documentation/node/rearrange',
    DOC_DOCUMENTATION_TEMPLATE: '/automated-documentation/template',
    DOC_STYLING_LIST: '/automated-documentation/get/styles',
    DOC_HELP_SECTION: '/automated-documentation/admin-help',
    SAVE_FILE_STRUCTURE: '/automated-documentation/admin-help/restructure',
  },
  PIPELINE: {
    PIPELINE_LIST: '/automated-pipeline/all-pipeline',
    PIPELINE_HISTORY: '/automated-pipeline/pipeline-history',
    CREATE_PIPELINE: '/automated-pipeline/create-pipeline',
    TRIGGER_PIPELINE: '/automated-pipeline/trigger-pipeline',
    CREATE_PIPELINE_NODE: '/automated-pipeline/node/create',
    UPDATE_PIPELINE_NODE: '/automated-pipeline/node/update',
    Read_ALL_NODE_OF_PIPELINE: '/automated-pipeline/read-all-node',
    Read_PIPELINE: '/automated-pipeline/read-pipeline',
    UPDATE_PIPELINE: '/automated-pipeline/update-pipeline',
    READ_NODE_DATA: '/automated-pipeline/read-node',
    COMPILE: '/automated-pipeline/compile-pipeline',
    DELETE_PIPELINE: '/automated-pipeline/delete-pipeline',
    DELETE_NODE: '/automated-pipeline/delete-node',
    DELETE_HISTORY: '/automated-pipeline/delete-pipeline-history',
    STOP_EXECUTION: '/automated-pipeline/stop-execution',
    POWER_BI: '/automated-pipeline/init-power-bi',
    FETCH_NORMAL_OPERATION_TASKS:
      '/automated-pipeline/normal-operation-task-details',
    ALL_DATASETS_RTU: '/automated-pipeline/mark-all-dataset-published',
    CLONE_PIPELINE: '/automated-pipeline/pipeline-clone',
    SHARE_PIPELINE: '/automated-pipeline/pipeline-share',
    FETCH_SHARED_PIPELINE: '/automated-pipeline/pipeline-sharelist',
    PIPELINE_SHARE_ACTION: '/automated-pipeline/pipeline-share-action',
    CONNECT_PIPELINE: '/automated-pipeline/add-pipeline',
    REMOVE_PIPELINE_CONNECTION: '/automated-pipeline/remove-connection',
    GET_PIPELINE_LIST_WITHOUT_PAGINATION: '/automated-pipeline/show-pipeline',
  },
  WORKER: {
    WORKER_LIST: '/execution-engine/list-workers',
    FETCH_WORKERS_METRICS: '/execution-engine/get-metrics',
    DEREGISTER_WORKER: '/execution-engine/deregister-worker',
    REMOTE_EXECUTABLES: '/execution-engine/remote-executables',
    ORCHESTRATION_SETTINGS: '/execution-engine/orchestration-settings',
  },
  CONSOLE: {
    CONSOLE_SAVE_CHART_COMMENT: '/console/save/table/comment',
    POST_UDF_DATA: '/run',
    FETCH_UDF_DATA: '/parameters',
    GET_TASKS: '/functions/tasks',
    JOB_QUEUE_MARK_READ_UNREAD: '/functions/read-status',
    FETCH_UDFS: '/functions/udf',
    FETCH_UDF: '/functions/udf',
    DELETE_USER_DEFINED_FUNCTION: '/functions/delete',
    CREATE_UDF: '/functions/create',
    UPDATE_UDF: '/functions/save',
    CREATE_JOB: '/functions/execute',
    RUN_UDF: '/functions/run',
    SAVE_CHART: '/functions/save/chart',
    SAVE_TABLE: '/functions/save/table',
    GET_CHART: '/functions/get/chart',
    GET_TABLE: '/functions/get/table',
    GET_TABLE_DATA: '/functions/output/table',
    DOWNLOAD_CSV: '/functions/download-csv',
    DATASET_COLUMN_DETAILS: '/functions/column/type',
    GET_CONSOLE_LIBRARY_LIST: '/functions/global-libraries/list',
    CONSOLE_LIBRARY: '/functions/global-libraries',
    RERUN_OPERATION: '/functions/rerun',
    GITHUB_INTEGRATION: '/functions/github-integration',
    UPLOAD_CONSOLEFILE: '/functions/startupload',
    UPLOAD_CHUNK: '/functions/uploadchunk',
    UPLOAD_COMPLETED: '/functions/completeupload',
    RENAME_DATASET_DISPLAY_NAME: '/functions/consolefiles/rename',
    FILES: '/functions/console-files',
    DELETE_FILE: '/functions/deleteconsolefile',
    DOWNLOAD_FILE: '/functions/downloadconsolefile',
    EXECUTE_UDF: '/functions/async-run',
    UDF_TASK_DETAILS: '/functions/tasks-result',
  },
  UDF_MANAGER: {
    GET_COMMIT_FILE: '/functions/file-contents',
    GET_TASKS_UDF: '/functions/tasks',
    DOWNLOAD_FILE: '/functions/download-consolefile',
    RUN_UDF: '/functions/sync-run',
    FETCH_UDF_COMMIT: '/functions/commit-details',
    FETCH_UDFS: '/functions/udf',
    CREATE_UDF: '/functions/udf',
    UPDATE_UDF: '/functions/udf',
    GET_REPO_STRUCTURE: '/functions/file-actions',
    UPDATE_CODE: '/functions/file-actions',
    UPLOAD_CONSOLEFILE: '/functions/start-upload',
    UPLOAD_CHUNK: '/functions/upload-chunk',
    UPLOAD_COMPLETED: '/functions/complete-upload',
    FILES: '/functions/console-files',
    DELETE_FILE: '/functions/console-files',
    DELETE_SESSION: '/functions/sync-run',
    DELETE_UDF: '/functions/udf',
    RERUN_UDF: '/functions/rerun',
    SAVE_RUN: '/functions/save',
    EXECUTE_UDF: '/functions/async-run',
    DELETE_TASK: '/functions/tasks-result',
    CLONE_UDF: '/functions/clone-udf',
    JOB_QUEUE_MARK_READ_UNREAD: '/functions/read-status',
    UDF_TASK_DETAILS: '/functions/tasks-result',
    UDF_SAVE_CHART_COMMENT: '/console/save/table/comment',
    GET_TABLE: '/functions/get/table',
    GET_CHART: '/functions/get/chart',
    GET_TABLE_DATA: '/functions/output/table',
    SAVE_CHART: '/functions/save/chart',
    VALIDATE_CREDENTIALS: '/functions/github-validation',
    SAVE_TABLE: '/functions/save/table',
    DOWNLOAD_CSV: '/functions/download-csv',
  },
  APIS_AND_INTEGRATIONS: {
    CREATE_TOKEN: '/in/integrate/vault/token',
    GET_APIS: '/in/integrate/vault/alltoken',
    REVOKE_TOKEN: '/in/integrate/vault',
  },
};
export default API_ENDPOINTS;
