import PropTypes from 'prop-types';
import React from 'react';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ModelMetaDataSchemaTree = ({
  modelMetaDataSchema,
  handleNodeClick,
  nodeTypesToEnable,
}) => {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {modelMetaDataSchema?.sections?.map((sec) => (
        <TreeItem
          nodeId={sec?.section_name}
          label={sec?.section_name}
          key={sec.section_id}
        >
          {sec.attributes.map((attr) => (
            <TreeItem
              nodeId={attr.name}
              label={`${attr.label} (${attr.data_type})`}
              key={attr.name}
              onClick={() =>
                nodeTypesToEnable?.includes(attr.data_type?.toLowerCase())
                  ? handleNodeClick(sec, attr)
                  : null
              }
              disabled={
                !nodeTypesToEnable?.includes(attr.data_type?.toLowerCase())
              }
            />
          ))}
        </TreeItem>
      ))}
    </TreeView>
  );
};

ModelMetaDataSchemaTree.defaultProps = {
  handleNodeClick: () => {},
  modelMetaDataSchema: {},
  nodeTypesToEnable: [],
};

ModelMetaDataSchemaTree.propTypes = {
  handleNodeClick: PropTypes.func,
  modelMetaDataSchema: PropTypes.oneOfType([PropTypes.object]),
  nodeTypesToEnable: PropTypes.oneOfType([PropTypes.array]),
};

export default ModelMetaDataSchemaTree;
