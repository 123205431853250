/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import React, { useEffect, useState, useCallback } from 'react';
import { object, string } from 'yup';
import {
  Grid,
  Button,
  Box,
  TextField,
  Switch,
  Paper,
  Autocomplete,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { DIALOG_ICON_BUTTON_CSS } from '../../const/CommonConst';
import DialogTransition from './Transitions/DialogTransition';
import useDocumentation from '../../Hooks/Documentation/useDocumentation';
import useConsoleComment from '../../Hooks/Console/useConsole';
import useProject from '../../Hooks/Project/useProject';
import SwalToast from './SwalToast';
import DocTree from '../Documentation/DocTree';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const getOptionLabel = (option) => option.document_name;
const isOptionEqualToValue = (option, val) => option.id === val.id;

/**
 * @function CommentBox
 * @description CommentBox is a box in which we add comments
 * @param {function} handleCommentBoxClose - handle function when comment box is closed
 * @param {string} id - a string which stores id
 * @param {string} tableName - a string which stores table Name
 * @param {string} commentType - a string which stores comment Type
 * @param {string} b64 - a string which stores b64
 * @param {string} type - a string which stores type
 * @param {boolean} isConsole - a boolean which stores isConsole
 * @param {string} moduleName - a string which stores module Name
 * @param {string} subModuleName - a string which stores sub Module Name
 */

const CommentBox = ({
  id,
  // tableName,
  commentType,
  openDialog,
  b64,
  type,
  handleCommentBoxClose,
  moduleName,
  isConsole = false,
  subModuleName,
}) => {
  const {
    docEditorOverview,
    setDocEditorOverview,
    activeProject,
    fetchDocumentList,
    fetchEditorOverview,
    saveContentWord,
  } = useDocumentation();
  const [append, setappend] = useState(false);
  const [documents, setdocuments] = useState(null);
  const [selectedSectionID, setselectedSectionID] = useState(null);
  const [comment, setComment] = useState('');
  const { saveTableComment, saveChartComment } = useProject();
  const { commentApiCall } = useConsoleComment();

  const formValidationSchema = object({
    DocName: object().shape({
      document_name: string().required('Required'),
    }),
    heading: string().required('Required'),
  });

  useEffect(() => {
    fetchDocumentList(activeProject.project_id).then((res) => {
      setdocuments(res.data);
    });
  }, [activeProject]);

  /**
   * @function submitComment
   * @description It is to submit comment
   * @param {any} values
   * @returns {void}
   * @example submitComment values={values}
   */
  const submitComment = (values) => {
    if (selectedSectionID && !append && selectedSectionID.parent === -1) {
      SwalToast({
        icon: 'info',
        title: "Sections can't be replaced.",
      });
      return;
    }
    const data = {
      id,
      comment,
      b64,
      module: moduleName,
      sub_module: subModuleName,
      replace: selectedSectionID ? !append : false,
      append,
      section_id: values.Section,
      document_id: values.DocName?.id,
      ...(selectedSectionID &&
        append && {
          node_parent_list: [
            selectedSectionID.parent === -1
              ? selectedSectionID.id
              : selectedSectionID.parent,
          ],
        }),
      ...(selectedSectionID && {
        node_id: append ? selectedSectionID.parent : selectedSectionID.id,
      }),
    };
    if (commentType === 'console') {
      commentApiCall(data);
    } else if (type === 'chart') {
      const { parent, id: ID } = selectedSectionID;
      data.img_heading = values.heading;
      data.img_id = { parent, id: ID };
      saveChartComment(data, isConsole, () => {
        SwalToast({
          icon: 'success',
          title: 'Comment submitted successfully.',
        });
        saveContentWord({ docId: values.DocName?.id });
      });
    } else if (type === 'table') {
      delete data.b64;
      const { parent, id: ID } = selectedSectionID;
      data.table_heading = values.heading;
      data.table_id = { parent, id: ID };
      saveTableComment(data, isConsole, () => {
        SwalToast({
          icon: 'success',
          title: 'Comment submitted successfully.',
        });
        saveContentWord({ docId: values.DocName?.id });
      });
    } else {
      SwalToast({
        icon: 'error',
        title: 'Something went wrong.',
      });
    }
    handleCommentBoxClose();
  };

  const formikForm = useFormik({
    initialValues: {
      comment: '',
      subSection: '',
      Section: '',
      DocName: '',
      heading: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      submitComment(values);
    },
  });

  /**
   * @function getSelected
   * @description It updates selection section ID
   * @param {number} _id
   * @returns {void}
   * @example getSelected _id={_id}
   */
  const getSelected = (_id) => {
    setselectedSectionID(_id);
  };

  const handleAutocompleteChange = useCallback(
    (_event, newValue) => {
      formikForm.setFieldValue('DocName', newValue);
      if (newValue) {
        fetchEditorOverview({ docID: newValue?.id });
      } else {
        setDocEditorOverview(null);
      }
    },
    [formikForm, fetchEditorOverview, setDocEditorOverview]
  );

  const handleGetSelectedID = useCallback(
    (val) => {
      getSelected(val);
    },
    [getSelected]
  );

  const handleSwitchChange = useCallback(() => {
    setappend((prevAppend) => !prevAppend);
  }, [setappend]);

  const renderInputComponent = useCallback(
    function (params) {
      return (
        <TextField
          required
          {...params}
          label="Document name"
          name="DocName"
          placeholder="Enter document name"
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={formikForm.handleBlur}
          helperText={
            formikForm?.errors?.DocName?.document_name &&
            formikForm?.touched?.DocName
              ? formikForm.errors.DocName?.document_name
              : null
          }
          error={
            Boolean(formikForm?.errors?.DocName) && formikForm?.touched?.DocName
          }
        />
      );
    },
    [formikForm]
  );

  const handleCommentChange = useCallback(
    (e) => {
      formikForm.handleChange(e);
      setComment(e.target.value);
    },
    [formikForm, setComment]
  );

  const handleResetForm = useCallback(() => {
    formikForm.resetForm();
    setDocEditorOverview(null);
    setappend(false);
    setselectedSectionID(null);
  }, [formikForm, setDocEditorOverview, setappend, setselectedSectionID]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        PaperComponent={PaperComponent}
        open={openDialog}
        onClose={handleCommentBoxClose}
        aria-labelledby="draggable-dialog-title"
        hideBackdrop={true}
        TransitionComponent={DialogTransition}
      >
        <DialogTitle>Comments / Insights</DialogTitle>
        <IconButton
          id="mui_cmp_cust_drag_dialog_title_close_btn"
          className="mui-dialog-close-icon-btn"
          color="primary"
          aria-label="close"
          onClick={() => {
            handleCommentBoxClose();
          }}
          size="large"
          sx={DIALOG_ICON_BUTTON_CSS}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={
                  Array.isArray(documents) && documents.length !== 0
                    ? documents
                    : []
                }
                disableClearable={['', undefined].includes(
                  formikForm?.values?.DocName
                )}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                noOptionsText="No document available."
                popupIcon={<DropdownArrow />}
                value={formikForm.values.DocName || null}
                onChange={handleAutocompleteChange}
                renderInput={renderInputComponent}
              />
            </Grid>
            {docEditorOverview && (
              <Grid item xs={12}>
                <Box p={1} border={1} borderColor="primary.main">
                  {docEditorOverview.child.length > 0 ? (
                    <Box
                      sx={{
                        width: 'auto',
                        height: 250,
                        overflow: 'scroll',
                      }}
                    >
                      <DocTree
                        data={{
                          node_id: 'root',
                          node_type: 'root',
                          node_heading:
                            formikForm.values.DocName?.document_name,
                          child: docEditorOverview.child,
                        }}
                        getSelectedID={handleGetSelectedID}
                      />
                    </Box>
                  ) : (
                    <Box textAlign="center">
                      No section available in this document.
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
            {selectedSectionID && (
              <Grid item xs={12}>
                <Box mt={1} mb={2}>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>Replace</Grid>
                    <Grid item>
                      <Switch
                        checked={append}
                        onChange={handleSwitchChange}
                        name="checkedC"
                      />
                    </Grid>
                    <Grid item>Append</Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                label="Heading"
                placeholder="Enter heading"
                type="text"
                name="heading"
                {...formikForm.getFieldProps('heading')}
                error={Boolean(
                  formikForm?.errors?.heading && formikForm?.touched?.heading
                    ? formikForm.errors.heading
                    : null
                )}
                helperText={
                  formikForm?.errors?.heading && formikForm?.touched?.heading
                    ? formikForm.errors.heading
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Comment"
                placeholder="Enter comment"
                multiline={true}
                rows={3}
                name="comment"
                {...formikForm.getFieldProps('comment')}
                onChange={handleCommentChange}
                error={Boolean(
                  formikForm?.errors?.comment && formikForm?.touched?.comment
                    ? formikForm.errors.comment
                    : null
                )}
                helperText={
                  formikForm?.errors?.comment && formikForm?.touched?.comment
                    ? formikForm.errors.comment
                    : null
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetForm} variant="outlined">
            Reset
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={formikForm.handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CommentBox.propTypes = {
  b64: PropTypes.oneOfType([PropTypes.string]),
  commentType: PropTypes.string,
  handleCommentBoxClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isConsole: PropTypes.bool.isRequired,
  openDialog: PropTypes.bool.isRequired,
  moduleName: PropTypes.string.isRequired,
  tableName: PropTypes.string,
  type: PropTypes.string.isRequired,
  subModuleName: PropTypes.string.isRequired,
};

export default CommentBox;
