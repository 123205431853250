// @ts-nocheck
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/prop-types */
import {
  Typography,
  Box,
  IconButton,
  Dialog,
  Paper,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import Editor from '@monaco-editor/react';
import { DIALOG_ICON_BUTTON_CSS } from '../../../const/CommonConst';
import { ReactComponent as CodeIcon } from '../../../assets/images/Code.svg';
import CommentBox from '../../Common/CommentBox';
import { ReactComponent as CommentButtonIcon } from '../../../assets/images/CommentIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/DownloadIcon.svg';
import { ReactComponent as ExpandWindowIcon } from '../../../assets/images/ExpandWindowIcon.svg';
import { ReactComponent as CollapseWindowIcon } from '../../../assets/images/CollapseWindowIcon.svg';
import utils from '../../../utils/utils';
import SwalToast from '../../Common/SwalToast';
import AddResultToModelMetaDataModal from '../../Common/AddResultToModelMetaDataModal';
import { ModelMonitoringDashboardIcon } from '../../../const/CommonIcons';
import ModelMonitoringDashboardDialog from '../../../views/ModelMonitoringDashboard/ModelMonitoringDashboardDialog';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BASE64_DATA_PREFIX_LENGTH = 22;

const editorTheme = 'myThemeDark';

const IframeContainer = ({
  codeSnippet,
  url,
  commentid,
  // commenttableName,
  showCommentBox = true,
  key,
  moduleName,
  isConsole = false,
  dialogOpen,
  handleDialogOpen,
  graphData,
  setGraphData,
  chartHeader,
  handleDialogClose,
  zoomResultImg = 1,
  permission,
  onAddChartClick,
  activeProject,
  subModuleName,
  addResultToModelMetaData,
  modelMetaDataSchema,
}) => {
  const [base64Data, setBase64Data] = useState('');
  const [openGraphComment, setOpenGraphComment] = useState(false);
  const [openAddToModelMetaData, setOpenAddToModelMetaData] = useState(false);
  const [openCode, setOpenCode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  let listernerInstance = '';

  const showAddToModelMetaDataOption = React.useMemo(
    () =>
      addResultToModelMetaData &&
      activeProject?.system_generated_flag &&
      activeProject?.system_name === 'Vault',
    [addResultToModelMetaData, activeProject]
  );

  const monacoRef = useRef(null);

  const handleEditorDidMount = (monaco) => {
    monacoRef.current = monaco;
    monaco.editor.defineTheme('myThemeDark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.foreground': '#AECDFC',
        'editor.background': '#141A3F',
        // 'editorCursor.foreground': '#8B0000',
        // 'editor.lineHighlightBackground': '#0000FF20',
        'editorLineNumber.foreground': '#141A3F',
        'editorLineNumber.activeForeground': '#fff',
        // 'editorLineNumber.background': 'red',
        'editorGutter.background': '#84afefbf',
        // 'editorGutter.mar': '5px',
        // 'editor.selectionBackground': '#88000030',
        // 'editor.inactiveSelectionBackground': '#88000015',
        'scrollbarSlider.background': '#AECDFC',
        'scrollbarSlider.activeBackground': '#AECDFC',
        'scrollbarSlider.hoverBackground': '#AECDFC',
      },
    });
  };

  const handleEventRaisedByIframe = React.useCallback(
    (e) => {
      if (url?.includes(e.origin) && e.data?.chartId === commentid) {
        if (base64Data !== e.data?.base64) {
          // added case for svg added because backend is not able to handle if we remove data:image/svg+xml;base64 from string
          if (
            e.data?.base64 &&
            e.data?.base64?.startsWith('data:image/svg+xml;base64')
          ) {
            setBase64Data(e.data?.base64);
          } else {
            // for png
            setBase64Data(
              e.data?.base64?.substring(
                BASE64_DATA_PREFIX_LENGTH,
                e.data.base64.length
              )
            );
          }
        }
      }
    },
    [setBase64Data]
  );

  /**
   * @function downloadChartFile
   * @description It is to download the chart file
   * @param {string} filename
   * @returns {void}
   * @example downloadChartFile filename={filename}
   */
  const downloadChartFile = React.useCallback(
    (filename = 'chart') => {
      // if svg
      if (base64Data?.startsWith('data:image/svg+xml;base64')) {
        utils.base64SvgToOtherBase64(base64Data, 'png', (pngData) => {
          const link = document.createElement('a');
          link.href = pngData; // `data:image/png;base64,${base64Data}`;
          link.download = `${filename}.png`;
          link.click();
        });
      } else {
        // else png
        const link = document.createElement('a');
        link.href = `data:image/png;base64,${base64Data}`;
        link.download = `${filename}.png`;
        link.click();
      }
    },
    [base64Data]
  );

  listernerInstance = window.addEventListener(
    'message',
    handleEventRaisedByIframe
  );

  /**
   * @function handleCodeBoxOpen
   * @description When the condition satisfied it change the state from null to true
   * @param {string} value
   * @returns {void}
   * @example handleCodeBoxOpen value={value}
   */
  const handleCodeBoxOpen = (value) => {
    if (value === 'Graph') {
      setOpenCode(true);
    }
  };

  /**
   * @function handleCodeBoxClose
   * @description It change the state from null to false
   * @returns {void}
   * @example handleCodeBoxOpen value={value}
   */
  const handleCodeBoxClose = () => {
    setOpenCode(false);
  };

  /**
   * @function handleCommentBoxOpen
   * @description When the condition satisfied it change the state from null to true
   * @param {string} value
   * @returns {void}
   * @example handleCommentBoxOpen value={value}
   */
  const handleCommentBoxOpen = (value) => {
    if (value === 'Graph') {
      setOpenGraphComment(true);
    }
  };

  /**
   * @function handleCommentBoxClose
   * @description It change the state from null to false
   * @returns {void}
   * @example handleCommentBoxClose value={value}
   */
  const handleCommentBoxClose = () => {
    setOpenGraphComment(false);
  };

  const handleAddToModelMetaDataModalClose = () => {
    setOpenAddToModelMetaData(false);
  };

  useEffect(() => {
    return () => {
      if (listernerInstance) {
        window.removeEventListener(listernerInstance);
      }
    };
  }, []);

  const addChartInMonitoring = React.useCallback(
    (payloadData) => {
      if (base64Data) {
        const chartDataPayload = {
          ...payloadData,
          type: 'chart',
          url: base64Data,
          project_id: activeProject?.project_id,
        };
        onAddChartClick(chartDataPayload).then((res) => {
          SwalToast({
            icon: 'success',
            title: res.data?.msg,
          });
        });
      }
    },
    [base64Data]
  );

  const handleGraphClick = useCallback(() => {
    handleDialogOpen();
    setGraphData({ ...graphData, type: 'chart' });
  }, [handleDialogOpen, setGraphData, graphData]);
  const handleModelMonitoringIconClick = useCallback(() => {
    setOpenDialog(true);
  }, [setOpenDialog]);

  return (
    <Box display="flex" sx={{ flexDirection: 'column' }} height="inherit">
      <Box display="flex" flexDirection="column">
        <Box
          mb={0}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box mt={1} ml={1}>
            <Typography variant="h4">{chartHeader}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" flexGrow={1}>
            <IconButton
              color="primary"
              // eslint-disable-next-line
              onClick={function () {
                downloadChartFile(chartHeader);
              }}
            >
              <DownloadIcon title="Download" />
            </IconButton>
            {codeSnippet && (
              <IconButton
                // eslint-disable-next-line
                onClick={function () {
                  handleCodeBoxOpen('Graph');
                }}
              >
                <CodeIcon title="Code snippet" />
              </IconButton>
            )}
            {showCommentBox && (
              <IconButton
                // eslint-disable-next-line
                onClick={function () {
                  handleCommentBoxOpen('Graph');
                }}
                disabled={!['Manage', 'View'].includes(permission)}
                title={
                  !['Manage', 'View'].includes(permission)
                    ? 'You are not authorized to access this!'
                    : ''
                }
              >
                <CommentButtonIcon title="Add comment" />
              </IconButton>
            )}
            {onAddChartClick && (
              <IconButton
                color="primary"
                // eslint-disable-next-line
                onClick={function () {
                  addChartInMonitoring(graphData);
                }}
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? 'You are not authorized to access this!'
                    : 'Add to model monitoring dashboard'
                }
              >
                <DashboardCustomizeIcon />
              </IconButton>
            )}
            {onAddChartClick && (
              <IconButton
                onClick={handleModelMonitoringIconClick}
                color="primary"
                title="Model monitoring dashboard"
              >
                <ModelMonitoringDashboardIcon width={20} height={20} />
              </IconButton>
            )}
            {showAddToModelMetaDataOption ? (
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenAddToModelMetaData(true);
                }}
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? 'You are not authorized to access this!'
                    : 'Add to vault'
                }
              >
                <ModelTrainingIcon />
              </IconButton>
            ) : null}
            {!dialogOpen ? (
              <IconButton
                title="Expanded view"
                onClick={handleGraphClick}
                size="small"
              >
                <ExpandWindowIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  handleDialogClose();
                }}
                size="small"
              >
                <CollapseWindowIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box height="inherit">
        <Box width="100%" height="100%" sx={{ zoom: `${zoomResultImg}` }}>
          <embed
            id="iframe_graph"
            key={key}
            src={url}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Box>
      {showCommentBox && openGraphComment && (
        <CommentBox
          handleCommentBoxClose={handleCommentBoxClose}
          b64={base64Data}
          openDialog={openGraphComment}
          id={commentid}
          type="chart"
          moduleName={moduleName}
          subModuleName={subModuleName}
          isConsole={isConsole}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        PaperComponent={PaperComponent}
        open={openCode}
        onClose={handleCodeBoxClose}
        aria-labelledby="draggable-dialog-title"
        // hideBackdrop={true}
        // TransitionComponent={DialogTransition}
      >
        <DialogTitle>Code Snippet</DialogTitle>
        <IconButton
          id="mui_cmp_cust_drag_dialog_title_close_btn"
          className="mui-dialog-close-icon-btn"
          color="primary"
          aria-label="close"
          onClick={handleCodeBoxClose}
          size="large"
          sx={DIALOG_ICON_BUTTON_CSS}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Editor
            height="50vh"
            defaultLanguage="python"
            defaultValue={codeSnippet}
            theme={editorTheme}
            beforeMount={handleEditorDidMount}
            options={{
              padding: {
                left: 12,
                right: 12,
                top: 12,
                bottom: 12,
              },
              readOnly: true,
              fontSize: 16,
              lineHeight: 22,
              fontFamily: 'Open Sans',
              lineDecorationsWidth: 5,
              scrollbar: {
                vertical: 'auto',
                horizontal: 'auto',
                verticalScrollbarSize: 5,
                horizontalScrollbarSize: 5,
              },
            }}
          />
        </DialogContent>
      </Dialog>

      <ModelMonitoringDashboardDialog
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
      {showAddToModelMetaDataOption ? (
        <AddResultToModelMetaDataModal
          open={openAddToModelMetaData}
          onClose={handleAddToModelMetaDataModalClose}
          addResultToModelMetaData={addResultToModelMetaData}
          modelMetaDataSchema={modelMetaDataSchema}
          activeProject={activeProject}
          resultContents={base64Data}
          resultType="chart"
        />
      ) : null}
    </Box>
  );
};

export default IframeContainer;
