// @ts-nocheck
import { createTheme, alpha } from '@mui/material/styles';
import _ from 'lodash';
import baseTheme from './base';
// The palette enables you to modify the color of the components to suit your brand.
// https://material-ui.com/customization/palette/
// https://material-ui.com/customization/default-theme/?expand-path=$.palette
// export default createTheme({
//   ...baseTheme,
//   themeName: 'Nimbus Light',
// });
const PRIMARY_MAIN_COLOR = '#272D55 !important';
const PRIMARY_CONTRAST_TEXT = '#AECDFC !important';
const ERROR_CONTRAST_TEXT = '#FFFFFF !important';
const BLUE_ONE = '#E8F1FF !important';
export default createTheme(
  _.merge(baseTheme, {
    themeName: 'Nimbus Light',
    palette: {
      type: 'light',
      primary: {
        main: '#272D55',
        light: '#525777',
        dark: '#1B1F3B',
        contrastText: '#AECDFC',
      },
      secondary: {
        main: '#AECDFC',
        light: '#BED7FC',
        dark: '#798FB0',
        contrastText: '#141A3F',
      },
      success: {
        light: '#52BE7F',
        main: '#27AE60',
        dark: '#1B7943',
        contrastText: '#000000',
      },
      error: {
        main: '#EB5757',
        light: '#EF7878',
        dark: '#A43C3C',
        contrastText: '#FFFFFF',
      },
      warning: {
        main: '#FBAE54',
        light: '#FBBE76',
        dark: '#AF793A',
        contrastText: '#000000',
      },
      info: {
        main: '#FBAE54',
        light: '#FBBE76',
        dark: '#AF793A',
        contrastText: '#000000',
      },
      other: {
        blue1: '#E8F1FF',
        blue2: '#F3F5FF',
        blue3: '#C8DEFF',
        blue4: '#645fe4',
        blue5: '#5890ff',
        blue6: '#1890FF1A',
        blue7: '#E3ECFF',
        brandBlue: '#272D55',
        badgeColor: '#EFCCBE',
        badgeBorder: '#EE883D',
        operationNode: '#EF9A60',
        lightBrandBlueLogin: '#84AFEF',
        hover: '#E8F1FF',
        white: '#FFFFFF',
        white2: '#ddd',
        black: '#000000',
        grey1: '#F4F4F4',
        grey2: '#9396AA',
        gray3: '#E5E8FA',
        purple: '#963DEE',
        yellow: '#B88A14',
        yellow2: '#FFC444',
        pink: '#B6205F',
        grey3: '#666',
        grey4: '#999',
        grey5: '#BCBCBC',
        grey6: '#636363',
        grey7: '#DFE1EE',
        treeViewBackgroundColor: '#DCE7FF',
        pieChart1: '#EBF0B1',
        pieChart2: '#EE9023',
        pieChart3: '#ACB9FD',
        pieChart4: '#BDEDDA',
        pieChart5: '#F4B2B6',
        pieChart6: '#AAD6FF',
        pieChart7: '#F9BCE4',
        pieChart8: '#FFD3B4',
        pieChart9: '#A6DCFB',
        pieChart10: '#B3F0F0',
        pieChart11: '#EBF0B1',
        pieChart12: '#D4BCF2',
        pieChart13: '#FDE5AA',
        pieChart14: '#EDB777',
        barChart1: '#DEF0B8',
        barChart2: '#BDEDDA',
        barChart3: '#B3F0F0',
        barChart4: '#F4B2B6',
        loginSecondaryColor: '#DFE7F3',
        gradient1:
          'linear-gradient(90deg, rgba(243,245,255,1) 0%, rgba(200,222,255,1) 79%)',
        gradient2:
          'linear-gradient(269.82deg, rgb(221, 233, 255) 57.25%, rgba(200, 222, 255, 0.1) 230.71%)',
        tableRowGradient1:
          'linear-gradient(269.82deg, rgb(221, 233, 255) 1.25%, rgba(200, 222, 255, 0.1) 102.71%)',
        tableRowGradient2:
          'linear-gradient(269.82deg, rgb(221, 233, 255) 57.25%, rgba(200, 222, 255, 0.1) 230.71%)',
        green: '0BB7A7',
        green1: '#3cf4ff',
        green2: '#00DD92',
        red: '#FF1809',
      },
      text: {
        primary: '#141A3F',
        disabled: '#9396AA',
      },
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF',
          },
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: '#F3F5FF',
            boxShadow: '0px 6px 12px rgba(132, 175, 239, 0.31)',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: alpha('#C0D9FF', 0.3),
          },
          colorSecondary: {
            borderColor: '#84AFEF',
          },
          root: {
            '&:hover': {
              backgroundColor: '#AECDFC',
            },
          },
          label: {
            color: '#272D55',
          },
          deleteIconColorPrimary: {
            color: '#272D55',
            '&:hover': {
              color: '#272D55',
            },
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: PRIMARY_MAIN_COLOR,
            color: '#F3F5FF !important',
            '& .MuiCheckbox-root': {
              color: '#fff',
            },
            '& .Mui-checked': {
              color: '#fff !important',
            },
          },
          root: {
            borderColor: '#AECDFC',
            background: '#FFFFFF',
          },
          sizeSmall: {
            borderColor: '#AECDFC',
            '&:hover': {
              backgroundColor: 'rgba(192, 217, 255, 0.3)',
            },
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: { borderColor: '#272D55' },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: PRIMARY_CONTRAST_TEXT,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          barColorPrimary: {
            backgroundColor: '#84AFEF',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            borderColor: '#AECDFC',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          select: {
            background: '#FFFFFF',
          },
          toolbar: {
            background: '#F3F5FF',
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          previousNext: {
            background: ERROR_CONTRAST_TEXT,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#000',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              color: '#000',
              '&.Mui-error': {
                color: '#EB5757',
              },
              '& .MuiTypography-root': {
                color: '#000',
              },
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              color: '#000',
            },
            '& .MuiInputLabel-shrink': {
              color: '#000',
            },
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            backgroundColor: 'none',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderColor: '#AECDFC',
            '& .Mui-selected': {
              color: '#141A3F !important',
            },
          },
          indicator: {
            boxShadow: '0px -2px 14px rgba(39, 45, 85, 0.24)',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#AAACBB',
          },
        },
      },
      // MuiButton: {
      // 	styleOverrides: {
      // 		outlinedSizeLarge: {
      // 			color: '#AECDFC !important',
      // 		},
      // 		containedSecondary: {
      // 			color: '#141A3F !important',
      // 		},
      // 	},
      // },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: '#272D55',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: '#272D55',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: PRIMARY_CONTRAST_TEXT,
          },
          thumb: {
            borderColor: '#272D55',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            borderBottomColor: '#84AFEF',
            '& .MuiListItemButton-root': {
              '& .list-item-button-hover-actions-right': {
                backgroundImage:
                  'linear-gradient(269.82deg, #DDE9FF 68.25%, rgba(200, 222, 255, 0.1) 87.71%)',
              },
              '&:hover': {
                backgroundColor: '#C8DEFF',
              },
            },
          },
        },
      },

      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: '#ffffff ',
          },
          icon: {
            color: PRIMARY_MAIN_COLOR,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff !important',
            border: '1px solid #D9D9D9',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff !important',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            boxShadow: `0px 6px 12px ${alpha('#84AFEF', 0.31)}`,
          },
          option: {
            color: '#272D55',
            backgroundColor: '#ffffff !important',
            '&[aria-selected="true"]': {
              backgroundColor: BLUE_ONE,
            },
            '&:focus': {
              backgroundColor: BLUE_ONE,
            },
            '&:hover': {
              backgroundColor: BLUE_ONE,
            },
            '&:active': {
              color: '#000000',
              backgroundColor: PRIMARY_CONTRAST_TEXT,
              '&&&:before': {
                backgroundColor: PRIMARY_CONTRAST_TEXT,
              },
              '&&:after': {
                backgroundColor: PRIMARY_CONTRAST_TEXT,
              },
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#272D55',
          },
          track: {
            color: PRIMARY_CONTRAST_TEXT,
          },
          rail: {
            color: PRIMARY_CONTRAST_TEXT,
            opacity: 1,
          },
          valueLabel: {
            color: '#FFFFFF',
            background: '#272D55',
          },
          mark: {
            background: PRIMARY_MAIN_COLOR,
          },
          markLabel: {
            color: PRIMARY_MAIN_COLOR,
          },
          thumb: {
            '&:hover': {
              boxShadow: '0px 0px 0px 15px RGBA(174, 205, 252, 0.5) !important',
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: '#F3F5FF',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: '#272D55',
            color: ERROR_CONTRAST_TEXT,
            '& .MuiTypography-root': {
              color: ERROR_CONTRAST_TEXT,
            },
          },
        },
      },
    },
  })
);
