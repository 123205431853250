// @ts-nocheck
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  Button,
  IconButton,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { REGULATIONS } from '../../const/CommonConst';
import DialogTransition from '../Common/Transitions/DialogTransition';
import {
  DropDown,
  DropdownItem /* DropdownItemGroup */,
} from '../Common/DropDown';
import { SwalToast } from '../Common';
import utils from '../../utils/utils';

/**
  @function CreateEditProjectModal
 // eslint-disable-next-line max-len
 * @description CreateNewProjectModal- child component of ProjectItemList.Creating new project modal of a user.
 * @param  {function} createNewProject -creating new project
//  * @param  {function} deleteProject -deleting project
 * @param  {function} updateProject -updating project
 * @param  {function} fetchProjects - fetching user projects
 * @param {boolean} open -open variable
 * @param {function} handleClose - changing state of open variable from null to false
 * @param {object} project -project object
 * @param {function} activateProject -activating project
 * @param {object} permissions -permissions object
 * @param {function} fetchUserProjectsStatus -fetching user projects status
 * @returns {JSX} CreateNewProjectModal UI
 * @example <CreateNewProjectModal createNewProject={createNewProject}
 * fetchProjects={fetchProjects} open={open}/>
 */
function CreateEditProjectModal({
  createNewProject = () => {},
  // deleteProject = () => {},
  updateProject = () => {},
  fetchProjects = () => {},
  open = false,
  handleClose = () => {},
  project,
  permissions,
  fetchUserProjectsStatus,
  fetchProjectTypes,
}) {
  const [mode, setMode] = React.useState('CREATE');
  const [projectTypes, setProjectTypes] = React.useState([]);
  const formValidationSchema = Yup.object({
    name: Yup.string()
      .max(255, 'Maximum 255 characters are allowed.')
      .required('Required'),
    description: Yup.string()
      .max(250, 'Maximum 250 characters are allowed.')
      .required('Required'),
    type: Yup.string().required('Required'),
    // portfolio: Yup.string().required('Required'),
  });
  const formikForm = useFormik({
    initialValues: {
      name: '',
      description: '',
      type: '',
      regulation: '',
      portfolio: 'Retail/Closed end',
    },
    validationSchema: formValidationSchema,

    onSubmit: (values) => {
      if (mode === 'CREATE') {
        const updateValues = !values.regulation
          ? { ...values, regulation: 'Classification' }
          : values;
        createNewProject(updateValues, (resp) => {
          handleClose();
          fetchProjects();
          fetchUserProjectsStatus();
          SwalToast({
            icon: 'success',
            title: resp.data.msg
              ? resp.data.msg
              : 'Project created successfully.',
          });
          formikForm.resetForm();
        });
      } else {
        const data = {
          ...values,
          id: project?.project_id,
        };
        updateProject(data).then(
          (resp) => {
            handleClose();
            fetchProjects();
            SwalToast({
              icon: 'success',
              title: `Project ${
                resp?.data?.name ? resp?.data?.name?.trim() : ''
              } updated successfully.`,
            });
            formikForm.resetForm();
          },
          () => {}
        );
      }
    },
  });
  useEffect(() => {
    if (project) {
      setMode('EDIT');
      formikForm.setValues({ ...project });
      const timerId = setTimeout(() => {
        formikForm.validateForm();
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [project]);

  useEffect(() => {
    fetchProjectTypes().then((res) => {
      setProjectTypes(res);
    });
  }, []);

  return (
    <Dialog
      onClose={() => {
        formikForm.resetForm();
        handleClose();
      }}
      aria-labelledby="customized-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      TransitionComponent={DialogTransition}
      open={open}
    >
      <DialogTitle>{mode === 'EDIT' ? 'Edit' : 'Create'} Project</DialogTitle>
      <IconButton
        id="prj_over_prj_item_list_cr_new_prj_modal_close_btn"
        className="mui-dialog-close-icon-btn"
        color="primary"
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
        onClick={() => {
          formikForm.resetForm();
          handleClose();
        }}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              label="Project name"
              name="name"
              placeholder="Enter project name"
              {...formikForm.getFieldProps('name')}
              error={formikForm.touched.name && Boolean(formikForm.errors.name)}
              helperText={
                formikForm?.errors?.name && formikForm?.touched?.name
                  ? formikForm.errors.name
                  : null
              }
              fullWidth={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Description"
              name="description"
              placeholder="Enter description"
              multiline
              rows={3}
              {...formikForm.getFieldProps('description')}
              error={
                formikForm.touched.description &&
                Boolean(formikForm.errors.description)
              }
              helperText={
                formikForm?.errors?.description &&
                formikForm?.touched?.description
                  ? formikForm.errors.description
                  : null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DropDown
              required
              label="Project type"
              placeholder="Select project type"
              name="type"
              {...formikForm.getFieldProps('type')}
              error={
                formikForm?.touched?.type && Boolean(formikForm?.errors?.type)
              }
              helperText={
                formikForm?.errors?.type && formikForm?.touched?.type
                  ? formikForm.errors?.type
                  : null
              }
            >
              {projectTypes.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <DropdownItem value={item} key={index}>
                  {utils.toTitleCase(item)}
                </DropdownItem>
              ))}
            </DropDown>
          </Grid>
          <Grid item xs={12}>
            <DropDown
              label="Model type"
              name="regulation"
              placeholder="Select model type"
              disabled={mode === 'EDIT'}
              {...formikForm.getFieldProps('regulation')}
              helperText={
                formikForm?.errors?.regulation &&
                formikForm?.touched?.regulation
                  ? formikForm.errors.regulation
                  : null
              }
            >
              {/* <DropdownItemGroup>Non time series</DropdownItemGroup> */}
              {REGULATIONS.filter((item) => !item.isTs).map((item) => (
                <DropdownItem value={item.key} key={item.key}>
                  {item?.label}
                </DropdownItem>
              ))}
              {/* <DropdownItemGroup>Time series</DropdownItemGroup> */}
              {REGULATIONS.filter((item) => item.isTs).map((item) => (
                <DropdownItem value={item.key} key={item.key}>
                  {item?.label}
                </DropdownItem>
              ))}
              {/* <DropdownItemGroup>Others</DropdownItemGroup>
              <DropdownItem value="None">None</DropdownItem> */}
            </DropDown>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          title={
            mode !== 'CREATE'
              ? permissions?.editProject !== 'Yes'
                ? 'You are not authorized to access this!'
                : ''
              : ''
          }
          disabled={
            mode !== 'CREATE' ? permissions?.editProject !== 'Yes' : false
          }
          onClick={() => {
            if (mode === 'EDIT') {
              formikForm.setValues({
                name: '',
                description: '',
                type: '',
                regulation: project.regulation,
              });
              formikForm.setTouched({}, false);
            } else {
              formikForm.resetForm();
            }
          }}
          variant="outlined"
        >
          reset
        </Button>
        <Button
          title={
            mode !== 'CREATE'
              ? permissions?.editProject !== 'Yes'
                ? 'You are not authorized to access this!'
                : ''
              : ''
          }
          disabled={
            (mode !== 'CREATE' ? permissions?.editProject !== 'Yes' : false) ||
            project?.share_status === 'Failed' ||
            project?.clone_status === 'Failed'
          }
          color="primary"
          variant="contained"
          onClick={() => formikForm.handleSubmit()}
        >
          Submit
        </Button>
      </DialogActions>
      {/* <DiscardButton onClick={() => formikForm.resetForm()} className="mr-2" />
            <CreateProjectButton /> */}
    </Dialog>
  );
}

CreateEditProjectModal.defaultProps = {
  // deleteProject: () => {},
  project: null,
  updateProject: () => {},
  activateProject: () => {},
  permissions: {},
  fetchUserProjectsStatus: () => {},
};

CreateEditProjectModal.propTypes = {
  createNewProject: PropTypes.func.isRequired,
  // deleteProject: PropTypes.func,
  fetchProjects: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  project: PropTypes.oneOfType([PropTypes.object]),
  updateProject: PropTypes.func,
  activateProject: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.object]),
  fetchUserProjectsStatus: PropTypes.func,
  fetchProjectTypes: PropTypes.func.isRequired,
};

export default CreateEditProjectModal;
