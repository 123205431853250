// @ts-nocheck
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Zoom } from '@mui/material/';
import { sortBy } from 'lodash';
import {
  EditableCell,
  ReactMuiTable,
  ReactMuiColumnHeaderWithOptions,
  ReactMuiCellWithOptions,
  ReactMuiTableVirtualized,
  ReactMuiTableColumnHeaderTextEllipsis,
} from 'solytics-frontend';
import axios from 'axios';
import DoNotDisturbOutlinedIcon from '@mui/icons-material/DoNotDisturbOutlined';
import ApiEndpoints from '../../const/ApiEndpoints';
import utils from '../../utils/utils';
import GridTasksTableAndCharts from './GridTasksTable&Charts';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import SwalToast from './SwalToast';
import NoDataFoundComponent from './NoDataFoundComponent';

const EXECUTION_SCHEDULED_MESSAGE =
  'The operation is scheduled for execution. Please check the job queue for further updates.';

const INITIAL_PAGE_SIZE = 500;

const getVirtualizedColumnProps = (column) => ({
  style: {
    ...(column.id === 'pk'
      ? {
          backgroundColor: 'rgba(192, 217, 255, 0.3)',
        }
      : {}),
  },
});

const getColumnProps = (column) => ({
  style: {
    ...(column.id === 'pk'
      ? {
          maxWidth: '70px',
          minWidth: '70px',
          backgroundColor: 'rgba(192, 217, 255, 0.3)',
        }
      : {}),
  },
});

/**
 * @function DatasetTable
 * @description To describe Dataset Table
 * @param {object} tableDataWithPagination  - An object to store data of table with pagination
 * @param {function} updateDatasetCells - A function to update dataset cells
 * @param {number} totalDatasetRows  - A number which stores total dataset rows
 * @param {function} fetchDatasetRowsWithPagination - A function to fetch dataset rows with pagination
 * @param {function} datasetRowsPageCount -  A function which count page of dataset rows
 * @param {object} dataset                -  An object which store datasets
 * @param {function} renameColumn         -  A function to rename column in DatasetTable
 * @param {function} duplicateColumn      - A function to duplicate column in DatasetTable
 * @param {function} deleteColumn         - A function to delete column in DatasetTable
 * @param {function} addColumn            - A function to add column in DatasetTable
 * @param {function} deleteRow            - A function to delete row in DatasetTable
 * @param {function} duplicateRow         - A function to duplicate row in DatasetTable
 * @param {function} addRow               - A function to add row in DatasetTable
 * @param {function} fetchTasks           - A function to fetch tasks in DatasetTable
 * @param {number} projectId              - A number which store Id of project in DatasetTable
 * @param {function} manageSpaces         - A function which manage spaces in DatasetTable
 * @param {function} manageCases          - A function which manage cases in DatasetTable
 * @param {function} splitColumn          - A function to split column in DatasetTable
 * @param {function} findAndReplace       - A function to find and replace something in DatasetTable
 * @param {function} sortColumn           - A function to sort column in DatasetTable
 * @param {object} activeProject          - An object which store active projects in DatasetTable
 * @param {array} dataSets                - An array of dataset
 * @param {function} analyse              -  A function
 * @param {function} embeddedValues       - A function to embedded Values in DatasetTable
 * @param {function} standardisationError  - A function
 * @param {function} integrityRules        - A function to check the rules
 * @param {function} uniqueness            - A function to find uniqueness in DatasetTable
 * @param {function} scores                - A function to get scores value in DatasetTable
 * @param {function} findDuplicateRows     - A function to find duplicate rows in DatasetTable
 * @param {function} findDuplicateColumns  - A function to find duplicate columns in DatasetTable
 * @param {function} findAndDeleteDuplicateRows - A function to find and delete duplicate rows in DatasetTable
 * @param {function} findAndDeleteDuplicateColumns  - A function to find and delete duplicate columns in DatasetTable
 * @param {function} valueError                 - A function
 * @param {object} gridTaskResultsData         - An object which stores task result data of grid in DatasetTable
 * @param {function} resetGridTaskResultsData  - A function to reset data
 * @param {function} findBlankValuePercentage  -  A function to find Blank value percentage
 * @param {function} replaceBlankValues     - A function to replace blank values in DatasetTable
 * @param {function} findRegexPercentage    - A function to find Regex Percentage in DatasetTable
 * @param {string} permission               - A string value
 * @param {bool} isReadOnly                - A boolean value which ensures we can only read
 * @param {function} fetchGridTaskData    - A function to fetch Grid task data in DatasetTable
 * @param {function} fetchTableDataResult  -A function to fetch table data result in DatasetTable
 * @param {bool} showToolbar              - A boolean value which shows toolbar
 * @returns {JSX.Element}
 * @example <DatasetTable />
 */
const DatasetTable = ({
  updateDatasetCells = () => {},
  fetchDatasetRowsWithPagination,
  dataset,
  renameColumn,
  duplicateColumn,
  deleteColumn,
  addColumn,
  deleteRow,
  duplicateRow,
  addRow,
  fetchTasks,
  projectId,
  manageSpaces,
  manageCases,
  splitColumn,
  findAndReplace,
  sortColumn,
  activeProject,
  dataSets,
  analyse,
  embeddedValues,
  standardisationError,
  integrityRules,
  uniqueness,
  scores,
  findDuplicateRows,
  findDuplicateColumns,
  findAndDeleteDuplicateRows,
  findAndDeleteDuplicateColumns,
  valueError,
  gridTaskResultsData,
  resetGridTaskResultsData = () => {},
  findBlankValuePercentage,
  replaceBlankValues,
  findRegexPercentage,
  permission,
  isReadOnly,
  fetchGridTaskData,
  fetchTableDataResult,
  showToolbar = true,
}) => {
  const [tableColumns, setTableColumns] = useState([]);
  const [datasetList, setDatasetList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const handleTableStateChange = ({ globalFilter }) => {
    if (globalFilter || globalFilter === undefined) {
      setSearchString(globalFilter);
    }
  };
  // ealier we used to manage this state in hook file because there was no need to show more than one dataset table on single screen.
  // now we moved this table state to this child component.
  const [datasetRowsWithPagination, setDatasetRowsWithPagination] = useState(
    []
  );
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [datasetRowsPageCount, setDatasetRowPageCount] = useState(0);
  const [totalDatasetRows, setTotalDatasetRows] = useState(0);
  const fetchIdRef = React.useRef(0);
  const fetchDatasetRowsWithPaginationWrapper = React.useCallback(
    (datasetId, pageIndex, pageSize, searchText) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      // Your server could send back total page count.
      setIsLoading(true);
      fetchDatasetRowsWithPagination(
        datasetId,
        pageIndex,
        pageSize,
        searchText
      ).then(
        (res) => {
          if (res.data.response) {
            setIsLoading(false);
            if (fetchId === fetchIdRef.current) {
              setDatasetRowsWithPagination(res.data.response);
              setTotalDatasetRows(res.headers['x-total-count']);
              setDatasetRowPageCount(
                Math.ceil(res.headers['x-total-count'] / pageSize)
              );
            }
          } else {
            setIsLoading(false);
            setDatasetRowsWithPagination([]);
            setDatasetRowPageCount(0);
            setTotalDatasetRows(0);
          }
        },
        () => {
          setIsLoading(false);
          setDatasetRowsWithPagination([]);
          setDatasetRowPageCount(0);
          setTotalDatasetRows(0);
        }
      );
    },
    [setDatasetRowPageCount, setTotalDatasetRows, setDatasetRowsWithPagination]
  );

  useEffect(() => {
    if (activeProject?.project_id) {
      const arr = [];
      dataSets.forEach((el) => {
        if (el.project_id.includes(activeProject?.project_id)) {
          arr.push(el);
        }
      });
      setDatasetList(arr);
    }
  }, [activeProject]);

  // Value Error operation function
  const valueErrorCallback = React.useCallback(
    (values) => {
      resetGridTaskResultsData({});
      const newValues = { ...values };
      newValues.dataset_id = dataset?.id;
      delete newValues?.table_name;
      valueError(newValues).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    [dataset]
  );
  const columnOptionsCallbacks = {
    onDeleteCallback: ({ column, state }) => {
      resetGridTaskResultsData();
      deleteColumn(column.id, dataset?.id, activeProject?.project_id).then(
        () => {
          setTableColumns((old) =>
            old.filter((col) => col.accessor !== column.id)
          );
          fetchDatasetRowsWithPaginationWrapper(
            dataset?.id,
            state.pageIndex,
            state.pageSize
          );
          SwalToast({
            icon: 'success',
            title: EXECUTION_SCHEDULED_MESSAGE,
          });
        }
      );
    },
    onRenameCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      renameColumn(
        column.id,
        values.newColumnName,
        dataset?.id,
        activeProject?.project_id
      ).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onDuplicateCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      duplicateColumn(
        column.id,
        values.newColumnName,
        dataset?.id,
        activeProject?.project_id
      ).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onAddColumnCallback: (values, { state }) => {
      resetGridTaskResultsData();
      addColumn(
        values.columnName,
        values.dataType,
        dataset?.id,
        activeProject?.project_id
      ).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onDeleteRowCallback: ({ row, state }) => {
      resetGridTaskResultsData();
      deleteRow(row?.original?.pk, dataset?.id, activeProject?.project_id).then(
        () => {
          setDatasetRowsWithPagination((old) =>
            old.filter((_ele, i) => i !== row?.index)
          );
          fetchDatasetRowsWithPaginationWrapper(
            dataset?.id,
            state.pageIndex,
            state.pageSize
          );
          SwalToast({
            icon: 'success',
            title: EXECUTION_SCHEDULED_MESSAGE,
          });
        }
      );
    },
    onDuplicateRowCallback: ({ row, state }) => {
      resetGridTaskResultsData();
      duplicateRow(
        row?.original?.pk,
        dataset?.id,
        activeProject?.project_id
      ).then(() => {
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
      });
    },
    onAddRowCallback: (values, { state }) => {
      resetGridTaskResultsData();
      addRow(values, dataset?.id, activeProject?.project_id).then(() => {
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
      });
    },

    onManageSpacesCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      const manageSpacesPayload = {
        column_name: column.id,
        sub_operation: values.operationName,
        project_id: projectId,
      };
      manageSpaces(manageSpacesPayload, dataset?.id).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },

    onManageCasesCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      const manageCasesPayload = {
        column_name: column.id,
        sub_operation: values.operationName,
        project_id: projectId,
      };
      manageCases(manageCasesPayload, dataset?.id).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },

    onSplitColumnCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      const splitColumnPayload = {
        column_to_split: column.id,
        project_id: projectId,
        delimiter: values.delimiter,
      };
      splitColumn(splitColumnPayload, dataset?.id).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },

    onFindAndReplaceCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      const findAndReplacePayload = {
        column_name: column.id,
        old_text: values.oldText,
        new_text: values.newText,
        project_id: projectId,
      };
      findAndReplace(findAndReplacePayload, dataset?.id).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },

    onSortColumnCallback: (values, { column, state }) => {
      resetGridTaskResultsData();
      const sortPayload = {
        column_name: column.id,
        order: values.order,
        project_id: projectId,
      };
      sortColumn(sortPayload, dataset?.id).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onAnalyseCallback: ({ column }) => {
      resetGridTaskResultsData();
      const analysePayload = {
        project_id: projectId,
        column_name: column.id,
        dataset_id: dataset?.id,
      };
      analyse(analysePayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onUniquenessCallback: ({ column }) => {
      resetGridTaskResultsData();
      const uniquenessPayload = {
        project_id: projectId,
        column_name: column.id,
        dataset_id: dataset?.id,
      };
      uniqueness(uniquenessPayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onEmbeddedValuesCallback: (values, { column }) => {
      resetGridTaskResultsData();
      const embeddedValyesPayload = {
        project_id: projectId,
        column_name: column.id,
        dataset_id: dataset?.id,
        delimiter: values.delimiter,
      };
      embeddedValues(embeddedValyesPayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onStandardisationErrorCallback: (values, { column }) => {
      resetGridTaskResultsData();
      if (values.valueError === 'formats') {
        switch (values.formatType) {
          case 'currency': {
            const currencyPayload = {
              project_id: projectId,
              column_name: column.id,
              dataset_id: dataset?.id,
              value_error: values.valueError,
              format_type: values.formatType,
              exchange_value: values.conversion_rate,
              new_column_name: values.new_column_name,
            };
            standardisationError(
              'STANDARDISATION_ERROR_CURRENCY',
              currencyPayload
            ).then(() => {
              fetchTasks(dataset?.id, projectId);
              SwalToast({
                icon: 'success',
                title: EXECUTION_SCHEDULED_MESSAGE,
              });
            });
            break;
          }
          case 'date': {
            const datePayload = {
              project_id: projectId,
              column_name: column.id,
              dataset_id: dataset?.id,
              value_error: values.valueError,
              format_type: values.formatType,
              from_format: values.fromFormat,
              to_format: values.toFormat,
            };
            standardisationError(
              'STANDARDISATION_ERROR_DATE',
              datePayload
            ).then(() => {
              fetchTasks(dataset?.id, projectId);
              SwalToast({
                icon: 'success',
                title: EXECUTION_SCHEDULED_MESSAGE,
              });
            });
            break;
          }
          case 'email': {
            const commonPayload = {
              project_id: projectId,
              column_name: column.id,
              dataset_id: dataset?.id,
              value_error: values.valueError,
              format_type: values.formatType,
            };
            standardisationError(
              values.formatType === 'email'
                ? 'STANDARDISATION_ERROR_EMAIL'
                : 'STANDARDISATION_ERROR_PHONE',
              commonPayload
            ).then(() => {
              fetchTasks(dataset?.id, projectId);
              SwalToast({
                icon: 'success',
                title: EXECUTION_SCHEDULED_MESSAGE,
              });
            });
            break;
          }

          default:
            break;
        }
      } else {
        const unitsPayload = {
          project_id: projectId,
          column_name: column.id,
          dataset_id: dataset?.id,
          value_error: values.valueError,
          unit_type: values.unit_type,
          converting_from: values.converting_from,
          converting_to: values.converting_to,
          new_column_name: values.new_column_name,
          primary_key: values.primary_key,
        };
        standardisationError('STANDARDISATION_ERROR_UNIT', unitsPayload).then(
          () => {
            fetchTasks(dataset?.id, projectId);
            SwalToast({
              icon: 'success',
              title: EXECUTION_SCHEDULED_MESSAGE,
            });
          }
        );
      }
    },
    onScoresCallback: (values, { column }) => {
      resetGridTaskResultsData();
      if (values.selectedOperation === 'rsspds') {
        const rsspdsPayload = {
          project_id: projectId,
          column_name: column.id,
          dataset_id: dataset?.id,
          scores: values.scores,
        };
        scores('SCORES_RSSPDS', rsspdsPayload).then(() => {
          fetchTasks(dataset?.id, projectId);
          SwalToast({
            icon: 'success',
            title: EXECUTION_SCHEDULED_MESSAGE,
          });
        });
      } else {
        const dfcsPayload = {
          project_id: projectId,
          column_name: column.id,
          dataset_id: dataset?.id,
        };
        scores('SCORES_DFCS', dfcsPayload).then(() => {
          fetchTasks(dataset?.id, projectId);
          SwalToast({
            icon: 'success',
            title: EXECUTION_SCHEDULED_MESSAGE,
          });
        });
      }
    },
    onIntegrityRulesCallback: (values, { column }) => {
      resetGridTaskResultsData();
      if (values.integrityType === 'incorrect') {
        const incorrectPayload = {
          project_id: projectId,
          column_name: column.id,
          dataset_id: dataset?.id,
          integrity_type: values.integrityType,
          values: values.incorrect_values,
        };
        integrityRules(
          'INTEGRITY_RULES_INCORRECT_VALUES',
          incorrectPayload
        ).then(() => {
          fetchTasks(dataset?.id, projectId);
          SwalToast({
            icon: 'success',
            title: EXECUTION_SCHEDULED_MESSAGE,
          });
        });
      } else {
        const referentialPayload = {
          project_id: projectId,
          /* Creating a new variable called parent_ and setting it equal to the value of the variable
                parent. */
          dataset_id_one: dataset?.id,
          primary_key: values.primary_key,
          dataset_id_two: dataSets.find(
            (data) => data.name === values.base_table
          ).id,
          foreign_key: values.foreign_key,
          dataset_id: dataset?.id,
          column_name: column.id,
        };
        integrityRules('INTEGRITY_RULES_RERERENTIAL', referentialPayload).then(
          () => {
            fetchTasks(dataset?.id, projectId);
            SwalToast({
              icon: 'success',
              title: EXECUTION_SCHEDULED_MESSAGE,
            });
          }
        );
      }
    },
    onFindDulicateColumnsCallback: (_values, { column, state }) => {
      resetGridTaskResultsData();
      const findDuplicateColumnsPayload = {
        project_id: projectId,
        column_name: column.id,
        dataset_id: dataset?.id,
      };
      findDuplicateColumns(findDuplicateColumnsPayload).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onFindDulicateRowsCallback: ({ row }) => {
      resetGridTaskResultsData();
      const findDuplicateRowsPayload = {
        project_id: projectId,
        row_id: row?.original?.pk,
        dataset_id: dataset?.id,
      };
      findDuplicateRows(findDuplicateRowsPayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },

    onFindAndDeleteDuplicateRowsCallback: (values, { state }) => {
      resetGridTaskResultsData();
      const findAndDeleteDuplicateRowsPayload = {
        project_id: projectId,
        ...values,
        dataset_id: dataset?.id,
      };
      findAndDeleteDuplicateRows(
        findAndDeleteDuplicateRowsPayload,
        dataset?.id
      ).then(() => {
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },

    onFindAndDeleteDuplicateColumnsCallback: (values) => {
      resetGridTaskResultsData();
      const findAndDeleteDuplicateColumnsPayload = {
        project_id: projectId,
        ...values,
        dataset_id: dataset?.id,
      };
      findAndDeleteDuplicateColumns(
        findAndDeleteDuplicateColumnsPayload,
        dataset?.id
      ).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onValueErrorCallback: (values) => {
      valueErrorCallback(values); // calling Value Error function
    },
    onFindBlankValuePercentageCallback: ({ column }) => {
      resetGridTaskResultsData({});
      const findBlankValuePercentagePayload = {
        project_id: projectId,
        column_name: column.id,
        dataset_id: dataset?.id,
      };
      findBlankValuePercentage(findBlankValuePercentagePayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onReplaceBlankValuesCallback: (values, { column, state }) => {
      resetGridTaskResultsData({});
      const replaceBlankValuesPayload = {
        project_id: projectId,
        dataset_id: dataset?.id,
        column_name: column.id,
        replace_with: values.replaceWith,
        is_custom: values.isCustom,
        custom_value: values.customValue,
      };
      replaceBlankValues(replaceBlankValuesPayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        fetchDatasetRowsWithPaginationWrapper(
          dataset?.id,
          state.pageIndex,
          state.pageSize
        );
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
    onFindRegexPercentageCallback: (values, { column }) => {
      resetGridTaskResultsData({});
      const findRegexPercentagePayload = {
        project_id: projectId,
        column_name: column.id,
        dataset_id: dataset?.id,
        regex: values.regex,
      };
      findRegexPercentage(findRegexPercentagePayload).then(() => {
        fetchTasks(dataset?.id, projectId);
        SwalToast({
          icon: 'success',
          title: EXECUTION_SCHEDULED_MESSAGE,
        });
      });
    },
  };

  const fetchColumnMetaData = React.useCallback(
    (payload, successCallback, errorCallback) => {
      const newPayload = { ...payload };
      newPayload.dataset_id = dataset.id;
      delete newPayload?.table_name;
      axios
        .post(
          ApiEndpoints.MODULE_BASE_URL.DI +
            ApiEndpoints.DI.DATASET_COLUMN_DETAILS,
          newPayload,
          null
        )
        .then(
          (response) => {
            const sortOrder = Object.keys(datasetRowsWithPagination?.[0] || {});
            response.data.columns.sort((a, b) => {
              return (
                sortOrder.indexOf(a.column_name) -
                sortOrder.indexOf(b.column_name)
              );
            });
            successCallback(response);
            return response;
          },
          (error) => {
            errorCallback(error);
          }
        );
    },
    [dataSets]
  );

  useEffect(() => {
    if (datasetRowsWithPagination.length !== 0) {
      const dynamicTableHeaders = utils.tableDataColumnCalculator(
        datasetRowsWithPagination,
        40,
        isReadOnly ? null : EditableCell,
        isReadOnly
          ? ReactMuiTableColumnHeaderTextEllipsis
          : ReactMuiColumnHeaderWithOptions,
        columnOptionsCallbacks,
        isReadOnly ? ({ value }) => value : ReactMuiCellWithOptions,
        datasetList,
        dataset?.name,
        fetchColumnMetaData,
        projectId
      );
      setTableColumns(
        sortBy(dynamicTableHeaders, ({ id }) => (id === 'pk' ? 0 : 1))
      ); // for non-serialized rows
    } else {
      setTableColumns([]);
    }
    //
  }, [datasetRowsWithPagination, dataset]);
  const onDownloadCSV = (commentid = null) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.DM + ApiEndpoints.GRID.DOWNLOAD_GRID_CSV,
        {
          result_dataset_id: commentid[0],
          dataset_id: dataset?.id,
          project_id: activeProject?.project_id,
          module: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key,
          sub_module: MODULE_SUB_MODULE_OPERATIONS_TREE.DM.subModules.GR.key,
        }
      )
      .then(
        (response) => {
          SwalToast({
            icon: 'success',
            title:
              'Started preparing download link. Please check Downloads page for further details.',
          });
          return response;
        },
        (err) => {
          throw err;
        }
      );

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  /**
   * @function updateMyData
   * @description To update MyData
   * @param {number} rowIndex  -  A index of datasetRowsWithPagination's row
   * @param {number} columnId - A column of datasetRowsWithPagination
   * @param {number} value  - A number which is used to update the value of MyData
   * @returns {void}
   * @example <updateMyData rowIndex={rowIndex}/>
   */
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true)

    const row = datasetRowsWithPagination.filter(
      (_item, index) => index === rowIndex
    )[0];

    if (row[columnId] !== value) {
      updateDatasetCells(
        [
          {
            pk: row.pk ? row.pk : rowIndex,
            column_name: columnId,
            value,
            dataset_id: dataset?.id,
            project_id: projectId,
          },
        ],
        () => {
          setDatasetRowsWithPagination((old) =>
            old.map((nrow, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return nrow;
            })
          );
          SwalToast({
            icon: 'success',
            title: EXECUTION_SCHEDULED_MESSAGE,
          });
        },
        () => {}
      );
    }
  };

  // datasetId, pageSize = 500, pageIndex = 0
  const fetchDatasetRows = React.useCallback(
    (pageIndex = 0, pageSize = INITIAL_PAGE_SIZE) => {
      if (dataset) {
        fetchDatasetRowsWithPaginationWrapper(dataset?.id, pageIndex, pageSize);
        // console.log(searchString, searchText, Boolean(searchText));
        // if (searchString === searchText || searchText.contains(searchString)) {
        //   // do nothing
        // } else {
        //   fetchDatasetRowsWithPaginationWrapper(dataset?.id, pageIndex, pageSize);
        // }
      }

      if (gridTaskResultsData?.isPaginated && gridTaskResultsData?.allData) {
        fetchGridTaskData(
          gridTaskResultsData?.allData,
          null,
          pageIndex,
          pageSize
        );
      }
    },
    [dataset, gridTaskResultsData?.allData]
  );

  /**
   * @function onRefreshClick
   * @description To refresh the table data
   * @param {object} data  -  A object which contains the page index and page size
   * @returns {void}
   */
  const onRefreshClick = (data) => {
    fetchDatasetRowsWithPaginationWrapper(
      dataset?.id,
      data.pageIndex,
      data.pageSize
    );
  };

  // added support for showing page number

  /**
   * @function defaultLabelDisplayedRows
   * @description To display the page number
   * @param {object} from  -  A object which contains the page index and page size
   * @param {object} to  -  A object which contains the page index and page size
   * @param {object} count  -  A object which contains the page index and page size
   * @param {object} page  -  A object which contains the page index and page size
   * @returns {void}
   */

  const defaultLabelDisplayedRows = ({ from, to, count, page: _p }) => {
    return `${from}–${to} of ${
      count !== -1 ? count : `more than ${to}`
    },  Page: ${_p + 1}`;
  };

  return (
    <Box mx={0}>
      {permission !== 'None' ? (
        <Box p={0}>
          <Grid spacing={2} direction="column">
            {gridTaskResultsData?.gridTaskResults?.results && (
              <Grid item xs={12}>
                <Box mt={2} mb={0}>
                  <GridTasksTableAndCharts
                    activeDataset={dataset}
                    gridTaskResults={gridTaskResultsData?.gridTaskResults}
                    operationName={gridTaskResultsData?.taskOperationName}
                    permission={permission}
                    fetchTableDataResult={fetchTableDataResult}
                    onDownloadCSV={onDownloadCSV}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={11}>
              <Box mb={2}>
                {tableColumns?.length > 200 ? (
                  <ReactMuiTableVirtualized
                    updateMyData={updateMyData}
                    onRefreshClick={onRefreshClick}
                    skipPageReset
                    fetchColumnMetaData={fetchColumnMetaData}
                    onDownloadCSV={onDownloadCSV}
                    columns={tableColumns}
                    data={datasetRowsWithPagination}
                    showOptions
                    enablePagination
                    enableVirtualization
                    dataSetName={dataset?.name ? dataset.name : ''}
                    projectId={
                      dataset?.project_id?.[0] ? dataset?.project_id?.[0] : ''
                    }
                    fetchData={fetchDatasetRows}
                    enableControlledPagination
                    enableManualFilter={false}
                    loading={false}
                    pageCount={datasetRowsPageCount}
                    rowsPerPageOptions={[500, 1000, 2000]}
                    initialPageSize={INITIAL_PAGE_SIZE}
                    dataLength={Number(totalDatasetRows)}
                    uniqueId={`dataset-table-${dataset?.id}`}
                    highlightCells={gridTaskResultsData?.highlightCells}
                    isAllOptionDisabled
                    permission={permission}
                    getColumnProps={getVirtualizedColumnProps}
                    showPaginationAction={false}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    initialGlobalFilter={searchString}
                    onTableStateChange={handleTableStateChange}
                    showToolbar={showToolbar}
                    NoDataFoundComponent={NoDataFoundComponent}
                  />
                ) : (
                  <ReactMuiTable
                    updateMyData={updateMyData}
                    onRefreshClick={onRefreshClick}
                    skipPageReset
                    fetchColumnMetaData={fetchColumnMetaData}
                    onDownloadCSV={onDownloadCSV}
                    columns={tableColumns}
                    data={datasetRowsWithPagination}
                    showOptions
                    enablePagination
                    enableVirtualization
                    dataSetName={dataset?.name ? dataset.name : ''}
                    projectId={
                      dataset?.project_id?.[0] ? dataset?.project_id?.[0] : ''
                    }
                    fetchData={fetchDatasetRows}
                    enableControlledPagination
                    enableManualFilter={false}
                    loading={false}
                    pageCount={datasetRowsPageCount}
                    rowsPerPageOptions={[500, 1000, 2000]}
                    initialPageSize={500}
                    dataLength={Number(totalDatasetRows)}
                    uniqueId={`dataset-table-${dataset?.id}`}
                    highlightCells={gridTaskResultsData?.highlightCells}
                    isAllOptionDisabled
                    permission={permission}
                    getColumnProps={getColumnProps}
                    showPaginationAction={false}
                    labelDisplayedRows={defaultLabelDisplayedRows}
                    initialGlobalFilter={searchString}
                    onTableStateChange={handleTableStateChange}
                    showToolbar={showToolbar}
                    NoDataFoundComponent={NoDataFoundComponent}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <>
            <Zoom in={true} mountOnEnter unmountOnExit>
              <Box
                display="flex"
                flexGrow={1}
                style={{
                  minHeight: 'calc(100vh - 300px)',
                }}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <DoNotDisturbOutlinedIcon
                  sx={{
                    width: '150px',
                    height: '150px',
                    marginLeft: '5px',
                  }}
                  color="primary"
                />
                <br />
                <Typography variant="subtitle1">
                  You are not authorized to access this!
                </Typography>
              </Box>
            </Zoom>
          </>
        </Box>
      )}
    </Box>
  );
};

DatasetTable.defaultProps = {
  isReadOnly: false,
  showToolbar: true,
  gridTaskResultsData: {},
  duplicateColumn: () => {},
  renameColumn: () => {},
  deleteColumn: () => {},
  addColumn: () => {},
  deleteRow: () => {},
  duplicateRow: () => {},
  addRow: () => {},
  fetchTasks: () => {},
  manageSpaces: () => {},
  manageCases: () => {},
  splitColumn: () => {},
  findAndReplace: () => {},
  sortColumn: () => {},
  analyse: () => {},
  embeddedValues: () => {},
  standardisationError: () => {},
  integrityRules: () => {},
  uniqueness: () => {},
  scores: () => {},
  findDuplicateRows: () => {},
  findDuplicateColumns: () => {},
  findAndDeleteDuplicateRows: () => {},
  findAndDeleteDuplicateColumns: () => {},
  resetGridTaskResultsData: () => {},
  valueError: () => {},
  findBlankValuePercentage: () => {},
  replaceBlankValues: () => {},
  findRegexPercentage: () => {},
};
DatasetTable.propTypes = {
  updateDatasetCells: PropTypes.func.isRequired,
  fetchDatasetRowsWithPagination: PropTypes.func.isRequired,
  dataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  renameColumn: PropTypes.func,
  duplicateColumn: PropTypes.func,
  deleteColumn: PropTypes.func,
  addColumn: PropTypes.func,
  deleteRow: PropTypes.func,
  duplicateRow: PropTypes.func,
  addRow: PropTypes.func,
  fetchTasks: PropTypes.func,
  projectId: PropTypes.number.isRequired,
  manageSpaces: PropTypes.func,
  manageCases: PropTypes.func,
  splitColumn: PropTypes.func,
  findAndReplace: PropTypes.func,
  sortColumn: PropTypes.func,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dataSets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  analyse: PropTypes.func,
  embeddedValues: PropTypes.func,
  standardisationError: PropTypes.func,
  integrityRules: PropTypes.func,
  uniqueness: PropTypes.func,
  scores: PropTypes.func,
  findDuplicateRows: PropTypes.func,
  findDuplicateColumns: PropTypes.func,
  findAndDeleteDuplicateRows: PropTypes.func,
  findAndDeleteDuplicateColumns: PropTypes.func,
  gridTaskResultsData: PropTypes.oneOfType([PropTypes.object]),
  resetGridTaskResultsData: PropTypes.func,
  valueError: PropTypes.func,
  findBlankValuePercentage: PropTypes.func,
  replaceBlankValues: PropTypes.func,
  findRegexPercentage: PropTypes.func,
  permission: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  fetchGridTaskData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  fetchTableDataResult: PropTypes.func,
  showToolbar: PropTypes.bool,
};
export default DatasetTable;
