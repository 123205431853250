// https://material-ui.com/customization/default-theme/?expand-path=$.palette
// https://material-ui.com/customization/palette/

const BORDER_NONE_IMPORTANT = 'none !important';

const theme = {
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontSize: '26px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '29.96px',
    },
    h3: {
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '29.96px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '21.79px',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 600,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '19.07px',
    },
    body3: {
      fontSize: '12px',
      fontWeight: 600,
    },
    body4: {
      fontSize: '12px',
      fontWeight: 400,
    },
    body5: {
      fontSize: '10px',
      fontWeight: 400,
    },
    body6: {
      fontSize: '8px',
      fontWeight: 400,
    },
    body7: {
      fontSize: '10px',
      fontWeight: 600,
    },
    button: {
      fontSize: '16px',
      fontWeight: 400,
    },
  },
  zIndex: {
    appBar: 1201,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          transition: 'all .4s ease-in-out',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'div',
          h2: 'div',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'span',
          body2: 'span',
        },
        color: 'text.primary',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        paper: {
          marginTop: '5px',
        },
        option: {
          fontWeight: '400',
          margin: '5px',
          minHeight: '40px !important',
          borderRadius: '4px',
        },
        endAdornment: {
          top: '20px',
          right: '21px !important',
          alighItems: 'center',
        },
        inputRoot: {
          paddingTop: '4px !important',
          paddingBottom: '4px !important',
          minHeight: '46px !important',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        select: {
          paddingTop: '12px',
          paddingBottom: '12px',
          display: 'block',
          textOverflow: 'ellipsis',
          // borderBottom: BORDER_NONE_IMPORTANT,
          // boxShadow: 'none',
          fontSize: 14,
          whiteSpace: 'nowrap',
          borderRadius: '6px',
          '&:focus': {
            borderRadius: '6px',
          },
          '&:before': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
          '&:after': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
        },
        icon: {
          right: '20px',
          top: 'calc(50% - 0.2em)',
        },
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '58px !important',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
          '&:after': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
          '&:after': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
          '&:before': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
        },
        underline: {
          '& :before': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
          '& :after': {
            borderBottom: BORDER_NONE_IMPORTANT,
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            pointerEvents: 'auto',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        autoComplete: 'off',
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          marginTop: '22px',

          '& input.MuiInputBase-input': {
            paddingTop: '12px',
            paddingBottom: '12px',
          },
          '& .MuiInputBase-multiline': {
            paddingTop: '12px',
            paddingBottom: '12px',
          },
          '& .MuiInputLabel-root': {
            transform: 'none',
            top: '-24px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: '-1px',
            legend: {
              display: 'none',
            },
          },

          // '& .MuiInputLabel-shrink': {
          //   lineHeight: '15px',
          // },
          // '& .MuiFilledInput-root:after': {
          //   borderBottom: BORDER_NONE_IMPORTANT,
          // },
          '& .MuiOutlinedInput-root': {
            borderRadius: '6px',
            // '&:hover:not(.Mui-disabled):before': {
            //   borderBottom: BORDER_NONE_IMPORTANT,
            // },
            // '&:before': {
            //   borderBottom: BORDER_NONE_IMPORTANT,
            // },
          },
          // '& .MuiFilledInput-underline': {
          //   '& :before': {
          //     borderBottom: BORDER_NONE_IMPORTANT,
          //   },
          //   '& :after': {
          //     borderBottom: BORDER_NONE_IMPORTANT,
          //   },
          // },
          // '& .MuiFilledInput-input': {
          //   lineHeight: '19.07px',
          //   fontWeight: '500',
          //   fontStyle: 'normal',
          // },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: 'normal',
          height: '40px',
          boxShadow: BORDER_NONE_IMPORTANT,
          '&.Mui-disabled': {
            pointerEvents: 'auto',
          },
          textTransform: 'capitalize',
          padding: '4px 16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: '400',
          lineHeight: '19.07px',
          marginLeft: '0px',
          marginTop: '2px',
          paddingLeft: '0px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '42px',
          borderBottom: '1px solid',
        },
        indicator: {
          height: '3px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 600,
          textTransform: 'capitalize',
          paddingTop: 0,
          paddingBottom: '16px',
          paddingLeft: '12px',
          paddingRight: '12px',
          whiteSpace: 'nowrap',
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '180px',
          flexDirection: 'initial',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          padding: '2px 2px 2px 2px !important',
          lineHeight: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'white !important',
          userSelect: 'none',
          fontSize: '12px !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          textTransform: 'uppercase',
          '&:last-child': {
            borderRadius: '0 4px 0 0',
          },
          '&:first-of-type': {
            borderRadius: '4px 0 0 0',
          },
        },
        root: {
          borderBottom: 'solid 1px',
          minHeight: 25,
          fontWeight: 400,
          fontSize: '16px !important',
        },
        sizeSmall: {
          padding: '7px 7px 7px 7px !important',
          borderRight: 'solid 1px',
          wordBreak: 'break-all',
          '&:last-child': {
            paddingRight: 4,
            borderRight: 'none',
          },
          input: {
            fontFamily: 'Open Sans, sans-serif',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          borderLeft: '1px',
          borderRight: '1px',
          borderBottom: '2px',
          borderTop: 0,
          borderStyle: 'solid',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableBody-root *:hover::-webkit-scrollbar': {
            width: '10px !important',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
          display: 'block',
          borderBottom: 0,
          '& p': {
            margin: 0,
          },
        },
        caption: {
          userSelect: 'none',
        },
        select: {
          borderRadius: 2,
          paddingX: 0,
          fontSize: '14px',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'sm',
        fullWidth: true,
      },
      styleOverrides: {
        paper: {
          padding: 0,
          borderRadius: '4px',
          // padding: "22.05px",
          '& .mui-dialog-close-icon-btn': {},
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: '16px',
          fontStyle: 'normal',
          lineHeight: 'normal',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '24px',
          paddingBottom: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // justifyContent: 'center',
          paddingTop: '12px',
          paddingBottom: '10px',
          paddingRight: '24px',
          paddingLeft: '24px',
        },
      },
    },
    // MuiSelect: {
    // 	styleOverrides: {
    // 		select: {
    // 			display: 'block',
    // 			borderBottom: '1px solid #0BB7A7',
    // 			boxShadow: 'none',
    // 			fontSize: 14,
    // 			whiteSpace: 'nowrap',
    // 			textOverflow: 'ellipsis',
    // 		},
    // 	},
    // },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          minWidth: '100%',
          display: 'flex !important',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 400,
          fontStyle: 'normal',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // fontSize: 13,
          // boxShadow: 'none',
          // selected: {},
          // padding: '0px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '& .MuiListItemButton-root': {
            '& .list-item-button-hover-actions-right': {
              display: 'none',
              position: 'absolute',
              right: '0',
            },
            '&:hover': {
              '& .list-item-button-hover-actions-right': {
                display: 'block',
              },
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          boxShadow: 'none',
          selected: {},
          padding: '10px',
          '&:hover': {
            fontWeight: '700',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          height: '8px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: 0,
          paddingLeft: '13px',
          paddingRight: '13px',
          paddingTop: '6px',
          paddingBottom: '7px',
          minHeight: 32,
        },
        label: {
          fontSize: 14,
          lineHeight: '19.7px',
          fontWeight: 400,
          fontStyle: 'normal',
          maxWidth: '180px',
        },
        colorSecondary: {
          borderWidth: '1px',
          borderStyle: 'solid',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '20px',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          height: '14px !important',
          width: '34px !important',
          borderRadius: '7px',
        },
        thumb: {
          height: '20px !important',
          width: '20px !important',
          border: '1px solid',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '4px',
        },
        mark: {
          height: '4px',
          width: '4px',
          borderRadius: '2px',
        },
        valueLabel: {
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: 400,
          letterSpacing: '0.25px',
        },
        markLabel: {
          fontSize: '10px',
          lineHeight: '20px',
          fontWeight: '600 !important',
          letterSpacing: '0.25px',
        },
        thumb: {
          height: 20,
          width: 20,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
};

export default theme;
