/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from 'react-contexify';

import 'react-contexify/dist/ReactContexify.css';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '--contexify-activeItem-bgColor': `${theme?.palette?.secondary?.light}`,
  '--contexify-activeItem-color': theme.palette?.primary?.main,
  '--contexify-item-color': theme.palette?.primary?.main,
  '--contexify-menu-bgColor': theme.palette?.other?.white,
  '--contexify-activeArrow-color': theme.palette?.primary?.main,
  '--contexify-menu-shadow': `0px 6px 12px ${alpha(
    theme.palette.other?.lightBrandBlueLogin,
    0.31
  )}`,
}));

const RecursiveMenu = ({
  menus = [],
  menuId,
  onMenuClick,
  isSubMenu = false,
  subMenuDetails = null,
  additionalData = null,
}) => {
  return isSubMenu ? (
    <Submenu
      label={
        <Box display="flex" alignItems="center">
          {subMenuDetails.icon ? <subMenuDetails.icon color="primary" /> : null}
          <Box ml={2} display="flex" flexGrow={1}>
            <Typography>{subMenuDetails?.label}</Typography>
          </Box>
        </Box>
      }
      disabled={subMenuDetails?.isMenuDisabled}
    >
      {menus.map((menu, _index) =>
        menu.subMenus?.length ? (
          <RecursiveMenu
            menus={menu.subMenu}
            isSubMenu={true}
            onMenuClick
            menuId
            key={`${menu.id}_${menu.label}`}
          />
        ) : (
          <Item
            id={menu.id}
            onClick={onMenuClick}
            key={`${menu.id}_${menu.label}`}
            data={menu}
            disabled={menu.isMenuDisabled}
          >
            <Box display="flex" alignItems="center">
              {menu?.icon ? <menu.icon color="primary" /> : null}
              <Box ml={2} display="flex" flexGrow={1}>
                <Typography>{menu.label}</Typography>
              </Box>
            </Box>
          </Item>
        )
      )}
    </Submenu>
  ) : (
    <StyledMenu id={menuId}>
      {menus.map((menu, _index) =>
        menu.subMenus?.length ? (
          <RecursiveMenu
            menus={menu.subMenus}
            isSubMenu={true}
            subMenuDetails={menu}
            onMenuClick={onMenuClick}
            menuId={menuId}
            key={`${menu.id}_${menu.label}`}
          />
        ) : (
          <Item
            id={menu.id}
            onClick={onMenuClick}
            data={menu}
            key={`${menu.id}_${menu.label}`}
            disabled={menu.isMenuDisabled}
          >
            <Box display="flex" alignItems="center">
              {menu?.icon ? (
                <menu.icon color="primary" width={21} height={21} />
              ) : null}
              <Box ml={2} display="flex" flexGrow={1}>
                <Typography>{menu.label}</Typography>
              </Box>
            </Box>
          </Item>
        )
      )}
    </StyledMenu>
  );
};

export default RecursiveMenu;

export const ContextMenus = ({
  menus = [],
  additionalData = {},
  handleMenuClick,
  menuId,
}) => {
  // do not remove this
  // you can use this hook from everywhere. All you need is the menu id
  // const { show } = useContextMenu({
  //   id: menuId,
  // });

  function handleItemClick(menuClickEventData) {
    if (handleMenuClick) {
      handleMenuClick(menuClickEventData);
    } else {
      menuClickEventData.data?.onClick(menuClickEventData);
    }
  }
  // do not remove this
  // function displayMenu(e) {
  //   // put whatever custom logic you need
  //   // you can even decide to not display the Menu
  //   show({
  //     id: menuId,
  //     event: e,
  //     props: { ...additionalData },
  //   });
  // }
  // {/* <div onContextMenu={displayMenu}>Right click inside the box</div> */
  //   <Menu id={MENU_ID}>
  //         <Item onClick={handleItemClick}>Item 1</Item>
  //         <Item onClick={handleItemClick}>Item 2</Item>
  //         <Separator />
  //         <Item disabled>Disabled</Item>
  //         <Separator />
  //         <Submenu label="Submenu">
  //           <Item onClick={handleItemClick}>Sub Item 1</Item>
  //           <Item onClick={handleItemClick}>Sub Item 2</Item>
  //         </Submenu>
  //       </Menu> */
  //    */}
  return (
    <RecursiveMenu
      menus={menus}
      onMenuClick={handleItemClick}
      menuId={menuId}
      isSubMenu={false}
      additionalData={additionalData}
    />
  );
};
