/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import axios from 'axios';
import { SwalToast } from '../components/Common';
import API_ENDPOINTS from '../const/ApiEndpoints';
import utils from './utils';

const LOADER_HIDE_DELAY = 400;
const UNAUTHORIZED_RESPONSE_CODE = 401;
window.REFRESH_TOKEN_IN_PROGRESS = false;
window.TOKEN_REFRESHED_RECENTLY = false;

const waitAndCheckIfRefreshTokenIsDone = (resolve) => {
  console.log('waiting for token refresh..');
  setTimeout(() => {
    const refresh = localStorage.getItem('refresh_token');
    const access = localStorage.getItem('access_token');
    if (refresh && access) {
      resolve({
        data: {
          refresh: localStorage.getItem('refresh_token'),
          access: localStorage.getItem('access_token'),
        },
      });
    } else {
      waitAndCheckIfRefreshTokenIsDone(resolve);
    }
  }, 3000);
};

/**
 * @function refreshAccessToken
 * @description Refresh access token
 * @param {string} refreshToken - Refresh token
 * @returns {object} - Axios promise
 */

const refreshAccessToken = (refreshToken) => {
  if (window.REFRESH_TOKEN_IN_PROGRESS || window.TOKEN_REFRESHED_RECENTLY) {
    return new Promise((resolve) => {
      waitAndCheckIfRefreshTokenIsDone(resolve);
    });
  }
  window.REFRESH_TOKEN_IN_PROGRESS = true;
  return axios
    .post(
      `${API_ENDPOINTS.MODULE_BASE_URL.DI + API_ENDPOINTS.AUTH.REFRESH_TOKEN}`,
      {
        refresh: refreshToken,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          org: `${process.env.REACT_APP_ORG_NAME}`,
        },
      }
    )
    .then(
      (data) => {
        window.REFRESH_TOKEN_IN_PROGRESS = false;
        window.TOKEN_REFRESHED_RECENTLY = true;
        setTimeout(() => {
          window.TOKEN_REFRESHED_RECENTLY = false;
        }, [4000]);
        return data;
      },
      (err) => {
        window.REFRESH_TOKEN_IN_PROGRESS = false;
        window.TOKEN_REFRESHED_RECENTLY = false;
        throw err;
      }
    );
};

const logout = () => {
  try {
    const { activeProject, userId, userAndIdDetails } =
      utils.getUserDetailsFromStorage();
    if (activeProject) {
      userAndIdDetails.set(userId, activeProject);
    }
    localStorage.clear();
    window?.cookieStore?.delete('RECENTLY_CLICKED_NOTIFICATION_DATA');
    if (userAndIdDetails.size > 0) {
      localStorage.setItem(
        'USER_ACTIVE_PROJECT_LIST',
        JSON.stringify(Array.from(userAndIdDetails.entries()))
      );
    }
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const inializeAxios = () => {
  const showLoaderEvent = new Event('showLoader', { bubbles: true });
  const hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  const headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  let offlineSnack = '';
  window.addEventListener('offline', () => {
    offlineSnack = window.enqueueSnackbar('You are working in offline mode.', {
      variant: 'info',
      preventDuplicate: true,
      persist: true,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      action: () => {},
    });
  });
  window.addEventListener('online', () => {
    if (offlineSnack) {
      window.closeSnackbar(offlineSnack);
    }
  });
  axios.defaults.headers.common = headersCommonOptions;
  axios.interceptors.request.use((req) => {
    numberOfApiCallsPending += 1;
    if (req.loader !== false) {
      document.dispatchEvent(showLoaderEvent);
    }
    if (
      req.url ===
      `${API_ENDPOINTS.MODULE_BASE_URL.DI + API_ENDPOINTS.AUTH.REFRESH_TOKEN}`
    ) {
      return req;
    }
    const token = localStorage.getItem('access_token');
    if (utils.hostNeedsToken(token, req.url)) {
      req.headers.Authorization = `JWT ${token}`;
    }
    if (utils.hostNeedsOrg(req.url)) {
      req.headers.org = `${process.env.REACT_APP_ORG_NAME}`;
    }
    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, LOADER_HIDE_DELAY);
      }
      return response;
    },
    async (error) => {
      numberOfApiCallsPending -= 1;
      const errorMessage = error?.response?.data?.msg
        ? typeof error?.response?.data?.msg === 'string'
          ? error.response.data.msg
          : JSON.stringify(error.response.data.msg)
        : error.message;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, LOADER_HIDE_DELAY);
      }

      if (
        error.config.url ===
        API_ENDPOINTS.MODULE_BASE_URL.DI + API_ENDPOINTS.AUTH.REFRESH_TOKEN
      ) {
        logout();
        return Promise.reject(error);
      }
      if (
        error.response?.status === UNAUTHORIZED_RESPONSE_CODE &&
        !error?.config?._retry
      ) {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          SwalToast({
            icon: 'info',
            // eslint-disable-next-line no-nested-ternary
            title:
              'Please wait your token might have expired. We are trying to refresh the token and resend the request.',
          });
        } else {
          logout();
          return Promise.reject(error);
        }
        error.config._retry = true;
        const res = await refreshAccessToken(refreshToken);
        if (res?.data?.refresh && res?.data?.access) {
          SwalToast({
            icon: 'success',
            // eslint-disable-next-line no-nested-ternary
            title: 'Token refreshed successfully. Resending API requests.',
          });
          localStorage.setItem('refresh_token', res?.data?.refresh);
          localStorage.setItem('access_token', res?.data?.access);
          return axios.request(error.config);
        }
        SwalToast({
          icon: 'error',
          // eslint-disable-next-line no-nested-ternary
          title: 'Failed to refresh the token. Invalid API response.',
        });
        logout();
        return Promise.reject(error);
      }
      // This condition for intercepting API errors for which we need to show yellow/info type toast message.
      if (error?.response?.headers?.['x-info'] === 'True') {
        SwalToast({
          icon: 'info',
          title: errorMessage,
        });
      }
      SwalToast({
        icon: 'error',
        title: errorMessage,
      });
      return Promise.reject(error);
    }
  );
};
export default inializeAxios;
