/* eslint-disable */
// @ts-nocheck
import {
  Grid,
  Typography,
  Box,
  Card,
  CardHeader,
  // List,
  // ListItem,
  Alert,
  Zoom,
  Button,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withTransaction } from '@elastic/apm-rum-react';
import useAuth from '../../Hooks/Auth/useAuth';
// import Gif from '../../assets/animated/BlueGifLogin.gif';
import useQueryParams from '../../Hooks/useQueryParams';
import { ReactComponent as NimbusLogo } from '../../assets/images/NIMBUS Uno.svg';
import LoginPageAnimation from '../../components/Login/LoginPageAnimation';
import REGEX from '../../const/Regex';
import Particles from 'react-particles';
import { loadSlim } from 'tsparticles-slim';
import { SwalToast } from 'components/Common';

/**
 * @function Login
 * @description The login page for the application
 * @params {object} history - the history object used to navigate between pages
 * @returns {React.Component} - React component
 */
const Login = ({ history }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const queryParams = useQueryParams();
  const { user, login, forgetPassword, resetPassword, adLogin } = useAuth();
  const [forgetPasswordForm, setforgetPasswordForm] = useState(false);
  // const [hiddenLoginPassword, sethiddenLoginPassword] = useState(true);
  const [hiddenPassword, sethiddenPassword] = useState(true);
  const [hidden, sethidden] = useState(true);
  const [openResettingPasswordModal, setResettingPasswordModal] =
    useState(false);
  const [successMessage, updateSuccessMessage] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');
  // forget email form
  const formForgetValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required'),
  });
  const ForgetForm = useFormik({
    initialValues: {
      email: '',
    },
    enableReinitialize: true,
    validationSchema: formForgetValidationSchema,
    onSubmit: (values) => {
      updateSuccessMessage('');
      updateErrorMessage('');
      const body = {
        email: values.email,
        domain: window.location.href.split('/')[2],
      };
      forgetPassword(body).then(
        (res) => updateSuccessMessage(res.data.success),
        (error) => updateErrorMessage(error.response.data.failed)
      );
    },
  });

  /**
   * @function handleAdLogin
   * @description handles the login with AD button click
   * @returns {void}
   */
  const handleAdLogin = () => {
    adLogin().then(() => {
      history.push('/');
    });
  };

  // reset password form
  const ResetPasswordformValidationSchema = Yup.object({
    passwordReset: Yup.string()
      .required('Required')
      .min(8, 'Password must contain at least 8 characters.')
      .matches(
        REGEX.AT_LEAST_A_CAP_LLTR,
        'Password must contain at least one capital letter.'
      )
      .matches(
        REGEX.AT_LEAST_A_LOWR_CASE,
        'Password must contain at least one small letter.'
      )
      .matches(
        REGEX.AT_LEAST_A_NUMBER,
        'Password must contain at least one number.'
      )
      .matches(
        REGEX.AT_LEAST_A_SPEC_CHAR,
        'Password must contain at least one special character.'
      ),
    confirmPassword: Yup.string()
      .when('passwordReset', {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref('passwordReset')],
          'Please make sure your passwords match.'
        ),
      })
      .required('Required'),
  });

  const ResetPasswordForm = useFormik({
    initialValues: {
      passwordReset: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema: ResetPasswordformValidationSchema,
    onSubmit: (values) => {
      updateSuccessMessage('');
      updateErrorMessage('');
      const body = {
        password: values.passwordReset,
        token: queryParams.get('token'),
        uidb64: queryParams.get('uidb64'),
      };
      resetPassword(body).then(
        (res) => updateSuccessMessage(res.data.message),
        (error) => updateErrorMessage(error.response.data.detail)
      );
    },
  });

  const formValidationSchema = Yup.object({
    username: Yup.string()
      .matches(REGEX.LOGIN_USERNAME, 'Incorrect input')
      .required('Required')
      .max(320, 'Username can contain at most 320 characters.'),
    password: Yup.string()
      .required('Required')
      .min(8, 'Password must contain at least 8 characters.')
      .matches(REGEX.NOT_ONLY_NUMBER, 'Password should not be only numbers.'),
  });
  const loginForm = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      login(values).then(
        (res) => {
          if (res?.data?.temporary_password) {
            SwalToast({
              icon: 'info',
              title: res.data.tokens.msg,
            });
            setTimeout(() => {
              const uidb64 = res?.data?.tokens?.uidb64;
              const token = res?.data?.tokens?.token;
              const url = `/login?uidb64=${uidb64}&token=${token}`;
              setResettingPasswordModal(true);
              history.push(url);
            }, 1000);
          } else {
            history.push('/');
          }
        },
        // @ts-ignore
        () => {}
      );
    },
  });

  const changehiddenPassword = () => {
    sethiddenPassword(!hiddenPassword);
  };

  const changehidden = () => {
    sethidden(!hidden);
  };

  // @ts-ignore
  // const changehiddenLoginPassword = () => {
  //   sethiddenLoginPassword(!hiddenLoginPassword);
  // };

  /**
   * @function handleMouseDownPassword
   * @description handles the mouse down event on the password field
   * @param {object} event - the event object
   * @returns {void}
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // @ts-ignore
  // const handleResettingClose = () => {
  //   // need to ask
  //   setResettingPasswordModal(false);
  // };

  useEffect(() => {
    if (queryParams.get('uidb64') && queryParams.get('token')) {
      setResettingPasswordModal(true);
    }
  }, [queryParams]);

  useEffect(() => {
    if (user.isAuthenticate) {
      history.push('/');
    }
  }, [user]);

  const handleClose = () => {
    history.push('/');
    setforgetPasswordForm(false);
    setResettingPasswordModal(false);
    updateSuccessMessage('');
    updateErrorMessage('');
  };

  const theme = useTheme();

  const particlesInit = React.useCallback(async (engine) => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = React.useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <Grid
      item
      xs={12}
      container
      backgroundColor="white"
      height="100vh"
      sx={{ zIndex: 1, overflowX: 'auto' }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={(_theme) => ({
          background: '#0c3270',
        })}
        px={8}
        py={5}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          height="100vh"
          width="50vw"
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '50vw',
          }}
          options={{
            fpsLimit: 120,
            interactivity: {
              events: {
                onDiv: {
                  selectors: '#loginFormCards',
                  enable: false,
                  mode: 'repulse',
                  type: 'square',
                },
                onClick: {
                  enable: false,
                  mode: 'push',
                },
                onHover: {
                  enable: false,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: '#AECDFC',
              },
              links: {
                color: '#AECDFC',
                distance: 150,
                enable: true,
                opacity: 0.2,
                width: 1,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: {
                  default: 'bounce',
                },
                random: true,
                speed: 1,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.1,
              },
              // shape: {
              //   type: 'circle',
              // },
              size: {
                value: { min: 1, max: 5 },
              },
            },
            detectRetina: true,
            fullScreen: {
              enable: false,
              zIndex: 0,
            },
          }}
        ></Particles>
        <Grid item xs={12} height="100%">
          <Box display="flex" flexDirection="row" height="100%" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              maxWidth="650px"
              flexGrow={1}
              marginX="auto"
            >
              <NimbusLogo height="60px" width="120px" />
              <Box
                width="inherit"
                display="flex"
                flexGrow={1}
                alignItems="center"
              >
                <LoginPageAnimation />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Zoom in={true} mountOnEnter unmountOnExit timeout={800}>
          <Box display="flex" flexDirection="row" height="100%" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              maxWidth="650px"
              flexGrow={1}
              marginX="auto"
              id="loginFormCards"
            >
              {/* fake block is added to avoid autocomplete in forms. Chrome is not honouring autoComplete="off" */}
              <Box display="none">
                <input name="username" type="text" />
                <input name="password" type="password" />
              </Box>
              {!forgetPasswordForm && !openResettingPasswordModal && (
                <Grid item xs={12} px={5}>
                  <Card elevation={0}>
                    <Box pl={4} pr={4} pb={4} pt={1} mb={4}>
                      <Typography variant="h3">Get Started Now!</Typography>

                      <Typography variant="body3">
                        Enter your credentials to access your account
                      </Typography>
                      <Box mb={5} mt={6}>
                        <TextField
                          required
                          id="username"
                          type="text"
                          name="username"
                          placeholder="Enter username"
                          variant="outlined"
                          autoComplete="off"
                          label="Username"
                          value={loginForm.values.username}
                          onChange={loginForm.handleChange}
                          helperText={
                            loginForm.errors.username &&
                            loginForm.touched.username
                              ? loginForm.errors.username
                              : null
                          }
                          sx={(_theme) => {
                            return {
                              '& .MuiFilledInput-root': {
                                backgroundColor: `${_theme.palette.other.blue2} !important`,
                              },
                            };
                          }}
                          error={Boolean(
                            loginForm.errors.username &&
                              loginForm.touched.username
                              ? loginForm.errors.username
                              : null
                          )}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          required
                          id="password"
                          label="Password"
                          placeholder="Enter password"
                          type={!hidden ? 'text' : 'password'}
                          variant="outlined"
                          value={loginForm.values.password}
                          onChange={loginForm.handleChange}
                          helperText={
                            loginForm.errors.password &&
                            loginForm.touched.password
                              ? loginForm.errors.password
                              : null
                          }
                          error={Boolean(
                            loginForm.errors.password &&
                              loginForm.touched.password
                              ? loginForm.errors.password
                              : null
                          )}
                          sx={(_theme) => {
                            return {
                              '& .MuiFilledInput-root': {
                                backgroundColor: `${_theme.palette.other.blue2} !important`,
                              },
                            };
                          }}
                          autoComplete="off"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  color="primary"
                                  aria-label="toggle password visibility"
                                  onClick={changehidden}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {!hidden ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        className="extras"
                        mt={4}
                      >
                        <Grid item className="align-items-center">
                          <Typography
                            // @ts-ignore
                            variant="body2"
                            className="forgotpassword"
                            color="primary"
                            sx={{
                              // @ts-ignore
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                            onClick={() => {
                              setforgetPasswordForm(true);
                            }}
                          >
                            Forgot password?
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexGrow={1}
                        flexDirection="column"
                        mt={2}
                      >
                        <Button
                          size="large"
                          autoFocus
                          onClick={loginForm.handleSubmit}
                          disabled={user?.isLoading}
                          variant="contained"
                          color="primary"
                          type="submit"
                          fullWidth
                          sx={(_theme) => ({
                            // textTransform: 'none',
                            color: _theme?.palette?.other.white,
                          })}
                        >
                          {user.isLoading ? 'Loading...' : 'Login'}
                        </Button>
                      </Box>

                      <Box my={4}>
                        <Divider>
                          <Typography variant="body2">OR</Typography>
                        </Divider>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexGrow={1}
                        flexDirection="column"
                      >
                        <Button
                          fullWidth
                          size="large"
                          onClick={handleAdLogin}
                          disabled={user?.isLoading}
                          variant="outlined"
                          color="primary"
                          type="submit"
                        >
                          {user.isLoading ? 'Loading...' : 'Login with AD'}
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}

              {forgetPasswordForm && !openResettingPasswordModal && (
                <Grid item xs={12} px={5}>
                  <Card elevation={0}>
                    <Box pl={4} pr={4} pb={4} pt={1} mb={4}>
                      <Box mb={3}>
                        <Typography variant="h3">Reset Password</Typography>

                        <Typography variant="body3">
                          Please enter your email address. We’ll send you a link
                          to reset your password.
                        </Typography>
                      </Box>
                      {successMessage && (
                        <Alert severity="success" sx={{ marginBottom: 1 }}>
                          {successMessage}
                        </Alert>
                      )}
                      {errorMessage && (
                        <Alert severity="error">{errorMessage}</Alert>
                      )}
                      <Box mb={3} mt={5}>
                        <TextField
                          required
                          id="email"
                          placeholder="Enter your registered email address"
                          label="Enter your email"
                          type="email"
                          variant="outlined"
                          value={ForgetForm.values.email}
                          onChange={ForgetForm.handleChange}
                          helperText={ForgetForm.errors.email}
                          error={Boolean(ForgetForm.errors.email)}
                          autoComplete="off"
                          sx={(_theme) => {
                            return {
                              '& .MuiFilledInput-root': {
                                backgroundColor: `${_theme.palette.other.blue2} !important`,
                              },
                            };
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton color="primary" edge="end" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexGrow={1}
                        flexDirection="column"
                        mt={4}
                        justifyContent="space-between"
                      >
                        <Button
                          size="large"
                          autoFocus
                          onClick={ForgetForm.handleSubmit}
                          variant="contained"
                          color="primary"
                          type="submit"
                          fullWidth
                        >
                          Send Reset Link
                        </Button>

                        <Button
                          sx={{ marginTop: 2 }}
                          size="large"
                          onClick={handleClose}
                          color="primary"
                          type="submit"
                          variant="outlined"
                          fullWidth
                        >
                          Back to Login
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}

              {!forgetPasswordForm && openResettingPasswordModal && (
                <Grid item xs={12} px={5}>
                  <Card elevation={0}>
                    <Box pl={4} pr={4} pb={4} pt={1} mb={4}>
                      <Box mb={3}>
                        <Typography variant="h3">Reset password</Typography>

                        <Typography variant="body3">
                          Enter new password
                        </Typography>
                      </Box>
                      {successMessage && (
                        <Alert severity="success">{successMessage}</Alert>
                      )}
                      {errorMessage && (
                        <Alert severity="error">{errorMessage}</Alert>
                      )}

                      <Box mb={3} mt={3}>
                        <FormControl variant="outlined">
                          <TextField
                            required
                            name="passwordReset"
                            placeholder="Enter your new password"
                            label="New password"
                            variant="outlined"
                            type={!hidden ? 'text' : 'password'}
                            value={ResetPasswordForm.values.passwordReset}
                            onChange={ResetPasswordForm.handleChange}
                            onBlur={ResetPasswordForm.handleBlur(
                              'passwordReset'
                            )}
                            helperText={
                              ResetPasswordForm?.errors?.passwordReset &&
                              ResetPasswordForm?.touched?.passwordReset
                                ? ResetPasswordForm?.errors?.passwordReset
                                : null
                            }
                            error={
                              Boolean(
                                ResetPasswordForm?.errors?.passwordReset
                              ) && ResetPasswordForm?.touched?.passwordReset
                            }
                            autoComplete="off"
                            sx={(_theme) => {
                              return {
                                '& .MuiFilledInput-root': {
                                  backgroundColor: `${_theme.palette.other.blue2} !important`,
                                },
                              };
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    color="primary"
                                    aria-label="toggle password visibility"
                                    onClick={changehidden}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!hidden ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Box>

                      <Box mb={3}>
                        <FormControl variant="outlined">
                          <TextField
                            required
                            variant="outlined"
                            name="confirmPassword"
                            placeholder="Re-enter your new password"
                            label="Re-enter new password"
                            type={!hiddenPassword ? 'text' : 'password'}
                            value={ResetPasswordForm.values.confirmPassword}
                            onChange={ResetPasswordForm.handleChange}
                            onBlur={ResetPasswordForm.handleBlur(
                              'confirmPassword'
                            )}
                            helperText={
                              ResetPasswordForm?.errors?.confirmPassword &&
                              ResetPasswordForm?.touched?.confirmPassword
                                ? ResetPasswordForm?.errors?.confirmPassword
                                : null
                            }
                            error={
                              Boolean(
                                ResetPasswordForm?.errors?.confirmPassword
                              ) && ResetPasswordForm?.touched?.confirmPassword
                            }
                            autoComplete="off"
                            sx={(_theme) => {
                              return {
                                '& .MuiFilledInput-root': {
                                  backgroundColor: `${_theme.palette.other.blue2} !important`,
                                },
                              };
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    color="primary"
                                    aria-label="toggle password visibility"
                                    onClick={changehiddenPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!hiddenPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexGrow={1}
                        flexDirection="column"
                        mt={4}
                        justifyContent="space-between"
                      >
                        <Button
                          size="large"
                          autoFocus
                          onClick={ResetPasswordForm.handleSubmit}
                          variant="contained"
                          color="primary"
                          type="submit"
                          fullWidth
                        >
                          Set New Password
                        </Button>

                        <Button
                          sx={{ marginTop: 2 }}
                          size="large"
                          onClick={handleClose}
                          color="primary"
                          type="submit"
                          fullWidth
                          variant="outlined"
                        >
                          Back to login
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              )}
            </Box>
          </Box>
        </Zoom>
      </Grid>
    </Grid>
  );
};
Login.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withTransaction('Login', 'component')(Login);
