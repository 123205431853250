/* eslint-disable react/prop-types */
// @ts-nocheck
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Divider,
  Stack,
  Dialog,
  // Slide,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import React, { useCallback, useState, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import { useResizeDetector } from 'react-resize-detector';
import GitHubIcon from '@mui/icons-material/GitHub';
import { object, bool, string } from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFormik } from 'formik';
import ConsoleOperationsResultViewer from './ConsoleOperationsResultViewer';
import ConsoleCreateEditUdf from './ConsoleCreateEditUdf';
import ConsoleArgumentInput from './ConsoleArgumentInput';
import SwalToast from './SwalToast';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
import { ReactComponent as Edit } from '../../assets/images/Edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import DialogTransition from './Transitions/DialogTransition';
import utils from '../../utils/utils';
import { PodSpecs } from '../../const/ConsoleConst';
import DeleteConfirmModal from './DeleteConfirmModal';

const LABEL_STYLES = {
  '.MuiInputLabel-root': {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
};

/**
 * @function formatArg
 * @description format arg
 * @param {object} arg - object containing arg details
 * @param {Array} models - list of models
 * @param {Array} datasets - list of datasets
 * @param {function} fetchDatasetColumnDetails - A function to fetch dataset column details
 * @param {object} activeProject - currently active project
 * @returns {object} formatted arg
 */

const formatArg = async (
  arg,
  models,
  datasets,
  fetchDatasetColumnDetails,
  activeProject
) => {
  switch (arg.type) {
    case 'Model':
      return {
        ...arg,
        value: models.find((model) => model.id === parseInt(arg.value, 10)),
      };
    case 'Dataset':
      return {
        ...arg,
        value: datasets.find(
          (dataset) => dataset.id === parseInt(arg.value, 10)
        ),
      };
    case 'Column':
      // eslint-disable-next-line no-case-declarations
      const dataset = datasets.find(
        (_dataset) => _dataset.id === parseInt(arg.dataset, 10)
      );
      // eslint-disable-next-line no-case-declarations
      const {
        data: { columns: datasetColumns },
      } = await fetchDatasetColumnDetails({
        project_id: activeProject?.project_id,
        datasetId: dataset?.id,
      });
      return {
        ...arg,
        dataset,
        datasetColumns,
        value: datasetColumns.filter((col) =>
          arg.value.includes(col.column_name)
        ),
      };
    default:
      return {
        ...arg,
      };
  }
};

const CustomCell = (data) => {
  const { cell } = data;
  const value = [true, false].includes(cell.value)
    ? cell.value.toString()
    : cell.value;
  return <Box title={value}>{value}</Box>;
};

const columnGetOptionLabel = (option) => {
  return option.table_display_name ? option?.table_display_name : '';
};

const datasetListRenderOption = (props, option) => (
  <Box display="flex" flexGrow={1} {...props} key={option.id}>
    <Typography noWrap>{option?.table_display_name}</Typography>
  </Box>
);

const cpuFieldRenderInput = (params) => (
  <TextField
    {...params}
    label="CPU field"
    placeholder="Select CPU field"
    InputLabelProps={{
      shrink: true,
    }}
  />
);

const cpuFieldRenderOption = (optionProps, el) => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    textOverflow="ellipsis"
    {...optionProps}
  >
    <Box display="flex" flexGrow={1} textOverflow="ellipsis" pr={2}>
      <Typography noWrap>{el?.label}</Typography>
    </Box>
    <Box display="flex" alignSelf="flex-end">
      <Typography variant="caption">CPU: {el?.cpu}</Typography>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: 5, marginLeft: 5 }}
      />
      <Typography variant="caption">RAM: {el?.ram}</Typography>
    </Box>
  </Box>
);

/**
 * @function Console
 * @description console
 * @param {object} activeDataset - currently active dataset
 * @param {object} activeDatasetColumns - object containing selected dataset column detail
 * @param {object} activeProject - currently active project
 * @param {Array} datasetList - list of datasets
 * @param {function} moduleName - A function to add column in DatasetTable
 * @param {function} subModuleName - A function to add column in DatasetTable
 * @param {object} asyncTaskResult - object containing async task result
 * @param {function} setAsyncTaskResult - A function to set async task result
 * @param {function} fetchUdfs - A function to fetch udf
 * @param {function} deleteUdf - A function to delete udf
 * @param {function} createUdf - A function to create udf
 * @param {function} createJob - A function to create job
 * @param {function} updateUdf - A function to update udf
 * @param {Array} udfs - list of udfs
 * @param {object} selectedUdf - A object to store selected udf
 * @param {function} setSelectedUdf - A function to set selected udf
 * @param {function} runUdf - A function to run udf
 * @param {function} githubIntegration - A function to integrate github
 * @param {function} fetchUdfDetails - A function to fetch udf details
 * @param {function} fetchDatasetColumnDetails - A function to fetch dataset column details
 * @param {function} fetchAllModels - A function to fetch all models
 * @param {string} permission - A string value
 * @param {function} fetchConsoleLibraries
 * @returns {JSX.Element}
 */
const Console = ({
  activeDataset,
  activeDatasetColumns = [],
  activeProject,
  datasetList = [],
  fetchDatasetList = () => {},
  moduleName,
  subModuleName,
  asyncTaskResult,
  setAsyncTaskResult = () => {},
  fetchUdfs = () => {},
  deleteUdf = () => {},
  createUdf = () => {},
  createJob = () => {},
  updateUdf = () => {},
  udfs = [],
  selectedUdf,
  setSelectedUdf,
  runUdf = () => {},
  githubIntegration = () => {},
  fetchUdfDetails = () => {},
  fetchDatasetColumnDetails = () => {},
  fetchAllModels = () =>
    new Promise((resolve) => {
      resolve([]);
    }),
  allProjects = [],
  fetchTableData,
  permission = 'Manage',
  onDownloadCSV = () => {},
  fetchConsoleLibraries,
  fetchDatasetRowsWithPagination,
  isLoading,
  markDatasetAsReadyToUse,
  addResultToModelMetaData,
  fetchModelMetaDataSchema,
  fetchConsoleFileList = () => {},
}) => {
  // list, codeReceipe
  const [activeView, setActiveView] = useState('list');
  const [createEditUdfMode, setCreateEditUdfMode] = useState('create');
  const [showOption, setShowOption] = useState('block');
  const [cloudCode, setCloudCode] = useState(false);
  const [githubValue, setGithubValue] = useState({});
  const [showGithubIntForm, setShowGithubIntForm] = useState('none');
  const [allModels, setAllModels] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUdfName, setDeleteUdfName] = useState('');
  const [pod, setPod] = useState('');
  const { height, ref } = useResizeDetector();
  const [isHeightHigh, setIsHeightHigh] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const viewportHeight = window.innerHeight;
  const adjustedHeight = viewportHeight - 250;

  React.useEffect(() => {
    if (!isRendered) {
      if (height > adjustedHeight) {
        setIsHeightHigh(true);
        setIsRendered(true);
      } else if (height <= adjustedHeight) {
        setIsHeightHigh(false);
        setIsRendered(true);
      }
    }
  }, [viewportHeight, height]);

  const formValidationSchema = object({
    githubInt: bool().required('Required'),
    gitAccessToken: string().when('githubInt', {
      is: true,
      then: string(),
    }),
    repoLink: string().when('githubInt', {
      is: true,
      then: string().required('Required'),
    }),
  });
  const formikForm = useFormik({
    initialValues: {
      githubInt: false,
      gitAccessToken: '',
      repoLink: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: () => {
      const payload = {
        githubInt: formikForm.values.githubInt,
        gitAccessToken: formikForm.values.gitAccessToken,
        repoLink: formikForm.values.repoLink,
      };
      githubIntegration(payload).then((resp) => {
        setGithubValue({
          githubInt: formikForm.values.githubInt,
          code: resp.data.code,
        });
        SwalToast({
          icon: 'success',
          title: 'Code from your github repo extracted successfully.',
        });
        formikForm.resetForm();
        setCloudCode(false);
        setShowGithubIntForm('none');
        setShowOption('block');
      });
    },
  });

  /**
   * @function fetchAllUdfs
   * @description To fetch All Udfs
   * @returns {void}
   * @example fetchAllUdfs()
   */
  const fetchAllUdfs = () => {
    fetchUdfs(moduleName, subModuleName);
  };
  React.useEffect(() => {
    setTimeout(() => {
      fetchAllUdfs();
    }, 3000);
    setSelectedUdf(null);
  }, [moduleName, activeDataset]);

  const resetConsoleState = () => {
    setSelectedUdf(null);
    setAsyncTaskResult(null);
    utils.resetConsoleArgs(null);
    setPod('');
  };

  React.useEffect(() => {
    if (activeProject?.project_id) {
      fetchAllModels({
        project_id: activeProject?.project_id,
      }).then(
        (resp) => {
          setAllModels(resp);
        },
        () => {
          setAllModels([]);
        }
      );
    }
  }, [activeProject]);

  React.useEffect(() => {
    if (
      (selectedUdf !== null && createEditUdfMode !== 'edit') ||
      (asyncTaskResult !== null && activeView === 'codeReceipe') ||
      (asyncTaskResult !== null && createEditUdfMode === 'edit')
    ) {
      setActiveView('list');
    }
  }, [selectedUdf, asyncTaskResult, activeView, createEditUdfMode]);

  React.useEffect(() => {
    (async function () {
      if (
        asyncTaskResult?.parameters &&
        udfs?.length &&
        Array.isArray(allModels) &&
        activeProject &&
        datasetList.length
      ) {
        const argsFromTaskResult = [...asyncTaskResult.parameters.args];
        setPod(
          PodSpecs.find(
            (ele) => ele?.value === asyncTaskResult?.parameters?.pod_spec
          )?.label ?? ''
        );
        const udf = udfs.find(
          (uf) => uf.id === asyncTaskResult?.parameters?.id
        );
        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const [index, arg] of argsFromTaskResult.entries()) {
          // eslint-disable-next-line no-await-in-loop
          argsFromTaskResult[index] = await formatArg(
            cloneDeep(arg),
            [...allModels],
            [...datasetList],
            fetchDatasetColumnDetails,
            activeProject
          );
        }

        udf.args = [...argsFromTaskResult];
        setSelectedUdf({ ...udf });
      }
    })();
  }, [asyncTaskResult, udfs, allModels, datasetList, activeProject]);

  /**
   * @function downloadTableData
   * @description It is to download data of table
   * @param {number} commentid
   * @returns {void}
   * @example downloadTableData commentid={commentid}
   */
  const downloadTableData = (commentid) => {
    onDownloadCSV({
      id: commentid,
      project_id: activeProject?.project_id,
      module: moduleName,
      subModule: subModuleName,
    });
  };

  /**
   * @function editFunction
   * @description To edit the function
   * @param {object} e - event object
   * @param {object} udf
   * @returns {void}
   * @example editFunction e={e}
   */
  const editFunction = (e, udf) => {
    setSelectedUdf(null);
    e.stopPropagation();
    // fetch all details of udf first
    fetchUdfDetails(udf.id).then((resp) => {
      setActiveView('codeReceipe');
      setCreateEditUdfMode('edit');
      setCloudCode(false);
      setAsyncTaskResult(null);
      setTimeout(() => {
        setSelectedUdf(resp.data);
      }, 1000);
    });
  };

  /**
   * @function deleteFunction
   * @description To delete the function
   * @param {object} e - event object
   * @param {object} udf
   * @returns {void}
   * @example deleteFunction e={e}
   */
  const deleteFunction = (e, udf) => {
    document.body.click();
    deleteUdf(udf.id).then(() => {
      SwalToast({
        icon: 'success',
        title: 'User defined function deleted successfully.',
      });
      setSelectedUdf(null);
      fetchAllUdfs();
    });
  };

  /**
   * @function createConsoleJob
   * @description To create Console Job
   * @returns {void}
   * @example createConsoleJob()
   */
  const createConsoleJob = React.useCallback(() => {
    let stop = false;
    const selectedUdfCopy = { ...selectedUdf };
    if (selectedUdfCopy.args) {
      selectedUdfCopy.args.forEach((arg) => {
        if (arg.value === undefined || arg.value === '') {
          SwalToast({
            icon: 'error',
            title: 'Please fill all required arguments.',
          });
          stop = true;
        }
      });
    }
    if (stop) return;
    const payload = {
      args: selectedUdfCopy?.args?.length
        ? utils.prepareArgsConsole(Object.assign([], selectedUdfCopy.args))
        : [],
      table: activeDataset.Name,
      project_id: activeProject.project_id,
      id: selectedUdfCopy?.id,
      pod_spec: isEmpty(pod) ? null : pod,
    };
    createJob(payload).then(() => {
      SwalToast({
        icon: 'success',
        title:
          'The operation is scheduled for execution. Please check the job queue for further updates.',
      });
    });
  }, [selectedUdf, pod]);

  /**
   * @function updateSelectedUdfArg
   * @description To update and set the value of SelectedUdf
   * @param {number} index - index of argument
   * @param {any} arg - argument
   * @param {object} event - event object
   * @returns {void}
   * @example updateSelectedUdfArg index={index}
   */
  const updateSelectedUdfArg = (index, arg, event) => {
    const copyOfSelectedUdf = { ...selectedUdf };
    copyOfSelectedUdf.args[index] = {
      ...copyOfSelectedUdf.args[index],
      value: event.target.value,
    };
    setSelectedUdf(copyOfSelectedUdf);
  };

  /**
   * @function fetchSelectedDatasetColumnDetails
   * @description To fetch selected dataset column details
   * @param {number} index
   * @param {any} arg
   * @param {object} event - event object
   * @param {object} value
   * @returns {void}
   * @example fetchSelectedDatasetColumnDetails index={index}
   */
  const fetchSelectedDatasetColumnDetails = React.useCallback(
    (index, arg, event, value) => {
      const copyOfSelectedUdf = { ...selectedUdf };
      if (value?.Name) {
        fetchDatasetColumnDetails({
          project_id: activeProject?.project_id,
          datasetId: value?.id,
        }).then(
          (resp) => {
            copyOfSelectedUdf.args[index] = {
              ...copyOfSelectedUdf.args[index],
              datasetColumns: resp.data.columns,
              dataset: value,
              value: {},
            };
            setSelectedUdf(copyOfSelectedUdf);
          },
          () => {
            copyOfSelectedUdf.args[index] = {
              ...copyOfSelectedUdf.args[index],
              datasetColumns: [],
              dataset: value,
              value: {},
            };
            setSelectedUdf(copyOfSelectedUdf);
          }
        );
      } else {
        copyOfSelectedUdf.args[index] = {
          ...copyOfSelectedUdf.args[index],
          datasetColumns: [],
          dataset: value,
          value: {},
        };
        setSelectedUdf(copyOfSelectedUdf);
      }
    },
    [activeProject, setSelectedUdf, selectedUdf]
  );

  /**
   * @function handleClose
   * @description To handle Close
   * @returns {void}
   * @example handleClose()
   */
  const handleClose = () => {
    setGithubValue({
      githubInt: false,
      gitAccessToken: '',
      repoLink: '',
    });
    formikForm.resetForm();
    setCloudCode(false);
    setShowGithubIntForm('none');
    setShowOption('block');
  };

  const options = udfs.map((option) => {
    const projectUdfs =
      option.projectId === activeProject.project_id
        ? `UDFs in ${activeProject.name} project`
        : 'Other UDFs';
    return {
      projectUdfs,
      ...option,
    };
  });

  const handleDeleteUDF = (e) => {
    deleteFunction(e, deleteUdfName);
    setShowDeleteModal(false);
  };

  const importGithubCodeOnClick = useCallback(() => {
    formikForm.setFieldValue('githubInt', true);
    setShowGithubIntForm('block');
    setShowOption('none');
  }, [formikForm, setShowOption, setShowGithubIntForm]);

  const createOwnCodeOnClick = useCallback(() => {
    setGithubValue({
      githubInt: formikForm.values.githubInt,
    });
    formikForm.resetForm();
    setCloudCode(false);
    setShowGithubIntForm('none');
    setShowOption('block');
  }, [
    formikForm,
    setShowOption,
    setShowGithubIntForm,
    setCloudCode,
    setGithubValue,
  ]);

  const resetButtonOnClick = useCallback(() => {
    formikForm.resetForm();
    formikForm.setValues({
      githubInt: true,
      gitAccessToken: '',
      repoLink: '',
    });
  }, [formikForm]);

  const udfListOnChange = useCallback(
    (_e, value) => {
      setPod(null);
      setAsyncTaskResult(null);
      utils.resetConsoleArgs(value);
      setSelectedUdf(value);
      setIsRendered(false);
      setIsHeightHigh(false);
    },
    [setPod, setAsyncTaskResult, setSelectedUdf, utils.resetConsoleArgs]
  );

  const udfRenderOption = useCallback(
    (props, option) => (
      <Box
        display="flex"
        flexDirection="row"
        flexGrow={1}
        alignItems="center"
        {...props}
        minHeight="unset !important"
        height="unset !important"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexGrow={1}
          flexDirection="column"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
          >
            <Typography mb={0.2} noWrap width={250}>
              {option.name}
            </Typography>
            {activeProject.project_id !== option.projectId && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  noWrap
                  variant="caption"
                  title={`Project: ${
                    allProjects.find(
                      (prj) => prj.project_id === option.projectId
                    )?.name
                  }`}
                >
                  Project: &nbsp;
                  <b>
                    {
                      allProjects.find(
                        (prj) => prj.project_id === option.projectId
                      )?.name
                    }
                  </b>
                </Typography>
                <Box>
                  {option?.miscellaneous?.githubInt ? (
                    <Box display="flex" alignItems="center">
                      <IconButton size="small" title="UDF created from github">
                        <GitHubIcon color="primary" />
                      </IconButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Typography variant="body5" color="primary">
              Created:&nbsp;
              {moment(new Date(option.created)).format('DD/MM/YYYY, HH:mm:ss')}
            </Typography>
            <Typography variant="body5" color="primary">
              Last Modified:&nbsp;
              {moment(new Date(option.last_modified)).format(
                'DD/MM/YYYY, HH:mm:ss'
              )}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignSelf="flex-end" flexDirection="column">
          {activeProject.project_id === option.projectId ? (
            <>
              <Box>
                {option?.miscellaneous?.githubInt ? (
                  <Box display="flex" alignItems="center">
                    <IconButton size="small" title="UDF created from github">
                      <GitHubIcon color="primary" />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>
              <Box display="flex" flexDirection="row">
                <Box
                  title={
                    permission !== 'Manage'
                      ? 'You are not authorized to access this!'
                      : 'Edit function'
                  }
                >
                  <IconButton
                    disabled={permission !== 'Manage'}
                    aria-label="edit"
                    color="primary"
                    size="small"
                    onClick={(e) => editFunction(e, option)}
                  >
                    <Edit />
                  </IconButton>
                </Box>
                <Box
                  title={
                    permission !== 'Manage'
                      ? 'You are not authorized to access this!'
                      : 'Delete function'
                  }
                >
                  <IconButton
                    disabled={permission !== 'Manage'}
                    aria-label="delete"
                    size="small"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteModal(true);
                      setDeleteUdfName(option);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    ),
    [allProjects, activeProject, editFunction]
  );

  const udfRenderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        label="User defined function"
        placeholder="Search user defined function"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ backgroundColor: 'white' }}
      />
    ),
    []
  );

  const codeReceipeButtonOnClick = useCallback(() => {
    setActiveView('create');
    setCreateEditUdfMode('create');
    setCloudCode(true);
    setAsyncTaskResult(null);
    setSelectedUdf(null);
  }, [
    setActiveView,
    setCreateEditUdfMode,
    setCloudCode,
    setAsyncTaskResult,
    setSelectedUdf,
  ]);

  const cpuFieldOnchange = useCallback(
    (_event, value) => {
      setPod(value.label);
    },
    [setPod]
  );

  const gridPropsOne = useMemo(() => {
    return isHeightHigh
      ? {
          container: true,
          spacing: 2,
          alignItems: 'center',
          style: { marginBottom: 12 },
        }
      : {
          xs: 12,
          sm: 12,
          md: 6,
          lg: 6,
          width: 450,
          overflowX: 'auto',
        };
  }, [isHeightHigh]);

  // const cpuGridProps = useMemo(() => {
  //   return isHeightHigh
  //     ? {
  //         xs: 12,
  //         sm: 12,
  //         md: 6,
  //         lg: 4,
  //       }
  //     : { width: 450 };
  // }, [isHeightHigh]);

  const gridPropsTwo = useMemo(() => {
    return isHeightHigh
      ? { item: true, xs: 12, sm: 12, md: 6, lg: 4 }
      : { container: true, alignItems: 'center', style: { marginBottom: 12 } };
  }, [isHeightHigh]);

  const gridPropsThree = useMemo(() => {
    return isHeightHigh
      ? { container: true, spacing: 1, display: 'flex', alignItems: 'center' }
      : { item: true, xs: 12 };
  }, [isHeightHigh]);

  React.useEffect(() => {
    resetConsoleState();
  }, [activeProject]);

  const publishedDatasets = useMemo(() => {
    return datasetList?.length
      ? datasetList?.filter((dataset) => dataset?.published === true)
      : [];
  }, [datasetList]);

  return (
    <>
      <Dialog
        open={cloudCode}
        aria-labelledby="customized-dialog-title"
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        TransitionComponent={DialogTransition}
      >
        {showOption === 'block' && (
          <>
            <DialogTitle>Create User Defined Function</DialogTitle>
            <IconButton
              id="cnsl_github_modal_close_btn"
              color="primary"
              aria-label="close"
              onClick={handleClose}
              size="large"
              sx={{
                position: 'absolute',
                right: 16,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className="popupheadertext"
                    align="center"
                  >
                    How do you want to create new UDF?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    mt={2}
                  >
                    <Button
                      id="cnsl_github_code_btn"
                      color="primary"
                      variant="contained"
                      onClick={importGithubCodeOnClick}
                    >
                      Import Github Code
                    </Button>

                    <Button
                      id="cnsl_create_code_btn"
                      color="primary"
                      variant="contained"
                      onClick={createOwnCodeOnClick}
                    >
                      Create own code
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}

        {showGithubIntForm === 'block' && (
          <>
            <DialogTitle>Add Your Github Account Details</DialogTitle>
            <IconButton
              id="cnsl_git_modal_close_btn"
              className="mui-dialog-close-icon-btn"
              color="primary"
              aria-label="close"
              onClick={handleClose}
              size="large"
              sx={{
                position: 'absolute',
                right: 16,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body4">
                    <strong>Note:</strong>&nbsp;
                  </Typography>
                  <Typography variant="body4">
                    <i>
                      Make sure you are adding the link of github repo file and
                      not folder.
                    </i>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Github access token"
                    name="gitAccessToken"
                    placeholder="Enter github access token"
                    {...formikForm.getFieldProps('gitAccessToken')}
                    error={
                      Boolean(formikForm?.errors?.gitAccessToken) &&
                      formikForm?.touched?.gitAccessToken
                    }
                    helperText={
                      formikForm?.errors?.gitAccessToken &&
                      formikForm?.touched?.gitAccessToken
                        ? formikForm.errors.gitAccessToken
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Repo link"
                    name="repoLink"
                    multiline
                    rows={2}
                    maxRows={4}
                    placeholder="Enter github repo link"
                    {...formikForm.getFieldProps('repoLink')}
                    error={Boolean(
                      formikForm.errors.repoLink && formikForm.touched.repoLink
                        ? formikForm.errors.repoLink
                        : null
                    )}
                    helperText={
                      formikForm?.errors?.repoLink &&
                      formikForm?.touched?.repoLink
                        ? formikForm.errors.repoLink
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={resetButtonOnClick} variant="outlined">
                Reset
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={formikForm.handleSubmit}
              >
                Next
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {activeView === 'list' ? (
        <Box m={3} minHeight={200}>
          <Grid container alignItems="center">
            <Box width={450} mb={2}>
              <Autocomplete
                id="console_user_defined_function_selector_2"
                options={options.sort(
                  (a, b) => -a.projectUdfs.localeCompare(b.projectUdfs)
                )}
                groupBy={(option) => option.projectUdfs}
                popupIcon={<DropdownArrow />}
                noOptionsText="No user defined function available."
                getOptionLabel={(option) => (option.name ? option.name : '')}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderInput={udfRenderInput}
                renderOption={udfRenderOption}
                onChange={udfListOnChange}
                value={selectedUdf}
                placeholder="Select/ Search user defined function"
              />
            </Box>
            <Box flexGrow={1}>
              <Box
                display="flex"
                flexGrow={1}
                flexDirection="column"
                mb={3}
                mt={4}
              >
                <Box alignSelf="flex-end">
                  <Button
                    variant="contained"
                    title={
                      permission !== 'Manage'
                        ? 'You are not authorized to access this!'
                        : 'Create new user defined function.'
                    }
                    size="large"
                    onClick={codeReceipeButtonOnClick}
                    disabled={permission !== 'Manage'}
                  >
                    Add user defined function
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Box ref={ref}>
            {selectedUdf?.args?.length ? (
              <Grid container direction="column">
                <Box mt={2} />

                <Grid {...gridPropsOne}>
                  {selectedUdf?.args?.map((arg, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid {...gridPropsTwo} key={index}>
                      {arg?.type === 'Column' ? (
                        <Grid {...gridPropsThree}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={isHeightHigh ? 6 : 12}
                            mb={isHeightHigh ? 0 : 2}
                          >
                            <Autocomplete
                              popupIcon={<DropdownArrow />}
                              options={publishedDatasets}
                              getOptionLabel={columnGetOptionLabel}
                              disableClearable={['', undefined, {}].includes(
                                arg?.dataset?.table_display_name
                              )}
                              value={arg.dataset ? arg.dataset : {}}
                              sx={LABEL_STYLES}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  label={
                                    <>
                                      {`${arg?.name} (${arg?.type})*`}
                                      <Tooltip
                                        title={<Box>{arg.description}</Box>}
                                        placement="top"
                                        arrow
                                        sx={{ marginRight: '4px' }}
                                      >
                                        <ErrorOutlineIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                    </>
                                  }
                                  placeholder="Select dataset"
                                  name="dataset"
                                />
                              )}
                              renderOption={datasetListRenderOption}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={fetchSelectedDatasetColumnDetails.bind(
                                this,
                                index,
                                arg
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={isHeightHigh ? 6 : 12}
                            mb={isHeightHigh ? 0 : 2}
                          >
                            <ConsoleArgumentInput
                              type={arg.type}
                              datasetColumns={
                                arg.dataset?.table_display_name
                                  ? arg.datasetColumns
                                  : []
                              }
                              models={allModels}
                              allDatasets={publishedDatasets}
                              value={arg.value}
                              placeholder={
                                !arg?.value?.length
                                  ? 'Select dataset variables'
                                  : ''
                              }
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={updateSelectedUdfArg.bind(
                                this,
                                index,
                                arg
                              )}
                              fetchConsoleFileList={fetchConsoleFileList}
                              activeProject={activeProject}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={isHeightHigh ? 0 : 12}>
                          <ConsoleArgumentInput
                            type={arg.type}
                            datasetColumns={arg.datasetColumns}
                            models={allModels}
                            allDatasets={publishedDatasets}
                            value={arg.value}
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={updateSelectedUdfArg.bind(
                              this,
                              index,
                              arg
                            )}
                            placeholder={utils.getPlaceholderTextByArgType(
                              arg.type
                            )}
                            sx={LABEL_STYLES}
                            label={
                              <>
                                {`${arg?.name} (${arg.type})*`}
                                <Tooltip
                                  title={<Box>{arg.description}</Box>}
                                  placement="top"
                                  arrow
                                  sx={{ marginRight: '4px' }}
                                >
                                  <ErrorOutlineIcon
                                    color="primary"
                                    fontSize="small"
                                  />
                                </Tooltip>
                              </>
                            }
                            fetchConsoleFileList={fetchConsoleFileList}
                            activeProject={activeProject}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}

            {!selectedUdf?.args?.length ? (
              <Box mb={2}>
                {selectedUdf ? (
                  <Typography>
                    No argument are required for selected function.
                  </Typography>
                ) : null}
              </Box>
            ) : null}

            {selectedUdf ? (
              <Grid {...gridPropsOne}>
                <Grid {...gridPropsTwo}>
                  <Grid item xs={isHeightHigh ? 0 : 12}>
                    <Autocomplete
                      popupIcon={<DropdownArrow />}
                      disableClearable
                      id="cpu-field"
                      name="cpufield"
                      noOptionsText="No CPU field available."
                      value={pod}
                      onChange={cpuFieldOnchange}
                      options={PodSpecs}
                      renderInput={cpuFieldRenderInput}
                      renderOption={cpuFieldRenderOption}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {selectedUdf ? (
              <Box display="flex" flexGrow={1} flexDirection="column" mt={1}>
                <Box
                  alignSelf="flex-start"
                  title={
                    permission !== 'Manage'
                      ? 'You are not authorized to access this!'
                      : ''
                  }
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={createConsoleJob}
                    disabled={permission !== 'Manage'}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box mt={2} />
        </Box>
      ) : (
        <Box m={2} position="relative">
          <ConsoleCreateEditUdf
            datasetList={datasetList}
            activeDatasetColumns={activeDatasetColumns}
            activeDataset={activeDataset}
            activeProject={activeProject}
            moduleName={moduleName}
            subModuleName={subModuleName}
            createUdf={createUdf}
            updateUdf={updateUdf}
            fetchUdfs={fetchAllUdfs}
            runUdf={runUdf}
            fetchDatasetColumnDetails={fetchDatasetColumnDetails}
            udf={selectedUdf}
            onBackClick={() => {
              setActiveView('list');
              setSelectedUdf(null);
            }}
            githubValue={githubValue}
            setGithubValue={setGithubValue}
            mode={createEditUdfMode}
            setMode={setCreateEditUdfMode}
            allProjects={allProjects}
            allModels={allModels}
            fetchTableData={fetchTableData}
            fetchConsoleLibraries={fetchConsoleLibraries}
            fetchConsoleFileList={fetchConsoleFileList}
          />
        </Box>
      )}
      {activeView === 'list' && asyncTaskResult !== null ? (
        <Grid container>
          <Grid item xs={12} ml={2} mr={2} overflow="scroll">
            <ConsoleOperationsResultViewer
              taskResult={asyncTaskResult?.results}
              activeDataset={activeDataset}
              showCommentBox
              fetchTableData={fetchTableData}
              permission={permission}
              downloadTableData={downloadTableData}
              isPagination={true}
              customCell={CustomCell}
              fetchDatasetRowsWithPagination={fetchDatasetRowsWithPagination}
              isLoading={isLoading}
              projectId={activeProject?.project_id}
              activeProject={activeProject}
              datasetList={datasetList?.length ? datasetList : []}
              permissions={permission}
              taskResultDatasets={asyncTaskResult?.artifacts?.outputs.filter(
                (data) => data?.type === 'dataset'
              )}
              fetchAllDatasets={fetchDatasetList}
              markDatasetAsReadyToUse={markDatasetAsReadyToUse}
              fetchModelMetaDataSchema={fetchModelMetaDataSchema}
              addResultToModelMetaData={addResultToModelMetaData}
            />
          </Grid>
        </Grid>
      ) : null}
      <Dialog
        open={showDeleteModal}
        aria-labelledby="customized-dialog-title"
        maxWidth="xs"
        fullWidth
        onClose={() => setShowDeleteModal(false)}
        TransitionComponent={DialogTransition}
      >
        <DeleteConfirmModal
          open={showDeleteModal}
          onCloseClick={() => setShowDeleteModal(false)}
          onDeleteClick={(e) => handleDeleteUDF(e)}
          confirmationMsg={`Are you sure you want to delete ${deleteUdfName.name} ?`}
          resourceName="UDF"
        />
      </Dialog>
    </>
  );
};

export default Console;
