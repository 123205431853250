// @ts-ignore
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import useConsole from '../Console/useConsole';
import ApiEndpoints from '../../const/ApiEndpoints';
import useProject from '../Project/useProject';
import utils from '../../utils/utils';
import { SwalToast } from '../../components/Common';
// import { REGULATIONS } from '../../const/CommonConst';

const ME_BASE_URL = ApiEndpoints.MODULE_BASE_URL.ME;

export default function useUDFManager() {
  // For Active Dataset
  const [activeDataset, setActiveDataset] = useState('');
  const [consoleResultDetail, setConsoleResultDetail] = useState(null);
  const { datasetList, fetchDatasetList, project } = useProject();
  const [fileList, setFileList] = useState([]);
  const [totalFileList, setTotalFileList] = useState(0);
  const [fileListPageCount, setFileListPageCount] = useState(0);
  const [udfJobList, setUdfJobList] = useState([]);
  const [totalUdfJobList, setTotalUdfJobList] = useState(0);
  const [udfJobListPageCount, setUdfJobListPageCount] = useState(0);

  const { activeProject } = useConsole();

  useEffect(() => {
    if (activeDataset?.id) {
      datasetList.forEach((data) => {
        if (data.id === activeDataset?.id) {
          setActiveDataset(data);
        }
      });
    }
  }, [datasetList]);

  // For Active Operation
  const [activeOperation, setActiveOperation] = useState(
    MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.AT.operations.AT
  );
  // For Column Details of Active Dataset
  const [datasetColumnDetails, setDatasetColumnDetails] = useState([]);
  const [udfs, setUdfs] = useState([]);
  // For Response Data : Graphs or Tables or Table ID
  const [taskDetails, updateTaskDetails] = useState({});
  const [consoleModuleTasks, setConsoleModuleTasks] = useState([]);

  // all models data
  const [allModelData, setAllModleData] = useState([]);
  // Target variables/columns for dropdown
  const [targetVariables, setTargetVariables] = useState([]);
  const [fileType, setFileType] = useState(null);
  const [checkError, setCheckError] = useState(false);
  const [description, setDescription] = useState(null);
  const [selectedUdf, setSelectedUdf] = useState(null);
  const [udfCompareData, setUdfCompareData] = useState(null);
  const [jobs, setJobs] = useState([]);

  const [commitList, setCommitList] = useState([]);
  const [commitListPageCount, setCommitListPageCount] = useState(0);
  const [totalCommitList, setTotalCommitList] = useState(0);

  /**
   * @function CommentApiCall
   * @description API call for saving comments in documentations.
   * @param {*} body
   * @returns {Promise} AxiosPromise
   * @example CommentApiCall(body)
   */
  const CommentApiCall = async (body) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UDF_SAVE_CHART_COMMENT
        }`,
        body
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  // console documentation related api calls
  /**
   * @function fetchTables
   * @description API call for fetching tables in documentations.
   * @param {object} params
   * @returns {Promise} AxiosPromise
   * @example fetchTables(params)
   */
  const fetchTables = (params) =>
    axios
      .get(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.GET_TABLE,
        {
          params,
        }
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function fetchCharts
   * @description API call for fetching charts in documentations.
   * @param {object} params
   * @returns {Promise} AxiosPromise
   * @example fetchCharts
   */
  const fetchCharts = (params) =>
    axios
      .get(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.GET_CHART,
        {
          params,
        }
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function createUdf
   * @description API call for creating UDF
   * @param {object} payload - takes UDF details as payload.
   * @returns {Promise} AxiosPromise
   * @example createUdf(payload)
   */
  const createUdf = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.CREATE_UDF
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function updateUdf
   * @description API call for updating/editing the UDF details.
   * @param {object} payload - takes udf details along with udf id.
   * @returns {Promise} AxiosPromise
   * @example updateUdf(payload)
   */
  const updateUdf = (payload) =>
    axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UPDATE_UDF
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function getRepoStructure
   * @description API call for fetching repo structure of UDF.
   * @param {object} payload takes udf_id as payload
   * @returns {Promise} AxiosPromise
   * @example getRepoStructure(payload);
   */
  const getRepoStructure = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.GET_REPO_STRUCTURE
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function saveFileCode
   * @description API call for saving codes in UDF Manager.
   * @param {object} payload - takes udf_id, file_path and code as payload.
   * @returns {Promise} AxiosPromise
   * @example SaveFileCode(payload);
   */
  const saveFileCode = (payload) =>
    axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UPDATE_CODE
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchDatasetColumnDetails
   * @description API call for fetching dataset column details
   * @param {object} datasetName - object containing dataset_name and project_id
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetColumnDetails(datasetName);
   */
  const fetchDatasetColumnDetails = (datasetName) => {
    return axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.MT +
          ApiEndpoints.MT.DATASET_COLUMN_DETAILS
        }`,
        datasetName
      )
      .then(
        (res) => {
          setDatasetColumnDetails(res?.data?.columns);
          return res;
        },
        () => {}
      );
  };

  /**
   * @function findActiveDataset
   * @description API call for finding active Dataset
   * @param {string} datasetId - dataset id
   * @returns {Promise} AxiosPromise
   * @example findActiveDataset(datasetId);
   */
  const findActiveDataset = useCallback(
    (datasetId) => {
      return datasetList.find((dataset) => dataset.id === datasetId);
    },
    [datasetList]
  );

  /**
   * @function findActiveOperation
   * @description API call for finding the current operations
   * @param {object} operationKey current operation key
   * @returns {undefined}
   * @example findActiveOperation(operationKey)
   */
  const findActiveOperation = (operationKey) => {
    const operations = Object.values(
      MODULE_SUB_MODULE_OPERATIONS_TREE.MT.subModules.AT.operations
    );
    return operations.find((data) => data.key === operationKey);
  };

  /**
   * @function handleSuccess
   * @description API call once task details result API is sucessful
   * @param {object} additionTaskDetails
   * @param {object} successCallback
   * @returns {undefined}
   * @example handleSuccess
   */
  const handleSuccess = (additionTaskDetails, successCallback) => {
    return (res) => {
      if (res.data.task_status === 'SUCCESS') {
        const activeOperationData = findActiveOperation(
          additionTaskDetails.operation
        );
        setActiveOperation(activeOperationData);

        if (res.data?.parameters?.datasetId) {
          const activeDatasetId = findActiveDataset(
            res.data.parameters.datasetId
          );
          setActiveDataset(activeDatasetId);
        }

        updateTaskDetails(res.data);
        successCallback();
      } else {
        updateTaskDetails({});
      }
    };
  };

  /**
   * @function fetchAsyncResult
   * @description API call for fetching AsyncResult
   * @param {object}
   * @returns {Promise} AxiosPromise
   * @example fetchAsyncResult(taskID, additionTaskDetails, successCallback)
   */
  const fetchAsyncResult = (taskID, additionTaskDetails, successCallback) => {
    return axios
      .get(`${ME_BASE_URL + ApiEndpoints.MT.GET_SS_TASKS}/${taskID}`)
      .then(handleSuccess(additionTaskDetails, successCallback))
      .catch(() => {});
  };

  /**
   * @function fetchSavedModelList
   * @description API call for fetching saved model list
   * @param {object} params - params for fetching saved model list
   * @returns {Promise} AxiosPromise
   * @example fetchSavedModelList(params);
   */
  const fetchAllModels = (params) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.ME +
          ApiEndpoints.ME.ME_GET_SAVED_MODEL_LIST
        }`,
        {
          params,
        }
      )
      .then(
        (res) => res?.data,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchAllModelDetails
   * @description API call to get all models details associated with the active project.
   * @param {number} projectId - projectId of the active project.
   * @returns {Promise} AxiosPromise
   * @example fetchAllModelDetails(projectId);
   */

  const fetchAllModelDetails = (projectId) => {
    if (projectId) {
      axios
        .get(
          `${ME_BASE_URL}${ApiEndpoints.MT.MT_SS_ALL_MODELS_DETAILS}?project_id=${projectId}`
        )
        .then(
          (res) => {
            setAllModleData(res?.data);
          },
          () => {}
        );
    }
    // no return statement
  };

  /**
   * @function downloadConsoleFile
   * @description API call for downloading console file
   * @param {number} fileId - id for file to be downloaded
   * @returns {Promise} AxiosPromise
   * @example downloadConsoleFile(fileId);
   */
  const downloadConsoleFile = (fileId) => {
    const Endpoint = `${ApiEndpoints.UDF_MANAGER.DOWNLOAD_FILE}?id=${fileId}`;

    return axios.get(`${ApiEndpoints.MODULE_BASE_URL.CONSOLE}${Endpoint}`).then(
      (resp) => resp,
      (error) => {
        throw error;
      }
    );
  };

  /**
   * @function deleteConsoleFileWithId
   * @description API call for deleting console file with id
   * @param {number} fileId - console file id
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example deleteConsoleFileWithId (fileId, successCallback)
   */
  const deleteConsoleFileWithId = (fileObject) => {
    return axios
      .delete(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.DELETE_FILE
        }`,
        {
          data: {
            id: fileObject.id,
          },
        }
      )
      .then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: `${fileObject.name} deleted successfully.`,
          });
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };
  /**
   * @function fetchConsoleFileList
   * @description API call for fetching console file list
   * @param {number} projectId - project id for which files list to be fetched
   * @returns {Promise} AxiosPromise
   * @example fetchConsoleFileList(projectId,successCallback);
   */
  const fetchConsoleFileList = (
    projectId,
    pageIndex = 0,
    pageSize = 20,
    searchText = ''
  ) => {
    const Endpoint = projectId
      ? `${ApiEndpoints.UDF_MANAGER.FILES}?project_id=${projectId}`
      : ApiEndpoints.UDF_MANAGER.FILES;

    return axios
      .get(`${ApiEndpoints.MODULE_BASE_URL.CONSOLE}${Endpoint}`, {
        params: {
          page: pageIndex + 1,
          ...(pageSize ? { pagesize: pageSize } : {}),
          ...(searchText ? { search_term: searchText } : {}),
        },
      })
      .then(
        (res) => {
          if (res.data) {
            setFileList(res.data);
            setTotalFileList(res.headers['x-total-count']);
            setFileListPageCount(
              Math.ceil(res.headers['x-total-count'] / pageSize)
            );
          } else {
            setFileList([]);
            setTotalFileList(0);
            setFileListPageCount(0);
          }
          return res;
        },
        (error) => {
          setFileList([]);
          setTotalFileList(0);
          setFileListPageCount(0);
          throw error;
        }
      );
  };

  /**
   * @function fetchConsoleFileListAll
   * @description API call for fetching console file list
   * @param {number} projectId - project id for which files list to be fetched
   * @returns {Promise} AxiosPromise
   * @example fetchConsoleFileListAll(projectId,successCallback);
   */
  const fetchConsoleFileListAll = (projectId) => {
    const Endpoint = projectId
      ? `${ApiEndpoints.UDF_MANAGER.FILES}?project_id=${projectId}`
      : ApiEndpoints.UDF_MANAGER.FILES;

    return axios.get(`${ApiEndpoints.MODULE_BASE_URL.CONSOLE}${Endpoint}`).then(
      (res) => {
        return res;
      },
      (error) => {
        throw error;
      }
    );
  };

  /**
   * @function initiateUploadToS3ForConsoleFiles
   * @description POST API call for initiate console files upload to S3
   * @param {object} fileDetails - details of file
   * @param {function} successCallback - Callback function to be called on success
   * @example initiateUploadToS3ForConsoleFiles(fileDetails,successCallback)
   */
  const initiateUploadToS3ForConsoleFiles = (fileDetails, successCallback) => {
    const filePayload = { ...fileDetails };
    filePayload.fileType = fileType;
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UPLOAD_CONSOLEFILE
        }`,
        filePayload
      )
      .then(
        (resp) => {
          setCheckError(false);
          if (successCallback) {
            successCallback(resp);
          }
        },
        (error) => {
          setCheckError(true);
          throw error;
        }
      );
  };
  /**
   * @function uploadChunkForConsoleFiles
   * @description PUT API call for upload each chunk to cloud storage
   * @param {object} chunkDetails - details of chunk data
   * @param {object} config - config data
   * @returns {Promise} AxiosPromise
   * @example uploadChunkForConsoleFiles(chunkDetails, config)
   */
  const uploadChunkForConsoleFiles = (chunkDetails, config) => {
    return axios.put(
      `${
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
        ApiEndpoints.UDF_MANAGER.UPLOAD_CHUNK
      }`,
      chunkDetails,
      config
    );
  };
  /**
   * @function onCompleteMultipartUploadToS3ForConsoleFiles
   * @description API call for complete multipart upload to S3
   * @param {object} fileChunksSummary - summary of chunk files
   * @param {function} successCallback - Callback function to execute upon completion of API call
   *  when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example onCompleteMultipartUploadToS3ForConsoleFiles(fileChunksSummary,successCallback)
   */
  const onCompleteMultipartUploadToS3ForConsoleFiles = (
    fileChunksSummary,
    successCallback
  ) => {
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UPLOAD_COMPLETED
        }`,
        {
          ...fileChunksSummary,
          project_id: activeProject?.project_id,
          filetype: fileType,
          description,
        }
      )
      .then(
        (res) => {
          setCheckError(false);
          successCallback(res);
        },
        (error) => {
          throw error;
        }
      );
  };
  const { startUpload, progress } = useMultipartFileUploadToS3({
    startUploadCallback: initiateUploadToS3ForConsoleFiles,
    uploadChunk: uploadChunkForConsoleFiles,
    onCompleteMultipartUpload: onCompleteMultipartUploadToS3ForConsoleFiles,
  });

  /**
   * @function fetchUdfs
   * @description fetches the udfs for a given project
   * @param {String} projectId - the project id
   * @returns {Promise} AxiosPromise
   * @example fetchUdfs(projectId, udfId)
   */

  const fetchUdfs = (projectId, udfId) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.FETCH_UDFS
        }`,
        {
          params: {
            project_id: projectId,
            udf_id: udfId,
          },
          loader: true,
        }
      )
      .then(
        (resp) => {
          setUdfs(resp?.data?.data);
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchUdfDetails
   * @description API  call for fetching the details of a particular UDF
   * @param {number} udfId - takes udf id as payload
   * @returns {Promise} AxiosPromise
   * @example fetchUdfDetails(udfId)
   */
  const fetchUdfDetails = (udfId) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.FETCH_UDFS
        }`,
        {
          params: {
            udf_id: udfId,
          },
          loader: true,
        }
      )
      .then(
        (resp) => {
          return resp?.data?.data;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchUdfDetailsCommitHistory
   * @description API call for fetching commits of the udf
   * @param {number} udfId - takes udfId as payload
   * @returns {Promise} AxiosPromise
   * @example fetchUdfDetailsCommitHistory(udfId)
   */
  const fetchUdfDetailsCommitHistory = (udfId, pageIndex = 0, pageSize = 5) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.FETCH_UDF_COMMIT
        }`,
        {
          params: {
            page: pageIndex + 1,
            ...(udfId ? { udf_id: udfId } : {}),
            ...(pageSize ? { pagesize: pageSize } : {}),
          },
          loader: true,
        }
      )
      .then(
        (resp) => {
          // return resp.data.data;
          if (resp?.data?.data) {
            const commits = resp?.data?.data.filter((item) => {
              const exists = item?.files?.find(
                (commit) => commit?.filename === 'main.ipynb'
              );

              if (exists) return true;

              return false;
            });
            setCommitList(commits);
            setTotalCommitList(resp?.headers['x-total-count']);
            setCommitListPageCount(
              Math.ceil(resp?.headers['x-total-count'] / pageSize)
            );
          } else {
            setCommitList([]);
            setTotalCommitList(0);
            setCommitListPageCount(0);
          }
          return resp?.data?.data;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function runUdf
   * @description API call for running the UDF
   * @param {object}payload - takes udf id as payload
   * @returns {Promise} AxiosPromise
   * @example runUdf(payload)
   */
  const runUdf = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.RUN_UDF
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchResultUdfList
   * @description API call for fetching tasks list of UDF
   * @param {string} udfName takes udf of the udf whose task we need.
   * @returns {Promise} AxiosPromise
   * @example fetchResultUdfList(udfName)
   */
  const fetchResultUdfList = (udfName) => {
    if (udfName) {
      return axios
        .get(
          `${
            ApiEndpoints.MODULE_BASE_URL.CONSOLE +
            ApiEndpoints.UDF_MANAGER.GET_TASKS_UDF
          }`,
          {
            params: {
              operation: udfName,
            },
            loader: true,
          }
        )
        .then(
          (res) => {
            return res?.data;
          },
          (error) => {
            throw error;
          }
        );
    }
  };

  /**
   * @function fetchResultUdfListCompare
   * @description API call for fetching tasks list of UDF
   * @param {string} udfName takes udf of the udf whose task we need.
   * @returns {Promise} AxiosPromise
   * @example fetchResultUdfListCompare(udfName, pageIndex, pageSize, taskStatus)
   */
  const fetchResultUdfListCompare = (
    udfName,
    pageIndex = 0,
    pageSize = 20,
    taskStatus = 'SUCCESS'
  ) => {
    if (udfName) {
      return axios
        .get(
          `${
            ApiEndpoints.MODULE_BASE_URL.CONSOLE +
            ApiEndpoints.UDF_MANAGER.GET_TASKS_UDF
          }`,
          {
            params: {
              operation: udfName,
              page: pageIndex + 1,
              ...(pageSize ? { pagesize: pageSize } : {}),
              ...(taskStatus
                ? { task_status: taskStatus }
                : { task_status: '' }),
            },
          }
        )
        .then(
          (res) => {
            if (res?.data) {
              setUdfJobList(res?.data);
              setTotalUdfJobList(res?.headers['x-total-count']);
              setUdfJobListPageCount(
                Math.ceil(res?.headers['x-total-count'] / pageSize)
              );
            } else {
              setUdfJobList([]);
              setTotalUdfJobList(0);
              setUdfJobListPageCount(0);
            }
            return res;
          },
          (error) => {
            setUdfJobList([]);
            setTotalUdfJobList(0);
            setUdfJobListPageCount(0);
            throw error;
          }
        );
    }
  };

  /**
   * @function fetchModuleTasksConsole
   * @description API call for fetching module tasks
   * @param {number} projectId - project id
   * @param {boolean} hideLoaderOnRequest - hiding loader on request
   * @returns {Promise} AxiosPromise
   * @example  fetchModuleTasksConsole(project_id,hideLoaderOnRequest)
   */
  const fetchModuleTasksConsole = (projectId, hideLoaderOnRequest) => {
    if (typeof projectId === 'number') {
      axios
        .get(
          `${
            ApiEndpoints.MODULE_BASE_URL.CONSOLE +
            ApiEndpoints.UDF_MANAGER.GET_TASKS_UDF
          }`,
          {
            params: {
              project_id: projectId,
            },
            loader: !hideLoaderOnRequest,
          }
        )
        .then(
          (res) => {
            setConsoleModuleTasks(res?.data);
            return res;
          },
          (err) => {
            setConsoleModuleTasks([]);
            throw err;
          }
        );
    }
  };

  /**
   * @function udfSharing
   * @description API call for sharing the UDFs
   * @param {object} payload - takes models, datasets, documents, project_id, recipient, notes,
   * @returns {Promise} AxiosPromise
   * @example udfSharing(payload)
   */
  const udfSharing = (payload) =>
    axios
      .post(ApiEndpoints.MODULE_BASE_URL.FTS + ApiEndpoints.FTS.UDF_SHARING, {
        ...payload,
      })
      .then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: res?.data?.msg,
          });
          return res;
        },
        (error) => {
          throw error;
        }
      );
  /**
   * @function udfCloning
   * @description API call for cloning the UDF
   * @param {object} payload - takes id, udf_name, description in payload,
   * @returns {Promise} AxiosPromise
   * @example udfCloning(payload)
   */
  const udfCloning = (payload) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.CLONE_UDF,
        {
          ...payload,
        }
      )
      .then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: res?.data?.msg,
          });
          return res;
        },
        (error) => {
          throw error;
        }
      );

  const validateGithubCredintials = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.VALIDATE_CREDENTIALS
        }`,
        payload
      )
      .then(
        (resp) => {
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function udfCommitContent
   * @description API call for fetching commit content of udf
   * @param {object} payload - takes commit hash of the commit whose content user want
   * @returns {Promise} AxiosPromise
   * @example udfCommitContent(payload)
   */
  const udfCommitContent = (payload) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.GET_COMMIT_FILE,
        {
          ...payload,
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function deleteRunningSession
   * @description API call for stopping the run session of UDF
   * @param {number} udfId -UDF id of the udf whose run session we want to stop
   * @returns {Promise} AxiosPromise
   * @example deleteRunningSession(udfId)
   */
  const deleteRunningSession = (udfId) => {
    return axios
      .delete(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.DELETE_SESSION
        }`,
        {
          data: {
            id: udfId,
          },
        }
      )
      .then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: res?.data?.message,
          });
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function deleteUDF
   * @description API call deleting the udf
   * @param {number} udfId - takes UDF id of the udf which is needed to be deleted.
   * @returns {Promise} AxiosPromise
   * @example deleteUDF(udfId)
   */
  const deleteUDF = (udfId) => {
    return axios
      .delete(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.DELETE_UDF
        }`,
        {
          data: {
            udf_id: udfId,
          },
        }
      )
      .then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: res?.data?.msg,
          });
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function reRunUDF
   * @description API call for re running the udf tasks
   * @param {object} payload - takes task_id as payload
   * @returns {Promise} AxiosPromise
   * @example reRunUDF(payload)
   */
  const reRunUDF = (payload) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.RERUN_UDF,
        {
          ...payload,
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function saveRunUDF
   * @description API call for saving the code after run.
   * @param {object} payload - it takes UDF_id as a payload
   * @returns {Promise} AxiosPromise
   * @example saveRunUDF(payload)
   */
  const saveRunUDF = (payload) =>
    axios
      .put(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.SAVE_RUN,
        {
          id: Number(payload),
        }
      )
      .then(
        (res) => {
          SwalToast({
            icon: 'success',
            title: res?.data?.msg,
          });
          return res;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function executeUdf
   * @description API call for executing the UDF tasks
   * @param {object} payload - args, udf_id, project_id and pod_spec
   * @returns {Promise} AxiosPromise
   * @example executeUdf(payload)
   */
  const executeUdf = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.EXECUTE_UDF
        }`,
        payload
      )
      .then(
        (resp) => {
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function terminateConsoleTask
   * @description API call for deleting the UDF task
   * @param {string} taskId - task id of the task user want to terminate
   * @returns {Promise} AxiosPromise
   * @example terminateConsoleTask(taskId)
   */
  const terminateConsoleTask = (taskId, project_id) => {
    return axios
      .delete(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.DELETE_TASK
        }`,
        {
          data: {
            task_id: taskId,
            project_id,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };

  /**
   * @function fetchUDFTaskDetails
   * @description API to fetch task details of UDF
   * @param {string} taskID - task id of the task
   * @returns {Promise} AxiosPromise
   * @example fetchUDFTaskDetails(taskId)
   */
  const fetchUDFTaskDetails = (taskID) => {
    return axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.UDF_TASK_DETAILS
        }`,
        {
          params: {
            task_id: taskID,
          },
          loader: true,
        }
      )
      .then(
        (res) => {
          if (res?.data?.task_status === 'SUCCESS') {
            // setActiveOperation(data);
            setConsoleResultDetail(res?.data);
          } else {
            setConsoleResultDetail({});
          }
          return res;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function fetchAsyncResultConsoleAndActivateDataset
   * @description API call for fetching asynchronous result
   * @param {string}  taskID - task id
   * @returns  {Promise} AxiosPromise
   * @example  fetchAsyncResultConsoleAndActivateDataset(taskID)
   */
  const fetchAsyncResultConsoleAndActivateDataset = (taskID) =>
    fetchUDFTaskDetails(taskID).then(
      (res) => {
        if (res?.data?.task_status === 'SUCCESS') {
          if (utils.isNewDatasetCreatedByUdf(res.data)) {
            fetchDatasetList(project.activeProject?.project_id);
          }
          if (res?.data?.parameters?.table) {
            datasetList.forEach((dataset) => {
              if (dataset.Name === res.data.parameters.table) {
                setActiveDataset({ ...dataset });
              }
            });
          }
        }
        return res;
      },
      (error) => {
        throw error;
      }
    );

  /**
   * @function tableDetails
   * @description API call for table details
   * @param {number} tableID - id of tables.
   * @returns {Promise} AxiosPromise
   * @example tableDetails
   */
  const tableDetails = (tableID) => {
    return axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.GET_TABLE_DATA
        }`,
        {
          params: {
            id: tableID,
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw error;
      });
  };

  /**
   * @function markTaskReadUnreadConsole
   * @description API call for mark task read unread of
   *  performance testing sub module job queue tasks
   * @param {object} taskParams -task parameters of mark task read unread of sampling sub module
   * @returns {Promise} AxiosPromise
   * @example markTaskReadUnreadConsole(taskParams);
   */
  const markTaskReadUnreadConsole = (taskParams) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.JOB_QUEUE_MARK_READ_UNREAD
        }`,
        {
          params: taskParams,
        }
      )
      .then(
        (res) => {
          return res?.data;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchTableData
   * @description API call for fetching table data page wise
   * @param {number} tableId id of table
   * @param {number} pageNumber the no. of page you want to fetch data of
   * @param {number} pageSize the sixe of page.
   * @returns {Promise} AxiosPromise
   * @example fetchTableData(tableId, pageNumber, pageSize)
   */
  const fetchTableData = (tableId, pageNumber, pageSize) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.GET_TABLE_DATA
        }`,
        {
          params: {
            id: tableId,
            page: pageNumber + 1,
            pagesize: pageSize,
          },
        }
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function onDownloadCSV
   * @description API call for downloading the tables created as result in UDF
   * @param {object} data conatins id of dataset and project_id
   * @returns {Promise} AxiosPromise
   * @example onDownloadCSV(data)
   */
  const onDownloadCSV = (data) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.UDF_MANAGER.DOWNLOAD_CSV,
        {
          id: data?.id,
          project_id: data?.project_id,
        }
      )
      .then(
        (response) => {
          SwalToast({
            icon: 'success',
            title:
              'Started preparing download link. Please check downloads page for further details.',
          });
          return response;
        },
        (err) => {
          throw err;
        }
      );

  /**
   * @function fetchDatasetColumnDetailsForConsole
   * @description API call for fetching dataset column details
   * @param {object} payload - data for fetching dataset column details
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetColumnDetailsForConsole(body,successCallback);
   */
  const fetchDatasetColumnDetailsForConsole = (payload) => {
    if (payload?.dataset_id && payload?.project_id) {
      return axios
        .post(
          `${
            ApiEndpoints.MODULE_BASE_URL.CONSOLE +
            ApiEndpoints.CONSOLE.DATASET_COLUMN_DETAILS
          }`,
          {
            project_id: payload.project_id,
            dataset_id: payload.dataset_id,
          }
        )
        .then(
          (resp) => resp,
          (error) => {
            throw error;
          }
        );
    }
  };

  return {
    udfs,
    setUdfs,
    selectedUdf,
    setSelectedUdf,
    fetchUdfs,
    fetchUdfDetails,
    createUdf,
    updateUdf,
    getRepoStructure,
    runUdf,
    udfSharing,
    executeUdf,
    terminateConsoleTask,
    fetchUDFTaskDetails,
    activeDataset,
    setActiveDataset,

    consoleModuleTasks,
    fetchResultUdfList,
    fetchResultUdfListCompare,
    udfCompareData,
    setUdfCompareData,
    tableDetails,
    jobs,
    setJobs,
    markTaskReadUnreadConsole,
    fetchModuleTasksConsole,
    udfCommitContent,
    deleteRunningSession,
    deleteUDF,
    reRunUDF,
    saveRunUDF,
    udfCloning,
    fetchAllModels,
    fetchTables,
    fetchCharts,
    setConsoleResultDetail,
    consoleResultDetail,
    CommentApiCall,
    // consoleRunTaskResults,
    fileList,
    fileListPageCount,
    totalFileList,
    udfJobList,
    totalUdfJobList,
    udfJobListPageCount,
    validateGithubCredintials,
    fetchTableData,
    onDownloadCSV,
    startUpload,
    progress,
    deleteConsoleFileWithId,
    setFileType,
    activeProject,
    setDescription,
    downloadConsoleFile,
    checkError,
    fetchDatasetColumnDetails,
    setDatasetColumnDetails,
    fetchAllModelDetails,
    fetchAsyncResultConsoleAndActivateDataset,
    fetchAsyncResult,
    fetchUdfDetailsCommitHistory,
    activeOperation,
    datasetColumnDetails,
    targetVariables,
    setTargetVariables,
    allModelData,
    taskDetails,
    datasetList,
    fetchDatasetList,
    fetchConsoleFileList,
    fetchConsoleFileListAll,
    project,
    saveFileCode,
    commitList,
    commitListPageCount,
    totalCommitList,
    fetchDatasetColumnDetailsForConsole,
  };
}
