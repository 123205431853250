/* eslint-disable react/prop-types */

import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  Box,
  Divider,
  Drawer,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { withTransaction } from '@elastic/apm-rum-react';
import PropTypes from 'prop-types';

import urlToHelpSectionModuleTagMapping from '../../const/HelpSectionConst';
import useHelpSection from '../../Hooks/HelpSection/useHelpSection';
import TransperentSearchBox from '../../components/Common/TransperentSearchBox';
import SwalToast from '../../components/Common/SwalToast';
import { ReactComponent as ExternalLink } from '../../assets/images/external-link.svg';
import { ReactComponent as NoDataFound } from '../../assets/images/NoDataFound.svg';
import useCommon from '../../Hooks/useCommon';
import utils from '../../utils/utils';

const NumberOfResultstoShow = 5;
const MoreResultsInDescription = 3;
const handleFocus = (e, theme) => {
  e.currentTarget.style.border = `2px solid ${theme.palette.secondary.main}`;
};
const handleBlur = (e, theme) => {
  e.currentTarget.style.border = `2px solid ${theme?.palette?.grey?.[300]}`;
};

const handleMouseEnter = (e, themeObject) => {
  e.currentTarget.style.backgroundColor = themeObject.palette.other.grey1;
};

const handleMouseLeave = (e, themeObject) => {
  e.currentTarget.style.backgroundColor = themeObject.palette.other.white;
};

const showContentWithElipses = (content, maxWidth) => {
  if (content.length <= maxWidth) {
    return content;
  }
  return `${content.slice(0, maxWidth - 3)}...`;
};

/**
 * @function SettingsModule
 * @description Renders a module for displaying article title with descriptions.
 * @param {object} props - Props passed to the component.
 * @param {Array} props.content - Array of content items.
 * @param {function} props.setShowDescription - Function to set whether to show the description.
 * @param {boolean} props.showDescription - Flag indicating whether to show the description.
 * @param {number} props.numberOfresults - Number of results to display.
 * @param {object} props.themeObject - Theme object for styling.
 * @returns {JSX.Element} A React component representing the article title with descriptions.
 */
const SettingsModule = ({
  content,
  setShowDescription,
  showDescription,
  numberOfresults,
  themeObject,
  setIsUserSuperadmin,
}) => {
  const [descriptionKey, setDescriptionKey] = useState('');
  const handleStripClick = useCallback(
    (index) => {
      setDescriptionKey(index);
      setShowDescription(true);
    },
    [setDescriptionKey, setShowDescription]
  );

  const handleClickWithIndex = useCallback(
    (index) => () => {
      handleStripClick(index);
    },
    [handleStripClick]
  );

  const handleClickLink = useCallback(() => {
    setIsUserSuperadmin();
  }, []);
  return (
    <Box>
      {!showDescription &&
        content?.map(
          (item, index) =>
            index < numberOfresults * NumberOfResultstoShow && (
              <Box
                px={0}
                py={0}
                key={item?.id}
                role="button"
                tabIndex={0}
                onClick={handleClickWithIndex(index)}
                sx={{
                  borderRadius: '8px',
                  marginTop: '23px',
                  width: '424px',
                  height: '71px',
                  cursor: 'pointer',
                  marginLeft: '11px',
                  marginRight: '16px',
                }}
                onMouseEnter={(e) => handleMouseEnter(e, themeObject)}
                onMouseLeave={(e) => handleMouseLeave(e, themeObject)}
              >
                <Box
                  sx={{
                    marginLeft: '5px',
                    marginTop: '10px',
                    height: '16px',
                  }}
                >
                  <Typography
                    variant="body1"
                    color={themeObject?.palette?.other?.brandBlue}
                  >
                    {item?.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingTop: '8px',
                    marginLeft: '5px',
                    height: '34px',
                    opacity: '60%',
                    width: '397px',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      margin: '0px',
                      padding: '0px',
                      paddingBottom: '6px',
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      maxHeight: '3em',
                      lineHeight: '16px',
                      color: themeObject?.palette?.other?.brandBlue,
                      textAlign: 'justify',
                    }}
                  >
                    {showContentWithElipses(
                      content?.[index]?.content
                        ?.repeat(10)
                        .replace(/<[^>]+>/g, ''),
                      109
                    )}
                  </Typography>
                </Box>
              </Box>
            )
        )}
      {showDescription && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          marginBottom="26px"
        >
          <Box
            sx={{
              marginLeft: '16px',
              marginRight: '16px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                lineHeight: '16px',
                height: '16px',
              }}
            >
              {content?.[descriptionKey]?.title}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: '14px',
              marginLeft: '16px',
              marginRight: '16px',
              overflow: 'hidden',
              height: '200px',
            }}
          >
            <Typography
              display="inline-block"
              variant="body2"
              sx={{
                lineHeight: '22px',
                textAlign: 'justify',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '416px',
              }}
            >
              {showContentWithElipses(
                content?.[descriptionKey]?.content?.replace(/<[^>]+>/g, ''),
                530
              )}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop="16px">
            <Typography
              sx={{
                color: themeObject?.palette?.secondary?.contrastText,
                marginLeft: '16px',
                maxWidth: '262px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant="body1"
            >
              Learn more about the {content?.[descriptionKey]?.title}
            </Typography>
            <Link
              sx={{
                color: themeObject?.palette?.secondary?.contrastText,
                cursor: 'pointer',
                marginLeft: '8px',
                marginTop: '2px',
              }}
              href={`/help?nodeId=${content[descriptionKey]?.id}`}
              target="_blank"
              onClick={handleClickLink}
            >
              <ExternalLink />
            </Link>
          </Box>
          <Divider sx={{ marginTop: '20px' }} />
          <Typography
            variant="subtitle1"
            sx={{
              marginLeft: '16px',
              paddingTop: '24px',
            }}
          >
            Related articles
          </Typography>
          {content?.map(
            (item, index) =>
              index !== Number(descriptionKey) &&
              index <= Number(descriptionKey + MoreResultsInDescription) && (
                <Box
                  px={0}
                  py={0}
                  key={item?.id}
                  role="button"
                  tabIndex={0}
                  onClick={handleClickWithIndex(index)}
                  sx={{
                    borderRadius: '8px',
                    marginTop: '23px',
                    width: '424px',
                    height: '71px',
                    cursor: 'pointer',
                    marginLeft: '11px',
                    marginRight: '16px',
                  }}
                  onMouseEnter={(e) => handleMouseEnter(e, themeObject)}
                  onMouseLeave={(e) => handleMouseLeave(e, themeObject)}
                >
                  <Box
                    sx={{
                      marginLeft: '5px',
                      marginTop: '10px',
                      height: '16px',
                    }}
                  >
                    <Typography variant="body1">{item.title}</Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: '8px',
                      marginLeft: '5px',
                      width: '397px',
                      height: '34px',
                      opacity: '60%',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        margin: '0px',
                        padding: '0px',
                        paddingBottom: '6px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '3em',
                        lineHeight: '16px',
                        color: themeObject?.palette?.other?.brandBlue,
                      }}
                    >
                      {showContentWithElipses(
                        content?.[index]?.content?.replace(/<[^>]+>/g, ''),
                        110
                      )}
                    </Typography>
                  </Box>
                </Box>
              )
          )}
        </Box>
      )}
    </Box>
  );
};

/**
 * @function HelpSectionDrawer
 * @description Renders a side drawer for the help section with search functionality.
 * @param {object} props - Props passed to the component.
 * @param {function} props.setShowHelpSectionDrawer - Function to set whether to show the help section drawer.
 * @param {boolean} props.showHelpSectionDrawer - Flag indicating whether to show the help section drawer.
 * @param {string} props.moduleName - Name of the module.
 * @returns {JSX.Element} A React component representing the help section drawer.
 */
const HelpSectionDrawer = ({
  setShowHelpSectionDrawer,
  showHelpSectionDrawer,
  moduleName,
}) => {
  const currentModule = utils?.getModuleNameFromURL();
  const { common, dispatch, setHelpDrawerKeywordSearch } = useCommon();
  const theme = useTheme();
  const { getHierarchy, setIsUserSuperadmin } = useHelpSection();
  const [content, setContent] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [numberOfresults, setNumberOfresults] = useState(1);
  const [lengthOfContent, setLengthOfContent] = useState(0);
  const [remainingContent, setRemainingContent] = useState(0);
  const [tag, setTag] = useState([]);

  useEffect(() => {
    if (!moduleName) {
      if (
        ['project', 'documentation', 'udf-manager'].includes(currentModule[0])
      ) {
        setTag(urlToHelpSectionModuleTagMapping[currentModule[0]]);
      } else {
        setTag(
          urlToHelpSectionModuleTagMapping[currentModule[0]][currentModule[1]]
        );
      }
    }
  }, [window.location.href, setTag, moduleName]);

  useEffect(() => {
    if (moduleName?.length > 0 || tag?.length > 0) {
      getHierarchy({
        id: null,
        tags: moduleName?.length ? moduleName : tag,
        search: null,
      })
        .then((resp) => {
          setContent(resp);
          setLengthOfContent(resp?.length);
        })
        .catch((error) => {
          SwalToast({
            icon: 'error',
            title: error,
          });
        });
    }
  }, [tag]);

  const debounce = (callback, delay) => {
    const timeoutRef = useRef(null);
    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);
    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
    return debouncedCallback;
  };

  /**
   * @function handleSearchContent
   * @description Handles the change event for the search input and updates the Redux store.
   * @param {Event} _event - The event object representing the change event.
   * @returns {void}
   */
  const handleSearchContent = useCallback(
    (_event) => {
      if (_event?.target?.value?.length > 0) {
        dispatch(setHelpDrawerKeywordSearch(_event.target.value));
      } else {
        dispatch(setHelpDrawerKeywordSearch(''));
      }
    },
    [dispatch, setHelpDrawerKeywordSearch]
  );

  /**
   * @function handleSearch
   * @description Debounced version of the search functionality to reduce api calls.
   * @returns {void}
   */
  const handleSearch = debounce(() => {
    if (moduleName?.length > 0 || tag?.length > 0) {
      if (common.helpDrawerKeywordSearch?.length) {
        setShowDescription(false);
        getHierarchy({
          id: null,
          tags: null,
          search: common.helpDrawerKeywordSearch,
        })
          .then((resp) => {
            setNumberOfresults(1);
            setContent(resp);
            setLengthOfContent(resp?.length);
          })
          .catch((error) => {
            SwalToast({
              icon: 'error',
              title: error,
            });
          });
      } else {
        getHierarchy({
          id: null,
          tags: moduleName?.length ? moduleName : tag,
          search: null,
        })
          .then((resp) => {
            setContent(resp);
            setLengthOfContent(resp?.length);
          })
          .catch((error) => {
            SwalToast({
              icon: 'error',
              title: error,
            });
          });
      }
    }
  }, 1000);
  useEffect(() => {
    handleSearch();
  }, [common.helpDrawerKeywordSearch]);
  const handleBackToDescription = useCallback(() => {
    setShowDescription(false);
  }, [setShowDescription]);

  const handleShowHelpSectionDrawer = useCallback(() => {
    setShowHelpSectionDrawer(false);
  }, [setShowHelpSectionDrawer]);

  const handleCLoseDrawer = useCallback(() => {
    setShowDescription(false);
    setShowHelpSectionDrawer(false);
    setNumberOfresults(1);
  }, [setShowDescription, setShowHelpSectionDrawer, setNumberOfresults]);

  const handleShowMore = useCallback(() => {
    setNumberOfresults((prevState) => prevState + 1);
  }, [numberOfresults, setNumberOfresults]);

  useEffect(() => {
    setRemainingContent(
      (Math.floor(lengthOfContent / NumberOfResultstoShow) - numberOfresults) *
        NumberOfResultstoShow
    );
  }, [numberOfresults, lengthOfContent, setRemainingContent]);
  return (
    <Drawer
      anchor="right"
      open={showHelpSectionDrawer}
      onClose={handleShowHelpSectionDrawer}
      sx={{
        '& .MuiPaper-root': {
          width: '451px',
          height: '100%',
        },
      }}
    >
      <Grid marginTop="70px" overflow="hidden">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              marginLeft: '16px',
            }}
          >
            {showDescription && (
              <IconButton onClick={handleBackToDescription}>
                <ArrowBackIcon
                  color="primary"
                  sx={{
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            )}
            <Typography variant="body1">Help Section</Typography>
          </Box>
          <Box mr="10px">
            <IconButton onClick={handleCLoseDrawer}>
              <CloseIcon
                color="primary"
                sx={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box marginTop="10px">
          <Divider />
        </Box>
        <Box
          marginTop="16px"
          display="flex"
          flexDirection="row"
          marginLeft="16px"
          marginBottom="24px"
          sx={{
            width: '419px',
            height: '48px',
            border: `2px solid ${theme?.palette?.grey?.[300]}`,
            borderRadius: '8px',
            outline: 'none',
            transition: 'border-color 0.3s ease',
          }}
          onFocus={(e) => handleFocus(e, theme)}
          onBlur={(e) => handleBlur(e, theme)}
        >
          <TransperentSearchBox
            value={common.helpDrawerKeywordSearch}
            sx={{
              marginTop: '0px',
            }}
            onChange={handleSearchContent}
            placeholder="Search what you need"
          />
        </Box>
        {!showDescription && common.helpDrawerKeywordSearch?.length > 0 && (
          <Box
            sx={{
              marginBottom: '10px',
              marginLeft: '16px',
            }}
          >
            {!showDescription && lengthOfContent !== 0 && (
              <>
                <Typography
                  variant="body2"
                  color={theme?.palette?.other?.brandBlue}
                >
                  Showing results from content containing
                </Typography>{' '}
                <Typography
                  variant="body1"
                  color={theme?.palette?.other?.brandBlue}
                >{`'${common.helpDrawerKeywordSearch}'`}</Typography>
              </>
            )}
          </Box>
        )}
        {!showDescription &&
          common.helpDrawerKeywordSearch?.length > 0 &&
          lengthOfContent === 0 && (
            <>
              <Box
                sx={{
                  marginBottom: '10px',
                  marginLeft: '37%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '35%',
                }}
              >
                {!showDescription && lengthOfContent === 0 && <NoDataFound />}
              </Box>
              <Box sx={{ marginBottom: '10px', marginLeft: '33%' }}>
                {!showDescription && lengthOfContent === 0 && (
                  <>
                    <Typography variant="body2">
                      No matching result found.
                    </Typography>
                  </>
                )}
              </Box>
            </>
          )}
        <Box
          sx={{
            height: '600px',
            overflow: 'scroll',
          }}
        >
          <SettingsModule
            content={content}
            showDescription={showDescription}
            setShowDescription={setShowDescription}
            numberOfresults={numberOfresults}
            themeObject={theme}
            setIsUserSuperadmin={setIsUserSuperadmin}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: '16px',
              marginTop: '24px',
              marginBottom: '32px',
            }}
          >
            {!showDescription &&
              numberOfresults * NumberOfResultstoShow < lengthOfContent && (
                <Typography
                  sx={{
                    marginLeft: '16px',
                    color: theme?.palette?.secondary?.contrastText,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={handleShowMore}
                >
                  Show{' '}
                  {remainingContent >= NumberOfResultstoShow
                    ? NumberOfResultstoShow
                    : lengthOfContent -
                      numberOfresults * NumberOfResultstoShow}{' '}
                  more articles
                </Typography>
              )}
          </Box>
        </Box>
      </Grid>
    </Drawer>
  );
};

HelpSectionDrawer.propTypes = {
  setShowHelpSectionDrawer: PropTypes.func.isRequired,
  showHelpSectionDrawer: PropTypes.bool.isRequired,
  moduleName: PropTypes.string.isRequired,
};
export default withTransaction(
  'HelpSectionDrawer',
  'component'
)(HelpSectionDrawer);
