import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as ADicon } from '../assets/images/SidebarIcons/BlackSvgLogos/AutomatedDocumentationDark.svg';
import { ReactComponent as CPicon } from '../assets/images/SidebarIcons/PrimaryColorSvgLogos/CodePipline.svg';
import { ReactComponent as CPicon2 } from '../assets/images/SidebarIcons/PrimaryColorSvgLogos/CodePipelinedark.svg';

// Sidebar Svgicons import
import { ReactComponent as DMicon } from '../assets/images/SidebarIcons/BlackSvgLogos/DataManagementDark.svg';
import { ReactComponent as EDAicon } from '../assets/images/SidebarIcons/BlackSvgLogos/EDA.svg';
import { ReactComponent as FEicon } from '../assets/images/SidebarIcons/BlackSvgLogos/FeatureEngineering.svg';
import { ReactComponent as MEicon } from '../assets/images/SidebarIcons/BlackSvgLogos/ModelEstimationDark.svg';
import { ReactComponent as MIicon } from '../assets/images/SidebarIcons/BlackSvgLogos/ModelInterpretationDark.svg';
import { ReactComponent as MTicon } from '../assets/images/SidebarIcons/BlackSvgLogos/ModelTesting.svg';
import { ReactComponent as POicon } from '../assets/images/SidebarIcons/BlackSvgLogos/ProjectOverview.svg';
import { ReactComponent as SSicon } from '../assets/images/SidebarIcons/BlackSvgLogos/Sampling&SegmentationDark.svg';
import { ReactComponent as VRicon } from '../assets/images/SidebarIcons/BlackSvgLogos/VariableReduction.svg';
import { ReactComponent as MMDicon } from '../assets/images/SidebarIcons/BlackSvgLogos/ModelMonitoringDashboard.svg';
import { ReactComponent as DownloadingIcon } from '../assets/images/SidebarIcons/BlackSvgLogos/Downloading.svg';
import { ReactComponent as SettingsOutlinedIcon } from '../assets/images/SidebarIcons/BlackSvgLogos/SettingsOutlined.svg';
import { ReactComponent as ModelDeployment } from '../assets/images/SidebarIcons/BlackSvgLogos/ModelDeployment.svg';
import { ReactComponent as ModelManager } from '../assets/images/SidebarIcons/BlackSvgLogos/modelmanager.svg';
import { ReactComponent as UDFManager } from '../assets/images/SidebarIcons/BlackSvgLogos/UDFManager.svg';

/* APP SIDEBAR ICONS */
export const ProjectOverViewIcon = (props) => <POicon {...props} />;
export const DataManagementIcon = (props) => <DMicon {...props} />;
export const EdaIcon = (props) => <EDAicon {...props} />;
export const FeatureEngineeringIcon = (props) => <FEicon {...props} />;
export const SamplingAnfSegmentationIcon = (props) => <SSicon {...props} />;
export const VariableReductionIcon = (props) => <VRicon {...props} />;
export const ModelTestingIcon = (props) => <MTicon {...props} />;
export const ModelEstimationIcon = (props) => <MEicon {...props} />;
export const ModelDeploymentIcon = (props) => <ModelDeployment {...props} />;
export const ModelManagerIcon = (props) => <ModelManager {...props} />;
export const ModelInterpretationIcon = (props) => (
  // <AiFillInteraction {...props} />
  <MIicon {...props} />
);
export const ModelMonitoringDashboardIcon = (props) => (
  <MMDicon {...props} width={21} height={21} />
);
export const AutomatedDocumentationIcon = (props) => <ADicon {...props} />;
export const Downloading = (props) => <DownloadingIcon {...props} />;
export const UdfManager = (props) => <UDFManager {...props} />;
export const CodePipline = (props) => <CPicon {...props} />;
export const CodePipelinedark = (props) => <CPicon2 {...props} />;
export const CloseButtonIcon = (props) => <CloseIcon {...props} />;

// ⚙
export const SettingGearIcon = (props) => <SettingsOutlinedIcon {...props} />;
