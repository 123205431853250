import axios from 'axios';
import ApiEndpoints from '../../const/ApiEndpoints';

import useUserGroupProvisioning from '../UserGroupProvisioning/useUserGroupProvisioning';

import utils from '../../utils/utils';

export default function useHelpSection() {
  const { userId } = utils.getUserDetailsFromStorage();
  const { fetchAllUsers } = useUserGroupProvisioning();

  /**
   * if user belongs to  superadmin group, then it stores IsUserSuperAdmin  true in localstorage
   * @returns void
   */
  const setIsUserSuperadmin = () => {
    fetchAllUsers().then((resp) => {
      if (resp?.data?.users) {
        const currentUser = resp?.data?.users?.find(
          (user) => user?.id === userId
        );
        if (currentUser) {
          const isUserAdmin = true;
          if (isUserAdmin) {
            localStorage.setItem(
              'IsUserSuperAdmin',
              JSON.stringify(isUserAdmin)
            );
          }
        }
      }
    });
  };

  /**
   * Retrieves hierarchy data based on provided payload.
   * @param {Object} payload - Payload containing parameters for the API request.
   * @param {string|null} payload.id - Identifier of the node to retrieve hierarchy data for.
   * @param {string[]|null} payload.tags - Array of tags to filter the hierarchy data.
   * @param {string|null} payload.search - Search string to filter the hierarchy data.
   * @returns {Promise<Object>} Promise representing the API response containing hierarchy data.
   * @throws {Error} Throws an error if the API request fails.
   */
  const getHierarchy = (payload) => {
    const tagsString = payload?.tags ? payload.tags.join(',') : null;

    return axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.DOC + ApiEndpoints.DOC.DOC_HELP_SECTION
        }`,
        {
          params: {
            id: payload?.id,
            tags: tagsString,
            search: payload?.search,
          },
          // @ts-ignore
          loader: true,
        }
      )
      .then(
        (resp) => {
          return resp.data;
        },
        (error) => {
          throw error;
        }
      );
  };

  /**
   * Adds a new section with the provided details.
   * @param {Object} payload - Payload containing data for the new section.
   * @param {string} payload.title - Title of the new section.
   * @param {string} payload.content - Content of the new section.
   * @param {string[]} [payload.tags] - Array of tags for the new section.
   * @param {string|null} [payload.childOf] - Identifier of the parent node for the new section.
   * @returns {Promise<Object>} Promise representing the API response for adding the new section.
   * @throws {Error} Throws an error if the API request fails.
   */
  const addNewSection = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.DOC + ApiEndpoints.DOC.DOC_HELP_SECTION
        }`,
        {
          title: payload?.title,
          content: payload?.content,
          tags: payload?.tags,
          childOf: payload?.childOf,
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * Saves the file structure data.
   * @param {Object} payload - Payload containing the formatted data response.
   * @param {any} payload.formattedDataResponse - The formatted data response to be saved.
   * @returns {Promise<Object>} Promise representing the API response for saving the file structure.
   * @throws {Error} Throws an error if the API request fails.
   */
  const saveFileStructure = (payload) => {
    return axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.DOC +
          ApiEndpoints.DOC.SAVE_FILE_STRUCTURE
        }`,
        { format: payload?.formattedDataResponse }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * Updates the section with the provided details.
   * @param {Object} payload - Payload containing data for updating the section.
   * @param {string} payload.id - Identifier of the section to update.
   * @param {string} payload.title - New title for the section.
   * @param {string} payload.content - New content for the section.
   * @param {string[]} [payload.tags] - New tags for the section.
   * @returns {Promise<Object>} Promise representing the API response for updating the section.
   * @throws {Error} Throws an error if the API request fails.
   */
  const updateSection = (payload) => {
    return axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.DOC + ApiEndpoints.DOC.DOC_HELP_SECTION
        }`,
        {
          id: payload?.id,
          title: payload?.title,
          content: payload?.content,
          tags: payload?.tags,
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * Deletes the node with the specified ID.
   * @param {string} id - Identifier of the node to delete.
   * @returns {Promise<Object>} Promise representing the API response for deleting the node.
   * @throws {Error} Throws an error if the API request fails.
   */
  const deleteNode = (id) => {
    return axios
      .delete(
        `${
          ApiEndpoints.MODULE_BASE_URL.DOC + ApiEndpoints.DOC.DOC_HELP_SECTION
        }`,
        { data: { id } }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };
  return {
    setIsUserSuperadmin,
    getHierarchy,
    addNewSection,
    updateSection,
    deleteNode,
    saveFileStructure,
  };
}
