import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const stabilityMetrics = ['PSI', 'CSI'];
const timeSeriesMetrics = ['ME', 'RMSE', 'MAPE', 'MAE'];

const ColorBox = ({ index, metric }) => {
  let displayText;
  let color;

  if (index === 0) {
    displayText =
      stabilityMetrics.includes(metric) || timeSeriesMetrics.includes(metric)
        ? 'RED'
        : 'GREEN';
    color =
      stabilityMetrics.includes(metric) || timeSeriesMetrics.includes(metric)
        ? 'red'
        : '#3fcc2d';
  } else if (index === 1) {
    displayText = 'AMBER';
    color = '#FFBF00';
  } else {
    displayText =
      stabilityMetrics.includes(metric) || timeSeriesMetrics.includes(metric)
        ? 'GREEN'
        : 'RED';
    color =
      stabilityMetrics.includes(metric) || timeSeriesMetrics.includes(metric)
        ? '#3fcc2d'
        : 'red';
  }

  return (
    <Box
      color="white"
      bgcolor={color}
      width={100}
      padding={0.5}
      textAlign="center"
      borderRadius="4px"
      fontWeight={700}
    >
      {displayText.toUpperCase()}
    </Box>
  );
};

ColorBox.propTypes = {
  index: PropTypes.number.isRequired,
  metric: PropTypes.string.isRequired,
};
export default ColorBox;
