// @ts-nocheck
import axios from 'axios';
import ApiEndpoints from '../../const/ApiEndpoints';

/**
 * Custom hook for user group provisioning.
 *
 * @returns {{
 *   fetchAllUsers: () => Promise<any>,
 *   fetchAllGroups: () => Promise<any>,
 *   blockUser: (status: string, id: string) => Promise<any>,
 *   createGroup: (body: object) => Promise<any>,
 *   updateGroup: (body: object, id: string) => Promise<any>,
 *   updateUser: (body: object, id: string) => Promise<any>,
 *   createUserProfile: (body: object) => Promise<any>,
 *   deleteGroup: (id: string) => Promise<any>
 * }} Object containing functions for managing user group provisioning.
 */
export default function useUserGroupProvisioning() {
  const BASE_URL = ApiEndpoints.MODULE_BASE_URL;

  /**
   * @function fetchAllUsers
   * @description API call for fetching user list.
   * @returns {promise} AxiosPromise
   * @example fetchUserSProfile()
   */
  const fetchAllUsers = () =>
    axios.get(`${BASE_URL.DI}${ApiEndpoints.AUTH.USER_PROFILE}`).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   *
   * @function blockUser
   * @description API call for blocking user.
   * @param {string} status - The action status.
   * @param {string} id - The user's id.
   * @returns {Promise<any>} Axios promise containing the response.
   * @example
   * blockUser('block', 'userId').then(response => {...});
   */

  const blockUser = (status, id) =>
    axios
      .post(`${BASE_URL.DI}${ApiEndpoints.AUTH.BLOCK_USER}`, {
        action: status,
        user_id: id,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function createUserProfile
   * @description API call for creating new user.
   * @param {object} body - object containing details of new users.
   * @returns {promise} AxiosPromise
   * @example createUserProfile(body)
   */
  const createUserProfile = (body) =>
    axios.post(`${BASE_URL.DI}${ApiEndpoints.AUTH.CREATE_USER}`, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   * @function updateUser
   * @description API call for updating users profile
   * @param {object} body - object containing details of user
   * @param {string} id - user's id
   * @returns {promise} AxiosPromise
   * @example updateUser(body,id)
   */
  const updateUser = (body, id) =>
    axios
      .post(`${BASE_URL.DI}${ApiEndpoints.AUTH.UPDATE_USER}`, {
        user_id: id,
        ...body,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchAllGroups
   * @description API call for fetching group details
   * @returns {promise} AxiosPromise
   * @example fetchAllGroups()
   */
  const fetchAllGroups = () =>
    axios.get(`${BASE_URL.DI}${ApiEndpoints.AUTH.GROUP_PROFILE}`).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );
  const fetchAccessLogs = (params) =>
    axios
      .get(`${BASE_URL.DI}${ApiEndpoints.AUTH.ACCESS_LOGS}`, {
        params,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );
  const fetchUserStats = () =>
    axios.get(`${BASE_URL.DI}${ApiEndpoints.AUTH.USER_STATS}`).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );
  const downloadCsvAccessLogs = (param) => {
    return axios
      .get(`${BASE_URL.DI}${ApiEndpoints.AUTH.DOWNLOAD_CSV}`, {
        params: {
          start_date: param.startDate,
          end_date: param.endDate,
          text: param.text,
        },
      })
      .then((res) => {
        return res;
      });
  };
  /**
   * @function fetchAuditLogs
   * @description API call for fetching audit logs
   * @returns {promise} AxiosPromise
   * @example fetchAuditLogs(params)
   */
  const fetchAuditLogs = (params) =>
    axios
      .get(`${BASE_URL.DI}${ApiEndpoints.AUTH.AUDIT_LOGS}`, {
        params,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );
  /**
   * @function downloadCsvAuditLogs
   * @description API call for downloading audits logs data
   * @returns {promise} AxiosPromise
   * @example downloadCsvAuditLogs(param)
   */
  const downloadCsvAuditLogs = (param) => {
    return axios
      .get(`${BASE_URL.DI}${ApiEndpoints.AUTH.DOWNLOAD_CSV_AUDIT_LOGS}`, {
        params: {
          start_date: param.startDate,
          end_date: param.endDate,
          text: param.text,
        },
      })
      .then((res) => {
        return res;
      });
  };
  // Create group
  const createGroup = (body) =>
    axios.post(`${BASE_URL.DI}${ApiEndpoints.AUTH.CREATE_GROUP}`, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  /**
   * @function updateGroup
   * @description API call for updating group profile
   * @param {object} body - object containing details of group
   * @param {string} id - group's id
   * @returns {promise} AxiosPromise
   * @example updateGroup(body,id)
   */
  const updateGroup = (body, id) =>
    axios
      .post(`${BASE_URL.DI}${ApiEndpoints.AUTH.UPDATE_GROUP}`, {
        group_id: id,
        ...body,
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  // Delete group
  const deleteGroup = (id) =>
    axios
      .delete(`${BASE_URL.DI}${ApiEndpoints.AUTH.DELETE_GROUP}`, {
        data: {
          group_id: id,
        },
      })
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  // Change Password
  const changePassword = (id) =>
    axios
      .get(`${BASE_URL.DI}${ApiEndpoints.AUTH.CHANGE_PASSWORD}?user_id=${id}`)
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );

  // Save Changed Password
  const saveChangedPassword = (body) =>
    axios.post(`${BASE_URL.DI}${ApiEndpoints.AUTH.CHANGE_PASSWORD}`, body).then(
      (res) => res,
      (error) => {
        throw error;
      }
    );

  return {
    fetchAllUsers,
    fetchAllGroups,
    blockUser,
    createGroup,
    updateGroup,
    updateUser,
    createUserProfile,
    deleteGroup,
    changePassword,
    saveChangedPassword,
    fetchAccessLogs,
    downloadCsvAccessLogs,
    fetchAuditLogs,
    downloadCsvAuditLogs,
    fetchUserStats,
  };
}
