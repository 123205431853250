// @ts-nocheck
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Dialog,
  Button,
  IconButton,
  Typography,
  TextField,
  DialogContent,
  DialogActions,
  Autocomplete,
  DialogTitle,
} from '@mui/material';
import { isEmpty } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { object, string, array, lazy } from 'yup';
import { useFormik } from 'formik';
import DialogTransition from './Transitions/DialogTransition';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
import ConsoleArgumentInput from './ConsoleArgumentInput';
import REGEX from '../../const/Regex';
import utils from '../../utils/utils';

const MAX_CHARACTER_SIZE = 225;
const TYPES_OF_ARGS = [
  {
    label: 'Console File',
    value: 'ConsoleFiles',
  },
  {
    label: 'Dataset',
    value: 'Dataset',
  },
  {
    label: 'Column',
    value: 'Column',
  },
  {
    label: 'Model',
    value: 'Model',
  },
  {
    label: 'String',
    value: 'String',
  },
  {
    label: 'Int',
    value: 'Int',
  },
  {
    label: 'Float',
    value: 'Float',
  },
  {
    label: 'Boolean',
    value: 'Boolean',
  },
];

/**
  @function ConsoleCreateEditArgModal
 * @description ConsoleCreateEditArgModal- child component of console Creating modal.
 * @param  {function} handleClose - close model
 * @param  {function} fetchConsoleFileList - fetching console list
 * @param {boolean} open -open variable
 * @param {function} handleClose - changing state of open variable from null to false
 * @param {object} editArgument -argument object
 * @param {function} activeProject -activating project
 * @param {function} argFormikForm - console formikform
 * @returns {JSX} ConsoleCreateEditArgModal UI
 * @example <ConsoleCreateEditArgModal open={open}
 */

const formValidationSchema = object({
  name: string()
    .required('Required')
    .max(MAX_CHARACTER_SIZE, 'Only 225 characters are allowed.'),
  type: object()
    .shape({
      label: string().required('Required'),
      value: string().required('Required'),
    })
    .required('Required'),
  dataset: object().when('type', (type) => {
    if (type?.value === 'Column') {
      return object()
        .shape({
          Name: string().required('Required'),
          id: string().required('Required'),
        })
        .required('Required');
    }
    return object();
  }),
  datasetColumns: array().of(object()),
  value: lazy((val) => {
    if (Array.isArray(val)) {
      return array().required('Required').min(1, 'Required');
    }
    if (typeof val === 'object') {
      return object().nullable().required('Required.');
    }
    return string().required('Required');
  }),
  description: string()
    .required('Required')
    .max(250, 'Only 250 characters are allowed.')
    .matches(REGEX.NO_SPACE, 'Please enter a valid description.'),
});
function ConsoleCreateEditArgModal({
  open = false,
  handleClose = () => {},
  handleArgumentSubmit,
  allModels,
  datasetList,
  activeProject,
  fetchConsoleFileList,
  fetchDatasetColumnDetails,
  editArgument,
  mode,
}) {
  const argFormikForm = useFormik({
    initialValues: {
      name: '',
      type: '',
      value: '',
      dataset: '',
      description: '',
      datasetColumns: [],
    },
    validationSchema: formValidationSchema,
    onSubmit: (value) => {
      handleArgumentSubmit(value);
      handleClose();
      argFormikForm.resetForm();
    },
  });

  useEffect(() => {
    if (mode) {
      argFormikForm.setValues({
        ...editArgument,
        name: editArgument?.name,
        type: editArgument?.type,
        value: editArgument?.value,
        description: editArgument?.description,
      });
      const timerId = setTimeout(() => {
        argFormikForm.validateForm();
      }, 1000);
      return () => clearTimeout(timerId);
    }
  }, [editArgument, mode]);
  const handleArgumentType = (_event, val) => {
    argFormikForm.setFieldValue('value', '');
    const tempName = argFormikForm.values.name;
    const tempDesc = argFormikForm.values.description;
    argFormikForm.handleReset();
    argFormikForm.setFieldValue('description', tempDesc);
    argFormikForm.setFieldValue('name', tempName);
    argFormikForm.setFieldValue('type', val ?? '');
  };
  const handleChangeData = (event) => {
    argFormikForm.setFieldValue('value', event?.target?.value);
  };
  const handleDataSetChange = (_event, newValue) => {
    argFormikForm.setFieldValue('dataset', newValue ?? '');
    argFormikForm.setFieldValue('value', '');
    if (newValue?.id) {
      fetchDatasetColumnDetails({
        project_id: activeProject?.project_id,
        datasetId: newValue?.id,
      }).then(
        (resp) => {
          argFormikForm.setFieldValue('datasetColumns', resp?.data?.columns);
        },
        () => {}
      );
    }
  };

  const handleArgModalClose = useCallback(() => {
    handleClose();
    argFormikForm.resetForm();
  }, [handleClose, argFormikForm]);
  const onClickCloseIcon = useCallback(() => {
    handleClose();
    argFormikForm.resetForm();
  }, [handleClose, argFormikForm]);

  return (
    <Dialog
      onClose={handleArgModalClose}
      aria-labelledby="console-add-lib-modal"
      open={open}
      fullWidth
      TransitionComponent={DialogTransition}
    >
      <DialogTitle id="console-add-lib-modal">
        {mode ? 'Edit' : 'Add'} Argument
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
        onClick={onClickCloseIcon}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              value={argFormikForm?.values?.name}
              name="name"
              label="Argument name *"
              placeholder="Enter argument name"
              {...argFormikForm.getFieldProps('name')}
              helperText={
                argFormikForm?.errors?.name && argFormikForm?.touched?.name
                  ? argFormikForm?.errors?.name
                  : null
              }
              error={
                Boolean(argFormikForm?.errors?.name) &&
                argFormikForm?.touched?.name
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              options={TYPES_OF_ARGS}
              popupIcon={<DropdownArrow />}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              disableClearable={['', undefined].includes(
                argFormikForm?.values?.type
              )}
              noOptionsText="No argument type available."
              placeholder="Select argument type"
              value={argFormikForm?.values?.type}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Argument type *"
                  placeholder="Select argument type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={argFormikForm.handleBlur}
                  name="type"
                  helperText={
                    argFormikForm?.touched?.type &&
                    argFormikForm?.errors?.type?.value
                      ? argFormikForm?.errors?.type.value
                      : ''
                  }
                  error={
                    Boolean(argFormikForm?.errors?.type) &&
                    argFormikForm?.touched?.type
                  }
                />
              )}
              renderOption={(props, option) => (
                <Box display="flex" flexGrow={1} {...props}>
                  <Typography noWrap>{option.label}</Typography>
                </Box>
              )}
              onChange={handleArgumentType}
            />
          </Grid>
          {argFormikForm?.values?.type?.value === 'Column' ? (
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={
                  datasetList
                    ? datasetList.filter((data) => data?.published === true)
                    : []
                }
                popupIcon={<DropdownArrow />}
                disableClearable={
                  ['', undefined, {}].includes(
                    argFormikForm?.values?.dataset
                  ) || isEmpty(argFormikForm?.values?.dataset)
                }
                getOptionLabel={(option) =>
                  option?.table_display_name ? option?.table_display_name : ''
                }
                isOptionEqualToValue={(option, value) =>
                  option.table_display_name === value.table_display_name
                }
                value={
                  argFormikForm?.values?.dataset
                    ? argFormikForm?.values?.dataset
                    : {}
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={argFormikForm.handleBlur}
                    name="dataset"
                    label="Dataset *"
                    placeholder="Select dataset"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      argFormikForm?.touched?.dataset &&
                      argFormikForm?.errors?.dataset?.Name
                        ? argFormikForm?.errors?.dataset?.Name
                        : ''
                    }
                    error={
                      Boolean(argFormikForm?.errors?.dataset?.Name) &&
                      argFormikForm?.touched?.dataset
                    }
                  />
                )}
                renderOption={(props, option, index) => (
                  <Box display="flex" flexGrow={1} {...props} key={index}>
                    <Typography noWrap>{option?.table_display_name}</Typography>
                  </Box>
                )}
                noOptionsText="No dataset available."
                onChange={handleDataSetChange}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <ConsoleArgumentInput
              type={argFormikForm?.values?.type?.value}
              value={
                argFormikForm?.values?.type ? argFormikForm?.values?.value : ''
              }
              datasetColumns={argFormikForm?.values?.datasetColumns}
              allDatasets={datasetList}
              models={allModels}
              onChange={handleChangeData}
              placeholder={
                !argFormikForm?.values?.value?.length
                  ? utils.getPlaceholderTextByArgType(
                      argFormikForm?.values?.type?.value
                    )
                  : ''
              }
              label={utils.getLabelTextByArgType(
                argFormikForm?.values?.type?.value
              )}
              handleBlur={argFormikForm.handleBlur}
              helperText={
                argFormikForm?.touched?.value && argFormikForm?.errors?.value
                  ? argFormikForm?.errors?.value
                  : null
              }
              error={
                Boolean(argFormikForm?.errors?.value) &&
                argFormikForm?.touched?.value
              }
              fetchConsoleFileList={fetchConsoleFileList}
              activeProject={activeProject}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Description"
              value={argFormikForm?.values?.description}
              required
              placeholder="Enter description"
              {...argFormikForm.getFieldProps('description')}
              helperText={
                argFormikForm?.errors?.description &&
                argFormikForm?.touched?.description
                  ? argFormikForm?.errors?.description
                  : null
              }
              error={
                Boolean(argFormikForm?.errors?.description) &&
                argFormikForm?.touched?.description
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={argFormikForm.resetForm}>
          Reset
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={argFormikForm.handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConsoleCreateEditArgModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleArgumentSubmit: PropTypes.func.isRequired,
  allModels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  datasetList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChangeData: PropTypes.func.isRequired,
  fetchConsoleFileList: PropTypes.func.isRequired,
  fetchDatasetColumnDetails: PropTypes.func.isRequired,
  editArgument: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mode: PropTypes.bool.isRequired,
};

export default ConsoleCreateEditArgModal;
