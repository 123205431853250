const TIME_SERIES_ARIMAX_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
];
const TIME_SERIES_ARDL_MARKS = [
  {
    value: 1,
    label: '1',
  },
  // These changes are done under the Ticket NNG-4478 link --> https://solytics.atlassian.net/browse/NNG-4478
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];
const TIME_SERIES_SARIMAX_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
];
const LASSO_REG_ALPHAS_MARKS = [
  {
    value: 0.0001,
    label: '0.0001',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
];
const LEARNING_RATE_MARKS = [
  {
    value: 0.001,
    label: '0.001',
  },
  {
    value: 0.1,
    label: '0.1',
  },
  {
    value: 0.2,
    label: '0.2',
  },
  {
    value: 0.3,
    label: '0.3',
  },
  {
    value: 0.4,
    label: '0.4',
  },
  {
    value: 0.5,
    label: '0.5',
  },
];
const MAX_DEPTH_MARKS = [
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 15.9,
    label: '15.9',
  },
];
const MIN_DATA_IN_LEAF = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];
const XG_BOOST_N_ESTIMATORS = [
  {
    value: 100,
    label: '100',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 300,
    label: '300',
  },
  {
    value: 400,
    label: '400',
  },
  {
    value: 500,
    label: '500',
  },
];
const N_ESTIMATORS_MARKS = [
  {
    value: 100,
    label: '100',
  },
  {
    value: 1000,
    label: '1000',
  },
  {
    value: 2000,
    label: '2000',
  },
  {
    value: 3000,
    label: '3000',
  },
  {
    value: 4000,
    label: '4000',
  },
  {
    value: 5000,
    label: '5000',
  },
  {
    value: 6000,
    label: '6000',
  },
  {
    value: 7000,
    label: '7000',
  },
  {
    value: 8000,
    label: '8000',
  },
  {
    value: 9000,
    label: '9000',
  },
  {
    value: 10000,
    label: '10000',
  },
];
const COL_SAMPLE_BY_LEVEL_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 0.2,
    label: '0.2',
  },
  {
    value: 0.4,
    label: '0.4',
  },
  {
    value: 0.6,
    label: '0.6',
  },
  {
    value: 0.8,
    label: '0.8',
  },
  {
    value: 1,
    label: '1',
  },
];
const MIN_SAMPLE_SPLIT_MARKS = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 11,
    label: '11',
  },
  {
    value: 14,
    label: '14',
  },
];
const XG_BOOST_MAX_DEPTH_MARKS = [
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 16,
    label: '16',
  },
];
const NUM_LEAVES_MARKS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 300,
    label: '300',
  },
  {
    value: 400,
    label: '400',
  },
  {
    value: 500,
    label: '500',
  },
];
const LIGHT_BM_LEARNINGRATE_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 0.1,
    label: '0.1',
  },
  {
    value: 0.2,
    label: '0.2',
  },
  {
    value: 0.3,
    label: '0.3',
  },
  {
    value: 0.4,
    label: '0.4',
  },
  {
    value: 0.5,
    label: '0.5',
  },
];

const D_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
];

const M_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
];
const EXTRA_TREE_MAXDEPTH_MARKS = [
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
];
const KNEIGHBOUR_MARKS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
];

const BIG_DATA_N_FOLD_MARKS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

const BIG_DATA_DEPTH_MARKS = [
  {
    value: 3,
    label: '3',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 15,
    label: '15',
  },
];

const BIG_DATA_BINS_MARKS = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
];

const BIG_DATA_ITERATION_MARKS = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

const HIDDEN_SIZES_MARKS = [
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
];

const DTC_MAX_DEPTH_MARKS = [
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
];

const DTC_RANDOM_STATE_MARKS = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 24,
    label: '24',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 36,
    label: '36',
  },
  {
    value: 42,
    label: '42',
  },
];

const DTC_MLN_MARKS = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
];

const DTC_MSS_MARKS = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 14,
    label: '14',
  },
];

const NB_MARKS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
];

const SVM_C_MARKS = [
  {
    value: 0.01,
    label: '0.01',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];
const TIME_SERIES_GLSAR_MARKS = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 12,
    label: '12',
  },

  {
    value: 16,
    label: '16',
  },

  {
    value: 20,
    label: '20',
  },
];

const MODELTYPE_OPTIONS = ['pinv', 'qr', 'chol'];

const CAT_BOST = ['CatboostClassifier'];
const CAT_BOST_OBJ = {
  cb_low: 0.2,
  cb_upper: 0.3,
  depth_low: 9,
  depth_upper: 10,
  lr_lower: 0.062,
  lr_upper: 0.063,
  mdl_low: 31,
  mdl_upper: 32,
  n_low: 100,
  n_upper: 101,
};
const COV_TYPES = ['nonrobust', 'HC0', 'HC1', 'HC2', 'HC3', 'HAC'];
const WEIGHT_TYPES = [
  'up_weight',
  'down_weight',
  'inverse_variance',
  'inverse_fitted_value',
];
const LIGHT_BM = [
  'LightGBMClassifierXT',
  'LightGBMClassifier',
  'LightGBMClassifierCustom',
];
const LIGHT_BM_OBJ = {
  estimators_lower: 100,
  estimators_upper: 600,
  feature_lower: 0.2,
  feature_upper: 0.3,
  leaves_lower: 100,
  leaves_upper: 190,
  lr_lower: 0.1,
  lr_upper: 0.191,
  min_lower: 100,
  min_upper: 191,
};
const RANDOM_FOREST = [
  'RandomForestClassifierGini',
  'RandomForestClassifierEntr',
];
const RANDOM_FOREST_OBJ = {
  md_low: 8,
  md_up: 9,
  msl_low: 2,
  msl_up: 3,
  mss_low: 3,
  mss_up: 4,
  nest_low: 100,
  nest_up: 101,
  nj_low: 10,
  nj_up: 11,
};
const EXTRA_TREE = ['ExtraTreesClassifierEntr', 'ExtraTreesClassifierGini'];
const EXTRA_TREE_OBJ = {
  md_low: 8,
  md_up: 9,
  msl_low: 2,
  msl_up: 3,
  mss_low: 3,
  mss_up: 4,
  nest_low: 100,
  nest_up: 101,
  nj_low: 200,
  nj_up: 201,
};
const KNEIGHBOUR = ['KNeighborsClassifierDist', 'KNeighborsClassifierUnif'];
const KNEIGHBOUR_OBJ = {
  n_neighbors_low: 10,
  n_neighbors_up: 40,
  nj_low: 99,
  nj_up: 184,
};
const XG_BOOST = ['XGBoost_Classifier'];
const XG_BOOST_OBJ = {
  colsample_bytree_low: 0.2,
  colsample_bytree_up: 0.3,
  lr_low: 0.1,
  lr_up: 0.11,
  md_low: 8,
  md_up: 9,
  nest_low: 200,
  nest_up: 201,
};
const lOGISTIC = ['LogisticRegressionClassifier'];
const lOGISTICOBJ = {
  c_low: 100,
  c_up: 101,
  penalty: 'l1',
};
// time-series models code starts here
const ARIMAX = ['ARIMAX', 'ARIMA'];
const ARIMAX_OBJ = {
  p: 2,
  q: 2,
  d: 1,
};

const SARIMAX = ['SARIMAX'];
const SARIMAX_OBJ = {
  p: 2,
  q: 2,
  d: 1,
  P: 0,
  D: 0,
  Q: 0,
  M: 0,
};

const ARDL = ['ARDL'];
const ARDL_OBJ = {
  lags: 1,
  order: 0,
};
const ARDL_OBJ_MULTIPLE = {
  lags: '',
  order: {},
};
const WLS_REGRESSION_OBJ = {
  method: 'pinv',
  covariance_type: 'nonrobust',
  weight: 'up_weight',
};
const GLSAR_REGRESSION_OBJ = {
  method: 'pinv',
  covariance_type: 'nonrobust',
  rho: 5,
};

const REGRESSION = ['OLS Regression', 'OLS REGRESSION'];
const REGRESSION_OBJ = {
  method: 'pinv',
  covariance_type: 'nonrobust',
};
const GLS_REGRESSION = ['GLS Regression', 'GLS REGRESSION'];
const GLSAR_REGRESSION = ['GLSAR Regression', 'GLSAR REGRESSION'];
// const GLSAR_OBJ = {
//   lags: 1,
//   order: 0,
// };
const WLS_REGRESSION = ['WLS Regression', 'WLS REGRESSION'];
const QUANTILE_REGRESSION = ['Quantile Regression', 'QUANTILE REGRESSION'];
const QUANTILE_OBJECT = {
  vcov: 'robust',
  q: 0.5,
  kernel: 'epa',
  bandwidth: 'hsheather',
};
const VCOV = ['robust', 'iid'];
const KERNEL = ['epa', 'cos', 'gau', 'par'];
const BANDWIDTH = ['hsheather', 'bofinger', 'chamberlain'];

const GLM = ['GLM'];

const GEE = ['GEE'];
const FAMILY_DICT = [
  { label: 'Gaussian', value: 'Gaussian' },
  { label: 'Poisson', value: 'Poisson' },
  { label: 'Binomial', value: 'Binomial' },
  { label: 'Gamma', value: 'Gamma' },
  { label: 'Inverse Gaussian', value: 'InverseGaussian' },
  { label: 'Negative Binomial', value: 'NegativeBinomial' },
  { label: 'Tweedie', value: 'Tweedie' },
];
const LINK_DICT_GAUSSIAN = [
  { label: 'Identity', value: 'identity' },
  { label: 'Log', value: 'log' },
  { label: 'Power', value: 'power' },
];
const LINK_DICT_BINOMIAL = [
  { value: 'cloglog', label: 'C.log-log' },
  { value: 'identity', label: 'Identity' },
  { value: 'log', label: 'Log' },
  { value: 'logit', label: 'Logit' },
  { value: 'probit', label: 'Probit' },
];
const LINK_DICT_NEGATIVE_BINOMAIL = [
  { label: 'Log', value: 'log' },
  { value: 'identity', label: 'Identity' },
  { value: 'power', label: 'Power' },
  { value: 'nbinom', label: 'Nbinom' },
];

const LINK_DICT_TWEEDIE = [
  { value: 'log', label: 'Log' },
  { value: 'power', label: 'Power' },
];
// {label:,value:}
const LINK_DICT = [
  { value: 'cauchy', label: 'Cauchy' },
  { value: 'cloglog', label: 'Cloglog' },
  { value: 'identity', label: 'Identity' },
  { value: 'inverse_power', label: 'Inverse power' },
  { value: 'inverse_squared', label: 'Inverse squared' },
  { value: 'log', label: 'Log' },
  { value: 'logit', label: 'Logit' },
  { value: 'nbinom', label: 'Nbinom' },
  { value: 'probit', label: 'Probit' },
];

const COVSTRUCTURE_DICT = ['Exchangeable', 'Independence'];
const CONVERGENCE_TYPE = [
  { value: 'robust', label: 'Robust' },
  { value: 'naive', label: 'Navie' },
  { value: 'bias_reduced', label: 'Bias Reduced' },
];
const GEE_OBJ = {
  family: 'Gaussian',
  link: 'identity',
  cov_struct: 'Independence',
  cov_type: 'robust',
};

const GLM_OBJ = {
  family: 'Gaussian',
  link: 'identity',
};

// backend needs values to be lowercased so modified value
const STRENGTH_METRICS = [
  { value: 'confusion matrix', label: 'Confusion Matrix' },
  { value: 'specificity & sensitivity', label: 'Specificity & Sensitivity' },
  { value: 'auc', label: 'Auc' },
  { value: 'ks', label: 'Ks' },
  { value: 'gain/lift curve', label: 'Gain/Lift curve' },
  { value: 'vif test', label: 'Vif Test' },
];

// backend needs values to be lowercased so modified value
const STABILITY_METRICS = [
  { value: 'csi', label: 'CSI' },
  { value: 'psi', label: 'PSI' },
  { value: 'rank order', label: 'Rank Order' },
];

const EVAL_METRICS = [
  { value: 'roc_auc', label: 'roc_auc' },
  { value: 'accuracy', label: 'accuracy' },
  { value: 'f1', label: 'f1' },
  { value: 'f1_macro', label: 'f1_macro' },
  { value: 'f1_micro', label: 'f1_micro' },
  { value: 'f1_weighted', label: 'f1_weighted' },
];

const ME_MODEL_INITIATION_SAMPLING_SLIDER = {
  slider: {
    label: 'Base',
    isDropDown: false,
    Range: [],
    max: 100,
    min: 1,
    default: 50,
    step: 1,
    marks: [
      {
        value: 0,
        label: '0%',
      },
      {
        value: 10,
        label: '10%',
      },
      {
        value: 20,
        label: '20%',
      },
      {
        value: 30,
        label: '30%',
      },
      {
        value: 40,
        label: '40%',
      },
      {
        value: 50,
        label: '50%',
      },
      {
        value: 60,
        label: '60%',
      },
      {
        value: 70,
        label: '70%',
      },
      {
        value: 80,
        label: '80%',
      },
      {
        value: 90,
        label: '90%',
      },
      {
        value: 100,
        label: '100%',
      },
    ],
  },
};

const ME_PARAMETRS_MODAL_SLIDER_DATA = {
  slider: {
    isDropDown: false,
    max: 1,
    Range: [0.2, 0.8],
    min: 0,
    step: 0.1,
    default: [0.2, 0.8],
    marks: [
      {
        value: 0,
        label: '0',
      },
      {
        value: 0.2,
        label: '0.2',
      },
      {
        value: 0.4,
        label: '0.4',
      },
      {
        value: 0.6,
        label: '0.6',
      },
      {
        value: 0.8,
        label: '0.8',
      },
      {
        value: 1,
        label: '1',
      },
    ],
  },
};
const ME_MODEL_INITIATION_THRESHOLD = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '0.2',
    value: 0.2,
  },
  {
    label: '0.4',
    value: 0.4,
  },
  {
    label: '0.6',
    value: 0.6,
  },
  {
    label: '0.8',
    value: 0.8,
  },
  {
    label: '1',
    value: 1,
  },
];

const getSliderData = (props) => ({
  ...ME_PARAMETRS_MODAL_SLIDER_DATA.slider,
  label: props,
});

const LINEAR_SVC = ['LinearSVC'];

const LINEAR_SVC_OBJ = {
  maxIter: 20,
  minIter: 10,
  minregParam: 1,
  maxregParam: 3,
  nfold: 2,
};

const ANN = ['ArtificialNeuralNetworkClassifier'];
// Do not remove these commented options may be used later
const ANN_ACTIVATION_OPTION = [
  'relu',
  // 'sigmoid',
  // 'softplus',
  // 'softsign',
  'tanh',
  // 'selu',
  // 'elu',
  // 'exponential',
  // 'LeakyReLU',
  // 'relu',
];
const ANN_SOLVER_OPTION = [
  'sgd',
  'adam',
  // 'rmsprop',
  // 'adadelta',
  // 'adagrad',
  // 'adamax',
  // 'nadam',
  // 'ftrl',
];
const ANN_OBJ = {
  activation: 'relu',
  solver: 'adam',
  num_layers: '8,8',
};
const DTC = ['DecisionTreeClassifier'];
const DTC_OBJ = {
  md_low: 8,
  md_up: 9,
  rs_low: 0,
  rs_up: 0,
  mln_low: 2,
  mln_up: 14,
  ms_low: 3,
  ms_up: 4,
};
const NB = ['NaiveBayesClassifier'];
const NB_OBJ = {
  var_smoothing_low: 1,
  var_smoothing_up: 10,
};

const LASSO_REG = ['LASSO REGRESSION', 'Lasso Regression'];
const LASSO_REG_OBJ = {
  alpha_low: 0.0001,
  alpha_up: 10,
};

const SVM = ['SupportVectorMachine'];
const SVM_KERNEL_OPTION = ['linear', 'rbf', 'poly', 'sigmoid'];
const SVM_SHRINKING_OPTION = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];
const SVM_OBJ = {
  C_low: 1.0,
  C_up: 5.0,
  kernel: 'linear',
  shrinking: { label: 'True', value: true },
};

const BR = ['BETA REGRESSION'];
const BR_LINK_OPTION = ['Logit', 'Probit', 'CLoglog'];
const BR_LINKP_OPTION = ['Log', 'Identity', 'Sqrt'];
const BR_OBJ = {
  link: 'Logit',
  link_precision: 'Log',
};

export {
  TIME_SERIES_ARIMAX_MARKS,
  TIME_SERIES_SARIMAX_MARKS,
  LEARNING_RATE_MARKS,
  MAX_DEPTH_MARKS,
  MIN_DATA_IN_LEAF,
  XG_BOOST_N_ESTIMATORS,
  N_ESTIMATORS_MARKS,
  COL_SAMPLE_BY_LEVEL_MARKS,
  MIN_SAMPLE_SPLIT_MARKS,
  XG_BOOST_MAX_DEPTH_MARKS,
  NUM_LEAVES_MARKS,
  LIGHT_BM_LEARNINGRATE_MARKS,
  D_MARKS,
  M_MARKS,
  EXTRA_TREE_MAXDEPTH_MARKS,
  KNEIGHBOUR_MARKS,
  MODELTYPE_OPTIONS,
  COV_TYPES,
  WEIGHT_TYPES,
  CAT_BOST,
  CAT_BOST_OBJ,
  LIGHT_BM,
  LIGHT_BM_OBJ,
  RANDOM_FOREST,
  RANDOM_FOREST_OBJ,
  EXTRA_TREE,
  EXTRA_TREE_OBJ,
  KNEIGHBOUR,
  KNEIGHBOUR_OBJ,
  XG_BOOST,
  XG_BOOST_OBJ,
  lOGISTIC,
  lOGISTICOBJ,
  ARIMAX,
  ARIMAX_OBJ,
  SARIMAX,
  SARIMAX_OBJ,
  REGRESSION,
  REGRESSION_OBJ,
  GLS_REGRESSION,
  GLSAR_REGRESSION,
  WLS_REGRESSION,
  QUANTILE_REGRESSION,
  QUANTILE_OBJECT,
  WLS_REGRESSION_OBJ,
  GLSAR_REGRESSION_OBJ,
  GLM,
  GEE,
  FAMILY_DICT,
  LINK_DICT,
  COVSTRUCTURE_DICT,
  CONVERGENCE_TYPE,
  GEE_OBJ,
  GLM_OBJ,
  ARDL,
  ARDL_OBJ_MULTIPLE,
  ARDL_OBJ,
  TIME_SERIES_ARDL_MARKS,
  LINK_DICT_GAUSSIAN,
  LINK_DICT_BINOMIAL,
  LINK_DICT_NEGATIVE_BINOMAIL,
  STRENGTH_METRICS,
  STABILITY_METRICS,
  EVAL_METRICS,
  ME_MODEL_INITIATION_SAMPLING_SLIDER,
  ME_PARAMETRS_MODAL_SLIDER_DATA,
  ME_MODEL_INITIATION_THRESHOLD,
  getSliderData,
  LINK_DICT_TWEEDIE,
  LINEAR_SVC,
  LINEAR_SVC_OBJ,
  BIG_DATA_N_FOLD_MARKS,
  BIG_DATA_DEPTH_MARKS,
  BIG_DATA_BINS_MARKS,
  BIG_DATA_ITERATION_MARKS,
  ANN,
  ANN_ACTIVATION_OPTION,
  ANN_OBJ,
  ANN_SOLVER_OPTION,
  HIDDEN_SIZES_MARKS,
  DTC,
  DTC_OBJ,
  DTC_MAX_DEPTH_MARKS,
  DTC_RANDOM_STATE_MARKS,
  DTC_MLN_MARKS,
  DTC_MSS_MARKS,
  NB_MARKS,
  NB_OBJ,
  NB,
  SVM_C_MARKS,
  SVM,
  SVM_KERNEL_OPTION,
  SVM_SHRINKING_OPTION,
  SVM_OBJ,
  BR,
  BR_LINK_OPTION,
  BR_LINKP_OPTION,
  BR_OBJ,
  LASSO_REG_ALPHAS_MARKS,
  LASSO_REG,
  LASSO_REG_OBJ,
  TIME_SERIES_GLSAR_MARKS,
  VCOV,
  KERNEL,
  BANDWIDTH,
};
