// @ts-nocheck
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { withTransaction } from '@elastic/apm-rum-react';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import useProject from '../../Hooks/Project/useProject';
import useAuth from '../../Hooks/Auth/useAuth';
import Sidebar from '../../components/Sidebar/Sidebar';
import AppHeader from '../../components/AppHeader/AppHeader';
import HelppageAppHeader from '../../components/AppHeader/HelppageAppHeader';
import NotFound404 from '../../components/Common/NotFound404';
import NoProject from '../../components/Project/NoProject';
import { Routes, AllowedRoutesWithoutProjectSelection } from '../../Routes';
import usePushNotifications from '../../Hooks/usePushNotifications';
import { toggleSidebarWidth } from '../../toolkit/commonSlicer';
import utils from '../../utils/utils';
// import * as webWorkerManager from '../../webWorkerManager';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  // @ts-ignore
  ({ theme, open, ishelpsection }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: !ishelpsection ? '100px' : '0px',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: !ishelpsection ? '260px' : '0px',
    }),
  })
);

const Layout = ({ history }) => {
  // @ts-ignore
  const { isSidebarCollapsed } = useSelector((state) => state.common);
  const { permissions } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { user, fetchUserPermissions } = useAuth();
  const {
    project,
    fetchUserProjects,
    activateProject,
    fetchDatasetList,
    createNewProject,
    fetchUserUnreadTaskSubmodulewise,
    fetchSentReceivedArtifacts,
    fetchSentReceivedProject,
    fetchSentReceivedPipelines,
    userProjectUnreadTask,
    fetchUserProjectsStatus,
    fetchProjectTypes,
  } = useProject();
  const {
    subscribe,
    handleNotificationClick,
    handleRecentlyClickedNotification,
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
  } = usePushNotifications(history, user);

  const fetchUreadTasks = React.useCallback(() => {
    fetchUserUnreadTaskSubmodulewise(project?.activeProject?.project_id);
  }, [project?.activeProject?.project_id, fetchUserUnreadTaskSubmodulewise]);

  const currentModule = utils?.getModuleNameFromURL();
  useEffect(() => {
    subscribe();
    fetchUserPermissions();
    window.addEventListener('UPDATE_UREAD_JOB_COUNTS', fetchUreadTasks);

    return () => {
      window.removeEventListener('UPDATE_UREAD_JOB_COUNTS', fetchUreadTasks);
    };
  }, [project]);

  useEffect(() => {
    fetchUserUnreadTaskSubmodulewise(project?.activeProject?.project_id);
  }, [project?.activeProject?.project_id]);
  useEffect(() => {
    handleNotificationClick();
  }, [handleNotificationClick]);

  useEffect(() => {
    handleRecentlyClickedNotification();
  }, [handleRecentlyClickedNotification]);

  // TODO: Web worker implementation
  // useEffect(() => {
  //   if (
  //     // @ts-ignore
  //     window?.trackingWorker === undefined
  //   ) {
  //     // @ts-ignore
  //     window.trackingWorker = webWorkerManager.startWorker(
  //       '/tracking-worker.js'
  //     );
  //   }
  //   return () => {
  //     // @ts-ignore
  //     if (window?.trackingWorker) {
  //       // @ts-ignore
  //       webWorkerManager.stopWorker(window?.trackingWorker);
  //       // @ts-ignore
  //       window.trackingWorker = undefined;
  //     }
  //   };
  // }, []);
  // window.addEventListener('UNREADTASKCOUNT', function (e) {alert("event raise")},};

  return (
    <div>
      {currentModule[0] !== 'help' ? (
        <AppHeader
          createNewProject={createNewProject}
          fetchUserProjects={fetchUserProjects}
          fetchSentReceivedArtifacts={fetchSentReceivedArtifacts}
          fetchSentReceivedProject={fetchSentReceivedProject}
          fetchSentReceivedPipelines={fetchSentReceivedPipelines}
          activateProject={activateProject}
          project={project}
          user={user}
          fetchDatasets={fetchDatasetList}
          // eslint-disable-next-line react/destructuring-assignment, react/prop-types
          history={history}
          permission={
            permissions[
              `${MODULE_SUB_MODULE_OPERATIONS_TREE.PO.key}_${MODULE_SUB_MODULE_OPERATIONS_TREE.PO.subModules.PC.key}`
            ]
          }
          fetchUserProjectsStatus={fetchUserProjectsStatus}
          fetchProjectTypes={fetchProjectTypes}
        />
      ) : (
        <HelppageAppHeader />
      )}
      {currentModule[0] !== 'help' && (
        <Sidebar
          project={project}
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebarWidth={() => dispatch(toggleSidebarWidth())}
          userPermissions={user?.permissions}
          userProjectUnreadTask={userProjectUnreadTask}
        />
      )}

      <Main
        open={!isSidebarCollapsed}
        ishelpsection={currentModule[0] === 'help'}
      >
        <Box
          sx={{
            height: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingTop: '64px',
            position: 'relative',
          }}
        >
          <Suspense
            fallback={
              <Box
                display="flex"
                flexGrow={1}
                width="100%"
                height="100%"
                alignItems="center"
                flexDirection="row"
              >
                <Box
                  alignItems="center"
                  display="flex"
                  marginRight="auto"
                  marginLeft="auto"
                >
                  <CircularProgress />
                </Box>
              </Box>
            }
          >
            <Switch>
              {Routes.map((route, idx) =>
                route.component ? (
                  <Route
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    // @ts-ignore
                    name={route.name}
                    render={(routeProps) =>
                      !project?.activeProject?.project_id ? (
                        AllowedRoutesWithoutProjectSelection.includes(
                          route.path
                        ) ? (
                          <route.component {...routeProps} />
                        ) : (
                          <NoProject
                            permission={
                              permissions[
                                `${MODULE_SUB_MODULE_OPERATIONS_TREE.PO.key}_${MODULE_SUB_MODULE_OPERATIONS_TREE.PO.subModules.PC.key}`
                              ]
                            }
                            history={history}
                            user={user}
                            createNewProject={createNewProject}
                            fetchUserProjects={fetchUserProjects}
                            {...routeProps}
                          />
                        )
                      ) : (
                        <route.component {...routeProps} />
                      )
                    }
                  />
                ) : null
              )}
              <Redirect exact path="/" to="/project/overview" />
              <Route
                // @ts-ignore
                path="*"
                // @ts-ignore
                name="Not Found"
                render={(routeProps) => <NotFound404 {...routeProps} />}
              />
            </Switch>
          </Suspense>
        </Box>
      </Main>
    </div>
  );
};

Layout.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withTransaction('Layout', 'component')(Layout);
