import { Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import React from 'react';
import { ReactComponent as NimbusLogo } from '../../assets/images/NIMBUS Uno.svg';
/**
 * @function HelpPageAppHeader
 * @description Header of the Help page
 * @returns {JSX} AppHeader UI
 * @example <HelpPageAppHeader />
 */
const HelpPageAppHeader = () => {
  return (
    <AppBar
      position="absolute"
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      elevation={2}
    >
      <Toolbar>
        <Box flexGrow={1}>
          <NimbusLogo
            title="NIMBUS Uno"
            className="nimbusLogo"
            height="40px"
            width="87.5px"
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HelpPageAppHeader;
