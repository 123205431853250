import React from 'react';

const SampleView = React.lazy(() => import('./views/SampleView/SampleView'));
// PROJECT OVERVIEW
const ProjectsOverview = React.lazy(
  () => import('./views/Project/ProjectsOverview')
);
const ProjectDetails = React.lazy(
  () => import('./views/Project/ProjectDetails')
);

// DATA MANAGEMENT
const DataIngestion = React.lazy(
  () => import('./views/DataManagement/data-ingestion')
);
const DataPreparation = React.lazy(
  () => import('./views/DataManagement/data-preparation')
);
const UploadModel = React.lazy(
  () => import('./views/DataManagement/model-upload')
);

// EDA
const PreliminarySteps = React.lazy(
  () => import('./views/EDA/preliminary-steps')
);

const TreatingOutliers = React.lazy(
  () => import('./views/EDA/treating-outliers')
);

const VariableAnalysis = React.lazy(
  () => import('./views/EDA/variable-analysis')
);

const StatisticalTests = React.lazy(
  () => import('./views/EDA/statistical-tests')
);

const MissingValuesImputation = React.lazy(
  () => import('./views/EDA/missing-values-imputation')
);
const TimeSeriesAnalysis = React.lazy(
  () => import('./views/EDA/time-series-analysis')
);

const FeatureTransformation = React.lazy(
  () => import('./views/FeatureEngineering/FeatureTransformation')
);
const ModelEstimation = React.lazy(
  () => import('./views/ModelEstimation/ModelEstimation')
);

const Segmentation = React.lazy(
  () => import('./views/Segmentation/Segmentation')
);

// SAMPLING
const Sampling = React.lazy(() => import('./views/Sampling/Sampling'));

// VARIABLE REDUCTION
const VariableReduction = React.lazy(
  () => import('./views/VariableReduction/VariableReduction')
);

// MODEL MANAGER
const ModelManager = React.lazy(
  () => import('./views/ModelManager/ModelManager')
);

const ViewModelDetails = React.lazy(
  () => import('./views/ModelManager/ViewModelDetails')
);

const TestCaseManager = React.lazy(
  () => import('./views/ModelManager/TestCaseManager')
);

const ModelsComparisonDialog = React.lazy(
  () => import('./views/ModelManager/ModelComparisonDialog')
);
// MODEL TESTING

const PerformanceTesting = React.lazy(
  () => import('./views/ModelTesting/performance-testing')
);

const AssumptionTesting = React.lazy(
  () => import('./views/ModelTesting/Assumption-testing')
);

const ModelComparison = React.lazy(
  () => import('./views/ModelTesting/model-comparison')
);

const BackTesting = React.lazy(
  () => import('./views/ModelTesting/back-testing')
);

const ModelCalibiration = React.lazy(
  () => import('./views/ModelTesting/model-calibration')
);

const ScoringScaling = React.lazy(
  () => import('./views/ModelTesting/scoring-scaling')
);

const SensitivityAnalysis = React.lazy(
  () => import('./views/ModelTesting/sensitivity-analysis')
);

const Fairness = React.lazy(
  () => import('./views/ModelTesting/fairness-biasness')
);

const DriftAnalysis = React.lazy(
  () => import('./views/ModelTesting/DriftAnalysis')
);

const ForeCasting = React.lazy(
  () => import('./views/ModelTesting/forecasting')
);

// MODEL INTERPRETATION

const FeatureInterpretation = React.lazy(
  () => import('./views/ModelInterpretation/feature-interpretation')
);

const IndividualPrediction = React.lazy(
  () => import('./views/ModelInterpretation/individual-prediction')
);

const DecisionTrees = React.lazy(
  () => import('./views/ModelInterpretation/decision-tree')
);

const MiTimeSeriesAnalysis = React.lazy(
  () => import('./views/ModelInterpretation/time-series-analysis')
);

// MODEL DEPLOYMENT
const ModelDeployment = React.lazy(
  () => import('./views/ModelDeployment/ModelDeployment')
);

// DOCUMENT MANAGER ROUTES
const ListDocuments = React.lazy(
  () => import('./views/Documentation/ListDocuments')
);
const EditDocTemplate = React.lazy(
  () => import('./views/Documentation/EditDocTemplate')
);

// PIPELINES

const ListPipeline = React.lazy(() => import('./views/Pipeline/ListPipeline'));
const EditPipeline = React.lazy(() => import('./views/Pipeline/EditPipeline'));
const PipelineExecutionHistory = React.lazy(
  () => import('./views/Pipeline/PipelineExecutionHistory')
);
const PipelineExecutionResult = React.lazy(
  () => import('./views/Pipeline/PipelineExecutionResult')
);

// UDF Manager

const RunUdf = React.lazy(() => import('./views/UDFManager/RunUdf'));
const FileBrowser = React.lazy(
  () => import('./views/UDFManager/FileBrowserView')
);
const EditUdf = React.lazy(() => import('./views/UDFManager/EditUdf'));
const UDFManager = React.lazy(() => import('./views/UDFManager/UDFManager'));
const UDFDetails = React.lazy(() => import('./views/UDFManager/UDFDetails'));
const CreateUdf = React.lazy(() => import('./views/UDFManager/CreateUdf'));
const CompareResults = React.lazy(
  () => import('./views/UDFManager/CompareResultsDialog')
);
const ExecuteUdf = React.lazy(() => import('./views/UDFManager/ExecuteUdf'));

const PipelineExecutionComparison = React.lazy(
  () => import('./views/Pipeline/PipelineExecutionComparision')
);
// SETTINGS
const ColorPallete = React.lazy(() => import('./views/Settings/ColorPallete'));

// INVENTORY RECIVED ARTIFACTS
const SentReceivedArtifacts = React.lazy(
  () => import('./views/Project/SentReceivedArtifacts')
);

// USER GROUP PROVISIONING
const UserGroupProvisioning = React.lazy(
  () => import('./views/UserGroupProvisioning/UserGroupProvisioning')
);
const Auditability = React.lazy(
  () => import('./views/Auditability/Auditability')
);

const WorkerManagement = React.lazy(
  () => import('./views/WorkerManagement/WorkerManagement')
);

const OrchestrationManagement = React.lazy(
  () => import('./views/OrchestrationManagement/OrchestrationManagement')
);

const ConsoleLibrary = React.lazy(
  () => import('./views/ConsoleLibrary/ConsoleLibrary')
);

const DocumentationTemplate = React.lazy(
  () => import('./views/DocumentationTemplate/DocumentationTemplate')
);

const PowerBi = React.lazy(() => import('./views/PowerBi/PowerBi'));
const BigData = React.lazy(() => import('./views/BigData/BigData'));
const Downloads = React.lazy(() => import('./views/Downloads/Downloads'));
const APIsAndIntegrations = React.lazy(
  () => import('./views/APIsAndIntegrations/APIsAndIntegrations')
);

const StreamlitDashboard = React.lazy(
  () => import('./views/Streamlit/StreamlitDashboard')
);

const AllowedRoutesWithoutProjectSelection = [
  '/',
  '/project/sent-received-artifacts',
  '/project/overview',
  '/data-management/data-ingestion',
  '/project/details',
  '/data-management/model-upload',
  '/documentation/list',
  '/documentation/document',
  '/settings/color-palette',
  '/settings/user-group-provisioning',
  '/user-group-provisioning',
  '/settings/worker-management',
  '/settings/orchestration-management',
  '/settings/download-screen',
  '/settings/console-library',
  '/settings/big-data',
  '/settings/documentation-template',
  '/settings/power-bi',
  '/settings/apis-and-integrations',
  '/settings/auditability',
  '/downloads',
  '/help',
];

const Routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/sample-view', name: 'SampleView', component: SampleView },
  {
    path: '/project/sent-received-artifacts',
    name: 'SentReceivedArtifacts',
    component: SentReceivedArtifacts,
  },
  {
    path: '/project/overview',
    name: 'ProjectsOverview',
    component: ProjectsOverview,
  },
  {
    path: '/project/details',
    name: 'ProjectDetails',
    component: ProjectDetails,
  },
  {
    path: '/data-management/data-ingestion',
    name: 'DataIngestion',
    component: DataIngestion,
  },

  {
    path: '/data-management/preparation',
    name: 'DataPreparation',
    component: DataPreparation,
  },
  {
    path: '/data-management/model-upload',
    name: 'ModelUpload',
    component: UploadModel,
  },

  {
    path: '/eda/preliminary-steps',
    name: 'PreliminarySteps',
    component: PreliminarySteps,
  },

  {
    path: '/eda/treating-outliers',
    name: 'TreatingOutliers',
    component: TreatingOutliers,
  },

  {
    path: '/eda/variable-analysis',
    name: 'VariableAnalysis',
    component: VariableAnalysis,
  },

  {
    path: '/eda/statistical-tests',
    name: 'StatisticalTests',
    component: StatisticalTests,
  },

  {
    path: '/eda/missing-values-imputation',
    name: 'MissingValuesImputation',
    component: MissingValuesImputation,
  },
  {
    path: '/eda/time-series-analysis',
    name: 'TimeSeriesAnalysis',
    component: TimeSeriesAnalysis,
  },

  {
    path: '/feature-engineering/feature-transformation',
    name: 'FeatureTransformation',
    component: FeatureTransformation,
  },

  {
    path: '/sampling-segmentation/sampling',
    name: 'Sampling',
    component: Sampling,
  },

  {
    path: '/sampling-segmentation/segmentation',
    name: 'Segmentation',
    component: Segmentation,
  },

  {
    path: '/variable-reduction',
    name: 'VariableReduction',
    component: VariableReduction,
  },

  {
    path: '/model-testing/performance-testing',
    name: 'PerformanceTesting',
    component: PerformanceTesting,
  },

  {
    path: '/model-testing/assumption-testing',
    name: 'AssumptionTesting',
    component: AssumptionTesting,
  },

  {
    path: '/model-testing/model-comparison',
    name: 'ModelComparison',
    component: ModelComparison,
  },

  {
    path: '/model-testing/back-testing',
    name: 'BackTesting',
    component: BackTesting,
  },

  {
    path: '/model-testing/calibiration',
    name: 'ModelCalibiration',
    component: ModelCalibiration,
  },

  {
    path: '/model-testing/scoring-scaling',
    name: 'ScoringScaling',
    component: ScoringScaling,
  },

  {
    path: '/model-testing/sensitivity-analysis',
    name: 'SensitivtyAnalysis',
    component: SensitivityAnalysis,
  },

  {
    path: '/model-testing/fairness',
    name: 'Fairness',
    component: Fairness,
  },

  {
    path: '/model-testing/drift-analysis',
    name: 'DriftAnalysis',
    component: DriftAnalysis,
  },

  {
    path: '/model-testing/forecasting',
    name: 'SensitivtyAnalysis',
    component: ForeCasting,
  },

  {
    path: '/model-estimation/',
    name: 'ModelEstimation',
    component: ModelEstimation,
  },

  {
    path: '/model-interpretation/feature-interpretation',
    name: 'FeatureInterpretation',
    component: FeatureInterpretation,
  },

  {
    path: '/model-interpretation/individual-prediction',
    name: 'IndividualPrediction',
    component: IndividualPrediction,
  },

  {
    path: '/model-interpretation/decision-trees',
    name: 'DecisionTrees',
    component: DecisionTrees,
  },

  {
    path: '/model-interpretation/time-series-analysis',
    name: 'MiTimeSeriesAnalysis',
    component: MiTimeSeriesAnalysis,
  },
  {
    path: '/model-manager/models',
    name: 'ModelManager',
    component: ModelManager,
    exact: true,
  },
  {
    path: '/model-manager/test-case-manager',
    name: 'TestCaseManager',
    component: TestCaseManager,
  },
  {
    path: '/model-manager/models/:id',
    name: 'ViewModelDetails',
    component: ViewModelDetails,
    exact: true,
  },
  {
    path: '/model-manager/models-comparison',
    name: 'ModelsComparisonDialog',
    component: ModelsComparisonDialog,
    exact: true,
  },
  {
    path: '/model-deployment',
    name: 'ModelDeployment',
    component: ModelDeployment,
  },

  {
    path: '/documentation/list',
    name: 'ListDocuments',
    component: ListDocuments,
  },

  {
    path: '/documentation/document',
    name: 'EditDocTemplate',
    component: EditDocTemplate,
  },
  {
    path: '/udf-manager/compare-results',
    name: 'CompareResults',
    exact: true,
    component: CompareResults,
  },

  {
    path: '/udf-manager/execute/:id',
    name: 'ExecuteUdf',
    exact: true,
    component: ExecuteUdf,
  },
  {
    path: '/udf-manager/edit/:udfId',
    name: 'EditUdf',
    exact: true,
    component: EditUdf,
  },
  {
    path: '/udf-manager/create',
    name: 'CreateUdf',
    exact: true,
    component: CreateUdf,
  },
  {
    path: '/udf-manager/create/:id/debug',
    name: 'RunUdf',
    exact: true,
    component: RunUdf,
  },
  {
    path: '/udf-manager/edit/:id/code/debug',
    name: 'RunUdf',
    exact: true,
    component: RunUdf,
  },
  {
    path: '/udf-manager/edit/:id/code',
    name: 'FileBrowser',
    exact: true,
    component: FileBrowser,
  },
  {
    path: '/udf-manager/list',
    name: 'UDFManager',
    component: UDFManager,
  },
  {
    path: '/udf-manager/detail/:id',
    name: 'UDFDetails',
    exact: true,
    component: UDFDetails,
  },
  {
    path: '/udf-manager/detail/:id/debug',
    name: 'RunUdf',
    exact: true,
    component: RunUdf,
  },
  {
    // pipeline/list
    path: '/pipeline/list',
    name: 'ListPipeline',
    component: ListPipeline,
  },
  {
    path: '/pipeline/:id/execution',
    name: 'PipelineExecutionHistory',
    component: PipelineExecutionHistory,
    exact: true,
  },
  {
    // '/pipeline/:id'
    path: '/pipeline/:id',
    name: 'EditPipeline',
    exact: true,
    component: EditPipeline,
  },
  {
    path: '/pipeline/:id/execution/compare/:executionOneId&:executionTwoId',
    name: 'PipelineExecutionComparison',
    exact: true,
    component: PipelineExecutionComparison,
  },
  {
    path: '/pipeline/:id/execution/:executionid',
    name: 'PipelineExecutionResult',
    exact: true,
    component: PipelineExecutionResult,
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads,
  },
  {
    path: '/settings/color-palette',
    name: 'ColorPalette',
    component: ColorPallete,
  },
  {
    path: '/settings/user-group-provisioning',
    name: 'UserGroupProvisioning',
    component: UserGroupProvisioning,
  },
  {
    path: '/settings/auditability',
    name: 'Auditability',
    component: Auditability,
  },
  {
    path: '/settings/worker-management',
    name: 'WorkerManagement',
    component: WorkerManagement,
  },
  {
    path: '/settings/orchestration-management',
    name: 'OrchestrationManagement',
    component: OrchestrationManagement,
  },
  {
    path: '/settings/big-data',
    name: 'BigData',
    component: BigData,
  },
  {
    path: '/settings/console-library',
    name: 'ConsoleLibrary',
    component: ConsoleLibrary,
  },
  {
    path: '/settings/documentation-template',
    name: 'DocumentationTemplate',
    component: DocumentationTemplate,
  },
  {
    path: '/settings/power-bi',
    name: 'PowerBi',
    component: PowerBi,
  },
  {
    path: '/settings/apis-and-integrations',
    name: 'APIsAndIntegrations',
    component: APIsAndIntegrations,
  },
  {
    path: '/settings/streamlit-dashboard',
    name: 'StreamlitDashboard',
    component: StreamlitDashboard,
  },
];

export { Routes, AllowedRoutesWithoutProjectSelection };
