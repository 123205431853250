// @ts-nocheck
import React from 'react';
import Zoom from '@mui/material/Zoom';

/**
 * @component DialogTransition
 * @description A transition component used for dialogs, implementing a zoom effect.
 * @param {object} props - The props object.
 * @param {object} ref - Forwarded ref object.
 * @returns {JSX.Element} Zoom transition effect for dialogs.
 */
const DailogTransition = React.forwardRef((props, ref) => (
  <Zoom in={true} ref={ref} {...props} />
));

export default DailogTransition;
