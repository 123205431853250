// @ts-nocheck
// PUSH_KEY is nothing but vapid public key
// SUBSCRIPTION API request format
// {
//   "group": "NimbusWebPush",
//   "status_type": "subscribe",
//   "browser": "chrome",
//   "subscription": { // this object is auto generated by swReg.pushManager.subscribe()
//     "endpoint": "<url>",
//     "keys": {
//       "p256dh": "<string>",
//       "auth": "<string>"
//     }
//   }
// }
import axios from 'axios';
import { useCallback } from 'react';
import utils from '../utils/utils';
import API_ENDPOINTS from '../const/ApiEndpoints';
import useProject from './Project/useProject';

const config = {
  PUSH_KEY: process.env.REACT_APP_PUSH_KEY,
};
/**
 * Custom hook for handling push notifications.
 *
 * @param {import('history').History} history - React Router history object.
 * @param {Object} user - User object containing user information.
 * @param {string} user.userData - User data.
 * @returns {{
 *   subscribe: () => void,
 *   urlB64ToUint8Array: (base64String: string) => Uint8Array,
 *   handleNotificationClick: () => void,
 *   handleRecentlyClickedNotification: () => void,
 * }} Object containing functions for subscribing to push notifications and handling notifications.
 */
const usePushNotifications = (history, user) => {
  const { activateProject, project } = useProject();
  /**
   * Converts a base64 string to a Uint8Array.
   *
   * @param {string} base64String - The base64 encoded string.
   * @returns {Uint8Array} The Uint8Array representation of the base64 string.
   */
  const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      // eslint-disable-next-line no-useless-escape
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };
  /**
   * Subscribes to push notifications.
   */
  const subscribe = async () => {
    // Use serviceWorker.ready to ensure that you can subscribe for push
    navigator.serviceWorker?.ready.then(async (serviceWorkerRegistration) => {
      if (Notification.permission !== 'granted') {
        const subscription =
          await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(config.PUSH_KEY),
          });
        const token = await localStorage.getItem('access_token');

        axios
          .post(
            API_ENDPOINTS.MODULE_BASE_URL.DI +
              API_ENDPOINTS.PUSH_NOTIFICATION.SUBSCRIBE,
            JSON.stringify({
              group: 'NimbusWebPush', // topic
              status_type: 'subscribe',
              browser: utils.getBrowserName(),
              subscription,
            }),
            {
              headers: {
                'content-type': 'text/html',
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then(
            () => {},
            () => {}
          );
      }
    });
  };

  /**
   * Redirects to the appropriate page when a notification is clicked.
   *
   * @param {Object} notificationData - Data associated with the notification.
   */
  const redirectForNotification = useCallback(
    (notificationData) => {
      if (project.userProjectsList.length) {
        const notificationProject = project.userProjectsList.find(
          (prj) => prj.project_id === notificationData.projectId
        );
        activateProject(notificationProject);
        const url = utils.getPageUrlByModuleSubModuleName(
          notificationData.module,
          notificationData.subModule
        );
        history.push({
          pathname: url,
          search: `?${new URLSearchParams(notificationData).toString()}`,
        });
        window.dispatchEvent(new Event('UPDATE_UREAD_JOB_COUNTS'));
      }
    },
    [project.userProjectsList]
  );

  const handleNotificationClick = useCallback(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        // event is a MessageEvent object
        console.log(
          `The service worker sent me a message: ${JSON.stringify(event.data)}`
        );
        redirectForNotification(event.data);
      });
    }
  }, [redirectForNotification]);
  /**
   * Retrieves recently clicked notification data and handles it.
   * This method retrives data from cookies and raise the event.
   */
  const handleRecentlyClickedNotification = useCallback(() => {
    window?.cookieStore
      ?.get('RECENTLY_CLICKED_NOTIFICATION_DATA')
      .then((res) => {
        if (res?.value && user?.userData) {
          setTimeout(() => {
            const val = JSON.parse(res.value);
            if (user.userData?.id === val.userId) {
              redirectForNotification(val);
              window.cookieStore.delete('RECENTLY_CLICKED_NOTIFICATION_DATA');
            }
          }, [1000]);
        }
      });
  }, [redirectForNotification]);

  return {
    subscribe,
    urlB64ToUint8Array,
    handleNotificationClick,
    handleRecentlyClickedNotification,
  };
};

export default usePushNotifications;
