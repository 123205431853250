// import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import rootReducer from './RootReducer';
// @ts-ignore
import packageJson from '../../package.json';

const persistConfig = {
  key: 'root',
  version: packageJson.version,
  storage: sessionStorage,
  whitelist: ['common'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// import { composeWithDevTools } from 'redux-devtools-extension'

// const composeEnhancers =
//   (typeof window !== 'undefined' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
//   compose;

/** Saga Middleware */
// const sagaMiddleware = createSagaMiddleware();

/** Create middlewares for redux */
// const middlewares = applyMiddleware(sagaMiddleware);

/** Create redux store */
// const store = createStore(rootReducer, composeEnhancers(middlewares));

/**
 * @function store - Create redux store
 * @description - Create redux store with saga middleware
 * @param {object} rootReducer - Root reducer
 * @param {object} middlewares - Middlewares
 * @returns {object} - Redux store
 */
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return [
      ...getDefaultMiddleware({
        // thunk: false,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ];
  },
});
/** run saga watchers */
export const persistor = persistStore(store);
