const REGEX = {
  EMAIL: '^[ ]*[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}[ ]*$', // in email we are allowing heading & trailing whitespaces
  ALPHA_NUMERIC_WITHOUT_SPACES: '^[ ]*[a-zA-Z0-9]+[ ]*$', // without space in between
  MOBILE_NO: '^[ ]*[0-9]{10,10}[ ]*$', // number (min/max 10) without spaces allowing heading & trailing whitespaces.
  // eslint-disable-next-line prettier/prettier, no-useless-escape
  LOGIN_USERNAME: "^[A-Za-z0-9_@.-/#!$%&'*:+-/=?^_`{|}~]+$",
  ARG_NAME: '^[a-zA-Z_][a-zA-Z0-9_]*$',
  IMG_URL: /https?:\/\/[a-zA-Z0-9.-]+(?:\.[a-zA-Z]{2,})?(?:\/[^\s]*)?/g,
  DOCUMENT_NAME: '^[^#%*:<>?/\\|"]+$',
  ALPHA_WITH_SPACES: '^[A-Za-z]+[A-Za-z ]*$', //  alphabets with or without space
  GROUP_NAME: '^[a-zA-Z][a-zA-Z0-9_-]+$', // first letter can only be an alpha, after that number, alpha _, - allowed without space.
  HEX: /^#(([A-F0-9]{2}){3,4}|[A-F0-9]{3})$/i,
  AT_LEAST_A_NUMBER: '^(?=.*\\d).+',
  AT_LEAST_A_SPEC_CHAR: '^(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?~\\-=|]).+$',
  AT_LEAST_A_CAP_LLTR: '.*[A-Z].*',
  AT_LEAST_A_LOWR_CASE: '.*[a-z].*',
  ALPHA_NUM_SPACE_DASH: /^[A-Za-z0-9_-]+[0-9A-Za-z\-_\s]*$/, // accept mix of alphabets, numbers, space, underscore and dash.
  ONLY_ALPHABET: /^[a-z]+$/i, // only alphabets without space
  NO_SPACE: /^\S/,
  NO_SPACE_IN_START_AND_END: /^\S(?:.*\S)?$/,
  S3_BUCKET_PATTERN:
    '(?!(^((2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2}).){3}(2(5[0-5]|[0-4][0-9])|[01]?[0-9]{1,2})$|^xn--|.+-s3alias$))^[a-z0-9][a-z0-9.-]{1,61}[a-z0-9]$',
  ALPHA_NUMERIC_UNDERSCORE_FIRST_CHAR: '^[a-zA-Z][a-zA-Z0-9_]*$', // first letter can only be an alpha, after that number, alpha _, without space.
  DATE_REGEX: /^\d{4}\/\d{2}\/\d{2}$/,
};
export default REGEX;
