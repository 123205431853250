import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { init as initApm } from '@elastic/apm-rum';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './state/Store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { msalConfig } from './authConfig';
// @ts-ignore
import packageJson from '../package.json';

const container = document.getElementById('root');
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

try {
  // eslint-disable-next-line no-unused-vars
  const apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: `${packageJson.name}-${process.env.REACT_APP_ENV}`,

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: `${process.env.REACT_APP_ERROR_LOGGER}`,

    // Set service version (required for sourcemap feature)
    serviceVersion: packageJson.version,
    environment: process.env.REACT_APP_ENV,
    active: process.env.NODE_ENV === 'production',
    // @ts-ignore
    distributedTracingOrigins: window.location.pathname,
    logLevel: 'warn',
  });

  // @ts-ignore
  apm.addFilter((payload) => {
    if (payload.errors) {
      payload.errors.forEach((error) => {
        // eslint-disable-next-line no-param-reassign
        error.exception.message = error.exception.message.replace(
          'secret',
          '[REDACTED]'
        );
      });
      // to ignore certain errors
      // eslint-disable-next-line no-param-reassign
      // payload.errors = payload.errors.filter((error) => {
      //   /Unhandled promise rejection: AxiosError/.test(error.exception.message);
      // });
    }
    if (payload.transactions) {
      payload.transactions.forEach((tr) => {
        tr.spans.forEach((span) => {
          if (span.context && span.context.http && span.context.http.url) {
            const url = new URL(span.context.http.url);
            if (url.searchParams.get('token')) {
              url.searchParams.set('token', 'REDACTED');
            }
            // eslint-disable-next-line no-param-reassign
            span.context.http.url = url.toString();
          }
        });
      });
    }
    return payload;
  });
  // @ts-ignore
  window.apm = apm;
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Failed to start APM RUM Agent', e);
}

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
