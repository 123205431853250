import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import { SwalToast } from '../../components/Common';
import ApiEndpoints from '../../const/ApiEndpoints';

export default function useConsole() {
  const [consoleAsyncTaskResults, setConsoleAsyncTaskResults] = useState({});
  const [consoleModuleTasks, setConsoleModuleTasks] = useState([]);
  const [udfs, setUdfs] = useState([]);
  const [selectedUdf, setSelectedUdf] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [description, setDescription] = useState(null);
  const [checkError, setCheckError] = useState(false);
  const { activeProject } = useSelector((state) => state.project);
  /**
   * @function fetchUdfs
   * @description fetches the udfs for a given module and subModule
   * @param {String} moduleName - the module name
   * @param {String} subModule - the subModule name
   * @param {String} projectId - the project id
   */

  const fetchUdfs = (moduleName, subModule, projectId) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.FETCH_UDFS
        }`,
        {
          params: {
            module: moduleName,
            sub_module: subModule,
            project_id: projectId,
          },
          // @ts-ignore
          loader: false,
        }
      )
      .then(
        (resp) => {
          setUdfs(resp?.data?.data);
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchUdfDetails
   * @description Fetches details of a User Defined Function (UDF) by its ID.
   * @param {number} id - The ID of the User Defined Function (UDF).
   * @returns {Promise} - A promise that resolves to the response data when the request is successful.
   * @throws {Error} - If there's an error during the request.
   */
  const fetchUdfDetails = (id) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.FETCH_UDF
        }`,
        {
          params: {
            udf_id: id,
          },
          // @ts-ignore
          loader: false,
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function createUdf
   * @description Creates a User Defined Function (UDF) using the provided payload.
   * @param {Object} payload - The payload containing the data necessary for creating the UDF.
   * @param {string} payload.name - The name of the UDF.
   * @param {string} payload.code - The code for the UDF.
   * @param {string[]} [payload.libraries=[]] - An array of libraries required by the UDF (optional, default is an empty array).
   * @returns {Promise} - A promise that resolves to the response data when the request is successful.
   * @throws {Error} - If there's an error during the request.
   */
  const createUdf = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.CREATE_UDF
        }`,
        { ...payload, libraries: payload?.libraries ? payload?.libraries : [] }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function githubIntegration
   * @description Integrates with GitHub using the provided payload.
   * @param {Object} payload - The payload containing the data necessary for the GitHub integration.
   * @returns {Promise} - A promise that resolves to the response data when the request is successful.
   * @throws {Error} - If there's an error during the request.
   */
  const githubIntegration = (payload) => {
    return axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.GITHUB_INTEGRATION
        }`,
        payload
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function fetchConsoleLibraries
   * @description Fetches the list of console libraries.
   * @returns {Promise} - A promise that resolves to the response data when the request is successful.
   * @throws {Error} - If there's an error during the request.
   */
  const fetchConsoleLibraries = () => {
    return axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.GET_CONSOLE_LIBRARY_LIST
        }`
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function updateUdf
   * @description Updates a User Defined Function (UDF) using the provided payload.
   * @param {Object} payload - The payload containing the data necessary for updating the UDF.
   * @param {string} payload.id - The ID of the UDF to be updated.
   * @param {string} payload.name - The updated name of the UDF.
   * @param {string} payload.code - The updated code for the UDF.
   * @param {string[]} [payload.libraries=[]] - An array of libraries required by the UDF (optional, default is an empty array).
   * @returns {Promise} - A promise that resolves to the response data when the request is successful.
   * @throws {Error} - If there's an error during the request.
   */
  const updateUdf = (payload) =>
    axios
      .put(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.UPDATE_UDF
        }`,
        {
          ...payload,
          libraries: payload?.libraries ? payload?.libraries : [],
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function addLibrary
   * @description Adds a library to the console module.
   * @param {object} data - The data representing the library to be added.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const addLibrary = (data) => {
    return axios
      .put(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.CONSOLE_LIBRARY
        }`,
        {
          library: {
            ...data,
          },
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function updateLibrary
   * @description Updates a library in the console module.
   * @param {object} data - The data representing the library to be updated.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const updateLibrary = (data) => {
    return axios
      .patch(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.CONSOLE_LIBRARY
        }`,
        {
          library: {
            ...data,
          },
        }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function deleteLibrary
   * @description Deletes a library from the console module.
   * @param {number} id - The ID of the library to be deleted.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const deleteLibrary = (id) => {
    return axios
      .delete(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.CONSOLE_LIBRARY,
        { params: { id } }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };

  /**
   * @function createJob
   * @description Creates a job in the console module.
   * @param {object} payload - The payload containing data for creating the job.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const createJob = (payload) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.CREATE_JOB
        }`,
        payload
      )
      .then(
        (resp) => {
          setConsoleAsyncTaskResults(null);
          return resp;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function deleteUdf
   * @description Deletes a user-defined function from the console module.
   * @param {number} id - The ID of the user-defined function to be deleted.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const deleteUdf = (id) =>
    axios
      .delete(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.DELETE_USER_DEFINED_FUNCTION,
        { params: { id } }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function runUdf
   * @description Runs a user-defined function in the console module.
   * @param {number} id - The ID of the user-defined function to be executed.
   * @param {number} projectId - The ID of the project associated with the user-defined function.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const runUdf = (id, projectId) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.RUN_UDF,
        {},
        { params: { id, project_id: projectId } }
      )
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );

  /**
   * @function onDownloadCSV
   * @description Initiates the download of a CSV file from the console module.
   * @param {object} data - The data object containing necessary information for downloading the CSV file.
   * @param {number} data.id - The ID of the resource.
   * @param {number} data.project_id - The ID of the project associated with the resource.
   * @param {string} data.module - The module name.
   * @param {string} data.subModule - The sub-module name.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const onDownloadCSV = (data) =>
    axios
      .post(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.DOWNLOAD_CSV,
        {
          id: data?.id,
          project_id: data?.project_id,
          module: data.module,
          subModule: data.subModule,
        }
      )
      .then(
        (response) => {
          SwalToast({
            icon: 'success',
            title:
              'Started preparing download link. Please check downloads page for further details.',
          });
          return response;
        },
        (err) => {
          throw err;
        }
      );

  // For fetching Active Dataset Columns
  /**
   * @function fetchDatasetColumnDetailsForConsole
   * @description API call for fetching dataset column details
   * @param {object} payload - data for fetching dataset column details
   * @returns {Promise} AxiosPromise
   * @example fetchDatasetColumnDetailsForConsole(body,successCallback);
   */
  const fetchDatasetColumnDetailsForConsole = (payload) => {
    if (payload?.dataset_id && payload?.project_id) {
      return axios
        .post(
          `${
            ApiEndpoints.MODULE_BASE_URL.CONSOLE +
            ApiEndpoints.CONSOLE.DATASET_COLUMN_DETAILS
          }`,
          {
            project_id: payload.project_id,
            dataset_id: payload.dataset_id,
          }
        )
        .then(
          (resp) => resp,
          (error) => {
            throw error;
          }
        );
    }
  };

  /**
   * @function fetchTableData
   * @description Fetches table data from the console module.
   * @param {number} tableId - The ID of the table to fetch data from.
   * @param {number} pageNumber - The page number of the table data to fetch.
   * @param {number} pageSize - The size of each page in the table data.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const fetchTableData = (tableId, pageNumber, pageSize) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.GET_TABLE_DATA
        }/${tableId}`,
        {
          params: {
            page: pageNumber + 1,
            pagesize: pageSize,
          },
        }
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function fetchSavedModelList
   * @description API call for fetching saved model list
   * @param {object} params - params for fetching saved model list
   * @returns {Promise} AxiosPromise
   * @example fetchSavedModelList(params);
   */
  const fetchAllModels = (params) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.ME +
          ApiEndpoints.ME.ME_GET_SAVED_MODEL_LIST
        }`,
        {
          params,
        }
      )
      .then(
        (res) => res.data,
        (error) => {
          throw error;
        }
      );

  /**
   * @function commentApiCall
   * @description Makes an API call to save a comment for a chart in the console module.
   * @param {object} body - The data representing the comment to be saved.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const commentApiCall = async (body) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.CONSOLE_SAVE_CHART_COMMENT
        }`,
        body
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function terminateTaskConsole
   * @description API call for terminating task in task manager
   * @param {string} taskID - task id
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to
   * execute when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example terminateTaskConsole(taskID, successCallback, errorCallback);
   */
  const terminateTaskConsole = (taskID) => {
    const url = `${ApiEndpoints.MODULE_BASE_URL.CONSOLE}${ApiEndpoints.CONSOLE.GET_TASKS}/${taskID}`;
    return axios
      .delete(url)
      .then((res) => res)
      .catch((e) => {
        throw e;
      });
  };

  /**
   * @function markTaskReadUnreadConsole
   * @description API call for mark task read unread of
   *  performance testing sub module job queue tasks
   * @param {object} taskParams -task parameters of mark task read unread of sampling sub module
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @returns {Promise} AxiosPromise
   * @example markTaskReadUnreadConsole(taskParams,successCallback);
   */
  const markTaskReadUnreadConsole = (taskParams, successCallback) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.JOB_QUEUE_MARK_READ_UNREAD
        }`,
        {
          params: taskParams,
        }
      )
      .then(
        () => {
          successCallback();
        },
        () => {}
      );

  /**
   * @function fetchAsyncResultConsole
   * @description API call for fetching asynchronous result using task id,
   * result will contain date like graph and charts
   * @param {string} taskID - task id
   * @returns {Promise} AxiosPromise
   * @example fetchAsyncResultConsole(taskID);
   */
  const fetchAsyncResultConsole = (taskID) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.UDF_TASK_DETAILS
        }`,
        {
          params: {
            task_id: taskID,
          },
        }
      )
      .then(
        (res) => {
          if (res.data.task_status === 'SUCCESS') {
            // setActiveOperation(data);
            setConsoleAsyncTaskResults(res.data);
          } else {
            setConsoleAsyncTaskResults({});
          }
          return res;
        },
        (error) => {
          throw error;
        }
      );

  /**
   * @function fetchModuleTasksConsole
   * @description API call for fetching module tasks
   * @param {string} module -module to be fetched
   * @param {object} operation - operation
   * @param {string} endpoint - API endpoints
   * @param {number} projectId - project id
   * @param {boolean} hideLoaderOnRequest - hiding loader on request
   * @returns {Promise} AxiosPromise
   * @example  fetchModuleTasksConsole(module,operation,endpoint,
   * setModuleTasks,project_id,hideLoaderOnRequest)
   */
  const fetchModuleTasksConsole = (
    _module,
    operation,
    _endpoint,
    projectId,
    hideLoaderOnRequest
  ) =>
    axios
      .get(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.GET_TASKS
        }`,
        {
          params: {
            project_id: projectId,
          },
          // @ts-ignore
          loader: !hideLoaderOnRequest,
        }
      )
      .then(
        (res) => {
          setConsoleModuleTasks(res.data);
          return res;
        },
        (err) => {
          setConsoleModuleTasks([]);
          throw err;
        }
      );

  // console documentation related api calls
  /**
   * @function fetchTables
   * @description Fetches tables from the console module based on given parameters.
   * @param {object} params - The parameters for fetching tables.
   * @returns {Promise} A Promise that resolves with the response data if successful, or rejects with an error.
   */
  const fetchTables = (params) =>
    axios
      .get(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.GET_TABLE,
        {
          params,
        }
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );
  /**
   * @function fetchCharts
   * @description Fetches charts data from the server.
   * @param {Object} params - The parameters for fetching charts data.
   * @returns {Promise<Object>} A promise resolving to the response containing charts data.
   */
  const fetchCharts = (params) =>
    axios
      .get(
        ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.GET_CHART,
        {
          params,
        }
      )
      .then(
        (res) => res,
        (err) => {
          throw err;
        }
      );

  /**
   * @function consoleRerunOperation
   * @description Executes a rerun operation in the console module.
   * @param {number} id - The ID of the operation to rerun.
   * @returns {Promise<Object>} A promise resolving to the response of the rerun operation.
   */
  const consoleRerunOperation = (id) =>
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.RERUN_OPERATION
        }`,
        id
      )
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );
  /**
   * @function downloadConsoleFile
   * @description API call for downloading console file
   * @param {number} fileId - id for file to be downloaded
   * @returns {Promise} AxiosPromise
   * @example downloadConsoleFile(fileId);
   */
  const downloadConsoleFile = (fileId) => {
    const Endpoint = `${ApiEndpoints.CONSOLE.DOWNLOAD_FILE}?id=${fileId}`;

    return axios.get(`${ApiEndpoints.MODULE_BASE_URL.CONSOLE}${Endpoint}`).then(
      (resp) => resp,
      (error) => {
        throw error;
      }
    );
  };

  /**
   * @function deleteConsoleFileWithId
   * @description API call for deleting console file with id
   * @param {number} fileId - console file id
   * when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example deleteConsoleFileWithId (fileId, successCallback,errorCallback)
   */
  const deleteConsoleFileWithId = (fileObject) => {
    return axios
      .delete(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.DELETE_FILE
        }?id=${fileObject.id}`
      )
      .then((res) => {
        SwalToast({
          icon: 'success',
          title: `${fileObject.name} deleted successfully.`,
        });
        return res;
      })
      .catch((error) => {
        SwalToast({
          icon: 'error',
          title: error.data.msg,
        });
        throw error;
      });
  };
  /**
   * @function fetchConsoleFileList
   * @description API call for fetching console file list
   * @param {number} projectId - project id for which files list to be fetched
   * @returns {Promise} AxiosPromise
   * @example fetchConsoleFileList(projectId,successCallback,errorCallback);
   */
  const fetchConsoleFileList = (projectId, loader = true) => {
    const Endpoint = projectId
      ? `${ApiEndpoints.CONSOLE.FILES}?project_id=${projectId}`
      : ApiEndpoints.CONSOLE.FILES;

    return axios
      .get(`${ApiEndpoints.MODULE_BASE_URL.CONSOLE}${Endpoint}`, { loader })
      .then(
        (resp) => resp,
        (error) => {
          throw error;
        }
      );
  };
  /**
   * @function initiateUploadToS3ForConsoleFiles
   * @description POST API call for initiate console files upload to S3
   * @param {object} fileDetails - details of file
   * @param {function} successCallback - Callback function to be called on success
   * @param {function} errorCallback - Callback function to be called on error
   * @example initiateUploadToS3ForConsoleFiles(fileDetails,successCallback,errorCallback)
   */
  const initiateUploadToS3ForConsoleFiles = (
    fileDetails,
    successCallback,
    errorCallback
  ) => {
    const filePayload = { ...fileDetails };
    filePayload.fileType = fileType;
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.UPLOAD_CONSOLEFILE
        }`,
        filePayload
      )
      .then(
        (resp) => {
          setCheckError(false);
          if (successCallback) {
            successCallback(resp);
          }
        },
        (error) => {
          setCheckError(true);
          errorCallback(error);
        }
      );
  };
  /**
   * @function uploadChunkForConsoleFiles
   * @description PUT API call for upload each chunk to cloud storage
   * @param {object} chunkDetails - details of chunk data
   * @param {object} config - config data
   * @returns {Promise} AxiosPromise
   * @example uploadChunkForConsoleFiles(chunkDetails, config)
   */
  const uploadChunkForConsoleFiles = (chunkDetails, config) => {
    return axios.put(
      `${
        ApiEndpoints.MODULE_BASE_URL.CONSOLE + ApiEndpoints.CONSOLE.UPLOAD_CHUNK
      }`,
      chunkDetails,
      config
    );
  };
  /**
   * @function onCompleteMultipartUploadToS3ForConsoleFiles
   * @description API call for complete multipart upload to S3
   * @param {object} fileChunksSummary - summary of chunk files
   * @param {function} successCallback - Callback function to execute upon completion of API call
   * @param {function} errorCallback - Callback function to execute
   *  when any error happens during the calling of API
   * @returns {Promise} AxiosPromise
   * @example onCompleteMultipartUploadToS3ForConsoleFiles(fileChunksSummary,successCallback,errorCallback)
   */
  const onCompleteMultipartUploadToS3ForConsoleFiles = (
    fileChunksSummary,
    successCallback,
    errorCallback
  ) => {
    axios
      .post(
        `${
          ApiEndpoints.MODULE_BASE_URL.CONSOLE +
          ApiEndpoints.CONSOLE.UPLOAD_COMPLETED
        }`,
        {
          ...fileChunksSummary,
          project_id: activeProject?.project_id,
          file_type: fileType,
          description,
        }
      )
      .then(
        (res) => {
          setCheckError(false);
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  };
  const { startUpload, progress } = useMultipartFileUploadToS3({
    startUploadCallback: initiateUploadToS3ForConsoleFiles,
    uploadChunk: uploadChunkForConsoleFiles,
    onCompleteMultipartUpload: onCompleteMultipartUploadToS3ForConsoleFiles,
  });

  return {
    udfs,
    setUdfs,
    selectedUdf,
    setSelectedUdf,
    fetchUdfs,
    fetchUdfDetails,
    createUdf,
    githubIntegration,
    updateUdf,
    createJob,
    deleteUdf,
    runUdf,
    fetchDatasetColumnDetailsForConsole,
    fetchAllModels,

    consoleModuleTasks,
    consoleAsyncTaskResults,
    fetchAsyncResultConsole,
    terminateTaskConsole,
    markTaskReadUnreadConsole,
    fetchModuleTasksConsole,

    fetchTables,
    fetchCharts,

    setConsoleAsyncTaskResults,

    // consoleUDFParams,
    // fetchUDFParameters,
    // postUDFData,
    commentApiCall,
    // consoleRunTaskResults,
    fetchTableData,
    onDownloadCSV,
    fetchConsoleLibraries,
    deleteLibrary,
    addLibrary,
    updateLibrary,
    consoleRerunOperation,
    startUpload,
    progress,
    deleteConsoleFileWithId,
    setFileType,
    activeProject,
    fetchConsoleFileList,
    setDescription,
    downloadConsoleFile,
    checkError,
  };
}
