import { combineReducers } from 'redux';
import docSlicer from '../toolkit/docSlicer';
import projectSlicer from '../toolkit/projectSlicer';
import userSilcer from '../toolkit/userSilcer';
import commonSlicer from '../toolkit/commonSlicer';

/**
 * @function reducer  Root reducer.
 * @description Redux root reducer.
 * @param {object} state - Current state.
 * @param {string} name - Name of the action.
 * @param {Function} reducer - Reducer function for the action.
 * @returns {Object} - Root reducer.
 */

const reducer = combineReducers({
  user: userSilcer,
  project: projectSlicer,
  doc: docSlicer,
  common: commonSlicer,
});

export default reducer;
