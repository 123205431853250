export const API_MODULE_PATH_CONST = {
  DM: 'datasets',
  EDA: 'eda',
  SAMPLING: 'sampling',
  SEG: 'sg',
  VR: 'vr',
  FTS: 'fts',
  ME: 'me',
  MT: 'mp',
  MI: 'mi',
  MD: 'modeldeployment',
};
