// @ts-nocheck
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/prop-types */
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import DatasetTable from './DatasetTable';
import SwalToast from './SwalToast';
import DialogTransition from './Transitions/DialogTransition';
import { DATASET_REQUIRED_FIELDS } from '../../const/CommonConst';

/**
 * @function OperationResultsViewerDatasetTable
 * @description A Container to view operation results in dataset form with is_grid option.
 * @param {number} totalDatasetRows   - A numerical value which gives the total number of rows in the dataset.
 * @param {function} fetchDatasetRowsWithPagination  - A function to get the page wise rows of the dataset.
 * @param {number} datasetRowsPageCount - A numerical value which contains number of total pages in the dataset.
 * @param {boolean} isLoading  - A boolean to detect the loading status of the dataset response.
 * @param {string} permissions -  A string value containing user permissions.
 * @param {number} projectid  - The value which gives the id of the selected project.
 * @param {object} activeProject  - Currently active project's information object.
 * @param {array} datasetList - A boolean value to check the condition to open dialog box
 * @param {array} paginatedDatasetRowData - Paginated table data.
 * @param {array} taskResultDatasets   - Results in the form of datasets.
 * @returns {JSX.Element} OperationResultsViewerDatasetTable UI
 * @example <OperationResultsViewerDatasetTable />
 */
const OperationResultsViewerDatasetTable = ({
  totalDatasetRows,
  fetchDatasetRowsWithPagination,
  datasetRowsPageCount,
  isLoading,
  permissions,
  projectid,
  activeProject,
  datasetList,
  paginatedDatasetRowData,
  taskResultDatasets,
  fetchAllDatasets,
  markDatasetAsReadyToUse,
  setSwitchReadyToUse,
  disableHeaderBorders = false,
  enableComments = true,
}) => {
  const [showReadyToUse, setReadyToUse] = React.useState(false);
  const [selectedDsIdFrMarkReadyToUse, setSelectedDsIdFrMarkReadyToUse] =
    React.useState();

  const updatedTaskResultDatasets = taskResultDatasets.map((item2) => {
    const matchingItem1 = datasetList.find((item1) => item1.id === item2.key);
    if (matchingItem1) {
      return {
        ...item2,
        published: matchingItem1.published,
        preprocessed: matchingItem1.preprocessed,
      };
    }
    return item2;
  });

  /**
   * @function onReadyToUse
   * @description A function to mark the dataset as ready to use.
   * @returns {void} void
   */
  const onReadyToUse = useCallback(() => {
    const data = { id: selectedDsIdFrMarkReadyToUse };
    markDatasetAsReadyToUse(data).then(
      (res) => {
        SwalToast({
          icon: 'success',
          title: res.data.msg,
        });
        setReadyToUse(false);
        fetchAllDatasets(
          activeProject.project_id ?? '',
          true,
          [DATASET_REQUIRED_FIELDS].join()
        ).then(
          () => {
            setSwitchReadyToUse(true);
          },
          (error) => {
            throw error;
          }
        );
      },
      (error) => {
        throw error;
      }
    );
  }, [
    selectedDsIdFrMarkReadyToUse,
    markDatasetAsReadyToUse,
    SwalToast,
    setReadyToUse,
    fetchAllDatasets,
  ]);
  return (
    <Box display="flex" flexDirection="column" height="inherit">
      <Box height="inherit">
        <Box width="100%" height="100%" textAlign="center">
          <Grid item xs={12}>
            {updatedTaskResultDatasets?.map((dataset) => {
              return (
                <>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      border: !disableHeaderBorders ? 1 : 0,
                      borderBottom: 0,
                      borderStyle: 'solid',
                      borderColor: 'secondary.main',
                      borderRadius: '4px 4px 0 0',
                      padding: 1,
                    }}
                    display="flex"
                    alignItems="center"
                    flex="1"
                    flexDirection="row"
                    data-html2canvas-ignore="true"
                  >
                    <Box flexGrow={1} textAlign="left" paddingLeft={1}>
                      <Typography
                        variant="h4"
                        style={{ textTransform: 'capitalize' }}
                      >
                        Dataset : {dataset?.label}
                      </Typography>
                    </Box>
                    {(permissions === 'Manage' || permissions === 'View') &&
                    enableComments ? (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                        title={
                          permissions === 'Manage'
                            ? 'Ready to use'
                            : 'You are not authorized to access this!'
                        }
                      >
                        <Switch
                          checked={dataset?.published === true}
                          onChange={() => {
                            // condition to return if dataset is already ready to use
                            if (dataset?.published === true) {
                              return;
                            }
                            setSelectedDsIdFrMarkReadyToUse(dataset?.key);
                            setReadyToUse(true);
                          }}
                          disabled={
                            dataset?.preprocessed !== 'done' ||
                            permissions !== 'Manage'
                          }
                        />
                      </Box>
                    ) : null}
                  </Box>
                  <Dialog
                    onClose={() => {
                      setReadyToUse(false);
                    }}
                    aria-labelledby="Mark as Ready to use"
                    maxWidth="xs"
                    fullWidth
                    open={showReadyToUse}
                    TransitionComponent={DialogTransition}
                  >
                    <DialogTitle>Ready To Use</DialogTitle>
                    <IconButton
                      id="dm_di_dts_tb_close_icon"
                      aria-label="close"
                      onClick={() => {
                        setReadyToUse(false);
                      }}
                      sx={{
                        position: 'absolute',
                        right: 16,
                        top: 8,
                      }}
                      color="primary"
                      size="large"
                    >
                      <CloseIcon />
                    </IconButton>

                    <DialogContent dividers>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6" align="center">
                            Are you sure you want to mark this dataset as ready
                            to use?
                          </Typography>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        onClick={() => {
                          setReadyToUse(false);
                        }}
                        disabled={permissions !== 'Manage'}
                      >
                        No
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={onReadyToUse}
                        disabled={permissions !== 'Manage'}
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <DatasetTable
                    dataset={
                      datasetList?.filter((ele) => {
                        return ele?.id === dataset?.key;
                      })?.[0]
                    }
                    tableDataWithPagination={paginatedDatasetRowData}
                    setTableDataWithPagination={() => {}}
                    updateDatasetCells={() => {}}
                    totalDatasetRows={totalDatasetRows}
                    fetchDatasetRowsWithPagination={
                      fetchDatasetRowsWithPagination
                    }
                    datasetRowsPageCount={datasetRowsPageCount}
                    isLoading={isLoading}
                    projectId={projectid}
                    activeProject={activeProject}
                    dataSets={datasetList?.length ? datasetList : []}
                    fetchGridTaskData={() => {}}
                    setGridTaskResultsData={() => {}}
                    permission={permissions}
                    isReadOnly={true}
                    showToolbar={false}
                    uniqueId={`result-viewer-ds-${dataset?.key}`}
                  />
                </>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

OperationResultsViewerDatasetTable.defaultProps = {
  paginatedDatasetRowData: [],
  disableHeaderBorders: false,
  enableComments: true,
};
OperationResultsViewerDatasetTable.propTypes = {
  paginatedDatasetRowData: PropTypes.oneOfType([PropTypes.array]),
  totalDatasetRows: PropTypes.number.isRequired,
  fetchDatasetRowsWithPagination: PropTypes.func.isRequired,
  datasetRowsPageCount: PropTypes.number.isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  disableHeaderBorders: PropTypes.bool,
  enableComments: PropTypes.bool,
};
export default React.memo(OperationResultsViewerDatasetTable);
