/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import defaultDoc from '../const/DefaultDoc';

const initialState = {
  activeDoc: null,
  activeDocSection: {},
  docEditorOverview: [],
  allTemplate: [],
  moduleOptiontype: {},
  docTableAndCharts: {},
  docList: null,
  docSectionList: {},
  docSubSectionList: {},
  htmlBody: defaultDoc,
  docFile: null,
  submodOperations: {
    dataManagement: [
      'Data Quality',
      'Data Validation',
      'Data Preperation and Adjustment',
    ],
    eda: [
      'Preliminary Steps',
      'Treating Outliers',
      'Missing Value Imputation',
      'Variable Analysis',
      'Statistical Tests',
      'Time Series Analysis',
    ],
  },
  isLoading: false,
  error: null,
};

/**
 * @function docSlice  Documentation slice reducer.
 * @description Redux slice reducer for documentation state.
 * @param {initialState} state - Current documentation state.
 * @param {string} name - Name of the action.
 * @param {Function} reducer - Reducer function for the action.
 * @returns {Object} - Documentation slice reducer.
 */

export const docSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    setActiveDoc: (state, action) => {
      state.activeDoc = action.payload;
    },
    setdocList: (state, action) => {
      state.docList = action.payload;
    },
    setDocFile: (state, action) => {
      state.docFile = action.payload;
    },
    setdocSubSectionList: (state, action) => {
      state.docSubSectionList = action.payload;
    },
    setdocEditorOverview: (state, action) => {
      state.docEditorOverview = action.payload;
    },
    setdocSectionList: (state, action) => {
      state.docSectionList = action.payload;
    },
    setdocTableAndCharts: (state, action) => {
      state.docTableAndCharts = action.payload;
    },
    setActiveDocSection: (state, action) => {
      state.activeDocSection = action.payload;
    },
    setHtmlBody: (state, action) => {
      state.htmlBody = action.payload;
    },
    setmoduleOptiontype: (state, action) => {
      state.moduleOptiontype = action.payload;
    },
    setallOverview: (state, action) => {
      state.allTemplate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setActiveDoc,
  setdocList,
  setDocFile,
  setActiveDocSection,
  setdocSubSectionList,
  setdocEditorOverview,
  setdocTableAndCharts,
  setdocSectionList,
  setHtmlBody,
  setmoduleOptiontype,
  setallOverview,
} = docSlice.actions;

export default docSlice.reducer;
