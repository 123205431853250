/* eslint-disable no-param-reassign */
import { Grid, Box, Typography, Switch, IconButton } from '@mui/material';
import {
  ReactMuiTable,
  ReactMuiTableColumnHeaderTextEllipsis,
  utils,
} from 'solytics-frontend';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import MultiSelect from '../Common/MultiSelect';
import { DropDown, DropdownItem } from '../Common/DropDown';
import {
  STRENGTH_METRICS,
  STABILITY_METRICS,
} from '../../const/ModelEstimationConst';
import NoDataFoundComponent from '../Common/NoDataFoundComponent';

const customCell = (data) => {
  const { cell } = data;
  return (
    <>
      <Box title={cell.value}>
        {cell.value === null ? '-' : utils.getFormattedNumber(cell?.value)}
      </Box>
    </>
  );
};

/**
 * @function ModelComparisonAndResultsFilter
 * @description To show comparison and results filter model
 * @param {object} taskResults - An object which stores results of task
 * @param {object} parameters - An object
 * @param {function} setTaskResults - A function to set task results
 * @param {string} activeView - A string value which stores the activeView value
 * @param {string} operationName - A string value which stores the name of operation
 * @param {function} setShowResultsOnComparison - A function to set show results on comparison
 * @returns {JSX.Element} ModelComparisonAndResultsFilter UI
 * @example <ModelComparisonAndResultsFilter taskResults={taskResults} />
 */
const ModelComparisonAndResultsFilter = ({
  taskResults,
  parameters,
  setTaskResults,
  activeView,
  operationName,
  setShowResultsOnComparison,
  modelComparisonView,
  setModelComparisonView,
}) => {
  const [columnGraphData, setColumnGraphData] = useState([]);
  const [columnTableData, setColumnTableData] = useState([]);
  const [selectedModelingTechnique, setSelectedModelingTechnique] =
    useState('');
  const [modelTechniquesForComparison, setModelTechniquesForComparison] =
    useState([]);
  // eslint-disable-next-line no-underscore-dangle, no-unused-vars
  const [_taskResults, _setTaskResults] = useState(cloneDeep(taskResults));

  /**
   * @function modelComparisonChartsAndTableFilter
   * @description To filter the data for model comparison charts and table
   * @param {object} ele - An object which stores the data
   * @returns {object} ele - An object which stores the data
   */

  const modelComparisonChartsAndTableFilter = React.useCallback(
    (ele) => {
      const isModelInitiation = operationName === 'ModelInitiation';
      const isModelInitiationTS = operationName === 'ModelInitiationTS';
      const hasSelectedModelingTechnique = selectedModelingTechnique !== '';
      if (
        ((activeView === 'graph' && columnGraphData?.length === 0) ||
          (activeView === 'table' && columnTableData?.length === 0) ||
          (activeView === 'graph' &&
            isModelInitiation &&
            columnGraphData.some(
              (el) => el.value === ele.strengthMetrics?.[0]
            )) ||
          (activeView === 'table' &&
            isModelInitiation &&
            columnTableData.some(
              (el) => el.value === ele.stabilityMetrics?.[0]
            ))) &&
        modelTechniquesForComparison.some(
          (el) => el.Model_technique === ele.model_technique
        )
      ) {
        return ele;
      }

      if (
        hasSelectedModelingTechnique &&
        ele.model_technique === selectedModelingTechnique
      ) {
        return ele;
      }

      if (
        isModelInitiationTS &&
        modelTechniquesForComparison.some(
          (el) => el.model_technique === ele.model_technique
        )
      ) {
        return ele;
      }

      return null;
    },
    [
      operationName,
      selectedModelingTechnique,
      activeView,
      columnGraphData,
      columnTableData,
      modelTechniquesForComparison,
    ]
  );
  useEffect(() => {
    setColumnGraphData([]);
    setColumnTableData([]);
  }, [activeView]);

  useEffect(() => {
    if (modelComparisonView) {
      setSelectedModelingTechnique('');
      setShowResultsOnComparison(false);
      if (modelTechniquesForComparison?.length) {
        setShowResultsOnComparison(true);
      }
    }

    if (!modelComparisonView) {
      setModelTechniquesForComparison([]);
      setColumnGraphData([]);
      setColumnTableData([]);
      setShowResultsOnComparison(true);
    }

    if (_taskResults.is_chart && _taskResults.is_table) {
      const charts = _taskResults.charts.filter((ele) => {
        return modelComparisonChartsAndTableFilter(ele);
      });

      setTaskResults((prevState) => {
        if (charts.length) {
          prevState.charts = charts;
        } else {
          prevState.charts = _taskResults.charts;
        }

        return { ...prevState };
      });
      const tables = _taskResults.tables.filter((ele) => {
        return modelComparisonChartsAndTableFilter(ele);
      });

      setTaskResults((prevState) => {
        if (tables.length) {
          prevState.tables = tables;
          prevState.table_id = tables.map(({ id }) => id);
        } else {
          prevState.tables = _taskResults.tables;
          prevState.table_id = _taskResults.table_id;
        }

        return { ...prevState };
      });
    }
  }, [
    columnGraphData,
    columnTableData,
    selectedModelingTechnique,
    modelTechniquesForComparison,
    modelComparisonView,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'Model Technique Label',
        heading: 'Model Technique',
        // accessor is the "key" in the data
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'Precision',
        heading: 'Precision',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'Recall',
        heading: 'Recall',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'F1',
        heading: 'F1-Score',
        Cell: customCell,
        Filter: false,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'Accuracy',
        heading: 'Accuracy',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
    ],
    [modelComparisonView]
  );

  const timeSeriesColumns = useMemo(
    () => [
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'Model Technique Label',
        heading: 'Model Technique',
        // accessor is the "key" in the data
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'R-Square',
        heading: 'R-Square',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'Adjusted R-Square',
        heading: 'Adjusted R-Square',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },

      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'RMSE',
        heading: 'RMSE',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: ReactMuiTableColumnHeaderTextEllipsis,
        accessor: 'MAE',
        heading: 'MAE',
        Cell: customCell,
        width: 100,
        maxWidth: 100,
        minWidth: 100,
      },
    ],
    [modelComparisonView]
  );

  /**
   * @function getSelectedRowData
   * @description to get selected row data
   * @param {number} selectedRowIds - A numeric value which stores the ids of selected rows
   * @returns {void}
   * @example <getSelectedRowData selectedRowIds={selectedRowIds} />
   */
  const getSelectedRowData = ({ selectedRowIds }) => {
    // eslint-disable-next-line array-callback-return
    const selectedRows = taskResults?.response.filter((row, i) => {
      const numArray = Object.keys(selectedRowIds).map(Number);
      if (numArray.includes(i)) {
        return row.Model_technique ? row.Model_technique : row.model_technique;
      }
    });
    setModelTechniquesForComparison(selectedRows);
  };
  const modelComparisionOnChange = useCallback(() => {
    setModelComparisonView((prev) => !prev);
  }, [setModelComparisonView]);
  const getHeaderPropsReactMuiTable = useCallback(
    (column) => ({
      style: {
        ...(column.id === 'selection' ? { minWidth: 100, maxWidth: 120 } : {}),
      },
    }),
    []
  );
  const getColumnPropsReactMuiTable = useCallback(
    (column) => ({
      style: {
        ...(column.id === 'selection'
          ? { textAlign: 'center', minWidth: 100, maxWidth: 120 }
          : {}),
      },
    }),
    []
  );
  const selectModelTechniqueOnChange = useCallback(
    (e) => {
      setSelectedModelingTechnique(e.target.value);
    },
    [setSelectedModelingTechnique]
  );
  const selectModelTechniqueOnClick = useCallback(() => {
    setSelectedModelingTechnique('');
  }, [setSelectedModelingTechnique]);
  const selectMetricsForTableOnChange = useCallback(
    (e) => {
      setColumnTableData(e);
    },
    [setColumnTableData]
  );
  const selectMetricsForGraphsOnChange = useCallback(
    (e) => {
      setColumnGraphData(e);
    },
    [setColumnGraphData]
  );
  return (
    <>
      <Box pt={2} pb={2}>
        <Box mt={3}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mb={1}
          >
            <Grid xs={4} />
            <Grid
              xs={4}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h4">Model Summary Table</Typography>
            </Grid>
            <Grid
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              xs={4}
            >
              <Switch
                checked={modelComparisonView}
                onChange={modelComparisionOnChange}
              />
              <Typography variant="body2">Model Comparison</Typography>
            </Grid>
          </Grid>

          {taskResults?.response?.length ? (
            <ReactMuiTable
              columns={
                parameters?.modelTechniquesTS?.length > 0
                  ? timeSeriesColumns
                  : columns
              }
              data={taskResults?.response}
              pageCount={taskResults?.response?.length}
              updateMyData={null}
              skipPageReset={true}
              enableRowSelection={false}
              enablePagination={true}
              enableVirtualization={false}
              showToolbar={false}
              enableControlledPagination={false}
              onTableStateChange={getSelectedRowData}
              rowSelectionConfig={{
                enable: modelComparisonView,
                mode: 'checkbox',
                showDeleteOnSelected: false,
                type: 'multi-select',
              }}
              getHeaderProps={getHeaderPropsReactMuiTable}
              getColumnProps={getColumnPropsReactMuiTable}
              NoDataFoundComponent={NoDataFoundComponent}
            />
          ) : null}
        </Box>

        <Grid container spacing={3} mt={3}>
          {!modelComparisonView && activeView !== 'dataset' && (
            <Grid item xs={3}>
              <DropDown
                label="Model technique"
                name="modeling_technique"
                placeholder="Select model technique"
                onChange={selectModelTechniqueOnChange}
                value={selectedModelingTechnique}
                formikFormError={null}
                endAdornment={
                  <IconButton
                    size="small"
                    sx={{
                      display: selectedModelingTechnique !== '' ? '' : 'none',
                      marginRight: 3,
                    }}
                    onClick={selectModelTechniqueOnClick}
                  >
                    <ClearIcon />
                  </IconButton>
                }
              >
                {(parameters?.modelTechniquesTS?.length > 0
                  ? parameters?.modelTechniquesTS
                  : parameters?.model_techniques_sc
                )?.map((option) => {
                  return (
                    <DropdownItem key={option.value} value={option.value}>
                      {option?.label}
                    </DropdownItem>
                  );
                })}
              </DropDown>
            </Grid>
          )}
          {activeView === 'table' &&
            !parameters?.modelTechniquesTS &&
            modelComparisonView && (
              <Grid item xs={3}>
                <MultiSelect
                  options={STABILITY_METRICS?.filter((ele) =>
                    parameters?.stability_metrics?.includes(ele?.value)
                  )}
                  label="Metrics for tables"
                  name="modelColumns"
                  placeholder="Select metrics for tables"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={columnTableData !== undefined ? columnTableData : []}
                  onChange={selectMetricsForTableOnChange}
                  disableCloseOnSelect={false}
                />
              </Grid>
            )}
          {activeView === 'graph' &&
            !parameters?.modelTechniquesTS &&
            modelComparisonView && (
              <Grid item xs={3}>
                <MultiSelect
                  options={STRENGTH_METRICS?.filter((ele) =>
                    parameters?.strength_metrics?.includes(ele?.value)
                  )}
                  label="Metrics for graphs"
                  name="model_technique"
                  placeholder="Select metrics for graphs"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={columnGraphData !== undefined ? columnGraphData : []}
                  onChange={selectMetricsForGraphsOnChange}
                  disableCloseOnSelect={false}
                />
              </Grid>
            )}
        </Grid>
      </Box>
    </>
  );
};

ModelComparisonAndResultsFilter.propTypes = {
  taskResults: PropTypes.oneOfType([PropTypes.object]).isRequired,
  parameters: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setTaskResults: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
  operationName: PropTypes.string.isRequired,
  setShowResultsOnComparison: PropTypes.func.isRequired,
  modelComparisonView: PropTypes.bool.isRequired,
  setModelComparisonView: PropTypes.func.isRequired,
};

export default ModelComparisonAndResultsFilter;
