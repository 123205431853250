import { CardContent, Grid, Typography, Box, Card } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'InfoCard';

const classes = {
  cardContainer: `${PREFIX}-cardContainer`,
  cardHeader: `${PREFIX}-cardHeader`,
  classHeaderText: `${PREFIX}-classHeaderText`,
  centerThis: `${PREFIX}-centerThis`,
  commonUtilsCardContainer: `${PREFIX}-commonUtilsCardContainer`,
  commonUtilsCardHeader: `${PREFIX}-commonUtilsCardHeader`,
  commonUtilsCardHeaderText: `${PREFIX}-commonUtilsCardHeaderText`,
  commonUtilsCardBody: `${PREFIX}-commonUtilsCardBody`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.cardContainer}`]: {
    border: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: 5,
    opacity: 1,
  },

  [`& .${classes.cardHeader}`]: {
    background: theme.palette.primary.main,
    textAlign: 'center',
    padding: 4,
  },

  [`& .${classes.classHeaderText}`]: {
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: 'white',
    fontWeight: 400,
  },

  [`& .${classes.centerThis}`]: {
    textAlign: 'center',
  },

  [`& .${classes.commonUtilsCardContainer}`]: {
    border: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 5,
    opacity: 1,
  },

  [`& .${classes.commonUtilsCardHeader}`]: {
    background: theme.palette.primary.main,
    opacity: 1,
    height: theme.spacing(3.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.commonUtilsCardHeaderText}`]: {
    textTransform: 'uppercase',
    color: 'White',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    paddingLeft: 15,
    height: '100%',
  },

  [`& .${classes.commonUtilsCardBody}`]: {
    overFlowX: 'hidden',
    overFlowY: 'auto',
  },
}));
/**
 * @function InfoCard
 * @description UI card to store info
 * @param {object} activeOperation - object conatining details of selected operation.
 * @param {string} content - It sets the content
 * @param {string} header - It sets the header
 * @param {number} children - It tells the number of children
 * @param {number} maxHeight - It sets the max Height
 * @returns {JSX.Element}
 */
const InfoCard = ({
  activeOperation,
  content,
  header,
  maxHeight,
  children,
}) => (
  <Root>
    {activeOperation ? (
      <Card>
        <Box pt={2} pl={3} pr={3} pb={2} style={{ backgroundColor: 'white' }}>
          <Box className="please-select-project-message" mt={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <Box className={classes.cardContainer}>
                  <Box className={classes.cardHeader} mb={2}>
                    <Typography
                      variant="body2"
                      className={classes.classHeaderText}
                    >
                      {header}
                    </Typography>
                  </Box>

                  <Box>
                    <CardContent className={classes.centerThis}>
                      <Typography variant="body2">
                        {content}
                        <strong className="operation-name-for-card">
                          {' '}
                          {activeOperation?.label
                            ? activeOperation.label
                            : ''}{' '}
                          &nbsp;
                        </strong>
                        operation .
                      </Typography>
                    </CardContent>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    ) : (
      <div className={classes.commonUtilsCardContainer}>
        <div className={classes.commonUtilsCardHeader}>
          <p className={classes.commonUtilsCardHeaderText}>{header}</p>
        </div>

        <div
          style={
            maxHeight ? { maxHeight: `${maxHeight}` } : { maxHeight: '11vw' }
          }
          className={classes.commonUtilsCardBody}
        >
          {children}
        </div>
      </div>
    )}
  </Root>
);

InfoCard.propTypes = {
  activeOperation: PropTypes.oneOfType([PropTypes.object]).isRequired,
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  maxHeight: PropTypes.number.isRequired,
  children: PropTypes.number.isRequired,
};

export default React.memo(InfoCard);
