/* eslint-disable no-unused-vars */
// @ts-nocheck
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  IconButton,
  Box,
  Avatar,
  Badge,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import React, {
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { isEmpty } from 'lodash';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as HelpOutlineIcon } from '../../assets/images/help-circle.svg';
import utils from '../../utils/utils';
import CreateEditProjectModal from '../Project/CreateEditProjectModal';
import { ReactComponent as NimbusLogo } from '../../assets/images/NIMBUS Uno.svg';
import { ReactComponent as InventoryIcon } from '../../assets/images/InventoryIcon.svg';
import { SwalToast } from '../Common';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
import { setIsHelpIconClicked } from '../../toolkit/commonSlicer';
import useCommon from '../../Hooks/useCommon';
import HelpSectionDrawer from '../../views/HelpSection/HelpSectionDrawer';

const PREFIX = 'AppHeader';

const classes = {
  appBar: `${PREFIX}-appBar`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  createProject: `${PREFIX}-createProject`,
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.appBar}`]: {
    position: 'absolute',
    height: theme.spacing(7.25),
    paddingRight: 0,
    zIndex: theme.zIndex.drawer + 1,
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    cursor: 'pointer',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    '& .MuiFormControl-root': {
      height: theme.spacing(4.5),
    },
    '& .MuiAutocomplete-endAdornment': {
      top: '6px',
      right: '2px !important',
    },
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(4.5),
      '& fieldset': {
        borderColor: `${theme.palette.secondary.main}33`,
        '& legend': {
          width: 0.01,
        },
      },
    },
  },

  [`& .${classes.inputInput}`]: {
    // padding: theme.spacing(1, 1, 1, 2),
    paddingLeft: `calc(1em + ${theme.spacing(8)})`,
    transition: theme.transitions.create('width'),
    width: '12ch',
    color: theme.palette.other.white,
  },
}));

const isOptionEqualToValue = (option, _value) => {
  return option.project_id === _value.project_id;
};

const getOptionLabel = (_project) => {
  if (_project && _project.name) {
    return _project.name;
  }
  // eslint-disable-next-line
  else {
    return '';
  }
};

/**
 * @function AppHeader
 * @description Header of the application
 * @param {object} project - project
 * @param {function} fetchUserProjects - fetch users projects
 * @param {function} createNewProject - create new project
 * @param {function} fetchSentReceivedArtifacts - fetch sent and received artifacts
 * @param {function} fetchSentReceivedProject - fetch sent and received projects
 * @param {function} fetchSentReceivedPipelines - fetch sent and received pipelines
 * @param {object} history -used to access browser's history session
 * @param {object} activateProject - active project
 * @param  {function} fetchDataSets - to fetch all the datasets
 * @param {object} user - user
 * @param {object} permission - permission
 * @param {object} fetchUserProjectsStatus - fetch user projects status
 * @returns {JSX} AppHeader UI
 * @example <AppHeader project={project}/>
 */
const AppHeader = ({
  project,
  fetchUserProjects,
  createNewProject,
  fetchSentReceivedArtifacts,
  fetchSentReceivedProject,
  fetchSentReceivedPipelines,
  activateProject,
  fetchDatasets,
  user,
  history,
  permission,
  fetchUserProjectsStatus,
  fetchProjectTypes,
}) => {
  const { isHelpIconClicked } = useSelector((state) => state.common);
  const { common } = useCommon();
  const dispatch = useDispatch();
  // for AD only
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const [inputValueProject, setInputValueProject] = useState('');
  const [value, setValue] = useState(project.activeProject);
  const [pendingArtifactsCount, setPendingArtifactsCount] = useState([]);
  const [pendingProjectCount, setPendingProjectCount] = useState([]);
  const [pendingPipelineCount, setpendingPipelineCount] = useState([]);
  const anchorRef = useRef();
  const inputRef = useRef();
  const [open, setOpen] = useState(false);
  const helpSectionOnClick = useCallback(() => {
    dispatch(setIsHelpIconClicked());
  }, [setIsHelpIconClicked]);
  /**
   * @function updatePendingArtifactsCount
   * @description It updates state of the Pending Artifacts Count
   * @returns {void}
   * @example updatePendingArtifactsCount()
   */

  const updatePendingArtifactsCount = () => {
    fetchSentReceivedProject().then((res) => {
      const pendingData =
        res.data.received?.length >= 1
          ? res.data.received?.filter(
              (data) => data?.receiver_status === 'PENDING'
            )
          : [];
      setPendingProjectCount(pendingData);
    });
    fetchSentReceivedArtifacts((res) => {
      const pendingData =
        res.data.recieved?.length >= 1
          ? res.data.recieved?.filter(
              (data) =>
                data?.reciever_status === 'Pending' && data.is_present === true
            )
          : [];
      setPendingArtifactsCount(pendingData);
    });
    fetchSentReceivedPipelines().then((res) => {
      const pendingData =
        res.data.received?.length >= 1
          ? res.data.received?.filter(
              (data) => data?.receiver_status === 'Pending'
            )
          : [];
      setpendingPipelineCount(pendingData);
    });
  };
  /**
   * @function pendingArtifactsProjectAndPipelineCount
   * @description It counts the pending Artifacts, Projects and Pipelines
   * @returns {void}
   * @example pendingArtifactsProjectAndPipelineCount()
   */

  const pendingArtifactsProjectAndPipelineCount = [
    ...pendingArtifactsCount,
    ...pendingProjectCount,
    ...pendingPipelineCount,
  ];

  /**
   * @function handleArtifactStatusChange
   * @description It handles the Status change of Artifact
   * @returns {void}
   * @example handleArtifactStatusChange()
   */
  const handleArtifactStatusChange = () => {
    updatePendingArtifactsCount();
  };

  React.useEffect(() => {
    updatePendingArtifactsCount();

    window.addEventListener('artifactStatusChange', handleArtifactStatusChange);

    return () => {
      window.removeEventListener(
        'artifactStatusChange',
        handleArtifactStatusChange
      );
    };
  }, []);

  React.useEffect(() => {
    if (!project.activeProject && localStorage?.USER_ACTIVE_PROJECT_LIST) {
      const getIdProject = new Map(
        JSON.parse(localStorage?.USER_ACTIVE_PROJECT_LIST)
      );
      const userId = JSON.parse(localStorage?.user_data).id;
      const activeUserIdDetails = getIdProject.get(userId) || false;
      if (activeUserIdDetails) {
        const activeProjectSavedState = JSON.parse(activeUserIdDetails);
        project.userProjectsList.forEach((prj) => {
          if (prj.project_id === Number(activeProjectSavedState.project_id)) {
            localStorage.setItem('ACTIVE_PROJECT', activeProjectSavedState);
            activateProject(prj);
            // fetchDatasets();
          }
        });
      }
    }
  }, [project]);

  const showProjectsHandler = () => {
    inputRef.current.focus();
  };
  React.useEffect(() => {
    document.addEventListener('showProjects', showProjectsHandler, false);
    return () => {
      document.removeEventListener('showProjects', showProjectsHandler);
    };
  }, []);

  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  /**
   * @function handleOpen
   * @description changing state of open variable from null  to true
   * @returns {void}
   * @example handleOpen()
   */
  const handleOpen = useCallback(() => {
    setOpenCreateProjectModal(true);
  }, [setOpenCreateProjectModal]);
  /**
   * @function handleClose
   * @description changing state of open variable from null  to false
   * @returns {void}
   * @example handleClose()
   */
  const handleClose = useCallback(() => {
    setOpenCreateProjectModal(false);
  }, [setOpenCreateProjectModal]);

  useLayoutEffect(() => {
    if (!project?.userProjectsList?.length) {
      fetchUserProjects(
        null,
        () => {},
        () => {}
      );
    }
    setInputValueProject(
      project?.activeProject?.name ? project.activeProject.name : ''
    );
    setValue(project?.activeProject ? project?.activeProject : '');
  }, [project?.activeProject?.name]);

  /**
   * @function logout
   * @description Clearing local Storage, deleting notification data cookie
   * @returns {void}
   * @example logout()
   */
  const logout = () => {
    const { activeProject, userId, userAndIdDetails, userEmail } =
      utils.getUserDetailsFromStorage();
    const normalLogout = () => {
      if (activeProject) {
        userAndIdDetails.set(userId, activeProject);
      }
      localStorage.clear();
      sessionStorage.clear();
      if (userAndIdDetails?.size > 0) {
        localStorage.setItem(
          'USER_ACTIVE_PROJECT_LIST',
          JSON.stringify(Array.from(userAndIdDetails.entries()))
        );
      }
      window?.cookieStore?.delete('RECENTLY_CLICKED_NOTIFICATION_DATA');
      window.location.reload();
    };
    // for AD only.
    if (isAuthenticated && !isEmpty(userEmail)) {
      const currentAccount = instance.getAccountByUsername(userEmail);
      // logout_hint needs to be enables from app settings.
      // Read more at https://learn.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
      const logoutHint = currentAccount?.idTokenClaims?.login_hint;
      instance
        .logoutPopup({
          logoutHint,
          mainWindowRedirectUri: '/', // redirects the top level app after logout
        })
        .then(() => {
          normalLogout();
        });
    } else {
      normalLogout();
    }
  };

  /**
   * @function handleToggle
   * @description Reversing state of prevOpen
   * @returns {void}
   * @example handleToggle()
   */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * @function handleCloseUser
   * @description Handling the close user
   * @param {object} event - event object
   * @returns {void}
   * @example handleCloseUser(event)
   */
  const handleCloseUser = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCreateProject = useCallback(
    (values) => {
      history.push('/project/create', values);
    },
    [history]
  );

  /**
   * @function handleListKeyDown
   * @description Once the required condition is fulfilled it prevents the default action and -
   * - change the state to false
   * @param {object} event - event object
   * @returns {void}
   * @example handleListKeyDown event={event}
   */
  const handleListKeyDown = useCallback(
    (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    [setOpen]
  );

  const handleArtifactRedirect = useCallback(() => {
    history.push('/project/sent-received-artifacts');
  }, [history]);

  const handleLogout = () => {
    logout();
  };

  return (
    <AppBar
      position="absolute"
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      elevation={2}
    >
      <Toolbar>
        <Box flexGrow={1}>
          <NimbusLogo
            title="NIMBUS Uno"
            className="nimbusLogo"
            height="40px"
            width="87.5px"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width={500}
        >
          <Button
            color="secondary"
            sx={(theme) => ({
              '&.Mui-disabled': {
                background: theme.palette.other.grey2,
                color: theme.palette.other.white2,
              },
            })}
            variant="contained"
            title={
              permission !== 'Yes'
                ? 'You are not authorized to access this!'
                : ''
            }
            disabled={permission !== 'Yes'}
            onClick={handleOpen}
            size="large"
          >
            + Create Project
          </Button>
          <Box
            width="200px"
            height="100%"
            sx={{
              background: 'rgba(174, 205, 252, 0.2)',
              borderRadius: '5px',
            }}
          >
            <Autocomplete
              // classes={{
              //   root: classes.inputRoot,
              //   input: classes.inputInput,
              // }}
              sx={(theme) => ({
                width: '100%',
                outline: 0,
                height: '100%',
                color: theme.palette.other.white,
                '& .MuiAutocomplete-endAdornment': {
                  right: '5px !important',
                },
              })}
              value={value}
              openOnFocus
              disableClearable={['', undefined, {}].includes(value?.name)}
              id="app_header_nav_bar_select_project"
              inputValue={inputValueProject}
              options={project.userProjectsList.filter(
                (option) =>
                  !option.is_archived &&
                  !['InProgress', 'Failed'].includes(option?.clone_status) &&
                  !['InProgress', 'Failed'].includes(option?.share_status)
              )}
              noOptionsText="No project available."
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={isOptionEqualToValue}
              onInputChange={(_event, newInputValue) => {
                setInputValueProject(newInputValue);
              }}
              onChange={(_event, newValue) => {
                setValue(newValue);
                project.userProjectsList.forEach((prj) => {
                  if (
                    newValue !== null &&
                    prj.project_id === Number(newValue.project_id)
                  ) {
                    activateProject(prj);
                    // fetchDatasets(prj.project_id);
                    SwalToast({
                      icon: 'success',
                      title: ` Project ${prj.name} activated successfully.`,
                    });
                  }
                });
              }}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  {...params}
                  placeholder={
                    project.activeProject?.name
                      ? project.activeProject.name
                      : 'Search project'
                  }
                  inputRef={inputRef}
                  sx={(theme) => ({
                    marginTop: 0,
                    height: '40px',
                    '& .MuiInputBase-root': {
                      backgroundColor: 'transparent !important',
                      color: theme.palette.other.white,
                    },
                    '& .MuiSvgIcon-root': {
                      color: theme.palette.other.white,
                    },
                  })}
                />
              )}
            />
          </Box>
          <Box ml={1}>
            <IconButton onClick={helpSectionOnClick} title="Help section">
              <HelpOutlineIcon
                color="secondary"
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Box>
          <Box>
            <Badge
              badgeContent={pendingArtifactsProjectAndPipelineCount?.length}
              sx={(theme) => ({
                '& .MuiBadge-badge': {
                  color: theme.palette.other.white,
                  backgroundColor: theme.palette.other.badgeBorder,
                  marginRight: '10px',
                  marginTop: '10px',
                },
              })}
              title={
                pendingArtifactsProjectAndPipelineCount?.length
                  ? 'Pending artifacts/projects/pipelines'
                  : ''
              }
            >
              <IconButton
                onClick={handleArtifactRedirect}
                title="Artifacts/Projects/Pipelines"
              >
                <InventoryIcon />
              </IconButton>
            </Badge>
          </Box>

          <Box>
            <IconButton
              id="app_header_navbar_people_btn"
              ref={anchorRef}
              aria-controls={open ? 'fade-menu' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              title={user?.userData?.username}
            >
              <Avatar
                sx={{
                  width: 28,
                  height: 28,
                  color: 'primary.main',
                  backgroundColor: 'secondary.main',
                }}
              >
                {utils.getInitials(user?.userData?.username)}
              </Avatar>
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                    marginTop: '8px',
                    marginRight: '24px',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        // eslint-disable-next-line react/jsx-no-bind
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleCloseUser} disabled>
                          {user?.userData?.username}
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>
      </Toolbar>
      <CreateEditProjectModal
        open={openCreateProjectModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
        onCreateProject={handleCreateProject}
        createNewProject={createNewProject}
        fetchProjects={fetchUserProjects}
        onClose={handleClose}
        fetchUserProjectsStatus={fetchUserProjectsStatus}
        fetchProjectTypes={fetchProjectTypes}
      />
      {common?.isHelpIconClicked && (
        <HelpSectionDrawer
          setShowHelpSectionDrawer={() => dispatch(setIsHelpIconClicked())}
          showHelpSectionDrawer={isHelpIconClicked}
          moduleName={[]}
        />
      )}
    </AppBar>
  );
};
AppHeader.defaultProps = {
  permission: '',
};
AppHeader.propTypes = {
  project: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchUserProjects: PropTypes.func.isRequired,
  activateProject: PropTypes.func.isRequired,
  fetchDatasets: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fetchSentReceivedArtifacts: PropTypes.func.isRequired,
  fetchSentReceivedProject: PropTypes.func.isRequired,
  fetchSentReceivedPipelines: PropTypes.func.isRequired,
  createNewProject: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  permission: PropTypes.string,
  fetchUserProjectsStatus: PropTypes.func.isRequired,
  fetchProjectTypes: PropTypes.func.isRequired,
};

export default AppHeader;
