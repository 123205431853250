/* eslint-disable no-nested-ternary */
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  Badge,
  Slide,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useContextMenu } from 'react-contexify';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import LockResetIcon from '@mui/icons-material/LockReset';
import Delete from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactComponent as CodeOffIcon } from '../../assets/images/codeIcon.svg';
import MODULE_SUB_MODULE_OPERATIONS_TREE from '../../const/ModuleSubModuleOperationsTree';
import { ReactComponent as ExpandMoreIcon } from '../../assets/images/DatasetBarExpandIcon.svg';
import { ReactComponent as CollapseIcon } from '../../assets/images/DatasetBarCollapseIcon.svg';
import { ReactComponent as DatasetIcon } from '../../assets/images/DatasetsIcon.svg';
import CustomCarousel from '../AllCustomComponents/CustomCarousel/CustomCarousel';
import TransperentSearchBox from './TransperentSearchBox';
import SwalToast from './SwalToast';
import utils from '../../utils/utils';
import { ContextMenus } from './ContextMenus';
import {
  DS_LOCKED_MSG,
  DS_RECALIBRATION_MSG,
  DATASET_REQUIRED_FIELDS,
} from '../../const/CommonConst';
import DeleteConfirmModal from './DeleteConfirmModal';
import useDataIngestion from '../../Hooks/DM/useDataIngestion';
import RecalibrationModal from './RecalibrationModal';

const PREFIX = 'OperationCards';
const classes = {
  badge: `${PREFIX}-badge`,
  operationsIcons: `${PREFIX}-operationsIcons`,
  root: `${PREFIX}-root`,
  chip: `${PREFIX}-chip`,
  activeChip: `${PREFIX}-activeChip`,
  operationDiv: `${PREFIX}-operationDiv`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.badge}`]: {
    left: 0,
    top: 0,
    borderWidth: theme.spacing(0.3),
    borderColor: '#ffffff',
    borderStyle: 'solid',
    padding: theme.spacing(1),
    color: '#ffffff',
  },
  [`& .${classes.operationsIcons}`]: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginLeft: theme.spacing(0.4),
  },
  [`& .${classes.chip}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.operationDiv}`]: {
    borderRight: `${theme.spacing(0.1)} solid ${theme.palette.secondary.main}`,
    borderLeft: `${theme.spacing(0.1)} solid ${theme.palette.secondary.main}`,
  },
}));

// styled badge
const StyledBadge = Badge;
/**
 * @function OperationChip
 * @description Operation Chip is a UI chip
 * @param {object} data - object conatining details of operation.
 * @param {boolean} isSteps - boolean to check if operation is steps or not.
 * @param {function} onClick - function to handle click on operation chip.
 * @param {string} className - className to be applied on chip.
 * @param {boolean} console - boolean to check if operation is console or not.
 * @param {string} color - color of chip.
 */
const OperationChip = ({
  data,
  isSteps,
  onClick,
  className,
  console,
  color,
}) => {
  return (
    <Chip
      title={console ? data.name : data.label}
      size="small"
      icon={
        console ? (
          <Box pr={0.5}>
            <CodeOffIcon color="primary" />
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            {isSteps && (
              <StyledBadge
                badgeContent={data?.stepCount}
                color="primary"
                classes={{
                  badge: classes.badge,
                }}
              />
            )}
            <Box
              sx={{
                ml: isSteps && data.stepCount ? 4 : 1,
              }}
            >
              <img
                className={classes.operationsIcons}
                src={data.icon.default}
                alt={data.key}
              />
            </Box>
          </Box>
        )
      }
      label={
        console ? (
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" noWrap={true}>
              {data.name}
            </Typography>
            <Typography
              sx={{
                fontSize: '7px',
              }}
            >
              CONSOLE
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2" noWrap={true}>
            {data.label}
          </Typography>
        )
      }
      onClick={onClick}
      color={color}
      style={data.disabled ? { pointerEvents: 'none' } : {}}
      className={className}
    />
  );
};
OperationChip.defaultProps = {
  className: '',
  console: false,
  isSteps: false,
};
OperationChip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  isSteps: PropTypes.bool,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  console: PropTypes.bool,
};

/**
 * @function OperationCards
 * @description UI of Operation Card
 * @param {function} onOpClick - function to handle click on operation card.
 * @param {object} activeOperation - object conatining details of selected operation.
 * @param {array} operations - An array of operations
 * @param {object} activeDataset - currently active dataset
 * @param {function} onDqClick - function to handle click on dq button.
 * @param {array} datasets - An array of dataset
 * @param {object} activeProject - An object which store active projects in DatasetTable
 * @param {boolean} isSteps - boolean to check if operation is steps or not.
 * @param {string} module - module name
 * @param {string} subModule - sub module name
 * @param {function} trackEntryExitTime - function to track entry and exit time of operation
 * @param {object} project - An object which store active projects in DatasetTable
 * @param {array} udfs - An array of udfs
 * @param {function} setSelectedUdf - function to set selected udf
 * @param {object} selectedUdf - An object which store selected udf
 * @param {function} changeTab - function to change tab
 * @returns {JSX.Element}
 */

const OperationCards = ({
  // eslint-disable-next-line no-unused-vars
  onOpClick = (_d) => {},
  activeOperation,
  operations,
  activeDataset,
  onDqClick,
  datasets,
  activeProject,
  isSteps,
  module,
  subModule = '',
  trackEntryExitTime = () => {},
  project = {},
  // fetchAllDatasets = () => {},
  udfs,
  setSelectedUdf,
  selectedUdf,
  changeTab,
  fetchDatasetList,
}) => {
  useEffect(() => {
    if (activeOperation?.key && activeOperation?.key !== ' ') {
      const operationEntryPayload = {
        module,
        sub_module: subModule,
        operation: activeOperation?.key,
        entry_flag: true,
        entry_timestamp: Math.floor(Date.now() / 1000),
        project_id: project?.activeProject?.project_id,
      };
      trackEntryExitTime(operationEntryPayload);
      return () => {
        const operationExitPayload = {
          module,
          sub_module: subModule,
          operation: activeOperation?.key,
          exit_flag: true,
          exit_timestamp: Math.floor(Date.now() / 1000),
          project_id: project?.activeProject?.project_id,
        };
        trackEntryExitTime(operationExitPayload);
      };
    }
  }, [activeOperation?.key]);

  // Change
  const history = useHistory();
  const { deleteDatasetsWithTableName, recalibrateDataset } =
    useDataIngestion();
  const [searchData, setSearchData] = useState('');
  const [searchOperationData, setSearchOperationData] = useState('');
  const [isOperationExpanded, setIsOperationExpanded] = useState(false);
  const [isDataExpanded, setIsDataExpanded] = useState(false);
  const [deleteDatasetId, setDeleteDatasetId] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecalibrateModal, setShowRecalibrateModal] = useState(false);

  const onRefresh = useCallback(() => {
    fetchDatasetList(
      project?.activeProject?.project_id,
      true,
      [
        DATASET_REQUIRED_FIELDS,
        'mvperc',
        'categorical_threshold',
        'stages',
        'fields',
      ].join()
    );
  }, [fetchDatasetList, project?.activeProject?.project_id]);

  const filteredOperationsArray = React.useMemo(
    () =>
      (Array.isArray(operations)
        ? operations
        : Object.values(operations)
      ).filter((ele) => {
        if (
          ele?.regulation?.length &&
          ele.showOp &&
          ele.regulation.includes(activeProject?.regulation)
        ) {
          return searchOperationData
            ? ele.label
                ?.toLowerCase()
                .includes(searchOperationData?.toLowerCase())
            : ele;
        }

        if (!ele?.regulation?.length && ele.showOp) {
          return searchOperationData
            ? ele.key
                ?.toLowerCase()
                .includes(searchOperationData?.toLowerCase())
            : ele;
        }
        return false;
      }),
    [operations, activeProject, searchOperationData]
  );

  const filteredUdfsArray = React.useMemo(
    () =>
      udfs.filter((ele) =>
        searchOperationData
          ? ele.name?.toLowerCase().includes(searchOperationData?.toLowerCase())
          : ele
      ),
    [searchOperationData, udfs]
  );

  const udfToShowInOp = filteredUdfsArray
    .filter((data) => data.show_in_op_bar === true)
    .filter((item) => {
      const sidebarSplitArray = item.tags?.sidebar?.map(
        (sidebarEl) => sidebarEl.split('_')[1]
      );

      const isSidebarTermFound = sidebarSplitArray.find(
        (sideSplitEl) => sideSplitEl === subModule
      );

      if (isSidebarTermFound) return true;

      return false;
    });

  const searchBasedFilter = React.useMemo(() => {
    return datasets?.filter((ele) =>
      searchData
        ? ele.table_display_name
            ?.toLowerCase()
            .includes(searchData?.toLowerCase())
        : ele
    );
  }, [searchData, datasets, deleteDatasetId]);

  const filteredDatasetsArray = React.useMemo(
    () =>
      searchBasedFilter.filter(
        (data) =>
          (data.preprocessed === 'done' ||
            data?.preprocessed === 'recalibrating') &&
          (data.published === true ||
            (data.published === false &&
              module === MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key))
      ),
    [searchBasedFilter, module]
  );

  const filteredDatasetsNotReadyToUseArray = React.useMemo(
    () =>
      datasets?.filter(
        (item) =>
          item.preprocessed === 'done' &&
          item.published === false &&
          module !== MODULE_SUB_MODULE_OPERATIONS_TREE.DM.key
      ),
    [module, datasets]
  );
  const handleOnChange = useCallback(
    (e) => {
      setSearchOperationData(e.target.value);
    },
    [setSearchOperationData]
  );
  const onClickHandler = useCallback(() => {
    setIsOperationExpanded(!isOperationExpanded);
  }, [setIsOperationExpanded, isOperationExpanded]);

  const { show: showDatasetOperationMenus } = useContextMenu();
  const menus = useMemo(
    () => [
      {
        label: `Recalibrate/Reset dataset`,
        id: 'ds_recal',
        icon: AutoModeIcon,
        isMenuDisabled: ({ props: data }) =>
          data?.datalock === false ||
          (data?.datalock === true && data?.preprocessed === 'recalibrating'),
        onClick: ({ props: data }) => {
          setDeleteDatasetId(data);
          setShowRecalibrateModal(true);
        },
      },
      {
        label: 'Delete dataset',
        id: 'ds_delete',
        icon: Delete,
        onClick: ({ props: data }) => {
          setShowDeleteModal(true);
          setDeleteDatasetId(data);
        },
      },
    ],
    [setDeleteDatasetId, setShowRecalibrateModal, setShowDeleteModal]
  );
  const handleDeleteDataset = useCallback(() => {
    deleteDatasetsWithTableName(
      deleteDatasetId,
      () => {
        SwalToast({
          icon: 'success',
          title: 'Dataset deleted successfully.',
        });
        fetchDatasetList(
          project?.activeProject?.project_id,
          true,
          [
            DATASET_REQUIRED_FIELDS,
            'mvperc',
            'categorical_threshold',
            'stages',
            'fields',
          ].join()
        );
      },
      () => {
        SwalToast({
          icon: 'error',
          title: 'Failed to delete dataset.',
        });
      }
    );
    setShowDeleteModal(false);
  }, [setShowDeleteModal, deleteDatasetsWithTableName, deleteDatasetId]);

  const handleRecalibrateDataset = useCallback(() => {
    const payload = {
      dataset_id: deleteDatasetId?.id,
      project_id: project?.activeProject?.project_id,
    };
    recalibrateDataset(payload).then((res) => {
      SwalToast({
        icon: 'success',
        title: res?.data?.response,
      });
      fetchDatasetList(
        project?.activeProject?.project_id,
        true,
        [
          DATASET_REQUIRED_FIELDS,
          'mvperc',
          'categorical_threshold',
          'stages',
          'fields',
        ].join()
      );
    });
    setShowRecalibrateModal(false);
  }, [recalibrateDataset, setShowRecalibrateModal, deleteDatasetId]);

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
    setDeleteDatasetId({});
  }, [setDeleteDatasetId, setShowDeleteModal]);

  const handleCloseRecalibrateModal = useCallback(() => {
    setShowRecalibrateModal(false);
    setDeleteDatasetId({});
  }, [setDeleteDatasetId, setShowRecalibrateModal]);

  const getIcon = useCallback(
    (data) => {
      switch (true) {
        case data?.preprocessed === 'recalibrating':
          return <LockResetIcon color="primary" />;
        case data?.datalock:
          return <LockOutlinedIcon color="primary" />;
        default:
          return <></>;
      }
    },
    [datasets]
  );
  const getTitle = useCallback(
    (data) => {
      switch (true) {
        case data?.preprocessed === 'recalibrating':
          return DS_RECALIBRATION_MSG;
        case data?.datalock:
          return DS_LOCKED_MSG;
        default:
          return `Display Name: ${data?.table_display_name} \nDataset: ${data?.dataset}`;
      }
    },
    [datasets]
  );
  return (
    <>
      <Slide direction="left" in={true} timeout={700}>
        <StyledBox
          sx={{
            width: '49%',
            border: 2,
            borderColor: 'secondary.main',
            borderStyle: 'solid',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingTop: '11px',
            paddingBottom: '11px',
            borderRadius: 1,
            mr: '12px',
            height: isOperationExpanded ? 'auto' : '60px',
          }}
        >
          <Box>
            <Collapse in={isOperationExpanded} collapsedSize={33}>
              <Grid container direction="row" item={true} xs={12}>
                {operations && Object.values(operations)?.length !== 0 ? (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems={!isOperationExpanded ? 'center' : 'start'}
                      >
                        <Box
                          mt={isOperationExpanded && 1}
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <Typography variant="body1" mr={2}>
                            Operations
                          </Typography>
                        </Box>

                        <Box
                          flexGrow={1}
                          overflow="hidden"
                          position="relative"
                          mt={0}
                          className={classes.operationDiv}
                        >
                          {!isOperationExpanded ? (
                            <CustomCarousel
                              elements={[
                                ...filteredOperationsArray.map(
                                  (data, index) => (
                                    <Box
                                      key={data.objKey}
                                      display="flex"
                                      id={`${module}_ops_cards_ops_op_${data.key}`}
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      <Box>
                                        <OperationChip
                                          data={data}
                                          isSteps={isSteps}
                                          onClick={() => {
                                            changeTab('operation');
                                            onOpClick(data);
                                            setSelectedUdf(null);
                                          }}
                                          color={
                                            activeOperation?.key === data.key
                                              ? 'secondary'
                                              : 'primary'
                                          }
                                        />
                                      </Box>

                                      {/* DoubleArrow */}
                                      {filteredOperationsArray.length - 1 ===
                                        index ||
                                      !isSteps ||
                                      !data.stepCount ? null : (
                                        <Box sx={{ ml: 1 }}>
                                          {' '}
                                          <DoubleArrowIcon color="primary" />{' '}
                                        </Box>
                                      )}
                                    </Box>
                                  )
                                ),
                                ...udfToShowInOp.map((data) => (
                                  <Box
                                    key={data.id}
                                    id={`${module}_ops_cards_ops_op_${data.id}`}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                  >
                                    <Box>
                                      <OperationChip
                                        console={true}
                                        data={data}
                                        onClick={() => {
                                          utils.resetConsoleArgs(data);
                                          setSelectedUdf(data);
                                          changeTab('udfs');
                                          onOpClick({ key: ' ' });
                                          history.push(
                                            `/udf-manager/execute/${data?.id}`
                                          );
                                        }}
                                        color={
                                          selectedUdf?.id === data.id
                                            ? 'secondary'
                                            : 'primary'
                                        }
                                      />
                                    </Box>
                                  </Box>
                                )),
                              ]}
                            />
                          ) : (
                            <Box>
                              <Box ml={1} mb={1} width={200}>
                                <TransperentSearchBox
                                  placeholder="Search"
                                  onChange={handleOnChange}
                                  value={searchOperationData}
                                />
                              </Box>
                              <Box
                                overflow="hidden"
                                display="flex"
                                flexDirection="row"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                ml={1}
                              >
                                {filteredOperationsArray.length > 0 ||
                                filteredUdfsArray.length > 0 ? (
                                  <>
                                    {filteredOperationsArray.map(
                                      (data, index) => (
                                        <Box
                                          key={data.objKey}
                                          id={`${module}_ops_cards_ops_op_${data.key}`}
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="center"
                                        >
                                          <Box>
                                            <OperationChip
                                              data={data}
                                              isSteps={
                                                utils.isValueEmpty(
                                                  searchOperationData
                                                )
                                                  ? isSteps
                                                  : null
                                              }
                                              onClick={() => {
                                                changeTab('operation');
                                                onOpClick(data);
                                                setSelectedUdf(null);
                                              }}
                                              color={
                                                activeOperation?.key ===
                                                data.key
                                                  ? 'secondary'
                                                  : 'primary'
                                              }
                                              className={classes.chip}
                                            />
                                          </Box>

                                          {/* DoubleArrow */}
                                          {filteredOperationsArray.length -
                                            1 ===
                                            index || !isSteps ? null : (
                                            <Box
                                              sx={{ mx: 0.5 }}
                                              display="flex"
                                              flexDirection="row"
                                            >
                                              <DoubleArrowIcon color="primary" />{' '}
                                            </Box>
                                          )}
                                        </Box>
                                      )
                                    )}
                                    {udfToShowInOp.map((data) => (
                                      <Box
                                        key={data.id}
                                        id={`${module}_ops_cards_ops_op_${data.id}`}
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                      >
                                        <Box>
                                          <OperationChip
                                            data={data}
                                            console={true}
                                            onClick={() => {
                                              utils.resetConsoleArgs(data);
                                              setSelectedUdf(data);
                                              changeTab('udfs');
                                              onOpClick({ key: ' ' });
                                              history.push(
                                                `/udf-manager/execute/${data?.id}`
                                              );
                                            }}
                                            color={
                                              selectedUdf?.id === data.id
                                                ? 'secondary'
                                                : 'primary'
                                            }
                                            className={classes.chip}
                                          />
                                        </Box>
                                      </Box>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {!filteredUdfsArray.length ||
                                    (!filteredOperationsArray.length &&
                                      searchData !== '') ? (
                                      <Box mb={0.4}>
                                        <Typography variant="body4" ml={2}>
                                          No matching results found!
                                        </Typography>
                                      </Box>
                                    ) : null}
                                  </>
                                )}
                              </Box>
                            </Box>
                          )}
                        </Box>

                        <Grid
                          item
                          xs={1}
                          sx={{ paddingLeft: 1 }}
                          display="flex"
                          alignItems="start"
                        >
                          <Grid item xs={12}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="start"
                            >
                              <IconButton
                                color="primary"
                                onClick={onClickHandler}
                                size="small"
                              >
                                {isOperationExpanded ? (
                                  <CollapseIcon
                                    color="primary"
                                    id={`${module}_ops_cards_dts_dt_interm_check_box_icon_btn`}
                                    onClick={() => {
                                      setSearchOperationData('');
                                    }}
                                  />
                                ) : (
                                  <ExpandMoreIcon
                                    color="primary"
                                    id={`${module}_ops_cards_dts_dt_add_box_icon_btn`}
                                  />
                                )}
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Collapse>
          </Box>
        </StyledBox>
      </Slide>
      <Slide direction="left" in={true} timeout={700}>
        <StyledBox
          sx={{
            width: '50%',
            border: 2,
            borderColor: 'secondary.main',
            borderStyle: 'solid',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingTop: '11px',
            paddingBottom: '11px',
            borderRadius: 1,
            height: isDataExpanded ? 'auto' : '60px',
          }}
        >
          <Box>
            <Collapse in={isDataExpanded} collapsedSize={33}>
              <Grid item xs={12} sx={{ paddingLeft: 1 }}>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems={!isDataExpanded ? 'center' : 'start'}
                  >
                    <Box
                      mt={isDataExpanded && 1}
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      mr={1}
                    >
                      <Typography variant="body1" mr={1}>
                        Datasets
                      </Typography>

                      <IconButton
                        title="Refresh"
                        color="primary"
                        size="small"
                        sx={{ width: '23px', height: '23px' }}
                        onClick={onRefresh}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Box>

                    <Box flexGrow={1} overflow="hidden" position="relative">
                      {datasets?.length ? (
                        <Box
                          flexGrow={1}
                          overflow="hidden"
                          position="relative"
                          className={classes.operationDiv}
                        >
                          {!isDataExpanded ? (
                            filteredDatasetsArray.length !== 0 ? (
                              <CustomCarousel
                                elements={
                                  datasets &&
                                  activeProject?.project_id &&
                                  filteredDatasetsArray.map((data) => (
                                    <Box
                                      key={data.id}
                                      id={`${module}_ops_cards_dts_dt_${data.id}`}
                                    >
                                      <Chip
                                        title={getTitle(data)}
                                        size="small"
                                        sx={{
                                          '.MuiChip-icon': {
                                            color: 'initial',
                                          },
                                        }}
                                        icon={getIcon(data)}
                                        color={
                                          activeDataset?.id === data.id
                                            ? 'secondary'
                                            : 'primary'
                                        }
                                        label={
                                          <Typography
                                            variant="body2"
                                            noWrap={true}
                                          >
                                            {data?.table_display_name}
                                          </Typography>
                                        }
                                        onClick={() => {
                                          if (
                                            data?.preprocessed ===
                                            'recalibrating'
                                          ) {
                                            SwalToast({
                                              icon: 'info',
                                              title: DS_RECALIBRATION_MSG,
                                            });
                                            onDqClick(data);
                                          } else if (data?.datalock) {
                                            SwalToast({
                                              icon: 'info',
                                              title: DS_LOCKED_MSG,
                                            });
                                            onDqClick(data);
                                          } else {
                                            onDqClick(data);
                                          }
                                        }}
                                        onContextMenu={(e) => {
                                          showDatasetOperationMenus({
                                            id: `dataset-operation-menus-${data.id}`,
                                            event: e,
                                            props: data,
                                          });
                                        }}
                                      />
                                      <ContextMenus
                                        menuId={`dataset-operation-menus-${data.id}`}
                                        menus={menus}
                                      />
                                    </Box>
                                  ))
                                }
                              />
                            ) : (
                              <Box mt={0.9} mb={0.9}>
                                <Typography variant="body4" ml={1.4}>
                                  {filteredDatasetsNotReadyToUseArray.length}{' '}
                                  dataset(s) available in this project but not
                                  marked as ready to use.
                                </Typography>
                              </Box>
                            )
                          ) : (
                            <Box>
                              <Box ml={1} mb={1} width={200}>
                                <TransperentSearchBox
                                  placeholder="Search"
                                  onChange={(e) =>
                                    setSearchData(e.target.value)
                                  }
                                  value={searchData}
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="row"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                ml={1}
                                mt={0.1}
                                maxHeight="40vh"
                                overflow="auto"
                              >
                                {datasets &&
                                activeProject?.project_id &&
                                filteredDatasetsArray.length !== 0 ? (
                                  filteredDatasetsArray.map((data) => (
                                    <Box
                                      key={data.id}
                                      id={`${module}_ops_cards_dts_dt_${data.id}`}
                                    >
                                      <Chip
                                        size="small"
                                        title={getTitle(data)}
                                        sx={{
                                          '.MuiChip-icon': {
                                            color: 'initial',
                                          },
                                        }}
                                        icon={getIcon(data)}
                                        label={
                                          <Typography
                                            variant="body2"
                                            noWrap={true}
                                          >
                                            {data?.table_display_name}
                                          </Typography>
                                        }
                                        onClick={() => {
                                          if (
                                            data?.preprocessed ===
                                            'recalibrating'
                                          ) {
                                            SwalToast({
                                              icon: 'info',
                                              title: DS_RECALIBRATION_MSG,
                                            });
                                            onDqClick(data);
                                          } else if (data?.datalock) {
                                            SwalToast({
                                              icon: 'info',
                                              title: DS_LOCKED_MSG,
                                            });
                                            onDqClick(data);
                                          } else {
                                            onDqClick(data);
                                          }
                                        }}
                                        color={
                                          activeDataset?.id === data.id
                                            ? 'secondary'
                                            : 'primary'
                                        }
                                        className={classes.chip}
                                        onContextMenu={(e) => {
                                          showDatasetOperationMenus({
                                            id: `dataset-operation-menus-${data.id}`,
                                            event: e,
                                            props: data,
                                          });
                                        }}
                                      />
                                      <ContextMenus
                                        menuId={`dataset-operation-menus-${data.id}`}
                                        menus={menus}
                                      />
                                    </Box>
                                  ))
                                ) : (
                                  <Box>
                                    {!searchBasedFilter.length &&
                                    searchData !== '' ? (
                                      <Box mb={0.4}>
                                        <Typography variant="body4" ml={2}>
                                          No matching results found!
                                        </Typography>
                                      </Box>
                                    ) : null}

                                    {filteredDatasetsNotReadyToUseArray.length ? (
                                      <Box mt={0.8} mb={0.9}>
                                        <Typography variant="body4" ml={2}>
                                          {
                                            filteredDatasetsNotReadyToUseArray.length
                                          }{' '}
                                          dataset(s) in this project but not
                                          marked as ready to use.
                                        </Typography>
                                      </Box>
                                    ) : null}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box
                          textAlign="center"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="row"
                          onClick={() => {
                            history.push('/data-management/data-ingestion');
                          }}
                          mt={0.9}
                        >
                          <DatasetIcon />
                          <Typography
                            variant="body2"
                            noWrap={true}
                            sx={(theme) => ({
                              color: theme.palette.other.lightBrandBlueLogin,
                              textDecoration: 'underline',
                              textDecorationColor:
                                theme.palette.other.lightBrandBlueLogin,
                            })}
                          >
                            +Add dataset
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Grid
                      item
                      xs={1}
                      sx={{ paddingLeft: 1 }}
                      display="flex"
                      alignItems="start"
                    >
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="start"
                        >
                          <Box display="flex" alignItems="center">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setIsDataExpanded(!isDataExpanded);
                              }}
                              size="small"
                            >
                              {isDataExpanded ? (
                                <CollapseIcon
                                  color="primary"
                                  id={`${module}_ops_cards_dts_dt_interm_check_box_icon_btn`}
                                  onClick={() => {
                                    setSearchData('');
                                  }}
                                />
                              ) : (
                                <ExpandMoreIcon
                                  color="primary"
                                  id={`${module}_ops_cards_dts_dt_add_box_icon_btn`}
                                />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        </StyledBox>
      </Slide>
      <DeleteConfirmModal
        open={showDeleteModal}
        onCloseClick={handleCloseDeleteModal}
        onDeleteClick={handleDeleteDataset}
        confirmationMsg={`Are you sure you want to delete ${deleteDatasetId.table_display_name} ?`}
        resourceName="Dataset"
      />
      <RecalibrationModal
        open={showRecalibrateModal}
        onClose={handleCloseRecalibrateModal}
        onRecalibrateClick={handleRecalibrateDataset}
      />
    </>
  );
};

OperationCards.defaultProps = {
  isSteps: false,
  selectedUdf: null,
};

OperationCards.propTypes = {
  onOpClick: PropTypes.func.isRequired,
  activeOperation: PropTypes.oneOfType([PropTypes.object]).isRequired,
  operations: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeDataset: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  onDqClick: PropTypes.func.isRequired,
  datasets: PropTypes.oneOfType([PropTypes.array]).isRequired,
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSteps: PropTypes.bool,
  module: PropTypes.string.isRequired,
  subModule: PropTypes.string.isRequired,
  trackEntryExitTime: PropTypes.func.isRequired,
  project: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // fetchAllDatasets: PropTypes.func.isRequired,
  udfs: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setSelectedUdf: PropTypes.func.isRequired,
  selectedUdf: PropTypes.oneOfType([PropTypes.object]),
  changeTab: PropTypes.func.isRequired,
  fetchDatasetList: PropTypes.func.isRequired,
};

export default OperationCards;
