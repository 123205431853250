const urlToHelpSectionModuleTagMapping = {
  project: ['ProjectOverview'],
  'data-management': {
    'data-ingestion': ['Datamanagement', 'DataIngestion'],
    preparation: ['Datamanagement', 'DataPreparation'],
  },
  eda: {
    'preliminary-steps': ['EDA', 'PreliminarySteps'],
    'treating-outliers': ['EDA', 'TreatingOutliers'],
    'missing-values-imputation': ['EDA', 'MissingValuesImputation'],
    'variable-analysis': ['EDA', 'VariableAnalysis'],
    'statistical-tests': ['EDA', 'StatisticalTests'],
    'time-series-analysis': ['EDA', 'TimeSeriesAnalysis_EDA'],
  },
  'feature-engineering': {
    'feature-transformation': ['FeatureEngineering'],
  },
  'sampling-segmentation': {
    sampling: ['SamplingSegmentation', 'Sampling'],
    segmentation: ['SamplingSegmentation', 'Segmentation'],
  },
  'variable-reduction': ['VariableReduction'],
  'model-manager': {
    models: ['ModelManager', 'Models'],
  },
  'model-estimation': ['ModelEstimation'],
  'model-testing': {
    'performance-testing': ['ModelTesting', 'PerformaceTesting'],
    'assumption-testing': ['ModelTesting', 'AssumptionTesting'],
    'model-comparison': ['ModelTesting', 'ModelComparison'],
    'back-testing': ['ModelTesting', 'BackTesting'],
    'sensitivity-analysis': ['ModelTesting', 'SensitivityAnalysis'],
    forecasting: ['ModelTesting', 'Forecasting'],
    'scoring-scaling': ['ModelTesting', 'ScoringScaling'],
    fairness: ['ModelTesting', 'Fairness'],
    'drift-analysis': ['ModelTesting', 'DriftAnalysis'],
    calibiration: ['ModelTesting', 'ModelRecaliberation'],
  },
  'model-interpretation': {
    'feature-interpretation': ['ModelInterpretation', 'FeatureInterpretation'],
    'individual-prediction': ['ModelInterpretation', 'IndividualPrediction'],
    'decision-trees': ['ModelInterpretation', 'DecisionTrees'],
    'time-series-analysis': [
      'ModelInterpretation',
      'TimeSeriesAnalysis_ModelInterpretation',
    ],
  },
  documentation: ['AutomatedDocumentation'],
  'udf-manager': [
    'UDF',
    'CreateUdf',
    'ViewDetails',
    'Execute',
    'RunUdf',
    'DeleteUdf',
    'SaveEditUdf',
    'VersionHistory',
    'ShareSendUdf',
    'ShareAcceptUdf',
    'CloneUdf',
    'Rerun',
    'JobsView',
    'JobsCompare',
    'JobsAddPipeLine',
    'JobsDelete',
    'FileView',
    'FileUpload',
    'FileDownload',
    'FileDelete',
    'DeleteUdf',
    'ConsoleFile',
    'Console',
  ],
  pipeline: ['Pipeline'],
  downloads: ['Downloads'],
  settings: {
    'color-palette': ['Settings', 'ColorPalette'],
    'user-group-provisioning': ['Settings', 'UserManagement'],
    auditability: 'Auditability',
    'orchestration-management': ['Settings', 'OrchestrationManagement'],
    'worker-management': ['Settings', 'Workers'],
    'documentation-template': ['Settings', 'DocumentationTemplate'],
    'power-bi': ['Settings', 'PowerBi'],
  },
};
export default urlToHelpSectionModuleTagMapping;
