import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import React, { useEffect } from 'react';
import { REGULATIONS } from '../../../../const/CommonConst';

const tsRegulationKeys = REGULATIONS.filter((item) => item.isTs).map(
  (item) => item.key
);

const PREFIX = 'MetricSelctionNew';

const classes = {
  root: `${PREFIX}-root`,
  chip: `${PREFIX}-chip`,
  chipSelected: `${PREFIX}-chipSelected`,
  typographyHeading: `${PREFIX}-typographyHeading`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    // backgroundColor: '#e3f2fd',
  },

  [`& .${classes.chip}`]: {
    margin: theme.spacing(0.5),
    fontSize: '1rem',
    letterSpacing: theme.spacing(0.1),
    borderWidth: theme.spacing(0.4),
    color: 'primary',
  },

  [`& .${classes.chipSelected}`]: {
    margin: theme.spacing(0.5),
    fontSize: '1rem',
    letterSpacing: theme.spacing(0.1),
    borderWidth: theme.spacing(0.4),
    color: '#ffffff',
  },

  [`& .${classes.typographyHeading}`]: {
    letterSpacing: theme.spacing(0.1),
  },
  '& .MuiChip-label': {
    maxWidth: 'none',
  },
}));

/**
 * @function MetricSelection
 * @description Metric Selection Modal - child component
 *  of Performance Testing - building UI of Performance Testing
 * @param {object} activeDataset - currently active dataset
 * @param {object} activeProject - object containing details
 *  of active project like project regulation, type, datasetcount etc.
 * @param {function} fetchExisistingRules - function to fetch existing rules
 * @param {Array} stabilityMetrics - array of selected stability metrics options
 * @param {function} setStabilityMetrics - function to set stability metrics
 * @param {Array} strengthMetrics - array of selected strength metrics options
 * @param {function} setStrengthMetrics - function to set strength metrics
 * @param {Array} timeSeriesMetrics - array of selected time series metrics options
 * @param {function} setTimeSeriesMetrics - function to set time series metrics options
 * @param {function} fetchTSExistingRules - function to fetch existing rules for time series
 * @param {boolean} showMatricesError=false - boolean that decide if to show error or not
 * @returns {JSX} MetricSelection UI
 * @example <MetricSelectionNew activeDataset={activeDataset}
 *  activeProject={activeProject} fetchExisistingRules={fetchExisistingRules} />
 */
const MetricSelection = ({
  activeDataset,
  activeProject,
  fetchExisistingRules,
  stabilityMetrics,
  setStabilityMetrics,
  strengthMetrics,
  setStrengthMetrics,
  timeSeriesMetrics,
  setTimeSeriesMetrics,
  fetchTSExistingRules,
  showMatricesError = false,
}) => {
  // For the case of failure in fetching existing metrices
  useEffect(() => {
    if (activeProject?.project_id) {
      fetchExisistingRules({ project_id: activeProject.project_id });
      fetchTSExistingRules({ project_id: activeProject.project_id });
    }
  }, [activeProject?.project_id, activeDataset?.name]);

  /**
   * @function handleMetricChipClick
   * @description function to handle click on metric chip
   * @param {object} data - object containing details of metric chip
   * @param {boolean} tSMetricChip=false - boolean to decide if metric chip is for time series
   * @param {boolean} strengthChip=false - boolean to decide if metric chip is for strength
   * @returns {undefined} undefined
   */
  const handleMetricChipClick = (
    data,
    tSMetricChip = false,
    strengthChip = false
  ) => {
    let newMetrics;
    if (tSMetricChip) {
      newMetrics = [...timeSeriesMetrics];
    } else if (strengthChip) {
      newMetrics = [...strengthMetrics];
    } else {
      newMetrics = [...stabilityMetrics];
    }
    const currentIndex = newMetrics.findIndex((x) => x.label === data.label);
    newMetrics[currentIndex].isSelected = !newMetrics[currentIndex].isSelected;
    if (tSMetricChip) {
      setTimeSeriesMetrics(newMetrics);
    } else if (strengthChip) {
      setStrengthMetrics(newMetrics);
    } else {
      setStabilityMetrics(newMetrics);
    }
  };

  return (
    <StyledGrid container>
      <Box width="100%">
        {tsRegulationKeys.includes(activeProject?.regulation) ? (
          <Box py={1}>
            <Typography
              variant="subtitle1"
              className={classes.typographyHeading}
            >
              Select metrics
            </Typography>
            <Box className={classes.root}>
              {timeSeriesMetrics.map((data) => (
                <li key={data.value}>
                  <Chip
                    label={data.label}
                    color={data.isSelected ? 'secondary' : 'primary'}
                    clickable
                    icon={data.isSelected ? <DoneIcon /> : null}
                    onClick={() => handleMetricChipClick(data, true)}
                    className={
                      data.isSelected ? classes.chipSelected : classes.chip
                    }
                  />
                </li>
              ))}
            </Box>
          </Box>
        ) : (
          <>
            <Box py={1}>
              <Typography
                variant="subtitle1"
                className={classes.typographyHeading}
              >
                Select stability metrics
              </Typography>
              <Box className={classes.root}>
                {stabilityMetrics.map((data) => (
                  <li key={data.value}>
                    <Chip
                      label={data.label}
                      color={data.isSelected ? 'secondary' : 'primary'}
                      clickable
                      icon={data.isSelected ? <DoneIcon /> : null}
                      onClick={() => handleMetricChipClick(data)}
                      className={
                        data.isSelected ? classes.chipSelected : classes.chip
                      }
                    />
                  </li>
                ))}
              </Box>
            </Box>
            <Box py={1}>
              <Typography
                variant="subtitle1"
                className={classes.typographyHeading}
              >
                Select strength metrics
              </Typography>
              <Box className={classes.root}>
                {strengthMetrics.map((data) => (
                  <li key={data.value}>
                    <Chip
                      label={data.label}
                      color={data.isSelected ? 'secondary' : 'primary'}
                      clickable
                      icon={data.isSelected ? <DoneIcon /> : null}
                      onClick={() => handleMetricChipClick(data, false, true)}
                      className={
                        data.isSelected ? classes.chipSelected : classes.chip
                      }
                    />
                  </li>
                ))}
              </Box>
            </Box>
          </>
        )}
        {showMatricesError && (
          <Box height={55} textAlign="center">
            <Typography color="error" component="h6" variant="h6">
              Please select atleast 1 item from each metrics and save metrics.
            </Typography>
          </Box>
        )}
      </Box>
    </StyledGrid>
  );
};

MetricSelection.propTypes = {
  activeProject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeDataset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  stabilityMetrics: PropTypes.oneOfType([PropTypes.array]).isRequired,
  strengthMetrics: PropTypes.oneOfType([PropTypes.array]).isRequired,
  fetchExisistingRules: PropTypes.func.isRequired,
  setStabilityMetrics: PropTypes.func.isRequired,
  setStrengthMetrics: PropTypes.func.isRequired,
  showMatricesError: PropTypes.bool.isRequired,
  setTimeSeriesMetrics: PropTypes.func.isRequired,
  timeSeriesMetrics: PropTypes.func.isRequired,
  fetchTSExistingRules: PropTypes.func.isRequired,
};

export default React.memo(MetricSelection);
