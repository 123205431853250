import React from 'react';
import {
  Dialog,
  Typography,
  IconButton,
  Paper,
  Grid,
  Box,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import PropTypes from 'prop-types';
import './CustomDraggableModal.scss';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import DialogTransition from '../../../components/Common/Transitions/DialogTransition';

/**
 * @function PaperComponent
 * @description Draggable Paper Component
 * @param {object} props - Props
 * @returns {object} - React draggable dialog component.
 */

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * @function CustomDraggableModal
 * @description Custom Draggable Modal
 * @param {object} props - Props
 * @returns {object} - Custom Draggable Modal.
 */

const CustomDraggableModal = (props) => {
  const { maxWidth, show, onHide, header, children } = props;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth || 'xs'}
      PaperComponent={PaperComponent}
      open={show}
      onClose={() => {
        onHide();
      }}
      aria-labelledby="draggable-dialog-title"
      hideBackdrop={true}
      TransitionComponent={DialogTransition}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Grid item xs={12}>
          <Box
            display="flex"
            flexGrow={1}
            alignItems="flex-end"
            flexDirection="column"
          >
            <IconButton
              id="mui_cmp_cust_drag_dialog_title_close_btn"
              className="mui-dialog-close-icon-btn"
              color="primary"
              aria-label="close"
              onClick={() => {
                onHide();
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h3">{header}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

CustomDraggableModal.defaultProps = {
  show: true,
  onHide: () => {},
  maxWidth: 'xs',
};
CustomDraggableModal.propTypes = {
  show: PropTypes.bool,
  maxWidth: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onHide: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CustomDraggableModal;
